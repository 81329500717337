import {darken} from 'polished'
import {Check, ChevronDown} from 'react-feather'
import {Button as RebassButton, ButtonProps as ButtonPropsOriginal} from 'rebass/styled-components'
import styled, {DefaultTheme, useTheme} from 'styled-components/macro'

import {RowBetween} from '../Row'
import {BREAKPOINTS} from 'theme'
import GmeowIEarcon from 'components/Icons/GmeowIEarcon'
import {useEffect, useMemo, useState} from 'react'
import {useWeb3React} from '@web3-react/core'
import {DEFAULT_CHAINID, SupportedChainId} from 'constants/chains'
import useSelectChain from 'hooks/useSelectChain'
import {useToggleWalletModal} from 'state/application/hooks'

type ButtonProps = Omit<ButtonPropsOriginal, 'css'>

export const BaseButton = styled(RebassButton) <
    {
        padding?: string
        width?: string
        $borderRadius?: string
        altDisabledStyle?: boolean
    } & ButtonProps
>`
    padding: ${({padding}) => padding ?? '16px'};
    width: ${({width}) => width ?? '100%'};
    color: ${({theme}) => theme.deprecated_text1};

    font-weight: 600;
    text-align: center;
    outline: none;
    border: 1px solid transparent;
    text-decoration: none;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;

    &:disabled {
        opacity: 50%;
        cursor: auto;
        pointer-events: none;
    }

    will-change: transform;
    transition: transform 450ms ease;
    transform: perspective(1px) translateZ(0);

    > * {
        user-select: none;
    }

    > a {
        text-decoration: none;
    }
`

export const ButtonPrimary = styled(BaseButton)`
    font-size: 20px;
    font-weight: 600;
    color: #FFF9E1;
    --notchSize: 15px;
    border: none;
    font-family: 'DynaPuff';
    background: #00E440;
    cursor: pointer;
    width: 100%;
    text-decoration: none;

    span {
        color: #FFF9E1;
        font-weight: bold;
    }

    &:hover {
        background: gray;
    }
`

export const ButtonLight = styled(BaseButton)`
    background-color: ${({theme}) => theme.accentActionSoft};
    color: ${({theme}) => theme.accentAction};
    font-size: 20px;
    font-weight: 500;

    &:focus {
        box-shadow: 0 0 0 1pt ${({theme, disabled}) => !disabled && theme.accentActionSoft};
        background-color: ${({theme, disabled}) => !disabled && theme.accentActionSoft};
    }

    &:hover {
        background-color: ${({theme, disabled}) => !disabled && theme.accentActionSoft};
    }

    &:active {
        box-shadow: 0 0 0 1pt ${({theme, disabled}) => !disabled && theme.accentActionSoft};
        background-color: ${({theme, disabled}) => !disabled && theme.accentActionSoft};
    }

    :disabled {
        opacity: 0.4;

        :hover {
            cursor: auto;
            background-color: transparent;
            box-shadow: none;
            border: 1px solid transparent;
            outline: none;
        }
    }
`

export const ButtonGray = styled(BaseButton)`
    background-color: transparent;
    color: #05C63B;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 20px */

    &:hover {
        opacity: 0.5;
    }

    &:active {
        opacity: 0.5;
    }
`

export const ButtonSecondary = styled(BaseButton)`
    border: 1px solid ${({theme}) => theme.deprecated_primary4};
    color: ${({theme}) => theme.deprecated_primary1};
    background-color: transparent;
    font-size: 16px;
    padding: ${({padding}) => (padding ? padding : '10px')};

    &:focus {
        box-shadow: 0 0 0 1pt ${({theme}) => theme.deprecated_primary4};
        border: 1px solid ${({theme}) => theme.deprecated_primary3};
    }

    &:hover {
        border: 1px solid ${({theme}) => theme.deprecated_primary3};
    }

    &:active {
        box-shadow: 0 0 0 1pt ${({theme}) => theme.deprecated_primary4};
        border: 1px solid ${({theme}) => theme.deprecated_primary3};
    }

    &:disabled {
        opacity: 50%;
        cursor: auto;
    }

    a:hover {
        text-decoration: none;
    }
`

export const ButtonOutlined = styled(BaseButton)`
    border-radius: 6px;
    border: 2px solid #E5B670;
    background: #FFF6D0;

    &:focus {
        opacity: 50%;
    }

    &:hover {
        opacity: 50%;
    }

    &:active {
        opacity: 50%;
    }

    &:disabled {
        opacity: 50%;
        cursor: auto;
    }
`

export const ButtonYellow = styled(BaseButton)`
    background-color: ${({theme}) => theme.accentWarningSoft};
    color: ${({theme}) => theme.accentWarning};

    &:focus {
        background-color: ${({theme}) => theme.accentWarningSoft};
    }

    &:hover {
        background: ${({theme}) => theme.stateOverlayHover};
        mix-blend-mode: normal;
    }

    &:active {
        background-color: ${({theme}) => theme.accentWarningSoft};
    }

    &:disabled {
        background-color: ${({theme}) => theme.accentWarningSoft};
        opacity: 60%;
        cursor: auto;
    }
`

export const ButtonEmpty = styled(BaseButton)`
    background-color: transparent;
    color: ${({theme}) => theme.deprecated_primary1};
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
        text-decoration: underline;
    }

    &:hover {
        text-decoration: none;
    }

    &:active {
        text-decoration: none;
    }

    &:disabled {
        opacity: 50%;
        cursor: auto;
    }
`

export const ButtonText = styled(BaseButton)`
    padding: 0;
    width: fit-content;
    background: none;
    text-decoration: none;

    &:focus {
        text-decoration: underline;
    }

    transition: scale 0.15s ease-in-out;
    &:hover {
        opacity: 0.9;
        scale: 1.02;
    }

    &:active {
        text-decoration: underline;
    }

    &:disabled {
        opacity: 50%;
        cursor: auto;
    }
`

const ButtonConfirmedStyle = styled(BaseButton)`
    background-color: ${({theme}) => theme.deprecated_bg3};
    color: ${({theme}) => theme.deprecated_text1};
        /* border: 1px solid ${({theme}) => theme.deprecated_green1}; */

    &:disabled {
        opacity: 50%;
        background-color: ${({theme}) => theme.deprecated_bg2};
        color: ${({theme}) => theme.deprecated_text2};
        cursor: auto;
    }
`

const ButtonErrorStyle = styled(BaseButton)`
    background-color: ${({theme}) => theme.deprecated_red1};
    border: 1px solid none;

    &:focus {
        box-shadow: 0 0 0 1pt ${({theme}) => darken(0.05, theme.deprecated_red1)};
        background-color: ${({theme}) => darken(0.05, theme.deprecated_red1)};
    }

    &:hover {
        background-color: ${({theme}) => darken(0.05, theme.deprecated_red1)};
    }

    &:active {
        box-shadow: 0 0 0 1pt ${({theme}) => darken(0.1, theme.deprecated_red1)};
        background-color: ${({theme}) => darken(0.1, theme.deprecated_red1)};
    }

    &:disabled {
        opacity: 50%;
        cursor: auto;
        box-shadow: none;
        background-color: ${({theme}) => theme.deprecated_red1};
        border: 1px solid ${({theme}) => theme.deprecated_red1};
    }
`

export function ButtonConfirmed({
                                    confirmed,
                                    altDisabledStyle,
                                    ...rest
                                }: {
    confirmed?: boolean;
    altDisabledStyle?: boolean;
    earMaxW?: string;
    mbEarMaxW?: string
} & ButtonProps) {
    if (confirmed) {
        return <NotchedButtonFill {...rest} />
    } else {
        return <NotchedButtonFill {...rest} altDisabledStyle={altDisabledStyle}/>
    }
}

export function ButtonError({error, height, ...rest}: {
    error?: boolean,
    earMaxW?: string,
    height?: string
} & ButtonProps) {
    if (error) {
        return <NotchedButtonFill {...rest} bg="#00E440" textColor="#FFF9E1" height={height}/>
    } else {
        return <NotchedButtonFill {...rest} bg="#00E440" textColor="#FFF9E1" height={height}/>
    }
}

export function ButtonDropdown({disabled = false, children, ...rest}: { disabled?: boolean } & ButtonProps) {
    return (
        <NotchedButtonFill {...rest} disabled={disabled}>
            <RowBetween>
                <div style={{display: 'flex', alignItems: 'center'}}>{children}</div>
                <ChevronDown size={24}/>
            </RowBetween>
        </NotchedButtonFill>
    )
}

export function ButtonDropdownLight({disabled = false, children, ...rest}: { disabled?: boolean } & ButtonProps) {
    return (
        <ButtonOutlined {...rest} disabled={disabled}>
            <RowBetween>
                <div style={{display: 'flex', alignItems: 'center'}}>{children}</div>
                <ChevronDown size={24} color="#4B2828"/>
            </RowBetween>
        </ButtonOutlined>
    )
}

const ActiveOutlined = styled(ButtonOutlined)`
    background: #E5B670;

    .description-text-style {
        color: #fff;
    }
`

const Circle = styled.div`
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background: linear-gradient(90deg, #68ffff 0%, #68ff9b 100%);

    display: flex;
    align-items: center;
    justify-content: center;
`

const CheckboxWrapper = styled.div`
    width: 20px;
    padding: 0 10px;
    position: absolute;
    top: 11px;
    right: 15px;
`

const ResponsiveCheck = styled(Check)`
    size: 13px;
`

export function ButtonRadioChecked({active = false, children, ...rest}: { active?: boolean } & ButtonProps) {
    const theme = useTheme()

    if (!active) {
        return (
            <ButtonOutlined $borderRadius="0px" padding="12px 8px" {...rest}>
                {children}
            </ButtonOutlined>
        )
    } else {
        return (
            <ActiveOutlined {...rest} padding="12px 8px" $borderRadius="0px">
                {children}
            </ActiveOutlined>
        )
    }
}

const ButtonOverlay = styled.div`
    background-color: transparent;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 150ms ease background-color;
    width: 100%;
`

export enum ButtonSize {
    small,
    medium,
    large,
}

export enum ButtonEmphasis {
    high,
    promotional,
    highSoft,
    medium,
    low,
    warning,
    destructive,
}

interface BaseButtonProps {
    size: ButtonSize
    emphasis: ButtonEmphasis
}

function pickThemeButtonBackgroundColor({theme, emphasis}: { theme: DefaultTheme; emphasis: ButtonEmphasis }) {
    switch (emphasis) {
        case ButtonEmphasis.high:
            return theme.accentAction
        case ButtonEmphasis.promotional:
            return theme.accentTextLightPrimary
        case ButtonEmphasis.highSoft:
            return theme.accentActionSoft
        case ButtonEmphasis.low:
            return 'transparent'
        case ButtonEmphasis.warning:
            return theme.accentWarningSoft
        case ButtonEmphasis.destructive:
            return theme.accentCritical
        case ButtonEmphasis.medium:
        default:
            return theme.backgroundInteractive
    }
}

function pickThemeButtonFontSize({size}: { size: ButtonSize }) {
    switch (size) {
        case ButtonSize.large:
            return '20px'
        case ButtonSize.medium:
            return '16px'
        case ButtonSize.small:
            return '14px'
        default:
            return '16px'
    }
}

function pickThemeButtonLineHeight({size}: { size: ButtonSize }) {
    switch (size) {
        case ButtonSize.large:
            return '24px'
        case ButtonSize.medium:
            return '20px'
        case ButtonSize.small:
            return '16px'
        default:
            return '20px'
    }
}

function pickThemeButtonPadding({size}: { size: ButtonSize }) {
    switch (size) {
        case ButtonSize.large:
            return '16px'
        case ButtonSize.medium:
            return '10px 12px'
        case ButtonSize.small:
            return '8px'
        default:
            return '10px 12px'
    }
}

function pickThemeButtonTextColor({theme, emphasis}: { theme: DefaultTheme; emphasis: ButtonEmphasis }) {
    switch (emphasis) {
        case ButtonEmphasis.high:
        case ButtonEmphasis.promotional:
            return theme.accentTextLightPrimary
        case ButtonEmphasis.highSoft:
            return theme.accentAction
        case ButtonEmphasis.low:
            return theme.textSecondary
        case ButtonEmphasis.warning:
            return theme.accentWarning
        case ButtonEmphasis.destructive:
            return theme.accentTextDarkPrimary
        case ButtonEmphasis.medium:
        default:
            return theme.textPrimary
    }
}

const BaseThemeButton = styled.button<BaseButtonProps>`
    align-items: center;
    background-color: ${pickThemeButtonBackgroundColor};
    border: 0;
    color: ${pickThemeButtonTextColor};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: ${pickThemeButtonFontSize};
    font-weight: 600;
    gap: 12px;
    justify-content: center;
    line-height: ${pickThemeButtonLineHeight};
    padding: ${pickThemeButtonPadding};
    position: relative;
    transition: 150ms ease opacity;

    :active {
        ${ButtonOverlay} {
            background-color: ${({theme}) => theme.stateOverlayPressed};
        }
    }

    :disabled {
        cursor: default;
        opacity: 0.6;
    }

    :focus {
        ${ButtonOverlay} {
            background-color: ${({theme}) => theme.stateOverlayPressed};
        }
    }

    :hover {
        ${ButtonOverlay} {
            background-color: ${({theme}) => theme.stateOverlayHover};
        }
    }
`

export const NotchedButtonFillWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
`

export const NotchedButtonFillBtn = styled.button<
    {
        textColor?: string
        fontSize?: string
        mbFontSize?: string
        mbFontSizeXs?: string
        padding?: string
        mobilePadding?: string
        height?: string
        mbHeight?: string
        width?: string
        justifyContent?: string
        alignItems?: string
        mobileMaxW?: string
        clipPath?: string
        bgHover?: string
        fontWeight?: string
        bg?: string;
    }
    & ButtonProps
>`
    position: relative;
    font-size: ${({fontSize}) => (fontSize ? fontSize : '21px')} !important;
    font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : '600')};
    width: ${({width}) => width ?? '100%'};
    height: ${({height}) => height ?? '45px'};
    padding: ${({padding}) => (padding ? padding : '16px 70px')};
    justify-content: ${({justifyContent}) => justifyContent ?? 'center'};
    align-items: ${({alignItems}) => alignItems ?? 'center'};

    --notchSize: 15px;
    border: none;
    font-family: DynaPuff;
    background: ${({bg}) => (bg ? bg : '#ffb704 ')};
    cursor: pointer;
    color: ${({textColor, disabled}) => disabled ? '#4B2828' : (textColor ?? '#4B2828')};;
    text-decoration: none;
    display: flex;
    position: relative;
    border-radius: 8px;
    transition: background-color 0.15s ease-in-out, scale 0.15s ease-in-out;

    svg.path {
        transition: fill 0.15s ease-in-out;
    }

    span {
        background: ${({bg}) => (bg ? bg : '#ffb704 ')};;
        -webkit-background-clip: text;
        background-clip: text;
        color: ${({textColor}) => textColor ?? '#4B2828'};
        font-weight: bold;
    }

    div, span {
        font-size: ${({fontSize}) => (fontSize ? fontSize : '21px')} !important;
    }

    &:hover, &:active {
        background: ${({bgHover}) => bgHover ?? 'gray'} !important;
        scale: 1.01;
    }

    &:active ~ svg.path, &:hover ~ svg.path {
        fill: ${({bgHover}) => bgHover ?? 'gray'} !important;
    }

    &:disabled {
        background: gray;
        cursor: not-allowed;
        scale: none;

        svg.path {
            fill: gray;
        }
    }

    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        width: ${({mobileMaxW}) => mobileMaxW ?? '100%'};
        padding: ${({mobilePadding}) => mobilePadding ?? ''};
        font-size: ${({mbFontSize}) => (mbFontSize ? mbFontSize : '18px')} !important;
        height: ${({mbHeight}) => mbHeight ?? '45px'};
        div, span {
            font-size: ${({mbFontSize}) => (mbFontSize ? mbFontSize : '18px')} !important;
        }
    }
    @media screen and (max-width: 410px) {
        font-size: ${({mbFontSizeXs}) => (mbFontSizeXs ? mbFontSizeXs : '15px')} !important;
        div, span, p {
            font-size: ${({mbFontSizeXs}) => (mbFontSizeXs ? mbFontSizeXs : '15px')} !important;
        }
    }
`

const GmeowBtnWrapper = styled.div<{
    disabled?: boolean;
    bg?: string;
    bgHover?: string;
    height?: string;
    mbHeight?: string;
}>`
    height: ${({height}) => height ?? '45px'};
    position: relative;
    text-decoration: none;

    svg.path {
        fill: ${({bg, disabled}) => (disabled ? 'gray' : (bg ? bg : '#ffb704'))} !important;

        &:hover {
            fill: ${({bgHover}) => bgHover ?? 'gray'} !important;
        }
    }

    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        height: ${({mbHeight}) => mbHeight ?? '45px'};
    }

    &:hover, &:active {
        svg.path {
            fill: gray !important;
        }
    }
`

const GmeowIconWrapper = styled.div<{
    iconTop?: string;
}>`
    position: absolute;
    top: ${({iconTop}) => iconTop ?? '-10px'};
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
`

const GmeowIconContent = styled.div<{
    earMaxW?: string;
    mbEarMaxW?: string;
}>`
    max-width: ${({earMaxW}) => earMaxW ?? '170px'};
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        max-width: ${({mbEarMaxW}) => mbEarMaxW ?? '170px'};
    }
`

export function NotchedButtonFill(props: any) {
    const {account, chainId} = useWeb3React()
    const selectChain = useSelectChain()
    const toggleWalletModal = useToggleWalletModal()

    const [isSwitchingChainId, setIsSwitchingChainId] = useState<boolean>(false)
    const isValidChain = useMemo(() => chainId == DEFAULT_CHAINID, [chainId])
    const isHideSwitchchain = props?.isHideSwitchchain ?? false
    const switchChain = async () => {
        if (isSwitchingChainId) return
        try {
            setIsSwitchingChainId(true)
            await selectChain(DEFAULT_CHAINID)
        } catch (err) {
        } finally {
            setIsSwitchingChainId(false)
        }
    }

    const textColor = props.bg == '#00E440' ? '#FFF9E1' : undefined
    if (props.isShowDirectly)
        return <NotchedButtonFillBeHide {...props} />
    return account ?
        (
            isHideSwitchchain ? <NotchedButtonFillBeHide {...props} />
                : (isValidChain ? <NotchedButtonFillBeHide {...props} />
                    : <NotchedButtonFillBeHide {...props} onClick={switchChain} disabled={false} textColor={textColor}>
                        {isSwitchingChainId ? 'SWITCHING...' : 'SWICH TO ZIRCUIT CHAIN'}
                    </NotchedButtonFillBeHide>)
        )
        :
        <NotchedButtonFillBeHide {...props} onClick={toggleWalletModal} disabled={false} textColor={textColor}>
            {"CONNECT WALLET"}
        </NotchedButtonFillBeHide>
}

export function NotchedButtonFillBeHide(props: any) {
    const [isHovered, setIsHovered] = useState<boolean>(false)

    return <GmeowBtnWrapper className='filled-btn-wrapper' {...props} onClick={() => {
    }} onTouchStart={() => setIsHovered(true)} onTouchEnd={() => setIsHovered(false)}>
        {
            !props.isTurnOfEar && <GmeowIconWrapper className='gmeo-icon-ear-wrapper' iconTop={props.iconTop}>
                <GmeowIconContent className='gmeo-icon-ear' earMaxW={props.earMaxW} mbEarMaxW={props.mbEarMaxW}>
                    <GmeowIEarcon fill={isHovered || props.disabled ? 'gray' : (props.bg ?? '#ffb704')}/>
                    <GmeowIEarcon fill={isHovered || props.disabled ? 'gray' : (props.bg ?? '#ffb704')}/>
                </GmeowIconContent>
            </GmeowIconWrapper>
        }

        <NotchedButtonFillBtn className='filled-btn' {...props} height={props.height}
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}>
            {props.children}
        </NotchedButtonFillBtn>
    </GmeowBtnWrapper>

}

export const NotchedButtonFillBase = styled.button<
    {
        fontSize?: string
        padding?: string
        height?: string
        width?: string
        justifyContent?: string
        alignItems?: string
        mobileMaxW?: string
        clipPath?: string
        fontWeight?: string
    } & ButtonProps
>`
    font-size: ${({fontSize}) => (fontSize ? fontSize : '18px')};
    font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : '600')};

    width: ${({width}) => width ?? '100%'};
    height: ${({height}) => height ?? '45px'};
    padding: ${({padding}) => (padding ? padding : '16px 70px')};
    justify-content: ${({justifyContent}) => justifyContent ?? 'center'};
    align-items: ${({alignItems}) => alignItems ?? 'center'};
    clip-path: ${({clipPath}) =>
            clipPath ?? 'polygon(10px 0, 100% 0, 100% 70%, calc(100% - 10px) 100%, 0 100%, 0 30%)'};
    --notchSize: 15px;
    border: none;
    font-family: 'Righteous';
    background: rgba(239, 239, 228, 0.2);
    cursor: pointer;
    color: #4B2828;
    text-decoration: none;
    display: flex;
    position: relative;

    span {
        background: linear-gradient(90deg, #68ffff 0%, #68ff9b 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: #4B2828;
        font-weight: bold;
    }

    &:hover {
        background: gray;
    }

    &:disabled {
        opacity: 50%;
        background-color: gray;
        cursor: not-allowed;
    }

    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        width: ${({mobileMaxW}) => mobileMaxW ?? '100%'};
    }
`

export const NotchedButtonFillPrimary = styled(NotchedButtonFill)`
    --notchSize: 15px;
    border: none;
    font-size: 18px;
    padding: 16px 70px;
    font-family: 'Righteous';
    background: #e8ecfb;
    cursor: pointer;
    width: 100%;

    span {
        background: linear-gradient(90deg, #68ffff 0%, #68ff9b 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: #000;
        font-weight: bold;
    }

    &:hover {
        background: gray;
    }

    &:disabled {
        opacity: 50%;
        background-color: gray;
        cursor: not-allowed;
    }
`

interface ThemeButtonProps extends React.ComponentPropsWithoutRef<'button'>, BaseButtonProps {
}

export const ThemeButton = ({children, ...rest}: ThemeButtonProps) => {
    return (
        <BaseThemeButton {...rest}>
            <ButtonOverlay/>
            {children}
        </BaseThemeButton>
    )
}
