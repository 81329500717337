import { Trans } from '@lingui/macro'
import { TraceEvent } from '@uniswap/analytics'
import { BrowserEvent, ElementName, EventName, SectionName } from '@uniswap/analytics-events'
import { Currency, CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import { useWeb3React } from '@web3-react/core'
import { AutoColumn } from 'components/Column'
import { LoadingOpacityContainer, loadingOpacityMixin } from 'components/Loader/styled'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { DEFAULT_CHAINID, isSupportedChain, SupportedChainId } from 'constants/chains'
import { darken } from 'polished'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { Lock } from 'react-feather'
import styled, { useTheme } from 'styled-components/macro'
import { flexColumnNoWrap, flexRowNoWrap } from 'theme/styles'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'

import { ReactComponent as DropDown } from '../../assets/images/swap-dropdown.svg'
import { useCurrencyBalance } from '../../state/connection/hooks'
import { BREAKPOINTS, ThemedText } from '../../theme'
import { ButtonGray } from '../Button'
import DoubleCurrencyLogo from '../DoubleLogo'
import { Input as NumericalInput } from '../NumericalInput'
import { RowBetween, RowFixed } from '../Row'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { FiatValue } from './FiatValue'
import { formatXpNumber } from 'pages/Xp/sections/XpTitle'
import useSelectChain from 'hooks/useSelectChain'
import { formatNumber } from 'utils/formatNumbers'
import fromExponential from 'from-exponential';

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${flexColumnNoWrap};
  position: relative;
  z-index: 1;
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  transition: height 1s ease;
  will-change: height;

  padding: 32px 24px;
  border-radius: 10px;
  border: 2px solid #E5B670;
  background: #FFF6D0;
  box-shadow: 4px 4px 4px 0px rgba(215, 144, 60, 0.80) inset, -4px -4px 4px 0px #FFF inset;

  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    padding: 16px 12px;
  }
`

const FixedContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

const Container = styled.div<{ hideInput: boolean }>`
  min-height: 44px;
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
`

const CurrencySelect = styled(ButtonGray) <{
  visible: boolean
  selected: boolean
  hideInput?: boolean
  disabled?: boolean
}>`
  align-items: center;
  background-color: rgba(255, 183, 4, 0.20);
  border-radius: 8px;
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
  box-shadow: ${({ selected }) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')};
  color: #4B2828;
  cursor: pointer;
  height: unset;
  outline: none;
  user-select: none;
  border: none;
  font-size: 21px;
  font-weight: 600;
  width: fit-content;
  padding: 12px 20px;
  gap: 8px;
  justify-content: space-between;
  margin-left: ${({ hideInput }) => (hideInput ? '0' : '12px')};
  &:hover,
  &:active {
    background: #FFB704;
    color: #4B2828;

    img.active-dropdown{
      display: block;
    }
    img.inactive-dropdown{
      display: none;
    }
  }

  &:before {
    background-size: 100%;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    content: '';
  }

  &:hover:before {
  }

  &:active:before {
  }

  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  img.active-dropdown{
    display: none;
  }
  img.inactive-dropdown{
    display: block;
  }
`

const InputRow = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  justify-content: space-between;
  background: rgba(239, 239, 228, 0.1);
`

const LabelRow = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  color: #4B2828;
  font-size: 0.75rem;
  line-height: 1rem;

  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.deprecated_text2)};
  }
`

const FiatRow = styled(LabelRow)`
  justify-content: flex-start;
  min-height: 20px;
  padding: 8px 0px 0px 0px;
  width: 100%;
`

const ToLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 70%;
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const StyledDropDown = styled(DropDown) <{ selected: boolean }>`
  margin: 0 0.25rem 0 0.35rem;
  height: 35%;
  margin-left: 8px;

  /* path {
    stroke: #4B2828;
    stroke-width: 2px;
  }
  :hover {
    stroke: #68fff3;
  }
  :active {
    stroke: #68fff3;
  } */
`

const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? '  margin: 0 0.25rem 0 0.25rem;' : '  margin: 0 0.25rem 0 0.25rem;')}
  font-size: 20px;
  font-weight: 400; 
  &:hover{
    opacity: 0.6;
  }
  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 15px;
  }
`

const StyledBalanceMax = styled.button<{ disabled?: boolean }>`
  background: #00E440;
  border: none;
  color: #4B2828;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
  padding: 4px 6px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background: #00e441b0;
  }

  :focus {
    outline: none;
  }
  span{
    color: #FFF9E1;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 15px */
  }
`

const MaxTokenContent = styled.div<{
  mobileFontSize?: string
}>`
  span{
    text-align: right;
    leading-trim: both;
    text-edge: cap;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 17.5px */
    @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
      font-size: ${({ mobileFontSize }) => mobileFontSize ?? '13px'} !important;
    }
  }
`

const StyledNumericalInput = styled(NumericalInput) <{ $loading: boolean }>`
  height: 40px;
  ${loadingOpacityMixin};
  text-align: left;
  font-size: 40px;
  line-height: 100%;
  font-variant: small-caps;
  padding: 0px;
  color: #4B2828;
  &::placeholder{
    color: #E5B670;
  }
  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 21px;
  }
`
const Text = styled.span`
  color: #4B2828;
  font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 18px */
`

interface SwapCurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: ReactNode
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  fiatValue?: CurrencyAmount<Token> | null
  priceImpact?: Percent
  id: string
  showCommonBases?: boolean
  showCurrencyAmount?: boolean
  disableNonToken?: boolean
  renderBalance?: (amount: CurrencyAmount<Currency>) => ReactNode
  locked?: boolean
  loading?: boolean
  customeFiatValue: number
}

export default function SwapCurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  onCurrencySelect,
  currency,
  otherCurrency,
  id,
  showCommonBases,
  showCurrencyAmount,
  disableNonToken,
  renderBalance,
  fiatValue,
  priceImpact,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  locked = false,
  loading = false,
  customeFiatValue,
  ...rest
}: SwapCurrencyInputPanelProps) {
  const { account, chainId } = useWeb3React()
  const selectChain = useSelectChain()
  const [modalOpen, setModalOpen] = useState(false)
  const [fiatValueIsLoading, setFiatValueIsLoading] = useState(false)
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const theme = useTheme()

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const chainAllowed = true
  const [isSwitchingChain, setIsSwitchingChain] = useState(false)
  const isValidChain = useMemo(() => chainId == DEFAULT_CHAINID, [chainId])

  useEffect(() => {
    !!value && !customeFiatValue ? setFiatValueIsLoading(true) : setFiatValueIsLoading(false)
  }, [fiatValueIsLoading, value, fiatValue])

  return (
    <InputPanel id={id} hideInput={hideInput} {...rest}>
      {locked && (
        <FixedContainer>
          <AutoColumn gap="sm" justify="center">
            <Lock />
            <ThemedText.DeprecatedLabel fontSize="12px" textAlign="center" padding="0 12px">
              <Trans>The market price is outside your specified price range. Single-asset deposit only.</Trans>
            </ThemedText.DeprecatedLabel>
          </AutoColumn>
        </FixedContainer>
      )}
      <Container hideInput={hideInput}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <MaxTokenContent style={{ minWidth: '65px' }} mobileFontSize="16px">
            <Text style={{ fontSize: '18px', fontWeight: 500 }}>
              {
                id == SectionName.CURRENCY_INPUT_PANEL ? 'You pay' : 'You receive'
              }
            </Text>
          </MaxTokenContent>

          {account ? (
            <RowFixed style={{ height: '17px' }}>
              {showMaxButton && selectedCurrencyBalance ? (
                <TraceEvent
                  events={[BrowserEvent.onClick]}
                  name={EventName.SWAP_MAX_TOKEN_AMOUNT_SELECTED}
                  element={ElementName.MAX_TOKEN_AMOUNT_BUTTON}
                >
                  <StyledBalanceMax onClick={onMax}>
                    <span >
                      MAX
                    </span>
                  </StyledBalanceMax>
                </TraceEvent>
              ) : null}
              <MaxTokenContent  >
                {!hideBalance && currency && selectedCurrencyBalance ? (
                  renderBalance ? (
                    renderBalance(selectedCurrencyBalance)
                  ) : (
                    <div>
                      {' '}
                      <span style={{ color: '#E5B670' }}>Balance:</span>{' '}
                      <span style={{ color: '#4B2828' }}>
                        {formatNumber(selectedCurrencyBalance.toSignificant(), 6)} {currency.symbol}
                      </span>
                    </div>
                  )
                ) : null}
              </MaxTokenContent>
            </RowFixed>
          ) : (
            <span />
          )}
        </div>
        <InputRow style={hideInput ? { padding: '0' } : {}}>
          {!hideInput && (
            <StyledNumericalInput
              className="token-amount-input"
              value={value}
              onUserInput={onUserInput}
              disabled={!chainAllowed}
              $loading={loading}
            />
          )}
          <CurrencySelect
            disabled={!chainAllowed}
            visible={currency !== undefined}
            selected={!!currency}
            hideInput={hideInput}
            className="open-currency-select-button"
            onClick={async () => {
              if (account && !isValidChain) {
                try {
                  setIsSwitchingChain(true)
                  await selectChain(SupportedChainId.ZIRCUIT)
                }
                catch (err) {
                }
                finally {
                  setIsSwitchingChain(false)
                }
                return
              }
              if (onCurrencySelect) {
                setModalOpen(true)
              }
            }}
          >
            <Aligner>
              <RowFixed>
                {pair ? (
                  <span style={{ marginRight: '0.5rem' }}>
                    <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
                  </span>
                ) : currency ? (
                  <CurrencyLogo style={{ marginRight: '2px' }} currency={currency} size="24px" />
                ) : null}
                {pair ? (
                  <StyledTokenName className="pair-name-container">
                    {pair?.token0.symbol}:{pair?.token1.symbol}
                  </StyledTokenName>
                ) : (
                  <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                    {(currency && currency.symbol && currency.symbol.length > 20
                      ? currency.symbol.slice(0, 4) +
                      '...' +
                      currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                      : currency?.symbol) ||
                      <Trans>
                        {
                          account && !isValidChain ? isSwitchingChain ? 'Switching...' : 'Switch to Zircuit' : 'Select token'
                        }
                      </Trans>
                    }
                  </StyledTokenName>
                )}
              </RowFixed>
              <img className='inactive-dropdown' style={{ maxWidth: '24px' }} src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/swap/inactive-dropdown.png' alt='gmeow-fi' />
              <img className='active-dropdown' style={{ maxWidth: '24px' }} src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/swap/active-dropdown.png' alt='gmeow-fi' />
            </Aligner>
          </CurrencySelect>
        </InputRow>

        {customeFiatValue > 0 && (
          <FiatRow>
            <ToLeft>
              <RowBetween>
                {/* <LoadingOpacityContainer $loading={loading}>
                  <FiatValue fiatValue={fiatValue} priceImpact={priceImpact} isLoading={fiatValueIsLoading} />
                </LoadingOpacityContainer> */}
                <span className='fiat_value'>
                  ${customeFiatValue < 0.0001 ? customeFiatValue.toFixed(10) : formatNumber(customeFiatValue, 6)}
                </span>
              </RowBetween>
            </ToLeft>
          </FiatRow>
        )}

      </Container>
      {onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
          showCurrencyAmount={showCurrencyAmount}
          disableNonToken={disableNonToken}
        />
      )}
    </InputPanel>
  )
}
