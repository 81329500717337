import styled from "styled-components/macro";
import { TextStyle } from "./Component";
import "../assets/style.css"

const PopupBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    @media (max-width: 768px) {
        align-items: start;
    }
`

const PopupContent = styled.div`
    padding: 32px;
    border-radius: 10px;
    border: 1.5px solid #E5B670;
    background: #FFF6D0;
    box-shadow: 0 7.395px 30px 0 rgba(145, 103, 20, 0.30);
    position: relative;
    width: 573px;
    @media (max-width: 768px) {
        margin-top: 50px;
        width: 336px;
        padding: 20px;
    }
`

const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 22px;
`

const Line = styled.div`
    height: 1px;
    width: 100%;
    background: rgba(229, 182, 112, 0.50);
    backdrop-filter: blur(4.800000190734863px);
    margin: 24px 0;
`

const Table = styled.div`
    border-radius: 10px;
    background: #FFF9E1;
    box-shadow: 0px 4px 20px 0px rgba(145, 103, 20, 0.12);
    padding: 8px 20px;
    @media (max-width: 768px) {
        padding: 0 10px;
    }
`

const TableTH = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-bottom: 18px;
    padding: 0 20px;
    @media (max-width: 768px) {
        padding: 0 10px;
    }
`

const TableItem = styled.div<{
    borderBottom?: string
}>`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    border-bottom: ${props => props.borderBottom};
    padding: 12px 0;
`

// PopupEarnTreat Component
export const PopupPaw = ({ show, onClose }: { show: any, onClose: any }) => {
    if (!show) return null;

    return (
        <PopupBackdrop>
            <PopupContent>
                <img onClick={onClose} className="close-icon" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_14.png" alt="img_14" />
                <TextStyle fontSize="24px"
                    fontFamily="DynaPuff"
                >
                    More PAW, more TREAT
                </TextStyle>
                <DescriptionWrapper>
                    <div className="desc-item">
                        <img className="img-star-yellow" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_15.png" alt="img_15" />
                        <TextStyle>
                            Mint PAW to get your hands on 15% real yield & Zircuit incentives.
                        </TextStyle>
                    </div>
                    <div className="desc-item">
                        <img className="img-star-yellow" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_15.png" alt="img_15" />
                        <TextStyle>
                            Each power of $10 PAW minted will get you to a higher threshold of rewards.
                        </TextStyle>
                    </div>
                    <div className="desc-item">
                        <img className="img-star-yellow" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_15.png" alt="img_15" />
                        <TextStyle>
                            The Leaderboard will be reset and the rewards will be distributed on a weekly basis.
                        </TextStyle>
                    </div>
                </DescriptionWrapper>
                <Line></Line>
                <TableTH>
                    <div className="item">
                        <TextStyle fontSize="12px" fontSizeMb="10px" textTransform="uppercase">Transaction
                            Value</TextStyle>
                    </div>
                    <div className="item">
                        <TextStyle fontSize="12px" fontSizeMb="10px" textTransform="uppercase">treat Earned</TextStyle>
                    </div>
                </TableTH>
                <div className="wrapper-img-invite-friend" style={{ padding: 0, paddingTop: '10px' }}>
                    <div className="img-invite-friend">
                        <img style={{ scale: '1.1' }} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/popups/paw.png" alt="img_16" />
                    </div>
                </div>
            </PopupContent>
        </PopupBackdrop>
    );
};