import styled from 'styled-components'
import { BREAKPOINTS } from 'theme' 
import {useEffect, useState} from "react";
import TextScramble from "./components/TextScarmble";

const WrapperSection2 = styled.section`
    background: #fff9e1;
    width: 100%;
    height: 100%;
    margin: 0px auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`

const WrapperContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 72px;
    width: 1170px;
    padding-bottom: 140px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        padding-bottom: 60px;
        padding-top: 80px;
    }
`

const WrapperTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
`

const WrapperImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 114px;
        height: 80px;
    }
`
const WrapperText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    text-align: center;

    h2 {
        margin: 0px;
        color: #4B2828;
        text-align: center;
        font-family: DynaPuff;
        font-size: 56px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 56px */
        @media screen and (max-width: ${BREAKPOINTS.sm}px){
            font-size: 32px;
        }
    }

    p {
        margin: 0px;
        color: #4B2828;
        text-align: center;
        font-family: Outfit;
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 21px */
        @media screen and (max-width: ${BREAKPOINTS.sm}px){
            font-size: 16px;
        }
    }
`

const WrapperBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    img.box_bg{
        width: 100%; 
        display: none;
    }
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img.box_bg{
            display: none;
        }
    }
`

const Box = styled.div`
    position: relative;
    border-radius: 10px;
    background: #FFF6D0;
    box-shadow: 0px 7.395px 30px 0px rgba(145, 103, 20, 0.30), -6px -6px 4px 0px rgba(215, 144, 60, 0.60) inset, 6px 6px 4px 0px rgba(255, 255, 255, 0.80) inset;
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        width: 575px;
    }
 
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        width: 90%;
    }
`

const WrapperSvg = styled.div`
    width: 575px;
    height: 402px;
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        width: 50%;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        max-width: 400px;
    }
`
const WrapperBoxContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const WrapperSvg3 = styled.div`
    width: 500px;
    height: 402px;
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        width: 575px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        width: 400px;
    }
`
const WrapperBoxContent3 = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const WrapperSvg4 = styled.div`
    width: 650px;
    height: 402px;
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        width: 575px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        width: 400px;
    }
`
const WrapperBoxContent4 = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const WrapperIconBox = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    img{
        width: 232.606px;
        height: 150.911px;
    }
    @media screen and (max-width: ${BREAKPOINTS.xl}px){
        img{
            width: 211.93px;
            height: 137.496px;
        }
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        margin: auto;
        
    }
`

const BoxContent = styled.div`
    padding: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    h2 {
        margin: 0px;
        color: #4B2828;
        font-family: DynaPuff;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 40px */
        @media screen and (max-width: ${BREAKPOINTS.sm}px){
            font-size: 24px;
        }
    }

    p {
        margin: 0px;
        color: #93553A;
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 22.5px */
        @media screen and (max-width: ${BREAKPOINTS.sm}px){
            font-size: 16px;
        }
    }
`
const WrapperCloudLeft = styled.div`
    position: absolute;
    left: 0;
    top: 55%;
    height: 300px;
    z-index: 1;
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        display: none;
    }
`

const WrapperCloudRight = styled.div`
    position: absolute;
    right: 0;
    top: 26%;
    height: 300px;
    z-index: 1;
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        display: none;
    }
`
export default function Section1() {

    const phrases = [
        'Yield Strategies <br /> with a Purr-spective'
    ];

    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index == phrases.length - 1) {
            return;
        }

        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        }, 2000);

        return () => clearInterval(interval);
    }, [index, phrases]);

    return (
        <>
            <WrapperSection2>
                <WrapperContent>
                    <WrapperTitle>
                        <WrapperImage>
                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/cat_icon.png" alt="cat_icon" />
                        </WrapperImage>
                        <WrapperText>
                            <TextScramble text={phrases[index]}/>
                            <p>Dive into a world of diverse yield-generating <br /> opportunities tailored to fit every
                                breed of trader</p>
                        </WrapperText>
                    </WrapperTitle>
                    <WrapperBox>
                        <Box>
                            <WrapperSvg>
                                <img className='box_bg' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/box.svg" alt="box" />
                            </WrapperSvg>
                            <WrapperBoxContent>
                                <BoxContent>
                                    <ContentBox>
                                        <h2>Fur-tastic Trades</h2>
                                        <p>Execute trades with lightning-fast cattitude, utilizing our purr-optimized
                                            algorithm for the most meow-vellous rates.</p>
                                    </ContentBox>
                                    <WrapperIconBox>
                                        <img width={'100%'} height={'100%'} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section1/icon_box1.png"
                                            alt="icon_box1" />
                                    </WrapperIconBox>
                                </BoxContent>
                            </WrapperBoxContent>
                        </Box>

                        <Box>
                            <WrapperSvg>
                                <img className='box_bg' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/box.svg" alt="box" />
                            </WrapperSvg>
                            <WrapperBoxContent>
                                <BoxContent>
                                    <ContentBox>
                                        <h2>Liquidity Purrse</h2>
                                        <p>Dive into the liquidity pool and earn PAW rewards. More liquidity, more
                                            purr-ower to the playful paws!</p>
                                    </ContentBox>
                                    <WrapperIconBox>
                                        <img width={'100%'} height={'100%'} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section1/icon_box2.png"
                                            alt="icon_box2" />
                                    </WrapperIconBox>
                                </BoxContent>
                            </WrapperBoxContent>
                        </Box>

                        <Box>
                            <WrapperSvg3>
                                <img className='box_bg' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/box_smaller.svg" alt="box" />
                            </WrapperSvg3>
                            <WrapperBoxContent3>
                                <BoxContent>
                                    <ContentBox>
                                        <h2>Purr-lific Staking</h2>
                                        <p>Stake your GM and reel in xGM, unlocking exclusive purrks and maximizing your
                                            earning paw-tential.</p>
                                    </ContentBox>
                                    <WrapperIconBox>
                                        <img width={'100%'} height={'100%'} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section1/icon_box3.png"
                                            alt="icon_box3" />
                                    </WrapperIconBox>

                                </BoxContent>
                            </WrapperBoxContent3>
                        </Box>

                        <Box>
                            <WrapperSvg4>
                                <img className='box_bg' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/box_larger.svg" alt="box" />
                            </WrapperSvg4>
                            <WrapperBoxContent4>
                                <BoxContent>
                                    <ContentBox>
                                        <h2>Catnap Grow Vaults</h2>
                                        <p>Our auto-compounding vaults work like tireless Tomcats, ensuring your assets
                                            flourish while you enjoy a cozy catnap.</p>
                                    </ContentBox>
                                    <WrapperIconBox>
                                        <img width={'100%'} height={'100%'} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section1/icon_box4.png"
                                            alt="icon_box4" />
                                    </WrapperIconBox>
                                </BoxContent>
                            </WrapperBoxContent4>
                        </Box>

                    </WrapperBox>
                </WrapperContent>
                < WrapperCloudLeft>
                    <img height={'100%'} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section1/img.png" alt="cloud-left" />
                </ WrapperCloudLeft>
                < WrapperCloudRight>
                    <img height={'100%'} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section1/img_1.png" alt="cloud-right" />
                </ WrapperCloudRight>
            </WrapperSection2>
        </>
    )
}
