import styled from "styled-components/macro";
import { TextOutline, TextStyle } from "./Components";
import "../assets/style.css"
import { BREAKPOINTS } from "../../../theme";
import { useMemo } from "react";
import { formatNumber } from "utils/formatNumbers";
import { NotchedButtonFill } from "components/Button";

const PopupBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.70);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    @media (max-width: ${BREAKPOINTS.md}px) {
        align-items: center;
    }
`

const PopupContent = styled.div`
    padding: 32px;
    border-radius: 10px;
    border: 1.5px solid #E5B670;
    background: #FFF6D0;
    box-shadow: 0 7.395px 30px 0 rgba(145, 103, 20, 0.30);
    position: relative;
    width: 573px;
    max-height: 95vh;
    overflow-y: auto;
    @media (max-width: ${BREAKPOINTS.md}px) {
        margin-top: 20px;
        width: 336px;
        padding: 20px;
    }

    .title-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
`

const InformationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 48px;
    margin-top: 72px;
`
const InformationInner = styled.div`
    display: flex;
    width: 100%;
    gap: 9px;
`
const InformationItem = styled.div`
    border-radius: 10px;
    background: #FFF9E1;
    position: relative;
    width: 100%;
    padding: 60px 0 24px 0;
    display: flex;
    justify-content: center;

    .img-wrapper {
        width: 100px;
        height: 100px;
        position: absolute;
        top: -35%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
        }

        @media (max-width: ${BREAKPOINTS.md}px) {
            width: 80px;
            height: 80px;
        }
    }
`

const Table = styled.div`
    height: 215px;
    overflow: auto;
    margin-top: 32px;
    border-radius: 10px;
    background: #FFF9E1;
    box-shadow: 0 4px 20px 0 rgba(145, 103, 20, 0.12);
    padding: 8px 20px;
    @media (max-width: ${BREAKPOINTS.md}px) {
        padding: 8px 10px;
    }

    .see-all-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        padding: 12px 0;

        img {
            width: 18px;
            height: 18px;
        }
    }
`

const TableItem = styled.div<{
    borderBottom?: string
}>`
    display: flex;
    justify-content: space-between;
    gap: 12px;
    /* grid-template-columns: repeat(5, minmax(0, 1fr)); */
    border-bottom: ${props => props.borderBottom};
    padding: 12px 0;

    .item-2 {
        display: flex;
        justify-content: center;
        align-items: end;
        gap: 6px;
        @media (max-width: ${BREAKPOINTS.md}px) {
            align-items: center;
        }

        img {
            width: 18px;
            height: 18px;
            @media (max-width: ${BREAKPOINTS.md}px) {
                width: 14px;
                height: 14px;
            }
        }
    }
`

export type RewardListType = {
    totalPaw: number
    totalXgm: number
    totalTicket: number
    totalNft: number
}

// PopupEarnTreat Component
export const PopupGetMultipleBoxSuccess = ({ show, onClose, rewardList, txHash, title }: {
    show: any, onClose: any, rewardList: RewardListType[], txHash: string, title: string

}) => {
    if (!show) return null;

    const totalPaw = useMemo(() => rewardList.reduce((prev, rw) => prev + rw.totalPaw, 0), [rewardList])
    const totalXgm = useMemo(() => rewardList.reduce((prev, rw) => prev + rw.totalXgm, 0), [rewardList])
    const totalTicket = useMemo(() => rewardList.reduce((prev, rw) => prev + rw.totalTicket, 0), [rewardList])
    const totalNft = useMemo(() => rewardList.reduce((prev, rw) => prev + rw.totalNft, 0), [rewardList])
    return (
        <PopupBackdrop>
            <PopupContent>
                <img onClick={onClose} className="close-icon" src="/treat/img_14.png" alt="img_14" />
                <div className="title-wrapper">
                    <TextStyle fontSize="24px"
                        fontSizeLg="24px"
                        fontSizeMb="20px"
                        fontFamily="DynaPuff"
                        textAlign="center"
                        textAlignMb="center"
                    >
                        Wow! Congratulations!
                    </TextStyle>
                    <TextOutline fontSize="32px" fontSizeLg="22px"
                        fontSizeMb="18px" strokeWidth="2px" strokeWidthMb="1px">
                        {title}
                    </TextOutline>
                </div>

                <InformationWrapper>
                    <InformationInner>
                        <InformationItem>
                            <div className="img-wrapper">
                                <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/eth.png" alt="img_1" />
                            </div>
                            <TextStyle fontSize="21px"
                                fontSizeLg="18px"
                                fontSizeMb="16px"
                                fontWeight="600"
                                lineHeight="125%"
                                textAlign="center"
                            >
                                {totalPaw.toFixed(5)} <span style={{ color: "rgba(75, 40, 40, 0.50)", fontFamily: "Outfit" }}>WETH</span>
                            </TextStyle>
                        </InformationItem>
                        <InformationItem>
                            <div className="img-wrapper">
                                <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_3.png" alt="img_3" />
                            </div>
                            <TextStyle fontSize="21px"
                                fontSizeLg="18px"
                                fontSizeMb="16px"
                                fontWeight="600"
                                lineHeight="125%"
                                textAlign="center"
                            >
                                {formatNumber(totalXgm)} <span style={{ color: "rgba(75, 40, 40, 0.50)", fontFamily: "Outfit" }}>xGM</span>
                            </TextStyle>
                        </InformationItem>
                    </InformationInner>
                    <InformationInner>
                        <InformationItem>
                            <div className="img-wrapper">
                                <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_2.png" alt="img_2" />
                            </div>
                            <TextStyle fontSize="21px"
                                fontSizeLg="18px"
                                fontSizeMb="16px"
                                fontWeight="600"
                                lineHeight="125%"
                                textAlign="center"
                            >
                                {formatNumber(totalNft)} <span style={{ color: "rgba(75, 40, 40, 0.50)", fontFamily: "Outfit" }}>NFT{totalNft > 1 ? 's' : ''}</span>
                            </TextStyle>
                        </InformationItem>
                        <InformationItem>
                            <div className="img-wrapper">
                                <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_4.png" alt="img_4" />
                            </div>
                            <TextStyle fontSize="21px"
                                fontSizeLg="18px"
                                fontSizeMb="16px"
                                fontWeight="600"
                                lineHeight="125%"
                                textAlign="center"
                            >
                                {formatNumber(totalTicket)} <span style={{ color: "rgba(75, 40, 40, 0.50)", fontFamily: "Outfit" }}>Ticket{totalTicket > 1 ? 's' : ''}</span>
                            </TextStyle>
                        </InformationItem>
                    </InformationInner>
                </InformationWrapper>
                {
                    rewardList.length > 1 && <Table>
                        {rewardList.map((item, index) => {
                            return (
                                <TableItem>
                                    <div className="item">
                                        <TextStyle fontSizeMb="12px">Box #{index + 1}</TextStyle>
                                    </div>
                                    <div className="item-2">
                                        <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/eth.png" alt="img_1" />
                                        <TextStyle fontSizeMb="10px">
                                            {item.totalPaw.toFixed(5)} <span
                                                style={{ color: "rgba(75, 40, 40, 0.50)", fontFamily: "Outfit" }}>WETH</span>
                                        </TextStyle>
                                    </div>
                                    <div className="item-2">
                                        <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupGetMulBoxSuccess/img_2.png" alt="img_2" />
                                        <TextStyle fontSizeMb="10px">
                                            {formatNumber(item.totalXgm)} <span
                                                style={{ color: "rgba(75, 40, 40, 0.50)", fontFamily: "Outfit" }}>xGM</span>
                                        </TextStyle>
                                    </div>
                                    <div className="item-2">
                                        <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupGetMulBoxSuccess/img_4.png" alt="img_4" />
                                        <TextStyle fontSizeMb="10px">
                                            {formatNumber(item.totalNft)} <span
                                                style={{ color: "rgba(75, 40, 40, 0.50)", fontFamily: "Outfit" }}>NFT</span>
                                        </TextStyle>
                                    </div>
                                    <div className="item-2">
                                        <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupGetMulBoxSuccess/img_5.png" alt="img_5" />
                                        <TextStyle fontSizeMb="10px">
                                            {formatNumber(item.totalTicket)} <span
                                                style={{ color: "rgba(75, 40, 40, 0.50)", fontFamily: "Outfit" }}>Ticket</span>
                                        </TextStyle>
                                    </div>
                                </TableItem>
                            )
                        })
                        }
                        {/* <div className="see-all-wrapper">
                            <TextStyle color="#DFA003" fontSize="14px" style={{ textDecorationLine: "underline", cursor: 'pointer' }} >
                                View tx
                            </TextStyle>
                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupGetMulBoxSuccess/img_6.png" alt="img_6" />
                        </div> */}
                    </Table>
                }

                <div style={{ marginTop: '24px' }}>
                    <NotchedButtonFill onClick={onClose}
                        isShowDirectly={true}
                        padding="16px 0"
                        bg="#00E440"
                        earMaxW="170px">
                        <TextStyle color="#FFF9E1"
                            fontFamily="DynaPuff"
                            fontSize="18px"
                            textTransform="uppercase">
                            yayyy!
                        </TextStyle>
                    </NotchedButtonFill>
                </div>

            </PopupContent>
        </PopupBackdrop>
    );
};
