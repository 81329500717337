import { Trace } from '@uniswap/analytics'
import { PageName } from '@uniswap/analytics-events'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ButtonText } from 'theme'
import { Link, useNavigate } from 'react-router-dom'
import { ExternalLink } from 'theme'
import { Box } from 'rebass'

const Footers = styled.div`
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 56px;
    margin: auto;
    width: 100%;
    min-height: 545px;
    position: relative;

    .footer_bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .footer_mobile_bg {
        display: none;
        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
            display: block !important;
        }
    }

    .menu_box {
        gap: 32px;
        position: relative;
        z-index: 2;
        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
            gap: 16px;
        }
    }

    @media screen and (max-width: 1700px) {
        min-height: 450px;
    }

    @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
        /* min-height: unset; */
        gap: 32px;
    }

    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        height: fit-content;
        gap: 32px;
        background-image: none;
    }
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        max-width: 700px;
        width: unset;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 400px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        max-width: 100%;
        height: unset;
        min-height: 250px;
        gap: 24px;
        padding-top: 24px;
        .footer_bg {
            display: none;
        }
    }
`

const LogoImg = styled.img`
    width: 256px;
    height: 56px;
    margin: 0;
    cursor: pointer;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        width: 152px;
        height: 30px;
    }
`

const SocialBox = styled.div`
    display: flex;
    gap: 20px;

`
const SocialImg = styled.img`
    width: 45px;
    height: 40px;
    cursor: pointer;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        width: 24px;
        height: 24px;
    }
`

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        gap: 16px;
    }
`

const FooterSeparator = styled.div`
    width: 100%;
    max-width: 972px;
    height: 1px;
    background: #ECC487;
    position: relative;
    z-index: 2;
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        max-width: 300px;
    }
`
const RightCopy = styled.h4`
    color: #4B2828;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    margin: 0;
`
const MenuBox = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 16px;
    column-gap: 50px;
    height: 64px;

    a {
        color: #4B2828;
    }

    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        column-gap: 40px;
        margin-bottom: 40px;
        grid-template-columns: 30% 30%;
        justify-content: center;
        margin: 10px 0;
        text-align: center;
        min-width: 250px;
    }
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        height: unset;
        grid-template-columns: auto auto auto auto;
        justify-content: space-evenly;
        padding-bottom: 0;
        margin-bottom: 0;
        min-width: 320px;
    }
`
const MenuItem = styled.div` 
    cursor: pointer;
    height: fit-content;
    a{
        color: #4B2828;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        text-decoration: unset;
        @media screen and (max-width: ${BREAKPOINTS.md}px) {
            font-size: 14px;
            font-weight: 400;
        }
    }
`


export default function Footer() {
    const navigate = useNavigate()
    return (
        <>
            <Footers>
                <img className='footer_bg' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/footer-bg.png" alt="gmeowfi" />
                <img className='footer_bg footer_mobile_bg' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/footer-mobile-bg.png" alt="gmeowfi" />
                <ContentBox>
                    <LogoImg
                        src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/common/site-logo.png"
                        alt="gmeow"
                        onClick={() => navigate('/home')}
                    />
                    <RightCopy>© 2024 Gmeow. All rights reserved.</RightCopy>
                </ContentBox>

                <FooterSeparator />

                <Box className='menu_box' display="grid" justifyItems="center">
                    <SocialBox>
                        <ExternalLink href="https://x.com/gmeowfi">
                            <SocialImg
                                src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/footer/x-sns.png"
                                alt="gmeow"
                            />
                        </ExternalLink>
                        <ExternalLink href="https://discord.com/invite/QcTmqgaUK6">
                            <SocialImg
                                src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/footer/discord-sns.png"
                                alt="gmeow"
                            />
                        </ExternalLink>
                        <ExternalLink href="https://t.me/gmeowfi">
                            <SocialImg
                                src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/footer/tele-sns.png"
                                alt="gmeow"
                            />
                        </ExternalLink> 
                    </SocialBox>

                    <MenuBox>
                        <MenuItem>
                            <Link to="/swap">
                                Trade
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <ExternalLink href="https://gmeow.info/">Links</ExternalLink>
                        </MenuItem>
                        <MenuItem>
                            <ExternalLink href="https://docs.gmeow.fi/">Docs</ExternalLink>
                        </MenuItem>
                        <MenuItem>
                            <ExternalLink href="https://bit.ly/gmeow">Media Kit</ExternalLink>
                        </MenuItem>
                    </MenuBox>
                </Box>
            </Footers>
        </>
    )
}
