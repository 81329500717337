import { Currency, Ether, NativeCurrency, Token, WETH9 } from '@uniswap/sdk-core'
import invariant from 'tiny-invariant'

import { UNI_ADDRESS } from './addresses'
import { SupportedChainId } from './chains'

export const NATIVE_CHAIN_ID = 'NATIVE'

// When decimals are not specified for an ERC20 token
// use default ERC20 token decimals as specified here:
// https://docs.openzeppelin.com/contracts/3.x/erc20
export const DEFAULT_ERC20_DECIMALS = 18

export const USDC_MAINNET = new Token(
  SupportedChainId.MAINNET,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USD//C'
)
export const DAI = new Token(
  SupportedChainId.MAINNET,
  '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const USDT = new Token(
  SupportedChainId.MAINNET,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD'
)

// ZIRCUIT Testnet
export const WETH_ZIRCUIT_TESTNET = new Token(
  SupportedChainId.ZIRCUIT_TESTNET,
  '0x4200000000000000000000000000000000000006',
  18,
  'WETH',
  'Wrapped Ether'
)

export const USDC_ZIRCUIT_TESTNET = new Token(
  SupportedChainId.ZIRCUIT_TESTNET,
  '0xd35f0a4C44A12E6f6D50c0f6a511ADCE35f2CF63',
  6,
  'USDC',
  'USD Coin'
)

export const USDT_ZIRCUIT_TESTNET = new Token(
  SupportedChainId.ZIRCUIT_TESTNET,
  '0x2b533475875b518144c2Cf8558d799BC6FB11716',
  6,
  'USDT',
  'Tether'
)

export const BTC_ZIRCUIT_TESTNET = new Token(
  SupportedChainId.ZIRCUIT_TESTNET,
  '0x0C8C73C735C9768e0911B7152F3f88Fffcab5Ea4',
  8,
  'BTC',
  'Bitcoin'
)

export const PAW_ZIRCUIT_TESTNET = new Token(
  SupportedChainId.ZIRCUIT_TESTNET,
  '0x156563d6bA40F03480A40AEa7F91392F7974C9F9',
  6,
  'PAW',
  'PAW'
)

// blast

// Zircuit mainnet
export const WETH_ZIRCUIT = new Token(
  SupportedChainId.ZIRCUIT,
  '0x4200000000000000000000000000000000000006',
  18,
  'WETH',
  'Wrapped Ether'
)

export const MAID_ZIRCUIT = new Token(
  SupportedChainId.ZIRCUIT,
  '0xC03EA4A8fbA397C48695F1AcFBAdFd9528C3CF7A',
  18,
  'MAID',
  'Maid Token'
)

export const xGM_ZIRCUIT = new Token(
  SupportedChainId.ZIRCUIT,
  '0x91041fdaf58254aba28dAd32F548D4A466FE0E65',
  18,
  'xGM',
  'xGM'
)

export const ZIRCUIT_TOKEN = new Token(
  SupportedChainId.ZIRCUIT,
  '0xfd418e42783382E86Ae91e445406600Ba144D162',
  18,
  'ZRC',
  'Zircuit'
)


export const UNI: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(SupportedChainId.MAINNET, UNI_ADDRESS[1], 18, 'UNI', 'Uniswap'),
}

export const WRAPPED_NATIVE_CURRENCY: { [chainId: number]: Token | undefined } = {
  ...(WETH9 as Record<SupportedChainId, Token>),
  [SupportedChainId.ZIRCUIT_TESTNET]: WETH_ZIRCUIT_TESTNET,
  [SupportedChainId.ZIRCUIT]: WETH_ZIRCUIT,
}

export function isCelo(chainId: number) {
  return false
}

export function isZircuit(chainId: number): chainId is SupportedChainId.ZIRCUIT {
  return chainId === SupportedChainId.ZIRCUIT
}

function isMatic(chainId: number) {
  return false
}

class MaticNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId
  }

  get wrapped(): Token {
    if (!isMatic(this.chainId)) throw new Error('Not matic')
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    invariant(wrapped instanceof Token)
    return wrapped
  }

  public constructor(chainId: number) {
    if (!isMatic(chainId)) throw new Error('Not matic')
    super(chainId, 18, 'MATIC', 'Polygon Matic')
  }
}

class ExtendedEther extends Ether {
  public get wrapped(): Token {
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    if (wrapped) return wrapped
    throw new Error('Unsupported chain ID')
  }

  private static _cachedExtendedEther: { [chainId: number]: NativeCurrency } = {}

  public static onChain(chainId: number): ExtendedEther {
    return this._cachedExtendedEther[chainId] ?? (this._cachedExtendedEther[chainId] = new ExtendedEther(chainId))
  }
}

const cachedNativeCurrency: { [chainId: number]: NativeCurrency | Token } = {}
export function nativeOnChain(chainId: number): NativeCurrency | Token {
  if (cachedNativeCurrency[chainId]) return cachedNativeCurrency[chainId]
  let nativeCurrency: NativeCurrency | Token
  if (isMatic(chainId)) {
    nativeCurrency = new MaticNativeCurrency(chainId)
  } else {
    nativeCurrency = ExtendedEther.onChain(chainId)
  }
  return (cachedNativeCurrency[chainId] = nativeCurrency)
}

export const TOKEN_SHORTHANDS: { [shorthand: string]: { [chainId in SupportedChainId]?: string } } = {
  USDC: {
    [SupportedChainId.MAINNET]: USDC_MAINNET.address,
    [SupportedChainId.ZIRCUIT_TESTNET]: USDC_ZIRCUIT_TESTNET.address,
  },
}
