import styled from "styled-components"
import { BREAKPOINTS } from "theme"


const PawBottomSectionWrapper = styled.div`
    width: 100%;
    max-width: 1170px;
    margin: auto;

    padding-bottom: 400px;
    h5{
        color: #4B2828;
        font-family: DynaPuff;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 40px */
        margin: 0;
    }
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        max-width: 95%;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        padding-bottom: 200px;
        h5{
            font-size: 24px;
        }
    }
`

const CardList = styled.div`
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-top: 24px;
    position: relative;

    div.bottom_main{
        position: absolute;
        left: 0;
        bottom: -110%;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        flex-direction: column;
        align-items: center;
        justify-items: center;
        div.bottom_main{
            bottom: -35%;
            img{
                max-width: 320px;
            }
        }
    }
`

const CardBox = styled.div`
    border-radius: 10px;
    background: #FAEEBD;
    padding: 24px;
    width: 100%;
    max-width: 374px;
    .img_box{
        height: 120px;
        display: flex;
        justify-content: center;
        img{
            height: 100%;
        }
    }
`

const CardBody = styled.div`
    display: grid;
    gap: 12px;
    margin-top: 32px;
    h4{
        color: #4B2828;
        font-family: DynaPuff;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 24px */
        margin: 0;
    }
    h5{
        color: #93553A;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 20px */
        margin: 0;
    }
`


export default function PawBottomSection() {

    return <PawBottomSectionWrapper>
        <h5>
            The Meowgic in PAW
        </h5>

        <CardList>
            <CardBox>
                <div className="img_box">
                    <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/paw/bottom_img1.png" alt="gmeowfi" />
                </div>
                <CardBody>
                    <h4>Consistent Price Growth</h4>
                    <h5>Every time you see PAW, its value increases. Watch your investment grow steadily!</h5>
                </CardBody>
            </CardBox>
        </CardList>

        <CardList>
            <CardBox style={{ paddingBottom: '65px' }}> 
                <div className="img_box">
                    <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/paw/bottom_img2.png" alt="gmeowfi" />
                </div>
                <CardBody>
                    <h4>Top-Notch Native Yield</h4>
                    <h5>Thanks to the stellar Native Yield from Gmeow's protocol earnings, your investment is in great hands!</h5>
                </CardBody>
            </CardBox>
            <CardBox style={{ paddingBottom: '65px' }}>
                <div className="img_box">
                    <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/paw/bottom_img3.png" alt="gmeowfi" />
                </div>
                <CardBody>
                    <h4>Rock-Solid Security</h4>
                    <h5>The PAW carousel is over-collateralized and fully supported by USDT. Rest assured, your PAW is safe and sound!</h5>
                </CardBody>
            </CardBox>

            <div className="bottom_main">
                <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/paw/bottom_main_img.png" alt="gmeowfi" />
            </div>
        </CardList>

    </PawBottomSectionWrapper>
}