import { useWeb3React } from "@web3-react/core"
import { NotchedButtonFill } from "components/Button"
import { CloseEnterCodePopupIcon, EnterCodePopup, EnterCodePopupBody, EnterCodePopupButtonBox, EnterCodePopupInput, EnterCodePopupInputBox, EnterCodePopupInputList, EnterCodePopupMessage, EnterCodePopupStep1, EnterCodePopupTitle } from "pages/MonoPass/testnest"
import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import { GMEOW_API_BASE_URL } from "utils/env"
import Modal from "components/Modal"
import { notification } from "antd"







export default function TreatRefcodePopup() {
    const { account, provider } = useWeb3React()
    const pathParams = useParams()

    const [isEnteringRefcode, setIsEnteringRefcode] = useState<boolean>(false)
    const [isOpenSignModal, setIsOpenSignModal] = useState<boolean>(false);
    const [refCode, setRefCode] = useState<string>('');
    const [enterRefcodeErr, setEnterRefcodeErr] = useState<string>('');
    const onClickSignMess = async () => {
        if (isEnteringRefcode) return;
        setIsEnteringRefcode(true);
        try {
            if (pathParams.id && provider && provider.provider && account) {
                const refCode = pathParams?.id?.split('=')[1];
                if (refCode.length < 6) return;

                const signMess = refCode.toLowerCase();

                const signature = await provider.send('personal_sign', [signMess, account]);
                await axios.post(`${GMEOW_API_BASE_URL}/airdrop/referral`, {
                    "address": account,
                    "referralBy": refCode,
                    signature
                });
                setIsOpenSignModal(false);
                notification.success({
                    message: 'Success'
                })
            }
        } catch (err) {
            console.error('Failed to sign mess', err);
            setEnterRefcodeErr('Invalid invitation code')
        }
        finally {
            setIsEnteringRefcode(false)
        }
    }
    const hasEnteredRef = async () => {
        const refCheck = await axios.get(`${GMEOW_API_BASE_URL}/leaderboard/has-entered-ref?address=${account}`);
        if (!refCheck.data)
            setIsOpenSignModal(true);
    }

    const refCodeList = useMemo(() => refCode.split(''), [refCode])
    useEffect(() => {
        if (pathParams.id && account) {
            const refCode = pathParams?.id?.split('=')[1];
            if (refCode.length < 6) return;
            setRefCode(refCode);
            hasEnteredRef()
        }
    }, [account])

    return <Modal isOpen={isOpenSignModal} onDismiss={() => setIsOpenSignModal(false)}>

        <EnterCodePopup>
            <EnterCodePopupBody>

                <CloseEnterCodePopupIcon onClick={() => setIsOpenSignModal(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                        stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </CloseEnterCodePopupIcon>

                <EnterCodePopupStep1>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ maxWidth: "392px" }}>
                            <EnterCodePopupTitle>
                                You received an invite to GMEOW
                            </EnterCodePopupTitle>
                        </div>
                    </div>

                    <EnterCodePopupInputList>
                        {
                            refCodeList.map((_, index) => <EnterCodePopupInputBox key={index}>
                                <EnterCodePopupInput className="inputCode" value={_} maxLength={1} isValidInputCode={false} readOnly />
                            </EnterCodePopupInputBox>
                            )
                        }
                    </EnterCodePopupInputList>

                    <EnterCodePopupMessage>{enterRefcodeErr}</EnterCodePopupMessage>

                    <EnterCodePopupButtonBox>
                        <NotchedButtonFill onClick={onClickSignMess}>
                            {isEnteringRefcode ? 'CONFIRMING...' : 'CONFIRM'}
                        </NotchedButtonFill>
                    </EnterCodePopupButtonBox>

                </EnterCodePopupStep1>
            </EnterCodePopupBody>

        </EnterCodePopup>
    </Modal>
}