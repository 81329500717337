import { useEffect, useState } from "react"




export default function useEthPrice() {
    // @ts-ignore
    const [ethPrice, setEthPrice] = useState<number>(window?.ethPrice ?? 0)

    useEffect(() => {
        // @ts-ignore
        if (window?.ethPrice) return
        fetch('https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT')
            .then(res => res.json())
            .then(data => {
                // @ts-ignore
                window.ethPrice = Number(data.price)
                setEthPrice(Number(data.price))
            })
    }, [])
    return ethPrice
}