import { Trans } from '@lingui/macro'
import { Trace, TraceEvent } from '@uniswap/analytics'
import { BrowserEvent, ElementName, EventName, PageName } from '@uniswap/analytics-events'
import { useWeb3React } from '@web3-react/core'
import { ButtonText, NotchedButtonFill } from 'components/Button'
import { AutoColumn } from 'components/Column'
import PositionList from 'components/PositionList'
import { DEFAULT_CHAINID, SupportedChainId } from 'constants/chains'
import { useV3Positions } from 'hooks/useV3Positions'
import { BookOpen, ChevronsRight, Inbox, Layers, PlusCircle } from 'react-feather'
import { Link, useNavigate } from 'react-router-dom'
import { useToggleWalletModal } from 'state/application/hooks'
import { useUserHideClosedPositions } from 'state/user/hooks'
import styled, { css, useTheme } from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { PositionDetails } from 'types/position'
import './pool.css'
import { V2_FACTORY_ADDRESSES } from '../../constants/addresses'
import { LoadingRows } from './styleds'
import { NotchedButtonBolderNew } from 'components/Button/NotchedButton'

// v2 pools
import V2Pool from '../Pool/v2'
import { useMemo, useState } from 'react'
import Toggle from 'components/Toggle'

const PageWrapper = styled.div`
    padding: 68px 0px 0px;
    width: 80%;
    display: flex;
    @media (max-width: 1028px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    @media (max-width: 768px) {
        width: 95%;
    }
`
const ButtonAddPosition = styled.div`
    border-radius: 10px;
    border: 1.5px dashed #D7903C;
    background: #FFF0CA;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-decoration: none !important;
    min-width: 200px;
    p {
        color: #4B2828;
        text-align: center;
        font-family: DynaPuff;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 18px */
        text-transform: uppercase;
        margin: 20px 0;
    }

    &:hover {
        cursor: pointer;
        opacity: ${({ theme }) => theme.opacity.hover};
    }
`

const PoolMenuItem = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 500;

    :hover {
        color: #4B2828;
        cursor: pointer;
        text-decoration: none;
    }
`

const ErrorContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    max-width: 300px;
    min-height: 25vh;
    color: #4B2828;
`

const IconStyle = css`
    width: 48px;
    height: 48px;
    margin-bottom: 0.5rem;
`
const InboxIcon = styled(Inbox)`
    ${IconStyle}
`
const MainContentWrapper = styled.main`
    background: transparent;
    //border: 1px solid rgba(239, 239, 228, 0.2);
    padding: 0;
    display: flex;
    gap: 20px;
    flex-direction: column;
    //box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    //0px 24px 32px rgba(0, 0, 0, 0.01);
`

const PositionInfo = styled.div`  
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
        color: #4B2828;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 17.5px */
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        flex-direction: column-reverse;
        gap: 12px;
        align-items: start;
        span{
            font-size: 14px;
        }
    }
`

const PositionInfoToggle = styled.div`
    display: flex;
    align-items: center;
    #toggle-optimized-router-button{
        scale: 0.8;
    }
`

function PositionsLoadingPlaceholder() {
    return (
        <LoadingRows>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
        </LoadingRows>
    )
}

export default function Pool() {
    const { account, chainId } = useWeb3React()
    const navigate = useNavigate()
    const toggleWalletModal = useToggleWalletModal()
    const [tabSelector, setTabSelector] = useState(1)

    const theme = useTheme()
    const [userHideClosedPositions, setUserHideClosedPositions] = useUserHideClosedPositions()

    const { positions, loading: positionsLoading } = useV3Positions(account)

    const isValidChainId = useMemo(() => chainId == DEFAULT_CHAINID, [chainId])

    // if (chainId !== SupportedChainId.ZIRCUIT_TESTNET && chainId !== SupportedChainId.ZIRCUIT_TESTNET) {
    //     return <WrongNetworkCard />;
    // }

    // if (!isSupportedChain(chainId)) {
    //     return <WrongNetworkCard />
    // }

    const [openPositions, closedPositions] = positions?.reduce<[PositionDetails[], PositionDetails[]]>(
        (acc, p) => {
            acc[p.liquidity?.isZero() ? 1 : 0].push(p)
            return acc
        },
        [[], []]
    ) ?? [[], []]

    const filteredPositions = [...openPositions, ...(userHideClosedPositions ? [] : closedPositions)]
    const showConnectAWallet = Boolean(!account)
    const showV2Features = Boolean(V2_FACTORY_ADDRESSES[SupportedChainId.ZIRCUIT])

    const menuItems = [
        {
            content: (
                <PoolMenuItem>
                    <Trans>Create a pool</Trans>
                    <PlusCircle size={16} />
                </PoolMenuItem>
            ),
            link: '/add/ETH',
            external: false,
        },
        {
            content: (
                <PoolMenuItem>
                    <Trans>Migrate</Trans>
                    <ChevronsRight size={16} />
                </PoolMenuItem>
            ),
            link: '/migrate/v2',
            external: false,
        },
        {
            content: (
                <PoolMenuItem>
                    <Trans>V2 liquidity</Trans>
                    <Layers size={16} />
                </PoolMenuItem>
            ),
            link: '/pool',
            external: false,
        },
        {
            content: (
                <PoolMenuItem>
                    <Trans>Learn</Trans>
                    <BookOpen size={16} />
                </PoolMenuItem>
            ),
            link: 'https://docs.gmeow.fi/',
            external: true,
        },
    ]

    return (
        <Trace page={PageName.POOL_PAGE} shouldLogImpression>
            <>
                <PageWrapper>
                    <div className="tab-pool-selector">
                        <NotchedButtonBolderNew cursor={`pointer`}>
                            <div
                                className={`wrapper-display-style tab-pool-selector-text`}
                                onClick={() => {
                                    navigate('/add')
                                }}
                            >
                                <div className={`wrapper-tab-pool-selector-text`}>
                                    <div style={{ width: "24px", height: "24px" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24"
                                            fill="none">
                                            <g clipPath="url(#clip0_160_1026)">
                                                <path
                                                    d="M6.80005 11C6.27416 11.9122 5.99756 12.9467 5.99805 13.9996C5.99853 15.0525 6.27608 16.0867 6.80282 16.9984C7.32955 17.9101 8.08692 18.6671 8.99885 19.1934C9.91077 19.7197 10.9451 19.9968 11.998 19.9968C13.051 19.9968 14.0853 19.7197 14.9972 19.1934C15.9092 18.6671 16.6665 17.9101 17.1933 16.9984C17.72 16.0867 17.9976 15.0525 17.998 13.9996C17.9985 12.9467 17.7219 11.9122 17.196 11L11.999 3L6.79905 11H6.80005Z"
                                                    stroke="#A59185" strokeWidth="2" strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_160_1026">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <p>Add Liquidity</p>
                                </div>
                            </div>
                        </NotchedButtonBolderNew>

                        <NotchedButtonBolderNew cursor={`pointer`}>
                            <div
                                className={`wrapper-display-style tab-pool-selector-text ${tabSelector === 1 ? 'tab-pool-active wrapper-display-style-active' : ''}`}
                                onClick={() => {
                                    setTabSelector(1)
                                }}
                            >
                                <div className={`wrapper-tab-pool-selector-text`}>
                                    <div style={{ width: "24px", height: "24px" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24"
                                            fill="none">
                                            <g clipPath="url(#clip0_160_1029)">
                                                <path
                                                    d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                                                    stroke="#4B2828" strokeWidth="2" strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                                <path
                                                    d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                                                    stroke="#4B2828" strokeWidth="2" strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                                <path d="M12 2V4" stroke="#4B2828" strokeWidth="2" strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                                <path d="M12 20V22" stroke="#4B2828" strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                                <path d="M20 12H22" stroke="#4B2828" strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                                <path d="M2 12H4" stroke="#4B2828" strokeWidth="2" strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_160_1029">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <p>Positions</p>
                                </div>
                            </div>
                        </NotchedButtonBolderNew>
                        {/*<NotchedButtonBolderNew w="100%" h="100%" borderColor={'rgba(239, 239, 228, 0.5)'}>*/}
                        {/*  <div*/}
                        {/*    className={`tab-pool-selector-text ${tabSelector === 2 ? 'tab-pool-active' : ''}`}*/}
                        {/*    onClick={() => {*/}
                        {/*      setTabSelector(2)*/}
                        {/*    }}*/}
                        {/*  >*/}
                        {/*    Positions V2*/}
                        {/*  </div>*/}
                        {/*</NotchedButtonBolderNew>*/}
                        {/* <NotchedButtonBolderNew w="100%" h="100%" borderColor={'rgba(239, 239, 228, 0.5)'}>
              <div className={`tab-pool-selector-text ${tabSelector === 3 ? 'tab-pool-active' : ''}`}>
                Positions Vault
              </div>
            </NotchedButtonBolderNew> */}
                    </div>
                    <div className="pool-container">
                        {tabSelector === 1 && (
                            <>
                                <AutoColumn gap="lg" justify="center">
                                    <AutoColumn gap="lg" style={{ width: '100%' }}>



                                        <MainContentWrapper>

                                            {positionsLoading ? (
                                                <PositionsLoadingPlaceholder />
                                            ) : filteredPositions && closedPositions && filteredPositions.length > 0 ? (
                                                <>
                                                    <PositionInfo>
                                                        {/* <span>
                                                            Your positions({filteredPositions.length})
                                                        </span> */}

                                                        <PositionInfoToggle
                                                        >
                                                            <Toggle
                                                                id="toggle-optimized-router-button"
                                                                isActive={userHideClosedPositions}
                                                                toggle={() => setUserHideClosedPositions(!userHideClosedPositions)}
                                                            />
                                                            <span>
                                                                <Trans>{userHideClosedPositions ? 'Show' : 'Hide'} closed positions</Trans>{' '}
                                                            </span>
                                                        </PositionInfoToggle>

                                                        <ButtonAddPosition as={Link}
                                                            to="/add/ETH">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                                viewBox="0 0 18 18" fill="none">
                                                                <path fillRule="evenodd" clipRule="evenodd"
                                                                    d="M7.69878 0.736818C7.30074 1.30536 7.01434 2.0821 6.87437 3.10469C6.7384 4.09812 6.7044 5.16249 6.77542 6.29945C6.7888 6.51373 6.70161 6.72217 6.53899 6.86469C6.37637 7.0072 6.15639 7.06795 5.94248 7.02941C5.62368 6.97198 5.28037 6.92133 4.91219 6.87771C4.903 6.87662 4.89384 6.87535 4.88471 6.87391C4.17084 6.76114 3.40871 6.70391 2.59725 6.70391C1.6982 6.70391 1.06807 6.93626 0.625947 7.32912L0.624413 7.33047C0.23768 7.6721 0 8.19079 0 9C0 9.67974 0.200302 10.0797 0.50215 10.33C0.845011 10.5841 1.40537 10.7598 2.28038 10.7598C3.74699 10.7598 5.00201 10.565 6.05824 10.1925C6.30004 10.1072 6.56967 10.158 6.76269 10.3251C6.95571 10.4923 7.0418 10.7497 6.9876 10.9975C6.87236 11.5244 6.77445 12.0651 6.69397 12.6198C6.57607 13.6407 6.5186 14.5186 6.5186 15.257C6.5186 16.3635 6.74363 17.0426 7.06368 17.4287C7.39445 17.7645 7.96744 18 8.93478 18C9.76295 18 10.2641 17.7753 10.5728 17.4367C10.9199 17.0259 11.1246 16.4556 11.1246 15.6592C11.1246 14.8713 10.9864 13.9191 10.6941 12.7936L10.693 12.7895C10.5315 12.1513 10.3577 11.5706 10.1725 11.0462C10.0941 10.8243 10.134 10.5784 10.2787 10.3918C10.4233 10.2052 10.6533 10.1029 10.8907 10.1197C11.7501 10.1803 13.6898 10.3128 14.4122 10.3128C15.2663 10.3128 15.9127 10.1164 16.4003 9.77253L16.4129 9.76367C16.8567 9.46493 17.1 9.02668 17.1 8.32961C17.1 7.53483 16.8711 7.13797 16.5784 6.92924L16.5627 6.91805C16.2103 6.652 15.6846 6.48045 14.9102 6.48045C14.0758 6.48045 13.2244 6.55145 12.3556 6.69441C11.8883 6.77131 11.4543 6.86415 11.0531 6.97225C10.788 7.04367 10.5051 6.95706 10.3274 6.75014C10.1497 6.54322 10.1095 6.25343 10.2242 6.00694C10.7563 4.86399 11.0341 3.49912 11.0341 1.89385C11.0341 1.15922 10.861 0.705813 10.6184 0.42942C10.4086 0.190422 10.024 0 9.29692 0C8.56781 0 8.06546 0.251503 7.69878 0.736818Z"
                                                                    fill="#4B2828" />
                                                            </svg>
                                                            <p>Add position</p>
                                                        </ButtonAddPosition>
                                                    </PositionInfo>
                                                    <PositionList
                                                        positions={filteredPositions}
                                                        setUserHideClosedPositions={setUserHideClosedPositions}
                                                        userHideClosedPositions={userHideClosedPositions}
                                                    />
                                                </>
                                            ) : (
                                                <ErrorContainer>
                                                    <ThemedText.DeprecatedBody color={theme.deprecated_text3}
                                                        textAlign="center">
                                                        <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
                                                        <div>
                                                            <Trans>Your active V3 liquidity positions will appear
                                                                here.</Trans>
                                                        </div>
                                                    </ThemedText.DeprecatedBody>
                                                    {!showConnectAWallet && closedPositions.length > 0 && (
                                                        <ButtonText
                                                            style={{ marginTop: '.5rem' }}
                                                            onClick={() => setUserHideClosedPositions(!userHideClosedPositions)}
                                                        >
                                                            <div style={{ color: 'rgba(239,239,228,0.5)' }}>
                                                                <Trans>Show closed positions</Trans>{' '}
                                                            </div>
                                                        </ButtonText>
                                                    )}

                                                    {
                                                        (!account || !isValidChainId) && <div style={{ marginTop: '2rem' }}>
                                                            <TraceEvent
                                                                events={[BrowserEvent.onClick]}
                                                                name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
                                                                properties={{ received_swap_quote: false }}
                                                                element={ElementName.CONNECT_WALLET_BUTTON}
                                                            >
                                                                <NotchedButtonFill
                                                                    onClick={toggleWalletModal}
                                                                    padding="8px 16px"
                                                                    earMaxW="130px"
                                                                    mbEarMaxW="110px"
                                                                >
                                                                    <Trans>Connect a wallet</Trans>
                                                                </NotchedButtonFill>
                                                            </TraceEvent>
                                                        </div>
                                                    }
                                                </ErrorContainer>
                                            )}
                                        </MainContentWrapper>
                                        {/* <HideSmall>
                <CTACards />
              </HideSmall> */}
                                    </AutoColumn>
                                </AutoColumn>
                            </>
                        )}
                        {tabSelector === 2 && (
                            <>
                                <V2Pool />
                            </>
                        )}
                    </div>
                </PageWrapper>
                {/* <SwitchLocaleLink /> */}
            </>
        </Trace>
    )
}
