import {useEffect, useState} from "react";

// @ts-ignore
const TextScramble = ({text}) => {
    const [displayText, setDisplayText] = useState('');
    const chars = 'MEOW';

    useEffect(() => {
        const scrambleText = (newText: string | any[]) => {
            const oldText = displayText;
            const length = Math.max(oldText.length, newText.length);
            const queue: { char: any; }[] | { from: string; to: any; start: number; end: number; }[] = [];
            for (let i = 0; i < length; i++) {
                const from = oldText[i] || '';
                const to = newText[i] || '';
                const start = Math.floor(Math.random() * 40);
                const end = start + Math.floor(Math.random() * 40);
                queue.push({char: undefined, from, to, start, end});
            }

            let frame = 0;
            const update = () => {
                let output = '';
                let complete = 0;
                for (let i = 0, n = queue.length; i < n; i++) {
                    // @ts-ignore
                    let {from, to, start, end, char} = queue[i];
                    if (frame >= end) {
                        complete++;
                        output += to;
                    } else if (frame >= start) {
                        if (!char || Math.random() < 0.28) {
                            char = randomChar();
                            // @ts-ignore
                            queue[i].char = char;
                        }
                        output += char
                    } else {
                        output += from;
                    }
                }
                output = output.split(' ').map(word => word.slice(0, 15)).join(' ');
                setDisplayText(output);
                if (complete !== queue.length) {
                    frame++;
                    requestAnimationFrame(update);
                }
            };
            update();
        };

        const randomChar = () => chars[Math.floor(Math.random() * chars.length)];

        scrambleText(text);
    }, [text]);

    return <h2 dangerouslySetInnerHTML={{__html: displayText}}></h2>;
};

export default TextScramble;