import { useState, useMemo, useCallback, useEffect } from 'react'
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts'
import styled from 'styled-components'
import { monthDayFormatter } from 'utils/formatChartTimes'
import { MusdStatType, TimePeriodType } from '.'
import dayjs from 'dayjs'

export const ToolTipBox = styled.div`
  display: flex;
  min-width: 57.949px;
  flex-direction: column;
  align-items: center;
  padding: 4px 8px;

  border-radius: 6px;
  border: 1.5px solid #00E440;
  background: #CAF0BC;
  span {
    color: #4B2828;
    font-family: Outfit;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 17.5px */
  }
`

export const useTimestampFormater = (timePeriod: TimePeriodType) =>
  useCallback(
    (timestamp: number) => {
      const dayjsFn = dayjs(timestamp)
      switch (timePeriod) {
        case 'HOUR':
        case 'DAY': {
          return dayjsFn.format('HH:00')
        }
        case 'WEEK':
        case '1MONTH':
        case 'MONTH': {
          return dayjsFn.format('DD. MMM')
        }
        default:
          return monthDayFormatter('en-US')(timestamp)
      }
    },
    [timePeriod]
  )

export const CustomXAxisTick = ({ x, y, payload, timestampFormat }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="rgba(75, 40, 40, 0.70);" className="musd-chart-axis-text">
        {timestampFormat(payload?.value)}
      </text>
    </g>
  )
}
const CustomYAxisTick = ({ x, y, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="rgba(75, 40, 40, 0.70)" className="musd-chart-axis-text">
        {payload?.value}
      </text>
    </g>
  )
}

export const CustomTooltip = ({ active, payload, label, timestampFormat }: any) => {
  if (active && payload && payload.length) {
    return (
      <ToolTipBox>
        <span style={{ color: '#05C63B' }}>{timestampFormat(label)}</span>
        <span>{Number(payload[0].value).toFixed(5)}</span>
      </ToolTipBox>
    )
  }
  return null
}

export function MusdPriceChart({
  musdStats,
  timePeriod,
  currentPrice,
}: {
  musdStats: MusdStatType[]
  timePeriod: TimePeriodType
  currentPrice: number
}) {
  const timestampFormat = useTimestampFormater(timePeriod)

  const [hasMouseChart, setHasMouseChart] = useState<boolean>(false)
  const [hoverYValue, sethoverYValue] = useState(0)
  const onMouseChart = (item: any, args: any = undefined) => {
    if (Object.keys(item).length > 0 && item.activePayload.length > 0) {
      sethoverYValue(item.activePayload[0].payload.price)
      setHasMouseChart(true)
    }
  }

  const onMouseOn = (item: any) => {
    if (item.activePayload) onMouseChart(item)
  }

  const prices = useMemo(() => {
    const newPrices: any = []

    if (musdStats.length > 0) {
      if (timePeriod != 'HOUR') {
        return musdStats
      }
      for (let index = 0; index < musdStats.length; index++) {
        const element = musdStats[index]
        let startDate = dayjs(element.createdAt).utc()

        if (index < musdStats.length - 1) {
          const endTime = musdStats[index + 1].timestamp
          while (startDate.valueOf() <= endTime) {
            newPrices.push({
              price: element.price,
              timestamp: startDate.valueOf(),
            })
            if (timePeriod != 'HOUR') startDate = startDate.add(10, 'minute')
            else startDate = startDate.add(5, 'minute')
          }
        }
      }
      if (musdStats.length > 0)
        newPrices.push({
          price: musdStats[musdStats.length - 1].price,
          timestamp: musdStats[musdStats.length - 1].timestamp,
        })
      // for (let index = 0; index < newPrices.length; index++) {
      //     const element = newPrices[index];
      //     if (index < middle)
      //         element.price = minPrice.price;
      //     else
      //         element.price = maxPrice.price;
      // }
    }

    return newPrices
  }, [musdStats])

  const priceRange = useMemo(() => {
    let minRange = 0
    let maxRange = 0
    if (currentPrice > 0) {
      if (timePeriod != 'HOUR') {
        minRange = currentPrice - 0.05
        maxRange = currentPrice + 0.02
      } else {
        minRange = Number((currentPrice - 0.0005).toFixed(5))
        maxRange = currentPrice + 0.0002
      }
    }

    return [minRange, maxRange]
  }, [currentPrice])

  const ticks = useMemo(() => {
    if (currentPrice <= 0) return []
    const tickArr = []
    let tickPrices = 0
    for (let index = 0.001; index < 5; index++) {
      tickPrices = currentPrice - 0.001
      tickArr.push(tickPrices)
    }
    for (let index = 0.001; index < 5; index++) {
      tickPrices = currentPrice + 0.001
      tickArr.push(tickPrices)
    }

    return tickArr
  }, [currentPrice])

  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    if (window.screen.width < 768) setIsMobile(true)
  }, [])
  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={prices}
          // @ts-ignore
          onMouseOver={onMouseChart}
          onMouseMove={onMouseOn}
          onMouseOut={() => setHasMouseChart(false)}
        >
          <defs>
            <linearGradient id="colorUv" x1="288.5" y1="0" x2="288.5" y2="300" gradientUnits="userSpaceOnUse">
              <stop stop-color="#ffb804b5" />
              <stop offset="1" stop-color="#FFB704" stop-opacity="0" />
            </linearGradient>
          </defs>
          <XAxis
            axisLine={false}
            dataKey="timestamp"
            tickLine={false}
            padding={{ right: isMobile ? 30 : 60 }}
            allowDuplicatedCategory={true}
            interval={'preserveStartEnd'}
            tickCount={12}
            tick={<CustomXAxisTick timestampFormat={timestampFormat} />}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            orientation="right"
            padding={{ top: 20 }}
            type="number"
            domain={priceRange}
            tickCount={8}
            tick={<CustomYAxisTick />}
          />
          <Tooltip content={<CustomTooltip timestampFormat={timestampFormat} />} />

          <ReferenceLine
            style={{ display: hoverYValue > 0 && hasMouseChart ? 'block' : 'none' }}
            y={hoverYValue}
            stroke="#FFB704"
            strokeWidth="3x"
          />

          <Area
            type="monotone"
            dataKey="price"
            strokeWidth="3px"
            stroke="#FFB704"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  )
}
