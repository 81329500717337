import { Connector } from '@web3-react/types'
import { notification } from 'antd'
import { networkConnection, walletConnectConnection } from 'connection'
import { getChainInfo } from 'constants/chainInfo'
import { isSupportedChain, SupportedChainId } from 'constants/chains'
import { FALLBACK_URLS, RPC_URLS } from 'constants/networks'
import { isMobile } from './userAgent'

function getRpcUrl(chainId: SupportedChainId): string {
  switch (chainId) {
    case SupportedChainId.MAINNET:
      return RPC_URLS[chainId][0]
    // Attempting to add a chain using an infura URL will not work, as the URL will be unreachable from the MetaMask background page.
    // MetaMask allows switching to any publicly reachable URL, but for novel chains, it will display a warning if it is not on the "Safe" list.
    // See the definition of FALLBACK_URLS for more details.
    default:
      return FALLBACK_URLS[chainId][0]
  }
}

export const switchChain = async (connector: Connector, chainId: SupportedChainId) => {
  if (!isSupportedChain(chainId)) {
    throw new Error(`Chain ${chainId} not supported for connector (${typeof connector})`)
  }
  // else if (connector === walletConnectConnection.connector || connector === networkConnection.connector) {
  //   await connector.activate(chainId)
  // } 
  else {
    const info = getChainInfo(chainId)
    const addChainParameter = {
      chainName: info.label,
      rpcUrls: [getRpcUrl(chainId)],
      nativeCurrency: info.nativeCurrency,
      blockExplorerUrls: [info.explorer],
    }
    if (connector.provider) {
      // check switch chain
      const hexChainId = `0x${chainId.toString(16)}`
      try {
        await connector.provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: hexChainId }],
        })
        return
      }
      catch (err) { // chain is not add to current wallet 
        if (err.code === 4902)  // This error code indicates that the chain has not been added to current wallet
        {
          await connector.provider.request({
            method: 'wallet_addEthereumChain',
            params: [{
              chainId: hexChainId, // Chain ID in hexadecimal (e.g., 0x89 for Polygon)
              ...addChainParameter,
            }]
          });
        } else if (isMobile && err) {
          await connector.provider.request({
            method: 'wallet_addEthereumChain',
            params: [{
              chainId: hexChainId, // Chain ID in hexadecimal (e.g., 0x89 for Polygon)
              ...addChainParameter,
            }]
          });
        }
      }
    }
    await connector.activate(addChainParameter)
  }
}
