import { BigNumber } from '@ethersproject/bignumber'
import type { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { TraceEvent } from '@uniswap/analytics'
import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core'
import { FeeAmount, NonfungiblePositionManager } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { sendEvent } from 'components/analytics'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import useParsedQueryString from 'hooks/useParsedQueryString'
import { useCallback, useEffect, useState } from 'react'
import { AlertTriangle } from 'react-feather'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Text } from 'rebass'
import {
  useRangeHopCallbacks,
  useV3DerivedMintInfo,
  useV3MintActionHandlers,
  useV3MintState,
} from 'state/mint/v3/hooks'
import { useTheme } from 'styled-components/macro'
import './add.css'

import {
  ButtonError,
  ButtonLight,
  ButtonPrimary,
  ButtonText,
  ButtonYellow,
  NotchedButtonFill,
  NotchedButtonFillPrimary,
} from '../../components/Button'
import { BlueCard, OutlineCard, YellowCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import FeeSelector from '../../components/FeeSelector'
import HoverInlineText from '../../components/HoverInlineText'
import LiquidityChartRangeInput from '../../components/LiquidityChartRangeInput'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { PositionPreview } from '../../components/PositionPreview'
import RangeSelector from '../../components/RangeSelector'
import PresetsButtons from '../../components/RangeSelector/PresetsButtons'
import RateToggle from '../../components/RateToggle'
import Row, { AutoRow, RowBetween, RowFixed } from '../../components/Row'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import { NONFUNGIBLE_POSITION_MANAGER_ADDRESSES } from '../../constants/addresses'
import { ZERO_PERCENT } from '../../constants/misc'
import { WRAPPED_NATIVE_CURRENCY } from '../../constants/tokens'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useArgentWalletContract } from '../../hooks/useArgentWalletContract'
import { useV3NFTPositionManagerContract } from '../../hooks/useContract'
import { useDerivedPositionInfo } from '../../hooks/useDerivedPositionInfo'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import { useStablecoinValue } from '../../hooks/useStablecoinPrice'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useV3PositionFromTokenId } from '../../hooks/useV3Positions'
import { useToggleWalletModal } from '../../state/application/hooks'
import { Bound, Field } from '../../state/mint/v3/actions'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TransactionType } from '../../state/transactions/types'
import usePrevious from "../../hooks/usePrevious";
import { useIsExpertMode, useUserSlippageToleranceWithDefault } from '../../state/user/hooks'
import { ExternalLink, ThemedText } from '../../theme'
import approveAmountCalldata from '../../utils/approveAmountCalldata'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { currencyId } from '../../utils/currencyId'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { Dots } from '../Pool/styleds'
import { Review } from './Review'
import { NotchedButtonBolderNew } from 'components/Button/NotchedButton'

import SettingsTab from '../../components/Settings'

import {
  CurrencyDropdown,
  DynamicSection,
  HideMedium,
  MediumOnly,
  PageWrapper,
  ResponsiveTwoColumns,
  RightContainer,
  ScrollablePage,
  StackedContainer,
  StackedItem,
  StyledInput,
  Wrapper,
} from './styled'

const reload = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/swap/reload.png'
const swaptop =
  'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/pool/liquidheader.png'
const swapTokens = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/pool/swap.png'
const plus = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/pool/plus.png'
import axios, { Method } from 'axios'

import AddLiquidityV2 from '../AddLiquidityV2/index'
import { isSupportedChain } from "../../constants/chains";
import { PositionPageUnsupportedContent } from "../Pool/PositionPage";
import PoolCurrencyInputPanel from "../../components/PoolCurrencyInputPanel";
import { formatNumber } from 'utils/formatNumbers'

const DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)

export default function AddLiquidityWrapper() {
  // const { chainId } = useWeb3React()
  // if (isSupportedChain(chainId)) {
  //   return <AddLiquidity />
  // } else {
  //   return <PositionPageUnsupportedContent />
  // }
  return <AddLiquidity />
}

function AddLiquidity() {
  const navigate = useNavigate()
  const {
    currencyIdA,
    currencyIdB,
    feeAmount: feeAmountFromUrl,
    tokenId,
  } = useParams<{ currencyIdA?: string; currencyIdB?: string; feeAmount?: string; tokenId?: string }>()
  const { account, chainId, provider } = useWeb3React()
  const theme = useTheme()

  const toggleWalletModal = useToggleWalletModal() // toggle wallet when disconnected
  const expertMode = useIsExpertMode()
  const addTransaction = useTransactionAdder()
  const positionManager = useV3NFTPositionManagerContract()

  // check for existing position if tokenId in url
  const { position: existingPositionDetails, loading: positionLoading } = useV3PositionFromTokenId(
    tokenId ? BigNumber.from(tokenId) : undefined
  )
  const hasExistingPosition = !!existingPositionDetails && !positionLoading
  const { position: existingPosition } = useDerivedPositionInfo(existingPositionDetails)

  // fee selection from url
  const [feeAmount, setFeeAmount] = useState<FeeAmount | undefined>(feeAmountFromUrl && Object.values(FeeAmount).includes(parseFloat(feeAmountFromUrl))
    ? parseFloat(feeAmountFromUrl)
    : 3000)

  const baseCurrency = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)
  // prevent an error if they input ETH/WETH
  const quoteCurrency =
    baseCurrency && currencyB && baseCurrency.wrapped.equals(currencyB.wrapped) ? undefined : currencyB

  // mint state
  const { independentField, typedValue, startPriceTypedValue } = useV3MintState()

  const {
    pool,
    ticks,
    dependentField,
    price,
    pricesAtTicks,
    pricesAtLimit,
    parsedAmounts,
    currencyBalances,
    position,
    noLiquidity,
    currencies,
    errorMessage,
    invalidPool,
    invalidRange,
    outOfRange,
    depositADisabled,
    depositBDisabled,
    invertPrice,
    ticksAtLimit,
  } = useV3DerivedMintInfo(
    baseCurrency ?? undefined,
    quoteCurrency ?? undefined,
    feeAmount,
    baseCurrency ?? undefined,
    existingPosition
  )

  const { onFieldAInput, onFieldBInput, onLeftRangeInput, onRightRangeInput, onStartPriceInput } =
    useV3MintActionHandlers(noLiquidity)

  const isValid = !errorMessage && !invalidRange

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm
  const [tabSelector, setTabSelector] = useState(1)

  // capital efficiency warning
  const [showCapitalEfficiencyWarning, setShowCapitalEfficiencyWarning] = useState(false)

  useEffect(() => setShowCapitalEfficiencyWarning(false), [baseCurrency, quoteCurrency, feeAmount])

  // txn values
  const deadline = useTransactionDeadline() // custom from users settings

  const [txHash, setTxHash] = useState<string>('')

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: parsedAmounts[dependentField]?.toSignificant() ?? '',
  }

  const usdcValues = {
    [Field.CURRENCY_A]: useStablecoinValue(parsedAmounts[Field.CURRENCY_A]),
    [Field.CURRENCY_B]: useStablecoinValue(parsedAmounts[Field.CURRENCY_B]),
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field]),
      }
    },
    {}
  )

  const atMaxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
      }
    },
    {}
  )

  const argentWalletContract = useArgentWalletContract()

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(
    argentWalletContract ? undefined : parsedAmounts[Field.CURRENCY_A],
    chainId ? NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId] : undefined
  )
  const [approvalB, approveBCallback] = useApproveCallback(
    argentWalletContract ? undefined : parsedAmounts[Field.CURRENCY_B],
    chainId ? NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId] : undefined
  )

  const allowedSlippage = useUserSlippageToleranceWithDefault(
    outOfRange ? ZERO_PERCENT : DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE
  )

  async function onAdd() {
    if (!chainId || !provider || !account) return

    if (!positionManager || !baseCurrency || !quoteCurrency) {
      return
    }
    setShowConfirm(true)
    if (position && account && deadline) {
      const useNative = baseCurrency.isNative ? baseCurrency : quoteCurrency.isNative ? quoteCurrency : undefined
      const { calldata, value } =
        hasExistingPosition && tokenId
          ? NonfungiblePositionManager.addCallParameters(position, {
            tokenId,
            slippageTolerance: allowedSlippage,
            deadline: deadline.toString(),
            useNative,
          })
          : NonfungiblePositionManager.addCallParameters(position, {
            slippageTolerance: allowedSlippage,
            recipient: account,
            deadline: deadline.toString(),
            useNative,
            createPool: noLiquidity,
          })

      let txn: { to: string; data: string; value: string } = {
        to: NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId],
        data: calldata,
        value,
      }

      if (argentWalletContract) {
        const amountA = parsedAmounts[Field.CURRENCY_A]
        const amountB = parsedAmounts[Field.CURRENCY_B]
        const batch = [
          ...(amountA && amountA.currency.isToken
            ? [approveAmountCalldata(amountA, NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId])]
            : []),
          ...(amountB && amountB.currency.isToken
            ? [approveAmountCalldata(amountB, NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId])]
            : []),
          {
            to: txn.to,
            data: txn.data,
            value: txn.value,
          },
        ]
        const data = argentWalletContract.interface.encodeFunctionData('wc_multiCall', [batch])
        txn = {
          to: argentWalletContract.address,
          data,
          value: '0x0',
        }
      }

      setAttemptingTxn(true)

      provider
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(estimate),
          }

          return provider
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              setAttemptingTxn(false)
              addTransaction(response, {
                type: TransactionType.ADD_LIQUIDITY_V3_POOL,
                baseCurrencyId: currencyId(baseCurrency),
                quoteCurrencyId: currencyId(quoteCurrency),
                createPool: Boolean(noLiquidity),
                expectedAmountBaseRaw: parsedAmounts[Field.CURRENCY_A]?.quotient?.toString() ?? '0',
                expectedAmountQuoteRaw: parsedAmounts[Field.CURRENCY_B]?.quotient?.toString() ?? '0',
                feeAmount: position.pool.fee,
              })
              setTxHash(response.hash)
              sendEvent({
                category: 'Liquidity',
                action: 'Add',
                label: [currencies[Field.CURRENCY_A]?.symbol, currencies[Field.CURRENCY_B]?.symbol].join('/'),
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          setAttemptingTxn(false)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
        })
    } else {
      return
    }
  }

  const handleCurrencySelect = useCallback(
    (currencyNew: Currency, currencyIdOther?: string): (string | undefined)[] => {
      const currencyIdNew = currencyId(currencyNew)

      if (currencyIdNew === currencyIdOther) {
        // not ideal, but for now clobber the other if the currency ids are equal
        return [currencyIdNew, undefined]
      } else {
        // prevent weth + eth
        const isETHOrWETHNew =
          currencyIdNew === 'ETH' ||
          (chainId !== undefined && currencyIdNew === WRAPPED_NATIVE_CURRENCY[chainId]?.address)
        const isETHOrWETHOther =
          currencyIdOther !== undefined &&
          (currencyIdOther === 'ETH' ||
            (chainId !== undefined && currencyIdOther === WRAPPED_NATIVE_CURRENCY[chainId]?.address))

        if (isETHOrWETHNew && isETHOrWETHOther) {
          return [currencyIdNew, undefined]
        } else {
          return [currencyIdNew, currencyIdOther]
        }
      }
    },
    [chainId]
  )

  const handleCurrencyASelect = useCallback(
    (currencyANew: Currency) => {
      const [idA, idB] = handleCurrencySelect(currencyANew, currencyIdB)
      if (idB === undefined) {
        navigate(`/add/${idA}`)
      } else {
        navigate(`/add/${idA}/${idB}`)
      }
    },
    [handleCurrencySelect, currencyIdB, navigate]
  )

  const handleCurrencyBSelect = useCallback(
    (currencyBNew: Currency) => {
      const [idB, idA] = handleCurrencySelect(currencyBNew, currencyIdA)
      if (idA === undefined) {
        navigate(`/add/${idB}`)
      } else {
        navigate(`/add/${idA}/${idB}`)
      }
    },
    [handleCurrencySelect, currencyIdA, navigate]
  )

  const handleFeePoolSelect = useCallback(
    (newFeeAmount: FeeAmount) => {
      onLeftRangeInput('')
      onRightRangeInput('')
      // navigate(`/add/${currencyIdA}/${currencyIdB}/${newFeeAmount}`)
      setFeeAmount(newFeeAmount)
    },
    [currencyIdA, currencyIdB, navigate, onLeftRangeInput, onRightRangeInput]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('')
      // dont jump to pool page if creating
      navigate('/pool')
    }
    setTxHash('')
  }, [navigate, onFieldAInput, txHash])

  const addIsUnsupported = useIsSwapUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)

  const clearAll = useCallback(() => {
    onFieldAInput('')
    onFieldBInput('')
    onLeftRangeInput('')
    onRightRangeInput('')
    navigate(`/add`)
  }, [navigate, onFieldAInput, onFieldBInput, onLeftRangeInput, onRightRangeInput])

  // get value and prices at ticks
  const { [Bound.LOWER]: tickLower, [Bound.UPPER]: tickUpper } = ticks
  const { [Bound.LOWER]: priceLower, [Bound.UPPER]: priceUpper } = pricesAtTicks

  const { getDecrementLower, getIncrementLower, getDecrementUpper, getIncrementUpper, getSetFullRange } =
    useRangeHopCallbacks(baseCurrency ?? undefined, quoteCurrency ?? undefined, feeAmount, tickLower, tickUpper, pool)

  // we need an existence check on parsed amounts for single-asset deposits
  const showApprovalA =
    !argentWalletContract && approvalA !== ApprovalState.APPROVED && !!parsedAmounts[Field.CURRENCY_A]
  const showApprovalB =
    !argentWalletContract && approvalB !== ApprovalState.APPROVED && !!parsedAmounts[Field.CURRENCY_B]

  const pendingText = `Supplying ${!depositADisabled ? parsedAmounts[Field.CURRENCY_A]?.toSignificant() : ''} ${!depositADisabled ? currencies[Field.CURRENCY_A]?.symbol : ''
    } ${!outOfRange ? 'and' : ''} ${!depositBDisabled ? parsedAmounts[Field.CURRENCY_B]?.toSignificant() : ''} ${!depositBDisabled ? currencies[Field.CURRENCY_B]?.symbol : ''
    }`

  const [searchParams, setSearchParams] = useSearchParams()

  const handleSetFullRange = useCallback(() => {
    getSetFullRange()

    const minPrice = pricesAtLimit[Bound.LOWER]
    console.log('minPrice', minPrice?.toSignificant());

    if (minPrice) searchParams.set('minPrice', minPrice.toSignificant())
    const maxPrice = pricesAtLimit[Bound.UPPER]
    if (maxPrice) searchParams.set('maxPrice', maxPrice.toSignificant())
    setSearchParams(searchParams)
  }, [getSetFullRange, pricesAtLimit, searchParams, setSearchParams])

  // START: sync values with query string
  const oldSearchParams = usePrevious(searchParams)
  // use query string as an input to onInput handlers
  useEffect(() => {
    const minPrice = searchParams.get('minPrice')
    const oldMinPrice = oldSearchParams?.get('minPrice')
    if (
      minPrice &&
      typeof minPrice === 'string' &&
      !isNaN(minPrice as any) &&
      (!oldMinPrice || oldMinPrice !== minPrice)
      && Number(minPrice) != 0
    ) {
      onLeftRangeInput(minPrice)
    }
    // disable eslint rule because this hook only cares about the url->input state data flow
    // input state -> url updates are handled in the input handlers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  useEffect(() => {
    const maxPrice = searchParams.get('maxPrice')
    const oldMaxPrice = oldSearchParams?.get('maxPrice')
    if (
      maxPrice &&
      typeof maxPrice === 'string' &&
      !isNaN(maxPrice as any) &&
      (!oldMaxPrice || oldMaxPrice !== maxPrice)
      && Number(maxPrice) != 0
    ) {
      onRightRangeInput(maxPrice)
    }
    // disable eslint rule because this hook only cares about the url->input state data flow
    // input state -> url updates are handled in the input handlers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])
  // END: sync values with query string

  const Buttons = () =>
    addIsUnsupported ? (
      <NotchedButtonFillPrimary disabled={true} padding="12px">
        <ThemedText.DeprecatedMain mb="4px">
          <Trans>Unsupported Asset</Trans>
        </ThemedText.DeprecatedMain>
      </NotchedButtonFillPrimary>
    ) : !account ? (
      <NotchedButtonFill onClick={toggleWalletModal} padding="12px">
        <Trans>Connect Wallet</Trans>
      </NotchedButtonFill>
    ) : (
      <AutoColumn gap="md">
        {(approvalA === ApprovalState.NOT_APPROVED ||
          approvalA === ApprovalState.PENDING ||
          approvalB === ApprovalState.NOT_APPROVED ||
          approvalB === ApprovalState.PENDING) &&
          isValid && (
            <RowBetween>
              {showApprovalA && (
                <NotchedButtonFill
                  onClick={approveACallback}
                  disabled={approvalA === ApprovalState.PENDING}
                  width={showApprovalB ? '48%' : '100%'}
                >
                  {approvalA === ApprovalState.PENDING ? (
                    <Dots>
                      <Trans>Approving {currencies[Field.CURRENCY_A]?.symbol}</Trans>
                    </Dots>
                  ) : (
                    <Trans>Approve {currencies[Field.CURRENCY_A]?.symbol}</Trans>
                  )}
                </NotchedButtonFill>
              )}
              {showApprovalB && (
                <NotchedButtonFill
                  onClick={approveBCallback}
                  disabled={approvalB === ApprovalState.PENDING}
                  width={showApprovalA ? '48%' : '100%'}
                >
                  {approvalB === ApprovalState.PENDING ? (
                    <Dots>
                      <Trans>Approving {currencies[Field.CURRENCY_B]?.symbol}</Trans>
                    </Dots>
                  ) : (
                    <Trans>Approve {currencies[Field.CURRENCY_B]?.symbol}</Trans>
                  )}
                </NotchedButtonFill>
              )}
            </RowBetween>
          )}
        <ButtonError
          onClick={() => {
            // expertMode ? onAdd() : setShowConfirm(true)
            setShowConfirm(true)
          }}
          disabled={
            !isValid ||
            (!argentWalletContract && approvalA !== ApprovalState.APPROVED && !depositADisabled) ||
            (!argentWalletContract && approvalB !== ApprovalState.APPROVED && !depositBDisabled)
          }
          error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
        >
          <Text fontWeight={500}>{errorMessage ? errorMessage : <Trans>Preview</Trans>}</Text>
        </ButtonError>
      </AutoColumn>
    )
  const headers = {
    Authorization: `Bearer auth=gmeow`,
    'Content-Type': 'application/json',
  }

  const [priceInputFiate, setPriceInputFiate] = useState<string | undefined>()
  const [priceOnputFiate, setPriceOnputFiate] = useState<string | undefined>()
  // show price

  // let addressTokenInput = currencyIdA as any
  // let addressTokenOutput = currencyIdB as any
  useEffect(() => {
    const fetchTokenPrice = async () => {
      if (currencyIdA !== null) {
        try {
          let addressValue = currencyIdA === 'ETH' ? '0x4300000000000000000000000000000000000006' : currencyIdA
          const res = await axios({
            method: 'get',
            url: `https://api.geckoterminal.com/api/v2/simple/networks/zircuit/token_price/${addressValue}`,
            headers,
          })
          if (res) {
            const price = res ? (Object.values(res.data.data.attributes.token_prices)[0] as string) : '0'
            setPriceInputFiate(price)
          }
        } catch (error) {
          console.log('Error:', error)
        }
      }
    }

    fetchTokenPrice()
  }, [account, chainId, currencyIdA])

  useEffect(() => {
    const fetchTokenPrice = async () => {
      if (currencyIdB !== null) {
        try {
          let addressValue = currencyIdB === 'ETH' ? '0x4200000000000000000000000000000000000006' : currencyIdB
          const res = await axios({
            method: 'get',
            url: `https://api.geckoterminal.com/api/v2/simple/networks/zircuit/token_price/${addressValue}`,
            headers,
          })
          if (res) {
            const price = res ? (Object.values(res.data.data.attributes.token_prices)[0] as string) : '0'
            setPriceOnputFiate(price)
          }
        } catch (error) {
          console.log('Error:', error)
        }
      }
    }

    fetchTokenPrice()
  }, [account, chainId, currencyIdB])

  return (
    <>
      <ScrollablePage>
        <TransactionConfirmationModal
          isOpen={showConfirm}
          onDismiss={handleDismissConfirmation}
          attemptingTxn={attemptingTxn}
          hash={txHash}
          content={() => (
            <ConfirmationModalContent
              title={<Trans>Add Liquidity</Trans>}
              onDismiss={handleDismissConfirmation}
              topContent={() => (
                <Review
                  parsedAmounts={parsedAmounts}
                  position={position}
                  existingPosition={existingPosition}
                  priceLower={priceLower}
                  priceUpper={priceUpper}
                  outOfRange={outOfRange}
                  ticksAtLimit={ticksAtLimit}
                />
              )}
              bottomContent={() => (
                <NotchedButtonFill onClick={onAdd}>
                  <Text fontWeight={500} fontSize={20}>
                    <Trans>Add</Trans>
                  </Text>
                </NotchedButtonFill>
              )}
            />
          )}
          pendingText={pendingText}
        />

        <div className="tab-pool-selector">
          <NotchedButtonBolderNew cursor={`pointer`}>
            <div
              className={`wrapper-display-style tab-pool-selector-text ${tabSelector === 1 ? 'tab-pool-active wrapper-display-style-active' : ''}`}
              onClick={() => {
                setTabSelector(1)
              }}
            >
              <div className={`wrapper-tab-pool-selector-text`}>
                <img style={{ width: "24px", height: "24px" }} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/add-liquidity/img.png" alt="img" />
                <p>Add Liquidity</p>
              </div>
            </div>
          </NotchedButtonBolderNew>
          <NotchedButtonBolderNew cursor={`pointer`}>
            <div
              className={`wrapper-display-style tab-pool-selector-text ${tabSelector === 2 ? 'tab-pool-active wrapper-display-style-active' : ''}`}
              onClick={() => {
                navigate('/pool')
              }}
            >
              <div className={`wrapper-tab-pool-selector-text`}>
                <img style={{ width: "24px", height: "24px" }} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/add-liquidity/img_1.png"
                  alt="img_1" />
                <p>Positions</p>
              </div>
            </div>
          </NotchedButtonBolderNew>
          {/* <NotchedButtonBolderNew w="100%" h="100%" borderColor={'rgba(239, 239, 228, 0.5)'}>
            <div className={`tab-pool-selector-text ${tabSelector === 3 ? 'tab-pool-active' : ''}`}>Add LP Vault</div>
          </NotchedButtonBolderNew> */}
        </div>


        <div className={`wrapper-pool-top-and-body`}>
          <div className="pool-top-container">
            <div className="swap-top-container">
              <div className="swap-top-left">
                <p style={{ cursor: 'pointer' }} onClick={() => navigate('/pool')}>Pools</p>
                <p>{'>'}</p>
                <p style={{ color: '#4B2828' }}>V3 Liquidity</p>
                {/* <div className={`swap-top-left-inner`}>
                  <div className={`swap-top-left-inner-wrap-img`}>
                    <img src="/add-liquidity/img_2.png" alt="img_2"/>
                    <img className={`right-img`} src="/add-liquidity/img_3.png" alt="img_3"/>
                  </div>
                  <p>ETH/USDC</p>
                </div> */}
              </div>
              <div className="swap-top-right">
                {!hasExistingPosition && (
                  <Row justifyContent="flex-end"
                    style={{ width: 'fit-content', minWidth: 'fit-content' }}>
                    <MediumOnly>
                      <div onClick={clearAll} style={{ cursor: 'pointer' }}>
                        <img src={reload} alt="swap image" width="30px"
                          style={{ paddingTop: '4px' }} />
                      </div>
                    </MediumOnly>
                  </Row>
                )}
                <SettingsTab placeholderSlippage={DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE} />
              </div>
            </div>
          </div>
          <div className="pool-body-container-page">
            {/* <AddRemoveTabs
              creating={false}
              adding={true}
              positionID={tokenId}
              defaultSlippage={DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE}
              showBackLink={!hasExistingPosition}
            ></AddRemoveTabs> */}
            <div className="pool-body-mid-container">
              <div className="pool-body-mid-left">
                <AutoColumn gap="lg">
                  {!hasExistingPosition && (
                    <>
                      <AutoColumn gap="md">
                        <RowBetween paddingBottom="18px">
                          <Text color={'#4B2828'}
                            fontSize={`0.875rem`} fontFamily={`Outfit`}>
                            <Trans>
                              Select a pair
                            </Trans>
                          </Text>
                          <div>
                            {baseCurrency && quoteCurrency ? (
                              <RateToggle
                                currencyA={baseCurrency}
                                currencyB={quoteCurrency}
                                handleRateToggle={() => {
                                  if (!ticksAtLimit[Bound.LOWER] && !ticksAtLimit[Bound.UPPER]) {
                                    onLeftRangeInput(
                                      (invertPrice ? priceLower : priceUpper?.invert())?.toSignificant() ?? ''
                                    )
                                    onRightRangeInput(
                                      (invertPrice ? priceUpper : priceLower?.invert())?.toSignificant() ?? ''
                                    )
                                    onFieldAInput(formattedAmounts[Field.CURRENCY_B] ?? '')
                                  }
                                  navigate(
                                    `/add/${currencyIdB as string}/${currencyIdA as string}${feeAmount ? '/' + feeAmount : ''
                                    }`
                                  )
                                }}
                              />
                            ) : null}
                          </div>

                        </RowBetween>
                        <div className="pool-select-pair">
                          {/*<Text marginBottom={'10px'} color={'rgba(239, 239, 228, 0.5)'}>*/}
                          {/*    PAIR*/}
                          {/*</Text>*/}
                          <div className="add-pair">
                            <div className="add-pair-div">
                              <CurrencyDropdown
                                value={formattedAmounts[Field.CURRENCY_A]}
                                onUserInput={onFieldAInput}
                                hideInput={true}
                                onMax={() => {
                                  onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
                                }}
                                onCurrencySelect={handleCurrencyASelect}
                                showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                                currency={currencies[Field.CURRENCY_A] ?? null}
                                id="add-liquidity-input-tokena"
                                showCommonBases
                                customeFiatValue={priceInputFiate}
                              />

                              <div className="add-pair-div-img">
                                <img
                                  src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/add-liquidity/img_5.png" alt="icons" />
                              </div>

                              <CurrencyDropdown
                                value={formattedAmounts[Field.CURRENCY_B]}
                                hideInput={true}
                                onUserInput={onFieldBInput}
                                onCurrencySelect={handleCurrencyBSelect}
                                onMax={() => {
                                  onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
                                }}
                                showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                                currency={currencies[Field.CURRENCY_B] ?? null}
                                id="add-liquidity-input-tokenb"
                                showCommonBases
                                customeFiatValue={priceOnputFiate}
                              />
                            </div>
                          </div>
                        </div>
                      </AutoColumn>{' '}
                    </>
                  )}
                  {hasExistingPosition && existingPosition && (
                    <PositionPreview
                      position={existingPosition}
                      title={<Trans>Selected Range</Trans>}
                      inRange={!outOfRange}
                      ticksAtLimit={ticksAtLimit}
                    />
                  )}
                </AutoColumn>
                <div style={{ marginTop: '32px' }}>
                  {!hasExistingPosition && (
                    <div>
                      <FeeSelector
                        disabled={!quoteCurrency || !baseCurrency}
                        feeAmount={feeAmount}
                        handleFeePoolSelect={handleFeePoolSelect}
                        currencyA={baseCurrency ?? undefined}
                        currencyB={quoteCurrency ?? undefined}
                      />
                    </div>
                  )}
                  {
                    noLiquidity &&
                    (
                      <AutoColumn gap="md">
                        <RowBetween>
                          <div style={{ color: `#4B2828` }} className="title-gap">
                            <Trans>Set Starting Price</Trans>
                          </div>
                        </RowBetween>

                        {noLiquidity && (
                          <BlueCard
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              padding: '1rem 1rem',
                              maxWidth: '794.81px'
                            }}
                          >
                            <ThemedText.DeprecatedBody
                              fontSize={14}
                              style={{ fontWeight: 500, fontFamily: "Outfit" }}
                              textAlign="left"
                              color={theme.deprecated_primaryText1}
                            >
                              <Trans>
                                This pool must be initialized before you can add
                                liquidity. To initialize, select a
                                starting price for the pool. Then, enter your
                                liquidity price range and deposit
                                amount. Gas fees will be higher than usual due
                                to the initialization transaction.
                              </Trans>
                            </ThemedText.DeprecatedBody>
                          </BlueCard>
                        )}
                        <div
                          style={{
                            padding: '8px',
                            background: 'transparent',
                            fontFamily: "Outfit !important",
                          }}
                        >
                          <StyledInput
                            className="start-price-input"
                            value={startPriceTypedValue}
                            onUserInput={onStartPriceInput}
                          />
                        </div>
                        <RowBetween
                          style={{ backgroundColor: 'transparent', padding: '8px' }}>
                          <div style={{ color: '#4B2828', fontFamily: "Outfit" }}>
                            <Trans>Current {baseCurrency?.symbol} Price:</Trans>
                          </div>
                          <div style={{
                            color: 'rgba(239, 239, 228, 0.5)',
                            fontFamily: "Outfit"
                          }}>
                            {price ? (
                              <div style={{
                                color: 'rgba(239, 239, 228, 0.5)',
                                fontFamily: "Outfit"
                              }}>
                                <RowFixed>
                                  <HoverInlineText
                                    maxCharacters={20}
                                    text={invertPrice ? price?.invert()?.toSignificant() : price?.toSignificant()}
                                  />{' '}
                                  <span
                                    style={{
                                      marginLeft: '4px',
                                      color: '#4B2828',
                                      fontFamily: "Outfit",
                                      fontWeight: 600
                                    }}>{quoteCurrency?.symbol}</span>
                                </RowFixed>
                              </div>
                            ) : (
                              '-'
                            )}
                          </div>
                        </RowBetween>
                      </AutoColumn>
                    )
                  }

                  {!hasExistingPosition ? (
                    <div style={{ width: "100%" }}>

                      <RightContainer gap="lg">
                        <DynamicSection gap="md" disabled={!feeAmount || invalidPool}>
                          {!noLiquidity && (
                            <>
                              {/*<RowBetween>*/}
                              {/*    <div style={{color: '#4B2828'}} className="title-gap">*/}
                              {/*        <Trans>Set Price Range</Trans>*/}
                              {/*    </div>*/}
                              {/*</RowBetween>*/}

                              {price && baseCurrency && quoteCurrency && !noLiquidity && (
                                <AutoRow gap="4px" justify="start">
                                  <Trans>
                                    <div
                                      style={{
                                        color: "rgba(75, 40, 40, 0.50)",
                                        fontFamily: "Outfit",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "125%" /* 20px */
                                      }}
                                    >
                                      Current Price:
                                    </div>
                                    <div
                                      style={{
                                        color: "#4B2828",
                                        fontFamily: "Outfit",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        lineHeight: "125%" /* 20px */
                                      }}
                                    >
                                      <HoverInlineText
                                        maxCharacters={20}
                                        text={invertPrice ? formatNumber(price.invert().toSignificant(), 6) : formatNumber(price.toSignificant(), 6)}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        color: "rgba(75, 40, 40, 0.50)",
                                        fontFamily: "Outfit",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        lineHeight: "125%" /* 20px */
                                      }}
                                    >
                                      {quoteCurrency?.symbol} per {baseCurrency.symbol}
                                    </div>
                                  </Trans>
                                </AutoRow>
                              )}

                              <LiquidityChartRangeInput
                                currencyA={baseCurrency ?? undefined}
                                currencyB={quoteCurrency ?? undefined}
                                feeAmount={feeAmount}
                                ticksAtLimit={ticksAtLimit}
                                price={
                                  price ? parseFloat((invertPrice ? price.invert() : price).toSignificant()) : undefined
                                }
                                priceLower={priceLower}
                                priceUpper={priceUpper}
                                onLeftRangeInput={onLeftRangeInput}
                                onRightRangeInput={onRightRangeInput}
                                interactive={!hasExistingPosition}
                              />
                            </>
                          )}
                        </DynamicSection>

                        <DynamicSection
                          gap="md"
                          disabled={!feeAmount || invalidPool || (noLiquidity && !startPriceTypedValue)}
                        >
                          <StackedContainer>
                            <StackedItem
                              style={{ opacity: showCapitalEfficiencyWarning ? '0.05' : 1 }}>
                              <AutoColumn gap="24px">
                                {noLiquidity && (
                                  <RowBetween>
                                    <div style={{ color: '#4B2828' }}
                                      className="title-gap">
                                      <Trans>Set Price Range</Trans>
                                    </div>
                                  </RowBetween>
                                )}
                                <RangeSelector
                                  priceLower={priceLower}
                                  priceUpper={priceUpper}
                                  getDecrementLower={getDecrementLower}
                                  getIncrementLower={getIncrementLower}
                                  getDecrementUpper={getDecrementUpper}
                                  getIncrementUpper={getIncrementUpper}
                                  onLeftRangeInput={onLeftRangeInput}
                                  onRightRangeInput={onRightRangeInput}
                                  currencyA={baseCurrency}
                                  currencyB={quoteCurrency}
                                  feeAmount={feeAmount}
                                  ticksAtLimit={ticksAtLimit}
                                />
                                {!noLiquidity && (
                                  <PresetsButtons
                                    setFullRange={() => {
                                      setShowCapitalEfficiencyWarning(true)
                                    }}
                                  />
                                )}
                              </AutoColumn>
                            </StackedItem>

                            {showCapitalEfficiencyWarning && (
                              <StackedItem zIndex={1}>
                                <YellowCard
                                  padding="15px"
                                  height="100%"
                                  style={{
                                    borderColor: theme.deprecated_yellow3,
                                    border: '1px solid',
                                  }}
                                >
                                  <AutoColumn gap="sm" style={{ height: '100%' }}>
                                    <RowFixed>
                                      <AlertTriangle stroke={'#FFB800'} size="16px" />
                                      <Text ml="12px" fontSize="15px"
                                        color={'#FFB800'}>
                                        <Trans>Efficiency Comparison</Trans>
                                      </Text>
                                    </RowFixed>
                                    <RowFixed>
                                      <Text ml="12px" fontSize="13px" margin={0}
                                        fontWeight={400} color={'#FFB800'}>
                                        <Trans>
                                          Full range positions may earn less fees
                                          than concentrated positions. Learn
                                          more{' '}
                                          <ExternalLink
                                            style={{
                                              color: theme.deprecated_yellow3,
                                              textDecoration: 'underline'
                                            }}
                                            href="https://docs.gmeow.fi//about/overview"
                                            target="_blank"
                                          >
                                            here
                                          </ExternalLink>
                                          .
                                        </Trans>
                                      </Text>
                                    </RowFixed>
                                    <Row>
                                      <NotchedButtonFill
                                        padding="8px"
                                        marginRight="8px"
                                        width="100%"
                                        earMaxW={"100px"}
                                        onClick={() => {
                                          setShowCapitalEfficiencyWarning(false)
                                          getSetFullRange()
                                        }}
                                      >
                                        <ThemedText.DeprecatedBlack fontSize={13}
                                          color="black">
                                          <Trans>I understand</Trans>
                                        </ThemedText.DeprecatedBlack>
                                      </NotchedButtonFill>
                                    </Row>
                                  </AutoColumn>
                                </YellowCard>
                              </StackedItem>
                            )}
                          </StackedContainer>

                          {outOfRange ? (
                            <YellowCard padding="8px 12px">
                              <RowBetween>
                                <AlertTriangle stroke={theme.deprecated_yellow3}
                                  size="16px" />
                                <Text ml="12px" fontSize="12px" color={'#FFB800'}>
                                  <Trans>
                                    Your position will not earn fees or be used in
                                    trades until the market price moves
                                    into your range.
                                  </Trans>
                                </Text>
                              </RowBetween>
                            </YellowCard>
                          ) : null}

                          {invalidRange ? (
                            <YellowCard padding="8px 12px">
                              <RowBetween>
                                <AlertTriangle stroke={theme.deprecated_yellow3}
                                  size="16px" />
                                <Text ml="12px" fontSize="12px" color={'#FFB800'}>
                                  <Trans>Invalid range selected. The min price must be
                                    lower than the max price.</Trans>
                                </Text>
                              </RowBetween>
                            </YellowCard>
                          ) : null}
                        </DynamicSection>

                        <DynamicSection
                          disabled={tickLower === undefined || tickUpper === undefined || invalidPool || invalidRange}
                        >
                          <AutoColumn gap="md">
                            <div className={`deposit-amount-text`}>
                              {hasExistingPosition ? <Trans>Add more liquidity</Trans> :
                                <Trans>Deposit amounts</Trans>}
                            </div>
                            <div style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "12px",
                            }}>
                              <PoolCurrencyInputPanel
                                hideInput={noLiquidity && (!priceLower || !priceUpper)}
                                value={formattedAmounts[Field.CURRENCY_A]}
                                onUserInput={onFieldAInput}
                                onMax={() => {
                                  onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
                                }}
                                showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                                currency={currencies[Field.CURRENCY_A] ?? null}
                                id="add-liquidity-input-tokena"
                                fiatValue={usdcValues[Field.CURRENCY_A]}
                                showCommonBases
                                locked={depositADisabled}
                                customeFiatValue={priceInputFiate}
                              />

                              <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}>
                                <img style={{ width: "18px", height: "18px" }}
                                  src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/add-liquidity/img_6.png" alt="img_6" />
                              </div>

                              <PoolCurrencyInputPanel
                                hideInput={noLiquidity && (!priceLower || !priceUpper)}
                                value={formattedAmounts[Field.CURRENCY_B]}
                                onUserInput={onFieldBInput}
                                onMax={() => {
                                  onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
                                }}
                                showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                                fiatValue={usdcValues[Field.CURRENCY_B]}
                                currency={currencies[Field.CURRENCY_B] ?? null}
                                id="add-liquidity-input-tokenb"
                                showCommonBases
                                locked={depositBDisabled}
                                customeFiatValue={priceOnputFiate}
                              />
                            </div>
                          </AutoColumn>
                        </DynamicSection>

                        <HideMedium>
                          <Buttons />
                        </HideMedium>
                        <MediumOnly>
                          <Buttons />
                        </MediumOnly>
                      </RightContainer>
                    </div>
                  ) : (
                    <Buttons />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {addIsUnsupported && (
          <UnsupportedCurrencyFooter
            show={addIsUnsupported}
            currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]}
          />
        )}
      </ScrollablePage>
      {/* <SwitchLocaleLink /> */}
    </>
  )
}
