import { Trans } from "@lingui/macro";
import { Currency, CurrencyAmount, Percent, Price } from "@uniswap/sdk-core";
import { FeeAmount, NonfungiblePositionManager } from "@uniswap/v3-sdk";
import { useWeb3React } from "@web3-react/core";
import { sendEvent } from "components/analytics";
import { AutoColumn } from "components/Column";
import Modal from "components/Modal";
import PoolCurrencyInputPanel from "components/PoolCurrencyInputPanel";
import { NONFUNGIBLE_POSITION_MANAGER_ADDRESSES } from "constants/addresses";
import { ZERO_PERCENT } from "constants/misc";
import { BigNumber } from "ethers";
import { useCurrency, useToken } from "hooks/Tokens";
import { ApprovalState, useApproveCallback } from "hooks/useApproveCallback";
import { useArgentWalletContract } from "hooks/useArgentWalletContract";
import { useV3NFTPositionManagerContract } from "hooks/useContract";
import { useDerivedPositionInfo } from "hooks/useDerivedPositionInfo";
import { useIsSwapUnsupported } from "hooks/useIsSwapUnsupported";
import { useStablecoinValue } from "hooks/useStablecoinPrice";
import useTransactionDeadline from "hooks/useTransactionDeadline";
import { useV3PositionFromTokenId } from "hooks/useV3Positions";
import { DynamicSection, HideMedium } from "pages/AddLiquidity/styled";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useToggleWalletModal } from "state/application/hooks";
import { Field } from "state/mint/actions";
import { Bound } from "state/mint/v3/actions";
import { useRangeHopCallbacks, useV3DerivedMintInfo, useV3MintActionHandlers, useV3MintState } from "state/mint/v3/hooks";
import { useTransactionAdder } from "state/transactions/hooks";
import { TransactionType } from "state/transactions/types";
import { useUserSlippageToleranceWithDefault } from "state/user/hooks";
import styled from "styled-components";
import { CloseIcon, ThemedText } from 'theme'
import approveAmountCalldata from "utils/approveAmountCalldata";
import { calculateGasMargin } from "utils/calculateGasMargin";
import { currencyId } from "utils/currencyId";
import { maxAmountSpend } from "utils/maxAmountSpend";
import type { TransactionResponse } from '@ethersproject/providers'
import { WRAPPED_NATIVE_CURRENCY } from "constants/tokens";
import usePrevious from "hooks/usePrevious";
import axios from "axios"
import { FixedHeightRow } from "components/PositionCard";
import Row, { RowBetween, RowFixed } from "components/Row";
import { Text } from "rebass";
import CurrencyLogo from "components/CurrencyLogo";
import RangeBadge from "components/Badge/RangeBadge";
import JSBI from "jsbi";
import HoverInlineText from "components/HoverInlineText";
import { formatNumber } from "utils/formatNumbers";
import { LightCard } from "components/Card";
import { formatTickPrice } from "utils/formatTickPrice";
import RateToggle from "components/RateToggle";
import { ButtonError, NotchedButtonFill, NotchedButtonFillPrimary } from "components/Button";
import { Dots } from "components/swap/styleds";
import { notification } from "antd";

const AddLiquidityV3PopupWrapper = styled.div`
    padding: 32px;
    width: 573px;
    height: fit-content;
    border-radius: 10px;
    border: 1.5px solid #E5B670;
    background: #FFF6D0;
    box-shadow: 0px 7.395px 30px 0px rgba(145, 103, 20, 0.30);

    @media (max-width: 600px) {
        padding: 12px;
    }
`

const TitleBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4{
        color: #4B2828;
        font-family: DynaPuff;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 24px */
        margin: 0;
    }
    margin-bottom: 32px;
`

const InvertedTxt = styled.span`
    color: rgba(75, 40, 40, 0.50);
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
    display: inline-block;
    margin-left: 4px;
`


function getRatio(
    lower: Price<Currency, Currency>,
    current: Price<Currency, Currency>,
    upper: Price<Currency, Currency>
) {
    try {
        if (!current.greaterThan(lower)) {
            return 100
        } else if (!current.lessThan(upper)) {
            return 0
        }

        const a = Number.parseFloat(lower.toSignificant(15))
        const b = Number.parseFloat(upper.toSignificant(15))
        const c = Number.parseFloat(current.toSignificant(15))

        const ratio = Math.floor((1 / ((Math.sqrt(a * b) - Math.sqrt(b * c)) / (c - Math.sqrt(b * c)) + 1)) * 100)

        if (ratio < 0 || ratio > 100) {
            throw Error('Out of range')
        }

        return ratio
    } catch {
        return undefined
    }
}

export default function AddLiquidityV3Popup({
    currencyIdA,
    currencyIdB,
    feeAmountFromUrl,
    tokenId,
    onClose
}: any) {

    const { account, chainId, provider } = useWeb3React()

    const toggleWalletModal = useToggleWalletModal() // toggle wallet when disconnected
    const addTransaction = useTransactionAdder()
    const positionManager = useV3NFTPositionManagerContract()

    // check for existing position if tokenId in url
    const { position: existingPositionDetails, loading: positionLoading } = useV3PositionFromTokenId(
        tokenId ? BigNumber
            .from(tokenId) : undefined
    )
    const hasExistingPosition = !!existingPositionDetails && !positionLoading
    const { position: existingPosition } = useDerivedPositionInfo(existingPositionDetails)

    // fee selection from url
    const [feeAmount, setFeeAmount] = useState<FeeAmount | undefined>(feeAmountFromUrl && Object.values(FeeAmount).includes(parseFloat(feeAmountFromUrl))
        ? parseFloat(feeAmountFromUrl)
        : 3000)

    const baseCurrency = useCurrency(currencyIdA)
    const currencyB = useCurrency(currencyIdB)
    // prevent an error if they input ETH/WETH
    const quoteCurrency =
        baseCurrency && currencyB && baseCurrency.wrapped.equals(currencyB.wrapped) ? undefined : currencyB

    // mint state
    const { independentField, typedValue, startPriceTypedValue } = useV3MintState()

    const {
        pool,
        ticks,
        dependentField,
        price,
        pricesAtTicks,
        pricesAtLimit,
        parsedAmounts,
        currencyBalances,
        position,
        noLiquidity,
        currencies,
        errorMessage,
        invalidPool,
        invalidRange,
        outOfRange,
        depositADisabled,
        depositBDisabled,
        invertPrice,
        ticksAtLimit,
    } = useV3DerivedMintInfo(
        baseCurrency ?? undefined,
        quoteCurrency ?? undefined,
        feeAmount,
        baseCurrency ?? undefined,
        existingPosition
    )

    const { onFieldAInput, onFieldBInput, onLeftRangeInput, onRightRangeInput, onStartPriceInput } =
        useV3MintActionHandlers(noLiquidity)

    const isValid = !errorMessage && !invalidRange

    // modal and loading
    const [showConfirm, setShowConfirm] = useState<boolean>(false)
    const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm
    const [tabSelector, setTabSelector] = useState(1)

    // capital efficiency warning
    const [showCapitalEfficiencyWarning, setShowCapitalEfficiencyWarning] = useState(false)

    useEffect(() => setShowCapitalEfficiencyWarning(false), [baseCurrency, quoteCurrency, feeAmount])

    // txn values
    const deadline = useTransactionDeadline() // custom from users settings

    const [txHash, setTxHash] = useState<string>('')

    // get formatted amounts
    const formattedAmounts = {
        [independentField]: typedValue,
        [dependentField]: parsedAmounts[dependentField]?.toSignificant() ?? '',
    }

    const usdcValues = {
        [Field.CURRENCY_A]: useStablecoinValue(parsedAmounts[Field.CURRENCY_A]),
        [Field.CURRENCY_B]: useStablecoinValue(parsedAmounts[Field.CURRENCY_B]),
    }

    // get the max amounts user can add
    const maxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
        (accumulator, field) => {
            return {
                ...accumulator,
                [field]: maxAmountSpend(currencyBalances[field]),
            }
        },
        {}
    )

    const atMaxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
        (accumulator, field) => {
            return {
                ...accumulator,
                [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
            }
        },
        {}
    )

    const argentWalletContract = useArgentWalletContract()

    // check whether the user has approved the router on the tokens
    const [approvalA, approveACallback] = useApproveCallback(
        argentWalletContract ? undefined : parsedAmounts[Field.CURRENCY_A],
        chainId ? NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId] : undefined
    )
    const [approvalB, approveBCallback] = useApproveCallback(
        argentWalletContract ? undefined : parsedAmounts[Field.CURRENCY_B],
        chainId ? NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId] : undefined
    )

    const DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)
    const allowedSlippage = useUserSlippageToleranceWithDefault(
        outOfRange ? ZERO_PERCENT : DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE
    )

    async function onAdd() {
        if (!chainId || !provider || !account) return

        if (!positionManager || !baseCurrency || !quoteCurrency) {
            return
        }
        setShowConfirm(true)
        if (position && account && deadline) {
            const useNative = baseCurrency.isNative ? baseCurrency : quoteCurrency.isNative ? quoteCurrency : undefined
            const { calldata, value } = NonfungiblePositionManager.addCallParameters(position, {
                tokenId,
                slippageTolerance: allowedSlippage,
                deadline: deadline.toString(),
                useNative,
            })

            let txn: { to: string; data: string; value: string } = {
                to: NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId],
                data: calldata,
                value,
            }

            if (argentWalletContract) {
                const amountA = parsedAmounts[Field.CURRENCY_A]
                const amountB = parsedAmounts[Field.CURRENCY_B]
                const batch = [
                    ...(amountA && amountA.currency.isToken
                        ? [approveAmountCalldata(amountA, NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId])]
                        : []),
                    ...(amountB && amountB.currency.isToken
                        ? [approveAmountCalldata(amountB, NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId])]
                        : []),
                    {
                        to: txn.to,
                        data: txn.data,
                        value: txn.value,
                    },
                ]
                const data = argentWalletContract.interface.encodeFunctionData('wc_multiCall', [batch])
                txn = {
                    to: argentWalletContract.address,
                    data,
                    value: '0x0',
                }
            }

            setAttemptingTxn(true)

            provider
                .getSigner()
                .estimateGas(txn)
                .then((estimate) => {
                    const newTxn = {
                        ...txn,
                        gasLimit: calculateGasMargin(estimate),
                    }

                    return provider
                        .getSigner()
                        .sendTransaction(newTxn)
                        .then(async (response: TransactionResponse) => {
                            await provider.waitForTransaction(response.hash)
                            addTransaction(response, {
                                type: TransactionType.ADD_LIQUIDITY_V3_POOL,
                                baseCurrencyId: currencyId(baseCurrency),
                                quoteCurrencyId: currencyId(quoteCurrency),
                                createPool: Boolean(noLiquidity),
                                expectedAmountBaseRaw: parsedAmounts[Field.CURRENCY_A]?.quotient?.toString() ?? '0',
                                expectedAmountQuoteRaw: parsedAmounts[Field.CURRENCY_B]?.quotient?.toString() ?? '0',
                                feeAmount: position.pool.fee,
                            })
                            setTxHash(response.hash)
                            sendEvent({
                                category: 'Liquidity',
                                action: 'Add',
                                label: [currencies[Field.CURRENCY_A]?.symbol, currencies[Field.CURRENCY_B]?.symbol].join('/'),
                            })

                            setAttemptingTxn(false)
                            notification.success({
                                message: "Added liquidity successful"
                            })
                            onClose()
                        })
                })
                .catch((error) => {
                    console.error('Failed to send transaction', error)
                    setAttemptingTxn(false)
                    // we only care if the error is something _other_ than the user rejected the tx
                    if (error?.code !== 4001) {
                        console.error(error)
                    }
                    notification.error({
                        message: "Add liquidity failed"
                    })
                })
        } else {
            return
        }
    }

    const handleCurrencySelect = useCallback(
        (currencyNew: Currency, currencyIdOther?: string): (string | undefined)[] => {
            const currencyIdNew = currencyId(currencyNew)

            if (currencyIdNew === currencyIdOther) {
                // not ideal, but for now clobber the other if the currency ids are equal
                return [currencyIdNew, undefined]
            } else {
                // prevent weth + eth
                const isETHOrWETHNew =
                    currencyIdNew === 'ETH' ||
                    (chainId !== undefined && currencyIdNew === WRAPPED_NATIVE_CURRENCY[chainId]?.address)
                const isETHOrWETHOther =
                    currencyIdOther !== undefined &&
                    (currencyIdOther === 'ETH' ||
                        (chainId !== undefined && currencyIdOther === WRAPPED_NATIVE_CURRENCY[chainId]?.address))

                if (isETHOrWETHNew && isETHOrWETHOther) {
                    return [currencyIdNew, undefined]
                } else {
                    return [currencyIdNew, currencyIdOther]
                }
            }
        },
        [chainId]
    )

    // const handleDismissConfirmation = useCallback(() => {
    //     setShowConfirm(false)
    //     // if there was a tx hash, we want to clear the input
    //     if (txHash) {
    //         onFieldAInput('')
    //         // dont jump to pool page if creating
    //         navigate('/pool')
    //     }
    //     setTxHash('')
    // }, [navigate, onFieldAInput, txHash])

    const addIsUnsupported = useIsSwapUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)

    const clearAll = useCallback(() => {
        onFieldAInput('')
        onFieldBInput('')
        onLeftRangeInput('')
        onRightRangeInput('')
    }, [onFieldAInput, onFieldBInput, onLeftRangeInput, onRightRangeInput])

    // get value and prices at ticks
    const { [Bound.LOWER]: tickLower, [Bound.UPPER]: tickUpper } = ticks
    const { [Bound.LOWER]: priceLower, [Bound.UPPER]: priceUpper } = pricesAtTicks

    // we need an existence check on parsed amounts for single-asset deposits
    const showApprovalA =
        !argentWalletContract && approvalA !== ApprovalState.APPROVED && !!parsedAmounts[Field.CURRENCY_A]
    const showApprovalB =
        !argentWalletContract && approvalB !== ApprovalState.APPROVED && !!parsedAmounts[Field.CURRENCY_B]

    const pendingText = `Supplying ${!depositADisabled ? parsedAmounts[Field.CURRENCY_A]?.toSignificant() : ''} ${!depositADisabled ? currencies[Field.CURRENCY_A]?.symbol : ''
        } ${!outOfRange ? 'and' : ''} ${!depositBDisabled ? parsedAmounts[Field.CURRENCY_B]?.toSignificant() : ''} ${!depositBDisabled ? currencies[Field.CURRENCY_B]?.symbol : ''
        }`

    useEffect(() => {
        clearAll()
    }, [])

    const [priceInputFiate, setPriceInputFiate] = useState<string | undefined>()
    const [priceOnputFiate, setPriceOnputFiate] = useState<string | undefined>()
    // show price

    const headers = {
        Authorization: `Bearer auth=gmeow`,
        'Content-Type': 'application/json',
    }
    // let addressTokenInput = currencyIdA as any
    // let addressTokenOutput = currencyIdB as any
    useEffect(() => {
        const fetchTokenPrice = async () => {
            if (currencyIdA !== null) {
                try {
                    let addressValue = currencyIdA === 'ETH' ? '0x4300000000000000000000000000000000000006' : currencyIdA
                    const res = await axios({
                        method: 'get',
                        url: `https://api.geckoterminal.com/api/v2/simple/networks/zircuit/token_price/${addressValue}`,
                        headers,
                    })
                    if (res) {
                        const price = res ? (Object.values(res.data.data.attributes.token_prices)[0] as string) : '0'
                        setPriceInputFiate(price)
                    }
                } catch (error) {
                    console.log('Error:', error)
                }
            }
        }

        fetchTokenPrice()
    }, [account, chainId, currencyIdA])

    useEffect(() => {
        const fetchTokenPrice = async () => {
            if (currencyIdB !== null) {
                try {
                    let addressValue = currencyIdB === 'ETH' ? '0x4200000000000000000000000000000000000006' : currencyIdB
                    const res = await axios({
                        method: 'get',
                        url: `https://api.geckoterminal.com/api/v2/simple/networks/zircuit/token_price/${addressValue}`,
                        headers,
                    })
                    if (res) {
                        const price = res ? (Object.values(res.data.data.attributes.token_prices)[0] as string) : '0'
                        setPriceOnputFiate(price)
                    }
                } catch (error) {
                    console.log('Error:', error)
                }
            }
        }

        fetchTokenPrice()
    }, [account, chainId, currencyIdB])

    const removed = existingPosition?.liquidity && JSBI.equal(existingPosition?.liquidity, JSBI.BigInt(0))

    const currency0 = useCurrency(currencyIdA)
    const currency1 = useCurrency(currencyIdB)

    const inverted = currency1 ? currency0?.equals(currency1) : undefined

    const ratio = useMemo(() => {
        return priceLower && pool && priceUpper
            ? getRatio(
                inverted ? priceUpper.invert() : priceLower,
                pool.token0Price,
                inverted ? priceLower.invert() : priceUpper
            )
            : undefined
    }, [inverted, pool, priceLower, priceUpper])

    const [rangeBaseCurrency, setRangeBaseCurrency] = useState<any>(currency0)
    const sorted = useMemo(() => currency0 === rangeBaseCurrency, [rangeBaseCurrency])
    const rangeQuoteCurrency = useMemo(() => sorted ? currency1 : currency0, [sorted])

    const selectPriceLower = sorted ? existingPosition?.token0PriceLower : existingPosition?.token0PriceUpper.invert()
    const selectPriceUpper = sorted ? existingPosition?.token0PriceUpper : existingPosition?.token0PriceLower.invert()

    const handleRateChange = useCallback((currency: Currency) => {
        setRangeBaseCurrency(currency)
    }, [quoteCurrency])


    const Buttons = () =>
        addIsUnsupported ? (
            <NotchedButtonFillPrimary disabled={true} padding="12px">
                <ThemedText.DeprecatedMain mb="4px">
                    <Trans>Unsupported Asset</Trans>
                </ThemedText.DeprecatedMain>
            </NotchedButtonFillPrimary>
        ) : !account ? (
            <NotchedButtonFill onClick={toggleWalletModal} padding="12px">
                <Trans>Connect Wallet</Trans>
            </NotchedButtonFill>
        ) : (
            <AutoColumn gap="md">
                {(approvalA === ApprovalState.NOT_APPROVED ||
                    approvalA === ApprovalState.PENDING ||
                    approvalB === ApprovalState.NOT_APPROVED ||
                    approvalB === ApprovalState.PENDING) &&
                    isValid && (
                        <RowBetween>
                            {showApprovalA && (
                                <NotchedButtonFill
                                    onClick={approveACallback}
                                    disabled={approvalA === ApprovalState.PENDING}
                                    width={showApprovalB ? '48%' : '100%'}
                                >
                                    {approvalA === ApprovalState.PENDING ? (
                                        <Dots>
                                            <Trans>Approving {currencies[Field.CURRENCY_A]?.symbol}</Trans>
                                        </Dots>
                                    ) : (
                                        <Trans>Approve {currencies[Field.CURRENCY_A]?.symbol}</Trans>
                                    )}
                                </NotchedButtonFill>
                            )}
                            {showApprovalB && (
                                <NotchedButtonFill
                                    onClick={approveBCallback}
                                    disabled={approvalB === ApprovalState.PENDING}
                                    width={showApprovalA ? '48%' : '100%'}
                                >
                                    {approvalB === ApprovalState.PENDING ? (
                                        <Dots>
                                            <Trans>Approving {currencies[Field.CURRENCY_B]?.symbol}</Trans>
                                        </Dots>
                                    ) : (
                                        <Trans>Approve {currencies[Field.CURRENCY_B]?.symbol}</Trans>
                                    )}
                                </NotchedButtonFill>
                            )}
                        </RowBetween>
                    )}
                <ButtonError
                    onClick={() => {
                        // expertMode ? onAdd() : setShowConfirm(true)
                        onAdd()
                    }}
                    disabled={
                        !isValid ||
                        (!argentWalletContract && approvalA !== ApprovalState.APPROVED && !depositADisabled) ||
                        (!argentWalletContract && approvalB !== ApprovalState.APPROVED && !depositBDisabled) || attemptingTxn
                    }
                    error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
                >
                    <Text fontWeight={500}>{errorMessage ? errorMessage : <Trans>ADD LIQUIDITY</Trans>}</Text>
                </ButtonError>
            </AutoColumn>
        )

    return <Modal isOpen={true} maxWidth={573} onDismiss={onClose}>
        <AddLiquidityV3PopupWrapper>
            <TitleBox>
                <h4>Increase liquidity</h4>

                <CloseIcon onClick={onClose} />
            </TitleBox>


            <DynamicSection
                disabled={tickLower === undefined || tickUpper === undefined || invalidPool || invalidRange}
            >
                <AutoColumn gap="md">
                    <div className={`deposit-amount-text`} style={{ fontSize: '21px' }}>
                        <Trans>Deposit amounts</Trans>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                    }}>
                        <PoolCurrencyInputPanel
                            hideInput={noLiquidity && (!priceLower || !priceUpper)}
                            value={formattedAmounts[Field.CURRENCY_A]}
                            onUserInput={onFieldAInput}
                            onMax={() => {
                                onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
                            }}
                            showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                            currency={currencies[Field.CURRENCY_A] ?? null}
                            id="add-liquidity-input-tokena"
                            fiatValue={usdcValues[Field.CURRENCY_A]}
                            showCommonBases
                            locked={depositADisabled}
                            customeFiatValue={priceInputFiate}
                        />

                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <img style={{ width: "18px", height: "18px" }}
                                src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/add-liquidity/img_6.png" alt="img_6" />
                        </div>

                        <PoolCurrencyInputPanel
                            hideInput={noLiquidity && (!priceLower || !priceUpper)}
                            value={formattedAmounts[Field.CURRENCY_B]}
                            onUserInput={onFieldBInput}
                            onMax={() => {
                                onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
                            }}
                            showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                            fiatValue={usdcValues[Field.CURRENCY_B]}
                            currency={currencies[Field.CURRENCY_B] ?? null}
                            id="add-liquidity-input-tokenb"
                            showCommonBases
                            locked={depositBDisabled}
                            customeFiatValue={priceOnputFiate}
                        />
                    </div>
                </AutoColumn>
            </DynamicSection>

            <div style={{
                width: '100%',
                height: '1px',
                background: 'rgba(229, 182, 112, 0.50)',
                margin: '24px 0'
            }}></div>

            <AutoColumn gap="12px">
                <FixedHeightRow>
                    <RowFixed gap="10px">
                        <CurrencyLogo size="20px" currency={existingPosition?.pool.token0} />
                        <Text fontSize={16} fontWeight={500} color="#4B2828">
                            <Trans>Pooled {existingPosition?.pool.token0.symbol}:</Trans>
                        </Text>
                    </RowFixed>
                    <RowFixed style={{ wordBreak: 'break-all' }}>
                        <Text fontSize={16} fontWeight={500} marginLeft="6px" color="#4B2828">
                            {existingPosition?.amount0?.toSignificant()}
                            {typeof ratio === 'number' && !removed ? (
                                <InvertedTxt>
                                    <Trans>{!inverted ? ratio : 100 - ratio}%</Trans>
                                </InvertedTxt>
                            ) : null}
                        </Text>
                    </RowFixed>
                </FixedHeightRow>

                <FixedHeightRow>
                    <RowFixed gap="10px">
                        <CurrencyLogo size="20px" currency={existingPosition?.pool.token1} />
                        <Text fontSize={16} fontWeight={500} color="#4B2828">
                            <Trans>Pooled {existingPosition?.pool.token1.symbol}:</Trans>
                        </Text>
                    </RowFixed>
                    <RowFixed style={{ wordBreak: 'break-all' }}>
                        <Text fontSize={16} fontWeight={500} marginLeft="6px" color="#4B2828">
                            {existingPosition?.amount1?.toSignificant()}
                            {typeof ratio === 'number' && !removed ? (
                                <InvertedTxt>
                                    <Trans>{inverted ? ratio : 100 - ratio}%</Trans>
                                </InvertedTxt>
                            ) : null}
                        </Text>
                    </RowFixed>
                </FixedHeightRow>

                <FixedHeightRow>
                    <Text fontSize={16} fontWeight={500} color="#4B2828">
                        <Trans>Range</Trans>
                    </Text>
                    <RangeBadge removed={removed} inRange={!invalidRange} />
                </FixedHeightRow>

                <FixedHeightRow>
                    <Text fontSize={16} fontWeight={500} color="#4B2828">
                        <Trans>Fee tier</Trans>
                    </Text>
                    <Text fontSize={16} fontWeight={500} color="#4B2828">
                        <Trans>
                            {
                                Number(feeAmount?.toString()) / 10000
                            }%
                        </Trans>
                    </Text>
                </FixedHeightRow>

                <FixedHeightRow style={{
                    display: window.screen.width <= 400 ? 'grid' : 'flex',
                    height: 'unset',
                    gap: window.screen.width <= 400 ? '4px' : '16px',
                }}>
                    <Text fontSize={16} fontWeight={500} color="#4B2828">
                        <Trans>Current price</Trans>
                    </Text>
                    <div style={{ width: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '5px' }}>
                        <div
                            style={{
                                color: "#4B2828",
                                fontFamily: "Outfit",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "125%" /* 20px */
                            }}
                        >
                            <HoverInlineText
                                maxCharacters={20}
                                text={!sorted ? formatNumber(price?.invert().toSignificant()) : formatNumber(price?.toSignificant())}
                            />
                        </div>
                        <div
                            style={{
                                color: "rgba(75, 40, 40, 0.50)",
                                fontFamily: "Outfit",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "125%" /* 20px */
                            }}
                        >
                            {(!sorted ? currency0 : currency1)?.symbol} per {(sorted ? currency0 : currency1)?.symbol}
                        </div>
                    </div>
                </FixedHeightRow>
            </AutoColumn>

            <div style={{
                width: '100%',
                height: '1px',
                background: 'rgba(229, 182, 112, 0.50)',
                margin: '24px 0'
            }}></div>

            <AutoColumn gap="12px">
                <FixedHeightRow>

                    <Text fontSize={16} fontWeight={500} color="#4B2828">
                        <Trans>Selected Range</Trans>
                    </Text>

                    <RateToggle
                        // @ts-ignore
                        currencyA={sorted ? currency0 : currency1}
                        // @ts-ignore
                        currencyB={sorted ? currency1 : currency0}
                        handleRateToggle={handleRateChange}
                    />
                </FixedHeightRow>

                <AutoColumn gap="md">
                    <RowBetween style={{
                        display: window.screen.width <= 400 ? 'grid' : 'flex',
                    }}>
                        <LightCard width="100%" padding="16px" style={{ background: '#FFF9E1', borderRadius: '10px' }}>
                            <AutoColumn gap="4px" justify="start">
                                <div style={{ fontSize: '12px' }}>
                                    <Trans>Min Price</Trans>
                                </div>

                                <Row style={{ gap: '4px' }} justify={'start'}>
                                    <div style={{ textAlign: 'center', color: '#4B2828' }}>
                                        {formatTickPrice({
                                            price: selectPriceLower,
                                            atLimit: ticksAtLimit,
                                            direction: Bound.LOWER,
                                        })}
                                    </div>
                                    <div style={{ fontSize: '12px', textAlign: 'center' }}>
                                        <Trans>
                                            {rangeQuoteCurrency?.symbol} per {rangeBaseCurrency?.symbol}
                                        </Trans>
                                    </div>
                                </Row>
                                <Text
                                    textAlign="start"
                                    color={'#E5B670'}
                                    fontSize={12}
                                    style={{ marginTop: '4px' }}
                                >
                                    <Trans>Your position will be 100% composed of {rangeBaseCurrency?.symbol} at this
                                        price</Trans>
                                </Text>
                            </AutoColumn>
                        </LightCard>

                        <LightCard width="100%" padding="16px" style={{ background: '#FFF9E1', borderRadius: '10px' }}>
                            <AutoColumn gap="4px" justify="start">
                                <div style={{ fontSize: '12px' }}>
                                    <Trans>Max Price</Trans>
                                </div>
                                <Row style={{ gap: '4px' }} justify={'start'}>
                                    <div style={{ textAlign: 'start', color: '#4B2828' }}>
                                        {formatTickPrice({
                                            price: selectPriceUpper,
                                            atLimit: ticksAtLimit,
                                            direction: Bound.UPPER,
                                        })}
                                    </div>
                                    <div style={{ fontSize: '12px', textAlign: 'start' }}>
                                        <Trans>
                                            {rangeQuoteCurrency?.symbol} per {rangeBaseCurrency?.symbol}
                                        </Trans>
                                    </div>
                                </Row>
                                <Text
                                    textAlign="start"
                                    color={'#E5B670'}
                                    fontSize={12}
                                    style={{ marginTop: '4px' }}
                                >
                                    <Trans>Your position will be 100% composed of {rangeQuoteCurrency?.symbol} at this
                                        price</Trans>
                                </Text>
                            </AutoColumn>
                        </LightCard>
                    </RowBetween>
                </AutoColumn>
            </AutoColumn>

            <div style={{
                marginTop: '32px'
            }}>
                <Buttons />
            </div>
        </AddLiquidityV3PopupWrapper>

    </Modal>
}