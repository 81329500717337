import { ReactNode } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { BREAKPOINTS } from "theme"


const BreadcrumbWraper = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        gap: 12px;
    }
`

const BreadcrumbItemBox = styled(BreadcrumbWraper) <{
    isActive?: boolean,
    hasIcon?: boolean
}>`
    margin-left: ${({ hasIcon }) => hasIcon ? '25px' : 0};
    span{
        color: ${({ isActive }) => isActive ? '#4B2828' : 'rgba(75, 40, 40, 0.50)'};
        cursor: ${({ isActive }) => isActive ? 'unset' : 'pointer'};
        transition: scale 0.15s ease-in-out, color 0.15s ease-in-out;
    }
     
    &:hover span{
        color: #4B2828;
        scale: ${({ isActive }) => isActive ? 'unset' : '1.05'};
    }
    svg{
        margin-top: 3px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        svg{
            width: 14px;
        }
    }
`
const BreadcrumbItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    span{
        font-family: DynaPuff;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 40px */ 
        @media screen and (max-width: ${BREAKPOINTS.md}px) {
            font-size: 28px;
        }
        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
            font-size: 16px;
        }
    }
`

const ArrowIcon = () => <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.72 0.839998C4.84 0.839998 6.02667 1.05333 7.28 1.48C8.53333 1.90667 9.76 2.46666 10.96 3.16C12.16 3.85333 13.2533 4.61333 14.24 5.44C15.2267 6.26667 16.0133 7.08 16.6 7.88C17.1867 8.65333 17.48 9.34667 17.48 9.96C17.48 10.5733 17.1733 11.3067 16.56 12.16C15.9467 12.9867 15.12 13.8533 14.08 14.76C13.0667 15.64 11.9333 16.4667 10.68 17.24C9.42667 17.9867 8.14667 18.6 6.84 19.08C5.53333 19.56 4.30667 19.8 3.16 19.8C2.25333 19.8 1.6 19.5467 1.2 19.04C0.8 18.5333 0.6 17.9867 0.6 17.4C0.6 16.2 1.73333 15 4 13.8C6.29333 12.5733 9.42667 11.4267 13.4 10.36V10.76C9.4 9.58667 6.33333 8.37333 4.2 7.12C2.09333 5.86667 1.04 4.61333 1.04 3.36C1.04 2.56 1.26667 1.94667 1.72 1.52C2.2 1.06666 2.86667 0.839998 3.72 0.839998Z" fill="#4B2828" fill-opacity="0.5" />
</svg>


type BreadcrumbItemType = {
    text: string
    icon?: ReactNode
    isActive?: boolean
    link?: string
}
export default function BreadcrumbList(props: {
    list: BreadcrumbItemType[]
}) {
    const navigate = useNavigate()
    const list: BreadcrumbItemType[] = props.list ?? []

    const goTo = (link?: string) => {
        if (link)
            navigate(link)
    }

    return <BreadcrumbWraper>
        {
            list.map((item, index) => <BreadcrumbItemBox key={index} isActive={item.isActive} onClick={() => goTo(item.link)} hasIcon={item.icon != undefined}>
                <BreadcrumbItem >
                    {item.icon && item.icon}
                    <span>{item.text}</span>
                </BreadcrumbItem>
                {
                    index < (list.length - 1) && <ArrowIcon />
                }
            </BreadcrumbItemBox>)
        }




    </BreadcrumbWraper>
}