import { useWeb3React } from "@web3-react/core"
import AlphaPositionV3List from "./AlphaPositionV3List"
import { useEffect, useMemo, useState } from "react"
import { ethers } from "ethers"
import { V3STAKER_ADDRESSES_MAP } from "constants/addresses"
import { useCustomeContract } from "hooks/useContract"
import v3staker_abi from '../../abis/alpha/V3Staker.json'
import { defaultAbiCoder } from "ethers/lib/utils"
import { NotchedButtonFill } from "components/Button"
import styled from "styled-components"
import { notification } from "antd"
import { DEFAULT_CHAINID } from "../../constants/chains";
import { getGasPriceSetting } from "utils/gasSetting"

const ButtonBox = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
`

function calculateTotalLiquidity(data: any) {
  return data?.reduce((total: any, item: any) => {
    const totalValue = Number(item?.userLiquidity)
    return total + totalValue
  }, 0)
}

type AlphaWithdrawModalProps = {
  isOpen: boolean
  onDismiss: () => void
  poolInfo: any
  tokenInfo: any
  userPositionsLocked: any
  handleDataFromChild: any
  handleGetTime: any
  handleUserTotalLiquidity: any
}
export default function AlphaWithdrawModal({
  isOpen,
  onDismiss,
  poolInfo,
  tokenInfo,
  userPositionsLocked,
  handleDataFromChild,
  handleGetTime,
  handleUserTotalLiquidity,
}: AlphaWithdrawModalProps) {
  const { account, chainId } = useWeb3React()
  const [customeChainId, setCustomeChainId] = useState(DEFAULT_CHAINID)
  useEffect(() => {
    if (chainId) {
      setCustomeChainId(chainId)
    } else {
      setCustomeChainId(DEFAULT_CHAINID)
    }
  }, [account, chainId])
  // state
  const [loadingUnstake, setLoadingUnstake] = useState(false)
  const [loadingUserStakeInfo, setLoadingUserStakeInfo] = useState(false)
  const [stakeNFTList, setStakeUserList] = useState<any>([])
  // select to harvest
  const [checkedIds, setCheckedIds] = useState<number[]>([])
  const onCheckedNftId = (formattedTokenId: number, isChecked: boolean) => {
    if (isChecked)
      setCheckedIds([formattedTokenId])
    else
      setCheckedIds(checkedIds.filter(nftId => nftId != formattedTokenId))
  }
  const totalHarvestReward = useMemo(() => {
    if (checkedIds.length == 0) return 0
    let totalRw = 0
    for (let index = 0; index < stakeNFTList.length; index++) {
      const element = stakeNFTList[index];

      console.log('element', element);

      if (checkedIds.includes(Number(element.formattedTokenId)))
        totalRw += element.rewardValue
    }

    console.log('totalRw', totalRw);
    return totalRw
  }, [checkedIds])

  function mapStakeAndUnstakeCallData(checkedIds: any, poolInfo: any) {
    return checkedIds.map((id: any, index: any) => {
      return {
        ['Data']: [
          {
            rewardToken: poolInfo.rewardToken,
            pool: poolInfo.pool,
            startTime: ethers.BigNumber.from(poolInfo.startTime),
            endTime: ethers.BigNumber.from(poolInfo.endTime),
            lockDuration: ethers.BigNumber.from(poolInfo.lockDuration),
            refundee: poolInfo.refundee,
          },
          ethers.BigNumber.from(id),
        ],
      }
    })
  }
  function mapGetData(poolInfo: any) {
    const rewardToken = poolInfo.rewardToken
    const refundee = poolInfo.refundee
    const pool = poolInfo.pool
    const startTime = ethers.BigNumber.from(poolInfo.startTime)
    const endTime = ethers.BigNumber.from(poolInfo.endTime)
    const lockDuration = ethers.BigNumber.from(poolInfo.lockDuration)

    const result = [rewardToken, pool, startTime, endTime, lockDuration, refundee]
    return result
  }
  // using custom image


  // contract
  const STAKING_ADDRESS = V3STAKER_ADDRESSES_MAP[chainId!]

  const v3stakerWithSign = useCustomeContract(STAKING_ADDRESS, v3staker_abi, true)

  // get user stake info
  const handleGetUserInfo = async () => {
    setLoadingUserStakeInfo(true)
    try {
      const tokenIdByUser = await v3stakerWithSign?.getTokensByOwner(account, '0', '100000')
      const items = await Promise.all(
        tokenIdByUser?.map(async (item: any, index: number) => {
          const userPosition = userPositionsLocked[index]
          const paramsGetRewardInfo = mapGetData(poolInfo)
          const userRewardByTokenId = await v3stakerWithSign?.getRewardInfos([item.tokenId])
          let timeNowStampInSeconds = Math.floor(Date.now() / 1000)
          let lockedUntil = item.deposit.lockUntil.toString()
          let isUnstake = Number(timeNowStampInSeconds) < Number(lockedUntil) ? false : true
          let countdownTime =
            Number(lockedUntil) - Number(timeNowStampInSeconds) > 0
              ? Number(lockedUntil)
              : 0

          const encodedKey = defaultAbiCoder.encode(
            [
              'address',
              'address',
              'uint256',
              'uint256',
              'uint256',
              'address'
            ],
            [
              item.incentiveKey.rewardToken,
              item.incentiveKey.pool,
              item.incentiveKey.startTime,
              item.incentiveKey.endTime,
              item.incentiveKey.lockDuration,
              item.incentiveKey.refundee
            ]
          );
          const incentiveId = ethers.utils.keccak256(encodedKey)

          return {
            tokenId: item.tokenId.toString(),
            formattedTokenId: item.tokenId.toString(),
            userReward: paramsGetRewardInfo,
            // rewardValue: Number(userRewardByTokenId[0]?.reward),
            rewardValue: Number(ethers?.utils?.formatUnits(userRewardByTokenId[0]?.reward, 18)),
            lockedTime: paramsGetRewardInfo[4].toString(),
            lockedUntil: lockedUntil,
            isUnstake: isUnstake,
            countdownTime: countdownTime,
            poolAddress: item.incentiveKey.pool,
            userLiquidity: ethers?.utils?.formatUnits(item?.liquidity),
            outOfRange: userPosition?.outOfRange ?? false,
            incentiveId: incentiveId,
            token0: poolInfo?.token0,
            token1: poolInfo?.token1
          }
        })
      )

      const encodedKey = defaultAbiCoder.encode(
        [
          'address',
          'address',
          'uint256',
          'uint256',
          'uint256',
          'address'
        ],
        [
          poolInfo?.rewardToken,
          poolInfo?.pool,
          poolInfo?.startTime,
          poolInfo?.endTime,
          poolInfo?.lockDuration,
          poolInfo?.refundee
        ]
      );
      const poolInfoIncentiveId = ethers.utils.keccak256(encodedKey)


      let filterByPool = items?.filter((item: any) => {
        return item?.poolAddress?.toLowerCase() === poolInfo?.pool?.toLowerCase() &&
          (item?.incentiveId?.toLowerCase() === poolInfoIncentiveId?.toLowerCase())
      })
      let totalLiq = calculateTotalLiquidity(filterByPool).toFixed(2)
      setStakeUserList(filterByPool)
      handleDataFromChild(filterByPool?.length)
      handleGetTime(filterByPool[0]?.countdownTime)
      handleUserTotalLiquidity(totalLiq)
      setLoadingUserStakeInfo(false)
    } catch (error) {
      console.log(error)
      setLoadingUserStakeInfo(false)
    }
  }

  const handleUnstake = async () => {
    if (checkedIds.length == 0) {
      notification.warning({
        message: 'Please choose nft that you want stake'
      })
      return
    }

    setLoadingUnstake(true)
    try {
      const paramStakeAndUnstakeCalldata = mapStakeAndUnstakeCallData(checkedIds, poolInfo)
      let formmattedTokenId = paramStakeAndUnstakeCalldata[0].Data[1]
      if (account) {
        const claimCalldata = v3stakerWithSign?.interface.encodeFunctionData(
          'claimReward',
          [poolInfo.rewardToken, account, ethers.constants.MaxUint256]
        )
        const unstakeCalldata = v3stakerWithSign?.interface.encodeFunctionData(
          'unstakeToken',
          paramStakeAndUnstakeCalldata[0].Data
        )
        const withdraw = v3stakerWithSign?.interface.encodeFunctionData('withdrawToken', [
          formmattedTokenId,
          account?.toString(),
          '0x',
        ])

        const gasEstimate = await v3stakerWithSign?.estimateGas.multicall([unstakeCalldata, claimCalldata, withdraw]);
        const gasLimit = Math.floor(gasEstimate ? gasEstimate.toNumber() * 2 : 200000)
        const gasPrice = undefined

        const tx = await v3stakerWithSign?.multicall([unstakeCalldata, claimCalldata, withdraw], {
          gasLimit,
          gasPrice
        })
        await tx.wait()
        if (tx) {
          console.log('tx', tx)
          setLoadingUnstake(false)
          handleGetUserInfo()
          setCheckedIds([])
        }
      }
    } catch (error) {
      console.log(error)
      setLoadingUnstake(false)
    }
  }
  useEffect(() => {
    if (isOpen) {
      handleGetUserInfo()
      setCheckedIds([])
    }
  }, [isOpen])

  const ModalBottom = () => {
    return <>
      {
        stakeNFTList.length == 0 ? <div style={{ marginTop: '20px' }}>
          <NotchedButtonFill
            // isShowDirectly={true} bg="#E5B670" textColor="#FFF9E1" 
            mbEarMaxW="110px"
            onClick={onDismiss}>
            CANCEL
          </NotchedButtonFill>
        </div>
          :
          <ButtonBox>
            <NotchedButtonFill
              // isShowDirectly={true} bg="#E5B670" textColor="#FFF9E1"
              mbEarMaxW="110px"
              onClick={onDismiss}>
              CANCEL
            </NotchedButtonFill>
            <NotchedButtonFill
              mbEarMaxW="110px"
              onClick={handleUnstake}
            // isShowDirectly={true} bg="#00E440"  textColor="#FFF9E1"
            >
              {loadingUnstake ? 'UNSTAKING...' : 'UNSTAKE'}
            </NotchedButtonFill>
          </ButtonBox>
      }
    </>
  }

  return <AlphaPositionV3List
    title="Unstake from"
    desc="Transfer your V3 LP back to your wallet and stop earning yield from this Alpha pool."
    isOpen={isOpen}
    onDismiss={onDismiss}
    loading={loadingUserStakeInfo}
    nftInfoList={stakeNFTList}
    showPositionType='UNSTAKE'
    checkedNftIds={checkedIds}
    onCheckedNftId={onCheckedNftId}
    totalHarvestReward={totalHarvestReward}
    modalBottom={<ModalBottom />}
    tokenCurrencyA={tokenInfo[0].currencyA}
    tokenCurrencyB={tokenInfo[0].currencyB}
  />
}