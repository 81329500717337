import './leaderboard.css'
import styled from 'styled-components';
import { shortenAddress } from 'utils';
import { formatXpNumber } from 'pages/Xp/sections/XpTitle';
import { useWeb3React } from '@web3-react/core';
import { BREAKPOINTS } from 'theme';
import { RewardType } from './XpLeaderboard';
import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';


export const TheadLabel = styled.p`
  color: #4B2828;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 17.5px */
  text-transform: uppercase;
  padding: 18px 0;
  margin: 0;
  text-align: left;
  span {
    color: #4B2828;
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 17.5px */
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 10px;
    line-height: 125%;
  }
`;

export const Text = styled.p`
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 10px;
    line-height: 125%;
  }
`;

export const RankText = styled.div`
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 22.5px */
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 10px;
    line-height: 125%;
  }
`;

export const RewardColBox = styled.div`
  display: flex;
  gap: 8px;
  justify-content: start;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    display: grid;
    padding: 8px 0;
  }
`;
const RewardColBoxRow = styled.div`
  display: flex;
  gap: 8px;
  justify-content: start;
`;
export const RewardColBoxItem = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 10px;
    line-height: 125%;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    min-width: 45px;
  }
`;

export const RewardColSeperator = styled.span<{
  hiddenOnMobile?: boolean
}>`
  background: #ffffff57;
  width: 2px;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    display: ${({ hiddenOnMobile }) => hiddenOnMobile ? 'none' : ''};
  }
`;

const RankTd = styled.td`
  /* margin-left: 20px; */
  min-width: 38px;
  
  @media screen and (max-width: ${BREAKPOINTS.xs}px) {
    width: 48px;
  }
`

const PaginatedTable = ({ data, myXp, maxSize }: {
  data: any;
  myXp?: {
    rank: number;
    xp: number;
    reward?: RewardType
  },
  maxSize: number;
}) => {
  const { account } = useWeb3React();

  const [lastCharCnt, setLastCharCnt] = useState<number>(4);

  const [myRank, setMyRank] = useState<number | string>(0);
  const truncateAddress = (address?: string, lastCharCnt: number = 4) => {
    try {
      return shortenAddress(address ?? '', lastCharCnt)
    }
    catch (err) {
      return 'undefined';
    }
  }
  useEffect(() => {
    if (!myXp) return;
    if (myXp.xp == 0) setMyRank('~');
    else if (myXp.rank > 0) setMyRank(myXp.rank);
    else if (data.length < maxSize && myXp.rank == 0) setMyRank(data.length + 1);
    else setMyRank('>' + maxSize);
  }, [myXp])
  useEffect(() => {
    if (window.screen.width < 600)
      setLastCharCnt(2);
  }, [])
  return (
    <>
      <div className="paginate-table" style={{ fontFamily: 'Urbanist' }}>
        <table>
          <thead>
            <tr >
              <th>
                <TheadLabel style={{ paddingLeft: '20px' }}>
                  #
                </TheadLabel>
              </th>
              <th>
                <TheadLabel>
                  USER
                </TheadLabel>
              </th>
              <th>
                <TheadLabel>
                  CURRENT XP
                </TheadLabel>
              </th>
              <th>
                <TheadLabel>
                  NEXT REWARDS
                </TheadLabel>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="paginate-table" style={{ fontFamily: 'Urbanist' }}>
        <table>
          <tbody>
            {
              myXp &&
              <tr style={{ background: '#80808029', borderBottom: 'unset' }}>
                <RankTd >
                  <Text style={{ paddingLeft: '20px' }}>
                    #{myRank}
                  </Text>
                </RankTd>

                <td >
                  <Text>  {account && truncateAddress(account, lastCharCnt)} (You)</Text>
                </td>
                <td>
                  <Text> {formatXpNumber(myXp?.xp)}</Text>
                </td>
                <td>
                  <RewardColBox >
                    <RewardColBoxRow>
                      <RewardColBoxItem>
                        <span>{formatXpNumber(myXp?.reward?.musd)}</span>
                        <Tooltip title="MUSD">
                          <img style={{ cursor: 'pointer', width: '20px', height: '20px' }} src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/musd/musd.svg' />
                        </Tooltip>
                      </RewardColBoxItem>
                      <RewardColSeperator />
                      <RewardColBoxItem>
                        <span>{formatXpNumber(myXp?.reward?.xmono)}</span>
                        <Tooltip title="xMONO">
                          <img style={{
                            cursor: 'pointer', width: '20px', height: '20px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)'
                          }} src='https://raw.githubusercontent.com/Monoswap-Labs/monoswap-token-list/main/img/blast_sepolia/assets/0xa07aC8cDe2a98B189477b8e41F0c2Ea6CdDbC055/logo.png' />
                        </Tooltip>

                      </RewardColBoxItem>
                      <RewardColSeperator hiddenOnMobile={true} />
                    </RewardColBoxRow>

                    <RewardColBoxRow>
                      <RewardColBoxItem>
                        <span>{formatXpNumber(myXp?.reward?.point ?? 0)}</span>
                        <Tooltip title="Blast Point">
                          <img style={{ cursor: 'pointer', width: '20px', height: '20px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)' }} src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chain-logo/blast.png' />
                        </Tooltip>

                      </RewardColBoxItem>
                      <RewardColSeperator />
                      <RewardColBoxItem>
                        <span>{formatXpNumber(myXp?.reward?.goldPoint ?? 0)}</span>
                        <Tooltip title="Blast Gold">
                          <img style={{ cursor: 'pointer', width: '20px', height: '20px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)' }} src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/xp/blast-gold-icon.png' />
                        </Tooltip>

                      </RewardColBoxItem>
                    </RewardColBoxRow>
                  </RewardColBox>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>

      <div className="paginate-table" style={{ fontFamily: 'Urbanist' }}>
        <table>
          <tbody>
            {data?.map((row: any, index: number) => (
              <tr key={row?.address}>
                <td >
                  <Text style={{ paddingLeft: '20px' }}>
                    <RankText style={{ fontWeight: row?.rank <= 3 ? 700 : 500 }}>
                      #{row?.rank}
                    </RankText>
                  </Text>
                </td>
                <td>
                  <Text style={{ fontWeight: row?.rank <= 3 ? 700 : 500 }}> {truncateAddress(row?.address, lastCharCnt)}</Text>
                </td>
                <td>
                  <Text style={{ fontWeight: row?.rank <= 3 ? 700 : 500 }}> {formatXpNumber(row?.xp)}</Text>
                </td>
                <td>
                  <RewardColBox >
                    {
                      row?.rank <= 100 &&
                      <RewardColBoxRow>
                        <RewardColBoxItem style={{ fontWeight: row?.rank <= 3 ? 700 : 500 }}>
                          <span>{formatXpNumber(row?.reward?.musd ?? 0)}</span>
                          <Tooltip title="MUSD">
                            <img style={{ cursor: 'pointer', width: '18px', height: '18px' }} src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/musd/musd.svg' />
                          </Tooltip>
                        </RewardColBoxItem>
                        <RewardColSeperator />
                        <RewardColBoxItem style={{ fontWeight: row?.rank <= 3 ? 700 : 500 }}>
                          <span>{formatXpNumber(row?.reward?.xmono)}</span>
                          <Tooltip title="xMONO">
                            <img style={{
                              cursor: 'pointer', width: '20px', height: '20px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)'
                            }} src='https://raw.githubusercontent.com/Monoswap-Labs/monoswap-token-list/main/img/blast_sepolia/assets/0xa07aC8cDe2a98B189477b8e41F0c2Ea6CdDbC055/logo.png' />
                          </Tooltip>

                        </RewardColBoxItem>
                        <RewardColSeperator hiddenOnMobile={true} />
                      </RewardColBoxRow>
                    }

                    <RewardColBoxRow>
                      <RewardColBoxItem style={{ fontWeight: row?.rank <= 3 ? 700 : 500 }}>
                        <span>{formatXpNumber(Number(row?.reward?.point ?? 0).toFixed(2))}</span>
                        <Tooltip title="Blast Point">
                          <img style={{ cursor: 'pointer', width: '20px', height: '20px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)' }} src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chain-logo/blast.png' />
                        </Tooltip>
                      </RewardColBoxItem>
                      <RewardColSeperator />
                      <RewardColBoxItem style={{ fontWeight: row?.rank <= 3 ? 700 : 500 }}>
                        <span>{formatXpNumber(Number(row?.reward?.goldPoint ?? 0).toFixed(2))}</span>
                        <Tooltip title="Blast Gold">
                          <img style={{ cursor: 'pointer', width: '20px', height: '20px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)' }} src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/xp/blast-gold-icon.png' />
                        </Tooltip>
                      </RewardColBoxItem>
                    </RewardColBoxRow>
                  </RewardColBox>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>

  )
}

export default PaginatedTable
