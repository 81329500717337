import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'
import {useEffect, useState} from "react";
import TextScramble from "./components/TextScarmble";
import { useNavigate } from 'react-router-dom';

const WrapperSection2 = styled.section`
    background: #ffe7ab;
    width: 100%;
    height: 650px;
    margin: 0px auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 140px;
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        height: 473px;
        margin-bottom: 60px;
    }
`

const WrapperContent = styled.div`
    padding: 24px 45px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 145px;

    .cat_foot_tablet{
        display: block;
    }
    .cat_foot_mobile{
        display: none;
    }
    @media screen and (max-width: ${BREAKPOINTS.xl}px){
        gap: 60px;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        padding: 24px;
        gap: 40px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        .cat_foot_tablet{
            display: none;
        }
        .cat_foot_mobile{
            display: block;
        }
    }
`

const Content = styled.div`
    width: 100%;
    max-width: 1170px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;

    h2 {
        color: #4B2828;
        font-family: DynaPuff;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 40px */
        max-width: 473px;
        margin: 0;
    }
    @media screen and (max-width: ${BREAKPOINTS.xl}px){
        display: grid;
        gap: 40px;
        align-items: center;
        justify-items: start;
        justify-content: center;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        h2{
            font-size: 32px;
            max-width: 329px;
        }
    }
`

const WrapperImage = styled.div`
    width: 485px;
    height: 158px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        width: 327px;
        height: 100px;
    }
    img{
        cursor: pointer;
    }
    img:hover {
        scale: 1.05;
        transition: ease-in-out;
        transition-delay: 200ms;
    }
`


export default function Section4() {
    const navigate = useNavigate()
    const phrases = [
        'Ready to collect some purr-fectly cute crypto cats?',
    ];

    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index == phrases.length - 1) {
            return;
        }

        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [index, phrases]);
    return (
        <>
            <WrapperSection2>
                <WrapperContent>
                    <img width={'100%'} className='cat_foot_tablet' height={"76px"} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/foot.png" alt="foot" />
                    <img width={'100%'} className='cat_foot_mobile' height={"40px"} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section4/cat-foot-img.png" alt="foot" />
                    <Content>
                        <TextScramble text={phrases[index]}/>
                        <WrapperImage>
                            <img onClick={()=> navigate('/add')} width={'100%'} height={'100%'} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section4/img.png" alt="img" />
                        </WrapperImage>
                    </Content>
                    <img width={'100%'} className='cat_foot_tablet' height={"76px"} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/foot.png" alt="foot" />
                    <img width={'100%'} className='cat_foot_mobile' height={"40px"} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section4/cat-foot-img.png" alt="foot" />
                </WrapperContent>
            </WrapperSection2>
        </>
    )
}
