import { Trans } from '@lingui/macro'
import Badge, { BadgeVariant } from 'components/Badge'
import { AlertCircle } from 'react-feather'
import styled from 'styled-components/macro'

import { MouseoverTooltip } from '../../components/Tooltip'
import { CloseIcon } from 'theme'

const WrapperInrange = styled.div`
    display: flex;
    height: 18px;
    padding: 4px 5px;
    align-items: center;
    border-radius: 50px;
    background: rgba(0, 228, 64, 0.12);
`


const BadgeWrapper = styled.div`
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
`

const BadgeText = styled.div`
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;

    span {
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 15px */
    }
`



const ActiveDot = styled.span`
    background-color: ${({ theme }) => theme.deprecated_success};
    height: 12px;
    width: 12px;
    margin-right: 4px;
`

export default function RangeBadge({
    removed,
    inRange,
}: {
    removed: boolean | undefined
    inRange: boolean | undefined
}) {
    return (
        <BadgeWrapper>
            {removed ? (
                <MouseoverTooltip text={<Trans>Your position has 0 liquidity, and is not earning fees.</Trans>}>
                    <Badge variant={BadgeVariant.DEFAULT} style={{
                        height: '24px',
                        background: 'rgba(75, 40, 40, 0.12)',
                        borderRadius: '20px',
                        color: 'rgba(75, 40, 40, 0.50)',
                        padding: '4px 6px'
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <circle cx="6" cy="6" r="4" stroke="#9A8372" strokeWidth="1.5" />
                            <line x1="9.16119" y1="2.77642" x2="2.79723" y2="9.14038" stroke="#9A8372" strokeWidth="1.5" />
                        </svg>

                        &nbsp;
                        <BadgeText>
                            <Trans>Closed</Trans>
                        </BadgeText>
                    </Badge>
                </MouseoverTooltip>
            ) : inRange ? (
                <MouseoverTooltip
                    text={
                        <Trans>
                            The price of this pool is within your selected range. Your position is currently earning
                            fees.
                        </Trans>
                    }
                >
                    <WrapperInrange>
                        {/*<Badge variant={BadgeVariant.POSITIVE}>*/}
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M1.2002 6L4.2002 9L10.2002 3" stroke="#05C63B" strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round" />
                        </svg>
                        &nbsp;
                        <BadgeText>
                            <Trans><span style={{ color: "#05C63B" }}>In range</span></Trans>
                        </BadgeText>
                        {/*</Badge>*/}
                    </WrapperInrange>
                </MouseoverTooltip>
            ) : (
                <MouseoverTooltip
                    text={
                        <Trans>
                            The price of this pool is outside of your selected range. Your position is not currently
                            earning fees.
                        </Trans>
                    }
                >
                    <Badge style={{
                        background: 'rgba(255, 0, 0, 0.12)',
                        borderRadius: '20px',
                        padding: '4px 6px',
                        height: '24px'
                    }} variant={BadgeVariant.WARNING}>
                        <CloseIcon style={{
                            width: '14px',
                            color: 'red'
                        }} onClick={() => { }} />
                        &nbsp;
                        <BadgeText style={{ color: 'red', fontFamily: 'Outfit', fontWeight: '500', fontStyle: 'normal' }}>
                            <Trans>Out of range</Trans>
                        </BadgeText>
                    </Badge>
                </MouseoverTooltip>
            )}
        </BadgeWrapper>
    )
}
