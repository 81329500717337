import styled from 'styled-components'
import './notchedbutton.css'
import {ReactElement} from 'react'

const TextSpan = styled.span<{
    color?: string
}>`
    font-family: Righteous;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 18px */
    text-transform: uppercase;
    color: ${({color}) => color ?? 'linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)'};
    background: linear-gradient(90deg, #68ffff 0%, #68ff9b 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const BorderButton = styled.button<{
    width: string
}>`
    width: ${({width}) => width ?? '100%'};

        // &:hover ${TextSpan} {
    //     color: #FFEFB0;
    // }

    &:disabled {
        cursor: not-allowed;
    }
`
export const NotchedButtonBolder = ({text, onClick, disabled, style, color, width, children}: any) => {
    return (
        <BorderButton style={style} width={width} className="btn-notched" onClick={onClick} disabled={disabled}>
            <div className="btn-text-notched" style={{height: '45px'}}></div>
            <TextSpan className="btn-notched-content" color={color}>
                {text}
                {children}
            </TextSpan>
        </BorderButton>
    )
}

const BorderButtonNew = styled.div<{
    w?: string
    h?: string
    borderColor?: string
    cursor: string
}>`
    width: ${({w}) => w};
    height: ${({h}) => h};
    background: ${({borderColor}) => borderColor};
    padding: 1px;
    cursor: ${({cursor}) => cursor};
    border-radius: 6px;

    &:hover {
        background: #FFE7AB;
    }
`
const BorderButtonNewContent = styled.div<{
    hover: string
    bg: string
}>`
    width: 100%;
    height: 100%;
    background: ${({bg}) => bg};

    &:hover {
        background: ${({hover}) => hover};
    }
`
export const NotchedButtonBolderNew = ({
                                           w,
                                           h,
                                           borderColor,
                                           bg,
                                           cursor,
                                           hv,
                                           children,
                                       }: {
    w?: string
    h?: string
    borderColor?: string
    bg?: string
    cursor?: string
    hv?: string
    children: ReactElement
}) => {
    const BorderColor = borderColor ?? ''
    const bgHv = hv ? hv : '#FFEFB0'
    return (
        <BorderButtonNew w={w} h={h} borderColor={BorderColor} cursor={cursor ?? 'unset'}>
            <BorderButtonNewContent bg={bg ?? '#FFEFB0'} hover={bgHv}>
                {children}
            </BorderButtonNewContent>
        </BorderButtonNew>
    )
}
