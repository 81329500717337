import {Trans} from '@lingui/macro'
import {FeeAmount} from '@uniswap/v3-sdk'
import Badge from 'components/Badge'
import {useFeeTierDistribution} from 'hooks/useFeeTierDistribution'
import {PoolState} from 'hooks/usePools'
import React from 'react'
import {ThemedText} from 'theme'


export function FeeTierPercentageBadge({
                                           feeAmount,
                                           distributions,
                                           poolState,
                                       }: {
    feeAmount: FeeAmount
    distributions: ReturnType<typeof useFeeTierDistribution>['distributions']
    poolState: PoolState
}) {
    return (
        <ThemedText.DeprecatedLabel>
            {!distributions || poolState === PoolState.NOT_EXISTS || poolState === PoolState.INVALID ? (
                <span style={{
                    color: "rgba(75, 40, 40, 0.50)",
                    textAlign: "right",
                    fontFamily: "Outfit",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "125%"
                }}>
                    <Trans>Not created</Trans>
                </span>
            ) : distributions[feeAmount] !== undefined ? (
                <span style={{
                    color: "rgba(75, 40, 40, 0.50)",
                    textAlign: "right",
                    fontFamily: "Outfit",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "125%"
                }} >
                    <Trans>({distributions[feeAmount]?.toFixed(0)}% select)</Trans>
                </span>
            ) : (
                <span style={{
                    color: "rgba(75, 40, 40, 0.50)",
                    textAlign: "right",
                    fontFamily: "Outfit",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "125%"
                }}>
                    <Trans>No data</Trans>
                </span>
            )}
        </ThemedText.DeprecatedLabel>
    )
}
