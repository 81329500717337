import { SupportedChainId } from "constants/chains"
import { RPC_URLS } from "constants/networks"
import { ethers } from "ethers"
import { useEffect, useState } from "react"
import create from 'zustand'
import { devtools } from 'zustand/middleware'

type RPPStoreType = {
    providerMap: Map<string, ethers.providers.JsonRpcProvider>,
    defaultProvider: ethers.providers.JsonRpcProvider,
    isInitiating: boolean
    initRpc: (rpcUrl: string) => void
}

const DEFAULT_RPC_URL = RPC_URLS[SupportedChainId.ZIRCUIT][0]
export const useRpcStore = create<RPPStoreType>()(
    devtools((set, get) => ({
        providerMap: new Map(),
        defaultProvider: new ethers.providers.JsonRpcProvider({
            url: DEFAULT_RPC_URL,
            throttleSlotInterval: 1000
        }),
        isInitiating: false,
        initRpc: (rpcUrl: string) => {
            if (get().isInitiating) return
            set({
                isInitiating: true
            })
            if (rpcUrl == DEFAULT_RPC_URL) return
            get().providerMap.set(rpcUrl, new ethers.providers.JsonRpcProvider({
                url: rpcUrl,
                throttleSlotInterval: 1000
            }))
            set({
                isInitiating: false
            })
        }
    })))

export default function useProviderNoSign(rpcUrl?: string) {
    const rpcStore = useRpcStore()
    const [provider, setProvider] = useState(rpcStore.defaultProvider)
    const [hasMappedRpc, setHasMappedRpc] = useState<boolean>(false)

    useEffect(() => {
        if (rpcUrl) {
            if (!rpcStore.providerMap.get(rpcUrl))
                rpcStore.initRpc(rpcUrl)
            if (rpcStore.providerMap.get(rpcUrl)) {
                const customProvider = rpcStore.providerMap.get(rpcUrl)
                if (customProvider && !hasMappedRpc) {
                    setProvider(customProvider)
                    setHasMappedRpc(true)
                }
            }
        }
    }, [rpcUrl, rpcStore.isInitiating])

    return provider
}