import styled from "styled-components/macro";
import { TextStyle } from "./Component";
import "../assets/style.css"

const PopupBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    @media (max-width: 768px) {
        align-items: start;
    }
`

const PopupContent = styled.div`
    padding: 32px;
    border-radius: 10px;
    border: 1.5px solid #E5B670;
    background: #FFF6D0;
    box-shadow: 0 7.395px 30px 0 rgba(145, 103, 20, 0.30);
    position: relative;
    width: 573px;
    @media (max-width: 768px) {
        margin-top: 100px;
        width: 336px;
        padding: 20px;
    }
`

const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 22px;
`

const Line = styled.div`
    height: 1px;
    width: 100%;
    background: rgba(229, 182, 112, 0.50);
    backdrop-filter: blur(4.800000190734863px);
    margin: 8px 0 24px 0;
`

const Table = styled.div`
    border-radius: 10px;
    background: #FFF9E1;
    box-shadow: 0px 4px 20px 0px rgba(145, 103, 20, 0.12);
    padding: 8px 20px;
`

const TableTH = styled.div`
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    margin-bottom: 18px;
    padding: 0 20px;
`

const TableItem = styled.div<{
    borderBottom?: string
}>`
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    border-bottom: ${props => props.borderBottom};
    padding: 12px 0;
`

// PopupEarnTreat Component
export const PopupInviteFriend = ({ show, onClose }: { show: any, onClose: any }) => {
    if (!show) return null;

    return (
        <PopupBackdrop>
            <PopupContent>
                <img onClick={onClose} className="close-icon" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_14.png" alt="img_14" />
                <TextStyle fontSize="24px"
                    fontFamily="DynaPuff"
                >
                    Invite friends for extra TREAT
                </TextStyle>
                <DescriptionWrapper>
                    <div className="desc-item">
                        <img className="img-star-yellow" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_15.png" alt="img_15" />
                        <TextStyle>
                            Get bonus PAW & xGM when you invite friends to join.
                        </TextStyle>
                    </div>
                    <div className="desc-item">
                        <img className="img-star-yellow" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_15.png" alt="img_15" />
                        <TextStyle>
                            Collect even more TREAT when your friends bring in their friends.
                        </TextStyle>
                    </div>
                    <div className="desc-item">
                        <img className="img-star-yellow" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_15.png" alt="img_15" />
                        <TextStyle>
                            A whopping 21% total commission - 12% from your invitees, 6% from their friends, and 3% from
                            the extended circle!
                        </TextStyle>
                    </div>
                    <div className="desc-item">
                        <img className="img-star-yellow" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_15.png" alt="img_15" />
                        <TextStyle>
                            Have at least 10,000 TREAT in your balance to start earning referral reward.
                        </TextStyle>
                    </div>
                </DescriptionWrapper>
                <Line></Line>
                <div className="wrapper-img-invite-friend">
                    <div className="img-invite-friend">
                        <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_16.png" alt="img_16" />
                    </div>
                </div>
            </PopupContent>
        </PopupBackdrop>
    );
};