import styled from "styled-components/macro";
import { BREAKPOINTS } from "../../../theme";

export const TextStyle = styled.p<
    {
        color?: string,
        fontFamily?: string,
        fontSize?: string,
        fontSizeMb?: string,
        fontWeight?: string,
        lineHeight?: string,
        margin?: string,
        marginMb?: string,
        padding?: string,
        textAlign?: string,
        textTransform?: string,
        textAlignMb?: string,
    }
>`
    color: ${({ color }) => color ? color : '#4B2828'} !important;
    font-family: ${({ fontFamily }) => fontFamily ?? 'Outfit'};
    font-size: ${({ fontSize }) => fontSize ?? '16px'} !important;
    font-style: normal;
    font-weight: ${({ fontWeight }) => fontWeight ?? '500'};
    line-height: ${({ lineHeight }) => lineHeight ?? '100%'};
    margin: ${({ margin }) => margin ?? 0};
    padding: ${({ padding }) => padding};
    text-align: ${({ textAlign }) => textAlign ?? 'start'};
    text-transform: ${({ textTransform }) => textTransform ?? 'none'};
    @media (max-width: ${BREAKPOINTS.md}px) {
        font-size: ${({ fontSizeMb }) => fontSizeMb ?? '14px'} !important;
        margin: ${({ marginMb }) => marginMb};
        text-align: ${({ textAlignMb }) => textAlignMb ?? 'start'};
    }
`


// xs: 396,
//     sm: 640,
//     md: 768,
//     lg: 1024,
//     xl: 1280,
//     xxl: 1536,
//     xxxl: 1920,

export const Container = styled.div`
    max-width: 1170px;
    margin: 0 auto;
    width: 100%;
    @media (max-width: ${BREAKPOINTS.xl}px) {
        max-width: 95%;
    }
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 48px;
    width: 100%;
`

export const ContentTopWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-template-rows:none;
    gap: 24px;
    width: 100%;
    @media (max-width: ${BREAKPOINTS.md}px) {
        grid-template-columns: none;
        //grid-template-rows: repeat(2, minmax(0, 1fr));
        gap: 35px;
    }
`

export const InfoTreatWrapper = styled.div`
    grid-column: span 2 / span 2;
    grid-row: unset;
    background: #FFEFB0;
    width: 100%;
    border: 2px solid #D7903C;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${BREAKPOINTS.md}px) {
        grid-row: span 1 / span 1;
        grid-column: unset;
    }
`

export const InfoTreatTop = styled.div`
    padding: 36px 24px 0 24px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: 100%;

    @media (max-width: ${BREAKPOINTS.md}px) {
        padding: 24px 12px 0 12px;
        gap: 24px;
    }

    .title {
        span {
            -webkit-text-stroke-width: 3px;
            -webkit-text-stroke-color: #723507;
            @media (max-width: ${BREAKPOINTS.md}px) {
                -webkit-text-stroke-width: 1px;
            }
        }
    }

    .info-treat-wrapper {
        border-radius: 10px;
        background: #FFF9E1;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;
        padding: 19.5px 0;
        @media (max-width: ${BREAKPOINTS.md}px) {
            gap: 12px;
            padding: 12px 0;
        }

        .info-treat-content {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .info-total-treat {
                display: flex;
                gap: 8px;
                align-items: center;

                .icon-treat {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
`

export const Line = styled.div`
    width: 1px;
    height: 100%;
    background: rgba(229, 182, 112, 0.50);
    backdrop-filter: blur(4.800000190734863px);
`

export const InfoTreatBottom = styled.div`
    display: flex;
    align-items: end;
    padding: 0 16px 19px 16px;
    @media (max-width: ${BREAKPOINTS.md}px) {
        padding: 29px 26px 29px 26px;
    }

    .info-treat-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;

    }
`

export const ActionTreatWrapper = styled.div`
    grid-column: span 3 / span 3;
    grid-row: unset;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 13px;
    @media (max-width: ${BREAKPOINTS.md}px) {
        grid-row: span 1 / span 1;
        grid-column: unset;
    }

    .title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .img-triple-treat {
            height: 37.432px;
            @media (max-width: ${BREAKPOINTS.md}px) {
                height: 20px;
            }
        }
    }

    .action-content-wrapper {
        background: #FFF6D0;
        box-shadow: 0 7.395px 30px 0 rgba(145, 103, 20, 0.30), -6px -6px 4px 0 rgba(215, 144, 60, 0.60) inset, 6px 6px 4px 0 rgba(255, 255, 255, 0.80) inset;
        border-radius: 10px;
        padding: 24px 24px 32px 24px;
        display: flex;
        flex-direction: column;
        @media (max-width: ${BREAKPOINTS.md}px) {
            padding: 16px 16px 24px 16px;
        }

        .action-content-title {
            padding: 5px 8px;
            border-radius: 50px;
            background: #FFE7AB;
            width: fit-content;
            height: fit-content;
        }

        .action-content-desc {
            margin-top: 16px;
            display: flex;
            gap: 6px;
            align-items: center;

            @media (max-width: ${BREAKPOINTS.md}px) {
                margin-top: 12px;
            }

            .icon-info {
                width: 16px;
                height: 16px;
                @media (max-width: ${BREAKPOINTS.md}px) {
                    width: 14px;
                    height: 14px;
                }
            }
        }

        .action-content-info-wrapper {
            margin-top: 18px;
            display: flex;
            gap: 12px;
            @media (max-width: ${BREAKPOINTS.md}px) {
                flex-wrap: wrap;
                margin-top: 12px;
            }

        }
    }

    .action-content-wrapper-farming-pool {
        padding: 8px 24px 32px 24px;
        @media (max-width: ${BREAKPOINTS.md}px) {
            padding: 8px 16px 24px 16px;
        }
    }
`

export const ActionTreatRightInfo = styled.div`
    width: 100%;
    padding: 20px 24px;
    border-radius: 10px;
    background: #FFF9E1;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (max-width: ${BREAKPOINTS.md}px) {
        padding: 16px;
    }

    .statistics-wrapper {
        display: flex;
        gap: 12px;
        align-items: center;

        .statistics-content {
            display: flex;
            gap: 6px;
            align-items: center;

            .icon-statistics {
                width: 16px;
                height: 16px;
            }
        }
    }
`

export const TitleActionFarmingPool = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .title-action-farming-pool-left-wrapper {
        display: flex;
        align-items: end;
        gap: 16px;

        .title-action-farming-pool-left-content {
            display: flex;
            align-items: end;

            .icon-water {
                width: 36px;
                height: 100%;
            }
        }
    }

    .icon-star {
        width: 40px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 14px;
    }
`

export const ActionTreatLeftAction = styled.div`
    width: 100%;
    padding: 20px 24px;
    border-radius: 10px;
    background: #FFF9E1;
    display: flex;
    justify-content: space-between;
    align-items: end;
    @media (max-width: ${BREAKPOINTS.md}px) {
        padding: 16px;
    }

    .statistic-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .statistics-content {
            display: flex;
            gap: 6px;
            align-items: center;

            .icon-statistics {
                width: 16px;
                height: 16px;
            }
        }
    }
`

export const InviteFriendWrapper = styled.div`
    border-radius: 10px;
    background-image: url("https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_7.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: -6px -6px 8px 0px rgba(40, 144, 3, 0.40) inset, 6px 6px 4px 0px rgba(224, 255, 221, 0.80) inset;
    width: 100%;
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .invite-friend-title {
        -webkit-text-stroke-width: 1.5px;
        -webkit-text-stroke-color: #723507;
        @media (max-width: ${BREAKPOINTS.md}px) {
            -webkit-text-stroke-width: 1px;
        }

        .type-br {
            @media (max-width: ${BREAKPOINTS.md}px) {
                display: none;
            }
        }
    }

    .invite-friend-content-wrapper {
        display: flex;
        gap: 40px;
        @media (max-width: ${BREAKPOINTS.md}px) {
            flex-direction: column;
            gap: 8px;
        }

        .invite-friend-content {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .link-to-invite-wrapper {
                display: flex;
                gap: 6px;
                align-items: center;

                .copy-wrapper {
                    padding: 4px 6px;
                    border-radius: 50px;
                    background: #00E440;
                }
            }
        }

    }
`

export const LineInviteFriend = styled.div`
    width: 1px;
    background: rgba(255, 239, 176, 0.50);
    backdrop-filter: blur(4.800000190734863px);
    @media (max-width: ${BREAKPOINTS.md}px) {
        width: 100%;
        height: 1px;
    }
`

export const LeaderboardWrapper = styled.div`
    border-radius: 10px;
    border: 2px solid #D7903C;
    background-image: url("https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_8.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 32px 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: ${BREAKPOINTS.md}px) {
        padding: 24px 12px;
    }

    .leaderboard-info-wrapper {
        margin-top: 36px;
        display: flex;
        gap: 32px;
        @media (max-width: ${BREAKPOINTS.md}px) {
            margin-top: 24px;
            flex-direction: column;
            gap: 8px;
        }
        @media (max-width: ${BREAKPOINTS.lg}px) {
            display: grid;
            width: 100%;

            .reward-info-box{
                margin-top: 12px;
            }
        }
        .epoch-info-box{
            display: flex;
            gap: 32px;
        }
        .reward-info-box{
            display: flex;
            gap: 32px;
        }

        @media (max-width: ${BREAKPOINTS.sm}px) {
            .reward-info-box, .epoch-info-box{
                gap: 12px;
                flex-wrap: wrap;
            }
             
        }

        .leaderboard-info {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .total-distribute-statistics-wrapper {
                display: flex;
                gap: 16px;
                align-items: center;

                @media (max-width: ${BREAKPOINTS.md}px) {
                    flex-wrap: wrap;
                    gap: 8px;
                }

                .total-distribute-statistics {
                    display: flex;
                    gap: 6px;
                    align-items: center;

                    .icon-statistics {
                        width: 21px;
                        height: 21px;
                        @media (max-width: ${BREAKPOINTS.md}px) {
                            width: 14px;
                            height: 14px;
                        }
                    }
                }
            }
        }

        .line {
            width: 1px;
            height: 45px;
            padding-top: 2px;
            background: rgba(229, 182, 112, 0.50);
            backdrop-filter: blur(4.800000190734863px);
            @media (max-width: ${BREAKPOINTS.lg}px) {
                display: none;
            }
        }
    }

    .tab-select-wrapper {
        margin-top: 55px;
        border-radius: 50px;
        border: 2px solid #E5B670;
        display: inline-flex;
        padding: 4px;
        align-items: center;
        gap: 5px;
        @media (max-width: ${BREAKPOINTS.md}px) {
            margin-top: 30px;
        }
    }

    .table-wrapper {
        margin-top: 45px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        @media (max-width: ${BREAKPOINTS.md}px) {
            margin-top: 35px;
        }

        .table-header {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(12, minmax(0, 1fr));
            padding: 0 36px;
            @media (max-width: ${BREAKPOINTS.md}px) {
                padding: 0 12px;
            }

            .table-header-rank {
                grid-column: span 1 / span 1;
            }

            .table-header-user {
                grid-column: span 3 / span 3;
            }

            .table-header-current-treat {
                grid-column: span 3 / span 3;
                @media (max-width: ${BREAKPOINTS.md}px) {
                    margin-left: 10px
                }
            }

            .table-header-rewards {
                grid-column: span 5 / span 5;
            }
        }
    }
`

export const TabSelectItem = styled.div<{
    selected?: boolean
}>`
    padding: 7px 20px;
    border-radius: 20px;
    background-color: ${({ selected }) => (selected ? '#E5B670' : 'transparent')};
    cursor: pointer;
    @media (max-width: ${BREAKPOINTS.md}px) {
        padding: 7px 10px;
    }

    &:hover {
        background-color: #E5B670;
        transition: background-color 0.15s ease-in-out;
    }

    .item-content {
        display: flex;
        gap: 4px;
        align-items: center;

        .icon-water {
            width: 24px;
        }
    }
`

export const Table = styled.div`
    padding: 8px 36px;
    width: 100%;
    border-radius: 10px;
    background: #FFF9E1;
    box-shadow: 0px 4px 20px 0px rgba(145, 103, 20, 0.12);
    @media (max-width: ${BREAKPOINTS.md}px) {
        padding: 8px 12px;
    }
    height: 500px;
    overflow-y: auto;
`
export const TableItem = styled.div<{
    borderBottom?: string
}>`

    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    padding: 12px 0;
    border-bottom: ${({ borderBottom }) => borderBottom};

    .table-body-rank {
        grid-column: span 1 / span 1;
        display: flex;
        justify-content: start;
        align-items: center;
    }

    .table-body-user {
        grid-column: span 3 / span 3;
        display: flex;
        justify-content: start;
        align-items: center;
        @media (max-width: ${BREAKPOINTS.md}px) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

    }

    .table-body-current-treat {
        grid-column: span 3 / span 3;
        display: flex;
        gap: 6px;
        align-items: center;

        @media (max-width: ${BREAKPOINTS.md}px) {
            margin-left: 10px
        }

        .icon-statistics {
            width: 16px;
            height: 16px;
        }
    } 

    .table-body-rewards {
        grid-column: span 5 / span 5;
        display: flex;
        gap: 12px;
        align-items: center;
        @media (max-width: ${BREAKPOINTS.md}px) {
            flex-direction: column;
            align-items: end;
        }

        .rewards-content {
            display: flex;
            gap: 6px;
            align-items: center;

            .icon-statistics {
                width: 16px;
                height: 16px;
            }
        }
    }

`

export const Paging = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    img {
        width: 18px;
        height: 18px;
    }

    .number {
        display: flex;
        align-items: center;
        gap: 8px;
    }
`

export const TreatHistoryWrapper = styled.div`
    border-radius: 10px;
    border: 2px solid #D7903C;
    background-image: url("https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_12.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 32px 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: ${BREAKPOINTS.md}px) {
        padding: 24px 12px;
    }

    .table-wrapper {
        margin-top: 45px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        @media (max-width: ${BREAKPOINTS.md}px) {
            margin-top: 35px;
        }

        .table-header {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(12, minmax(0, 1fr));
            padding: 0 36px;
            @media (max-width: ${BREAKPOINTS.md}px) {
                padding: 0 12px;
            }

            .table-header-user {
                grid-column: span 3 / span 3;
            }

            .table-header-current-treat {
                grid-column: span 3 / span 3;
                @media (max-width: ${BREAKPOINTS.md}px) {
                    margin-left: 10px
                }
            }

            .table-header-rewards {
                grid-column: span 6 / span 6;
            }
        }
    }
`

export const Dropdown = styled.div<{
    display?: boolean
}>`
    position: relative;

    .action {
        display: flex;
        gap: 4px;
        cursor: pointer;

        img {
            width: 14px;
            height: 14px;
        }
    }

    .display {
        position: absolute;
        display: ${({ display }) => display ? 'flex' : 'none'};
        padding: 12px;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 2px;
        border-radius: 6px;
        border: 2px solid #E5B670;
        background: #FFF9E1;
        width: 100px;
        top: 21px;
        z-index: 2;
        p{
            width: 100%;
            cursor: pointer;
            padding: 6px;
        }
        .active, p:hover{
            background: #E5B670;
            border-radius: 5px;
        }
        .active{
            cursor: unset;
        }
    }
`

export const TableItemTreatHistory = styled.div<{
    borderBottom?: string
}>`

    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    padding: 12px 0;
    border-bottom: ${({ borderBottom }) => borderBottom};


    .table-body-user {
        grid-column: span 3 / span 3;
        display: flex;
        justify-content: start;
        align-items: center;
        @media (max-width: ${BREAKPOINTS.md}px) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

    }

    .table-body-current-treat {
        grid-column: span 3 / span 3;
        display: flex;
        gap: 6px;
        align-items: center;
        @media (max-width: ${BREAKPOINTS.md}px) {
            margin-left: 10px;
        }

        .icon-statistics {
            width: 16px;
            height: 16px;
        }
    }

    .table-body-rewards {
        grid-column: span 6 / span 6;
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: end;
        @media (max-width: ${BREAKPOINTS.md}px) {
            flex-direction: column;
            align-items: end;
        }

        .rewards-content {
            display: flex;
            gap: 6px;
            align-items: center;

            .icon-statistics {
                width: 16px;
                height: 16px;
            }
        }
    }

    .table-body-current-treat.box{
        grid-column: span 6 / span 3;
        gap: 16px; 
        @media (max-width: ${BREAKPOINTS.sm}px) {
            /* display: grid;
            grid-template-columns: 50% 50%; */
            flex-wrap: wrap;
            gap: 8px;
        }
    }
    .table-body-rewards.box{
        gap: 4px !important;
        margin-right: 12px;
        @media (max-width: ${BREAKPOINTS.md}px) {
            flex-direction: row !important;
            align-items: center !important;
            a{
                font-size: 14px;
            }
        }
    }
`

