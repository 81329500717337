import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { NotchedButtonFill } from '../../components/Button'
import { alphaPoolAddress } from '../../constants/poolListData'
import { useCustomeContract } from 'hooks/useContract'
import v3staker_abi from '../../abis/alpha/V3Staker.json'
import { BREAKPOINTS } from '../../theme'
import { V3STAKER_ADDRESSES_MAP } from '../../constants/addresses'

import { ethers } from 'ethers'
import DoubleCurrencyLogo from 'components/DoubleLogo'

import { useMultipleCurrencies } from './index'
import { useWeb3React } from '@web3-react/core'
import { useV3Positions, useV3PositionsFromTokenIds } from 'hooks/useV3Positions'
import { useMultiplePositions } from '../../hooks/useMultiplePositions'
import { fetchPrice } from '../../nft/utils'
import { DEFAULT_CHAINID } from '../../constants/chains'
import { defaultAbiCoder } from '@ethersproject/abi'
import { useAlphaTvlMap } from 'state/alphapool/hooks'
import { PoolV3Info, useAlphaStore } from '../../hooks/useAlphaStore'
import { formatXpNumber } from 'pages/Xp/sections/XpTitle'
import styled from 'styled-components'
import useCountdownTimer from 'hooks/useCountdownTimer'
import AlphaDepositModal from 'components/Alpha/AlphaDepositModal'
import AlphaHarvestModal from 'components/Alpha/AlphaHarvestModal'
import AlphaWithdrawModal from 'components/Alpha/AlphaWithdrawModal'
import BreadcrumbList from 'components/BreadcrumbList'
import { useToken } from 'hooks/Tokens'
import { Currency } from '@uniswap/sdk-core'


export const CountdownTimer = ({ target }: any) => {
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Math.floor(Date.now() / 1000)
      const distance = target - now

      setDays(
        parseInt(
          Math.floor(distance / 86400)
            .toString()
            .padStart(2, '0')
        )
      )
      setHours(
        parseInt(
          Math.floor((distance % 86400) / 3600)
            .toString()
            .padStart(2, '0')
        )
      )
      setMinutes(
        parseInt(
          Math.floor((distance % 3600) / 60)
            .toString()
            .padStart(2, '0')
        )
      )
      setSeconds(parseInt((distance % 60).toString().padStart(2, '0')))
    }, 1000)

    return () => clearInterval(interval)
  }, [target])

  return (
    <div className="count-down-box">
      <p className="count-down-show-box">{days < 0 ? 0 : days.toString().padStart(2, '0')}d :&nbsp;</p>
      <p className="count-down-show-box"> {hours < 0 ? 0 : hours.toString().padStart(2, '0')}h :&nbsp;</p>
      <p className="count-down-show-box"> {minutes < 0 ? 0 : minutes.toString().padStart(2, '0')}m :&nbsp;</p>
      <p className="count-down-show-box"> {seconds < 0 ? 0 : seconds.toString().padStart(2, '0')}s</p>
    </div>
  )
}
function addSecondsAndFormat(seconds: any) {
  const now = Date.now()
  const milliseconds = seconds * 1000
  const futureTimestamp = now + milliseconds
  const futureDate = new Date(futureTimestamp)
  const formattedDate = futureDate.getDate() + '/' + (futureDate.getMonth() + 1) + '/' + futureDate.getFullYear()
  return formattedDate
}
export const numberWithCommas = (number: any) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}


const DetailFarmingPageWrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    margin: auto;
    margin-top: 80px;
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        width: 90%;
    }
`

const PoolInfoSummary = styled.div`
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        flex-direction: column;
        gap: 25px;
    }
`
const PoolInfoSummaryContent = styled.div`
    width: 100%;
    max-width: 820px;
    border-radius: 10px;
    background: #B7EEFF;
    padding: 8px 26px;

    display: flex;
    align-items: center;
    gap: 25px;
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        max-width: 600px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        img.summary_icon{
            display: none;
        }
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        display: grid;
        padding: 26px 0;
    }
`
const SummaryItemBox = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    &>div{
        display: flex;
        gap: 4px;
        align-items: center;
        img{
            width: 20px;
        }
    }
    span{ 
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 20px */
        display: inline-block;
 
    }
    span:last-child{
        margin-left: 12px; 
    }

    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        display: grid;
        gap: 8px;
        justify-items: center; 
    }
`

const SummaryItemBoxSeparator = styled.div`
    width: 1px;
    height: 100%;
    background: rgba(229, 182, 112, 0.70);
    backdrop-filter: blur(4.800000190734863px);
    
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        display: none;
    }
`

const StakedInfor = styled.div`
    margin-top: 40px;
    width: 100%;
    background: #FFF6D0;
    box-shadow: -6px -6px 4px 0px rgba(215, 144, 60, 0.60) inset, 6px 6px 4px 0px rgba(255, 255, 255, 0.80) inset;
    filter: drop-shadow(0px 7.395px 30px rgba(145, 103, 20, 0.30));
    border-radius: 10px;
    padding: 20px 24px;
    padding-bottom: 40px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        padding: 20px 12px;
        padding-bottom: 20px
    }
`
const StakedInforTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        display: grid;
        gap: 24px;
    }
    h4{
        color: #4B2828;
        font-family: DynaPuff;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 30px */
        text-transform: uppercase;
        margin: 0;
    }
`
const StakedInforActionList = styled.div`
    display: flex;
    gap: 16px;
    @media screen and (max-width: ${BREAKPOINTS.xs}px) {
        gap: 8px;
    }
`
const StakedInforBottom = styled.div`
    display: flex;
    gap: 24px;
    margin-top: 32px;
    div{
        width: 50%;
        display: grid;
        gap: 7px;
        padding: 24px 32px;
        border-radius: 10px;
        background: #FFF9E1;
        box-shadow: 0px 4px 20px 0px rgba(145, 103, 20, 0.12);
        .label{
            color: rgba(75, 40, 40, 0.50);
            font-family: Outfit;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 125%; /* 20px */
        }
        .content{
            color: #4B2828;
            font-family: Outfit;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%; /* 32px */ 
        }
        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
            .label{
                font-size: 14px;
            }
            .content{
                font-size: 24px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        display: grid;
        div{
            width: 100%;
        }
    }
`

const MoreStakeInfoBox = styled.div`
    margin-top: 24px;
    display: flex;
    gap: 24px;
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        flex-wrap: wrap;
        justify-content: center;
    }
`
const MoreStakeInfoItem = styled.div`
    width: calc(33.3% - 16px);
    background: #FFEFB0;
    border-radius: 10px;
    padding: 20px 24px;
    .title{
        color: #4B2828;
        font-family: DynaPuff;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 22.5px */
        text-transform: uppercase;
    }
    div.detail_box{
        display: grid;
        gap: 6px;
        margin-top: 20px;
        padding: 16px;
        border-radius: 10px;
        background: #FFF9E1;
        box-shadow: 0px 4px 20px 0px rgba(145, 103, 20, 0.12);
    }
    div.detail_item{
        display: flex;
        justify-content: space-between;
        span{
            color: rgba(75, 40, 40, 0.50);
            font-family: Outfit;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 125%; /* 20px */
        }
    }
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        width: calc(50% - 12px);
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        width: 100%
    }
`

export default function DetailFarmingPage() {
  const navigate = useNavigate()
  const { account, chainId } = useWeb3React()
  const isSupported = chainId === DEFAULT_CHAINID
  if (!isSupported) {
    navigate('/farming')
    return null
  }

  const tvlMap = useAlphaTvlMap()
  const alphaStore = useAlphaStore()

  const [customeChainId, setCustomeChainId] = useState(DEFAULT_CHAINID)
  const [poolStatus, setPoolStatus] = useState('')
  const [yourTotalNft, setYourTotalNft] = useState()
  const [yourPedingReward, setYourPendingReward] = useState()
  const [yourLockedTime, setYourLockedTime] = useState()
  const [userLiquidity, setUserTotalLiquidity] = useState(0)
  const [loadingUserStakeInfo, setLoadingUserStakeInfo] = useState(false)
  const [idNftStake, setIdNftStake] = useState<{ tokenId: string; poolAddress: string }[]>([])
  const [ethPrice, setEthPrice] = useState(3500)

  // context to call function
  const [contractInfo, setContractInfo] = useState(null)
  // modal
  const [isOpenModalDeposit, setIsOpenModalDeposit] = useState(false)
  const [isOpenModalHarvest, setIsOpenModalHarvest] = useState(false)
  const [isOpenModalWithdraw, setIsOpenModalWithdraw] = useState(false)
  // handle call loading new result
  const [trigger, setTrigger] = useState(false)

  const handleDataFromChild = (data: any) => {
    setYourTotalNft(data)
  }
  const handleUserTotalLiquidity = (data: any) => {
    setUserTotalLiquidity(data)
  }
  const handleGetTime = (data2: any) => {
    setYourLockedTime(data2)
  }
  const handleDataFromChildPendingReward = (data: any) => {
    setYourPendingReward(data)
  }
  useEffect(() => {
    if (chainId) {
      setCustomeChainId(chainId)
    } else {
      setCustomeChainId(DEFAULT_CHAINID)
    }
  }, [account, chainId])
  const params = useParams()
  const filteredPools = getPoolByAddress(params?.poolAddress)
  const tokenInfo = useMultipleCurrencies(filteredPools)
  const locationState = useLocation()
  const [poolInfo, setpoolInfo] = useState<PoolV3Info>()

  function getPoolByAddress(address: any) {
    return alphaPoolAddress[chainId!]?.filter((item) => item.pool.toLowerCase() === address?.toLowerCase()) || []
  }

  function checkStatus(data: any) {
    const startTime = new Date(data?.startTime * 1000)
    const endTime = new Date(data?.endTime * 1000)
    const now = new Date()

    if (now < startTime) {
      setPoolStatus('Incoming')
      return 'Incoming'
    } else if (now > startTime && now < endTime) {
      setPoolStatus('Active')
      return 'Active'
    } else {
      setPoolStatus('End')
      return 'End'
    }
  }
  const STAKING_ADDRESS = V3STAKER_ADDRESSES_MAP[81457]
  const v3stakerWithSign = useCustomeContract(STAKING_ADDRESS, v3staker_abi, true)

  const handleGetUserInfo = async () => {
    setLoadingUserStakeInfo(true)

    try {
      const tokenNftLockedByUser = await v3stakerWithSign?.getTokensByOwner(account, '0', '1000000')
      const items = await Promise.all(
        tokenNftLockedByUser?.map(async (item: any) => {
          const encodedKey = defaultAbiCoder.encode(
            [
              'address',
              'address',
              'uint256',
              'uint256',
              'uint256',
              'address'
            ],
            [
              item.incentiveKey.rewardToken,
              item.incentiveKey.pool,
              item.incentiveKey.startTime,
              item.incentiveKey.endTime,
              item.incentiveKey.lockDuration,
              item.incentiveKey.refundee
            ]
          );
          const incentiveId = ethers.utils.keccak256(encodedKey)

          return {
            tokenId: item.tokenId.toString(),
            poolAddress: item.incentiveKey.pool,
            incentiveId: incentiveId
          }
        })
      )

      const encodedKey = defaultAbiCoder.encode(
        [
          'address',
          'address',
          'uint256',
          'uint256',
          'uint256',
          'address'
        ],
        [
          poolInfo?.rewardToken,
          poolInfo?.pool,
          poolInfo?.startTime,
          poolInfo?.endTime,
          poolInfo?.lockDuration,
          poolInfo?.refundee
        ]
      );
      const poolInfoIncentiveId = ethers.utils.keccak256(encodedKey)

      const filterByPool = items?.filter((item: any) => {
        return (item?.poolAddress?.toLowerCase() === poolInfo?.pool?.toLowerCase())
          && (item?.incentiveId?.toLowerCase() === poolInfoIncentiveId?.toLowerCase())
      })
      setIdNftStake(filterByPool)
      setLoadingUserStakeInfo(true)
    } catch (error) {
      console.log(error)
      setLoadingUserStakeInfo(false)
    }
  }

  const { positions: v3Positions, loading: positionsLoading } = useV3Positions(account)
  const idPositionLocked = idNftStake.map((item) => ethers.BigNumber.from(item.tokenId))
  const { positions: positionsLocked } = useV3PositionsFromTokenIds(idPositionLocked)

  // filter user match with pool
  function filterPosition(data: any, currencyIdA: any, currencyIdB: any) {
    if (!data) return
    return data?.filter((item: any) => {
      const matchesTokens =
        (item.token0 === currencyIdA && item.token1 === currencyIdB) ||
        (item.token0 === currencyIdB && item.token1 === currencyIdA)
      const hasLiquidity = Number(ethers.utils.formatUnits(item.liquidity, 18)) > 0
      const matchesFee = Number(item.fee.toString()) === Number(poolInfo?.poolFee)
      return hasLiquidity && matchesFee && matchesTokens
    })
  }

  const userPosition = filterPosition(v3Positions, filteredPools[0]?.currencyIdA, filteredPools[0]?.currencyIdB) || []
  const userLockedPosition =
    filterPosition(positionsLocked, filteredPools[0]?.currencyIdA, filteredPools[0]?.currencyIdB) || []
  const userPositionsLocked = useMultiplePositions(userLockedPosition)


  const poolDuration = Math.round((Number(poolInfo?.endTime) - Number(poolInfo?.startTime)) / (24 * 60 * 60))

  const poolTvl = useMemo(() => {
    if (poolInfo?.hasEnded) return 0
    return tvlMap.get(poolInfo?.incentiveId ?? '') ?? 0
  }, [tvlMap, poolInfo])

  useEffect(() => {
    if (poolInfo) {
      checkStatus(poolInfo)
      handleGetUserInfo()
    }
  }, [account, chainId, poolInfo])

  useEffect(() => {
    fetchPrice().then((price) => {
      setEthPrice(price || 0)
    })
    if (locationState?.state && typeof locationState?.state === 'object')
      setpoolInfo(locationState?.state as PoolV3Info)
    else setpoolInfo(alphaStore.totalV3Pools.find(item => item.incentiveId == String(params.poolAddress).toLowerCase()))
  }, [])

  const { days, hours, minutes, seconds } = useCountdownTimer('DAY', poolInfo?.endTime)

  const tokenA = useToken(poolInfo?.token0);
  const tokenB = useToken(poolInfo?.token1);

  const breadcrumbList = useMemo(() => [
    {
      text: 'Pools',
      link: '/farming',
    },
    {
      text: `${tokenA?.symbol}/${tokenB?.symbol}`,
      icon: <DoubleCurrencyLogo
        currency0={tokenA as Currency}
        currency1={tokenB as Currency}
        size={32}
      />,
      isActive: true,
    },
  ], [tokenA, tokenB])

  return <DetailFarmingPageWrapper>
    <BreadcrumbList list={breadcrumbList} />

    <PoolInfoSummary>
      <PoolInfoSummaryContent>
        <img className="summary_icon" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/farming/summary_icon.png" alt="gmeowfi" />
        <SummaryItemBox>
          <div>
            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/farming/tvl_icon.png" alt="gmeowfi" />
            <span style={{ color: 'rgba(75, 40, 40, 0.50)', marginLeft: '0 !important' }}>TVL</span>
          </div>
          <span style={{ color: '#4B2828' }}>${formatXpNumber(poolTvl, 3)}</span>
        </SummaryItemBox>
        <SummaryItemBoxSeparator />

        <SummaryItemBox>
          <div>
            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/farming/reward_icon.png" alt="gmeowfi" />
            <span style={{ color: 'rgba(75, 40, 40, 0.50)', marginLeft: '0 !important' }}>Pool Reward</span>
          </div>
          <div>
            <span style={{ color: '#4B2828' }}>
              {formatXpNumber(poolInfo?.totalRewardUnclaimed)}
            </span>
            <img className="reward_icon" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/tokens/xgm_token.png" alt="gmeowfi" />
          </div>
        </SummaryItemBox>
        <SummaryItemBoxSeparator />

        <SummaryItemBox>
          <div>
            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/farming/apr_icon.png" alt="gmeowfi" />
            <span style={{ color: 'rgba(75, 40, 40, 0.50)', marginLeft: '0 !important' }}>APR</span>
          </div>
          <span style={{ color: '#4B2828' }}>
            {formatXpNumber(poolInfo?.apr)}%
          </span>
        </SummaryItemBox>

      </PoolInfoSummaryContent>

      <NotchedButtonFill onClick={() => navigate(
        `/add/${filteredPools[0]?.currencyIdA === '0x4200000000000000000000000000000000000023'
          ? 'ETH'
          : filteredPools[0]?.currencyIdA
        }/${filteredPools[0]?.currencyIdB === '0x4200000000000000000000000000000000000023'
          ? 'ETH'
          : filteredPools[0]?.currencyIdB
        }/${poolInfo?.poolFee}`
      )}>
        New position
      </NotchedButtonFill>

    </PoolInfoSummary>

    <StakedInfor>
      <StakedInforTop>
        <h4>
          Staked Liquidity
        </h4>
        <StakedInforActionList>
          <NotchedButtonFill onClick={() => setIsOpenModalHarvest(true)}
            textColor="#FFF9E1" bg="#00E440" earMaxW="96px" fontSize="16px" height="40px" width="175px" mobileMaxW="100px" mobilePadding="12px" mbHeight="32px" mbEarMaxW="70px" mbFontSize="12px" mbFontSizeXs="12px"
          >
            HARVEST
          </NotchedButtonFill>
          <NotchedButtonFill onClick={() => setIsOpenModalWithdraw(true)}
            textColor="#FFF9E1" bg="#00E440" earMaxW="96px" fontSize="16px" height="40px" width="175px" mobileMaxW="100px" mobilePadding="12px" mbHeight="32px" mbEarMaxW="70px" mbFontSize="12px" mbFontSizeXs="12px"
          >
            UNSTAKE
          </NotchedButtonFill>
          <NotchedButtonFill onClick={() => setIsOpenModalDeposit(true)}
            textColor="#FFF9E1" bg="#00E440" earMaxW="96px" fontSize="16px" height="40px" width="175px" mobileMaxW="100px" mobilePadding="12px" mbHeight="32px" mbEarMaxW="70px" mbFontSize="12px" mbFontSizeXs="12px"
          >
            DEPOSIT
          </NotchedButtonFill>
        </StakedInforActionList>
      </StakedInforTop>

      <StakedInforBottom>
        <div className="item">
          <span className="label">Your deposit</span>
          <span className="content">${formatXpNumber(poolInfo?.userTvl, 6)}</span>
        </div>
        <div className="item">
          <span className="label">Your pending rewards</span>
          <span className="content">{formatXpNumber(yourPedingReward, 18)} xGM</span>
        </div>
      </StakedInforBottom>
    </StakedInfor>

    <MoreStakeInfoBox>
      <MoreStakeInfoItem>
        <span className="title">Status</span>
        <div className="detail_box">
          <div className="detail_item">
            <span className="label">Status</span>
            <span className="content" style={{ color: poolInfo?.hasEnded ? 'red' : '#05C63B' }}>
              {poolStatus}
            </span>
          </div>

          <div className="detail_item">
            <span className="label">Duration</span>
            <span className="content">{poolDuration} Days</span>
          </div>

          <div className="detail_item">
            <span className="label">Ends in</span>
            <span className="content">
              {
                poolInfo?.hasEnded ? '0d: 0h: 0m: 0s' : `${days}d : ${hours}h : ${minutes}m : ${seconds}s`
              }
            </span>
          </div>
        </div>
      </MoreStakeInfoItem>

      <MoreStakeInfoItem>
        <span className="title">Requirements</span>
        <div className="detail_box">
          <div className="detail_item">
            <span className="label">Minimum amount</span>
            <span className="content">-</span>
          </div>

          <div className="detail_item">
            <span className="label">Minimum lock</span>
            <span className="content">
              {poolInfo?.poolId && (
                Number(poolInfo?.poolId) === 0
              ) ? '20 days' : '-'}</span>
          </div>

          <div className="detail_item">
            <span className="label">Locked until</span>
            <span className="content">{yourLockedTime ? addSecondsAndFormat(yourLockedTime) : '-'}</span>
          </div>
          <div className="detail_item">
            <span className="label">Whitelist</span>
            <span className="content">-</span>
          </div>
        </div>
      </MoreStakeInfoItem>

      <MoreStakeInfoItem>
        <span className="title">Authorization</span>
        <div className="detail_box">
          <div className="detail_item">
            <span className="label">Deposit</span>
            {
              poolInfo?.hasEnded ? <span className="content" style={{ color: '#ff0000' }}>Disabled</span>
                : <span className="content" style={{ color: '#05C63B' }}>Enabled</span>
            }
          </div>

          <div className="detail_item">
            <span className="label">Deposit end time</span>
            <span className="content" style={{ color: '#05C63B' }}>-</span>
          </div>

          <div className="detail_item">
            <span className="label">Harvest</span>
            {
              poolInfo?.hasEnded ? <span className="content" style={{ color: '#ff0000' }}>Disabled</span>
                : <span className="content" style={{ color: '#05C63B' }}>Enabled</span>
            }
          </div>
        </div>
      </MoreStakeInfoItem>

    </MoreStakeInfoBox>

    {/* <HarvestModal
            isOpen={true}
            onDismiss={() => { }}
            poolInfo={poolInfo}
            tokenInfo={undefined}
            handleDataFromChildPendingReward={handleDataFromChildPendingReward}
        /> */}
    {/* <AlphaHarvestModal isOpen={isShowHarvestModal} onDismiss={() => setIsShowHarvestModal(false)} />
    <AlphaWithdrawModal isOpen={isShowUnStakeModal} onDismiss={() => setIsShowUnStakeModal(false)} />
    <AlphaDepositModal isOpen={isShowStakeModal} onDismiss={() => setIsShowStakeModal(false)} /> */}

    <AlphaDepositModal
      isOpen={isOpenModalDeposit}
      onDismiss={() => setIsOpenModalDeposit(false)}
      userPosition={userPosition}
      tokenInfo={tokenInfo}
      poolInfo={poolInfo as PoolV3Info}
      handleDataFromChild={handleDataFromChild}
      setTrigger={setTrigger}
    />
    <AlphaHarvestModal
      isOpen={isOpenModalHarvest}
      onDismiss={() => setIsOpenModalHarvest(false)}
      poolInfo={poolInfo}
      tokenInfo={tokenInfo}
      userPositionsLocked={userPositionsLocked}
      handleDataFromChildPendingReward={handleDataFromChildPendingReward}
    />

    <AlphaWithdrawModal
      isOpen={isOpenModalWithdraw}
      onDismiss={() => setIsOpenModalWithdraw(false)}
      poolInfo={poolInfo}
      tokenInfo={tokenInfo}
      userPositionsLocked={userPositionsLocked}
      handleDataFromChild={handleDataFromChild}
      handleGetTime={handleGetTime}
      handleUserTotalLiquidity={handleUserTotalLiquidity}
    />
    {/* <HarvestModal
      isOpen={isOpenModalHarvest}
      onDismiss={() => setIsOpenModalHarvest(false)}
      poolInfo={poolInfo}
      tokenInfo={tokenInfo}
      userPositionsLocked={userPositionsLocked}
      handleDataFromChildPendingReward={handleDataFromChildPendingReward}
      trigger={trigger}
    />
    <WithdrawModal
      isOpen={isOpenModalWithdraw}
      onDismiss={() => setIsOpenModalWithdraw(false)}
      poolInfo={poolInfo}
      tokenInfo={tokenInfo}
      userPositionsLocked={userPositionsLocked}
      handleDataFromChild={handleDataFromChild}
      trigger={trigger}
      handleGetTime={handleGetTime}
      handleUserTotalLiquidity={handleUserTotalLiquidity}
    /> */}
  </DetailFarmingPageWrapper>

}