import { useWeb3React } from '@web3-react/core'
import { getChainInfo } from 'constants/chainInfo'
import { DEFAULT_CHAINID, SupportedChainId } from 'constants/chains'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useSelectChain from 'hooks/useSelectChain'
import useSyncChainQuery from 'hooks/useSyncChainQuery'
import { Box } from 'nft/components/Box'
import { Portal } from 'nft/components/common/Portal'
import { Column, Row } from 'nft/components/Flex'
import { TokenWarningRedIcon } from 'nft/components/icons'
import { subhead } from 'nft/css/common.css'
import { themeVars } from 'nft/css/sprinkles.css'
import { useIsMobile } from 'nft/hooks'
import { useCallback, useEffect, useRef, useState } from 'react'
import { AlertTriangle, ChevronDown, ChevronUp } from 'react-feather'
import styled, { useTheme } from 'styled-components/macro'

import * as styles from './ChainSelector.css'
import ChainSelectorRow from './ChainSelectorRow'
import { NavDropdown } from './NavDropdown'
import { BREAKPOINTS } from 'theme'

const NETWORK_SELECTOR_CHAINS = [
  DEFAULT_CHAINID,
]

interface ChainSelectorProps {
  leftAlign?: boolean
}

const SelectChainBoxBg = styled.div`
  padding: 20px 0px;
  min-width: 256px;
  border: 2px solid #00E440;
  background-color: rgba(239, 239, 228, 0.1);
  backdrop-filter: blur(4.8px);
  box-shadow: 8px 12px 20px rgba(51, 53, 72, 0.04), 4px 6px 12px rgba(51, 53, 72, 0.02),
    4px 4px 8px rgba(51, 53, 72, 0.04);
`

const ChainSelectorBox = styled.div<{
  isSupported: boolean
}>`
  position: relative;
  .chain-select-box{
    position: absolute;
    top: -14px;
    left: ${({ isSupported }) => isSupported ? '-9px' : '-15px'};
  }
  transition: scale 0.15s ease-in-out;
  &:hover .chain-select-box{
    scale: 1.02;
  }

  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    .chain-select-box{
      scale: 0.9;
    }
  }

  @media screen and (max-width: 540px) {
    .chain-select-box{
      display: none;
    }
    .active-chain-log{
      width: 18px;
      height: 18px;
    }
    button{
      padding-left: 0;
      padding-right: 0;
    }
  }
`

export const ChainSelector = ({ leftAlign }: ChainSelectorProps) => {
  const { chainId } = useWeb3React()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const isMobile = useIsMobile()
  const { account } = useWeb3React()
  const isAuthenticated = !!account
  const theme = useTheme()

  const ref = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setIsOpen(false), [modalRef])

  const info = chainId ? getChainInfo(chainId) : undefined

  const selectChain = useSelectChain()
  useSyncChainQuery()

  const [pendingChainId, setPendingChainId] = useState<SupportedChainId | undefined>(undefined)

  const onSelectChain = useCallback(
    async (targetChainId: SupportedChainId) => {
      setPendingChainId(targetChainId)
      await selectChain(targetChainId)
      setPendingChainId(undefined)
      setIsOpen(false)
    },
    [selectChain, setIsOpen]
  )

  // if (!chainId) {
  //   return null
  // }

  // const isSupported = !!info
  // const isSupported = !!info && chainId === SupportedChainId.SCROLL_ALPHA;
  const isSupported = !!info && (chainId === DEFAULT_CHAINID)

  const dropdown = (
    <NavDropdown top="64" left={leftAlign ? '0' : 'auto'} zIndex={'3'} right={leftAlign ? 'auto' : '0'} ref={modalRef}>
      <SelectChainBoxBg>
        <Column paddingX="16">
          {NETWORK_SELECTOR_CHAINS.map((chainId: SupportedChainId) => (
            <ChainSelectorRow
              onSelectChain={onSelectChain}
              targetChain={chainId}
              key={chainId}
              isPending={chainId === pendingChainId}
            />
          ))}
        </Column>
      </SelectChainBoxBg>
    </NavDropdown>
  )

  const chevronProps = {
    height: 20,
    width: 20,
    color: '#4B2828',
    opacity: 0.6,
  }


  return (
    <div>
      {isAuthenticated && (
        <ChainSelectorBox isSupported={isSupported}>
          <img className='chain-select-box' src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/common/chain-select-box-icon.png' alt='gmeowfi' />
          <Box position="relative" ref={ref}>
            <Row
              as="button"
              gap="0"
              className={styles.ChainSelector}
              background="none"
              onClick={() => setIsOpen(!isOpen)}
            >
              {!isSupported ? (
                <>
                  {/* <img
                  src={getChainInfo(SupportedChainId.ZIRCUIT_TESTNET_SEPOLIA)?.logoUrl}
                  alt={getChainInfo(SupportedChainId.ZIRCUIT_TESTNET_SEPOLIA)?.label}
                  className={styles.Image}
                /> */}

                  <Box
                    as="span"
                    className={subhead}
                    display={{ sm: 'flex', lg: 'flex' }}
                    fontSize={{ xs: '10', lg: '16' }}
                    style={{
                      lineHeight: '20px',
                      whiteSpace: 'nowrap',
                      color: '#4B2828',
                      fontWeight: '400',
                      opacity: 0.6,
                    }}
                  >
                    <AlertTriangle color="#ff5722" size={18} display="flex" />
                    {/* UNSUPPORTED */}
                  </Box>
                </>
              ) : (
                <>
                  <img src={info.logoUrl} alt={info.label} className={styles.Image + ' active-chain-log'} />
                  {/* <Box
                  className={subhead}
                  display={{ sm: 'none', xxxl: 'block' }}
                  style={{
                    lineHeight: '20px',
                    whiteSpace: 'nowrap',
                    color: '#4B2828',
                    fontSize: '18px',
                    fontWeight: '400',
                    opacity: '0.6',
                    textTransform: 'uppercase',
                  }}
                >
                  {info.label}
                </Box> */}
                </>
              )}
              <img style={{
                rotate: isOpen ? '180deg' : 'unset'
              }} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/common/selector-arrow-down-icon.png" alt="gmeowfi" />
              {/* {isOpen ? <ChevronUp {...chevronProps} /> : <ChevronDown {...chevronProps} />} */}
            </Row>
            {isOpen && (isMobile ? <Portal>{dropdown}</Portal> : <>{dropdown}</>)}
          </Box>
        </ChainSelectorBox>
      )}
    </div>
  )
}
