import { Trans } from '@lingui/macro'
import Web3Status from 'components/Web3Status'
import { useIsNftPage } from 'hooks/useIsNftPage'
import { Box } from 'nft/components/Box'
import { Row } from 'nft/components/Flex'
import { MonoLogo } from 'nft/components/icons'
import { ReactNode, useEffect, useState } from 'react'
import { NavLink, NavLinkProps, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ExternalLink } from 'theme'

import { ChainSelector } from './ChainSelector'
import * as styles from './style.css'
import {
    DownOutlined,
    CloseOutlined
} from "@ant-design/icons";
import { useDailyTaskStore } from 'state/dailyTask/useDailyTaskStore'
import { useWeb3React } from '@web3-react/core'
import GasInfoModal from './GasInfoModal'
import { formatNumber } from 'utils/formatNumbers'

const Nav = styled.nav`
    padding: 20px 12px;
    width: 90%;
    margin: 0px auto;
    height: 100px;
    z-index: 2;
    color: #efefe4 !important;
    @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
        width: 100%;
        padding: 20px;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        height: 60px;
        padding: 0 12px;
    }
`

const SubMenuListDesktop = styled.div`
    display: none;
    color: #efefe4;
    font-size: 18px;
    font-family: Righteous;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 18px;
    word-wrap: break-word;

    width: 208px;
    position: absolute;
    top: 50px;
    right: 0;
    gap: 16px;
    z-index: 9999;
    border-radius: 10px;
    border: 2px solid #E5B670;
    background: #FFF6D0;
    padding: 25px;
    opacity: 1;
    display: grid;

    &:hover {
        display: grid;
    }

    span {
        font-size: 18px;
    }
`
const SubMenuListMobile = styled.div`
    color: #efefe4;
    font-size: 18px;
    font-family: Righteous;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 18px;
    word-wrap: break-word;
    background-size: cover;
    display: grid;
    gap: 0;
    padding: 10px;
    padding-bottom: 0;
    display: none;
`

const TransColored = styled.span<{ hoverOpacity?: number }>`
    color: #4B2828;
    text-align: center;
    font-family: DynaPuff;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 24px */
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 2px;

    svg {
        width: 20px;
        height: 20px;
    }

    &:hover {
        opacity: 0.8;
    }

    @media screen and (max-width: 1650px) {
        font-size: 20px;
        padding: 0 !important;
        svg {
            width: 17px;
            height: 17px;
        }
    }

    @media screen and (max-width: 1400px) {
        font-size: 17px;
        padding: 0 !important;
        svg {
            width: 15px;
            height: 15px;
        }
    }

    @media screen and (max-width: 1300px) {
        font-size: 15px;
        padding: 0 !important;
        svg {
            width: 15px;
            height: 15px;
        }
    }
    @media screen and (max-width: 1200px) {
        font-size: 13px;
        padding: 0 !important;
    }
`

interface MenuItemProps {
    href?: string
    id?: NavLinkProps['id']
    isActive?: boolean
    children: ReactNode
    dataTestId?: string
    isDisable?: boolean
    onCloseAllSubmenu?: Function
}

const MenuItem = ({ href, dataTestId, id, isActive, children, isDisable, onCloseAllSubmenu }: MenuItemProps) => {
    const closeAllSubmenu = onCloseAllSubmenu ? onCloseAllSubmenu : () => {
    }
    return isDisable ? (
        <a
            onClick={() => closeAllSubmenu()}
            className={isActive ? styles.activeMenuItem : styles.menuItem}
            id={id}
            style={{ textDecoration: 'none', position: 'relative', cursor: 'pointer' }}
            data-testid={dataTestId}
        >
            {children}
        </a>
    ) : (
        <NavLink
            to={href ?? '#'}
            className={isActive ? styles.activeMenuItem : styles.menuItem}
            id={id}
            style={{ textDecoration: 'none', position: 'relative' }}
            data-testid={dataTestId}
            onClick={() => closeAllSubmenu()}
        >
            {children}
        </NavLink>
    )
}

const PageTabs = () => {
    const { pathname } = useLocation()
    // const { chainId: connectedChainId } = useWeb3React()
    // const chainName = chainIdToBackendName(connectedChainId)

    const isPoolActive =
        pathname.startsWith('/pool') ||
        pathname.startsWith('/add') ||
        pathname.startsWith('/remove') ||
        pathname.startsWith('/increase') ||
        pathname.startsWith('/find')

    // const isNftPage = useIsNftPage()

    let timeoutCloseSubmenu: NodeJS.Timeout
    const onHoverShowSubMenu = (e: any) => {
        if (timeoutCloseSubmenu) clearTimeout(timeoutCloseSubmenu)
        document.querySelectorAll('.submenu-desktop').forEach((el: any) => {
            closeSubmenu(el.parentElement)
        })
        let parentElement
        const currentTag = e.target
        if (currentTag.tagName == 'SPAN') parentElement = currentTag.parentElement
        // it's img
        else parentElement = currentTag.parentElement.parentElement.parentElement

        parentElement.classList.add('isOpenSubmenu')
        parentElement.querySelector('svg').style.rotate = '180deg'
        parentElement.querySelector('.submenu-desktop').style.display = 'grid'
    }

    const onCloseSubmenu = (e: any) => {
        let parentElement: any
        const currentTag = e.target
        if (currentTag.tagName == 'SPAN') parentElement = currentTag.parentElement
        // it's img
        else parentElement = currentTag.parentElement.parentElement
        if (parentElement.querySelector('.submenu-desktop')) {
            // for submenu
            parentElement.classList.remove('isOpenSubmenu')
            if (timeoutCloseSubmenu) clearTimeout(timeoutCloseSubmenu)
            timeoutCloseSubmenu = setTimeout(() => closeSubmenu(parentElement), 140)
        }
    }

    const closeSubmenu = (el: any, force = false) => {
        const submMenuTag = el.querySelector('.submenu-desktop')
        if (!force) if (submMenuTag.classList.contains('is-hovering') || el.classList.contains('isOpenSubmenu')) return

        el.querySelector('svg').style.rotate = '0deg'
        submMenuTag.style.display = 'none'
    }

    const onMouseEnterSubmenu = (e: any) => {
        const currentTag = e.target

        if (currentTag.classList.contains('submenu-desktop')) currentTag.classList.add('is-hovering')
    }
    const onMouseLeaveSubmenu = (e: any) => {
        const currentTag = e.target

        if (currentTag.classList.contains('submenu-desktop')) {
            currentTag.classList.remove('is-hovering')
            const parentElement: any = currentTag.parentElement
            if (timeoutCloseSubmenu) clearTimeout(timeoutCloseSubmenu)
            timeoutCloseSubmenu = setTimeout(() => closeSubmenu(parentElement), 140)
        }
    }
    const onHoverNoSubmenuEl = () =>
        document.querySelectorAll('.submenu-desktop').forEach((el: any) => closeSubmenu(el.parentElement, true))

    useEffect(() => {
        onHoverNoSubmenuEl()
    }, [pathname])
    useEffect(() => {
        document.querySelector('.site-body')?.addEventListener('mouseenter', onHoverNoSubmenuEl)
    }, [])
    return (
        <>
            <MenuItem onCloseAllSubmenu={onHoverNoSubmenuEl}>
                <NavLink to="/swap" style={{ textDecoration: 'none' }}>
                    <TransColored style={{ color: '#4B2828' }} onMouseEnter={onHoverNoSubmenuEl}>
                        <Trans>TRADE</Trans>
                    </TransColored>
                </NavLink>
            </MenuItem>
            <MenuItem isDisable={true} onCloseAllSubmenu={onHoverNoSubmenuEl}>
                <TransColored
                    hoverOpacity={0.6}
                    style={{ color: '#4B2828' }}
                    onMouseEnter={onHoverShowSubMenu}
                    onMouseLeave={onCloseSubmenu}
                >
                    <Trans>EARN</Trans>
                    <DownOutlined rev={undefined} />
                </TransColored>
                <SubMenuListDesktop
                    onMouseEnter={onMouseEnterSubmenu}
                    onMouseLeave={onMouseLeaveSubmenu}
                    className="submenu-desktop"
                >
                    <NavLink to="/add" style={{ textDecoration: 'none' }}>
                        <TransColored style={{ color: '#4B2828' }}>
                            <Trans>LIQUIDITY</Trans>
                        </TransColored>
                    </NavLink>
                    <NavLink to="/pool" style={{ textDecoration: 'none' }}>
                        <TransColored style={{ color: '#4B2828' }}>
                            <Trans>POSITIONS</Trans>
                        </TransColored>
                    </NavLink>
                    <NavLink to="/farming" style={{ textDecoration: 'none' }}>
                        <TransColored style={{ color: '#4B2828' }}>
                            <Trans>FARMING POOLS</Trans>
                        </TransColored>
                    </NavLink>
                    <NavLink to="/pools-list" style={{ textDecoration: 'none' }}>
                        <TransColored style={{ color: '#4B2828' }}>
                            <Trans>All POOLS</Trans>
                        </TransColored>
                    </NavLink>
                </SubMenuListDesktop>
            </MenuItem>
            {/*<MenuItem isDisable={true} onCloseAllSubmenu={onHoverNoSubmenuEl}>*/}
            {/*    <NavLink to="/paw" style={{ textDecoration: 'none' }}>*/}
            {/*        <TransColored style={{ color: '#4B2828' }} onMouseEnter={onHoverNoSubmenuEl}>*/}
            {/*            <Trans>PAW</Trans>*/}
            {/*        </TransColored>*/}
            {/*    </NavLink>*/}
            {/*</MenuItem>*/}

            <MenuItem isDisable={true} onCloseAllSubmenu={onHoverNoSubmenuEl}>
                <NavLink to="/treat" style={{ textDecoration: 'none' }}>
                    <TransColored style={{ color: '#4B2828' }} onMouseEnter={onHoverNoSubmenuEl}>
                        <Trans>TREAT</Trans>
                    </TransColored>
                </NavLink>
            </MenuItem>

            <MenuItem isDisable={true} onCloseAllSubmenu={onHoverNoSubmenuEl}>
                <NavLink to="/box" style={{ textDecoration: 'none' }}>
                    <TransColored style={{ color: '#4B2828' }} onMouseEnter={onHoverNoSubmenuEl}>
                        <Trans>BOX</Trans>
                    </TransColored>
                </NavLink>
            </MenuItem>
            {/* <MenuItem isDisable={true} onCloseAllSubmenu={onHoverNoSubmenuEl}>
                <NavLink to="/meow" style={{ textDecoration: 'none' }}>
                    <TransColored style={{ color: '#4B2828' }} onMouseEnter={onHoverNoSubmenuEl}>
                        <Trans>MEOW</Trans>
                    </TransColored>
                </NavLink>
            </MenuItem> */}

            <MenuItem isDisable={true} onCloseAllSubmenu={onHoverNoSubmenuEl}>
                <ExternalLink href="https://gmeow.info/">
                    <TransColored
                        hoverOpacity={0.6}
                        style={{ color: '#4B2828' }}
                        onMouseEnter={onHoverShowSubMenu}
                        onMouseLeave={onCloseSubmenu}
                    >
                        <Trans>INFO</Trans>
                    </TransColored>
                </ExternalLink>
            </MenuItem>
            <MenuItem isDisable={true} onCloseAllSubmenu={onHoverNoSubmenuEl}>
                <NavLink to="/bridge" style={{ textDecoration: 'none' }}>
                    <TransColored style={{ color: '#4B2828' }} onMouseEnter={onHoverNoSubmenuEl}>
                        <Trans>BRIDGE</Trans>
                    </TransColored>
                </NavLink>
            </MenuItem>
        </>
    )
}

const MobileBottomBar = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 90%;
    height: 100%;
    background-color: #FFF9E1;
    z-index: 100;
    /* padding-left: 20px; */
    padding-top: 10px;
    overflow: auto;
    border-right: 1px solid #7f7f7f6e;
`

const MobileMenuWrapper = ({ onClose }: { onClose: Function }) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const onClickShowSubMenu = (e: any) => {
        e.stopPropagation()
        let parentElement
        const currentTag = e.target
        if (currentTag.tagName == 'SPAN') parentElement = currentTag.parentElement
        // it's img
        else parentElement = currentTag.parentElement.parentElement

        if (parentElement.classList.contains('isOpenSubmenu')) {
            // close submenu
            parentElement.classList.remove('isOpenSubmenu')
            parentElement.querySelector('svg').style.rotate = '0deg'
            parentElement.querySelector('.submenu-mobile').style.display = 'none'
        } else {
            // open submenu
            parentElement.classList.add('isOpenSubmenu')
            parentElement.querySelector('svg').style.rotate = '180deg'
            parentElement.querySelector('.submenu-mobile').style.display = 'grid'
        }
    }
    return (
        <>
            <MobileBottomBar>
                <Row display="flex" justifyContent="space-between" alignItems="center" paddingRight="20">
                    <Box width="42" height="36" paddingLeft="12">
                        <MonoLogo
                            width="43"
                            height="36"
                            src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/common/mobile-logo.png"
                        />
                    </Box>
                    <CloseOutlined style={{ color: 'black' }} onClick={() => onClose()} rev={undefined} />
                </Row>

                <Row marginTop="40" gap={{ xl: '0', xxl: '8' }} display="grid">
                    <MenuItem href="/swap">
                        <TransColored>
                            <Trans>TRADE</Trans>
                        </TransColored>
                    </MenuItem>

                    <MenuItem href="#" data-show={false}>
                        <TransColored onClick={onClickShowSubMenu}>
                            <Trans>EARN</Trans>
                            <DownOutlined rev={undefined} />
                        </TransColored>
                        <SubMenuListMobile className="submenu-mobile">
                            <MenuItem href="/add">
                                <TransColored>
                                    <Trans>LIQUIDITY</Trans>
                                </TransColored>
                            </MenuItem>
                            <MenuItem href="/pool">
                                <TransColored>
                                    <Trans>POSITIONS</Trans>
                                </TransColored>
                            </MenuItem>
                            <MenuItem href="/farming">
                                <TransColored>
                                    <Trans>FARMING POOLS</Trans>
                                </TransColored>
                            </MenuItem>
                            <MenuItem href="/pools-list">
                                <TransColored>
                                    <Trans>All POOLS</Trans>
                                </TransColored>
                            </MenuItem>
                        </SubMenuListMobile>
                    </MenuItem>
                    {/*<MenuItem href="/paw">*/}
                    {/*   <TransColored>*/}
                    {/*       <Trans>PAW</Trans>*/}
                    {/*   </TransColored>*/}
                    {/*</MenuItem>*/}
                    <MenuItem href="/treat">
                        <TransColored>
                            <Trans>TREAT</Trans>
                        </TransColored>
                    </MenuItem>
                    <MenuItem href="/box">
                        <TransColored>
                            <Trans>BOX</Trans>
                        </TransColored>
                    </MenuItem>
                    {/* <MenuItem href="/meow">
                        <TransColored>
                            <Trans>MEOW</Trans>
                        </TransColored>
                    </MenuItem> */}
                    <MenuItem href="#">
                        <ExternalLink href="https://gmeow.info/">
                            <TransColored onClick={onClickShowSubMenu}>
                                <Trans>INFO</Trans>
                            </TransColored>
                        </ExternalLink>
                    </MenuItem>
                    <MenuItem href="/bridge">
                        <TransColored>
                            <Trans>BRIDGE</Trans>
                        </TransColored>
                    </MenuItem>
                </Row>
            </MobileBottomBar>
            <div
                onClick={() => onClose()}
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    background: '#06060652',
                    zIndex: 99,
                }}
            ></div>
        </>
    )
}
const LogoBox = styled.div`
    scale: 1;
    margin-right: 23px;

    @media screen and (max-width: 1300px) {
        scale: 0.7;
    }

    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        display: none;
    }
`
const LogoBoxMobile = styled.div`
    @media screen and (min-width: ${BREAKPOINTS.lg}px) {
        display: none;
    }
`

const TreatBalanceBox = styled.div`
    display: inline-flex;
    height: 40px;
    padding: 4px 10px 4px 8px;
    align-items: center;
    gap: 5px;
    border-radius: 50px;
    border: 2px solid #E5B670;
    cursor: pointer;
    transition: scale 0.15s ease-in-out;
    &:hover{
        scale: 1.02;
    }
    span{
        color: #4B2828;
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 21px */ 
    }
    .token_img{
        width: 20px;
        height: 20px;
    }
    .icon{
        width: 10px;
        height: 8px;
    }

    @media screen and (max-width: 540px) {
        height: 30px;
        padding: 8px 4px;
        span{
            font-size: 11px;
        }
        .token_img{
            width: 14px;
            height: 14px;
        }
    }
`

const Navbar = () => {
    const { account } = useWeb3React()
    const dailyTaskStore = useDailyTaskStore()
    const isNftPage = useIsNftPage()
    const navigate = useNavigate()
    const location = useLocation()

    const [isShowMobileMenu, setIsShowMobileMenu] = useState<boolean>(false)

    const [isShowGasSetting, setIsShowGasSetting] = useState<boolean>(false)
    const [gasSetingValue, setGasSetingValue] = useState<string>('')
    useEffect(() => {
        setIsShowMobileMenu(false)
    }, [location])
    return (
        <>
            <Nav>
                <Box display="flex" height="60" flexWrap="nowrap" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center" gap={{ sm: "52", xxl: "24", xxxl: "0" }}
                        style={{ position: 'relative' }}>
                        <Row marginLeft={{ lg: '0', xxl: '0' }} gap={{ xl: '0', xxl: '8' }}
                            display={{ sm: 'none', xl: 'flex' }}>
                            <LogoBox onClick={() => navigate('/home')} style={{ cursor: 'pointer' }}>
                                <MonoLogo width="284" height="60" />
                            </LogoBox>
                            <PageTabs />
                        </Row>

                        <Row className={styles.logoContainer} style={{ margin: '0' }} display="flex"
                            justifyContent="center" alignItems="center">
                            <LogoBoxMobile onClick={() => navigate('/home')}>
                                <MonoLogo
                                    width="43"
                                    height="36"
                                    src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/common/mobile-logo.png"
                                />
                            </LogoBoxMobile>
                        </Row>
                    </Box>

                    <Row display="flex" gap={{ sm: "10", md: "20" }}>
                        <TreatBalanceBox onClick={() => setIsShowGasSetting(true)}>
                            <img className='token_img'
                                src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/nav/gas_mode/gas_low.png"
                                alt="gmeowfi" />
                            {
                                window.screen.width > 600 && <span>
                                    Gas Mode
                                    {/* {formatNumber(gasSetingValue, 4)} */}
                                </span>
                            }
                        </TreatBalanceBox>

                        <TreatBalanceBox onClick={() => navigate('/swap?dailytask')}>
                            <img className='token_img'
                                src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/tokens/treat.png"
                                alt="gmeowfi" />
                            <span>
                                {formatNumber(dailyTaskStore.totalTokenClaimed)}
                            </span>
                            <img className='icon'
                                src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/swap/inactive-dropdown.png"
                                alt="gmeowfi" />
                        </TreatBalanceBox>
                        <GasInfoModal isOpen={isShowGasSetting} setIsOpen={setIsShowGasSetting} updateGasPrice={(value: string) => setGasSetingValue(value)} />

                        <ChainSelector />
                        <Web3Status />
                    </Row>

                    <Box
                        onClick={() => setIsShowMobileMenu(true)}
                        width="28"
                        position="relative"
                        display={{ lg: 'block', xl: 'none' }}
                    >
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.8381 0.269986L15.6933 0C16.3967 0 16.953 0.154297 17.362 0.462678C17.7873 0.752921 18 1.25177 18 1.95924C18 2.63042 17.7546 3.13835 17.2638 3.48301C16.773 3.82767 16.1595 4 15.4233 4C14.9816 4 14.5072 3.98186 14 3.94558C13.5092 3.89116 7.66258 3.80953 6.31561 3.80953C5.56306 3.80953 4.02249 4.11791 3.6135 3.80953C3.2045 3.50115 3 3.02043 3 2.36739C3 1.65993 3.22904 1.11572 3.68712 0.73478C4.14519 0.335698 4.75869 0.136157 5.52761 0.136157C6.03476 0.136157 6.53026 0.153503 6.97197 0.226064C7.23006 0.256724 9.60907 0.303851 11.8381 0.269986Z"
                                fill="#4B2828" />
                            <path
                                d="M11.8381 7.26998L15.6933 7C16.3967 7 16.953 7.1543 17.362 7.46268C17.7873 7.75292 18 8.25177 18 8.95924C18 9.63042 17.7546 10.1383 17.2638 10.483C16.773 10.8277 16.1595 11 15.4233 11C14.9816 11 14.5072 10.9819 14 10.9456C13.5092 10.8912 7.66258 10.8095 6.31561 10.8095C5.56306 10.8095 3.02249 11.1179 2.6135 10.8095C2.2045 10.5011 2 10.0204 2 9.36739C2 8.65992 2.22904 8.11572 2.68712 7.73478C3.14519 7.3357 3.75869 7.13616 4.52761 7.13616C5.03476 7.13616 6.53026 7.1535 6.97197 7.22606C7.23006 7.25672 9.60908 7.30385 11.8381 7.26998Z"
                                fill="#4B2828" />
                            <path
                                d="M10.3058 14.277L15.6933 14C16.3967 14 16.953 14.1543 17.362 14.4627C17.7873 14.7529 18 15.2518 18 15.9592C18 16.6304 17.7546 17.1383 17.2638 17.483C16.773 17.8277 16.1595 18 15.4233 18C14.9816 18 14.5072 17.9819 14 17.9456C13.5092 17.8912 5.66258 17.8095 4.31561 17.8095C3.56306 17.8095 1.02249 18.1179 0.613497 17.8095C0.204499 17.5011 0 17.0204 0 16.3674C0 15.6599 0.229039 15.1157 0.687117 14.7348C1.14519 14.3357 1.75869 14.1362 2.52761 14.1362C3.03476 14.1362 4.53026 14.1535 4.97197 14.2261C5.20786 14.2541 7.74582 14.2959 10.3058 14.277Z"
                                fill="#4B2828" />
                        </svg>
                    </Box>
                </Box>
                {isShowMobileMenu && <MobileMenuWrapper onClose={() => setIsShowMobileMenu(false)} />}
            </Nav>
        </>
    )
}

export default Navbar
