import {Trans} from '@lingui/macro'
import {TraceEvent} from '@uniswap/analytics'
import {BrowserEvent, ElementName, EventName} from '@uniswap/analytics-events'
import {Currency, CurrencyAmount, Percent, Token} from '@uniswap/sdk-core'
import {Pair} from 'custom-uniswap-v2-sdk'
import {useWeb3React} from '@web3-react/core'
import {AutoColumn} from 'components/Column'
import {LoadingOpacityContainer, loadingOpacityMixin} from 'components/Loader/styled'
import {isSupportedChain} from 'constants/chains'
import {darken} from 'polished'
import {ReactNode, useCallback, useState} from 'react'
import {Lock} from 'react-feather'
import styled, {useTheme} from 'styled-components/macro'
import {flexColumnNoWrap, flexRowNoWrap} from 'theme/styles'
import {formatCurrencyAmount} from 'utils/formatCurrencyAmount'

import {ReactComponent as DropDown} from '../../assets/images/dropdown.svg'
import {useCurrencyBalance} from '../../state/connection/hooks'
import {ThemedText} from '../../theme'
import {ButtonGray} from '../Button'
import DoubleCurrencyLogo from '../DoubleLogo'
import CurrencyLogo from '../Logo/CurrencyLogo'
import {Input as NumericalInput} from '../NumericalInput'
import {RowBetween, RowFixed} from '../Row'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import {FiatValue} from './FiatValue'

const InputPanel = styled.div<{ hideInput?: boolean }>`
    ${flexColumnNoWrap};
    position: relative;
    border-radius: 10px;
    border: 2px solid #E5B670;
    background: #FFF6D0;
    box-shadow: 4px 4px 4px 0px rgba(215, 144, 60, 0.80) inset, -4px -4px 4px 0px #FFF inset;
    z-index: 1;
    width: ${({hideInput}) => (hideInput ? '100%' : 'initial')};
    opacity: ${({hideInput}) => (hideInput ? 0.5 : 1)};
    transition: height 1s ease;
    will-change: height;
    padding: 24px;
`

const FixedContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
`

const Container = styled.div<{ hideInput: boolean; disabled: boolean }>`
    background-color: transparent;
    width: ${({hideInput}) => (hideInput ? '100%' : 'initial')};
    ${({theme, hideInput, disabled}) =>
            !disabled &&
            `
   
  `}
`
const PriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 8px;
`

const BalanceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const CurrencySelect = styled(ButtonGray) <{
    visible: boolean
    selected: boolean
    hideInput?: boolean
    disabled?: boolean
}>`
    align-items: center;
    border-radius: 8px;
    opacity: ${({disabled}) => (!disabled ? 1 : 0.4)};
    box-shadow: ${({selected}) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')};
    color: #4B2828;
    cursor: pointer;
    height: unset;
    outline: none;
    user-select: none;
    border: none;
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    padding: ${({selected}) => (selected ? '0' : '0')};
    gap: 8px;
    justify-content: space-between;
    margin-left: ${({hideInput}) => (hideInput ? '0' : '0')};

    &:hover,
    &:active {
    }

    &:before {
        background-size: 100%;

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        content: '';
    }

    &:hover:before {
    }

    &:active:before {
    }

    visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
    align-items: center;
    border-radius: 8px;
    opacity: ${({disabled}) => (!disabled ? 1 : 0.4)};
    box-shadow: ${({selected}) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')};
    color: #4B2828;
    cursor: pointer;
    height: unset;
    outline: none;
    user-select: none;
    border: none;
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    padding: ${({selected}) => (selected ? '0' : '0')};
    gap: 8px;
    justify-content: space-between;
    margin-left: ${({hideInput}) => (hideInput ? '0' : '0')};

    &:hover,
    &:active {
    }

    &:before {
        background-size: 100%;

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        content: '';
    }

    &:hover:before {
    }

    &:active:before {
    }

    visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
`

const InputRow = styled.div<{ selected: boolean }>`
    ${flexRowNoWrap};
    align-items: center;
    justify-content: space-between;
    background: rgba(239, 239, 228, 0.1);
    gap: 8px;
`

const LabelRow = styled.div`
    ${flexRowNoWrap};
    align-items: center;
    color: #4B2828;
    font-size: 0.75rem;
    line-height: 1rem;

    span:hover {
        cursor: pointer;
        color: ${({theme}) => darken(0.2, theme.deprecated_text2)};
    }
`
const FiatRow = styled(LabelRow)`
    justify-content: flex-end;
    min-height: 20px;
    padding: 8px 0px 0px 0px;
    width: 100%;
`

const Aligner = styled.span`
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
`

const StyledDropDown = styled(DropDown) <{ selected: boolean }>`
    margin: 0 0.25rem 0 0.35rem;
    height: 35%;
    margin-left: 8px;

    path {
        stroke: #4B2828;
        stroke-width: 2px;
    }

    :hover {
        stroke: #68fff3;
    }

    :active {
        stroke: #68fff3;
    }
`

const StyledTokenName = styled.span<{ active?: boolean }>`
    ${({active}) => (active ? 'margin: 0' : 'margin: 0')}
    color: #4B2828;
    text-align: center;
    font-family: Outfit;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 21px */
    text-transform: uppercase;
    @media (max-width: 767px) {
        font-size: 16px;
    }
`

const StyledBalanceMax = styled.button<{ disabled?: boolean }>`
    background: #00E440;
    border: none;
    color: #68fff3;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
    opacity: ${({disabled}) => (!disabled ? 1 : 0.4)};
    padding: 4px 6px;
    pointer-events: ${({disabled}) => (!disabled ? 'initial' : 'none')};
    border-radius: 8px;

    :hover {
        opacity: ${({disabled}) => (!disabled ? 0.8 : 0.4)};
    }

    :focus {
        outline: none;
    }
`

const StyledNumericalInput = styled(NumericalInput) <{ $loading: boolean }>`
    ${loadingOpacityMixin};
    text-align: left;
    color: #4B2828;
    font-family: Outfit;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 32px */
    width: 100%;
    text-overflow: unset;
    @media (max-width: 767px) {
        font-size: 21px;
    }
`

const StyleValueUsd = styled.div`
    color: #E5B670;
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 18px */;
    @media (max-width: 767px) {
        font-size: 14px;
    }
`

const InputWidth = styled.div`
    width: 100%;
`

interface CurrencyInputPanelProps {
    value: string
    onUserInput: (value: string) => void
    onMax?: () => void
    showMaxButton: boolean
    label?: ReactNode
    onCurrencySelect?: (currency: Currency) => void
    currency?: Currency | null
    hideBalance?: boolean
    pair?: Pair | null
    hideInput?: boolean
    otherCurrency?: Currency | null
    fiatValue?: CurrencyAmount<Token> | null
    priceImpact?: Percent
    id: string
    showCommonBases?: boolean
    showCurrencyAmount?: boolean
    disableNonToken?: boolean
    renderBalance?: (amount: CurrencyAmount<Currency>) => ReactNode
    locked?: boolean
    loading?: boolean
    customeFiatValue?: any
}

export default function PoolCurrencyInputPanel({
                                                   value,
                                                   onUserInput,
                                                   onMax,
                                                   showMaxButton,
                                                   onCurrencySelect,
                                                   currency,
                                                   otherCurrency,
                                                   id,
                                                   showCommonBases,
                                                   showCurrencyAmount,
                                                   disableNonToken,
                                                   renderBalance,
                                                   fiatValue,
                                                   priceImpact,
                                                   hideBalance = false,
                                                   pair = null, // used for double token logo
                                                   hideInput = false,
                                                   locked = false,
                                                   loading = false,
                                                   customeFiatValue,

                                                   ...rest
                                               }: CurrencyInputPanelProps) {
    const [modalOpen, setModalOpen] = useState(false)
    const {account, chainId} = useWeb3React()
    const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
    const theme = useTheme()

    const handleDismissSearch = useCallback(() => {
        setModalOpen(false)
    }, [setModalOpen])

    const chainAllowed = isSupportedChain(chainId)

    return (
        <InputPanel id={id} hideInput={hideInput} {...rest}>
            {locked && (
                <FixedContainer>
                    <AutoColumn gap="sm" justify="center">
                        <Lock stroke="#4B2828"/>
                        <div
                            style={{
                                fontSize: '12px',
                                textAlign: 'center',
                                padding: '0 12px',
                                color: '#4B2828',
                            }}
                        >
                            <Trans>
                                The market price is outside your specified price range. <br/>
                                Single-asset deposit only.
                            </Trans>
                        </div>
                    </AutoColumn>
                </FixedContainer>
            )}
            <Container hideInput={hideInput} disabled={!chainAllowed}>
                <InputRow style={hideInput ? {padding: '0'} : {}} selected={!onCurrencySelect}>
                    {currency && selectedCurrencyBalance ? (
                        <div>
                            {!hideInput && (
                                <PriceWrapper>
                                    <InputWidth>
                                        <StyledNumericalInput
                                            className="token-amount-input"
                                            value={value}
                                            onUserInput={onUserInput}
                                            disabled={!chainAllowed}
                                            $loading={loading}

                                        />
                                    </InputWidth>
                                    {!hideBalance && currency && (
                                        <StyleValueUsd>
                                            {customeFiatValue && value && !isNaN(Number(customeFiatValue)) && !isNaN(Number(value)) ? (
                                                <>
                                                    $
                                                    {Number(customeFiatValue) * Number(value) > 0.01
                                                        ? (Number(customeFiatValue) * Number(value)).toFixed(3)
                                                        : '< 0.01'}
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </StyleValueUsd>
                                    )}
                                </PriceWrapper>
                            )}
                        </div>
                    ) : (
                        <div style={{display: "none"}}></div>
                    )}


                    <BalanceWrapper>
                        {!hideInput && !hideBalance && currency && (
                            <>
                                {account ? (
                                    <RowFixed style={{height: '17px', marginTop: '10px'}}>
                                        {showMaxButton && selectedCurrencyBalance ? (
                                            <TraceEvent
                                                events={[BrowserEvent.onClick]}
                                                name={EventName.SWAP_MAX_TOKEN_AMOUNT_SELECTED}
                                                element={ElementName.MAX_TOKEN_AMOUNT_BUTTON}
                                            >
                                                <StyledBalanceMax onClick={onMax}>
                                                    <span
                                                        style={{
                                                            background: '#fff',
                                                            backgroundClip: 'text',
                                                            WebkitBackgroundClip: 'text',
                                                            color: 'transparent',
                                                            fontFamily: "Outfit",
                                                            fontSize: "12px",
                                                            fontStyle: "normal",
                                                            fontWeight: "600"
                                                        }}
                                                    >
                                                        MAX
                                                    </span>
                                                </StyledBalanceMax>
                                            </TraceEvent>
                                        ) : null}
                                        <div
                                            onClick={onMax}
                                            style={{
                                                display: 'inline',
                                                cursor: 'pointer',
                                                color: '#4B2828',
                                                fontSize: '14px',
                                                marginLeft: '5px',
                                                width: 'max-content'
                                            }}
                                        >
                                            {!hideBalance && currency && selectedCurrencyBalance ? (
                                                renderBalance ? (
                                                    renderBalance(selectedCurrencyBalance)
                                                ) : (
                                                    <div>
                                                        {' '}
                                                        <span
                                                            style={{
                                                                color: '#E5B670',
                                                                fontFamily: "Outfit"
                                                            }}>Balance:</span>{' '}
                                                        <span style={{
                                                            color: '#4B2828',
                                                            fontFamily: "Outfit",
                                                            fontWeight: "600"
                                                        }}>
                                                            {formatCurrencyAmount(selectedCurrencyBalance, 4)}
                                                        </span>
                                                    </div>
                                                )
                                            ) : null}
                                        </div>
                                    </RowFixed>
                                ) : (
                                    <span/>
                                )}
                            </>
                        )}

                        <CurrencySelect
                            disabled={!chainAllowed}
                            visible={currency !== undefined}
                            selected={!!currency}
                            hideInput={hideInput}
                            className="open-currency-select-button"
                            onClick={() => {
                                if (onCurrencySelect) {
                                    setModalOpen(true)
                                }
                            }}
                        >
                            <Aligner>
                                <RowFixed>
                                    {pair ? (
                                        <span style={{marginRight: '0.5rem'}}>
                                            <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1}
                                                                size={24} margin={true}/>
                                        </span>
                                    ) : currency ? (
                                        <CurrencyLogo style={{marginRight: '0.5rem'}} currency={currency} size="24px"/>
                                    ) : null}
                                    {pair ? (
                                        <StyledTokenName className="pair-name-container">
                                            {pair?.token0.symbol}:{pair?.token1.symbol}
                                        </StyledTokenName>
                                    ) : (
                                        <StyledTokenName className="token-symbol-container"
                                                         active={Boolean(currency && currency.symbol)}>
                                            {(currency && currency.symbol && currency.symbol.length > 20
                                                ? currency.symbol.slice(0, 4) +
                                                '...' +
                                                currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                                                : currency?.symbol) || <Trans>Select token</Trans>}
                                        </StyledTokenName>
                                    )}
                                </RowFixed>
                                {onCurrencySelect &&
                                    // <StyledDropDown selected={!!currency}/>
                                    <img style={{width: '24px', height: '24px'}}
                                         src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/add-liquidity/img_4.png"
                                         alt="img_4"/>
                                }
                            </Aligner>

                        </CurrencySelect>
                    </BalanceWrapper>
                </InputRow>

            </Container>
            {onCurrencySelect && (
                <CurrencySearchModal
                    isOpen={modalOpen}
                    onDismiss={handleDismissSearch}
                    onCurrencySelect={onCurrencySelect}
                    selectedCurrency={currency}
                    otherSelectedCurrency={otherCurrency}
                    showCommonBases={showCommonBases}
                    showCurrencyAmount={showCurrencyAmount}
                    disableNonToken={disableNonToken}
                />
            )}
        </InputPanel>
    )
}
