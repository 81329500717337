import { useEffect, useMemo, useState } from "react";
import {
  XAxis, YAxis, Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area
} from "recharts";
import { MusdStatType, TimePeriodType } from ".";
import { CustomXAxisTick, ToolTipBox, useTimestampFormater } from "./MusdPriceChart";
import { formatXpNumber } from "pages/Xp/sections/XpTitle";



export const CustomTooltip = ({ active, payload, label, timestampFormat }: any) => {
  if (active && payload && payload.length) {
    return (
      <ToolTipBox style={{ alignContent: 'flex-start', justifyContent: 'start', minWidth: '100px' }}>
        <span>{timestampFormat(label)}</span>
        <span>USDT: {formatXpNumber(payload[1].value)}</span>
        <span>PAW: {formatXpNumber(payload[0].value)}</span>
      </ToolTipBox>
    );
  }
  return null;
};

const YTickCustomContent = ({ x, y, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" className="musd-chart-axis-text">
        {formatXpNumber(payload?.value ?? 0)}
      </text>
    </g>
  )
}

export default function CollateralChart({ musdStats, timePeriod, minRange, maxRange }: { musdStats: MusdStatType[]; timePeriod: TimePeriodType; minRange?: number; maxRange?: number }) {
  const timestampFormat = useTimestampFormater(timePeriod);


  //   const priceRange = useMemo(() => {
  //     let minRange = 0;
  //     let maxRange = 0;
  //     if (currentPrice > 0) {
  //         minRange = currentPrice - 0.015;
  //         maxRange = currentPrice + 0.002;
  //     }
  //     return [minRange, maxRange]
  // }, [currentPrice]);

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.screen.width < 768)
      setIsMobile(true)
  }, [])

  useEffect(() => {
  }, [musdStats])
  return <>
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={musdStats}
      // onMouseOver={onMouseChart}
      // onMouseMove={onMouseOn}
      // onMouseOut={() => setHasMouseChart(false)}
      >
        <XAxis axisLine={false} dataKey="timestamp"
          tickCount={2} tickSize={2} tickLine={false}
          interval={'preserveStartEnd'}
          tick={<CustomXAxisTick timestampFormat={timestampFormat} />}
          padding={{ right: isMobile ? 40 : 100 }}
        />
        <YAxis
          padding={{ top: 70 }}
          axisLine={false}
          tickLine={false}
          interval={'preserveStartEnd'}
          orientation='right'
          tickMargin={5}
          tick={<YTickCustomContent />} />
        <Tooltip content={<CustomTooltip timestampFormat={timestampFormat} />} />

        <Area type="monotone" dataKey="supply" strokeWidth="3px" stroke="#FFB704" fill="none" />
        <Area type="monotone" dataKey="collateral" strokeWidth="3px" stroke="#68FF9B" fill="none" />
      </AreaChart>
    </ResponsiveContainer>
  </>
}