// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { sendEvent } from 'components/analytics'
import { isSupportedChainId } from 'lib/hooks/routing/clientSideSmartOrderRouter'
import { useEffect, useRef, useState } from 'react'
import { Settings, X } from 'react-feather'
import { Text } from 'rebass'
import styled, { useTheme } from 'styled-components/macro'

import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { useModalIsOpen, useToggleSettingsMenu } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/reducer'
import { useClientSideRouter, useExpertModeManager } from '../../state/user/hooks'
import { ThemedText } from '../../theme'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import Modal from '../Modal'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
import Toggle from '../Toggle'
import TransactionSettings from '../TransactionSettings'

const closeButton = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/swap/close-icon.png'

const StyledMenuIcon = styled(Settings)`
  height: 24px;
  width: 24px;

  > * {
    stroke: #FFB704;
  }
`

const StyledCloseIcon = styled(X)`
  height: 20px;
  width: 20px;
  :hover {
    cursor: pointer;
  }

  > * {
    stroke: #FFB704;
  }
`

const StyledMenuButton = styled.button<{ disabled: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 24px;

  ${({ disabled }) =>
    !disabled &&
    `
    :hover,
    :focus {
      cursor: pointer;
      outline: none;
      opacity: 0.7;
    }
  `}
`
const EmojiWrapper = styled.div`
  position: absolute;
  bottom: -6px;
  right: 0px;
  font-size: 14px;
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`
const MenyFlyoutModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* position: fixed; */
  /* top: 0; */
  /* right: 0; */
  /* bottom: 0; */
  /* left: 0; */
  /* background-color: rgba(0, 0, 0, 0.5); */
   /* Semi-transparent background */
  /* z-index: 99999; */
`

const MenuFlyout = styled.div` 
  width: 550px;
  height: fit-content;
  border: 1px solid none;
  padding: 20px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: #4B2828;

  border-radius: 10px;
  border: 1.5px solid #E5B670;
  background: #FFF6D0;
  box-shadow: 0px 7.395px 30px 0px rgba(145, 103, 20, 0.30);
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
   min-width: 18.125rem;
 `};

  user-select: none;
`

const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.deprecated_bg3};
`

const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  background-color: #FFF6D0;

  
`

export default function SettingsTab({ placeholderSlippage }: { placeholderSlippage: Percent }) {
  const { chainId } = useWeb3React()

  const node = useRef<HTMLDivElement | null>(null)
  const open = useModalIsOpen(ApplicationModal.SETTINGS)
  const toggle = useToggleSettingsMenu()

  const theme = useTheme()

  const [expertMode, toggleExpertMode] = useExpertModeManager()

  const [clientSideRouter, setClientSideRouter] = useClientSideRouter()
  // show confirmation view before turning on
  const [showConfirmation, setShowConfirmation] = useState(false)

  // useOnClickOutside(node, open ? toggle : undefined)

  // set expert mode default
  // useEffect(() => {
  //   if (!expertMode) {
  //     toggleExpertMode()
  //   }
  // }, [])
  return (
    <>
      <StyledMenu ref={node}>
        <Modal isOpen={showConfirmation} onDismiss={() => setShowConfirmation(false)} maxHeight={100}>
          <ModalContentWrapper>
            <AutoColumn gap="lg">
              <RowBetween style={{ padding: '0 2rem' }}>
                <div />
                <Text fontWeight={500} fontSize={20} color={'#4B2828'}>
                  <Trans>Are you sure?</Trans>
                </Text>
                <StyledCloseIcon onClick={() => setShowConfirmation(false)} color='#FFB704' stroke='#FFB704'/>
              </RowBetween>
              {/* <Break /> */}
              <AutoColumn gap="lg" style={{ padding: '0 2rem' }} color={'#4B2828'}>
                <Text fontWeight={400} fontSize={16} color={'#4B2828'}>
                  <Trans>
                    Expert mode turns off the confirm transaction prompt and allows high slippage trades that often result
                    in bad rates and lost funds.
                  </Trans>
                </Text>
                <Text fontWeight={400} fontSize={16} color={'#4B2828'}>
                  <Trans>ONLY USE THIS MODE IF YOU KNOW WHAT YOU ARE DOING.</Trans>
                </Text>
                <ButtonError
                  error={true}
                  padding="12px"
                  onClick={() => {
                    const confirmWord = t`confirm`
                    if (window.prompt(t`Please type the word "${confirmWord}" to enable expert mode.`) === confirmWord) {
                      toggleExpertMode()
                      setShowConfirmation(false)
                    }
                  }}
                >
                  <Text fontSize={20} fontWeight={500} id="confirm-expert-mode" color={'#FFF9E1'}>
                    <Trans>Turn On Expert Mode</Trans>
                  </Text>
                </ButtonError>
              </AutoColumn>
            </AutoColumn>
          </ModalContentWrapper>
        </Modal>
        <StyledMenuButton
          disabled={!isSupportedChainId(chainId)}
          onClick={toggle}
          id="open-settings-dialog-button"
          aria-label={t`Transaction Settings`}
        >
          <StyledMenuIcon />
          {expertMode ? (
            <EmojiWrapper>
              <span role="img" aria-label="wizard-icon">
                🧙
              </span>
            </EmojiWrapper>
          ) : null}
        </StyledMenuButton>
      </StyledMenu>
      {open && (
        <Modal isOpen={open} onDismiss={() => { }}>
          <MenyFlyoutModal >
            <MenuFlyout>
              <AutoColumn gap="md" style={{ padding: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Text fontWeight={400} fontSize={24}>
                    <Trans>Settings</Trans>
                  </Text>
                  <div onClick={toggle} style={{ cursor: 'pointer' }}>
                    <img src={closeButton} alt="close button" style={{ width: '20px', height: '20px' }} />
                  </div>
                </div>

                <TransactionSettings placeholderSlippage={placeholderSlippage} />

                <Text fontWeight={400} fontSize={14}>
                  <Trans>Interface Settings</Trans>
                </Text>
                {isSupportedChainId(chainId) && (
                  <RowBetween>
                    <RowFixed>
                      <ThemedText.DeprecatedBlack fontWeight={400} fontSize={14} color={theme.deprecated_text2}>
                        <Trans>Auto Router API</Trans>
                      </ThemedText.DeprecatedBlack>
                      <QuestionHelper text={<Trans>Use the Gmeow Labs API to get faster quotes.</Trans>} />
                    </RowFixed>
                    <Toggle
                      id="toggle-optimized-router-button"
                      isActive={!clientSideRouter}
                      toggle={() => {
                        sendEvent({
                          category: 'Routing',
                          action: clientSideRouter ? 'enable routing API' : 'disable routing API',
                        })
                        setClientSideRouter(!clientSideRouter)
                      }}
                    />
                  </RowBetween>
                )}
                <RowBetween>
                  <RowFixed>
                    <ThemedText.DeprecatedBlack fontWeight={400} fontSize={14} color={theme.deprecated_text2}>
                      <Trans>Expert Mode</Trans>
                    </ThemedText.DeprecatedBlack>
                    <QuestionHelper
                      text={
                        <Trans>Allow high price impact trades and skip the confirm screen. Use at your own risk.</Trans>
                      }
                    />
                  </RowFixed>
                  <Toggle
                    id="toggle-expert-mode-button"
                    isActive={expertMode}
                    toggle={
                      expertMode
                        ? () => {
                          toggleExpertMode()
                          setShowConfirmation(false)
                        }
                        : () => {
                          toggle()
                          setShowConfirmation(true)
                        }
                    }
                  />
                </RowBetween>
              </AutoColumn>
            </MenuFlyout>
          </MenyFlyoutModal>
        </Modal>
      )}
    </>
  )
}
