import { useWeb3React } from '@web3-react/core'
import { getChainInfoOrDefault } from 'constants/chainInfo'
import { SupportedChainId } from 'constants/chains'
import { useMemo } from 'react'
import { AlertTriangle, CheckCircle } from 'react-feather'
import styled from 'styled-components/macro'
import { ExternalLink } from 'theme'
import { colors } from 'theme/colors'

import { TransactionDetails } from '../../state/transactions/types'
import Loader from '../Loader'
import LogoView from './LogoView'
import TransactionBody from './TransactionBody'

export enum TransactionState {
  Pending,
  Success,
  Failed,
}

const Grid = styled(ExternalLink)<{ isLastTransactionInList?: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  // grid-template-columns: 44px auto 24px;
  width: 100%;
  text-decoration: none;
  padding: 12px 16px;
`

const TextContainer = styled.span`
  font-size: 14px;
  margin-top: auto;
  margin-bottom: auto;
  color: #4B2828;
`

const IconStyleWrap = styled.span`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  height: 14px;
`

export const TransactionSummary = ({
  transactionDetails,
  isLastTransactionInList = false,
}: {
  transactionDetails: TransactionDetails
  isLastTransactionInList?: boolean
}) => {
  const { chainId = 1 } = useWeb3React()
  const tx = transactionDetails
  const { explorer } = getChainInfoOrDefault(chainId ? chainId : SupportedChainId.MAINNET)
  const { info, receipt, hash } = tx

  const transactionState = useMemo(() => {
    const pending = !receipt
    const success = !pending && tx && (receipt?.status === 1 || typeof receipt?.status === 'undefined')
    const transactionState = pending
      ? TransactionState.Pending
      : success
      ? TransactionState.Success
      : TransactionState.Failed

    return transactionState
  }, [receipt, tx])

  const link = `${explorer}tx/${hash}`

  return chainId ? (
    <Grid href={link} target="_blank" isLastTransactionInList={isLastTransactionInList}>
      {/*<LogoView info={info} />*/}
      <TextContainer as="span">
        <TransactionBody info={info} transactionState={transactionState} />
      </TextContainer>
      {transactionState === TransactionState.Pending ? (
        <IconStyleWrap>
          <Loader />
        </IconStyleWrap>
      ) : transactionState === TransactionState.Success ? (
        <IconStyleWrap>
          <CheckCircle stroke={'#4B2828'} size="14px" />
        </IconStyleWrap>
      ) : (
        <IconStyleWrap>
          <AlertTriangle stroke={'#4B2828'} size="14px" />
        </IconStyleWrap>
      )}
    </Grid>
  ) : null
}
