import { Trans } from '@lingui/macro'
import { Currency } from '@uniswap/sdk-core'
import { ToggleElement, ToggleWrapper } from 'components/Toggle/MultiToggle'

// the order of displayed base currencies from left to right is always in sort order
// currencyA is treated as the preferred base currency
export default function RateToggle({
  currencyA,
  currencyB,
  handleRateToggle,
}: {
  currencyA: Currency
  currencyB: Currency
  handleRateToggle: (currency: Currency) => void
}) {
  const tokenA = currencyA?.wrapped
  const tokenB = currencyB?.wrapped

  const isSorted = tokenA && tokenB && tokenA.sortsBefore(tokenB)

  const handleToggle = (currency: Currency) => {
    handleRateToggle(currency)
  }

  return tokenA && tokenB ? (
    <div style={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}>
      <ToggleWrapper width="fit-content">
        <ToggleElement isActive={isSorted} fontSize="12px" onClick={() => handleToggle(isSorted ? currencyA : currencyB)}>
          <Trans>{isSorted ? currencyA.symbol : currencyB.symbol}</Trans>
        </ToggleElement>
        <ToggleElement isActive={!isSorted} fontSize="12px" onClick={() => handleToggle(isSorted ? currencyB : currencyA)}>
          <Trans>{isSorted ? currencyB.symbol : currencyA.symbol}</Trans>
        </ToggleElement>
      </ToggleWrapper>
    </div>
  ) : null
}
