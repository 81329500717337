import { useWeb3React } from "@web3-react/core";
import { NotchedButtonFill } from "components/Button";
import { NotchedButtonBolderNew } from "components/Button/NotchedButton";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useToggleWalletModal } from "state/application/hooks";
import styled from "styled-components"
import { BREAKPOINTS, CustomLightSpinner } from "theme";
import axios from 'axios'
import MonopassNft from "abis/monopass/MonopassNft.json";
import MonopassNftMinter from "abis/monopass/MonopassNftMinter.json";
import MyNftStats from "./MyNftStats";
import { useCustomeContract } from "hooks/useContract";
import { ZIRCUIT_TESTNET_MEOW_NFT_ADDRESS, ZIRCUIT_TESTNET_MEOW_NFT_MINTER_ADDRESS } from "constants/addresses";
import {DEFAULT_CHAINID, SupportedChainId} from "constants/chains";
import useCountdownTimer from "hooks/useCountdownTimer";
import useSelectChain from "hooks/useSelectChain";
import { getContract } from "utils";
import { LoadingOutlined } from '@ant-design/icons'
import { GMEOW_API_BASE_URL } from "utils/env";
import { notification } from "antd";
import useProviderNoSign from "hooks/useProviderNoSign";
import dayjs from "dayjs";
import MeowLeaderboard from "./MeowLeaderboard";

const TextStyle = styled.span<
    {
        color?: string,
        fontFamily?: string,
        fontSize?: string,
        fontSizeMb?: string,
        fontWeight?: string,
        lineHeight?: string,
        margin?: string,
        marginMb?: string,
        padding?: string,
        textAlign?: string
    }
>`
    color: ${({ color }) => color ? color : '#4B2828'} !important;
    font-family: ${({ fontFamily }) => fontFamily ?? 'Outfit'};
    font-size: ${({ fontSize }) => fontSize ?? '16px'};
    font-style: normal;
    font-weight: ${({ fontWeight }) => fontWeight ?? '500'};
    line-height: ${({ lineHeight }) => lineHeight ?? '100%'};
    margin: ${({ margin }) => margin};
    padding: ${({ padding }) => padding};
    text-align: ${({ textAlign }) => textAlign ?? 'start'};
    @media (max-width: 747px) {
        font-size: ${({ fontSizeMb }) => fontSizeMb ?? '21px'};
        margin: ${({ marginMb }) => marginMb};
    }
    &.page-title{
        color: #4B2828;
        font-family: DynaPuff;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 45px */
        text-transform: uppercase;
        @media (max-width: 747px) {
            font-size: 24px;
        }
    }
`

const Section1ContentWrapper = styled.section`
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        margin-top: 50px;
        flex-direction: column-reverse;
        align-items: center;
    }
`;

const ContentLeft = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 600px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        width: 100%;
    }
`;

const ContentTopWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const TitleAndDescWrapper = styled.div`
    padding: 32px 24px 40px 24px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 2px solid #D7903C;
    background: #FFEFB0;

    .percent-wrapper {
        margin: 16px 0 26px 0;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        align-items: center;

        @media (max-width: 640px) {

            gap: 10px;
            margin: 10px 0 20px 0;
        }
    }
`

const StatisticWrapper = styled.div`
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid #D7903C;
    background: #FFEFB0;
    gap: 24px;

    .statistic-content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
    }
`

const StatisticLine = styled.div`
    width: 2px;
    height: 100%;
    background: rgba(229, 182, 112, 0.50);
    backdrop-filter: blur(4.800000190734863px);
    display: block;
    @media (max-width: 747px) {
        display: none;
    }
`

const MintedMeowPercentBox = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        flex-wrap: wrap;
    }
`

const ButtonBox = styled.div`
    display: flex;
    gap: 20px;
`;

const ButtonBoxNotchedButtonFillTextStyle = styled.p`
    color: #FFF9E1;
    text-align: center;
    font-family: DynaPuff;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 21px */
    text-transform: uppercase;
    margin: 0;
    @media (max-width: ${BREAKPOINTS.sm}px) {
        font-size: 18px;
    }
`

const ContentRight = styled.div`
    border-radius: 12px;
    height: fit-content;

    .img-wrapper {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
            @media (max-width: ${BREAKPOINTS.md}px) {
                max-width: 363px;
                max-height: 480px;
            }
        }
    }
`;
const MonoPassWrapper = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`;
const MonoPassBody = styled.div`
    /* width: 64%; */
    width: 1224px;
    margin: 0 auto;
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        width: 100%;
        padding: 0 20px;
    }
`;

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 72px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        gap: 36px;
    }
`;

const Section2ContentWrapper = styled.div`
    position: relative;

    .bg-section-2 {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
        @media screen and (max-width: ${BREAKPOINTS.xl}px) {
            opacity: 0;
        }
        @media screen and (max-width: ${BREAKPOINTS.lg}px) {
            display: none;
        }
    }

`

const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 0 60px 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        position: relative;
    }

    .title {
        .title-top {
            margin: 0;
            color: #4B2828;
            text-align: center;
            font-family: DynaPuff;
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: 125%; /* 45px */
            text-transform: uppercase;
            @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                font-size: 24px;
            }
        }

        .title-bot {
            margin: 0;
            color: #FFB704;
            text-align: center;
            -webkit-text-stroke-width: 3px;
            -webkit-text-stroke-color: #723507;
            font-family: DynaPuff;
            font-size: 38px;
            font-style: normal;
            font-weight: 500;
            line-height: 125%; /* 46.25px */
            letter-spacing: 0.74px;
            text-transform: uppercase;
            @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                font-size: 25px;
                -webkit-text-stroke-width: 2px;
            }
        }
    }

    .desc-top {
        margin: 64px 0 0 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 80px;
        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
            gap: 0;
        }
    }

    .desc-bot {
        margin: 60px 0 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
            margin: 0;
        }
    }
`

const DescChildWrapper = styled.div`
    width: 100%;
    max-width: 422px;
    position: relative;

    img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
        scale: 1.15;
        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
            scale: 0.9;
        }
    }

    .desc-child-content-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 32px;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
            gap: 18px;
        }

        .title-desc {
            .title-desc-top {
                color: #00E440;
                text-align: center;
                font-family: DynaPuff;
                font-size: 32px;
                font-weight: 400;
                line-height: 100%; /* 32px */
                margin: 0;
                @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                    font-size: 22px;
                }
            }

            .title-desc-bot {
                color: #4B2828;
                font-family: DynaPuff;
                font-size: 32px !important;
                font-weight: 400;
                line-height: 100%;
                text-align: center;
                margin: 0;
                @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                    font-size: 22px;
                }
            }
        }

        .content {
            max-width: 272px;
            display: flex;
            @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                max-width: 230px;
            }
        }
    }
`

export const EnterCodePopup = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: #0a090f69;
    backdrop-filter: blur(1px);
    padding-top: 100px;
    @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
        padding-top: 100px;
    }
`;

export const EnterCodePopupStep1 = styled.div`
`;

const EnterCodePopupStep2 = styled.div`
    padding: 0 32px;
    overflow: auto;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        padding: 0 12px;
    }
`;
const EnterCodePopupStep2Task = styled(EnterCodePopupStep2)`
    padding: 0;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        height: 500px;
    }
`

export const EnterCodePopupBody = styled.div`
    max-width: 592px;
    /* height: 464px; */
    margin: auto;
    border-radius: 10px;
    background: #FFF6D0;
    box-shadow: 0px 7.395px 30px 0px rgba(145, 103, 20, 0.30);
    padding-top: 40px;
    padding-bottom: 55px;
    position: relative;
`;


export const CloseEnterCodePopupIcon = styled.div`
    position: absolute;
    top: 24px;
    right: 32px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: #FFB704;

    &:hover {
        scale: 1.05;
    }
`;

export const EnterCodePopupTitle = styled.h5`
    color: #4B2828;
    text-align: center;
    font-family: DynaPuff;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 40px */
    margin: 0;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        font-size: 18px;
        line-height: 22px;
        width: 80%;
    }
`;

export const EnterCodePopupInputList = styled.div`
    display: flex;
    gap: 8px;
    padding: 0 32px;
    padding-top: 40px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        justify-content: center;
    }
`;

export const EnterCodePopupInputBox = styled.div`
    width: 81px;
    height: 81px;
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 10px;
    background: #FFF9E1;
    box-shadow: 0 4px 16px 0 rgba(179, 149, 114, 0.30);
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        width: 60px;
        height: 60px;
    }
`;

export const EnterCodePopupInput = styled.input<{
    isValidInputCode: boolean
}>`
    color: #4B2828;
    width: 100%;
    height: 100%;
    text-align: center;
    font-family: Outfit;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 30px */
    outline: none;
    border: unset;
    text-transform: lowercase;
    background: ${({ isValidInputCode }) => isValidInputCode ? '#05C63B' : 'unset'};
    background-clip: ${({ isValidInputCode }) => isValidInputCode ? 'text' : 'unset'};
    -webkit-background-clip: ${({ isValidInputCode }) => isValidInputCode ? 'text' : 'unset'};
    -webkit-text-fill-color: ${({ isValidInputCode }) => isValidInputCode ? 'transparent' : 'unset'};
`;

export const EnterCodePopupMessage = styled.div`
    color: #FF1E56;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.5px;
    padding: 12px 26px 32px 32px;

    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        text-align: center;
    }
`
export const EnterCodePopupButtonBox = styled.div`
    padding: 0 32px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        display: flex;
        justify-content: center;
    }
`;
const EnterCodePopupNote = styled.span`
    color: rgba(75, 40, 40, 0.50);
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.2px;
    margin: 0;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        font-size: 16px;
        line-height: 17.5px;
    }
`;

const SocialActionItemBox = styled.div`
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(3, calc(33% - 4px));
    gap: 9px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        grid-template-columns: 100%;
    }
`;
const SocialActionItem = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #4B2828;
    font-family: Outfit;
    gap: 33.6px;

    img.task-success-img{
        width: 56px;
        height: 56px;
        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
            width: 36px;
            height: 36px;
        }
    }
`;

const SocialActionItemTop = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    padding: 0 16px;

    span{
        text-align: left;
    }
    img {
        width: 48px;
        height: 48px;
    }
    @media (max-width: ${BREAKPOINTS.sm}px) {
        img {
            width: 32px;
            height: 32px;
        }
    }
`

const SocialActionItemLabel = styled.span`
    font-size: 12px;
    opacity: 0.5;
    text-align: start;
`;

const SocialActionItemText = styled.span`
    color: #4B2828;
    text-align: center;
    font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 22.5px */
    @media screen and (
        max-width: ${BREAKPOINTS.sm}px) {
        font-size: 14px;
    }
`;

const SocialActionItemTextButton = styled.p`
    color: #FFF9E1;
    text-align: center;
    font-family: DynaPuff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
    text-transform: uppercase;
    margin: 0;
    @media screen and (
        max-width: ${BREAKPOINTS.sm}px) {
        font-size: 12px;
    }
`

const LinearText = styled.span`
    font-family: Righteous;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 17.5px */
    text-decoration-line: underline;
    background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

const SocialActionItemWrapper = styled.div`
    padding: 16px 0 23.4px 0;
    background: #FFEFB0;
    border: 2px solid #D7903C;
    border-radius: 12px;
    width: 100%;
`

const SocialActionItemUnDone = ({ opacity, imgLink, text, btnText, onClick, isLoading }: {
    opacity: number;
    imgLink?: string;
    text: string;
    btnText: string;
    onClick: Function;
    isLoading?: boolean
}) => {
    return <div style={{ opacity }}>
        <SocialActionItem>
            <SocialActionItemTop>
                {/*<SocialActionItemLabel>{label}</SocialActionItemLabel>*/}
                <img src={imgLink} alt="img_4" />
                <SocialActionItemText>{text}</SocialActionItemText>
            </SocialActionItemTop>
            <NotchedButtonFill width='148px' height="40px" mbHeight="40px" earMaxW={'100px'} mbEarMaxW='110px'
                padding={"0"}
                mobilePadding="16px 60px"
                onClick={() => onClick()}>
                <SocialActionItemTextButton style={{ color: '#4B2828' }}>
                    {
                        isLoading ? <LoadingOutlined rev={undefined} /> : btnText
                    }
                </SocialActionItemTextButton>
            </NotchedButtonFill>
        </SocialActionItem>
    </div>
}
const SocialActionItemDone = ({ text, imgLink }: {
    label: string;
    text: string;
    imgLink: string;
}) => {
    return <>
        <SocialActionItem style={{ gap: '12px' }}>
            <SocialActionItemTop>
                <img src={imgLink} alt="img_4" />
                <SocialActionItemText>{text}</SocialActionItemText>
            </SocialActionItemTop>
            <img className="task-success-img" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/swap/swap-success.png" alt="img_4" />
        </SocialActionItem>
    </>
}

const ModalStatusWrapper = styled.div`
    background: rgba(255, 255, 255, 0.70);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    padding: 100px 0 0 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 8;
    top: 0;
    left: 0;
    margin-top: 100px;
`

const ModalStatusMintingWrapper = styled.div`
    padding: 32px 105px 40px 105px;
    border-radius: 10px;
    border: 1.5px solid #E5B670;
    background: #FFF6D0;
    box-shadow: 0px 7.395px 30px 0px rgba(145, 103, 20, 0.30);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    height: fit-content;

    .img-top {
        width: 120px;
        height: 120px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
        }

        .img-minting {
            animation-name: spin;
            animation-duration: 5000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }

        @keyframes spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
    }

    .content-bottom {
        display: flex;
        flex-direction: column;
        gap: 12px;
        justify-content: center;
        align-items: center;
    }
`

const ModalStatusTransactionErrorWrapper = styled.div`
    padding: 32px 105px 40px 105px;
    border-radius: 10px;
    border: 1.5px solid #E5B670;
    background: #FFF6D0;
    box-shadow: 0px 7.395px 30px 0px rgba(145, 103, 20, 0.30);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    height: fit-content;

    .img-top {
        width: 120px;
        height: 120px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
        }
    }

    .content-bottom {
        display: flex;
        flex-direction: column;
        gap: 12px;
        justify-content: center;
        align-items: center;

        .status-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .view-tx {
                color: #EEAA01;
                font-family: Outfit;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 125%;
                text-decoration-line: underline;
                margin: 0;
            }
        }
    }
`
const ModalStatusTransactionSuccessWrapper = styled.div`
    padding: 32px 105px 40px 105px;
    border-radius: 10px;
    border: 1.5px solid #E5B670;
    background: #FFF6D0;
    box-shadow: 0px 7.395px 30px 0px rgba(145, 103, 20, 0.30);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    height: fit-content;

    .img-top {
        width: 120px;
        height: 120px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
        }
    }

    .content-bottom {
        display: flex;
        flex-direction: column;
        gap: 12px;
        justify-content: center;
        align-items: center;

        .status-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .view-tx {
                color: #EEAA01;
                font-family: Outfit;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 125%;
                text-decoration-line: underline;
                margin: 0;
            }
        }
    }
`

type UserMintStatusType = {
    hasEnteredMintRefCode: boolean;
    hasFollowedTw: boolean;
    hasFollowedTw1: boolean;
    hasFollowedTw2: boolean;
    hasJoinedDiscord: boolean;
    hasRetweetTw: boolean;
    hasRetweetTw1: boolean;
    hasRetweetTw2: boolean;
    hasRetweetTw3: boolean;
    hasDoneBridge: boolean;
    totalInvited: number;
    mintRefCode: string;
}

// const BASE_URL = 'https://api.gmeow.fi'
const BASE_URL = GMEOW_API_BASE_URL
// const BASE_URL = 'https://api.gmeow.fi'
export default function MeowMint() {
    const { account, chainId, provider } = useWeb3React();
    const toggleWalletModal = useToggleWalletModal();
    const pathParams = useParams();
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();


    const currentTime = Date.now() / 1000
    const endTime = 1723460985
    const timeEnd = currentTime >= endTime ? endTime + (3 * 86400) : endTime
    const { days, hours, minutes, seconds } = useCountdownTimer('DAY', timeEnd)
    const [buttonTestModel, setButtonTestModel] = useState<boolean>(false);

    const [mintUserStatus, setMintUserStatus] = useState<UserMintStatusType>({
        hasEnteredMintRefCode: false,
        hasFollowedTw: false,
        hasFollowedTw1: false,
        hasFollowedTw2: false,
        hasJoinedDiscord: false,
        hasRetweetTw: false,
        hasRetweetTw1: false,
        hasRetweetTw2: false,
        hasRetweetTw3: false,
        hasDoneBridge: false,
        totalInvited: 0,
        mintRefCode: ''
    });
    const providerNoSign = useProviderNoSign()
    const monopassNftContract = getContract(ZIRCUIT_TESTNET_MEOW_NFT_ADDRESS[SupportedChainId.ZIRCUIT_TESTNET], MonopassNft, providerNoSign);
    const monopassMinterNftContract = getContract(ZIRCUIT_TESTNET_MEOW_NFT_MINTER_ADDRESS[SupportedChainId.ZIRCUIT_TESTNET], MonopassNftMinter, providerNoSign);
    const monopassMinterNftWithSign = useCustomeContract(ZIRCUIT_TESTNET_MEOW_NFT_MINTER_ADDRESS[SupportedChainId.ZIRCUIT_TESTNET], MonopassNftMinter, true);

    const [isShowEnterCodePopup, setIsShowEnterCodePopup] = useState<boolean>(false);
    const [hasCompletedEnterCode, setHasCompletedEnterCode] = useState<boolean>(false);

    const [enterCodePopupStep, setEnterCodePopupStep] = useState<number>(1);
    const [enterCodePopupStep1ErrMss, setEnterCodepopupStep1ErrMss] = useState<string>('');
    const [enterCodePopupStep2ErrMss, setEnterCodepopupStep2ErrMss] = useState<string>('');
    const [isValidInputCode, setIsValidInputCode] = useState<boolean>(false);
    const inputCodeList = pathParams?.id?.split('=')[1].split('') ?? ['', '', '', '', '', ''];
    const [disableConfirmInputCodeBtn, setDisableConfirmInputCodeBtn] = useState<boolean>(true);

    // Debounce function to delay execution
    function debounce(func: Function, wait: number) {
        let timeout: NodeJS.Timeout;
        return function executedFunction(...args: any[]) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }

    // Modified onChangeInputCode function with debounce
    const onChangeInputCode = debounce((event: KeyboardEvent, inputIndex: number) => {
        const inputRefs: HTMLInputElement[] = Array.from(document.querySelectorAll('input.inputCode')) as HTMLInputElement[];
        let tempInputCodeList = [...inputCodeList];

        if (/^[a-zA-Z0-9]$/.test(event.key)) {
            if (inputIndex < 5) {
                inputRefs[inputIndex + 1].focus();
            }
        } else if (event.key === 'Delete' || event.key === 'Backspace') {
            if (inputIndex > 0) {
                inputRefs[inputIndex].value = '';
                inputRefs[inputIndex - 1].focus();
                if (inputRefs[inputIndex - 1].value.length > 0) {
                    inputRefs[inputIndex - 1].setSelectionRange(1, 1);
                }
            }
        }

        inputRefs.forEach((item, index) => {
            tempInputCodeList[index] = item.value.trim().toLowerCase();
        });

        const isAnyInputEmpty = tempInputCodeList.some(value => value === '');
        setDisableConfirmInputCodeBtn(isAnyInputEmpty);
    }, 50); // 250 ms debounce time

    const [isCheckingMintNftRefCode, setIsCheckingMintNftRefCode] = useState<boolean>(false);
    const onCallApiCheckInputCode = async (codes: string[]) => {
        if (!account || isCheckingMintNftRefCode || codes.find(i => i == '')) return;
        setIsCheckingMintNftRefCode(true);
        try {
            const signMess = codes.join('').toLowerCase()
            const signature = await provider?.send('personal_sign', [signMess, account]);
            if (!mintUserStatus.hasEnteredMintRefCode)
                await axios.get(`${BASE_URL}/meow-mint/enter-mint-refcode?address=${account.toString()}&mint_ref_code=${codes.join('')}&message=${signMess}&signature=${signature}`);
            setIsValidInputCode(true);
            mintUserStatus.hasEnteredMintRefCode = true;
            setHasCompletedEnterCode(true);
            setEnterCodepopupStep1ErrMss('')
            setTimeout(() => setEnterCodePopupStep(2), 1000);
        } catch (err) {
            setEnterCodepopupStep1ErrMss('Invalid invitation code')
        } finally {
            setIsCheckingMintNftRefCode(false);
        }
        ;
    }
    const onConfirmInputCode = async () => {
        if (disableConfirmInputCodeBtn) return;

        const inputCodes: string[] = [];
        document.querySelectorAll('input.inputCode').forEach((item: any, index) => {
            inputCodes.push(item.value.trim().toLowerCase());
        });
        await onCallApiCheckInputCode(inputCodes);
    }

    // reset entercode popup step1
    useEffect(() => {

        if (!isShowEnterCodePopup) {
            setMintTxLink('');
            setconnectXErrMss('');
            setconnectDiscordErrMss('');
            setEnterCodepopupStep1ErrMss('');
            setEnterCodepopupStep2ErrMss('');

            setHasCompletedEnterCode(mintUserStatus.hasEnteredMintRefCode);
            if (!mintUserStatus.hasEnteredMintRefCode)
                setEnterCodePopupStep(1);
            else if (mintUserStatus.hasEnteredMintRefCode && mintUserStatus.hasFollowedTw && mintUserStatus.hasJoinedDiscord && mintUserStatus.hasRetweetTw)
                setEnterCodePopupStep(3);
            else setEnterCodePopupStep(2);
            setMintTxStatus('pending');
            setMintTxLink('');
        } else {
            const inputRefs: HTMLInputElement[] = Array.from(document.querySelectorAll('input.inputCode')) as HTMLInputElement[];

            inputRefs.forEach((item, index) => {
                item.addEventListener('paste', (event: any) => {
                    event.preventDefault();

                    // Access the pasted text from the event object
                    // @ts-ignore
                    const pastedText = (event.clipboardData || window.clipboardData).getData('text').split('');

                    if (pastedText.length == 6) {
                        inputRefs.forEach((input, index) => {
                            // @ts-ignore
                            input.value = pastedText[index].trim();
                        });
                        if (!inputRefs.find(i => i.value == ''))
                            setDisableConfirmInputCodeBtn(false);
                    }
                });

                inputRefs[index].value = inputCodeList[index]
            })
        }
    }, [isShowEnterCodePopup]);


    const [hasDoneFollowTw, setHasDoneFollowTw] = useState<boolean>(false);
    const [hasDoneFollowTw1, setHasDoneFollowTw1] = useState<boolean>(false);
    const [hasDoneFollowTw2, setHasDoneFollowTw2] = useState<boolean>(false);
    const [hasClickJoinTw, setHasClickJoinTw] = useState<boolean>(false);
    const [hasClickJoinTw1, setHasClickJoinTw1] = useState<boolean>(false);
    const [hasClickJoinTw2, setHasClickJoinTw2] = useState<boolean>(false);
    const [hasDoneJoinDiscord, setHasDoneJoinDiscord] = useState<boolean>(false);
    const [hasDoneRt, setHasDoneRt] = useState<boolean>(false);
    const [hasDoneRt1, setHasDoneRt1] = useState<boolean>(false);
    const [hasDoneRt2, setHasDoneRt2] = useState<boolean>(false);
    const [hasDoneRt3, setHasDoneRt3] = useState<boolean>(false);
    const [hasDoneBridge, setHasDoneBridge] = useState<boolean>(false);

    const [isClickingDeposit, setIsClickingDeposit] = useState<boolean>(false);
    const [isClickingRT, setIsClickingRT] = useState<boolean>(false);
    const [isClickingRT1, setIsClickingRT1] = useState<boolean>(false);
    const [isClickingRT2, setIsClickingRT2] = useState<boolean>(false);
    const [isClickingRT3, setIsClickingRT3] = useState<boolean>(false);
    const [isClickingFollowX, setIsClickingFollowX] = useState<boolean>(false);
    const [isClickingFollowX1, setIsClickingFollowX1] = useState<boolean>(false);
    const [isClickingFollowX2, setIsClickingFollowX2] = useState<boolean>(false);
    const [isClickingJoinDiscord, setIsClickingJoinDiscord] = useState<boolean>(false);

    const selectChain = useSelectChain()
    // btn to open entercode popup or mint nft
    const onClickButtonBox = async () => {
        if (!account) // open connect wallet
            toggleWalletModal()
        else { //mint nft
            if (mintUserStatus.hasEnteredMintRefCode && mintUserStatus.hasFollowedTw && mintUserStatus.hasJoinedDiscord && mintUserStatus.hasRetweetTw && !searchParams.get('login-by')) {
                if (mintingSlotLeft > 0) {
                    await handleMintNft();
                }
            } else setIsShowEnterCodePopup(true); // show entercode popup
        }
    }

    const switchChain = async () => {
        if (isSwitchingChainId) return
        try {
            setIsSwitchingChainId(true)
            await selectChain(SupportedChainId.ZIRCUIT_TESTNET)
        }
        catch (err) { }
        finally { setIsSwitchingChainId(false) }
    }

    const [currentSocialStep, setCurrentSocialStep] = useState<number>(1);
    const [connectXErrMss, setconnectXErrMss] = useState<String>("");
    const [connectDiscordErrMss, setconnectDiscordErrMss] = useState<String>("");

    const checkMintStatus = async (obj: UserMintStatusType) => {
        setEnterCodePopupStep(2);
    }

    const checkLoginOauth = async () => {
        if (searchParams.get('login-by') && mintUserStatus.hasEnteredMintRefCode) {
            try {
                if (searchParams.get('login-by') == 'twitter' && searchParams.get('login-status') == 'success' && searchParams.get('twId')) {
                    // await bindSocialAccount('tw', searchParams.get('twId') ?? '');
                    checkMintStatus(mintUserStatus);
                    setHasDoneFollowTw(true);
                } else if (searchParams.get('login-by') == 'discord' && searchParams.get('login-status') == 'success' && searchParams.get('discordUserId')) {
                    // await bindSocialAccount('discord', searchParams.get('discordUserId') ?? '');
                    checkMintStatus(mintUserStatus);
                    setHasDoneJoinDiscord(true);
                }
                else if (searchParams.get('login-by') == 'twitter' && searchParams.get('login-status') == 'failed')
                    setEnterCodepopupStep2ErrMss(searchParams.get('mss') ?? '')
                else if (searchParams.get('login-by') == 'discord' && searchParams.get('login-status') == 'failed')
                    setEnterCodepopupStep2ErrMss(searchParams.get('mss') ?? '')
                setEnterCodePopupStep(() => 2);
                setIsShowEnterCodePopup(() => true);
            } catch (err) {
            }
        }
        else if (mintUserStatus.hasEnteredMintRefCode && mintUserStatus.hasFollowedTw && mintUserStatus.hasJoinedDiscord && mintUserStatus.hasRetweetTw) return;
    }

    useEffect(() => {
        checkLoginOauth();
        setHasCompletedEnterCode(mintUserStatus.hasEnteredMintRefCode);
    }, [mintUserStatus]);


    const [mintTxStatus, setMintTxStatus] = useState<'pending' | 'failed' | 'done'>('done');
    const [mintTxLink, setMintTxLink] = useState<string>('');
    const [isMintingNft, setIsMintingNft] = useState<boolean>(false);
    const [lastMintedIndex, setLastMintedIndex] = useState<number>(0)
    const handleMintNft = async () => {
        try {
            if (isMintingNft) return;
            setIsMintingNft(true);
            setEnterCodePopupStep(3);
            setIsShowEnterCodePopup(true);
            setMintTxStatus('pending');
            const response = await axios.get(`${BASE_URL}/meow-mint/get-signature?address=${account?.toString()}`);
            const signatureParams: {
                to: string;
                nonce: any;
                expiredAt: any;
                signature: any;
                mintCnt: number
            } = response.data;

            const transaction = await monopassMinterNftWithSign?.batchMintBySignature(signatureParams.to, signatureParams.nonce, signatureParams.mintCnt, signatureParams.expiredAt, signatureParams.signature);
            setMintTxLink(`https://explorer.testnet.zircuit.com/tx/${transaction.hash}`);
            await transaction.wait();
            setMintTxStatus('done');
            setLastMintedIndex(myNftCnt)
            setMyNftCnt(myNftCnt + signatureParams.mintCnt);
            setTotalMintedNft(totalMintedNft + signatureParams.mintCnt)
            setTotalMyMintedNft(totalMyMintedNft + signatureParams.mintCnt)
            if (location.href.indexOf('ref') != -1 || location.href.indexOf('login-by') != -1) navigate('/meow-mint')
        } catch (err) {
            setMintTxStatus('failed');
        } finally {
            setIsMintingNft(false);
        }
    }
    const [isAddingNft, setIsAddingNft] = useState<boolean>(false)
    const handleAddMonopassNftToken = async () => {
        if (!account || isAddingNft) return;
        try {
            setIsAddingNft(true)
            let providerWl: any = provider
            if (provider && provider.provider)
                providerWl = provider.provider
            if (providerWl) {
                const addTokenId = (await monopassNftContract?.tokenOfOwnerByIndex(account.toString(), myNftCnt - 1)).toString()
                await providerWl.request({
                    method: 'wallet_watchAsset',
                    params: {
                        type: "ERC721",
                        options: {
                            address: ZIRCUIT_TESTNET_MEOW_NFT_ADDRESS[SupportedChainId.ZIRCUIT_TESTNET],
                            tokenId: addTokenId,
                            image: 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/meow-nft/gif/meow.gif'
                        },
                    },
                })
                notification.success({
                    message: 'Added NFT successfull'
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsAddingNft(false)
        }
    }

    const [isCallingApiMintUserStatus, setIsCallingApiMintUserStatus] = useState<boolean>(false)
    const reloadMintUserStatus = async () => {
        if (isCallingApiMintUserStatus) return;
        if (account)
            try {
                setIsCallingApiMintUserStatus(true);
                const response = await axios.get(`${BASE_URL}/meow-mint/status?address=${account.toString()}`);
                const mintUserStatusRes = response.data
                if (response.data.hasEnteredMintRefCode) {
                    mintUserStatusRes.hasEnteredMintRefCode = true
                    setHasCompletedEnterCode(true);
                    setEnterCodePopupStep(2)
                }
                if (response.data.hasJoinedTw) {
                    setCurrentSocialStep(1);
                    setHasClickJoinTw(response.data.hasFollowedTw);
                    setHasDoneFollowTw(true);
                    mintUserStatusRes.hasFollowedTw = true
                }
                if (response.data.hasJoinedDiscord) {
                    setCurrentSocialStep(3);
                    setHasDoneJoinDiscord(true);
                    mintUserStatusRes.hasJoinedDiscord = true
                }
                if (response.data.hasRetweetTw) {
                    setHasDoneRt(true);
                    mintUserStatusRes.hasRetweetTw = true
                }

                let hasDoneBrideCheck
                let isDoneBridgeCheck = false
                try {
                    hasDoneBrideCheck = JSON.parse(window.localStorage.getItem('hasDoneBridge') ?? '{}')
                    if (hasDoneBrideCheck[account] == 1) {
                        mintUserStatusRes.hasDoneBridge = true
                        setHasDoneBridge(true)
                        isDoneBridgeCheck = true
                    }
                }
                catch (err) { }
                if (response.data.hasEnteredMintRefCode && response.data.hasFollowedTw && response.data.hasJoinedDiscord && response.data.hasRetweetTw && isDoneBridgeCheck) {
                    setEnterCodePopupStep(3)
                }
                setMintUserStatus(mintUserStatusRes);

            } catch (err) {
            } finally {
                setIsCallingApiMintUserStatus(false);
            }
    }
    useEffect(() => {
        if (pathParams.id && hasLoadedPage) {
            try {
                const refCode = pathParams?.id?.split('=')[1].split('');
                if (refCode.length < 6) return;

                if (!mintUserStatus.hasEnteredMintRefCode && mintUserStatus.mintRefCode != refCode.join('')) {
                    setIsShowEnterCodePopup(true)
                    setDisableConfirmInputCodeBtn(false)
                    setEnterCodePopupStep(1)
                }
            } catch (err) {
            }
        }
    }, [mintUserStatus])

    const [totalHolder, setTotalHolder] = useState<number>(0);
    const [totalMintedNft, setTotalMintedNft] = useState<number>(0);
    const [totalYourShare, setTotalYourShare] = useState<number>(0);
    const [totalMyMintedNft, setTotalMyMintedNft] = useState<number>(0);
    const [myNftCnt, setMyNftCnt] = useState<number>(0);

    const [isLoadingUserInfo, setIsLoadingUserInfo] = useState<boolean>(false)
    const [isLoadingUserMintedInfo, setIsLoadingUserMintedInfo] = useState<boolean>(false)

    const loadUserMintedInfo = async () => {
        if (!account || isLoadingUserMintedInfo) return
        try {
            try {
                setIsLoadingUserMintedInfo(true)
                setTotalMyMintedNft((await monopassMinterNftContract?.mintedCount(account)).toNumber());
            }
            catch (err1) {
                setTotalMyMintedNft((await monopassMinterNftContract?.mintedCount(account)).toNumber());
            }
            try {
                setMyNftCnt((await monopassNftContract?.balanceOf(account)).toNumber());
            }
            catch (err1) {
                setMyNftCnt((await monopassNftContract?.balanceOf(account)).toNumber());
            }
        }
        catch (err) {
        }
        setIsLoadingUserMintedInfo(false)
    }
    const loadStats = async () => {
        if (isLoadingUserInfo) return
        try {
            setIsLoadingUserInfo(true)
            setTotalHolder((await monopassNftContract?.getHoldersLength()).toNumber());
            setTotalMintedNft((await monopassNftContract?.totalSupply()).toNumber());
        }
        catch (err) {
        }
        finally {
            setIsLoadingUserInfo(false)
        }
    }

    useEffect(() => {
        if (myNftCnt == 0 && totalMintedNft == 0) return;
        setTotalYourShare(((myNftCnt / (totalMintedNft)) * 100))
    }, [totalMintedNft, myNftCnt])

    const initPage = async () => {
        loadUserMintedInfo()
        loadStats();
        await reloadMintUserStatus();
        setHasLoadedPage(true);
    }

    const [hasLoadedPage, setHasLoadedPage] = useState<boolean>(false);
    useEffect(() => {
        setIsShowEnterCodePopup(false)
        setHasCompletedEnterCode(false);
        setHasDoneFollowTw(false);
        setHasClickJoinTw(false)
        setHasDoneJoinDiscord(false);
        setHasDoneRt(false);
        setHasDoneBridge(false)
        setEnterCodePopupStep(1);
        setMyNftCnt(0)
        setTotalMyMintedNft(0)
        initPage();
        setMintUserStatus({
            hasEnteredMintRefCode: false,
            hasFollowedTw: false,
            hasFollowedTw1: false,
            hasFollowedTw2: false,
            hasJoinedDiscord: false,
            hasRetweetTw: false,
            hasRetweetTw1: false,
            hasRetweetTw2: false,
            hasRetweetTw3: false,
            hasDoneBridge: false,
            totalInvited: 0,
            mintRefCode: ''
        })

        if (window.localStorage.getItem('hasFollowedTw1'))
            setHasDoneFollowTw1(true)
        if (window.localStorage.getItem('hasFollowedTw2'))
            setHasDoneFollowTw2(true)
        if (window.localStorage.getItem('hasDoneRt1'))
            setHasDoneRt1(true)
        if (window.localStorage.getItem('hasDoneRt2'))
            setHasDoneRt2(true)
        if (window.localStorage.getItem('hasDoneRt3'))
            setHasDoneRt3(true)
    }, [account, chainId]);

    const [pageUrl, setPageUrl] = useState<string>()
    useEffect(() => {
        setPageUrl(`/meow-mint`)
    }, [])

    const mintingSlotLeft = useMemo(() => {
        return (mintUserStatus.totalInvited + (mintUserStatus.hasEnteredMintRefCode ? 1 : 0)) - totalMyMintedNft
    }, [mintUserStatus, totalMyMintedNft])

    const mintedPercent = useMemo(() => {
        return totalMintedNft / 100000
    }, [totalMintedNft])

    const isValidChainId = chainId == DEFAULT_CHAINID
    const [isSwitchingChainId, setIsSwitchingChainId] = useState<boolean>(false)

    return <MonoPassWrapper>
        {
            isShowEnterCodePopup &&
            <EnterCodePopup>
                <EnterCodePopupBody style={{
                    maxWidth: enterCodePopupStep == 2 ? '720px' : '592px'
                }}>

                    <CloseEnterCodePopupIcon onClick={() => { setIsShowEnterCodePopup(false) }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                            stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </CloseEnterCodePopupIcon>

                    {
                        enterCodePopupStep == 1 ?
                            //Start
                            <EnterCodePopupStep1>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <div style={{ maxWidth: "392px" }}>
                                        <EnterCodePopupTitle>
                                            Enter invitation code to mint Meow Chronicles
                                        </EnterCodePopupTitle>
                                    </div>
                                </div>

                                <EnterCodePopupInputList>
                                    {
                                        inputCodeList.map((_, index) => <EnterCodePopupInputBox key={index}>
                                            <EnterCodePopupInput className="inputCode"
                                                style={{
                                                    color: enterCodePopupStep1ErrMss ? 'red' : '#4B2828',
                                                    fontFamily: 'Outfit'
                                                }}
                                                isValidInputCode={isValidInputCode} maxLength={1}
                                                onKeyUp={(event: any) => onChangeInputCode(event, index)} />
                                        </EnterCodePopupInputBox>
                                        )
                                    }
                                </EnterCodePopupInputList>

                                <EnterCodePopupMessage>{enterCodePopupStep1ErrMss}</EnterCodePopupMessage>

                                <EnterCodePopupButtonBox>
                                    {
                                        account ?
                                            (isValidChainId ?
                                                <NotchedButtonFill disabled={disableConfirmInputCodeBtn}
                                                    onClick={onConfirmInputCode}>
                                                    {isCheckingMintNftRefCode ? 'LOADING...' : 'CONFIRM'}
                                                </NotchedButtonFill>
                                                : <NotchedButtonFill
                                                    style={{ textTransform: 'uppercase', width: "100%" }}
                                                    bg={'#00E440'}
                                                    onClick={switchChain}>
                                                    <ButtonBoxNotchedButtonFillTextStyle>
                                                        {isSwitchingChainId ? 'SWITCHING...' : 'SWICH TO ZIRCUIT CHAIN'}
                                                    </ButtonBoxNotchedButtonFillTextStyle>
                                                </NotchedButtonFill>)
                                            : <NotchedButtonFill
                                                onClick={toggleWalletModal}>
                                                CONNECT WALLET
                                            </NotchedButtonFill>
                                    }
                                </EnterCodePopupButtonBox>
                                <div style={{ display: 'grid', textAlign: 'center', marginTop: '24px' }}>
                                    <EnterCodePopupNote
                                        style={{ opacity: 0.5, display: 'inline-block', marginBottom: '5px' }}>
                                        Don't have a code or your code has been expired?
                                    </EnterCodePopupNote>
                                    <EnterCodePopupNote>
                                        <EnterCodePopupNote style={{ opacity: 0.5 }}>Follows us on</EnterCodePopupNote>
                                        <a href='https://x.com/intent/follow?screen_name=gmeowfi'
                                            target='_blank' style={{
                                                textDecoration: 'underline',
                                                opacity: 1,
                                                margin: '0 5px',
                                                fontFamily: 'Outfit',
                                                color: '#4B2828'
                                            }}>X</a>
                                        <EnterCodePopupNote style={{ opacity: 0.5 }}>and join our</EnterCodePopupNote>
                                        <a href='https://discord.com/invite/QcTmqgaUK6' target='_blank' style={{
                                            textDecoration: 'underline',
                                            opacity: 1,
                                            margin: '0 5px',
                                            fontFamily: 'Outfit',
                                            color: '#4B2828'
                                        }}>Discord</a>.
                                    </EnterCodePopupNote>
                                </div>
                            </EnterCodePopupStep1>
                            //End
                            :
                            enterCodePopupStep == 2
                                ?
                                <EnterCodePopupStep2>
                                    <EnterCodePopupStep2Task>
                                        <EnterCodePopupTitle style={{ maxWidth: '473px', margin: 'auto' }}>
                                            Complete these tasks
                                            to get your Meow
                                        </EnterCodePopupTitle>

                                        <SocialActionItemBox>
                                            <SocialActionItemWrapper>
                                                {
                                                    hasClickJoinTw ?
                                                        <SocialActionItemDone label="1/3" text="Follow Gmeow on X" imgLink="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_4.png" />
                                                        :
                                                        <>
                                                            {
                                                                hasDoneFollowTw ?
                                                                    <SocialActionItemUnDone
                                                                        isLoading={isClickingFollowX}
                                                                        opacity={currentSocialStep >= 1 ? 1 : 0.5}
                                                                        imgLink="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_4.png"
                                                                        text="Follow Gmeow on X" btnText="Follow Now" onClick={async () => {
                                                                            setIsClickingFollowX(true)
                                                                            await axios.post(`${BASE_URL}/airdrop/claim-task`, {
                                                                                "address": account,
                                                                                "taskId": 8
                                                                            })
                                                                            window.open('https://x.com/intent/follow?screen_name=gmeowfi', '_blank');
                                                                            mintUserStatus.hasFollowedTw = true
                                                                            setTimeout(() => {
                                                                                setHasClickJoinTw(true);
                                                                                setIsClickingFollowX(false)
                                                                            }, 8000);
                                                                        }} />
                                                                    :
                                                                    <SocialActionItemUnDone
                                                                        opacity={currentSocialStep >= 1 ? 1 : 0.5}
                                                                        imgLink="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_4.png"
                                                                        text="Follow Gmeow on X" btnText="Join Now"
                                                                        onClick={() => {
                                                                            window.location.href = `${BASE_URL}/auth/social-login/tw?user_address=${account}&redirect_to=${pageUrl}`
                                                                            setTimeout(() => {
                                                                                setHasDoneFollowTw(true);
                                                                                setCurrentSocialStep(2);
                                                                            }, 4000);
                                                                        }} />

                                                            }
                                                            {
                                                                connectXErrMss &&
                                                                <EnterCodePopupMessage
                                                                    style={{ padding: "5px 0" }}>{connectXErrMss}</EnterCodePopupMessage>
                                                            }
                                                        </>}
                                            </SocialActionItemWrapper>

                                            <SocialActionItemWrapper>
                                                {
                                                    hasDoneFollowTw1 ?
                                                        <SocialActionItemDone label="1/3" text="Follow Zircuit Daily on X" imgLink="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_4.png" />
                                                        :
                                                        <>
                                                            {
                                                                <SocialActionItemUnDone
                                                                    isLoading={isClickingFollowX1}
                                                                    opacity={currentSocialStep >= 1 ? 1 : 0.5}
                                                                    imgLink="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_4.png"
                                                                    text="Follow Zircuit Daily on X" btnText="Follow Now" onClick={() => {
                                                                        setIsClickingFollowX1(true)
                                                                        window.localStorage.setItem('hasFollowedTw1', '1')
                                                                        window.open('https://x.com/intent/follow?screen_name=ZircuitDaily', '_blank');
                                                                        mintUserStatus.hasFollowedTw1 = true
                                                                        // axios.post(`${BASE_URL}/airdrop/claim-task`, {
                                                                        //     "address": account,
                                                                        //     "taskId": 11
                                                                        // })
                                                                        setTimeout(() => {
                                                                            setHasClickJoinTw1(true);
                                                                            setIsClickingFollowX1(false)
                                                                            setHasDoneFollowTw1(true)
                                                                        }, 4000);
                                                                    }} />
                                                            }
                                                            {
                                                                connectXErrMss &&
                                                                <EnterCodePopupMessage
                                                                    style={{ padding: "5px 0" }}>{connectXErrMss}</EnterCodePopupMessage>
                                                            }
                                                        </>}
                                            </SocialActionItemWrapper>

                                            <SocialActionItemWrapper>
                                                {
                                                    hasDoneFollowTw2 ?
                                                        <SocialActionItemDone label="1/3" text="Follow our launch partner on X" imgLink="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_4.png" />
                                                        :
                                                        <>
                                                            {
                                                                <SocialActionItemUnDone
                                                                    isLoading={isClickingFollowX2}
                                                                    opacity={currentSocialStep >= 1 ? 1 : 0.5}
                                                                    imgLink="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_4.png"
                                                                    text="Follow our launch partner on X" btnText="Follow Now" onClick={() => {
                                                                        setIsClickingFollowX2(true)
                                                                        window.localStorage.setItem('hasFollowedTw2', '1')
                                                                        window.open('https://x.com/intent/follow?screen_name=zircatcoin', '_blank');
                                                                        mintUserStatus.hasFollowedTw2 = true
                                                                        // axios.post(`${BASE_URL}/airdrop/claim-task`, {
                                                                        //     "address": account,
                                                                        //     "taskId": 11
                                                                        // })
                                                                        setTimeout(() => {
                                                                            setHasClickJoinTw2(true);
                                                                            setIsClickingFollowX2(false)
                                                                            setHasDoneFollowTw2(true)
                                                                        }, 4000);
                                                                    }} />
                                                            }
                                                            {
                                                                connectXErrMss &&
                                                                <EnterCodePopupMessage
                                                                    style={{ padding: "5px 0" }}>{connectXErrMss}</EnterCodePopupMessage>
                                                            }
                                                        </>}
                                            </SocialActionItemWrapper>

                                            <SocialActionItemWrapper>
                                                {
                                                    hasDoneJoinDiscord ?
                                                        <SocialActionItemDone label="2/3" text="JOIN DISCORD" imgLink="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_5.png" /> :
                                                        <>
                                                            <SocialActionItemUnDone
                                                                isLoading={isClickingJoinDiscord}
                                                                opacity={currentSocialStep >= 2 ? 1 : 1}
                                                                imgLink="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_5.png"
                                                                text="Join Gmeow Discord"
                                                                btnText="Join now" onClick={() => {
                                                                    {
                                                                        setIsClickingJoinDiscord(true)
                                                                        window.open('https://discord.com/invite/QcTmqgaUK6', '_blank');

                                                                        setTimeout(() => {
                                                                            window.location.replace(`${BASE_URL}/auth/social-login/discord?user_address=${account}&redirect_to=${pageUrl}`);
                                                                            // setTimeout(() => {
                                                                            //     setHasDoneJoinDiscord(true);
                                                                            //     setCurrentSocialStep(3);
                                                                            // }, 5000);
                                                                        }, 8000)
                                                                    }
                                                                }} />
                                                            {
                                                                connectDiscordErrMss &&
                                                                <EnterCodePopupMessage
                                                                    style={{ padding: "5px 0" }}>{connectDiscordErrMss}</EnterCodePopupMessage>
                                                            }
                                                        </>
                                                }
                                            </SocialActionItemWrapper>

                                            <SocialActionItemWrapper>
                                                {
                                                    hasDoneRt ?
                                                        <SocialActionItemDone label="3/3" text="Share the word on X" imgLink="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_4.png" /> :
                                                        <>
                                                            <SocialActionItemUnDone
                                                                isLoading={isClickingRT}
                                                                opacity={currentSocialStep >= 3 ? 1 : 1}
                                                                imgLink="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_4.png"
                                                                text="Share the word on X"
                                                                btnText="TWEET NOW" onClick={async () => {
                                                                    setIsClickingRT(true)
                                                                    await axios.post(`${BASE_URL}/airdrop/claim-task`, {
                                                                        "address": account,
                                                                        "taskId": 7
                                                                    })
                                                                    const twitterRetweetUrl = 'https://x.com/intent/retweet?tweet_id=1814059560451387790';
                                                                    window.open(twitterRetweetUrl, '_blank')
                                                                    mintUserStatus.hasRetweetTw = true;
                                                                    setTimeout(() => {
                                                                        setHasDoneRt(true);
                                                                        setCurrentSocialStep(4);
                                                                        setIsClickingRT(false)
                                                                    }, 8000);
                                                                }} />
                                                            {
                                                                connectDiscordErrMss &&
                                                                <EnterCodePopupMessage
                                                                    style={{ padding: "5px 0" }}>{connectDiscordErrMss}</EnterCodePopupMessage>
                                                            }
                                                        </>
                                                }
                                            </SocialActionItemWrapper>

                                            <SocialActionItemWrapper>
                                                {
                                                    hasDoneRt1 ?
                                                        <SocialActionItemDone label="3/3" text="Retweet post on X(1)" imgLink="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_4.png" /> :
                                                        <>
                                                            <SocialActionItemUnDone
                                                                isLoading={isClickingRT1}
                                                                opacity={currentSocialStep >= 3 ? 1 : 1}
                                                                imgLink="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_4.png"
                                                                text="Retweet post on X(1)"
                                                                btnText="TWEET NOW" onClick={() => {
                                                                    setIsClickingRT1(true)
                                                                    localStorage.setItem('hasDoneRt1', '1')
                                                                    const twitterRetweetUrl = 'https://x.com/intent/retweet?tweet_id=1811520599992467637';
                                                                    window.open(twitterRetweetUrl, '_blank')
                                                                    mintUserStatus.hasRetweetTw1 = true;
                                                                    setTimeout(() => {
                                                                        setHasDoneRt1(true);
                                                                        setCurrentSocialStep(4);
                                                                        setIsClickingRT1(false)
                                                                    }, 5000);
                                                                }} />
                                                            {
                                                                connectDiscordErrMss &&
                                                                <EnterCodePopupMessage
                                                                    style={{ padding: "5px 0" }}>{connectDiscordErrMss}</EnterCodePopupMessage>
                                                            }
                                                        </>
                                                }
                                            </SocialActionItemWrapper>

                                            <SocialActionItemWrapper>
                                                {
                                                    hasDoneRt2 ?
                                                        <SocialActionItemDone label="3/3" text="Retweet our launch partner" imgLink="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_4.png" /> :
                                                        <>
                                                            <SocialActionItemUnDone
                                                                isLoading={isClickingRT2}
                                                                opacity={currentSocialStep >= 3 ? 1 : 1}
                                                                imgLink="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_4.png"
                                                                text="Retweet our launch partner"
                                                                btnText="TWEET NOW" onClick={() => {
                                                                    setIsClickingRT2(true)
                                                                    localStorage.setItem('hasDoneRt2', '1')
                                                                    const twitterRetweetUrl = 'https://x.com/intent/retweet?tweet_id=1812267638762197283';
                                                                    window.open(twitterRetweetUrl, '_blank')
                                                                    mintUserStatus.hasRetweetTw2 = true;

                                                                    setTimeout(() => {
                                                                        setHasDoneRt2(true);
                                                                        setCurrentSocialStep(4);
                                                                        setIsClickingRT2(false)
                                                                    }, 5000);
                                                                }} />
                                                            {
                                                                connectDiscordErrMss &&
                                                                <EnterCodePopupMessage
                                                                    style={{ padding: "5px 0" }}>{connectDiscordErrMss}</EnterCodePopupMessage>
                                                            }
                                                        </>
                                                }
                                            </SocialActionItemWrapper>

                                            <SocialActionItemWrapper>
                                                {
                                                    hasDoneBridge ?
                                                        <SocialActionItemDone label="3/3" text="Deposit to Zircuit & earn points" imgLink="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/bridge.png" /> :
                                                        <>
                                                            <SocialActionItemUnDone
                                                                isLoading={isClickingDeposit}
                                                                opacity={currentSocialStep >= 3 ? 1 : 1}
                                                                imgLink="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/bridge.png"
                                                                text="Deposit to Zircuit & earn points"
                                                                btnText="DEPOSIT NOW" onClick={() => {
                                                                    setIsClickingDeposit(true)

                                                                    if (account) {
                                                                        if (window.localStorage.getItem('hasDoneBridge')) {
                                                                            try {
                                                                                const bridgeLocalCheck = JSON.parse(window.localStorage.getItem('hasDoneBridge') ?? '{}')
                                                                                bridgeLocalCheck[account] = 1
                                                                                window.localStorage.setItem('hasDoneBridge', JSON.stringify(bridgeLocalCheck))
                                                                            }
                                                                            catch (err) { }
                                                                        }
                                                                        else
                                                                            window.localStorage.setItem('hasDoneBridge', JSON.stringify({
                                                                                [account]: 1
                                                                            }))
                                                                    }
                                                                    const brigeUrl = 'https://stake.zircuit.com/?ref=9wn6me';
                                                                    window.open(brigeUrl, '_blank')
                                                                    mintUserStatus.hasDoneBridge = true;
                                                                    setTimeout(() => {
                                                                        setHasDoneBridge(true);
                                                                        setCurrentSocialStep(4);
                                                                        setIsClickingDeposit(false)
                                                                    }, 5000);
                                                                }} />
                                                            {
                                                                connectDiscordErrMss &&
                                                                <EnterCodePopupMessage
                                                                    style={{ padding: "5px 0" }}>{connectDiscordErrMss}</EnterCodePopupMessage>
                                                            }
                                                        </>
                                                }
                                            </SocialActionItemWrapper>

                                            <SocialActionItemWrapper>
                                                {
                                                    hasDoneRt3 ?
                                                        <SocialActionItemDone label="3/3" text="Retweet post on X(2)" imgLink="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_4.png" /> :
                                                        <>
                                                            <SocialActionItemUnDone
                                                                isLoading={isClickingRT3}
                                                                opacity={currentSocialStep >= 3 ? 1 : 1}
                                                                imgLink="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_4.png"
                                                                text="Retweet post on X(2)"
                                                                btnText="TWEET NOW" onClick={() => {
                                                                    setIsClickingRT3(true)
                                                                    localStorage.setItem('hasDoneRt3', '1')
                                                                    const twitterRetweetUrl = 'https://x.com/intent/retweet?tweet_id=1814747189652169005';
                                                                    window.open(twitterRetweetUrl, '_blank')
                                                                    mintUserStatus.hasRetweetTw3 = true;

                                                                    setTimeout(() => {
                                                                        setHasDoneRt3(true);
                                                                        setCurrentSocialStep(4);
                                                                        setIsClickingRT3(false)
                                                                    }, 5000);
                                                                }} />
                                                            {
                                                                connectDiscordErrMss &&
                                                                <EnterCodePopupMessage
                                                                    style={{ padding: "5px 0" }}>{connectDiscordErrMss}</EnterCodePopupMessage>
                                                            }
                                                        </>
                                                }
                                            </SocialActionItemWrapper>
                                        </SocialActionItemBox>
                                        {
                                            enterCodePopupStep2ErrMss && <EnterCodePopupMessage
                                                style={{ padding: "5px 0" }}>{enterCodePopupStep2ErrMss}</EnterCodePopupMessage>
                                        }

                                        <div style={{ marginTop: '40px', display: 'grid' }}>
                                            {
                                                isValidChainId ?
                                                    <NotchedButtonFill
                                                        bg="#00E440"
                                                        disabled={!hasClickJoinTw || !hasDoneFollowTw1 || !hasDoneFollowTw2 || !hasDoneJoinDiscord || !hasDoneRt || !hasDoneRt1 || !hasDoneBridge}
                                                        style={{ textTransform: 'uppercase' }} onClick={handleMintNft}
                                                        textColor="#FFF9E1"
                                                    >
                                                        <SocialActionItemText style={{ color: '#FFF9E1 !important' }}>
                                                            MINT
                                                        </SocialActionItemText>
                                                    </NotchedButtonFill>
                                                    :
                                                    <NotchedButtonFill
                                                        style={{ textTransform: 'uppercase', width: "100%" }}
                                                        bg={'#00E440'}
                                                        onClick={switchChain}>
                                                        <ButtonBoxNotchedButtonFillTextStyle>
                                                            {isSwitchingChainId ? 'SWITCHING...' : 'SWICH TO ZIRCUIT CHAIN'}
                                                        </ButtonBoxNotchedButtonFillTextStyle>
                                                    </NotchedButtonFill>
                                            }

                                        </div>
                                    </EnterCodePopupStep2Task>

                                </EnterCodePopupStep2>
                                :
                                <EnterCodePopupStep2>
                                    {
                                        mintTxStatus == 'pending' && <>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <CustomLightSpinner src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/swap/swap-spinner.svg" alt="loader" size={'120px'} />
                                            </div>
                                            <EnterCodePopupTitle style={{
                                                maxWidth: '473px',
                                                margin: 'auto',
                                                marginBottom: '0',
                                                marginTop: '32px'
                                            }}>
                                                Minting
                                            </EnterCodePopupTitle>

                                            <EnterCodePopupNote style={{
                                                fontWeight: 500,
                                                display: 'block',
                                                textAlign: 'center',
                                                marginTop: '0px',
                                                marginBottom: 0,
                                                color: '#4B2828'
                                            }}>
                                                Confirm tx from your wallet
                                            </EnterCodePopupNote>
                                        </>
                                    }
                                    {
                                        mintTxStatus == 'failed' &&
                                        <>
                                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                                                <img style={{ width: '100px', height: '100px' }}
                                                    src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/transaction-status/failed.png" />
                                            </div>
                                            <EnterCodePopupTitle
                                                style={{ maxWidth: '473px', margin: 'auto', color: '#FF1E56' }}>
                                                Failed transaction
                                            </EnterCodePopupTitle>
                                            <EnterCodePopupNote style={{
                                                fontWeight: 500,
                                                display: 'block',
                                                textAlign: 'center',
                                                marginTop: '6px',
                                                marginBottom: 0,
                                                color: '#4B2828'
                                            }}>
                                                Please try again
                                            </EnterCodePopupNote>
                                            {
                                                mintTxLink && <div style={{
                                                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                                                    marginBottom: '5px', marginTop: '6px', cursor: 'pointer'
                                                }}
                                                    onClick={() => mintTxLink && window.open(mintTxLink, '_blank')}
                                                >
                                                    <span
                                                        style={{ borderBottom: '1px solid #EEAA01', fontSize: '16px', color: '#EEAA01' }}>View
                                                        Tx</span>
                                                </div>
                                            }
                                        </>
                                    }

                                    {
                                        mintTxStatus == 'done' &&
                                        <>
                                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                                                <img style={{ width: '100px', height: '100px' }}
                                                    src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/transaction-status/success.png" />
                                            </div>
                                            <EnterCodePopupTitle
                                                style={{ width: '351px', margin: 'auto', color: '#00E440' }}>
                                                Successfully minted
                                            </EnterCodePopupTitle>
                                            <EnterCodePopupNote style={{
                                                fontWeight: 500,
                                                display: 'block',
                                                textAlign: 'center',
                                                marginTop: '6px',
                                                marginBottom: 0,
                                                color: '#4B2828'
                                            }}>
                                                You’ve successfully minted a Meow Chronicles
                                            </EnterCodePopupNote>

                                            {
                                                mintTxLink && <div style={{
                                                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                                                    marginBottom: '5px', marginTop: '6px', cursor: 'pointer'
                                                }}
                                                    onClick={() => mintTxLink && window.open(mintTxLink, '_blank')}
                                                >
                                                    <span
                                                        style={{ borderBottom: '1px solid #EEAA01', fontSize: '16px', color: '#EEAA01' }}>View
                                                        Tx</span>
                                                </div>
                                            }
                                            <p style={{ color: '#00E440', fontSize: '16px', textAlign: 'center', cursor: 'pointer' }} onClick={handleAddMonopassNftToken}>
                                                Add to Metamask {isAddingNft && <LoadingOutlined rev={undefined} />}
                                            </p>

                                        </>
                                    }

                                </EnterCodePopupStep2>
                    }
                </EnterCodePopupBody>
            </EnterCodePopup>
        }

        <MonoPassBody>
            <SectionWrapper>
                <Section1ContentWrapper>
                    <ContentLeft>
                        <ContentTopWrapper>
                            <TitleAndDescWrapper>
                                <TextStyle className="page-title" fontSize={'36px'} lineHeight={'125%'}>
                                    MEOW CHRONICLES
                                </TextStyle>
                                <div className={'percent-wrapper'}>
                                    <MintedMeowPercentBox >
                                        {
                                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
                                                return <img key={i} src={`https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/meow-nft/${(mintedPercent * 10) >= i ? 'filled' : 'unfill'}-meow-percent.png`} />
                                            })
                                        }
                                        <TextStyle color={'#05C63B'} fontSizeMb={'14px'} fontWeight={'600'}>
                                            {totalHolder == 0 ? <LoadingOutlined rev={undefined} /> : (mintedPercent * 100).toFixed(3)}% minted
                                        </TextStyle>
                                    </MintedMeowPercentBox>
                                </div>
                                <TextStyle color={'#93553A'} fontSizeMb={'14px'} fontWeight={'400'} lineHeight={'125%'}>
                                    Hey there, kitty fans!! Have you heard about the ultra-adorable Good Morning Meow
                                    NFT?
                                    This little bundle of joy is the purrfect addition to your collection with its
                                    pawsitively charming and quirky design.
                                </TextStyle>
                            </TitleAndDescWrapper>

                            <StatisticWrapper>
                                <div className={'statistic-content'}>
                                    <TextStyle color={'#D7903C'} fontSize={'14px'} fontSizeMb={'12px'}
                                        fontWeight={'400'}>
                                        No. of Owner
                                    </TextStyle>
                                    <TextStyle fontSize={'21px'} fontSizeMb={'14px'} fontWeight={'600'}>
                                        {
                                            totalHolder == 0 ? <LoadingOutlined rev={undefined} /> : totalHolder.toLocaleString('en-US')
                                        }
                                    </TextStyle>
                                </div>
                                <StatisticLine></StatisticLine>
                                <div className={'statistic-content'}>
                                    <TextStyle color={'#D7903C'} fontSize={'14px'} fontSizeMb={'12px'}
                                        fontWeight={'400'}>
                                        Total Minted
                                    </TextStyle>
                                    <TextStyle fontSize={'21px'} fontSizeMb={'14px'} fontWeight={'600'}>
                                        {
                                            totalMintedNft == 0 ? <LoadingOutlined rev={undefined} /> : totalMintedNft.toLocaleString('en-US')
                                        }
                                    </TextStyle>
                                </div>
                                <StatisticLine></StatisticLine>
                                <div className={'statistic-content'}>
                                    <TextStyle color={'#D7903C'} fontSize={'14px'} fontSizeMb={'12px'}
                                        fontWeight={'400'}>
                                        Remaining Time
                                    </TextStyle>
                                    <TextStyle color={'#05C63B'} fontSize={'21px'} fontSizeMb={'14px'}
                                        fontWeight={'600'}>
                                        {days}d : {hours}h : {minutes}m : {seconds}s
                                    </TextStyle>
                                </div>
                            </StatisticWrapper>

                        </ContentTopWrapper>
                        {
                            account && <MyNftStats
                                totalInvited={mintUserStatus.totalInvited}
                                totalMinted={totalMyMintedNft}
                                refCode={mintUserStatus.mintRefCode}
                                mintingSlotLeft={mintingSlotLeft}
                                totalYourShare={totalYourShare}
                                isLoadingUserInfo={isLoadingUserInfo}
                            />
                        }


                        <ButtonBox style={{ marginTop: '30px' }}>
                            {
                                hasCompletedEnterCode && isValidChainId ? <NotchedButtonFill
                                    disabled={!hasLoadedPage || isMintingNft || mintingSlotLeft <= 0}
                                    style={{ textTransform: 'uppercase', width: "100%" }}
                                    height={'60px'}
                                    bg={'#00E440'}
                                    onClick={onClickButtonBox}>
                                    <ButtonBoxNotchedButtonFillTextStyle>
                                        {
                                            isMintingNft ? 'Minting...' : (!account ? 'CONNECT WALLET' : 'MINT')
                                        }
                                    </ButtonBoxNotchedButtonFillTextStyle>
                                </NotchedButtonFill>
                                    : (account ? <NotchedButtonFill
                                        style={{ textTransform: 'uppercase', width: "100%" }}
                                        height={'60px'}
                                        bg={'#00E440'}
                                        onClick={() => {
                                            if (!isValidChainId) switchChain()
                                            else onClickButtonBox()
                                        }}>
                                        <ButtonBoxNotchedButtonFillTextStyle>
                                            {isValidChainId ? 'Enter invitation code to mint' : isSwitchingChainId ? 'SWITCHING...' : 'SWICH TO ZIRCUIT CHAIN'}
                                        </ButtonBoxNotchedButtonFillTextStyle>
                                    </NotchedButtonFill>
                                        :
                                        <NotchedButtonFill
                                            style={{ textTransform: 'uppercase', width: "100%" }}
                                            height={'60px'}
                                            bg={'#00E440'}
                                            onClick={onClickButtonBox}>
                                            <ButtonBoxNotchedButtonFillTextStyle>
                                                CONNECT WALLET
                                            </ButtonBoxNotchedButtonFillTextStyle>
                                        </NotchedButtonFill>)
                            }
                        </ButtonBox>



                    </ContentLeft>

                    <ContentRight>
                        <div className={'img-wrapper'}>
                            <img loading="lazy"
                                src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/meow-nft/gif/meow.gif"
                                alt="monoswap-nft" />
                        </div>
                    </ContentRight>
                </Section1ContentWrapper>

                <MeowLeaderboard />

                <Section2ContentWrapper>
                    <img className={'bg-section-2'} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_2.png" alt="img_2" />
                    <ContentWrapper>
                        <div className={'title'}>
                            <p className={'title-top'}>what’s so special about</p>
                            <p className={'title-bot'}>meow chronicles?</p>
                        </div>
                        <div className={'desc-top'}>
                            <DescChildWrapper>
                                <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_3.png" alt="img_3" />
                                <div className={'desc-child-content-wrapper'}>
                                    <div className={'title-desc'}>
                                        <p className={'title-desc-top'}>PAW-tastic</p>
                                        <p className={'title-desc-bot'}>Rewards</p>
                                    </div>
                                    <div className={'content'}>
                                        <TextStyle textAlign={'center'} color={'#93553A'} fontSize={'18px'}
                                            fontSizeMb={'14px'}
                                            lineHeight={'125%'}>
                                            Receive PAW as a share of Gmeow Earning. A steady stream of passive income
                                            and deepen your involvement in the Gmeow ecosystem.
                                        </TextStyle>
                                    </div>
                                </div>
                            </DescChildWrapper>
                            <DescChildWrapper>
                                <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_3.png" alt="img_3" />
                                <div className={'desc-child-content-wrapper'}>
                                    <div className={'title-desc'}>
                                        <p className={'title-desc-top'}>Claw-some</p>
                                        <p className={'title-desc-bot'}>Chance</p>
                                    </div>
                                    <div className={'content'}>
                                        <TextStyle textAlign={'center'} color={'#93553A'} fontSize={'18px'}
                                            fontSizeMb={'14px'}
                                            lineHeight={'125%'}>
                                            Enhance your odds of winning big in the Gmeow Chest. Significantly
                                            increasing your chances of snagging substantial PAW rewards.
                                        </TextStyle>
                                    </div>
                                </div>
                            </DescChildWrapper>
                        </div>
                        <div className={'desc-bot'}>
                            <DescChildWrapper>
                                <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/testnet/img_3.png" alt="img_3" />
                                <div className={'desc-child-content-wrapper'}>
                                    <div className={'title-desc'}>
                                        <p className={'title-desc-top'} style={{ color: "#4B2828" }}>
                                            Shape the Meow
                                        </p>
                                        <p className={'title-desc-bot'} style={{ color: "#00E440" }}>Meow</p>
                                    </div>
                                    <div className={'content'}>
                                        <TextStyle textAlign={'center'} color={'#93553A'} fontSize={'18px'}
                                            fontSizeMb={'14px'}
                                            lineHeight={'125%'}>
                                            Have a say in the direction of the Gmeow protocol. Get extra voting power in
                                            governance decisions, allowing you to influence key aspects of Gmeow’s
                                            future.
                                        </TextStyle>
                                    </div>
                                </div>
                            </DescChildWrapper>
                        </div>
                    </ContentWrapper>
                </Section2ContentWrapper>
            </SectionWrapper>
        </MonoPassBody>

        {/*<button onClick={() => {*/}
        {/*    setButtonTestModel(!buttonTestModel)*/}
        {/*}} style={{color: "#000"}}>*/}
        {/*    Test model*/}
        {/*</button>*/}
    </MonoPassWrapper>
}

