import styled from "styled-components/macro";
import { TextStyle } from "./Components";
import "../assets/style.css"
import React, { useEffect, useMemo, useState } from "react";
import { Checkbox } from "../../../nft/components/layout/Checkbox";
import { NotchedButtonFill } from "../../../components/Button";
import { BREAKPOINTS } from "../../../theme";
import { ChestType } from "../sections/ChestPageSection1";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { formatNumber } from "utils/formatNumbers";
import { LoadingOutlined } from "@ant-design/icons"
import { useCustomeContract } from "hooks/useContract";
import { MONO_CHEST_ADDRESSES_MAP } from "constants/addresses";
import monochest_abi from '../../../abis/monochest/monochest.json'
import { notification } from "antd";
import { PopupGetMultipleBoxSuccess, RewardListType } from "./PopupGetMultipleBoxSuccess";
import { useBoxStore } from "../../../hooks/useBox";
import BigNumber from 'bignumber.js';
import { DEFAULT_CHAINID } from "../../../constants/chains";
import { getGasPriceSetting } from "utils/gasSetting";

const PopupBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.70);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    @media (max-width: ${BREAKPOINTS.md}px) {
        align-items: center;
    }
`

const PopupContent = styled.div`
    padding: 32px;
    border-radius: 10px;
    border: 1.5px solid #E5B670;
    background: #FFF6D0;
    box-shadow: 0 7.395px 30px 0 rgba(145, 103, 20, 0.30);
    position: relative;
    width: 573px;
    max-height: 95vh;
    overflow-y: auto;
    @media (max-width: ${BREAKPOINTS.md}px) {
        margin-top: 20px;
        width: 336px;
        padding: 20px;
    }
`

const BoxQuantity = styled.div`
    margin-top: 26px;
    border-radius: 10px;
    background: #FFF9E1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper-box {
        width: 160px;
        height: 160px;

        .box-style {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
        }
    }
`

const AmountWrapper = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const CountWrapper = styled.div`
    border-radius: 10px;
    border: 2px solid #E5B670;
    background: #FFF6D0;
    box-shadow: 4px 4px 4px 0px rgba(215, 144, 60, 0.80) inset, -4px -4px 4px 0px #FFF inset;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        cursor: pointer;
        width: 28px;
        height: 28px;
    }
`

const ChooseToPayWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-top: 32px;
    margin-bottom: 56px;

    .inner-wrapper {
        display: flex;
        flex-direction: row;
        gap: 9px;
        @media (max-width: ${BREAKPOINTS.md}px) {
            flex-direction: column;
        }
    }
`

const ChooseToPayItem = styled.div<{
    checked?: boolean;
}>`
    padding: 12px 12px 20px 20px;
    border-radius: 10px;
    background: #FFF9E1;
    box-shadow: ${({ checked }) => checked ? '0 0 4px 4px rgba(0, 228, 64, 0.40)' : ''};
    border: ${({ checked }) => checked ? '' : '2px solid #E5B670'};
    opacity: ${({ checked }) => checked ? '1' : '0.5'};
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    .title-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .number-wrapper {
        display: flex;
        align-items: center;
        gap: 6px;

        img {
            width: 24px;
            height: 24px;
        }
    }

    .balance-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .discount {
            display: flex;
            gap: 6px;

            img {
                width: 16px;
                height: 16px;
            }

        }
    }
`

const OpenBoxGifWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    img {
        width: 100%;
        height: 100%;
        max-width: 720px;
        max-height: 518px;
        border-radius: 10px;
        border: 1.5px solid #E5B670;
        background: url(<path-to-image>) lightgray 0px 0px / 100.175% 100.059% no-repeat;
        box-shadow: 0px 7.395px 30px 0px rgba(145, 103, 20, 0.30);
        @media (max-width: ${BREAKPOINTS.md}px) {
            width: 80%;
            height: unset;
        }
    }
`

export const PopupGetBox = ({ show, onClose, title, urlGif, urlOpenBoxGif, boxInfo }: {
    show: any,
    onClose: any,
    title: any,
    urlGif: any,
    urlOpenBoxGif: any
    boxInfo: ChestType
}) => {
    const { account, provider } = useWeb3React()
    if (!show) return null;

    const boxStore = useBoxStore()
    const [isLoadingBalance, setIsLoadingBalance] = useState<boolean>(false)
    const [ethBalance, setEthBalance] = useState<number>(0)
    const [amount, setAmount] = useState(1);

    function increase(): void {
        setAmount((prevAmount) => {
            if (prevAmount === 1) {
                return prevAmount + 4
            } else if (prevAmount === 5) {
                return prevAmount + 5
            } else if (prevAmount === 10) return (prevAmount = 20)
            else if (prevAmount === 20) return (prevAmount = 50)
            else if (prevAmount === 50) return (prevAmount = 100)
            else if (prevAmount === 100) return (prevAmount = 100)
            return prevAmount
        })
    }

    function decrease(): void {
        setAmount((prevAmount) => {
            if (prevAmount < 2) {
                return (prevAmount = 1)
            } else if (prevAmount === 5) {
                return prevAmount = 1
            } else if ((prevAmount === 10)) return prevAmount = 5
            else if ((prevAmount === 20)) return prevAmount = 10
            else if ((prevAmount === 50)) return prevAmount = 20
            else if ((prevAmount === 100)) return prevAmount = 50
            return prevAmount
        })
    }

    const [checkedUSD, setCheckedUSD] = React.useState(true);
    const [checkedPAW, setCheckedPAW] = React.useState(false);

    const [isOpenBox, setIsOpenBox] = useState(false);

    const handleCheckUSD = () => {
        // setCheckedUSD(!checkedUSD);
        // if (!checkedUSD) {
        //     setCheckedPAW(false);
        // }
    };

    const handleCheckPAW = () => {
        setCheckedPAW(!checkedPAW);
        if (!checkedPAW) {
            setCheckedUSD(false);
        }
    };

    const [openByToken, setOpenByToken] = useState<'ETH'>('ETH')
    const [isOpening, setIsOpening] = React.useState(false);
    const [rewardList, setRewardList] = useState<RewardListType[]>([])
    const [txHash, setTxHash] = useState<string>('')
    const [isBuySuccess, setIsBuySuccess] = useState(false)
    const chestContractWithSign = useCustomeContract(MONO_CHEST_ADDRESSES_MAP[DEFAULT_CHAINID], monochest_abi, true)

    const handleOpenBox = async (isNft?: boolean, nftAmount?: number, fee?: BigNumber, boxLevel?: number) => {
        if (isOpening) return
        let payToken = 0
        try {
            setIsOpening(true)
            setIsOpenBox(true);
            if (openByToken == "ETH") payToken = 0
            if (isNft) payToken = 1


            const nftBalance = boxInfo.nftBalance
            let amountToOpen = payToken != 0 ? nftAmount ?? nftBalance : amount
            // if (payToken == 1 && amountToOpen > 10) amountToOpen = 5

            let ethValue: number | BigNumber = (fee ?? boxInfo.openFee).multipliedBy(new BigNumber(amountToOpen))
            if (!isNft) ethValue = boxInfo.openFee.multipliedBy(amountToOpen).plus(boxInfo.usdbPrice.multipliedBy(amountToOpen))
            const boxType = boxLevel ?? (boxInfo.level - 1)

            const gasEstimate = await chestContractWithSign?.estimateGas.openBox(amountToOpen, boxType, payToken, false, {
                value: ethers.utils.parseEther(String(ethValue))
            });
            // const gasEstimate: any = undefined;
            const gasLimitCalc = Math.floor(gasEstimate ? gasEstimate.toNumber() * 2 : amountToOpen * 200000)
            const gasPrice = undefined
            const tx = await chestContractWithSign?.openBox(amountToOpen, boxType, payToken, false, {
                gasLimit: gasLimitCalc,
                gasPrice,
                value: ethers.utils.parseEther(ethValue.toString())
            })

            const txLog = await tx.wait()

            if (txLog?.status != '0') {
                loadUserBalance()
                setTxHash(tx.hash)
                const monochestABIInter = new ethers.utils.Interface(monochest_abi);
                const rewardListRes: RewardListType[] = []

                let totalOpennedAmount = 0
                let totalNft = 0
                let totalXgm = 0
                let totalUsd = 0
                const eventLogs = txLog.events
                for (let logIndex = 0; logIndex < eventLogs.length; logIndex++) {
                    const eventLog: any = eventLogs[logIndex];
                    try {
                        const parsedLog = monochestABIInter.parseLog(eventLog);
                        if (!parsedLog) continue;
                        if (parsedLog.name == 'BoxOpened') {
                            let opennedRw: RewardListType = {
                                totalPaw: 0,
                                totalXgm: 0,
                                totalTicket: 0,
                                totalNft: 0
                            }
                            const [_, amountTicket, amountXGM, amountETH, availableNFT] = parsedLog.args;
                            const xgm = Number(ethers.utils.formatUnits(amountXGM.toString(), 18))
                            opennedRw.totalXgm = xgm
                            totalXgm += xgm
                            const usd = Number(ethers.utils.formatUnits(amountETH.toString(), 18))
                            opennedRw.totalPaw = usd
                            totalUsd += usd

                            const ticketCnt = amountTicket.toNumber();
                            opennedRw.totalTicket = ticketCnt
                            opennedRw.totalNft = availableNFT ? 1 : 0
                            totalNft += opennedRw.totalNft
                            rewardListRes.push(opennedRw)
                            totalOpennedAmount++
                        }
                    }
                    catch (logErr) {
                        console.log('event log err: ', logErr);

                    }
                }
                useBoxStore.setState({
                    totalOpennedAmount: boxStore.totalOpennedAmount + totalOpennedAmount,
                    totalRewardEarned: {
                        totalXMono: boxStore.totalRewardEarned.totalXMono + totalXgm,
                        totalUSD: boxStore.totalRewardEarned.totalUSD + totalUsd,
                        totalMonoPass: boxStore.totalRewardEarned.totalMonoPass + totalNft
                    }
                })
                setRewardList(rewardListRes)
                setIsBuySuccess(true)
            }
            else throw "Tx failed"
        }
        catch (err) {
            console.log('err', err);

            if (err?.code == 'UNPREDICTABLE_GAS_LIMIT' || (err?.data?.message && String(err?.data?.message).indexOf('insufficient funds')))
                notification.error({
                    message: 'Insufficient funds for gas'
                })
            else notification.error({
                message: "Open box failed"
            })
            if (payToken == 1) onClose()
        }
        finally {
            setIsOpening(false)
            setIsOpenBox(false);
            if (payToken == 1) {
                useBoxStore.setState({
                    [`totalNftChest${(boxLevel ?? 0) - 1}`]: 0
                })
                // @ts-ignore
                window.loadTotalUserNftBalance()
            }
        }

    }

    const loadUserBalance = async () => {
        if (!account) return
        try {
            setIsLoadingBalance(true)
            const balance = await provider?.getBalance(account)
            setEthBalance(formatNumber(ethers.utils.formatEther(balance ?? '0'), 5))
        }
        catch (err) { }
        finally {
            setIsLoadingBalance(false)
        }
    }

    useEffect(() => {
        loadUserBalance()
    }, [account])

    useEffect(() => {
        if (boxInfo && show) {
            loadUserBalance();
        }
        else {
            setAmount(1);
        }
    }, [boxInfo])

    useEffect(() => {
        // @ts-ignore
        window.handleOpenBox = handleOpenBox
    }, [])
    const isInsufficientBalance = useMemo(() => {
        if (ethBalance == 0 || !boxInfo) return true
        return (boxInfo.usdbPrice.multipliedBy(amount).toNumber()) > ethBalance
    }, [ethBalance, amount])

    return (
        <PopupBackdrop>
            {!isOpenBox ?
                <PopupContent>
                    <img onClick={onClose} className="close-icon" src="/treat/img_14.png" alt="img_14" />
                    <TextStyle fontSize="24px"
                        fontFamily="DynaPuff"
                    >
                        {title}
                    </TextStyle>
                    <BoxQuantity>
                        <div className="wrapper-box">
                            <img className="box-style" src={urlGif} alt="box_1" />
                        </div>
                        <TextStyle
                            color="#05C63B"
                            fontSize="24px"
                            fontFamily="DynaPuff"
                        >
                            x{amount}
                        </TextStyle>
                    </BoxQuantity>
                    <AmountWrapper>
                        <TextStyle
                            fontSize="21px"
                        >
                            Amount
                        </TextStyle>
                        <CountWrapper>
                            <img onClick={() => {
                                decrease()
                            }} src="/chest/popupGetBox/img.png" alt="img" />
                            <TextStyle
                                fontSize="24px"
                                fontWeight="600"
                            >
                                {amount}
                            </TextStyle>
                            <img onClick={() => {
                                increase()
                            }} src="/chest/popupGetBox/img_1.png" alt="img" />
                        </CountWrapper>
                    </AmountWrapper>
                    <ChooseToPayWrapper>
                        <TextStyle
                            fontSize="21px"
                        >
                            Choose to pay in
                        </TextStyle>
                        <div className="inner-wrapper">
                            <ChooseToPayItem checked={checkedUSD}>
                                <div className="title-wrapper">
                                    <TextStyle fontSize="18px">
                                        ETH
                                    </TextStyle>
                                    <Checkbox onClick={handleCheckUSD} hovered={false} checked={checkedUSD}
                                        children={undefined} />
                                </div>
                                <div className="number-wrapper">
                                    <img src="/tokens/ethereum.png" alt="img_2" />
                                    <TextStyle fontSize="24px" fontWeight="600">
                                        {boxInfo.usdbPrice.multipliedBy(new BigNumber(amount)).toString()}
                                    </TextStyle>
                                </div>
                                <div className="balance-wrapper">
                                    <TextStyle color="#E5B670" fontSize="14px" lineHeight="125%">
                                        Balance <span style={{ color: "#4B2828", fontFamily: "Outfit" }}>
                                            {
                                                isLoadingBalance ? <LoadingOutlined rev={undefined} /> : ethBalance
                                            }
                                            {' '}ETH
                                        </span>
                                    </TextStyle>
                                </div>
                            </ChooseToPayItem>
                            {/* <ChooseToPayItem checked={checkedPAW}>
                                <div className="title-wrapper">
                                    <TextStyle fontSize="18px">
                                        PAW
                                    </TextStyle>
                                    <Checkbox onClick={handleCheckPAW} hovered={false} checked={checkedPAW}
                                              children={undefined}/>
                                </div>
                                <div className="number-wrapper">
                                    <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupGetBox/img_3.png" alt="img_3"/>
                                    <TextStyle fontSize="24px" fontWeight="600">
                                        500
                                    </TextStyle>
                                </div>
                                <div className="balance-wrapper">
                                    <TextStyle color="#E5B670" fontSize="14px" lineHeight="125%">
                                        Balance <span style={{color: "#4B2828", fontFamily: "Outfit"}}>3.025 USDe</span>
                                    </TextStyle>
                                    <div className="discount">
                                        <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupGetBox/img_4.png" alt="img_4"/>
                                        <TextStyle color="#E5B670" fontSize="14px" lineHeight="125%">
                                            5% Discount: <span
                                            style={{color: "#4B2828", fontFamily: "Outfit"}}> -0.3 PAW</span>
                                        </TextStyle>
                                    </div>
                                </div>
                            </ChooseToPayItem> */}
                        </div>
                    </ChooseToPayWrapper>
                    {
                        isInsufficientBalance ?
                            <NotchedButtonFill
                                disabled={true}
                                isShowDirectly={true}
                                padding="16px 0"
                                bg="#00E440"
                                earMaxW="170px">
                                <TextStyle color="#FFF9E1"
                                    fontFamily="DynaPuff"
                                    fontSize="18px"
                                    textTransform="uppercase">
                                    Insufficient Balance ETH
                                </TextStyle>
                            </NotchedButtonFill>
                            :
                            <NotchedButtonFill onClick={() => handleOpenBox()}
                                isShowDirectly={true}
                                padding="16px 0"
                                bg="#00E440"
                                earMaxW="170px">
                                <TextStyle color="#FFF9E1"
                                    fontFamily="DynaPuff"
                                    fontSize="18px"
                                    textTransform="uppercase">
                                    purchase with ETH
                                </TextStyle>
                            </NotchedButtonFill>
                    }

                </PopupContent>
                :
                <OpenBoxGifWrapper>
                    <img src={urlOpenBoxGif} alt="Box-1-Open" />
                </OpenBoxGifWrapper>
            }

            <PopupGetMultipleBoxSuccess
                show={isBuySuccess}
                onClose={onClose}
                txHash={txHash}
                rewardList={rewardList}
                title={`${amount} ${title.replace('get ', '')}${amount > 1 ? 'es' : ''} opened`}
            />
        </PopupBackdrop>
    );
};
