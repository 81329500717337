import { NotchedButtonFill } from "components/Button";
import Modal from "components/Modal";
import Slider from "components/Slider";
import { ethers } from "ethers";
import useEthPrice from "hooks/useEthPrice";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { formatNumber } from "utils/formatNumbers";



const ModalWrapper = styled.div`
    width: 100%;
    height: fit-content;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1.5px solid #E5B670;
    background: #FFF6D0;
    box-shadow: 0px 7.395px 30px 0px rgba(145, 103, 20, 0.30);
    position: relative;
    padding: 24px;
`


const TitleBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4{
        color: #4B2828;
        font-family: DynaPuff;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 24px */
        margin: 0;
    }
    margin-bottom: 32px;
`

const GasSetingSliderBox = styled.div`
    display: grid;
    gap: 12px;
    align-items: center;
    
    .info{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
    }
    span.content{
        color: #4B2828;
        font-family: Outfit;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 16px */
    }
`

const GasEstimateBox = styled.div`
    margin-top: 24px;
    display: grid;
    gap: 6px;
    .item_row{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .label{
        color: rgba(75, 40, 40, 0.50);
        font-family: Outfit;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 14px */   
    }
    .content{
        color: #4B2828;
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 18px */
    }
`

export default function GasInfoModal({ isOpen, setIsOpen, updateGasPrice }: any) {
    const [gweiAmount, setGweiAmount] = useState<number>(0)
    const ethPrice = useEthPrice()
    const zrcPrice = 0.16

    const parsedGwei = useMemo(() => gweiAmount * 100000, [gweiAmount])
    const gasPrice = useMemo(() => ethers.utils.formatUnits(parsedGwei, 'gwei'), [parsedGwei])

    const estimateAmountZrc = useMemo(() => {
        if (parsedGwei == 0) return 0
        return (Number(gasPrice) * ethPrice) / zrcPrice
    }, [gasPrice, ethPrice, parsedGwei])

    const saveGasSetting = () => {
        localStorage.setItem('gas_mining_setting', String(parsedGwei).toString())
        setIsOpen(false)
    }

    function initGweiSetting() {
        setGweiAmount(Number(localStorage.getItem('gas_mining_setting') ?? '0') / 100000)
    }

    useEffect(() => {
        if (isOpen)
            initGweiSetting()
    }, [isOpen])

    useEffect(() => {
        updateGasPrice(gasPrice)
    }, [gasPrice])
    return <>
        <Modal isOpen={isOpen} maxWidth={573} onDismiss={() => setIsOpen(false)}>
            <ModalWrapper>
                <TitleBox>
                    <h4>Gas Mining Setting</h4>
                    <img onClick={() => setIsOpen(false)} className="close-icon" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_14.png" alt="img_14" />
                </TitleBox>

                <GasSetingSliderBox>
                    <div className="info">
                        <span style={{ minWidth: '100px', color: '##4B2828 !important' }}>
                            Gas price
                        </span>
                        <span className="content" style={{ minWidth: '160px', textAlign: 'end' }}>{formatNumber(parsedGwei)} Gwei</span>
                    </div>
                    <Slider value={gweiAmount} onChange={(value) => setGweiAmount(value)} />

                </GasSetingSliderBox>

                <GasEstimateBox>
                    <div className="item_row">
                        <span className="label">Est gas</span>
                        <span className="content">
                            ~{gasPrice} ETH/Tx
                        </span>
                    </div>

                    <div className="item_row">
                        <span className="label">Est reward</span>
                        <span className="content">
                            ~{formatNumber(estimateAmountZrc, 4)} ZRC/Tx
                        </span>
                    </div>

                    <div className="item_row">
                        <span className="label">Rolls</span>
                        <span className="content">
                            ~{parsedGwei / 10000000} roll/Tx
                        </span>
                    </div>
                </GasEstimateBox>

                <div style={{ marginTop: '24px' }}>
                    <NotchedButtonFill isShowDirectly={true} onClick={saveGasSetting}>Save</NotchedButtonFill>
                </div>
            </ModalWrapper>
        </Modal>
    </>
}