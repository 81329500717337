import { TokenInfo } from "@uniswap/token-lists"
import { Position } from "@uniswap/v3-sdk"
import { BigNumber } from "ethers"
import { useCurrency, useToken } from "hooks/Tokens"
import { usePool } from "hooks/usePools"
import { useV3PositionFromTokenId } from "hooks/useV3Positions"
import { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { BREAKPOINTS } from "theme"
import { PositionDetails } from "types/position"
import { unwrappedToken } from "utils/unwrappedToken"
import { Currency, Token } from "@uniswap/sdk-core"
import { WrappedTokenInfo } from "state/lists/wrappedTokenInfo"
import { formatXpNumber } from "pages/Xp/sections/XpTitle"
import Badge, { BadgeVariant } from '../Badge'
import { AlertCircle } from "react-feather"
import { Trans } from "@lingui/macro"
import useCountdownTimer from "hooks/useCountdownTimer"
import { formatNumber } from "utils/formatNumbers"
import { usePositionTokenURI } from "hooks/usePositionTokenURI"
import { useAlphaStore } from "hooks/useAlphaStore"


export type AlphaPositionV3Info = {
    tokenId: any
    formattedTokenId: string
    fee: string
    liquidity: string
    tickLower: string
    tickUpper: string
    currency0?: any
    currency1?: any
    token0?: Token | any
    token1?: Token | any
    tokensOwed0: string
    tokensOwed1: string
    liquidityValue0: any
    liquidityValue1: any,
    outOfRange: boolean,
    rewardValue: number,
    countdownTime?: number
}

const AlphaPostionV3ItemWrapper = styled.div<{
    isSelected?: boolean
}>`
    width: 100%;
    border-radius: 10px;
    background: #FFF9E1;
    padding: 20px 24px;
    padding-left: 4px;
    transition: box-shadow 0.15s ease-in-out, opacity 0.15s ease-in-out;
    opacity: ${({ isSelected }) => isSelected ? 1 : 0.7};
    box-shadow: ${({ isSelected }) => isSelected ? '0px 0px 4px 4px rgba(0, 228, 64, 0.40)' : 'unset'};
    &:hover{
        box-shadow: 0px 0px 4px 4px rgba(0, 228, 64, 0.40);
        opacity: 1;
    }
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .nft_svg{
        width: fit-content;
    }
    .nft_svg svg{
        width: 149px;
        height: 201px;
    }
`

const PositionInfo = styled.div`
    display: flex;
    justify-content: space-between;
`
const PositionInfoLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`
const PositionRight = styled.div`   
    svg{
        width: 24px;
        height: 24px;
        cursor: pointer;
        transition: scale 0.15s ease-in-out;
        scale: 1.02;
    }
`

const PositionDetail = styled.div`
    margin-top: 17px;
    display: grid;
    gap: 9px;
`
const PositionDetailItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{ 
        text-align: right;
        font-family: Outfit;
        font-style: normal;
        line-height: 125%; /* 22.5px */
    }
    .label{
        color: rgba(75, 40, 40, 0.50);
        font-size: 16px;
        font-weight: 500;
    }
    .content{
        color: #4B2828;
        font-size: 18px;
        font-weight: 600;

    }
    @media screen and (max-width: 450px) {
        .label{
            font-size: 13px;
        }
        .content{
            font-size: 15px;
        }
    }
`

const BadgeText = styled.div`
  font-weight: 500;
  font-size: 12px;
`

export type ShowPositionType = 'DEPOSIT' | 'UNSTAKE' | 'HARVEST'

type AlphaPostionV3ItemProps = {
    showPositionType: ShowPositionType
    nftInfo: AlphaPositionV3Info
    isChecked: boolean
    onCheckedNftId: (nftId: number, isChecked: boolean) => void
}

export const BadgeBox = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    padding: 4px 5px;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 50px;
    background: rgba(0, 228, 64, 0.12);
    margin-left: 5px;
    span{
        color: #05C63B;
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 15px */
    }
`
function getTokenImg(address: string) {
    return `https://raw.githubusercontent.com/Monoswap-Labs/monoswap-token-list/main/img/blast/assets/${address}/logo.png`
}
export default function AlphaPostionV3Item({
    showPositionType,
    nftInfo,
    isChecked,
    onCheckedNftId
}: AlphaPostionV3ItemProps) {
    // const { loading, position } = useV3PositionFromTokenId(nftId)
    const alphaStore = useAlphaStore()

    const currency0 = useToken(nftInfo?.token0)
    const currency1 = useToken(nftInfo?.token1)

    const metadata = usePositionTokenURI(nftInfo?.tokenId)
    const svgSource = useMemo(() => {
        if (!('result' in metadata)) return undefined
        const rawSvt = atob(metadata.result.image.replace('data:image/svg+xml;base64,', ''))
        return rawSvt.replaceAll('â¢', '•')
    }, [metadata])

    const { days, hours, minutes, seconds } = useCountdownTimer('DAY', nftInfo?.countdownTime)

    const totalLiquidity = useMemo(() => {
        if (showPositionType != 'DEPOSIT') return 0
        else if (!nftInfo) return 0
        else {
            const usd0 = alphaStore.calcUsd(nftInfo.token0, Number(nftInfo.liquidityValue0))
            const usd1 = alphaStore.calcUsd(nftInfo.token1, Number(nftInfo.liquidityValue1))
            return usd0 + usd1
        }
    }, [alphaStore.tokenPrices, nftInfo])


    return <AlphaPostionV3ItemWrapper isSelected={isChecked}>
        {
            svgSource && <div className="nft_svg" dangerouslySetInnerHTML={{ __html: svgSource }} />
        }
        <div style={{ width: '100%', height: '100%', paddingBottom: '5px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <PositionInfo>
                <PositionInfoLeft>
                    <PositionDetailItem style={{ alignItems: 'center' }}>
                        <span className="label" style={{ fontSize: '14px' }}>
                            {'NFT ID: '}
                            <span className="label" style={{ fontSize: '14px', color: '#05C63B' }}>
                                #{nftInfo?.formattedTokenId}
                            </span>
                        </span>
                    </PositionDetailItem>
                </PositionInfoLeft>

                <PositionRight>
                    {
                        isChecked ? <svg onClick={() => onCheckedNftId(Number(nftInfo?.formattedTokenId ?? '0'), false)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clip-path="url(#clip0_487_2280)">
                                <path d="M19.5 2H4.5C3.11929 2 2 3.11929 2 4.5V19.5C2 20.8807 3.11929 22 4.5 22H19.5C20.8807 22 22 20.8807 22 19.5V4.5C22 3.11929 20.8807 2 19.5 2Z" fill="#00E440" />
                                <path d="M7.5 12L10.5 15L16.5 9" stroke="#FFF9E1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_487_2280">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                            :
                            <svg onClick={() => { if (nftInfo?.countdownTime == 0 || showPositionType != 'UNSTAKE') onCheckedNftId(Number(nftInfo?.formattedTokenId ?? '0'), true) }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M17.5 0H2.5C1.11929 0 0 1.11929 0 2.5V17.5C0 18.8807 1.11929 20 2.5 20H17.5C18.8807 20 20 18.8807 20 17.5V2.5C20 1.11929 18.8807 0 17.5 0Z" fill="#FFE7AB" />
                            </svg>
                    }
                </PositionRight>
            </PositionInfo>

            <PositionDetail>
                {
                    showPositionType == 'DEPOSIT' && <>
                        <PositionDetailItem>
                            <span className="label">Status</span>
                            <span className="content">
                                {nftInfo?.outOfRange ? (
                                    <BadgeBox>
                                        <AlertCircle width={12} height={12} stroke="orange" />
                                        <span style={{ color: 'orange' }}>Out of range</span>
                                    </BadgeBox>
                                ) : (
                                    <BadgeBox>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                            <path d="M1.19922 6L4.19922 9L10.1992 3" stroke="#05C63B" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <span>In range</span>
                                    </BadgeBox>
                                )}
                            </span>
                        </PositionDetailItem>
                        <PositionDetailItem>
                            <span className="label">{currency0?.symbol}</span>
                            <span className="content">
                                {formatNumber(nftInfo?.liquidityValue0, 6)}
                            </span>
                        </PositionDetailItem>
                        <PositionDetailItem>
                            <span className="label">{currency1?.symbol}</span>
                            <span className="content">
                                {formatNumber(nftInfo?.liquidityValue1, 6)}
                            </span>
                        </PositionDetailItem>
                        <PositionDetailItem>
                            <span className="label">Liquidity</span>
                            <span className="content">
                                ${formatNumber(totalLiquidity, 6)}
                            </span>
                        </PositionDetailItem>

                    </>
                }

                {
                    (showPositionType == 'UNSTAKE' || showPositionType == 'HARVEST') && <>
                        <PositionDetailItem>
                            <span className="label">REWARD</span>
                            <span className="content">
                                {formatXpNumber(nftInfo?.rewardValue, 6)} xGM
                            </span>
                        </PositionDetailItem>
                    </>
                }

                {
                    showPositionType == 'UNSTAKE' && <>
                        <PositionDetailItem>
                            <span className="label">LOCK UNTIL</span>
                            <span className="content">
                                {
                                    nftInfo?.countdownTime == 0 ? '0d : 0h : 0m : 0s' : `${days}d : ${hours}h : ${minutes}m : ${seconds}s`
                                }
                            </span>
                        </PositionDetailItem>
                    </>
                }
            </PositionDetail>
        </div>


    </AlphaPostionV3ItemWrapper>
}