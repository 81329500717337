import {Trans} from '@lingui/macro'
import PositionListItem from 'components/PositionListItem'
import React from 'react'
import styled from 'styled-components/macro'
import {MEDIA_WIDTHS} from 'theme'
import {PositionDetails} from 'types/position'

const DesktopHeader = styled.div`
    display: none;
    font-size: 14px;
    font-weight: 500;
    padding: 16px;
    border-bottom: 1px solid ${({theme}) => 'rgba(239,239,228,0.2)'};
    color: #4B2828;
    @media screen and (min-width: ${MEDIA_WIDTHS.deprecated_upToSmall}px) {
        align-items: center;
        display: flex;
        justify-content: space-between;
        & > div:last-child {
            text-align: right;
            margin-right: 12px;
        }
    }
`

const MobileHeader = styled.div`
    font-weight: medium;
    padding: 8px;
    font-weight: 500;
    padding: 16px;
    color: #4B2828;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid ${({theme}) => 'rgba(239,239,228,0.2)'};

    @media screen and (min-width: ${MEDIA_WIDTHS.deprecated_upToSmall}px) {
        display: none;
    }

    @media screen and (max-width: ${MEDIA_WIDTHS.deprecated_upToExtraSmall}px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`

const ToggleWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const ToggleLabel = styled.button`
    cursor: pointer;
    background: linear-gradient(90deg, #68ffff 0%, #68ff9b 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    border: none;
    font-size: 1rem;
`

type PositionListProps = React.PropsWithChildren<{
    positions: PositionDetails[]
    setUserHideClosedPositions: any
    userHideClosedPositions: boolean
}>

export default function PositionList({
                                         positions,
                                         setUserHideClosedPositions,
                                         userHideClosedPositions,
                                     }: PositionListProps) {
    return (
        <>
            {/*<DesktopHeader>*/}
            {/*  <div>*/}
            {/*    <Trans>Your positions</Trans>*/}
            {/*    {positions && ' (' + positions.length + ')'}*/}
            {/*  </div>*/}

            {/*  <ToggleLabel*/}
            {/*    id="desktop-hide-closed-positions"*/}
            {/*    onClick={() => {*/}
            {/*      setUserHideClosedPositions(!userHideClosedPositions)*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    {userHideClosedPositions ? (*/}
            {/*      <div style={{ color: '#4B2828' }}>*/}
            {/*        <Trans>Show closed positions</Trans>{' '}*/}
            {/*      </div>*/}
            {/*    ) : (*/}
            {/*      <div style={{ color: '#4B2828' }}>*/}
            {/*        <Trans>Hide closed positions</Trans>*/}
            {/*      </div>*/}
            {/*    )}*/}
            {/*  </ToggleLabel>*/}
            {/*</DesktopHeader>*/}
            {/*<MobileHeader>*/}
            {/*  <Trans>Your positions</Trans>*/}
            {/*  <ToggleWrap>*/}
            {/*    <ToggleLabel*/}
            {/*      onClick={() => {*/}
            {/*        setUserHideClosedPositions(!userHideClosedPositions)*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      {userHideClosedPositions ? (*/}
            {/*        <div style={{ color: '#4B2828' }}>*/}
            {/*          <Trans>Show closed positions</Trans>{' '}*/}
            {/*        </div>*/}
            {/*      ) : (*/}
            {/*        <div style={{ color: '#4B2828' }}>*/}
            {/*          <Trans>Hide closed positions</Trans>*/}
            {/*        </div>*/}
            {/*      )}*/}
            {/*    </ToggleLabel>*/}
            {/*  </ToggleWrap>*/}
            {/*</MobileHeader>*/}
            {positions.map((p) => (
                <PositionListItem key={p.tokenId.toString()} {...p} />
            ))}
        </>
    )
}
