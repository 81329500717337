import { ApolloClient, ApolloLink, concat, HttpLink, InMemoryCache } from '@apollo/client'
import { SupportedChainId } from 'constants/chains'

import store, { AppState } from '../../state/index'

const CHAIN_SUBGRAPH_URL: Record<number, string> = {
  [SupportedChainId.MAINNET]: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
  [SupportedChainId.ZIRCUIT]: 'https://app.sentio.xyz/api/v1/graphql/gmeowfi/gmeowfi-v3',
  // [SupportedChainId.ZIRCUIT_TESTNET]: 'https://api.studio.thegraph.com/query/64710/monoswap-v3-mainnet/v0.0.4',
}

const ZIRCUIT_API_KEY = 'ipA5fy31L4chrlWNherHYxydwESu2SgUz';


const httpLink = new HttpLink({ uri: CHAIN_SUBGRAPH_URL[SupportedChainId.ZIRCUIT] })

// This middleware will allow us to dynamically update the uri for the requests based off chainId
// For more information: https://www.apollographql.com/docs/react/networking/advanced-http-networking/
const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const chainId = (store.getState() as AppState).application.chainId

  const uri = chainId && CHAIN_SUBGRAPH_URL[chainId]
    ? CHAIN_SUBGRAPH_URL[chainId]
    : CHAIN_SUBGRAPH_URL[SupportedChainId.MAINNET];

  const headers: Record<string, string> = {};
  if (chainId === SupportedChainId.ZIRCUIT) {
    headers['api-key'] = ZIRCUIT_API_KEY;
  }

  operation.setContext(() => ({
    uri,
    headers,
  }));

  return forward(operation)
})

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
})
