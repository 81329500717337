import { Token } from "@uniswap/sdk-core"
import { TokenInfo } from "@uniswap/token-lists"
import { useWeb3React } from "@web3-react/core"
import { NotchedButtonFill } from "components/Button"
import { useTokenLogo } from "hooks/Tokens"
import { formatXpNumber } from "pages/Xp/sections/XpTitle"
import { useNavigate } from "react-router-dom"
import { useToggleWalletModal } from "state/application/hooks"
import styled from "styled-components"
import { formatNumber } from "utils/formatNumbers"

export const FarmingListItemWrapper = styled.div`
    width: 100%;
    max-width: 374px;
    height: 311px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF6D0;
    box-shadow: 12px 12px 0px 0px #00ABB6, 10px 10px 4px 0px #FFF inset;
    padding: 20px;
    padding-bottom: 32px;
`

export const PoolInforBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const PoolInfo = styled.div`
    display: flex;
    gap: 10px;
    align-items: flex-end;
`

export const PoolInfoImageBox = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
    img{
        width: 24px;
        height: 24px;
        border-radius: 24px;
        box-shadow: 2px 2px 0px 0px #D7903C;
    }
`

export const PoolInfoTitle = styled.span`
    color: #4B2828;
    font-family: DynaPuff;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 22.5px */
    text-transform: uppercase;
`

export const PoolInfoFee = styled.div`
    display: inline-flex;
    height: 24px;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #FFE7AB;
    span{
        color: #05C63B;
        text-align: center;
        font-family: DynaPuff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        text-transform: uppercase;
    }
`
export const PoolInfoDetail = styled.div`
    width: 100%;
    border-radius: 10px;
    background: #FFF9E1;
    box-shadow: 0px 4px 20px 0px rgba(145, 103, 20, 0.12);
    display: grid;
    gap: 9px;
    padding: 16px;
    margin-top: 18px;
    margin-bottom: 24px;
`
export const DetailItem = styled.div`
    display: flex;
    justify-content: space-between;
    span{
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 20px */
    }
    span.label{
        color: rgba(75, 40, 40, 0.50);
    }
    span.content{
        color: #4B2828;
        display: flex;
        gap: 4px;
        align-items: center;
        img{
            width: 16px;
            height: 16px;
        }
    }
`


type FarmingListItemProps = {
    tvl: number
    apr: number
    totalUnclaimReward: number
    token0?: Token | null
    token1?: Token | null
    token0Url: string 
    token1Url: string
    poolFee: number
    goDetail: () => void
    userTvl: number
    hasEnded?: boolean
}
export default function FarmingListItem({
    tvl,
    apr,
    totalUnclaimReward,
    token0,
    token1,
    token0Url,
    token1Url,
    poolFee,
    goDetail,
    userTvl,
    hasEnded
}: FarmingListItemProps) {
    const { account } = useWeb3React()
    const toggleWalletModal = useToggleWalletModal()
 
    return <FarmingListItemWrapper>
        <PoolInforBox>
            <PoolInfo>
                <PoolInfoImageBox>
                    <img src={token0Url} alt="gmeowfi" />
                    <img src={token1Url} alt="gmeowfi" />
                </PoolInfoImageBox>
                <PoolInfoTitle>
                    {token0?.symbol}/{token1?.symbol}
                </PoolInfoTitle>
                <PoolInfoFee>
                    <span>{poolFee}%</span>
                </PoolInfoFee>
            </PoolInfo>
            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/farming/pool_item_icon.png" alt="gmeowfi" />
        </PoolInforBox>

        <PoolInfoDetail>
            <DetailItem>
                <span className="label">
                    TVL
                </span>
                <span className="content">
                    ${formatXpNumber(tvl, 3)}
                </span>
            </DetailItem>

            <DetailItem>
                <span className="label">
                    APR
                </span>
                <span className="content">
                    {formatXpNumber(apr)}%
                </span>
            </DetailItem>

            <DetailItem>
                <span className="label">
                    Incentives
                </span>
                <span className="content">
                    {formatXpNumber(totalUnclaimReward)}
                    <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/tokens/xgm_token.png" alt="gmeowfi" />
                </span>
            </DetailItem>

            <DetailItem>
                <span className="label">
                    Your deposit
                </span>
                <span className="content">
                    ${formatNumber(userTvl, 6)}
                </span>
            </DetailItem>
        </PoolInfoDetail>
        <NotchedButtonFill
            // padding={'0px 17.5px'} mobilePadding={'0px 15px'}
            //     fontSize={'16px'}
            //     height={"40px"}
            //     earMaxW={"120px"} mbEarMaxW={"120px"}
            //     bg={"#00E440"}
            //     textColor="#FFF9E1"
            onClick={() => { if (!account) toggleWalletModal(); else if (!hasEnded) goDetail() }}
        >
            {account ? hasEnded ? 'View' : 'Deposit' : 'Connect'}
        </NotchedButtonFill>

    </FarmingListItemWrapper>
}
