import styled from 'styled-components'
import React, { useEffect, useState } from 'react'

import { BREAKPOINTS } from 'theme'
import { useCustomeContract } from 'hooks/useContract'
import {
    MONO_CHEST_ADDRESSES_MAP,
    MONO_CHEST_NFT_ADDRESSES_MAP,
} from '../../../constants/addresses'
import monochest_abi from '../../../abis/monochest/monochest.json'
import monoNft_abi from '../../../abis/monochest/mononft.json'

import { ethers } from 'ethers'
import axios from 'axios'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_CHAINID } from '../../../constants/chains'
import { CustomLightSpinner } from '../../../theme'
import ChestHistoryTable from './ChestHistoryTable'
import { getContract, shortenAddress } from 'utils'
import { RPC_URLS } from 'constants/networks'
import shuffleArray from 'utils/shuffleArray'
import {
    Section1,
    Section1Bottom,
    Section1BottomInfoWrapper,
    Section1BottomTxtWrapper,
    Section1Top,
    Section1TopItem,
    Section2,
    Section2ChestCommonWrapper,
    Section2ChestWrapper,
    SectionWrapper,
    TextOutline,
    TextStyle,
    ChestBoxWrapper,
    ChestBoxRateWrapper,
    ChestBoxContentWrapper,
    Section2ChestSpecialBoxWrapper,
    Section4,
    DescItem, TooltipItem
} from "../components/Components";
import "../assets/style.css"
import { NotchedButtonFill } from "../../../components/Button";

import { PopupGetBox } from "../components/PopupGetBox";
import { formatNumber } from 'utils/formatNumbers'
import { useBoxStore } from '../../../hooks/useBox'
import { Popover } from 'antd'
import BigNumber from 'bignumber.js';
import { GMEOW_API_BASE_URL } from 'utils/env'

const Circle = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/blue-loader.svg'

const TreePopupContent = styled.div`
    justify-content: center;
    align-items: center;

    min-width: 220px;
    height: fit-content;
    padding: 20px;
    align-items: flex-center;
    justify-items: center;
    gap: 0px;
    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.2);
    p {
        color: #FFB704;
        font-family: DynaPuff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 17.5px */
        margin: 0;
        text-align: center;
    }
    img.earned_img{
        width: 16px;
        height: 16px;
        border-radius: 50%;
    }
`
const RwTreePopupContent = styled(TreePopupContent)`
    display: grid;
    gap: 12px;
    padding: 12px;
    .info-wrapper{
        display: grid;
        gap: 4px;
  }
 `

const OpenHistoryMarqueeBox = styled.div<{
    maxWMobile?: string;
}>`
    max-width: 1200px;
    margin: 10px auto;
    display: flex;
    width: 100%;
    align-items: center;
    gap: 16px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.06) 51.01%, rgba(255, 255, 255, 0.02) 100%);

    .marquee{
       gap: 28px;
    }
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        width: 95%;
    }
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        max-width: ${({ maxWMobile }) => maxWMobile ?? '100%'};
    }
`
const OpenHistoryMarqueeList = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    position: relative;
    overflow: clip;
    width: fit-content;
    animation-duration: 15s;
`

const OpenHistoryMarqueeItem = styled.div`
    width: 200px;
    display: flex;
    align-items: center;
    gap: 16px;

    span {
        font-family: Urbanist;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
    }
`

export type ChestType = {
    level: number
    usdbPrice: BigNumber
    nftId: number
    chestName: string
    openFee: BigNumber
    earnedReward?: {
        usd: number,
        xgm: number,
        nft: number
    }
    rwInfo: {
        ticket: string;
        monopass: number;
        xmono: string;
        musd: string
    }
    nftBalance: number
}

const getCalcMarqueeItemWith = (type: string, amountLength: number) => {
    if (type == 'usd') {
        if (amountLength == 1) return '180px'
        else if (amountLength == 2) return '190px'
        else if (amountLength == 3) return '195px'
        else if (amountLength == 4) return '200px'
        else if (amountLength == 5) return '205px'
        else if (amountLength == 6) return '215px'
        else if (amountLength == 7) return '220px'
        else if (amountLength == 8) return '225px'
        else return '235px'
    } else if (type == 'xmono') {
        if (amountLength == 1) return '200px'
        else if (amountLength == 2) return '208px'
        else if (amountLength == 3) return '216px'
        else if (amountLength == 4) return '224px'
        else if (amountLength == 5) return '232px'
        else if (amountLength == 6) return '240px'
        else if (amountLength == 7) return '248px'
        else if (amountLength == 8) return '256px'
        else return '262px'
    } else if (type == 'monopass') {
        if (amountLength == 1) return '210px'
        else if (amountLength == 2) return '216px'
        else if (amountLength == 3) return '226px'
        else if (amountLength == 4) return '236px'
        else if (amountLength == 5) return '246px'
        else if (amountLength == 6) return '256px'
        else return '266px'
    } else if (type == 'ticket') {
        if (amountLength == 1) return '195px'
        else if (amountLength == 2) return '205px'
        else if (amountLength == 3) return '210px'
        else if (amountLength == 4) return '215px'
        else if (amountLength == 5) return '220px'
        else if (amountLength == 6) return '225px'
        else if (amountLength == 7) return '230px'
        else if (amountLength == 8) return '235px'
        else return '250px'
    }
    return 0
}
export default function ChestPageSection1() {
    // state
    const { account, chainId } = useWeb3React()

    const boxStore = useBoxStore()
    const [screenW, setScreenW] = useState<number>(1200);

    // boxPrice[BoxType.Fun] = 0.0005 ether;
    // boxPrice[BoxType.Joy] = 0.001 ether;
    // boxPrice[BoxType.Sip] = 0.0015 ether;
    // boxPrice[BoxType.Sweat] = 0.002 ether;
    // boxPrice[BoxType.Treasure] = 0.0025 ether;
    const [chestBoxes, setChestBoxes] = useState<ChestType[]>([
        {
            level: 1,
            usdbPrice: new BigNumber('0.0005'),
            chestName: 'Fun box',
            nftId: 2,
            openFee: new BigNumber('0.000025'),
            rwInfo: {
                ticket: 'x1',
                monopass: 1,
                xmono: '0.1 - 0.2',
                musd: '$0.1 - $10'
            },
            nftBalance: 0
        },
        {
            level: 2,
            usdbPrice: new BigNumber('0.001'),
            chestName: 'joy box',
            nftId: 3,
            openFee: new BigNumber('0.00005'),
            rwInfo: {
                ticket: 'x2',
                monopass: 1,
                xmono: '0.1 - 0.5',
                musd: '$0.1 - $100'
            },
            nftBalance: 0
        },
        {
            level: 3,
            usdbPrice: new BigNumber('0.0015'),
            chestName: 'sip box',
            nftId: 4,
            openFee: new BigNumber('0.000075'),
            rwInfo: {
                ticket: 'x3',
                monopass: 1,
                xmono: '0.1 - 1',
                musd: '$0.1 - $200'
            },
            nftBalance: 0
        },
        {
            level: 4,
            usdbPrice: new BigNumber('0.002'),
            chestName: 'sweat box',
            nftId: 5,
            openFee: new BigNumber('0.0001'),
            rwInfo: {
                ticket: 'x4',
                monopass: 1,
                xmono: '0.1 - 1',
                musd: '$0.1 - $400'
            },
            nftBalance: 0
        },
        {
            level: 5,
            usdbPrice: new BigNumber('0.0025'),
            chestName: 'treasure box',
            nftId: 6,
            openFee: new BigNumber('0.00012'),
            rwInfo: {
                ticket: 'x5',
                monopass: 1,
                xmono: '0.1 - 0.1',
                musd: '$0.1 - $600'
            },
            nftBalance: 0
        },
    ])
    const [boxInfo, setBoxInfo] = useState<ChestType>(chestBoxes[0])
    const [loadingGetContractInfo, setLoadingGetContractInfo] = useState(false)

    const [openHistoryList, setOpenHistoryList] = useState([
        {
            address: '0xca64...664C',
            amount: 10000,
            type: 'Tickets',
            width: getCalcMarqueeItemWith('ticket', 5)
        },
        {
            address: '0xca64...664C',
            amount: 2525.22,
            type: 'xGM',
            width: getCalcMarqueeItemWith('xmono', 7)
        },
        {
            address: '0xca64...664C',
            amount: 1,
            type: 'xGM',
            width: getCalcMarqueeItemWith('xmono', 1)
        },
        {
            address: '0xca64...664C',
            amount: 1,
            type: 'NFT',
            width: getCalcMarqueeItemWith('monopass', 1)
        },
        {
            address: '0xca64...664C',
            amount: 12,
            type: 'NFT',
            width: getCalcMarqueeItemWith('monopass', 2)
        },

        {
            address: '0xca64...664C',
            amount: 12,
            type: 'Tickets',
            width: getCalcMarqueeItemWith('ticket', 2)
        },
        {
            address: '0xca64...664C',
            amount: 12,
            type: 'xGM',
            width: getCalcMarqueeItemWith('xmono', 2)
        },
        {
            address: '0xca64...664C',
            amount: 0.1,
            type: 'ETH',
            width: getCalcMarqueeItemWith('usd', 1)
        },
        {
            address: '0xca64...664C',
            amount: 100,
            type: 'Tickets',
            width: getCalcMarqueeItemWith('ticket', 3)
        }
    ])
    const [totalUserOpennedAmount, setTotalUserOpennedAmount] = useState(0)
    const [userChestOwnByType, setUserChestOwnByType] = useState<any>([])

    const isSupported = chainId === DEFAULT_CHAINID

    const chestContract = getContract(MONO_CHEST_ADDRESSES_MAP[DEFAULT_CHAINID], monochest_abi, new ethers.providers.JsonRpcProvider(RPC_URLS[DEFAULT_CHAINID][0]))
    const monoNftContract = useCustomeContract(MONO_CHEST_NFT_ADDRESSES_MAP[DEFAULT_CHAINID], monoNft_abi, true)

    // get contract info
    const getContractInfo = async () => {
        setLoadingGetContractInfo(true)
        try {
            axios.get(`${GMEOW_API_BASE_URL}/box/get-box-info?address=${String(account ?? '').toLowerCase()}`)
                .then(({ data }) => {
                    console.log('data', data);

                    useBoxStore.setState({
                        totalOpennedAmount: data.totalOpened,
                        totalParticipant: Number(data.totalOpener),
                        totalRewardEarned: {
                            totalXMono: data?.earned?.xgm,
                            totalUSD: data?.earned?.usd,
                            totalMonoPass: data?.earned?.nft,
                        }
                    })
                    setTotalUserOpennedAmount(data?.earned?.totalChest)
                })
            setLoadingGetContractInfo(false)
        } catch (error) {
            console.log('error', error)
            setLoadingGetContractInfo(false)
        }
    }

    const loadUserInfo = async () => {
        if (account) {
            loadTotalUserNftBalance()
            // let totalUSerOpenned = []
            // let totalUSerAmount = 0
            // for (let i = 0; i < 5; i++) {
            //     const chestUserOpenByType = await chestContract?.totalChestOpenedByUser(account, i)
            //     let Array = {
            //         amount: Number(ethers.utils.formatUnits(chestUserOpenByType, 0)),
            //     }
            //     totalUSerOpenned.push(Array)
            //     totalUSerAmount += Number(ethers.utils.formatUnits(chestUserOpenByType, 0))
            // }
            // setUserChestOwnByType(totalUSerOpenned)
            // setTotalUserOpennedAmount(totalUSerAmount)
        }
    }
    const getChestOpenFee = async () => {
        try {
            const feeOpenChest1 = await chestContract?.txFees(0)
            const feeOpenChest2 = await chestContract?.txFees(1)
            const feeOpenChest3 = await chestContract?.txFees(2)
            const feeOpenChest4 = await chestContract?.txFees(3)
            const feeOpenChest5 = await chestContract?.txFees(4)

            setChestBoxes(prev => {
                for (let index = 0; index < prev.length; index++) {
                    const element = prev[index];
                    if (element.level == 1)
                        element.openFee = feeOpenChest1.toNumber()
                    if (element.level == 2)
                        element.openFee = feeOpenChest2.toNumber()
                    if (element.level == 3)
                        element.openFee = feeOpenChest3.toNumber()
                    if (element.level == 4)
                        element.openFee = feeOpenChest4.toNumber()
                    if (element.level == 5)
                        element.openFee = feeOpenChest5.toNumber()
                }
                return prev;
            })
        } catch (err) {
        }
    }

    const loadTotalUserNftBalance = async () => {
        if (!account) return
        try {
            let totalNftBalance = [0, 0, 0, 0, 0]
            for (let index = 0; index < chestBoxes.length; index++) {
                const element = chestBoxes[index];
                const userNftBalance = await monoNftContract?.balanceOf(account, element.nftId)
                const formattedUserBalance = parseInt(userNftBalance._hex, 16)
                totalNftBalance[index] = formattedUserBalance
                chestBoxes[index].nftBalance = formattedUserBalance
            }
            useBoxStore.setState({
                totalNftChest1: totalNftBalance[0],
                totalNftChest2: totalNftBalance[1],
                totalNftChest3: totalNftBalance[2],
                totalNftChest4: totalNftBalance[3],
                totalNftChest5: totalNftBalance[4],
            })
        } catch (err) {
        }
    }

    const [loadingApprovedNftContract, setLoadingApprovedNftContract] = useState(false)
    const [hasApprovedNftContract, setHasApprovedNftContract] = useState(false)

    // approve NFT
    const approveContractNft = async () => {
        setLoadingApprovedNftContract(true)
        try {
            const setApproveContract = await monoNftContract?.setApprovalForAll(MONO_CHEST_ADDRESSES_MAP[DEFAULT_CHAINID], true)
            await setApproveContract.wait()

            setLoadingApprovedNftContract(false)
            handleCheckApproveNftContract()

            return true
        } catch (error) {
            console.log(error)
            setLoadingApprovedNftContract(false)
            handleCheckApproveNftContract()

            return false
        }
    }

    const handleCheckApproveNftContract = async () => {
        try {
            const isApproved = await monoNftContract?.isApprovedForAll(account, MONO_CHEST_ADDRESSES_MAP[DEFAULT_CHAINID])
            if (isApproved) {
                setHasApprovedNftContract(true)
            } else {
                setHasApprovedNftContract(false)
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        getContractInfo()
        // @ts-ignore
        window.loadTotalUserNftBalance = loadTotalUserNftBalance
    }, [])
    useEffect(() => {
        if (account) {
            loadUserInfo()
            handleCheckApproveNftContract()

            axios.get(`${GMEOW_API_BASE_URL}/box/get-earned-reward?address=` + account.toLowerCase())
                .then(({ data }) => {
                    setChestBoxes(prev => {
                        if (data.box0) prev[0].earnedReward = data.box0
                        if (data.box1) prev[1].earnedReward = data.box1
                        if (data.box2) prev[2].earnedReward = data.box2
                        if (data.box3) prev[3].earnedReward = data.box3
                        if (data.box4) prev[4].earnedReward = data.box4
                        return prev
                    })
                })
        }
    }, [account])


    useEffect(() => {
        setScreenW(window.screen.width)
        axios.get(`${GMEOW_API_BASE_URL}/box/get-history?page=0&per_page=20`)
            .then(({ data }) => {
                const arr: any[] = []
                for (let index = 0; index < data.data.length; index++) {
                    const { xgm, usd, ticketCnt, totalNft, address } = data.data[index]
                    try {
                        const userAddress = shortenAddress(address, 4)
                        if (xgm > 0)
                            arr.push({
                                address: userAddress,
                                amount: xgm,
                                type: 'xGM',
                                width: getCalcMarqueeItemWith('xmono', String(xgm).length)
                            })
                        if (usd > 0)
                            arr.push({
                                address: userAddress,
                                amount: usd,
                                type: 'WETH',
                                width: getCalcMarqueeItemWith('usd', String(usd).length)
                            })
                        arr.push({
                            address: userAddress,
                            amount: ticketCnt,
                            type: 'Ticket' + (ticketCnt > 1 ? 's' : ''),
                            width: getCalcMarqueeItemWith('ticket', String(ticketCnt).length)
                        })
                        if (totalNft > 0)
                            arr.push({
                                address: userAddress,
                                amount: totalNft,
                                type: 'NFT',
                                width: getCalcMarqueeItemWith('monopass', String(totalNft).length)
                            })
                    } catch (err) {
                    }
                }
                let newArr = []
                // for (let index = 0; index < 10; index++)
                    newArr.push(...arr)
                setOpenHistoryList(shuffleArray(newArr))
            })
    }, [])

    const [showPopupGetBox, setShowPopupGetBox] = useState(false);
    const [title, setTitle] = useState<string>("");
    const [urlGift, setUrlGift] = useState<string>("");
    const [urlOpenBoxGift, setUrlOpenBoxGift] = useState("");
    const togglePopupGetBox = (title: any, urlGift: any, urlOpenBoxGift: any, boxData: ChestType) => {
        setShowPopupGetBox(!showPopupGetBox)
        setTitle(title)
        setUrlGift(urlGift)
        setUrlOpenBoxGift(urlOpenBoxGift)
        setBoxInfo(boxData)
        if (boxData.nftBalance > 0)
            setTimeout(() => {
                // @ts-ignore
                window.handleOpenBox(true, boxData.nftBalance, boxData.openFee, boxData.level - 1)
            }, 500)
    };
    const onCloseBuyBoxPopup = () => {
        setShowPopupGetBox(false)
        setTitle('')
        setUrlGift('')
        setUrlOpenBoxGift('')
    }

    return (
        <>
            <SectionWrapper>
                <PopupGetBox
                    show={showPopupGetBox}
                    title={title}
                    urlGif={urlGift}
                    urlOpenBoxGif={urlOpenBoxGift}
                    boxInfo={boxInfo}
                    onClose={onCloseBuyBoxPopup}></PopupGetBox>
                <Section1>
                    <Section1Top>
                        {
                            openHistoryList.length > 0 && <>
                                {
                                    screenW && <OpenHistoryMarqueeBox className='marquee-container'>
                                        <OpenHistoryMarqueeList className='marquee'>
                                            {
                                                openHistoryList.map((i, index) => <Section1TopItem key={index}>
                                                    <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section1/img.png" alt="img" />
                                                    <TextStyle color="#93553A" fontSize="14px" fontSizeLg="12px" fontSizeMb="8px"
                                                        fontWeight="600"
                                                        lineHeight="125%">
                                                        {i.address} <span style={{ fontWeight: "400" }}>won</span> <span
                                                            style={{ color: "#05C63B" }}>{i.amount} {i.type}</span>
                                                    </TextStyle>
                                                </Section1TopItem>)
                                            }
                                        </OpenHistoryMarqueeList>
                                        {/* <OpenHistoryMarqueeList className='marquee'>
                                            {
                                                openHistoryList.map((i, index) => <Section1TopItem key={index}>
                                                    <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section1/img.png" alt="img" />
                                                    <TextStyle color="#93553A" fontSize="14px" fontSizeLg="12px" fontSizeMb="8px"
                                                        fontWeight="600"
                                                        lineHeight="125%">
                                                        {i.address} <span style={{ fontWeight: "400" }}>won</span> <span
                                                            style={{ color: "#05C63B" }}>{i.amount} {i.type}</span>
                                                    </TextStyle>
                                                </Section1TopItem>)
                                            }
                                        </OpenHistoryMarqueeList> */}
                                    </OpenHistoryMarqueeBox>
                                }
                            </>
                        }
                    </Section1Top>

                    <Section1Bottom>
                        <Section1BottomTxtWrapper>
                            <TextStyle fontFamily="DynaPuff"
                                fontSize="36px"
                                fontSizeLg="24px"
                                fontSizeMb="20px"
                                lineHeight="125%"
                                textTransform="uppercase">
                                open boxes get your pussies
                            </TextStyle>
                            <TextStyle color="#93553A"
                                fontWeight="400"
                                lineHeight="125%">
                                Let's see what surprises you'll get.
                            </TextStyle>
                        </Section1BottomTxtWrapper>
                        <Section1BottomInfoWrapper>
                            <div className="info">
                                <TextStyle color="#D7903C"
                                    fontSize="14px" fontSizeMb="12px"
                                    fontWeight="400">
                                    Total Opened
                                </TextStyle>
                                <TextStyle fontSize="21px" fontWeight="600">
                                    {loadingGetContractInfo ? (
                                        <CustomLightSpinner src={Circle} alt="loader" size="10px" />) : formatNumber(boxStore.totalOpennedAmount)}
                                </TextStyle>
                            </div>
                            <div className="section1-bottom-line"></div>
                            <div className="info">
                                <TextStyle color="#D7903C"
                                    fontSize="14px" fontSizeMb="12px"
                                    fontWeight="400">
                                    Total Opener
                                </TextStyle>
                                <TextStyle fontSize="21px" fontWeight="600">
                                    {loadingGetContractInfo ? (
                                        <CustomLightSpinner src={Circle} alt="loader" size="10px" />) : formatNumber(boxStore.totalParticipant)
                                    }
                                </TextStyle>
                            </div>
                            <div className="section1-bottom-line"></div>
                            <div className="info">
                                <TextStyle color={"#D7903C"}
                                    fontSize="14px" fontSizeMb="12px"
                                    fontWeight={"400"}>
                                    Total Earned
                                </TextStyle>
                                <div className="total-distribute-statistics-wrapper">
                                    <div className="total-distribute-statistics">
                                        <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/eth.png" alt="img_4" />
                                        <TextStyle fontWeight={"600"} fontSize="21px" fontSizeMb="14px">
                                            {loadingGetContractInfo ? (
                                                <CustomLightSpinner src={Circle} alt="loader" size="10px" />
                                            ) : formatNumber(boxStore.totalRewardEarned?.totalUSD, 4)}
                                            <span style={{
                                                color: "rgba(75, 40, 40, 0.50)",
                                                fontFamily: "Outfit",
                                                marginLeft: "4px"
                                            }}>WETH</span>
                                        </TextStyle>
                                    </div>
                                    <div className="total-distribute-statistics">
                                        <img className="icon-statistics" src="/treat/img_5.png" alt="img_4" />
                                        <TextStyle fontWeight={"600"} fontSize="21px" fontSizeMb="14px">
                                            {loadingGetContractInfo ? (
                                                <CustomLightSpinner src={Circle} alt="loader" size="10px" />
                                            ) : formatNumber(boxStore.totalRewardEarned?.totalXMono)}
                                            <span style={{
                                                color: "rgba(75, 40, 40, 0.50)",
                                                fontFamily: "Outfit",
                                                marginLeft: "4px"
                                            }}>xGM</span>
                                        </TextStyle>
                                    </div>
                                    <div className="total-distribute-statistics">
                                        <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section1/img_4.png"
                                            alt="img_4" />
                                        <TextStyle fontWeight={"600"} fontSize="21px" fontSizeMb="14px">
                                            {loadingGetContractInfo ? (
                                                <CustomLightSpinner src={Circle} alt="loader" size="10px" />
                                            ) : boxStore.totalRewardEarned?.totalMonoPass}
                                            <span style={{
                                                color: "rgba(75, 40, 40, 0.50)",
                                                fontFamily: "Outfit",
                                                marginLeft: "4px"
                                            }}>NFT{boxStore.totalRewardEarned?.totalMonoPass > 0 ? 's' : ''}</span>
                                        </TextStyle>
                                    </div>
                                    {/* <img className="shop-arrow-icon" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section1/img_5.png"
                                        alt="img_5" /> */}
                                </div>
                            </div>
                        </Section1BottomInfoWrapper>
                    </Section1Bottom>
                </Section1>
                <img className="line-star" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/img_6.png" alt="img_6" />
                <Section2>
                    <div className="section2-title">
                        <TextStyle fontSize="36px"
                            fontSizeLg="24px"
                            fontSizeMb="20px"
                            lineHeight="125%"
                            textTransform="uppercase"
                            fontFamily="DynaPuff">
                            get yourself
                        </TextStyle>
                        <TextOutline fontSizeLg="26px" fontSizeMb="22px">
                            a box of TREATs
                        </TextOutline>
                    </div>
                    <Section2ChestWrapper>
                        <Section2ChestCommonWrapper>
                            <ChestBoxWrapper>
                                <ChestBoxRateWrapper>
                                    <img className="rate" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_1.png" alt="" />
                                    <Popover
                                        overlayInnerStyle={{ padding: 0, borderRadius: '6px', border: '2px solid #E5B670' }}
                                        arrow={true}
                                        color="#FFF9E1"
                                        placement="top"
                                        content={<RwTreePopupContent>
                                            <TextOutline fontSize="24px" strokeWidth="1.5px">
                                                fun box
                                            </TextOutline>
                                            <div className="info-wrapper">
                                                <TooltipItem>
                                                    <div className="desc-img-wrapper">
                                                        <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/eth.png" alt="img_1" />
                                                    </div>
                                                    <div className="desc-content-wrapper">
                                                        <TextStyle fontSize="12px" fontWeight="400">
                                                            ETH
                                                        </TextStyle>
                                                        <TextStyle color="#05C63B"
                                                            fontWeight="600"
                                                            lineHeight="125%">
                                                            0.00005 - 0.005
                                                        </TextStyle>
                                                    </div>
                                                </TooltipItem>
                                                <TooltipItem>
                                                    <div className="desc-img-wrapper">
                                                        <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_2.png" alt="img_2" />
                                                    </div>
                                                    <div className="desc-content-wrapper">
                                                        <TextStyle fontSize="12px" fontWeight="400">
                                                            Meow Chronicles NFT
                                                        </TextStyle>
                                                        <TextStyle color="#05C63B"
                                                            fontWeight="600"
                                                            lineHeight="125%">
                                                            1
                                                        </TextStyle>
                                                    </div>
                                                </TooltipItem>
                                                <TooltipItem>
                                                    <div className="desc-img-wrapper">
                                                        <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_3.png" alt="img_3" />
                                                    </div>
                                                    <div className="desc-content-wrapper">
                                                        <TextStyle fontSize="12px" fontWeight="400">
                                                            xGM
                                                        </TextStyle>
                                                        <TextStyle color="#05C63B"
                                                            fontWeight="600"
                                                            lineHeight="125%">
                                                            10 - 20
                                                        </TextStyle>
                                                    </div>
                                                </TooltipItem>
                                                <TooltipItem>
                                                    <div className="desc-img-wrapper">
                                                        <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_4.png" alt="img_4" />
                                                    </div>
                                                    <div className="desc-content-wrapper">
                                                        <TextStyle fontSize="12px" fontWeight="400">
                                                            Ticket
                                                        </TextStyle>
                                                        <TextStyle color="#05C63B"
                                                            fontWeight="600"
                                                            lineHeight="125%">
                                                            x1
                                                        </TextStyle>
                                                    </div>
                                                </TooltipItem>
                                            </div>
                                        </RwTreePopupContent>}>
                                        <img className="box-info-icon" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_6.png" alt="" />
                                    </Popover>
                                </ChestBoxRateWrapper>

                                <ChestBoxContentWrapper>
                                    <div className="wrapper-box">
                                        <img className="box-style" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_1.gif" alt="box_1" />
                                    </div>
                                    <div className="content-wrapper">
                                        <div className="desc-wrapper">
                                            <TextStyle fontFamily="DynaPuff"
                                                fontSize="21px"
                                                lineHeight="125%"
                                                textTransform="uppercase">
                                                Fun box
                                            </TextStyle>
                                            {
                                                account && <Popover
                                                    overlayInnerStyle={{ padding: 0, borderRadius: '6px', border: '2px solid #E5B670' }}
                                                    arrow={true}
                                                    color="#FFF9E1"
                                                    placement="top"
                                                    content={<TreePopupContent>
                                                        <p style={{ marginBottom: '10px', fontSize: '14px' }}>Total Earned: <br /></p>
                                                        <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '4px' }}>
                                                            {formatNumber(chestBoxes[0]?.earnedReward?.usd ?? 0, 4)} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/eth.png" /> / {formatNumber(chestBoxes[0]?.earnedReward?.xgm ?? 0)} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_1.png" /> / {chestBoxes[0]?.earnedReward?.nft ?? 0} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_2.png" /></p>
                                                    </TreePopupContent>}
                                                >
                                                    <img id="sweat-box" className="icon-info"
                                                        style={{ height: '16px', cursor: 'pointer' }}
                                                        src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_7.png"
                                                        alt="img_7" />
                                                </Popover>
                                            }
                                        </div>
                                        {
                                            boxStore.totalNftChest1 > 0 ? <NotchedButtonFill
                                                onClick={async () => {
                                                    if (hasApprovedNftContract) {
                                                        // open box now
                                                        togglePopupGetBox("Get Fun box", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_1.gif", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupOpenBox/Box-1-Open.gif", chestBoxes[0])
                                                    }
                                                    else await approveContractNft()
                                                }}
                                                bg="#00E440"
                                                earMaxW="110px"
                                                padding="18px 0">
                                                <TextStyle color="#FFF9E1"
                                                    fontFamily="DynaPuff"
                                                    fontSize="18px"
                                                    textTransform="uppercase">
                                                    {
                                                        hasApprovedNftContract ? `Open ${boxStore.totalNftChest1} airdropped Box${boxStore.totalNftChest1 > 1 ? 'es' : ''}` : (
                                                            loadingApprovedNftContract ? 'Approving...' : 'Approve NFT key'
                                                        )
                                                    }
                                                </TextStyle>
                                            </NotchedButtonFill>
                                                :
                                                <NotchedButtonFill
                                                    onClick={() => {
                                                        togglePopupGetBox("Get Fun box", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_1.gif", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupOpenBox/Box-1-Open.gif", chestBoxes[0])
                                                    }}
                                                    bg="#00E440"
                                                    earMaxW="110px"
                                                    padding="18px 0">
                                                    <TextStyle color="#FFF9E1"
                                                        fontFamily="DynaPuff"
                                                        fontSize="18px"
                                                        textTransform="uppercase">
                                                        {chestBoxes[0].usdbPrice.toString()} ETH / box
                                                    </TextStyle>
                                                </NotchedButtonFill>
                                        }
                                    </div>
                                </ChestBoxContentWrapper>
                            </ChestBoxWrapper>

                            {
                                screenW > 768 && <ChestBoxWrapper>
                                    <ChestBoxRateWrapper>
                                        <img className="rate" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_3.png" alt="" />
                                        <Popover
                                            overlayInnerStyle={{ padding: 0, borderRadius: '6px', border: '2px solid #E5B670' }}
                                            arrow={true}
                                            color="#FFF9E1"
                                            placement="top"
                                            content={<RwTreePopupContent>
                                                <TextOutline fontSize="24px" strokeWidth="1.5px">
                                                    sip box
                                                </TextOutline>
                                                <div className="info-wrapper">
                                                    <TooltipItem>
                                                        <div className="desc-img-wrapper">
                                                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/eth.png" alt="img_1" />
                                                        </div>
                                                        <div className="desc-content-wrapper">
                                                            <TextStyle fontSize="12px" fontWeight="400">
                                                                ETH
                                                            </TextStyle>
                                                            <TextStyle color="#05C63B"
                                                                fontWeight="600"
                                                                lineHeight="125%">
                                                                0.00005 - 0.1
                                                            </TextStyle>
                                                        </div>
                                                    </TooltipItem>
                                                    <TooltipItem>
                                                        <div className="desc-img-wrapper">
                                                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_2.png" alt="img_2" />
                                                        </div>
                                                        <div className="desc-content-wrapper">
                                                            <TextStyle fontSize="12px" fontWeight="400">
                                                                Meow Chronicles NFT
                                                            </TextStyle>
                                                            <TextStyle color="#05C63B"
                                                                fontWeight="600"
                                                                lineHeight="125%">
                                                                1
                                                            </TextStyle>
                                                        </div>
                                                    </TooltipItem>
                                                    <TooltipItem>
                                                        <div className="desc-img-wrapper">
                                                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_3.png" alt="img_3" />
                                                        </div>
                                                        <div className="desc-content-wrapper">
                                                            <TextStyle fontSize="12px" fontWeight="400">
                                                                xGM
                                                            </TextStyle>
                                                            <TextStyle color="#05C63B"
                                                                fontWeight="600"
                                                                lineHeight="125%">
                                                                10 - 50
                                                            </TextStyle>
                                                        </div>
                                                    </TooltipItem>
                                                    <TooltipItem>
                                                        <div className="desc-img-wrapper">
                                                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_4.png" alt="img_4" />
                                                        </div>
                                                        <div className="desc-content-wrapper">
                                                            <TextStyle fontSize="12px" fontWeight="400">
                                                                Ticket
                                                            </TextStyle>
                                                            <TextStyle color="#05C63B"
                                                                fontWeight="600"
                                                                lineHeight="125%">
                                                                x3
                                                            </TextStyle>
                                                        </div>
                                                    </TooltipItem>
                                                </div>
                                            </RwTreePopupContent>}>
                                            <img className="box-info-icon" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_6.png" alt="" />
                                        </Popover>
                                    </ChestBoxRateWrapper>

                                    <ChestBoxContentWrapper>
                                        <div className="wrapper-box">
                                            <img id="sip-box" className="box-style" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_3.gif"
                                                alt="box_1" />
                                        </div>
                                        <div className="content-wrapper">
                                            <div className="desc-wrapper">
                                                <TextStyle fontFamily="DynaPuff"
                                                    fontSize="21px"
                                                    lineHeight="125%"
                                                    textTransform="uppercase">
                                                    sip box
                                                </TextStyle>
                                                {
                                                    account && <Popover
                                                        overlayInnerStyle={{ padding: 0, borderRadius: '6px', border: '2px solid #E5B670' }}
                                                        arrow={true}
                                                        color="#FFF9E1"
                                                        placement="top"
                                                        content={<TreePopupContent>
                                                            <p style={{ marginBottom: '10px', fontSize: '14px' }}>Total Earned: <br /></p>
                                                            <p style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                                {formatNumber(chestBoxes[2]?.earnedReward?.usd ?? 0, 4)} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/eth.png" /> / {formatNumber(chestBoxes[2]?.earnedReward?.xgm ?? 0)} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_1.png" /> / {chestBoxes[2]?.earnedReward?.nft ?? 0} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_2.png" /></p>
                                                        </TreePopupContent>}
                                                    >
                                                        <img id="sweat-box" className="icon-info"
                                                            style={{ height: '16px', cursor: 'pointer' }}
                                                            src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_7.png"
                                                            alt="img_7" />
                                                    </Popover>
                                                }
                                            </div>

                                            {
                                                boxStore.totalNftChest3 > 0 ? <NotchedButtonFill
                                                    onClick={async () => {
                                                        if (hasApprovedNftContract) {
                                                            // open box now
                                                            togglePopupGetBox("Get Sip box", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_3.gif", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupOpenBox/Box-3-Open.gif", chestBoxes[2])
                                                        }
                                                        else await approveContractNft()
                                                    }}
                                                    bg="#00E440"
                                                    earMaxW="110px"
                                                    padding="18px 0">
                                                    <TextStyle color="#FFF9E1"
                                                        fontFamily="DynaPuff"
                                                        fontSize="18px"
                                                        textTransform="uppercase">
                                                        {
                                                            hasApprovedNftContract ? `Open ${boxStore.totalNftChest3} airdropped Box${boxStore.totalNftChest3 > 1 ? 'es' : ''}` : (
                                                                loadingApprovedNftContract ? 'Approving...' : 'Approve NFT key'
                                                            )
                                                        }
                                                    </TextStyle>
                                                </NotchedButtonFill>
                                                    :
                                                    <NotchedButtonFill
                                                        onClick={() => {
                                                            togglePopupGetBox("Get Sip box", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_3.gif", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupOpenBox/Box-3-Open.gif", chestBoxes[2])
                                                        }}
                                                        bg="#00E440"
                                                        earMaxW="110px"
                                                        padding="18px 0">
                                                        <TextStyle color="#FFF9E1"
                                                            fontFamily="DynaPuff"
                                                            fontSize="18px"
                                                            textTransform="uppercase">
                                                            {chestBoxes[2].usdbPrice.toString()} ETH / box
                                                        </TextStyle>
                                                    </NotchedButtonFill>
                                            }

                                        </div>
                                    </ChestBoxContentWrapper>
                                </ChestBoxWrapper>
                            }
                        </Section2ChestCommonWrapper>

                        {
                            screenW > 768 && <Section2ChestSpecialBoxWrapper>
                                <img className="icon-special-box" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_8.png" alt="img_8" />
                                <ChestBoxWrapper>
                                    <ChestBoxRateWrapper>
                                        <img className="rate" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_5.png" alt="img_5" />
                                        <Popover
                                            overlayInnerStyle={{ padding: 0, borderRadius: '6px', border: '2px solid #E5B670' }}
                                            arrow={true}
                                            color="#FFF9E1"
                                            placement="top"
                                            content={<RwTreePopupContent>
                                                <TextOutline fontSize="24px" strokeWidth="1.5px">
                                                    treasure box
                                                </TextOutline>
                                                <div className="info-wrapper">
                                                    <TooltipItem>
                                                        <div className="desc-img-wrapper">
                                                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/eth.png" alt="img_1" />
                                                        </div>
                                                        <div className="desc-content-wrapper">
                                                            <TextStyle fontSize="12px" fontWeight="400">
                                                                ETH
                                                            </TextStyle>
                                                            <TextStyle color="#05C63B"
                                                                fontWeight="600"
                                                                lineHeight="125%">
                                                                0.00005 - 0.3
                                                            </TextStyle>
                                                        </div>
                                                    </TooltipItem>
                                                    <TooltipItem>
                                                        <div className="desc-img-wrapper">
                                                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_2.png" alt="img_2" />
                                                        </div>
                                                        <div className="desc-content-wrapper">
                                                            <TextStyle fontSize="12px" fontWeight="400">
                                                                Meow Chronicles NFT
                                                            </TextStyle>
                                                            <TextStyle color="#05C63B"
                                                                fontWeight="600"
                                                                lineHeight="125%">
                                                                1
                                                            </TextStyle>
                                                        </div>
                                                    </TooltipItem>
                                                    <TooltipItem>
                                                        <div className="desc-img-wrapper">
                                                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_3.png" alt="img_3" />
                                                        </div>
                                                        <div className="desc-content-wrapper">
                                                            <TextStyle fontSize="12px" fontWeight="400">
                                                                xGM
                                                            </TextStyle>
                                                            <TextStyle color="#05C63B"
                                                                fontWeight="600"
                                                                lineHeight="125%">
                                                                10 - 110
                                                            </TextStyle>
                                                        </div>
                                                    </TooltipItem>
                                                    <TooltipItem>
                                                        <div className="desc-img-wrapper">
                                                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_4.png" alt="img_4" />
                                                        </div>
                                                        <div className="desc-content-wrapper">
                                                            <TextStyle fontSize="12px" fontWeight="400">
                                                                Ticket
                                                            </TextStyle>
                                                            <TextStyle color="#05C63B"
                                                                fontWeight="600"
                                                                lineHeight="125%">
                                                                x5
                                                            </TextStyle>
                                                        </div>
                                                    </TooltipItem>
                                                </div>
                                            </RwTreePopupContent>}>
                                            <img className="box-info-icon" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_6.png" alt="" />
                                        </Popover>
                                    </ChestBoxRateWrapper>

                                    <ChestBoxContentWrapper>
                                        <div className="wrapper-box">
                                            <img className="box-style" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_5.gif" alt="box_1" />
                                        </div>
                                        <div className="content-wrapper">
                                            <div className="desc-wrapper">
                                                <TextStyle fontFamily="DynaPuff"
                                                    fontSize="21px"
                                                    lineHeight="125%"
                                                    textTransform="uppercase">
                                                    treasure box
                                                </TextStyle>
                                                {
                                                    account && <Popover
                                                        overlayInnerStyle={{ padding: 0, borderRadius: '6px', border: '2px solid #E5B670' }}
                                                        arrow={true}
                                                        color="#FFF9E1"
                                                        placement="top"
                                                        content={<TreePopupContent>
                                                            <p style={{ marginBottom: '10px', fontSize: '14px' }}>Total Earned: <br /></p>
                                                            <p style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                                {formatNumber(chestBoxes[4]?.earnedReward?.usd ?? 0, 4)} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/eth.png" /> / {formatNumber(chestBoxes[4]?.earnedReward?.xgm ?? 0)} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_1.png" /> / {chestBoxes[4]?.earnedReward?.nft ?? 0} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_2.png" /></p>
                                                        </TreePopupContent>}
                                                    >
                                                        <img id="sweat-box" className="icon-info"
                                                            style={{ height: '16px', cursor: 'pointer' }}
                                                            src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_7.png"
                                                            alt="img_7" />
                                                    </Popover>
                                                }
                                            </div>

                                            {
                                                boxStore.totalNftChest5 > 0 ? <NotchedButtonFill
                                                    onClick={async () => {
                                                        if (hasApprovedNftContract) {
                                                            // open box now
                                                            togglePopupGetBox("Get Treasure box", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_5.gif", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupOpenBox/Box-5-Open.gif", chestBoxes[4])
                                                        }
                                                        else await approveContractNft()
                                                    }}
                                                    bg="#00E440"
                                                    earMaxW="110px"
                                                    padding="18px 0">
                                                    <TextStyle color="#FFF9E1"
                                                        fontFamily="DynaPuff"
                                                        fontSize="18px"
                                                        textTransform="uppercase">
                                                        {
                                                            hasApprovedNftContract ? `Open ${boxStore.totalNftChest5} airdropped Box${boxStore.totalNftChest5 > 1 ? 'es' : ''}` : (
                                                                loadingApprovedNftContract ? 'Approving...' : 'Approve NFT key'
                                                            )
                                                        }
                                                    </TextStyle>
                                                </NotchedButtonFill>
                                                    :
                                                    <NotchedButtonFill
                                                        onClick={() => {
                                                            togglePopupGetBox("Get Treasure box", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_5.gif", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupOpenBox/Box-5-Open.gif", chestBoxes[4])
                                                        }}
                                                        bg="#00E440"
                                                        earMaxW="110px"
                                                        padding="18px 0">
                                                        <TextStyle color="#FFF9E1"
                                                            fontFamily="DynaPuff"
                                                            fontSize="18px"
                                                            textTransform="uppercase">
                                                            {chestBoxes[4].usdbPrice.toString()} ETH / box
                                                        </TextStyle>
                                                    </NotchedButtonFill>
                                            }
                                        </div>
                                    </ChestBoxContentWrapper>
                                </ChestBoxWrapper>
                                <img className="icon-special-box" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_9.png" alt="img_9" />
                            </Section2ChestSpecialBoxWrapper>
                        }


                        <Section2ChestCommonWrapper>
                            <ChestBoxWrapper>
                                <ChestBoxRateWrapper>
                                    <img className="rate" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_2.png" alt="img_2" />
                                    <Popover
                                        overlayInnerStyle={{ padding: 0, borderRadius: '6px', border: '2px solid #E5B670' }}
                                        arrow={true}
                                        color="#FFF9E1"
                                        placement="top"
                                        content={<RwTreePopupContent>
                                            <TextOutline fontSize="24px" strokeWidth="1.5px">
                                                joy box
                                            </TextOutline>
                                            <div className="info-wrapper">
                                                <TooltipItem>
                                                    <div className="desc-img-wrapper">
                                                        <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/eth.png" alt="img_1" />
                                                    </div>
                                                    <div className="desc-content-wrapper">
                                                        <TextStyle fontSize="12px" fontWeight="400">
                                                            ETH
                                                        </TextStyle>
                                                        <TextStyle color="#05C63B"
                                                            fontWeight="600"
                                                            lineHeight="125%">
                                                            0.00005 - 0.05
                                                        </TextStyle>
                                                    </div>
                                                </TooltipItem>
                                                <TooltipItem>
                                                    <div className="desc-img-wrapper">
                                                        <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_2.png" alt="img_2" />
                                                    </div>
                                                    <div className="desc-content-wrapper">
                                                        <TextStyle fontSize="12px" fontWeight="400">
                                                            Meow Chronicles NFT
                                                        </TextStyle>
                                                        <TextStyle color="#05C63B"
                                                            fontWeight="600"
                                                            lineHeight="125%">
                                                            1
                                                        </TextStyle>
                                                    </div>
                                                </TooltipItem>
                                                <TooltipItem>
                                                    <div className="desc-img-wrapper">
                                                        <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_3.png" alt="img_3" />
                                                    </div>
                                                    <div className="desc-content-wrapper">
                                                        <TextStyle fontSize="12px" fontWeight="400">
                                                            xGM
                                                        </TextStyle>
                                                        <TextStyle color="#05C63B"
                                                            fontWeight="600"
                                                            lineHeight="125%">
                                                            10 - 30
                                                        </TextStyle>
                                                    </div>
                                                </TooltipItem>
                                                <TooltipItem>
                                                    <div className="desc-img-wrapper">
                                                        <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_4.png" alt="img_4" />
                                                    </div>
                                                    <div className="desc-content-wrapper">
                                                        <TextStyle fontSize="12px" fontWeight="400">
                                                            Ticket
                                                        </TextStyle>
                                                        <TextStyle color="#05C63B"
                                                            fontWeight="600"
                                                            lineHeight="125%">
                                                            x2
                                                        </TextStyle>
                                                    </div>
                                                </TooltipItem>
                                            </div>
                                        </RwTreePopupContent>}>
                                        <img className="box-info-icon" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_6.png" alt="" />
                                    </Popover>
                                </ChestBoxRateWrapper>

                                <ChestBoxContentWrapper>
                                    <div className="wrapper-box">
                                        <img className="box-style" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_2.gif" alt="box_1" />
                                    </div>
                                    <div className="content-wrapper">
                                        <div className="desc-wrapper">
                                            <TextStyle fontFamily="DynaPuff"
                                                fontSize="21px"
                                                lineHeight="125%"
                                                textTransform="uppercase">
                                                joy box
                                            </TextStyle>
                                            {
                                                account && <Popover
                                                    overlayInnerStyle={{ padding: 0, borderRadius: '6px', border: '2px solid #E5B670' }}
                                                    arrow={true}
                                                    color="#FFF9E1"
                                                    placement="top"
                                                    content={<TreePopupContent>
                                                        <p style={{ marginBottom: '10px', fontSize: '14px' }}>Total Earned: <br /></p>
                                                        <p style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                            {formatNumber(chestBoxes[1]?.earnedReward?.usd ?? 0, 4)} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/eth.png" /> / {formatNumber(chestBoxes[1]?.earnedReward?.xgm ?? 0)} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_1.png" /> / {chestBoxes[1]?.earnedReward?.nft ?? 0} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_2.png" /></p>
                                                    </TreePopupContent>}
                                                >
                                                    <img id="sweat-box" className="icon-info"
                                                        style={{ height: '16px', cursor: 'pointer' }}
                                                        src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_7.png"
                                                        alt="img_7" />
                                                </Popover>
                                            }
                                        </div>

                                        {
                                            boxStore.totalNftChest2 > 0 ? <NotchedButtonFill
                                                onClick={async () => {
                                                    if (hasApprovedNftContract) {
                                                        // open box now
                                                        togglePopupGetBox("Get Joy box", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_2.gif", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupOpenBox/Box-2-Open.gif", chestBoxes[1])
                                                    }
                                                    else await approveContractNft()
                                                }}
                                                bg="#00E440"
                                                earMaxW="110px"
                                                padding="18px 0">
                                                <TextStyle color="#FFF9E1"
                                                    fontFamily="DynaPuff"
                                                    fontSize="18px"
                                                    textTransform="uppercase">
                                                    {
                                                        hasApprovedNftContract ? `Open ${boxStore.totalNftChest2} airdropped Box${boxStore.totalNftChest2 > 1 ? 'es' : ''}` : (
                                                            loadingApprovedNftContract ? 'Approving...' : 'Approve NFT key'
                                                        )
                                                    }
                                                </TextStyle>
                                            </NotchedButtonFill>
                                                :
                                                <NotchedButtonFill
                                                    onClick={() => {
                                                        togglePopupGetBox("Get Joy box", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_2.gif", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupOpenBox/Box-2-Open.gif", chestBoxes[1])
                                                    }}
                                                    bg="#00E440"
                                                    earMaxW="110px"
                                                    padding="18px 0">
                                                    <TextStyle color="#FFF9E1"
                                                        fontFamily="DynaPuff"
                                                        fontSize="18px"
                                                        textTransform="uppercase">
                                                        {chestBoxes[1].usdbPrice.toString()} ETH / box
                                                    </TextStyle>
                                                </NotchedButtonFill>
                                        }
                                    </div>
                                </ChestBoxContentWrapper>
                            </ChestBoxWrapper>
                            {
                                screenW <= 768 && <ChestBoxWrapper>
                                    <ChestBoxRateWrapper>
                                        <img className="rate" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_3.png" alt="" />
                                        <Popover
                                            overlayInnerStyle={{ padding: 0, borderRadius: '6px', border: '2px solid #E5B670' }}
                                            arrow={true}
                                            color="#FFF9E1"
                                            placement="top"
                                            content={<RwTreePopupContent>
                                                <TextOutline fontSize="24px" strokeWidth="1.5px">
                                                    sip box
                                                </TextOutline>
                                                <div className="info-wrapper">
                                                    <TooltipItem>
                                                        <div className="desc-img-wrapper">
                                                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/eth.png" alt="img_1" />
                                                        </div>
                                                        <div className="desc-content-wrapper">
                                                            <TextStyle fontSize="12px" fontWeight="400">
                                                                ETH
                                                            </TextStyle>
                                                            <TextStyle color="#05C63B"
                                                                fontWeight="600"
                                                                lineHeight="125%">
                                                                0.00005 - 0.1
                                                            </TextStyle>
                                                        </div>
                                                    </TooltipItem>
                                                    <TooltipItem>
                                                        <div className="desc-img-wrapper">
                                                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_2.png" alt="img_2" />
                                                        </div>
                                                        <div className="desc-content-wrapper">
                                                            <TextStyle fontSize="12px" fontWeight="400">
                                                                Meow Chronicles NFT
                                                            </TextStyle>
                                                            <TextStyle color="#05C63B"
                                                                fontWeight="600"
                                                                lineHeight="125%">
                                                                1
                                                            </TextStyle>
                                                        </div>
                                                    </TooltipItem>
                                                    <TooltipItem>
                                                        <div className="desc-img-wrapper">
                                                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_3.png" alt="img_3" />
                                                        </div>
                                                        <div className="desc-content-wrapper">
                                                            <TextStyle fontSize="12px" fontWeight="400">
                                                                xGM
                                                            </TextStyle>
                                                            <TextStyle color="#05C63B"
                                                                fontWeight="600"
                                                                lineHeight="125%">
                                                                10 - 50
                                                            </TextStyle>
                                                        </div>
                                                    </TooltipItem>
                                                    <TooltipItem>
                                                        <div className="desc-img-wrapper">
                                                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_4.png" alt="img_4" />
                                                        </div>
                                                        <div className="desc-content-wrapper">
                                                            <TextStyle fontSize="12px" fontWeight="400">
                                                                Ticket
                                                            </TextStyle>
                                                            <TextStyle color="#05C63B"
                                                                fontWeight="600"
                                                                lineHeight="125%">
                                                                x3
                                                            </TextStyle>
                                                        </div>
                                                    </TooltipItem>
                                                </div>
                                            </RwTreePopupContent>}>
                                            <img className="box-info-icon" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_6.png" alt="" />
                                        </Popover>
                                    </ChestBoxRateWrapper>

                                    <ChestBoxContentWrapper>
                                        <div className="wrapper-box">
                                            <img id="sip-box" className="box-style" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_3.gif"
                                                alt="box_1" />
                                        </div>
                                        <div className="content-wrapper">
                                            <div className="desc-wrapper">
                                                <TextStyle fontFamily="DynaPuff"
                                                    fontSize="21px"
                                                    lineHeight="125%"
                                                    textTransform="uppercase">
                                                    sip box
                                                </TextStyle>
                                                {
                                                    account && <Popover
                                                        overlayInnerStyle={{ padding: 0, borderRadius: '6px', border: '2px solid #E5B670' }}
                                                        arrow={true}
                                                        color="#FFF9E1"
                                                        placement="top"
                                                        content={<TreePopupContent>
                                                            <p style={{ marginBottom: '10px', fontSize: '14px' }}>Total Earned: <br /></p>
                                                            <p style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                                {formatNumber(chestBoxes[2]?.earnedReward?.usd ?? 0, 4)} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/eth.png" /> / {formatNumber(chestBoxes[2]?.earnedReward?.xgm ?? 0)} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_1.png" /> / {chestBoxes[2]?.earnedReward?.nft ?? 0} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_2.png" /></p>
                                                        </TreePopupContent>}
                                                    >
                                                        <img id="sweat-box" className="icon-info"
                                                            style={{ height: '16px', cursor: 'pointer' }}
                                                            src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_7.png"
                                                            alt="img_7" />
                                                    </Popover>
                                                }
                                            </div>

                                            {
                                                boxStore.totalNftChest3 > 0 ? <NotchedButtonFill
                                                    onClick={async () => {
                                                        if (hasApprovedNftContract) {
                                                            // open box now
                                                            togglePopupGetBox("Get Sip box", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_3.gif", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupOpenBox/Box-3-Open.gif", chestBoxes[2])
                                                        }
                                                        else await approveContractNft()
                                                    }}
                                                    bg="#00E440"
                                                    earMaxW="110px"
                                                    padding="18px 0">
                                                    <TextStyle color="#FFF9E1"
                                                        fontFamily="DynaPuff"
                                                        fontSize="18px"
                                                        textTransform="uppercase">
                                                        {
                                                            hasApprovedNftContract ? `Open ${boxStore.totalNftChest3} airdropped Box${boxStore.totalNftChest3 > 1 ? 'es' : ''}` : (
                                                                loadingApprovedNftContract ? 'Approving...' : 'Approve NFT key'
                                                            )
                                                        }
                                                    </TextStyle>
                                                </NotchedButtonFill>
                                                    :
                                                    <NotchedButtonFill
                                                        onClick={() => {
                                                            togglePopupGetBox("Get Sip box", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_3.gif", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupOpenBox/Box-3-Open.gif", chestBoxes[2])
                                                        }}
                                                        bg="#00E440"
                                                        earMaxW="110px"
                                                        padding="18px 0">
                                                        <TextStyle color="#FFF9E1"
                                                            fontFamily="DynaPuff"
                                                            fontSize="18px"
                                                            textTransform="uppercase">
                                                            {chestBoxes[2].usdbPrice.toString()} ETH / box
                                                        </TextStyle>
                                                    </NotchedButtonFill>
                                            }

                                        </div>
                                    </ChestBoxContentWrapper>
                                </ChestBoxWrapper>
                            }

                            <ChestBoxWrapper>
                                <ChestBoxRateWrapper>
                                    <img className="rate" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_4.png" alt="" />
                                    <Popover
                                        overlayInnerStyle={{ padding: 0, borderRadius: '6px', border: '2px solid #E5B670' }}
                                        arrow={true}
                                        color="#FFF9E1"
                                        placement="top"
                                        content={<RwTreePopupContent>
                                            <TextOutline fontSize="24px" strokeWidth="1.5px">
                                                sweat box
                                            </TextOutline>
                                            <div className="info-wrapper">
                                                <TooltipItem>
                                                    <div className="desc-img-wrapper">
                                                        <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/eth.png" alt="img_1" />
                                                    </div>
                                                    <div className="desc-content-wrapper">
                                                        <TextStyle fontSize="12px" fontWeight="400">
                                                            ETH
                                                        </TextStyle>
                                                        <TextStyle color="#05C63B"
                                                            fontWeight="600"
                                                            lineHeight="125%">
                                                            0.00005 - 0.2
                                                        </TextStyle>
                                                    </div>
                                                </TooltipItem>
                                                <TooltipItem>
                                                    <div className="desc-img-wrapper">
                                                        <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_2.png" alt="img_2" />
                                                    </div>
                                                    <div className="desc-content-wrapper">
                                                        <TextStyle fontSize="12px" fontWeight="400">
                                                            Meow Chronicles NFT
                                                        </TextStyle>
                                                        <TextStyle color="#05C63B"
                                                            fontWeight="600"
                                                            lineHeight="125%">
                                                            1
                                                        </TextStyle>
                                                    </div>
                                                </TooltipItem>
                                                <TooltipItem>
                                                    <div className="desc-img-wrapper">
                                                        <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_3.png" alt="img_3" />
                                                    </div>
                                                    <div className="desc-content-wrapper">
                                                        <TextStyle fontSize="12px" fontWeight="400">
                                                            xGM
                                                        </TextStyle>
                                                        <TextStyle color="#05C63B"
                                                            fontWeight="600"
                                                            lineHeight="125%">
                                                            10 - 90
                                                        </TextStyle>
                                                    </div>
                                                </TooltipItem>
                                                <TooltipItem>
                                                    <div className="desc-img-wrapper">
                                                        <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_4.png" alt="img_4" />
                                                    </div>
                                                    <div className="desc-content-wrapper">
                                                        <TextStyle fontSize="12px" fontWeight="400">
                                                            Ticket
                                                        </TextStyle>
                                                        <TextStyle color="#05C63B"
                                                            fontWeight="600"
                                                            lineHeight="125%">
                                                            x4
                                                        </TextStyle>
                                                    </div>
                                                </TooltipItem>
                                            </div>
                                        </RwTreePopupContent>}>
                                        <img className="box-info-icon" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_6.png" alt="" />
                                    </Popover>
                                </ChestBoxRateWrapper>

                                <ChestBoxContentWrapper>
                                    <div className="wrapper-box">
                                        <img className="box-style" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_4.gif" alt="box_1" />
                                    </div>
                                    <div className="content-wrapper">
                                        <div className="desc-wrapper">
                                            <TextStyle fontFamily="DynaPuff"
                                                fontSize="21px"
                                                lineHeight="125%"
                                                textTransform="uppercase">
                                                sweat box
                                            </TextStyle>
                                            {
                                                account && <Popover
                                                    overlayInnerStyle={{ padding: 0, borderRadius: 0 }}
                                                    arrow={true}
                                                    color="#FFF9E1"
                                                    placement="top"
                                                    content={<TreePopupContent>
                                                        <p style={{ marginBottom: '10px', fontSize: '14px' }}>Total Earned: <br /></p>
                                                        <p style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                            {formatNumber(chestBoxes[3]?.earnedReward?.usd ?? 0)} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/eth.png" /> / {formatNumber(chestBoxes[3]?.earnedReward?.xgm ?? 0)} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_1.png" /> / {chestBoxes[3]?.earnedReward?.nft ?? 0} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_2.png" /></p>
                                                    </TreePopupContent>}
                                                >
                                                    <img id="sweat-box" className="icon-info"
                                                        style={{ height: '16px', cursor: 'pointer' }}
                                                        src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_7.png"
                                                        alt="img_7" />
                                                </Popover>
                                            }
                                        </div>

                                        {
                                            boxStore.totalNftChest4 > 0 ? <NotchedButtonFill
                                                onClick={async () => {
                                                    if (hasApprovedNftContract) {
                                                        // open box now
                                                        togglePopupGetBox("Get Sweat box", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_4.gif", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupOpenBox/Box-4-Open.gif", chestBoxes[3])
                                                    }
                                                    else await approveContractNft()
                                                }}
                                                bg="#00E440"
                                                earMaxW="110px"
                                                padding="18px 0">
                                                <TextStyle color="#FFF9E1"
                                                    fontFamily="DynaPuff"
                                                    fontSize="18px"
                                                    textTransform="uppercase">
                                                    {
                                                        hasApprovedNftContract ? `Open ${boxStore.totalNftChest4} airdropped Box${boxStore.totalNftChest4 > 1 ? 'es' : ''}` : (
                                                            loadingApprovedNftContract ? 'Approving...' : 'Approve NFT key'
                                                        )
                                                    }
                                                </TextStyle>
                                            </NotchedButtonFill>
                                                :
                                                <NotchedButtonFill
                                                    onClick={() => {
                                                        togglePopupGetBox("Get Sweat box", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_4.gif", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupOpenBox/Box-4-Open.gif", chestBoxes[3])
                                                    }}
                                                    bg="#00E440"
                                                    earMaxW="110px"
                                                    padding="18px 0">
                                                    <TextStyle color="#FFF9E1"
                                                        fontFamily="DynaPuff"
                                                        fontSize="18px"
                                                        textTransform="uppercase">
                                                        {chestBoxes[3].usdbPrice.toString()} ETH / box
                                                    </TextStyle>
                                                </NotchedButtonFill>
                                        }
                                    </div>
                                </ChestBoxContentWrapper>
                            </ChestBoxWrapper>
                        </Section2ChestCommonWrapper>

                        {
                            screenW <= 768 && <Section2ChestSpecialBoxWrapper>
                                <img className="icon-special-box" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_8.png" alt="img_8" />
                                <ChestBoxWrapper>
                                    <ChestBoxRateWrapper>
                                        <img className="rate" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_5.png" alt="img_5" />
                                        <Popover
                                            overlayInnerStyle={{ padding: 0, borderRadius: '6px', border: '2px solid #E5B670' }}
                                            arrow={true}
                                            color="#FFF9E1"
                                            placement="top"
                                            content={<RwTreePopupContent>
                                                <TextOutline fontSize="24px" strokeWidth="1.5px">
                                                    treasure box
                                                </TextOutline>
                                                <div className="info-wrapper">
                                                    <TooltipItem>
                                                        <div className="desc-img-wrapper">
                                                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/eth.png" alt="img_1" />
                                                        </div>
                                                        <div className="desc-content-wrapper">
                                                            <TextStyle fontSize="12px" fontWeight="400">
                                                                ETH
                                                            </TextStyle>
                                                            <TextStyle color="#05C63B"
                                                                fontWeight="600"
                                                                lineHeight="125%">
                                                                0.00005 - 0.3
                                                            </TextStyle>
                                                        </div>
                                                    </TooltipItem>
                                                    <TooltipItem>
                                                        <div className="desc-img-wrapper">
                                                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_2.png" alt="img_2" />
                                                        </div>
                                                        <div className="desc-content-wrapper">
                                                            <TextStyle fontSize="12px" fontWeight="400">
                                                                Meow Chronicles NFT
                                                            </TextStyle>
                                                            <TextStyle color="#05C63B"
                                                                fontWeight="600"
                                                                lineHeight="125%">
                                                                1
                                                            </TextStyle>
                                                        </div>
                                                    </TooltipItem>
                                                    <TooltipItem>
                                                        <div className="desc-img-wrapper">
                                                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_3.png" alt="img_3" />
                                                        </div>
                                                        <div className="desc-content-wrapper">
                                                            <TextStyle fontSize="12px" fontWeight="400">
                                                                xGM
                                                            </TextStyle>
                                                            <TextStyle color="#05C63B"
                                                                fontWeight="600"
                                                                lineHeight="125%">
                                                                10 - 110
                                                            </TextStyle>
                                                        </div>
                                                    </TooltipItem>
                                                    <TooltipItem>
                                                        <div className="desc-img-wrapper">
                                                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_4.png" alt="img_4" />
                                                        </div>
                                                        <div className="desc-content-wrapper">
                                                            <TextStyle fontSize="12px" fontWeight="400">
                                                                Ticket
                                                            </TextStyle>
                                                            <TextStyle color="#05C63B"
                                                                fontWeight="600"
                                                                lineHeight="125%">
                                                                x5
                                                            </TextStyle>
                                                        </div>
                                                    </TooltipItem>
                                                </div>
                                            </RwTreePopupContent>}>
                                            <img className="box-info-icon" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_6.png" alt="" />
                                        </Popover>
                                    </ChestBoxRateWrapper>

                                    <ChestBoxContentWrapper>
                                        <div className="wrapper-box">
                                            <img className="box-style" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_5.gif" alt="box_1" />
                                        </div>
                                        <div className="content-wrapper">
                                            <div className="desc-wrapper">
                                                <TextStyle fontFamily="DynaPuff"
                                                    fontSize="21px"
                                                    lineHeight="125%"
                                                    textTransform="uppercase">
                                                    treasure box
                                                </TextStyle>
                                                {
                                                    account && <Popover
                                                        overlayInnerStyle={{ padding: 0, borderRadius: '6px', border: '2px solid #E5B670' }}
                                                        arrow={true}
                                                        color="#FFF9E1"
                                                        placement="top"
                                                        content={<TreePopupContent>
                                                            <p style={{ marginBottom: '10px', fontSize: '14px' }}>Total Earned: <br /></p>
                                                            <p style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                                {formatNumber(chestBoxes[4]?.earnedReward?.usd ?? 0, 4)} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/eth.png" /> / {formatNumber(chestBoxes[4]?.earnedReward?.xgm ?? 0)} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_1.png" /> / {chestBoxes[4]?.earnedReward?.nft ?? 0} <img className='earned_img' src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_2.png" /></p>
                                                        </TreePopupContent>}
                                                    >
                                                        <img id="sweat-box" className="icon-info"
                                                            style={{ height: '16px', cursor: 'pointer' }}
                                                            src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_7.png"
                                                            alt="img_7" />
                                                    </Popover>
                                                }
                                            </div>

                                            {
                                                boxStore.totalNftChest5 > 0 ? <NotchedButtonFill
                                                    onClick={async () => {
                                                        if (hasApprovedNftContract) {
                                                            // open box now
                                                            togglePopupGetBox("Get Treasure box", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_5.gif", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupOpenBox/Box-5-Open.gif", chestBoxes[4])
                                                        }
                                                        else await approveContractNft()
                                                    }}
                                                    bg="#00E440"
                                                    earMaxW="110px"
                                                    padding="18px 0">
                                                    <TextStyle color="#FFF9E1"
                                                        fontFamily="DynaPuff"
                                                        fontSize="18px"
                                                        textTransform="uppercase">
                                                        {
                                                            hasApprovedNftContract ? `Open ${boxStore.totalNftChest5} airdropped Box${boxStore.totalNftChest5 > 1 ? 'es' : ''}` : (
                                                                loadingApprovedNftContract ? 'Approving...' : 'Approve NFT key'
                                                            )
                                                        }
                                                    </TextStyle>
                                                </NotchedButtonFill>
                                                    :
                                                    <NotchedButtonFill
                                                        onClick={() => {
                                                            togglePopupGetBox("Get Treasure box", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_5.gif", "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupOpenBox/Box-5-Open.gif", chestBoxes[4])
                                                        }}
                                                        bg="#00E440"
                                                        earMaxW="110px"
                                                        padding="18px 0">
                                                        <TextStyle color="#FFF9E1"
                                                            fontFamily="DynaPuff"
                                                            fontSize="18px"
                                                            textTransform="uppercase">
                                                            {chestBoxes[4].usdbPrice.toString()} ETH / box
                                                        </TextStyle>
                                                    </NotchedButtonFill>
                                            }
                                        </div>
                                    </ChestBoxContentWrapper>
                                </ChestBoxWrapper>
                                <img className="icon-special-box" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img_9.png" alt="img_9" />
                            </Section2ChestSpecialBoxWrapper>
                        }
                    </Section2ChestWrapper>
                </Section2 >
                {
                    account && <ChestHistoryTable />
                }
                <img className="line-star" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/img.png" alt="img" />
                <Section4>
                    <div className="left-img-wrapper">
                        <img className="left-img" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img.png" alt="img" />
                    </div>
                    <div className="right-content-wrapper">
                        <div className="section2-title">
                            <TextStyle fontSize="36px"
                                fontSizeLg="24px"
                                fontSizeMb="20px"
                                lineHeight="125%"
                                textTransform="uppercase"
                                fontFamily="DynaPuff">
                                why get boxes of cats?
                            </TextStyle>
                            <TextOutline fontSizeLg="26px" fontSizeMb="22px">
                                what’s in it?
                            </TextOutline>
                        </div>
                        <div className="info-wrapper">
                            <DescItem>
                                <div className="desc-img-wrapper">
                                    <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_1.png" alt="img_1" />
                                </div>
                                <div className="desc-content-wrapper">
                                    <TextStyle fontSize="24px" fontWeight="400">
                                        PAW
                                    </TextStyle>
                                    <TextStyle color="#93553A"
                                        lineHeight="125%">
                                        Just another token with an auto-yield rebasing mechanism.
                                    </TextStyle>
                                </div>
                            </DescItem>
                            <DescItem>
                                <div className="desc-img-wrapper">
                                    <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_2.png" alt="img_2" />
                                </div>
                                <div className="desc-content-wrapper">
                                    <TextStyle fontSize="24px" fontWeight="400">
                                        Meow Chronicles NFT
                                    </TextStyle>
                                    <TextStyle color="#93553A"
                                        lineHeight="125%">
                                        Your way to unlock all Gmeow’s exclusive privileges and unlimited boxes.
                                    </TextStyle>
                                </div>
                            </DescItem>
                            <DescItem>
                                <div className="desc-img-wrapper">
                                    <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_3.png" alt="img_3" />
                                </div>
                                <div className="desc-content-wrapper">
                                    <TextStyle fontSize="24px" fontWeight="400">
                                        xGM
                                    </TextStyle>
                                    <TextStyle color="#93553A"
                                        lineHeight="125%">
                                        Earn real yield in ETH from Gmeow’s protocol earning.
                                    </TextStyle>
                                </div>
                            </DescItem>
                            <DescItem>
                                <div className="desc-img-wrapper">
                                    <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_4.png" alt="img_4" />
                                </div>
                                <div className="desc-content-wrapper">
                                    <TextStyle fontSize="24px" fontWeight="400">
                                        Gmeow Tickets
                                    </TextStyle>
                                    <TextStyle color="#93553A"
                                        lineHeight="125%">
                                        Join Gmeow’s Lottery for free. What a deal, right?
                                    </TextStyle>
                                </div>
                            </DescItem>
                        </div>
                    </div>
                </Section4>
                <div className="cat-icon">
                    <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/img_1.png" alt="img_1" />
                </div>
            </SectionWrapper >
        </>
    )
}
export const numberWithCommas = (number: any) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
