import { Trans } from '@lingui/macro'
import { sendAnalyticsEvent, Trace, TraceEvent } from '@uniswap/analytics'
import { BrowserEvent, ElementName, EventName, PageName, SectionName } from '@uniswap/analytics-events'
import { PERMIT2_ADDRESS } from '@uniswap/permit2-sdk'
import { Trade } from '@uniswap/router-sdk'
import { Currency, CurrencyAmount, Percent, Token, TradeType } from '@uniswap/sdk-core'
import { UNIVERSAL_ROUTER_ADDRESS } from '@uniswap/universal-router-sdk'
import { useWeb3React } from '@web3-react/core'
import { sendEvent } from 'components/analytics'
import PriceImpactWarning from 'components/swap/PriceImpactWarning'
import SwapDetailsDropdown from 'components/swap/SwapDetailsDropdown'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import TokenSafetyModal from 'components/TokenSafety/TokenSafetyModal'
import { MouseoverTooltip } from 'components/Tooltip'
import { isSupportedChain } from 'constants/chains'
import { usePermit2Enabled } from 'featureFlags/flags/permit2'
import usePermit, { PermitState } from 'hooks/usePermit2'
import { useSwapCallback } from 'hooks/useSwapCallback'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import JSBI from 'jsbi'
import { formatSwapQuoteReceivedEventProperties } from 'lib/utils/analytics'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ReactNode } from 'react'
import { AlertTriangle, ArrowDown, CheckCircle, HelpCircle, Info, BarChart2, Gift } from 'react-feather'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Text } from 'rebass'
import { useToggleWalletModal } from 'state/application/hooks'
import { InterfaceTrade } from 'state/routing/types'
import { TradeState } from 'state/routing/types'
import { useHasPendingApproval, useTransactionAdder } from 'state/transactions/hooks'
import styled, { useTheme } from 'styled-components/macro'
import { currencyAmountToPreciseFloat, formatNumber, formatTransactionAmount } from 'utils/formatNumbers'
import './swap.css'
import { BREAKPOINTS, ExternalLink } from '../../theme'
import AddressInputPanel from '../../components/AddressInputPanel'
import {
    ButtonConfirmed,
    ButtonError,
    ButtonLight,
    ButtonPrimary,
    ButtonYellow,
    NotchedButtonFillWrapper,
    NotchedButtonFill,
    NotchedButtonFillPrimary,
} from '../../components/Button'
import { GrayCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import SwapCurrencyInputPanel from '../../components/CurrencyInputPanel/SwapCurrencyInputPanel'
import Loader from '../../components/Loader'
import { AutoRow, RowBetween } from '../../components/Row'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import { ArrowWrapper, PageWrapper, SwapCallbackError, SwapWrapper } from '../../components/swap/styleds'
import SwapHeader from '../../components/swap/SwapHeader'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import { TOKEN_SHORTHANDS } from '../../constants/tokens'
import { useAllTokens, useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useERC20PermitFromTrade, UseERC20PermitState } from '../../hooks/useERC20Permit'
import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import { useStablecoinValue } from '../../hooks/useStablecoinPrice'
import useWrapCallback, { WrapErrorText, WrapType } from '../../hooks/useWrapCallback'
import { Field } from '../../state/swap/actions'
import {
    useDefaultsFromURLSearch,
    useDerivedSwapInfo,
    useSwapActionHandlers,
    useSwapState,
} from '../../state/swap/hooks'
import { useExpertModeManager } from '../../state/user/hooks'
import { LinkStyledButton, ThemedText } from '../../theme'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeRealizedPriceImpact, warningSeverity } from '../../utils/prices'
import { supportedChainId } from '../../utils/supportedChainId'
import useCopyClipboard from 'hooks/useCopyClipboard'

// faucet
import { useCustomeContract } from 'hooks/useContract'
import { BLAST_SEPOLIA_TEST_MULTIL_FAUCET_ADDRESSES } from '../../constants/addresses'
import multifaucet_abi from '../../abis/faucet/multifaucet.json'
import axios, { Method } from 'axios'
import ClaimETHModal from 'components/swap/ClaimETHModal'
import ClaimMonoToken from 'components/swap/ClaimMonoToken'
import IconButton from '../../components/WalletDropdown/IconButton'
import { Copy } from 'react-feather'
import { useParams } from 'react-router-dom'
import SwapV2 from 'pages/SwapV2'
import CoinGeckoChart from 'components/Musd/CoinGeckoChart'

import { trendingPair } from '../../constants/poolListData'
import { DoubleLogo } from 'components/DoubleLogo'

const swaptop = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/swap/swaptop.png'
const reload = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/swap/reload.png'
const arrowswap = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/swap/arrowswap.png'
const ethCoin = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/swap/eth.png'
const blastToken =
    'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/swap/swaptoken.png'

import { message, notification, Tooltip } from 'antd'
import TradePrice from 'components/swap/TradePrice'
import Faucet from "./faucet/Faucet";
import {
    BannerImageWrapper,
    InformationContainer,
    InformationWrapper,
    SpanOutlined,
    TextStyle
} from "./components/Components";
import { useAlphaStore } from 'hooks/useAlphaStore'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'

const ArrowContainer = styled.div`
    display: inline-block;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
`

const SwapSection = styled.div`
    position: relative;
    background: transparent;
    color: ${({ theme }) => theme.textSecondary};
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;

    &:before {
        box-sizing: border-box;
        background-size: 100%;

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        pointer-events: none;
        content: '';
    }

    &:hover:before {
    }

    &:focus-within:before {
    }
`
const ArrowSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    /* margin-top: 50px; */

    .convert-box {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        display: flex;
        align-items: flex-start;
    }
    .insufficient-box{
        color: #F00;
        text-align: right;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 20px */
        position: absolute;
        top: 0;
        right: 0;
        padding-top: 8px;
        padding-right: 12px;
    }
    .insufficient-box.mobile{
        display: none;
    }
    @media screen and (max-width: 600px) {
        .convert-box, .insufficient-box {
            position: relative;
        }
        .insufficient-box{
            display: none;
        }
        .insufficient-box.mobile{
            display: block;
            padding-top: 0px;
        }
        padding-top: 12px;
        display: grid;
        justify-items: center
    }
`

const OutputSwapSection = styled(SwapSection) <{ showDetailsDropdown: boolean }>`
    border-bottom-left-radius: ${({ showDetailsDropdown }) => showDetailsDropdown && '0'};
    border-bottom-right-radius: ${({ showDetailsDropdown }) => showDetailsDropdown && '0'};
`

const DetailsSwapWrapper = styled.div`
    border-radius: 10px;
    background: #FFF9E1;
    padding: 24px;
    display: grid;
    gap: 16px;
`
const DetailsSwapSection = styled(SwapSection)`
    padding: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
`

export function getIsValidSwapQuote(
    trade: InterfaceTrade<Currency, Currency, TradeType> | undefined,
    tradeState: TradeState,
    swapInputError?: ReactNode
): boolean {
    return !!swapInputError && !!trade && (tradeState === TradeState.VALID || tradeState === TradeState.SYNCING)
}

interface HandleAddCustomTokenParams {
    symbol: string
    contractAddress: string
    decimals: number
    tokenLogoURL: string
}

interface HandleAddCustomTokenFunc {
    (params: HandleAddCustomTokenParams): Promise<void>
}

function largerPercentValue(a?: Percent, b?: Percent) {
    if (a && b) {
        return a.greaterThan(b) ? a : b
    } else if (a) {
        return a
    } else if (b) {
        return b
    }
    return undefined
}

const TRADE_STRING = 'SwapRouter'

interface Invitee {
    userAddress: string
    referralRewards: string | number
}

const useGetPairSelected = (item: any) => {
    const pairSelect = item[Field.INPUT]?.symbol + item[Field.OUTPUT]?.symbol
    return pairSelect
}

function findPairAddressBySelect(pairSelect: any) {
    const pair = trendingPair?.v3?.find((pair) => pair?.pairSelect1 === pairSelect || pair?.pairSelect2 === pairSelect)
    return pair ? pair?.pairAddress?.address : null
}

export default function Swap({ className }: { className?: string }) {
    const navigate = useNavigate()
    const { account, chainId, connector } = useWeb3React()
    const loadedUrlParams = useDefaultsFromURLSearch()
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const [newSwapQuoteNeedsLogging, setNewSwapQuoteNeedsLogging] = useState(true)
    const [fetchingSwapQuoteStartTime, setFetchingSwapQuoteStartTime] = useState<Date | undefined>()
    const [isCopied, setCopied] = useCopyClipboard()

    // token warning stuff
    const [loadedInputCurrency, loadedOutputCurrency] = [
        useCurrency(loadedUrlParams?.[Field.INPUT]?.currencyId),
        useCurrency(loadedUrlParams?.[Field.OUTPUT]?.currencyId),
    ]
    const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
    const urlLoadedTokens: Token[] = useMemo(
        () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c?.isToken ?? false) ?? [],
        [loadedInputCurrency, loadedOutputCurrency]
    )
    const handleConfirmTokenWarning = useCallback(() => {
        setDismissTokenWarning(true)
    }, [])

    // dismiss warning if all imported tokens are in active lists
    const defaultTokens = useAllTokens()
    const importTokensNotInDefault = useMemo(
        () =>
            urlLoadedTokens &&
            urlLoadedTokens
                .filter((token: Token) => {
                    return !Boolean(token.address in defaultTokens)
                })
                .filter((token: Token) => {
                    // Any token addresses that are loaded from the shorthands map do not need to show the import URL
                    const supported = supportedChainId(chainId)
                    if (!supported) return true
                    return !Object.keys(TOKEN_SHORTHANDS).some((shorthand) => {
                        const shorthandTokenAddress = TOKEN_SHORTHANDS[shorthand][supported]
                        return shorthandTokenAddress && shorthandTokenAddress === token.address
                    })
                }),
        [chainId, defaultTokens, urlLoadedTokens]
    )

    const theme = useTheme()
    // toggle wallet when disconnected
    const toggleWalletModal = useToggleWalletModal()

    // for expert mode
    const [isExpertMode] = useExpertModeManager()
    // swap state
    const { independentField, typedValue, recipient } = useSwapState()
    const {
        trade: { state: tradeState, trade },
        allowedSlippage,
        currencyBalances,
        parsedAmount,
        currencies,
        inputError: swapInputError,
    } = useDerivedSwapInfo()

    const {
        wrapType,
        execute: onWrap,
        inputError: wrapInputError,
    } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
    const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
    const { address: recipientAddress } = useENSAddress(recipient)

    const parsedAmounts = useMemo(
        () =>
            showWrap
                ? {
                    [Field.INPUT]: parsedAmount,
                    [Field.OUTPUT]: parsedAmount,
                }
                : {
                    [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
                    [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
                },
        [independentField, parsedAmount, showWrap, trade]
    )

    const fiatValueInput = useStablecoinValue(parsedAmounts[Field.INPUT])
    const fiatValueOutput = useStablecoinValue(parsedAmounts[Field.OUTPUT])

    const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
        () => [!trade?.swaps, TradeState.LOADING === tradeState, TradeState.SYNCING === tradeState],
        [trade, tradeState]
    )

    const fiatValueTradeInput = useStablecoinValue(trade?.inputAmount)
    const fiatValueTradeOutput = useStablecoinValue(trade?.outputAmount)
    const stablecoinPriceImpact = useMemo(
        () =>
            routeIsSyncing || !trade ? undefined : computeFiatValuePriceImpact(fiatValueTradeInput, fiatValueTradeOutput),
        [fiatValueTradeInput, fiatValueTradeOutput, routeIsSyncing, trade]
    )

    const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
    // const isValid = !swapInputError
    const isValid = chainId === 1 ? false : !swapInputError
    const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

    const handleTypeInput = useCallback(
        (value: string) => {
            onUserInput(Field.INPUT, value)
        },
        [onUserInput]
    )
    const handleTypeOutput = useCallback(
        (value: string) => {
            onUserInput(Field.OUTPUT, value)
        },
        [onUserInput]
    )

    // reset if they close warning without tokens in params
    const handleDismissTokenWarning = useCallback(() => {
        setDismissTokenWarning(true)
        navigate('/swap/')
    }, [navigate])

    // modal and loading
    const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
        showConfirm: boolean
        tradeToConfirm: Trade<Currency, Currency, TradeType> | undefined
        attemptingTxn: boolean
        swapErrorMessage: string | undefined
        txHash: string | undefined
    }>({
        showConfirm: false,
        tradeToConfirm: undefined,
        attemptingTxn: false,
        swapErrorMessage: undefined,
        txHash: undefined,
    })

    const formattedAmounts = useMemo(
        () => ({
            [independentField]: typedValue,
            [dependentField]: showWrap
                ? parsedAmounts[independentField]?.toExact() ?? ''
                : formatTransactionAmount(currencyAmountToPreciseFloat(parsedAmounts[dependentField])),
        }),
        [dependentField, independentField, parsedAmounts, showWrap, typedValue]
    )

    const userHasSpecifiedInputOutput = Boolean(
        currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
    )

    const permit2Enabled = usePermit2Enabled()
    const maximumAmountIn = useMemo(() => {
        const maximumAmountIn = trade?.maximumAmountIn(allowedSlippage)
        return maximumAmountIn?.currency.isToken ? (maximumAmountIn as CurrencyAmount<Token>) : undefined
    }, [allowedSlippage, trade])
    const permit = usePermit(
        permit2Enabled ? maximumAmountIn : undefined,
        permit2Enabled && chainId ? UNIVERSAL_ROUTER_ADDRESS(chainId) : undefined
    )
    const [isPermitPending, setIsPermitPending] = useState(false)
    const [isPermitFailed, setIsPermitFailed] = useState(false)
    const addTransaction = useTransactionAdder()
    const isApprovalPending = useHasPendingApproval(maximumAmountIn?.currency, PERMIT2_ADDRESS)
    const updatePermit = useCallback(async () => {
        setIsPermitPending(true)
        try {
            const approval = await permit.callback?.(isApprovalPending)
            if (approval) {
                sendAnalyticsEvent(EventName.APPROVE_TOKEN_TXN_SUBMITTED, {
                    chain_id: chainId,
                    token_symbol: maximumAmountIn?.currency.symbol,
                    token_address: maximumAmountIn?.currency.address,
                })

                const { response, info } = approval
                addTransaction(response, info)
            }
            setIsPermitFailed(false)
        } catch (e) {
            console.error(e)
            setIsPermitFailed(true)
        } finally {
            setIsPermitPending(false)
        }
    }, [
        addTransaction,
        chainId,
        isApprovalPending,
        maximumAmountIn?.currency.address,
        maximumAmountIn?.currency.symbol,
        permit,
    ])

    // check whether the user has approved the router on the input token
    const [approvalState, approveCallback] = useApproveCallbackFromTrade(
        permit2Enabled ? undefined : trade,
        allowedSlippage
    )
    const transactionDeadline = useTransactionDeadline()
    const {
        state: signatureState,
        signatureData,
        gatherPermitSignature,
    } = useERC20PermitFromTrade(permit2Enabled ? undefined : trade, allowedSlippage, transactionDeadline)

    const [approvalPending, setApprovalPending] = useState<boolean>(false)
    const handleApprove = useCallback(async () => {
        setApprovalPending(true)
        try {
            if (signatureState === UseERC20PermitState.NOT_SIGNED && gatherPermitSignature) {
                try {
                    await gatherPermitSignature()
                } catch (error) {
                    // try to approve if gatherPermitSignature failed for any reason other than the user rejecting it
                    if (error?.code !== 4001) {
                        await approveCallback()
                    }
                }
            } else {
                await approveCallback()

                sendEvent({
                    category: 'Swap',
                    action: 'Approve',
                    label: [TRADE_STRING, trade?.inputAmount?.currency.symbol].join('/'),
                })
            }
        } finally {
            setApprovalPending(false)
        }
    }, [signatureState, gatherPermitSignature, approveCallback, trade?.inputAmount?.currency.symbol])

    // check if user has gone through approval process, used to show two step buttons, reset on token change
    const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

    // mark when a user has submitted an approval, reset onTokenSelection for input field
    useEffect(() => {
        if (approvalState === ApprovalState.PENDING) {
            setApprovalSubmitted(true)
        }
    }, [approvalState, approvalSubmitted])

    const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
        () => maxAmountSpend(currencyBalances[Field.INPUT]),
        [currencyBalances]
    )
    const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))

    // the callback to execute the swap
    const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
        trade,
        allowedSlippage,
        recipient,
        signatureData,
        permit
    )

    const handleSwap = useCallback(() => {
        if (!swapCallback) {
            return
        }
        if (stablecoinPriceImpact && !confirmPriceImpactWithoutFee(stablecoinPriceImpact)) {
            return
        }
        setSwapState({
            attemptingTxn: true,
            tradeToConfirm,
            showConfirm: true,
            swapErrorMessage: undefined,
            txHash: undefined,
        })
        swapCallback()
            .then((hash) => {
                setSwapState({
                    attemptingTxn: false,
                    tradeToConfirm,
                    showConfirm: true,
                    swapErrorMessage: undefined,
                    txHash: hash,
                })
                sendEvent({
                    category: 'Swap',
                    action: 'transaction hash',
                    label: hash,
                })
                sendEvent({
                    category: 'Swap',
                    action:
                        recipient === null
                            ? 'Swap w/o Send'
                            : (recipientAddress ?? recipient) === account
                                ? 'Swap w/o Send + recipient'
                                : 'Swap w/ Send',
                    label: [TRADE_STRING, trade?.inputAmount?.currency?.symbol, trade?.outputAmount?.currency?.symbol, 'MH'].join(
                        '/'
                    ),
                })
            })
            .catch((error) => {
                setSwapState({
                    attemptingTxn: false,
                    tradeToConfirm,
                    showConfirm: true,
                    swapErrorMessage: error.message,
                    txHash: undefined,
                })
            })
    }, [
        swapCallback,
        stablecoinPriceImpact,
        tradeToConfirm,
        showConfirm,
        recipient,
        recipientAddress,
        account,
        trade?.inputAmount?.currency?.symbol,
        trade?.outputAmount?.currency?.symbol,
    ])

    // errors
    const [showInverted, setShowInverted] = useState<boolean>(true)
    const [swapQuoteReceivedDate, setSwapQuoteReceivedDate] = useState<Date | undefined>()

    // warnings on the greater of fiat value price impact and execution price impact
    const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
        const marketPriceImpact = trade?.priceImpact ? computeRealizedPriceImpact(trade) : undefined
        const largerPriceImpact = largerPercentValue(marketPriceImpact, stablecoinPriceImpact)
        return { priceImpactSeverity: warningSeverity(largerPriceImpact), largerPriceImpact }
    }, [stablecoinPriceImpact, trade])

    const isArgentWallet = useIsArgentWallet()

    // show approve flow when: no error on inputs, not approved or pending, or approved in current session
    // never show if price impact is above threshold in non expert mode
    const showApproveFlow =
        !permit2Enabled &&
        !isArgentWallet &&
        !swapInputError &&
        (approvalState === ApprovalState.NOT_APPROVED ||
            approvalState === ApprovalState.PENDING ||
            (approvalSubmitted && approvalState === ApprovalState.APPROVED)) &&
        !(priceImpactSeverity > 3 && !isExpertMode)

    const handleConfirmDismiss = useCallback(() => {
        setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
        // if there was a tx hash, we want to clear the input
        if (txHash) {
            onUserInput(Field.INPUT, '')
        }
    }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

    const handleAcceptChanges = useCallback(() => {
        setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
    }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

    const handleInputSelect = useCallback(
        (inputCurrency: Currency) => {
            setApprovalSubmitted(false) // reset 2 step UI for approvals
            onCurrencySelection(Field.INPUT, inputCurrency)
        },
        [onCurrencySelection]
    )

    const handleMaxInput = useCallback(() => {
        maxInputAmount && onUserInput(Field.INPUT, maxInputAmount?.toSignificant())
        sendEvent({
            category: 'Swap',
            action: 'Max',
        })
    }, [maxInputAmount, onUserInput])

    const handleOutputSelect = useCallback(
        (outputCurrency: Currency) => onCurrencySelection(Field.OUTPUT, outputCurrency),
        [onCurrencySelection]
    )

    const swapIsUnsupported = useIsSwapUnsupported(currencies[Field.INPUT], currencies[Field.OUTPUT])

    const priceImpactTooHigh = priceImpactSeverity > 3 && !isExpertMode
    const showPriceImpactWarning = largerPriceImpact && priceImpactSeverity > 3

    // Handle time based logging events and event properties.
    useEffect(() => {
        const now = new Date()
        // If a trade exists, and we need to log the receipt of this new swap quote:
        if (newSwapQuoteNeedsLogging && !!trade) {
            // Set the current datetime as the time of receipt of latest swap quote.
            setSwapQuoteReceivedDate(now)
            // Log swap quote.
            sendAnalyticsEvent(
                EventName.SWAP_QUOTE_RECEIVED,
                formatSwapQuoteReceivedEventProperties(trade, trade.gasUseEstimateUSD ?? undefined, fetchingSwapQuoteStartTime)
            )
            // Latest swap quote has just been logged, so we don't need to log the current trade anymore
            // unless user inputs change again and a new trade is in the process of being generated.
            setNewSwapQuoteNeedsLogging(false)
            // New quote is not being fetched, so set start time of quote fetch to undefined.
            setFetchingSwapQuoteStartTime(undefined)
        }
        // If another swap quote is being loaded based on changed user inputs:
        if (routeIsLoading) {
            setNewSwapQuoteNeedsLogging(true)
            if (!fetchingSwapQuoteStartTime) setFetchingSwapQuoteStartTime(now)
        }
    }, [
        newSwapQuoteNeedsLogging,
        routeIsSyncing,
        routeIsLoading,
        fetchingSwapQuoteStartTime,
        trade,
        setSwapQuoteReceivedDate,
    ])

    const approveTokenButtonDisabled =
        approvalState !== ApprovalState.NOT_APPROVED || approvalSubmitted || signatureState === UseERC20PermitState.SIGNED

    const showDetailsDropdown = Boolean(
        !showWrap && userHasSpecifiedInputOutput && (trade || routeIsLoading || routeIsSyncing)
    )
    // social tast
    const [retweet, setRetweet] = useState(false)
    const [loadingRetweet, setLoadingRetweet] = useState(false)
    const [loadingJoinDiscord, setLoadingJoinDiscord] = useState(false)
    const [joinDiscord, setJoinDiscord] = useState(false)
    const [claimToken, setClaimToken] = useState(false)
    const [claimTokenETH, setClaimETH] = useState(false)
    const [twId, setTwId] = useState<string>()
    const [faucetTwId, setFaucetTwId] = useState<string>()

    const [isOpenClaimEth, setIsOpenClaimEth] = useState(false)
    const [loadingClaimEth, setLoadingClaimEth] = useState(false)
    const [errorClaimEth, setErrorClaimEth] = useState(false)
    const [errorClaimToken, setErrorClaimToken] = useState(false)
    const [errMess, setErrMess] = useState('')
    const [errMessToken, setErrMessToken] = useState('')
    const [tabSelector, setTabSelector] = useState(1)

    function handleClickRetweet() {
        setLoadingRetweet(true)
        let twitterRetweetUrl = 'https://twitter.com/intent/retweet?tweet_id=1808593634146857036'
        // if (faucetTwId) {
        //   twitterRetweetUrl = 'https://twitter.com/intent/retweet?tweet_id=' + faucetTwId
        // } else {
        //   twitterRetweetUrl = 'https://twitter.com/gmeowfi/status/1808593634146857036'
        // }
        window.open(twitterRetweetUrl, '_blank')
        setTimeout(() => {
            setRetweet(true)
            setLoadingRetweet(false)
        }, 5000)
    }

    async function handleRequest() {
        setIsOpenClaimEth(true)
        setLoadingClaimEth(true)
        try {
            if (account) {
                const res = await axios.post('https://faucet.monoswap.io/api/claim', {
                    address: account.toString(),
                })

                if (res.status === 200) {
                    setLoadingClaimEth(false)
                    setClaimETH(true)
                } else {
                    console.log('Error', res.status)
                }
            }
        } catch (error) {
            setErrorClaimEth(true)
            setClaimETH(true)

            if (error.response) {
                if (error.response.status === 429) {
                    setErrMess(error.response.data.message)
                }
            } else if (error.response.status === 500) {
                setErrMess('Rpc error')
            } else {
                setErrMess('Faucet error')
            }
            setLoadingClaimEth(false)
        }
    }

    // faucet function
    const [loadingFaucet, setLoadingFaucet] = useState(false)
    const [disableClaim, setDisableClaim] = useState(true)
    const [claimTime, setClaimTime] = useState(0)
    const [isOpenMonoModal, setIsOpenMonoModal] = useState(false)

    // contract
    const faucetContract = useCustomeContract(BLAST_SEPOLIA_TEST_MULTIL_FAUCET_ADDRESSES, multifaucet_abi, false)
    const faucetContractWithSign = useCustomeContract(BLAST_SEPOLIA_TEST_MULTIL_FAUCET_ADDRESSES, multifaucet_abi, true)

    async function handleFaucet() {
        setLoadingFaucet(true)
        setIsOpenMonoModal(true)
        try {
            if (account) {
                const res = await axios.post('https://faucet.monoswap.io/api/claim-token', {
                    address: account.toString(),
                })

                if (res.status === 200) {
                    setLoadingFaucet(false)
                    setDisableClaim(true)
                } else {
                    console.log('Error', res.status)
                }
            }
        } catch (error) {
            setErrorClaimToken(true)
            setDisableClaim(true)

            if (error.response) {
                if (error.response.status === 429) {
                    setErrMessToken(error.response.data.message)
                }
            } else if (error.response.status === 500) {
                setErrMessToken('Rpc error')
            } else {
                setErrMessToken('Faucet error')
            }
            setLoadingFaucet(false)
        }
    }

    async function handleCheckLastClaim() {
        try {
            if (account) {
                const txTime = await faucetContract?.lastClaim(account)
                const formattedTime = txTime.toString()
                const currentTime = Date.now() / 1000
                const gapTime = Number(currentTime) - Number(formattedTime)
                if (gapTime > 86400) {
                    setDisableClaim(false)
                }
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    // add custome token
    const handleAddCustomToken: HandleAddCustomTokenFunc = async ({
        contractAddress,
        symbol,
        decimals,
        tokenLogoURL,
    }) => {
        try {
            if (connector.provider) {
                await connector.provider.request({
                    method: 'wallet_watchAsset',
                    params: {
                        type: 'ERC20',
                        options: {
                            address: contractAddress,
                            symbol: symbol,
                            decimals: decimals,
                            image: tokenLogoURL,
                        },
                    },
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        // handleCheckLastClaim()
        setDisableClaim(false)
    }, [account, chainId])

    // daily task
    const [totalXp, setTotalXp] = useState(0)
    const [yourStreak, setYourStreak] = useState(0)
    const [refEarn, setRefEarn] = useState(0)
    const [timeRemain, setTimeRemain] = useState({
        hour: '',
        minutes: '',
    })
    const [refLink, setRefLink] = useState('')
    const [refCode, setRefCode] = useState()
    const [loadingStatus, setLoadingStatus] = useState(true)
    const [invitees, setInvitees] = useState<Invitee[]>([])
    const [loadingCheckSwap, setLoadingCheckSwap] = useState(false)
    const [loadingCheckAdd, setLoadingCheckAdd] = useState(false)
    const [countdownSwap, setCountdownSwap] = useState(30)
    const [countdownAdd, setCountdownAdd] = useState(30)

    const [hasConnectedX, setHasConnectedX] = useState<boolean>(false)
    const [hasLoginFailed, setHasLoginFailed] = useState<boolean>(false)

    const [currentBanner, setCurrentBanner] = useState<number>(4)
    const [taskStatus, setTaskStatus] = useState({
        followTask: {
            hasClaimed: false,
        },
        reTweetTask: {
            hasClaimed: false,
        },
        dailyCheckInTask: {
            hasClaimed: false,
        },
        swapTask: {
            hasClaimed: false,
            taskStatus: false,
        },
        addLiquidityTask: {
            hasClaimed: false,
            taskStatus: false,
        },
    })
    const [loadingClaimTask, setLoadingClaimTask] = useState({
        dailyCheckin: false,
        followTask: false,
        reTweetTask: false,
        swapTask: false,
        addLiquidityTask: false,
    })
    let loadingTask = [
        {
            dailyCheckin: false,
            followTask: false,
            reTweetTask: false,
            swapTask: false,
            addLiquidityTask: false,
        },
        {
            dailyCheckin: true,
            followTask: false,
            reTweetTask: false,
            swapTask: false,
            addLiquidityTask: false,
        },
        {
            dailyCheckin: false,
            followTask: false,
            reTweetTask: false,
            swapTask: true,
            addLiquidityTask: false,
        },
        {
            dailyCheckin: false,
            followTask: false,
            reTweetTask: false,
            swapTask: false,
            addLiquidityTask: true,
        },
        {
            dailyCheckin: false,
            followTask: true,
            reTweetTask: false,
            swapTask: false,
            addLiquidityTask: false,
        },
        {
            dailyCheckin: false,
            followTask: false,
            reTweetTask: true,
            swapTask: false,
            addLiquidityTask: false,
        },
    ]
    const headers = {
        Authorization: `Bearer auth=gmeow`,
        'Content-Type': 'application/json',
    }
    let userRefCode = useParams()

    function extractReferralCode(fullRefCode: any) {
        if (!fullRefCode || !fullRefCode.startsWith('ref=')) {
            setRefCode(undefined)
            return
        }
        const equalSignIndex = fullRefCode?.indexOf('=')
        const referralCode = fullRefCode?.substring(equalSignIndex + 1)
        setRefCode(referralCode)
        return referralCode
    }

    async function getAirdropStatus() {
        let url = 'https://api.gmeow.fi/monoswap/api/v1/airdrop?address=' + account
        let method: Method = 'get'
        const config = { method, url, headers }
        setLoadingStatus(true)
        try {
            if (account) {
                const res = await axios(config)
                let result = res?.data.data
                const totalXp = result.totalTokenClaimed
                const streak = result.overview.boost
                const refEarns = result.overview.referralTokens
                // get reflink
                const userRefCode = result.refCode
                createReferralUrl(userRefCode)
                setTotalXp(totalXp)
                setYourStreak(streak)
                setRefEarn(refEarns)
                setInvitees(result?.invitedFriends)
                setTaskStatus({
                    followTask: {
                        hasClaimed: result.followTask.hasClaimed,
                    },
                    reTweetTask: {
                        hasClaimed: result.reTweetTask.hasClaimed,
                    },
                    dailyCheckInTask: {
                        hasClaimed: result.dailyCheckInTask.hasClaimed,
                    },
                    swapTask: {
                        hasClaimed: result.swapTask.hasClaimed,
                        taskStatus: result.swapTask.taskStatus,
                    },
                    addLiquidityTask: {
                        hasClaimed: result.addLiquidityTask.hasClaimed,
                        taskStatus: result.addLiquidityTask.taskStatus,
                    },
                })
                setHasConnectedX(result?.hasConnectedX)
                setLoadingStatus(false)
            }
        } catch (error) {
            console.log('res', error)
            setLoadingStatus(false)
        }
    }

    async function getTwId() {
        let url = 'https://api.gmeow.fi/monoswap/api/v1/metadata-config/get-by-key?key=RETWEET_TASK_URL'
        let method: Method = 'get'
        const config = { method, url, headers }
        try {
            if (account) {
                const res = await axios(config)
                setTwId(res.data.value)
            }
        } catch (error) {
            console.log('res', error)
        }
    }

    async function getFaucetTwId() {
        let url = 'https://api.gmeow.fi/monoswap/api/v1/metadata-config/get-by-key?key=RETWEET_FAUCET_URL'
        let method: Method = 'get'
        const config = { method, url, headers }
        try {
            if (account) {
                const res = await axios(config)
                setFaucetTwId(res.data.value)
            }
        } catch (error) {
            console.log('res', error)
        }
    }

    const handleCallCheckFunction = () => {
        setLoadingCheckSwap(true)
        setCountdownSwap(30)
        const intervalId = setInterval(() => {
            setCountdownSwap((currentCountdown) => {
                if (currentCountdown > 0) {
                    return currentCountdown - 1
                } else {
                    clearInterval(intervalId)
                    getAirdropStatus()
                    setLoadingCheckSwap(false)
                    return 0
                }
            })
        }, 1000)
    }
    const handleCallCheckAddFunction = () => {
        setLoadingCheckAdd(true)
        setCountdownAdd(30)
        const intervalId = setInterval(() => {
            setCountdownAdd((currentCountdown) => {
                if (currentCountdown > 0) {
                    return currentCountdown - 1
                } else {
                    clearInterval(intervalId)
                    getAirdropStatus()
                    setLoadingCheckAdd(false)
                    return 0
                }
            })
        }, 1000)
    }

    // check task
    async function claimTask(taskID: any) {
        let url = 'https://api.gmeow.fi/monoswap/api/v1/airdrop/claim-task'
        let method: Method = 'post'
        setLoadingClaimTask(loadingTask[taskID])
        try {
            if (account) {
                let taskid = Number(taskID)
                let data = {
                    address: account.toString(),
                    taskId: taskid,
                }
                const config = { method, url, headers, data }
                const res = await axios(config)
                let result = res?.data
                // console.log('resultClaim', result)

                if (taskID === 4) {
                    const twitterFollowUrl = 'https://twitter.com/intent/follow?screen_name=gmeowfi'
                    window.open(twitterFollowUrl, '_blank')
                }
                if (taskID === 5) {
                    let twitterRetweetUrl = 'https://twitter.com/gmeowfi/status/1808593634146857036'
                    // if (twId) {
                    //   twitterRetweetUrl = 'https://twitter.com/intent/retweet?tweet_id=' + twId
                    // } else {
                    //   twitterRetweetUrl = 'https://twitter.com/intent/retweet?tweet_id=1755646330570240142'
                    // }
                    window.open(twitterRetweetUrl, '_blank')
                }
                setTimeout(() => {
                    setLoadingClaimTask(loadingTask[0])
                    getAirdropStatus()
                }, 5000)
            }
        } catch (error) {
            console.log('error', error)
            setLoadingClaimTask(loadingTask[0])
        }
    }

    function startCountdown() {
        let intervalId: any

        const now = new Date()
        let endTime = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1, 0, 0, 0)
        endTime.setUTCHours(0, 0, 0, 0) // Set the time to 0:0:0 UTC

        function updateCountdown() {
            const currentTime = new Date()
            let timeLeft = Number(endTime) - Number(currentTime)

            if (timeLeft <= 0) {
                clearInterval(intervalId)
                endTime.setUTCDate(endTime.getUTCDate() + 1)
                timeLeft = Number(endTime) - Number(currentTime)
            }
            const totalSeconds = Math.ceil(timeLeft / 1000)
            const hours = Math.floor(totalSeconds / 3600)
            const minutes = Math.floor((totalSeconds % 3600) / 60)
            setTimeRemain({
                hour: String(hours).padStart(2, '0'),
                minutes: String(minutes).padStart(2, '0'),
            })
        }

        updateCountdown()
        intervalId = setInterval(updateCountdown, 60000)
    }

    function createReferralUrl(refcode: any) {
        let baseUrl = window.location.origin
        const referralUrl = `${baseUrl}/#/swap/ref=${refcode}`
        setRefLink(referralUrl)
        return referralUrl
    }

    function truncateString(str: any, length: any) {
        if (str.length > length) {
            return str.slice(0, length - 4) + '...' + str.slice(-4)
        } else {
            return str
        }
    }

    async function updateRef() {
        let url = 'https://api.gmeow.fi/monoswap/api/v1/airdrop/referral'
        let method: Method = 'post'
        try {
            let refcodes = extractReferralCode(userRefCode.id)
            if (refcodes && account) {
                let data = {
                    address: account.toString(),
                    referralBy: refcodes.toString(),
                    // referralBy: '0x14E12B139B721A514193877b6c6D829f8d1F4302',
                }
                const config = { method, url, headers, data }
                const res = await axios(config)
                let result = res?.data
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    // add chart
    let pairSelect = useGetPairSelected(currencies)

    const pairAddress = findPairAddressBySelect(pairSelect)

    const [chartDisplay, setChartDisplay] = useState(false)
    const chartUrl = `https://www.geckoterminal.com/blast/pools/0xfb45661dffa7e768c1d03b5aa9cd9e7f55032c1c?embed=1&info=0&swaps=0`
    const [urlChart, setUrlChart] = useState(chartUrl)
    const [isHovered, setIsHovered] = useState(false)

    const toggleChartDisplay = () => {
        setChartDisplay(!chartDisplay)
    }

    function handleSelectPair(item: any, token1: any, token2: any) {
        const encodedToken1 = encodeURIComponent(token1)
        const encodedToken2 = encodeURIComponent(token2)
        const swapUrl = `#/swap?inputCurrency=${encodedToken1}&outputCurrency=${encodedToken2}&chainName=blast`
        window.open(swapUrl, '_blank')
        // setUrlChart(`https://www.geckoterminal.com/blast/pools/${item}?embed=1&info=0&swaps=0`)
        // setChartDisplay(true)
    }

    useEffect(() => {
        if (pairAddress !== null) {
            setUrlChart(`https://www.geckoterminal.com/blast/pools/${pairAddress}?embed=1&info=0&swaps=0`)
        }
    }, [account, chainId, pairAddress])

    // show price
    const alphaStore = useAlphaStore()
    const amountInput = useMemo(() => {
        if (!formattedAmounts[Field.INPUT] || !formattedAmounts) return 0
        return Number(formattedAmounts[Field.INPUT].replaceAll(',', ''))
    }, [formattedAmounts])

    const priceInputFiate = useMemo(() => {
        if (!Field.INPUT || !currencies || !currencies[Field.INPUT] || amountInput == 0) return 0
        // @ts-ignore
        else return alphaStore.calcUsd(currencies[Field.INPUT].wrapped.address.toString(), amountInput)
    }, [currencies, amountInput, alphaStore.tokenPrices])

    const amountOutput = useMemo(() => {
        if (!formattedAmounts[Field.OUTPUT] || !formattedAmounts) return 0
        return Number(formattedAmounts[Field.OUTPUT].replaceAll(',', ''))
    }, [formattedAmounts])

    const priceOnputFiate = useMemo(() => {
        if (!Field.OUTPUT || !currencies || !currencies[Field.OUTPUT] || amountOutput == 0) return 0
        // @ts-ignore
        else return alphaStore.calcUsd(currencies[Field.OUTPUT].wrapped.address.toString(), amountOutput)
    }, [currencies, amountOutput, alphaStore.tokenPrices])

    const selectedCurrencyBalance = useCurrencyBalance(account, currencies[Field.INPUT] ?? undefined)

    // const {
    //     usdriceImpact,
    //     usdPercentImpact
    // } = useMemo(() => {
    //     const usdImpact = priceInputFiate - priceOnputFiate

    //     return {
    //         usdriceImpact: usdImpact,
    //         usdPercentImpact: 
    //     }
    // }, [priceInputFiate, priceOnputFiate])


    useEffect(() => {
        let bannerSlider = setInterval(() => {
            setCurrentBanner(prev => {
                if (prev == 4)
                    return 1;
                else return prev + 1
            })
        }, 5000)
        return () => {
            clearInterval(bannerSlider)
        }
    }, [])
    return (
        <Trace page={PageName.SWAP_PAGE} shouldLogImpression>
            <>
                <div className="swap-container">
                    <img className='bg' src="/swap/bg.png" alt="gmeow" />

                    <BannerImageWrapper>
                        <TextStyle fontFamily="DynaPuff"
                            fontSize="24px"
                            fontSizeLg="21px"
                            fontSizeMb="15px"
                            textTransform="uppercase">
                            <SpanOutlined style={{ color: "#F4BC1D", }}>
                                Join <SpanOutlined style={{ color: "#00E440" }}>Zircuit</SpanOutlined> Gas Mining Festival with <SpanOutlined style={{ color: "#ACF904" }}>Gmeow</SpanOutlined>
                            </SpanOutlined>
                        </TextStyle>
                        <span className='sub_text'>
                            <span className='bold_text'>Trade, Farm</span> and <span className='bold_text'>Open Boxes</span> to share <span className='bold_text'>150,000</span> <span className='border_text' style={{ color: '#00E440' }}>ZRC</span> & <span className='bold_text'>60,000,000</span> <span className='border_text' style={{ color: '#ACF904' }}>xGM</span>
                        </span>
                    </BannerImageWrapper>

                    <div className="swap-mid-container">
                        <div className="swap-mid-left">
                            <div>
                                <TokenSafetyModal
                                    isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
                                    tokenAddress={importTokensNotInDefault[0]?.address}
                                    secondTokenAddress={importTokensNotInDefault[1]?.address}
                                    onContinue={handleConfirmTokenWarning}
                                    onCancel={handleDismissTokenWarning}
                                    showCancel={true}
                                />
                                {/* <ClaimETHModal isOpen={false} loading={false} onDismiss={() => setLoadingConfirmModal(false)} /> */}
                                <ClaimETHModal
                                    isOpen={isOpenClaimEth}
                                    loading={loadingClaimEth}
                                    onDismiss={() => setIsOpenClaimEth(false)}
                                    errorClaimEth={errorClaimEth}
                                    errMess={errMess}
                                />
                                <ClaimMonoToken
                                    isOpen={isOpenMonoModal}
                                    loading={loadingFaucet}
                                    onDismiss={() => setIsOpenMonoModal(false)}
                                    errMessToken={errMessToken}
                                    errorClaimToken={errorClaimToken}
                                />
                                <div className="switch-version-container" style={{ display: 'none' }}>
                                    <div
                                        className={`switch-version-right ${tabSelector === 1 ? 'switch-version-left' : ''}`}
                                        onClick={() => {
                                            setTabSelector(1)
                                        }}
                                    >
                                        V3
                                    </div>
                                    <div
                                        className={`switch-version-right ${tabSelector === 2 ? 'switch-version-left' : ''}`}
                                        onClick={() => {
                                            setTabSelector(2)
                                        }}
                                    >
                                        V2
                                    </div>
                                </div>

                                <div className='swap-panel-container'>
                                    <div className='swap-panel-title'>
                                        <h4>Meow Swap</h4>
                                        <h5>Trade & Earn in an instant </h5>
                                    </div>

                                    <div className='swap-panel-setting'>
                                        <div className="swap-top-right">
                                            <div
                                                onMouseEnter={() => setIsHovered(true)}
                                                onMouseLeave={() => setIsHovered(false)}
                                                onClick={() => {
                                                    notification.info({
                                                        message: 'Coming Soon'
                                                    })
                                                    // toggleChartDisplay
                                                }}
                                                className={`swap-chart-toggle `}
                                            >
                                                <img style={{
                                                    width: isHovered ? '26px' : '24px'
                                                }}
                                                    src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/swap/barchart-icon.png'
                                                    alt='gmeow-fi' />
                                            </div>
                                        </div>
                                        <SwapHeader allowedSlippage={allowedSlippage} />
                                    </div>
                                </div>

                                <>
                                    {tabSelector === 1 && (
                                        <PageWrapper>
                                            <SwapWrapper className={className} id="swap-page">
                                                <ConfirmSwapModal
                                                    isOpen={showConfirm}
                                                    trade={trade}
                                                    originalTrade={tradeToConfirm}
                                                    onAcceptChanges={handleAcceptChanges}
                                                    attemptingTxn={attemptingTxn}
                                                    txHash={txHash}
                                                    recipient={recipient}
                                                    allowedSlippage={allowedSlippage}
                                                    onConfirm={handleSwap}
                                                    swapErrorMessage={swapErrorMessage}
                                                    onDismiss={handleConfirmDismiss}
                                                    swapQuoteReceivedDate={swapQuoteReceivedDate}
                                                    fiatValueInput={fiatValueTradeInput}
                                                    fiatValueOutput={fiatValueTradeOutput}
                                                />

                                                <div style={{ display: 'relative' }}>
                                                    <SwapSection>
                                                        <Trace section={SectionName.CURRENCY_INPUT_PANEL}>
                                                            <SwapCurrencyInputPanel
                                                                label={
                                                                    independentField === Field.OUTPUT && !showWrap ? (
                                                                        <Trans>From (at most)</Trans>
                                                                    ) : (
                                                                        <Trans>From</Trans>
                                                                    )
                                                                }
                                                                value={formattedAmounts[Field.INPUT]}
                                                                showMaxButton={showMaxButton}
                                                                currency={currencies[Field.INPUT] ?? null}
                                                                onUserInput={handleTypeInput}
                                                                onMax={handleMaxInput}
                                                                fiatValue={fiatValueInput ?? undefined}
                                                                onCurrencySelect={handleInputSelect}
                                                                otherCurrency={currencies[Field.OUTPUT]}
                                                                showCommonBases={true}
                                                                id={SectionName.CURRENCY_INPUT_PANEL}
                                                                loading={independentField === Field.OUTPUT && routeIsSyncing}
                                                                customeFiatValue={priceInputFiate}
                                                            />
                                                        </Trace>
                                                    </SwapSection>
                                                </div>

                                                <div>
                                                    <ArrowSection>
                                                        {
                                                            selectedCurrencyBalance && amountInput > Number(formatNumber(selectedCurrencyBalance.toSignificant())) && <div className='insufficient-box mobile'>
                                                                Insufficient balance
                                                            </div>
                                                        }
                                                        <div className='convert-box'>
                                                            {
                                                                trade && <RowBetween
                                                                    style={{ marginTop: '0.25rem', padding: '0 1rem' }}>
                                                                    <TradePrice price={trade.executionPrice}
                                                                        showInverted={showInverted}
                                                                        setShowInverted={setShowInverted} />


                                                                </RowBetween>
                                                            }
                                                        </div>
                                                        <ArrowWrapper clickable={isSupportedChain(chainId)}>
                                                            <TraceEvent
                                                                events={[BrowserEvent.onClick]}
                                                                name={EventName.SWAP_TOKENS_REVERSED}
                                                                element={ElementName.SWAP_TOKENS_REVERSE_ARROW_BUTTON}
                                                            >
                                                                <ArrowContainer
                                                                    onClick={() => {
                                                                        setApprovalSubmitted(false) // reset 2 step UI for approvals
                                                                        onSwitchTokens()
                                                                    }}
                                                                    color={theme.textPrimary}
                                                                >
                                                                    {/* <ArrowDown
                            size="16"
                            color={
                              currencies[Field.INPUT] && currencies[Field.OUTPUT]
                                ? theme.deprecated_text1
                                : theme.deprecated_text3
                            }
                          /> */}
                                                                    <img src={arrowswap} alt="" />
                                                                </ArrowContainer>
                                                            </TraceEvent>
                                                        </ArrowWrapper>

                                                        {
                                                            selectedCurrencyBalance && (amountInput > Number(selectedCurrencyBalance.toSignificant())) && <div className='insufficient-box'>
                                                                Insufficient balance
                                                            </div>
                                                        }

                                                    </ArrowSection>
                                                    {/* <span>
                                                        Insufficient balance
                                                    </span> */}
                                                </div>
                                                <AutoColumn gap="md">
                                                    <div>
                                                        <OutputSwapSection showDetailsDropdown={showDetailsDropdown}>
                                                            <Trace section={SectionName.CURRENCY_OUTPUT_PANEL}>
                                                                <SwapCurrencyInputPanel
                                                                    value={formattedAmounts[Field.OUTPUT]}
                                                                    onUserInput={handleTypeOutput}
                                                                    label={
                                                                        independentField === Field.INPUT && !showWrap ? (
                                                                            <Trans>To (at least)</Trans>
                                                                        ) : (
                                                                            <Trans>To</Trans>
                                                                        )
                                                                    }
                                                                    showMaxButton={false}
                                                                    hideBalance={false}
                                                                    fiatValue={fiatValueOutput ?? undefined}
                                                                    priceImpact={stablecoinPriceImpact}
                                                                    currency={currencies[Field.OUTPUT] ?? null}
                                                                    onCurrencySelect={handleOutputSelect}
                                                                    otherCurrency={currencies[Field.INPUT]}
                                                                    showCommonBases={true}
                                                                    id={SectionName.CURRENCY_OUTPUT_PANEL}
                                                                    loading={independentField === Field.INPUT && routeIsSyncing}
                                                                    customeFiatValue={priceOnputFiate}
                                                                />
                                                            </Trace>

                                                            {recipient !== null && !showWrap ? (
                                                                <>
                                                                    <AutoRow justify="space-between"
                                                                        style={{ padding: '0 1rem' }}>
                                                                        <ArrowWrapper clickable={false}>
                                                                            <img style={{ width: '100%', height: '100%' }}
                                                                                src={arrowswap} alt="gmeow" />
                                                                        </ArrowWrapper>
                                                                        <LinkStyledButton
                                                                            id="remove-recipient-button"
                                                                            onClick={() => onChangeRecipient(null)}
                                                                        >
                                                                            <Trans>- Remove recipient</Trans>
                                                                        </LinkStyledButton>
                                                                    </AutoRow>
                                                                    <AddressInputPanel id="recipient" value={recipient}
                                                                        onChange={onChangeRecipient} />
                                                                </>
                                                            ) : null}
                                                        </OutputSwapSection>
                                                    </div>

                                                    {
                                                        (showDetailsDropdown || showPriceImpactWarning) &&
                                                        <DetailsSwapWrapper>
                                                            {showDetailsDropdown && (
                                                                <DetailsSwapSection>
                                                                    <SwapDetailsDropdown
                                                                        trade={trade}
                                                                        syncing={routeIsSyncing}
                                                                        loading={routeIsLoading}
                                                                        showInverted={showInverted}
                                                                        setShowInverted={setShowInverted}
                                                                        allowedSlippage={allowedSlippage}
                                                                    />
                                                                </DetailsSwapSection>
                                                            )}
                                                            {/* {showPriceImpactWarning &&
                                                                <PriceImpactWarning priceImpact={largerPriceImpact} />} */}
                                                        </DetailsSwapWrapper>
                                                    }

                                                </AutoColumn>
                                            </SwapWrapper>

                                            <div className="swap-bot-button-container">
                                                <div>
                                                    {swapIsUnsupported ? (
                                                        <NotchedButtonFill disabled={true} width={'100%'} height="64px">
                                                            <ThemedText.DeprecatedMain mb="4px">
                                                                <Trans>Unsupported Asset</Trans>
                                                            </ThemedText.DeprecatedMain>
                                                        </NotchedButtonFill>
                                                    ) : !account ? (
                                                        <TraceEvent
                                                            events={[BrowserEvent.onClick]}
                                                            name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
                                                            properties={{
                                                                received_swap_quote: getIsValidSwapQuote(trade, tradeState, swapInputError),
                                                            }}
                                                            element={ElementName.CONNECT_WALLET_BUTTON}
                                                        >
                                                            <NotchedButtonFill onClick={toggleWalletModal}
                                                                width={'100%'} height="64px">
                                                                <Trans>CONNECT WALLET</Trans>
                                                            </NotchedButtonFill>
                                                        </TraceEvent>
                                                    ) : showWrap ? (
                                                        <NotchedButtonFill disabled={Boolean(wrapInputError)}
                                                            onClick={onWrap} width={'100%'}
                                                            height="64px">
                                                            {wrapInputError ? (
                                                                <WrapErrorText wrapInputError={wrapInputError} />
                                                            ) : wrapType === WrapType.WRAP ? (
                                                                <Trans>WRAP</Trans>
                                                            ) : wrapType === WrapType.UNWRAP ? (
                                                                <Trans>UNWRAP</Trans>
                                                            ) : null}
                                                        </NotchedButtonFill>
                                                    ) : routeNotFound && userHasSpecifiedInputOutput && !routeIsLoading && !routeIsSyncing ? (
                                                        <GrayCard style={{ textAlign: 'center' }}>
                                                            <ThemedText.DeprecatedMain mb="4px" color={'#000'}>
                                                                {/* <Trans>Insufficient liquidity for this trade, SWITCH TO V2.</Trans> */}
                                                                <Trans>No router available.</Trans>
                                                            </ThemedText.DeprecatedMain>
                                                        </GrayCard>
                                                    ) : showApproveFlow ? (
                                                        <AutoRow style={{ flexWrap: 'nowrap', width: '100%' }}>
                                                            <AutoColumn style={{ width: '100%' }} gap="25px">
                                                                <ButtonConfirmed
                                                                    fontWeight={600}
                                                                    onClick={handleApprove}
                                                                    disabled={approveTokenButtonDisabled}
                                                                    width="100%"
                                                                    height="64px"
                                                                    altDisabledStyle={approvalState === ApprovalState.PENDING}
                                                                    confirmed={
                                                                        approvalState === ApprovalState.APPROVED ||
                                                                        signatureState === UseERC20PermitState.SIGNED
                                                                    }
                                                                >
                                                                    <AutoRow justify="space-between"
                                                                        style={{ flexWrap: 'nowrap' }} height="20px">
                                                                        {approvalState === ApprovalState.APPROVED ||
                                                                            signatureState === UseERC20PermitState.SIGNED ? (
                                                                            <ThemedText.SubHeader width="100%"
                                                                                textAlign="center"
                                                                                color="#4B2828">
                                                                                <Trans>You can now
                                                                                    trade {currencies[Field.INPUT]?.symbol}</Trans>
                                                                            </ThemedText.SubHeader>
                                                                        ) : (
                                                                            <ThemedText.SubHeader width="100%"
                                                                                textAlign="center"
                                                                                color="#4B2828">
                                                                                <Trans>
                                                                                    {/* Allow the Gmeow Protocol to use
                                                                                    your {currencies[Field.INPUT]?.symbol} */}
                                                                                    Approve your trade
                                                                                </Trans>
                                                                            </ThemedText.SubHeader>
                                                                        )}

                                                                        {approvalPending || approvalState === ApprovalState.PENDING ? (
                                                                            <Loader stroke={theme.black} />
                                                                        ) : (approvalSubmitted && approvalState === ApprovalState.APPROVED) ||
                                                                            signatureState === UseERC20PermitState.SIGNED ? (
                                                                            <CheckCircle size="20" color={"#4B2828"} />
                                                                        ) : (
                                                                            <MouseoverTooltip
                                                                                text={
                                                                                    <Trans>
                                                                                        You must give the Gmeow smart
                                                                                        contracts permission to use
                                                                                        your{' '}
                                                                                        {currencies[Field.INPUT]?.symbol}.
                                                                                        You only have to do this once
                                                                                        per token.
                                                                                    </Trans>
                                                                                }
                                                                            >
                                                                                <HelpCircle size="20" color="#4B2828"
                                                                                    style={{ marginLeft: '8px' }} />
                                                                            </MouseoverTooltip>
                                                                        )}
                                                                    </AutoRow>
                                                                </ButtonConfirmed>
                                                                <ButtonError
                                                                    onClick={() => {
                                                                        if (isExpertMode) {
                                                                            handleSwap()
                                                                        } else {
                                                                            setSwapState({
                                                                                tradeToConfirm: trade,
                                                                                attemptingTxn: false,
                                                                                swapErrorMessage: undefined,
                                                                                showConfirm: true,
                                                                                txHash: undefined,
                                                                            })
                                                                        }
                                                                    }}
                                                                    width="100%"
                                                                    height="64px"
                                                                    id="swap-button"
                                                                    disabled={
                                                                        !isValid ||
                                                                        routeIsSyncing ||
                                                                        routeIsLoading ||
                                                                        (approvalState !== ApprovalState.APPROVED &&
                                                                            signatureState !== UseERC20PermitState.SIGNED) ||
                                                                        priceImpactTooHigh
                                                                    }
                                                                    error={isValid && priceImpactSeverity > 2}
                                                                >
                                                                    <Text fontSize={16} fontWeight={600}>
                                                                        {priceImpactTooHigh ? (
                                                                            <Trans>High Price Impact</Trans>
                                                                        ) : trade && priceImpactSeverity > 2 ? (
                                                                            <Trans>Swap Anyway</Trans>
                                                                        ) : (
                                                                            <Trans>Swap</Trans>
                                                                        )}
                                                                    </Text>
                                                                </ButtonError>
                                                            </AutoColumn>
                                                        </AutoRow>
                                                    ) : isValid && permit.state === PermitState.PERMIT_NEEDED ? (
                                                        <ButtonYellow
                                                            onClick={updatePermit}
                                                            disabled={isPermitPending || isApprovalPending}
                                                            style={{ gap: 14 }}
                                                        >
                                                            {isPermitPending ? (
                                                                <>
                                                                    <Loader size="20px" stroke={theme.accentWarning} />
                                                                    <ThemedText.SubHeader color="accentWarning">
                                                                        <Trans>Approve in your wallet</Trans>
                                                                    </ThemedText.SubHeader>
                                                                </>
                                                            ) : isPermitFailed ? (
                                                                <>
                                                                    <AlertTriangle size={20}
                                                                        stroke={theme.accentWarning} />
                                                                    <ThemedText.SubHeader color="accentWarning">
                                                                        <Trans>Approval failed. Try again.</Trans>
                                                                    </ThemedText.SubHeader>
                                                                </>
                                                            ) : isApprovalPending ? (
                                                                <>
                                                                    <Loader size="20px" stroke={theme.accentWarning} />
                                                                    <ThemedText.SubHeader color="accentWarning">
                                                                        <Trans>Approval pending</Trans>
                                                                    </ThemedText.SubHeader>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div style={{ height: 20 }}>
                                                                        <MouseoverTooltip
                                                                            text={
                                                                                <Trans>
                                                                                    Permission is required for Gmeow to
                                                                                    swap each token. This will expire
                                                                                    after one
                                                                                    month for your security.
                                                                                </Trans>
                                                                            }
                                                                        >
                                                                            <Info size={20}
                                                                                color={theme.accentWarning} />
                                                                        </MouseoverTooltip>
                                                                    </div>
                                                                    <ThemedText.SubHeader color="accentWarning">
                                                                        <Trans>Approve use
                                                                            of {currencies[Field.INPUT]?.symbol}</Trans>
                                                                    </ThemedText.SubHeader>
                                                                </>
                                                            )}
                                                        </ButtonYellow>
                                                    ) : (
                                                        <ButtonError
                                                            onClick={() => {
                                                                if (isExpertMode) {
                                                                    handleSwap()
                                                                } else {
                                                                    setSwapState({
                                                                        tradeToConfirm: trade,
                                                                        attemptingTxn: false,
                                                                        swapErrorMessage: undefined,
                                                                        showConfirm: true,
                                                                        txHash: undefined,
                                                                    })
                                                                }
                                                            }}
                                                            id="swap-button"
                                                            height="64px"
                                                            disabled={
                                                                !isValid ||
                                                                routeIsSyncing ||
                                                                routeIsLoading ||
                                                                priceImpactTooHigh ||
                                                                (permit2Enabled ? permit.state === PermitState.LOADING : Boolean(swapCallbackError))
                                                            }
                                                            error={isValid && priceImpactSeverity > 2 && (permit2Enabled || !swapCallbackError)}
                                                        >
                                                            <Text fontSize={20} fontWeight={600}>
                                                                {swapInputError ? (
                                                                    swapInputError
                                                                ) : routeIsSyncing || routeIsLoading ? (
                                                                    <Trans>Swap</Trans>
                                                                ) : priceImpactSeverity > 2 ? (
                                                                    <Trans>Swap Anyway</Trans>
                                                                ) : priceImpactTooHigh ? (
                                                                    <Trans>Price Impact Too High</Trans>
                                                                ) : (
                                                                    <Trans>Swap</Trans>
                                                                )}
                                                            </Text>
                                                        </ButtonError>
                                                    )}
                                                    {isExpertMode && swapErrorMessage ?
                                                        <SwapCallbackError error={swapErrorMessage} /> : null}
                                                </div>
                                                <div></div>
                                            </div>
                                        </PageWrapper>
                                    )}
                                    {tabSelector === 2 && <SwapV2 />}
                                </>
                                {/* <SwitchLocaleLink /> */}
                                {!swapIsUnsupported ? null : (
                                    <UnsupportedCurrencyFooter
                                        show={swapIsUnsupported}
                                        currencies={[currencies[Field.INPUT], currencies[Field.OUTPUT]]}
                                    />
                                )}
                            </div>
                        </div>
                        {chartDisplay && (
                            <div className="swap-mid-left">
                                {pairAddress ? (
                                    <div className="swap-mid-left-chart">
                                        <CoinGeckoChart url={urlChart} />
                                    </div>
                                ) : (
                                    <div className="chart-comming-soon">The chart is coming soon!</div>
                                )}
                            </div>
                        )}
                    </div>

                    <div style={{
                        margin: '80px',
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'relative',
                        zIndex: 1

                    }}>
                        <img style={{
                            maxWidth: '152px'
                        }} src="/swap/swap-spinner.png" alt="gmeow" />
                    </div>

                    {/*<Faucet/>*/}

                    {/*<div style={{*/}
                    {/*    margin: '80px',*/}
                    {/*    display: 'flex',*/}
                    {/*    justifyContent: 'center',*/}
                    {/*    position: 'relative',*/}
                    {/*    zIndex: 1*/}

                    {/*}}>*/}
                    {/*    <img style={{*/}
                    {/*        maxWidth: '152px'*/}
                    {/*    }} src="/swap/swap-spinner.png" alt="gmeow"/>*/}
                    {/*</div>*/}

                    {/*<InformationWrapper>*/}
                    {/*    <InformationContainer>*/}
                    {/*        <div className="left-content">*/}
                    {/*            <TextStyle fontFamily="DynaPuff"*/}
                    {/*                       fontSize="40px"*/}
                    {/*                       fontSizeLg="32px"*/}
                    {/*                       fontSizeMb="24px">*/}
                    {/*                About ETH*/}
                    {/*            </TextStyle>*/}
                    {/*            <div className="desc-wrapper">*/}
                    {/*                <TextStyle fontSize="16px"*/}
                    {/*                           lineHeight="140%">*/}
                    {/*                    Ethereum is a decentralized blockchain platform that establishes a peer-to-peer*/}
                    {/*                    network that securely executes and verifies application code, called smart*/}
                    {/*                    contracts. Smart contracts allow participants to transact with each other*/}
                    {/*                    without a trusted central authority. Transaction records are immutable,*/}
                    {/*                    verifiable, and securely distributed across the network, giving participants*/}
                    {/*                    full ownership and visibility into transaction data. Transactions are sent from*/}
                    {/*                    and received by user-created Ethereum accounts. A sender must sign transactions*/}
                    {/*                    and spend Ether, Ethereum's native cryptocurrency, as a cost of processing*/}
                    {/*                    transactions on the network.*/}
                    {/*                </TextStyle>*/}
                    {/*                <div className="link-wrapper">*/}
                    {/*                    <div className="link">*/}
                    {/*                        <img src="/swap/img_12.png" alt="img_12"/>*/}
                    {/*                        <TextStyle color="#05C63B"*/}
                    {/*                                   fontSize="16px"*/}
                    {/*                                   lineHeight="140%">*/}
                    {/*                            ethereum.org*/}
                    {/*                        </TextStyle>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="link">*/}
                    {/*                        <img src="/swap/img_12.png" alt="img_12"/>*/}
                    {/*                        <TextStyle color="#05C63B"*/}
                    {/*                                   fontSize="16px"*/}
                    {/*                                   lineHeight="140%">*/}
                    {/*                            x.com/ethereum*/}
                    {/*                        </TextStyle>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="right-content">*/}
                    {/*            <div className="feature">*/}
                    {/*                <TextStyle fontFamily="DynaPuff"*/}
                    {/*                           fontSize="40px"*/}
                    {/*                           fontSizeLg="32px"*/}
                    {/*                           fontSizeMb="24px">*/}
                    {/*                    Featured pairs*/}
                    {/*                </TextStyle>*/}
                    {/*                <div className="feature-item">*/}
                    {/*                    {[...Array(9)].map((_, index) => {*/}
                    {/*                        return <div className="item">*/}
                    {/*                            <div className="icon-coin">*/}
                    {/*                                <img src="/swap/img_13.png" alt="img_13"/>*/}
                    {/*                                <img className="right-img" src="/swap/img_13.png" alt="img_13"/>*/}
                    {/*                            </div>*/}
                    {/*                            <TextStyle>*/}
                    {/*                                ETH/USDC*/}
                    {/*                            </TextStyle>*/}
                    {/*                        </div>*/}
                    {/*                    })}*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="feature">*/}
                    {/*                <TextStyle fontFamily="DynaPuff"*/}
                    {/*                           fontSize="40px"*/}
                    {/*                           fontSizeLg="32px"*/}
                    {/*                           fontSizeMb="24px">*/}
                    {/*                    Featured pools*/}
                    {/*                </TextStyle>*/}
                    {/*                <div className="feature-item">*/}
                    {/*                    {[...Array(9)].map((_, index) => {*/}
                    {/*                        return <div className="item">*/}
                    {/*                            <div className="icon-coin">*/}
                    {/*                                <img src="/swap/img_13.png" alt="img_13"/>*/}
                    {/*                                <img className="right-img" src="/swap/img_13.png" alt="img_13"/>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="text-feature-wrapper">*/}
                    {/*                                <TextStyle>*/}
                    {/*                                    ETH/USDC*/}
                    {/*                                </TextStyle>*/}
                    {/*                                <TextStyle fontSize="14px" color="rgba(75, 40, 40, 0.70)">*/}
                    {/*                                    APR: 12%*/}
                    {/*                                </TextStyle>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    })}*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </InformationContainer>*/}
                    {/*</InformationWrapper>*/}

                </div>
            </>
        </Trace>
    )
}

interface TrendCardProps {
    row: any
    index: number
    handleSelectPair: (pairAddress: any) => void
}

const TrendCard: React.FC<TrendCardProps> = ({ row, index, handleSelectPair }) => {
    return (
        <div
            className={`trend-card ${index % 4 === 1 ? 'delay-1' : ''}`}
            key={row?.liquidityUsd + index * 2}
            onClick={() => handleSelectPair(row?.pairAddress.address)}
        >
            <DoubleLogo img1={row?.token1.logo} img2={row?.token2.logo} size={22} />
            <p>
                {row?.token1.name}/{row?.token2.name}
            </p>
            <div></div>
        </div>
    )
}
