import styled from 'styled-components'
import {BREAKPOINTS} from 'theme'
import TextScramble from "./components/TextScarmble";
import {useEffect, useState} from "react";

const WrapperSection2 = styled.section`
    background: #fff9e1;
    width: 100%;
    height: 100%;
    margin: 0px auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`

const WrapperContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 90px;
    padding-bottom: 140px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        .main_content_img {
            display: none;
        }

        gap: 40px;
        padding-bottom: 60px;
    }
`

const WrapperTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
`

const WrapperImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 114px;
        height: 80px;
    }
`
const WrapperText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    text-align: center;

    h2 {
        margin: 0px;
        color: #4B2828;
        text-align: center;
        font-family: DynaPuff;
        font-size: 56px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 56px */
    }

    p {
        color: #4B2828;
        text-align: center;
        font-family: Outfit;
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 21px */
        margin: auto;
        max-width: 443px;
    }

    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        h2 {
            font-size: 32px;
        }

        p {
            font-size: 16px;
            max-width: 335px;
        }
    }
`

const Cloud = styled.div`
    position: absolute;
    right: 0;
    top: 30%;
    z-index: 1;
    height: 320px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        display: none;
    }
`
const ContentBoxList = styled.div`
    display: none;
    justify-items: center;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        display: grid;
    }
`
const ContentBoxItem = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 422px;
    height: 330px;

    .box_bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`
const ContentBoxItemContent = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    padding: 61px 32px;
    gap: 24px;

    h3 {
        color: #4B2828;
        text-align: center;
        font-family: DynaPuff;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 30px */
        margin: 0;
    }

    h4 {
        color: #93553A;
        text-align: center;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 20px */
        margin: auto;
        max-width: 262px;
        margin-top: 0;
        margin-bottom: 0;
    }

`

export default function Section2() {
    const phrases = [
        'Gmeow’s Magic'
    ];

    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index == phrases.length - 1) {
            return;
        }

        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [index, phrases])
    return (
        <>
            <WrapperSection2>
                <WrapperContent>
                    <WrapperTitle>
                        <WrapperImage>
                            <img
                                src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/cat_icon.png"
                                alt="cat_icon"/>
                        </WrapperImage>
                        <WrapperText>
                            <TextScramble text={phrases[index]}/>
                            <p className='mobile_txt'>Uncover the enchanting features Gmeow has in store. From token
                                farming to NFT
                                curation, the magic never ends!</p>
                        </WrapperText>
                    </WrapperTitle>
                    <img className='main_content_img' width={'100%'} height={'100%'}
                         src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section2/img.svg"
                         alt="gmeowfi"/>

                    <ContentBoxList>
                        <ContentBoxItem>
                            <img className='box_bg' width={'100%'} height={'100%'}
                                 src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section2/box-bg.png"
                                 alt="gmeowfi"/>
                            <ContentBoxItemContent>
                                <h3>
                                    <h3 style={{color: '#00E440'}}>
                                        Supercharged
                                    </h3>
                                    Savings
                                </h3>
                                <h4>When you deposit your assets on Gmeow, it doesn’t just yield; it zooms to the moon
                                    faster than a laser pointer!</h4>
                            </ContentBoxItemContent>
                        </ContentBoxItem>
                        <img src="/landing/section2/box-separator.png" alt="gmeowfi"/>

                        <ContentBoxItem>
                            <img className='box_bg' width={'100%'} height={'100%'}
                                 src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section2/box-bg.png"
                                 alt="gmeowfi"/>
                            <ContentBoxItemContent>
                                <h3>
                                    <h3 style={{color: '#00E440'}}>
                                        Rebase
                                    </h3>
                                    Rodeo
                                </h3>
                                <h4> Your ETH and stablecoins aren't just sitting pretty; they're on a thrilling native
                                    rebase journey, riding the ups and downs like a cat chasing a bouncy ball.</h4>
                            </ContentBoxItemContent>
                        </ContentBoxItem>
                        <img
                            src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section2/box-separator.png"
                            alt="gmeowfi"/>

                        <ContentBoxItem>
                            <img className='box_bg' width={'100%'} height={'100%'}
                                 src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section2/box-bg.png"
                                 alt="gmeowfi"/>
                            <ContentBoxItemContent>
                                <h3>
                                    <h3 style={{color: '#00E440'}}>
                                        Pawsome
                                    </h3>
                                    Pawsome
                                </h3>
                                <h4>At Gmeow, 100% of the Build to Earn rewards go straight to our users! That's right,
                                    all the treats go to you, our fantastic furry friends.</h4>
                            </ContentBoxItemContent>
                        </ContentBoxItem>
                    </ContentBoxList>
                </WrapperContent>
                <Cloud>
                    <img width={'100%'} height={'100%'}
                         src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section2/img.png"
                         alt="cloud"/>
                </Cloud>
            </WrapperSection2>
        </>
    )
}
