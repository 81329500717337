import { useWeb3React } from "@web3-react/core"
import { Pagination, Space } from "antd";
import { PrevNextRender } from "pages/Xp/sections/ClaimedXpHistory";
import { useEffect, useState } from "react";
import styled from "styled-components"
import axios from "axios"
import { BREAKPOINTS, ExternalLink } from "theme";
import { ExplorerDataType, getExplorerLink } from "utils/getExplorerLink";
import { SupportedChainId } from "constants/chains";
import dayjs from "dayjs";
import "../../Xp/sections/leaderboard.css"
import { Section3, TextStyle } from "../components/Components";
import {
    TableItemTreatHistory,
    TabSelectItem,
} from "../../Treat/component/Component";
import { GMEOW_API_BASE_URL } from "utils/env";
import ContentLoading from "components/ContentLoading";

const outlink = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/outlink.png'

const TypeText = styled.span``
const HistoryTable = styled.div`
    padding: 8px 36px;
    width: 100%;
    border-radius: 10px;
    background: #FFF9E1;
    box-shadow: 0px 4px 20px 0px rgba(145, 103, 20, 0.12);
    @media (max-width: ${BREAKPOINTS.md}px) {
        padding: 8px 12px;
    }
    height: 500px;
    overflow-y: auto;
`
type ChestLogType = 'buy' | 'open' | 'all'
type ChestLogHistoryType = {
    boxType: number;
    xgm: number;
    usd: number;
    ticketCnt: number;
    totalNft: number;
    totalOpen: number;
    hash: string;
    createdAt: string;
}

export default function ChestHistoryTable() {
    const { account, chainId } = useWeb3React()
    const [historyList, setHistoryList] = useState<ChestLogHistoryType[]>([]);
    const [filterType, setFilterType] = useState<ChestLogType>('all');
    const [totalHistoryElement, setHistoryTotalElement] = useState<number>(0);
    const [currentHistoryPage, setCurrentHistoryPage] = useState<number>(1);

    const getTypeName = (type: ChestLogType) => {
        if (type == 'buy')
            return 'Buying'
        else return 'Opening'
    }

    useEffect(() => onCallApiHistory(currentHistoryPage), [currentHistoryPage])

    const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false)
    const onCallApiHistory = (page: number) => {
        if (isLoadingTable || !account) return
        setIsLoadingTable(true)
        axios.get(`${GMEOW_API_BASE_URL}/box/get-history?address=${account}&page=${page - 1}&per_page=${10}`)
            .then(({ data }) => {
                setHistoryList(data.data)
                setHistoryTotalElement(data.totalElement)
            })
            .finally(() => setIsLoadingTable(false))
    }

    const onHistoryChangePage = (page: number) => { setCurrentHistoryPage(page); onCallApiHistory(page) };

    useEffect(() => {
        if (account) onCallApiHistory(currentHistoryPage)
    }, [account])

    return <Section3 className="my-claimed-xp">
        <div className="section3-left-content">
            <div className="wrapper-title">
                <div className="txt-wrapper">
                    <TextStyle fontFamily={"DynaPuff"}
                        fontSize="40px"
                        fontWeight="400"
                        textTransform={"uppercase"}>
                        History Box
                    </TextStyle>
                </div>
                <div className="tab-select-wrapper">
                    <TabSelectItem onClick={() => setFilterType('all')} selected={filterType === 'all'}>
                        <div className="item-content">
                            <TextStyle fontSize="16px" fontSizeMb="12px">
                                All
                            </TextStyle>
                        </div>
                    </TabSelectItem>
                    <TabSelectItem onClick={() => setFilterType('buy')} selected={filterType === 'buy'}>
                        <div className="item-content">
                            <TextStyle fontSize="16px" fontSizeMb="12px">
                                Buy
                            </TextStyle>
                        </div>
                    </TabSelectItem>
                    <TabSelectItem onClick={() => setFilterType('open')} selected={filterType === 'open'}>
                        <div className="item-content">
                            <TextStyle fontSize="16px" fontSizeMb="12px">
                                Open
                            </TextStyle>
                        </div>
                    </TabSelectItem>
                </div>
            </div>
            <div className="cat-sleep-img">
                <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section3/img.png" alt="img" />
            </div>

        </div>
        <div className="table-wrapper">
            <div className="table-header">
                <div className="table-header-user">
                    <div className="action">
                        <TextStyle textTransform={"uppercase"} fontSize="14px" fontSizeMb="12px"
                            color={"rgba(75, 40, 40, 0.70)"}>Activity</TextStyle>
                    </div>
                </div>
                <div className="table-header-current-treat">
                    <TextStyle fontSize="14px" fontSizeMb="12px" color={"rgba(75, 40, 40, 0.70)"}
                        textTransform={"uppercase"} textAlignMb="center">amount</TextStyle>
                </div>
                <div className="table-header-rewards">
                    <TextStyle fontSize="14px" fontSizeMb="12px" color={"rgba(75, 40, 40, 0.70)"}
                        textTransform={"uppercase"} textAlign={"end"}
                        textAlignMb="end">date</TextStyle>
                </div>
            </div>
            <HistoryTable>
                <ContentLoading isLoading={isLoadingTable}>
                    {
                        historyList.length == 0 && !isLoadingTable && <p style={{ textAlign: 'center' }}>Data is empty</p>
                    }
                    {
                        historyList.map((item, index) => {
                            return (
                                <>
                                    {
                                        (filterType == 'all' || filterType == 'buy') && <TableItemTreatHistory className="box_row"
                                            borderBottom={index === 9 ? "none" : "1px solid rgba(229, 182, 112, 0.50)"}>
                                            <div className="table-body-user">
                                                <div className="table-body-rewards box">
                                                    <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/cart_icon.png"
                                                        alt="img_4" />
                                                    <TextStyle>
                                                        {getTypeName('buy')}
                                                    </TextStyle>
                                                </div>
                                            </div>
                                            <div className="table-body-current-treat box">
                                                <div className="table-body-rewards box">
                                                    <img className="icon-statistics" style={{ height: '24px', width: '24px' }} src={`https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/box_${item.boxType + 1}.gif`} alt="img" />
                                                    <TextStyle fontWeight={"600"}>
                                                        {item.totalOpen}
                                                    </TextStyle>
                                                </div>
                                            </div>
                                            <div className="table-body-rewards box" style={{ display: 'flex', gap: '4px', gridColumn: 'span 3 / span 6' }}>
                                                <ExternalLink
                                                    href={getExplorerLink(chainId ?? SupportedChainId.ZIRCUIT,
                                                        item.hash,
                                                        ExplorerDataType.TRANSACTION
                                                    )}
                                                    target="_blank"
                                                    style={{ display: 'flex', alignItems: 'center' }}
                                                >
                                                    <img src={outlink} alt="img" />
                                                </ExternalLink>
                                                <TextStyle fontWeight={"600"}>
                                                    {dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}
                                                </TextStyle>
                                            </div>
                                        </TableItemTreatHistory>
                                    }

                                    {
                                        (filterType == 'all' || filterType == 'open') && <TableItemTreatHistory className="box_row"
                                            borderBottom={index === 9 ? "none" : "1px solid rgba(229, 182, 112, 0.50)"}>
                                            <div className="table-body-user">
                                                <div className="table-body-rewards box">
                                                    <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/reward_icon.png"
                                                        alt="img_4" />
                                                    <TextStyle>
                                                        {getTypeName('open')}
                                                    </TextStyle>
                                                </div>
                                            </div>
                                            <div className="table-body-current-treat box">
                                                <div className="table-body-rewards box">
                                                    <img className="icon-statistics" style={{ width: '20px', height: '20px' }} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/eth.png"
                                                        alt="img_4" />
                                                    <TextStyle fontWeight={"600"}>
                                                        {item.usd}
                                                    </TextStyle>
                                                </div>
                                                {
                                                    item.xgm > 0 && <div className="table-body-rewards box">
                                                        <img className="icon-statistics" src="/treat/img_5.png"
                                                            alt="img_4" />
                                                        <TextStyle fontWeight={"600"}>
                                                            {item.xgm}
                                                        </TextStyle>
                                                    </div>
                                                }

                                                <div className="table-body-rewards box" >
                                                    <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section4/img_4.png" style={{ width: '20px', height: '20px' }}
                                                        alt="img_4" />
                                                    <TextStyle fontWeight={"600"}>
                                                        {item.ticketCnt}
                                                    </TextStyle>
                                                </div>
                                                {
                                                    item.totalNft > 0 && <div className="table-body-rewards box">
                                                        <img className="icon-statistics" src="/treat/img_5.png" style={{ width: '20px', height: '20px' }}
                                                            alt="img_4" />
                                                        <TextStyle fontWeight={"600"}>
                                                            {item.totalNft}
                                                            <span style={{
                                                                color: "rgba(75, 40, 40, 0.50)",
                                                                fontFamily: "Outfit",
                                                                marginLeft: "4px"
                                                            }}>NFT</span>
                                                        </TextStyle>
                                                    </div>
                                                }
                                            </div>
                                            <div className="table-body-rewards box" style={{ display: 'flex', gap: '4px', gridColumn: 'span 3 / span 6' }}>
                                                <ExternalLink
                                                    href={getExplorerLink(chainId ?? SupportedChainId.ZIRCUIT,
                                                        item.hash,
                                                        ExplorerDataType.TRANSACTION
                                                    )}
                                                    target="_blank"
                                                    style={{ display: 'flex', alignItems: 'center' }}
                                                >
                                                    <img src={outlink} alt="img" />
                                                </ExternalLink>
                                                <TextStyle fontWeight={"600"}>
                                                    {dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}
                                                </TextStyle>
                                            </div>
                                        </TableItemTreatHistory>
                                    }
                                </>
                            )
                        })
                    }
                </ContentLoading>
            </HistoryTable>
            {
                historyList.length > 0 && <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <Pagination
                        current={currentHistoryPage}
                        total={totalHistoryElement}
                        pageSize={10}
                        itemRender={PrevNextRender}
                        showSizeChanger={false}
                        onChange={onHistoryChangePage}
                    />
                </div>
            }
        </div>

    </Section3>
}