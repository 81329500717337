import styled from "styled-components/macro";
import { BREAKPOINTS } from "../../../theme";

export const TextStyle = styled.p<
    {
        color?: string,
        fontFamily?: string,
        fontSize?: string,
        fontSizeMb?: string,
        fontSizeLg?: string,
        fontWeight?: string,
        lineHeight?: string,
        margin?: string,
        marginMb?: string,
        padding?: string,
        textAlign?: string,
        textTransform?: string,
        textAlignMb?: string,
    }
>`
    color: ${({ color }) => color ? color : '#4B2828'} !important;
    font-family: ${({ fontFamily }) => fontFamily ?? 'Outfit'};
    font-size: ${({ fontSize }) => fontSize ?? '16px'} !important;
    font-style: normal;
    font-weight: ${({ fontWeight }) => fontWeight ?? '500'};
    line-height: ${({ lineHeight }) => lineHeight ?? '100%'};
    margin: ${({ margin }) => margin ?? 0};
    padding: ${({ padding }) => padding};
    text-align: ${({ textAlign }) => textAlign ?? 'start'};
    text-transform: ${({ textTransform }) => textTransform ?? 'none'};
    @media (max-width: ${BREAKPOINTS.lg}px) {
        font-size: ${({ fontSizeLg }) => fontSizeLg ?? '16px'} !important;
    }
    @media (max-width: ${BREAKPOINTS.md}px) {
        font-size: ${({ fontSizeMb }) => fontSizeMb ?? '14px'} !important;
        margin: ${({ marginMb }) => marginMb};
        text-align: ${({ textAlignMb }) => textAlignMb ?? 'start'};
    }
`

export const TextOutline = styled.p<{
    fontSize?: string,
    fontSizeMb?: string,
    fontSizeLg?: string,
    strokeWidth?: string,
    strokeWidthLg?: string,
    strokeWidthMb?: string,
}>`
    color: #FFB704;
    text-align: center;
    -webkit-text-stroke-width: ${({ strokeWidth }) => strokeWidth ?? '4px'};
    -webkit-text-stroke-color: #723507;
    font-family: DynaPuff;
    font-size: ${({ fontSize }) => fontSize ?? '48px'};
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 60px */
    text-transform: uppercase;
    margin: 0;
    @media (max-width: ${BREAKPOINTS.lg}px) {
        font-size: ${({ fontSizeLg }) => fontSizeLg ?? '16px'} !important;
        -webkit-text-stroke-width: ${({ strokeWidthLg }) => strokeWidthLg ?? '2px'};
    }
    @media (max-width: ${BREAKPOINTS.md}px) {
        font-size: ${({ fontSizeMb }) => fontSizeMb ?? '14px'} !important;
        -webkit-text-stroke-width: ${({ strokeWidthMb }) => strokeWidthMb ?? '1.5px'};
    }
`

export const SectionWrapper = styled.div`
    width: 100%;
    margin: 40px auto;

    .cat-icon {
        width: 100%;
        max-width: 115px;
        margin: 160px auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
        }
        @media (max-width: ${BREAKPOINTS.lg}px) {
            margin: 80px auto;
            max-width: 85px;
        }
    }
`

export const Section1 = styled.div`
    width: 100%;
    border-radius: 10px;
    border: 2px solid #FF7A00;
    background: #FFD98E;
`
export const Section1Top = styled.div`
    display: flex;
    justify-content: space-evenly;
    gap: 18px;
    border-bottom: 1px solid #E5B670;
    @media (max-width: ${BREAKPOINTS.lg}px) {
        gap: 0;
    }
    @media (max-width: ${BREAKPOINTS.md}px) {
        gap: 0;
    }
`
export const Section1TopItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    @media (max-width: ${BREAKPOINTS.md}px) {
        gap: 3px;
    }
    img {
        width: 30px;
        height: 28px;
        @media (max-width: ${BREAKPOINTS.lg}px) {
            width: 20px;
            height: 18px;
        }
        @media (max-width: ${BREAKPOINTS.md}px) {
            width: 16px;
            height: 14px;
        }
    }
`
export const Section1Bottom = styled.div`
    padding: 24px 36px 40px 36px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: ${BREAKPOINTS.lg}px) {
        padding: 18px 16px 30px 16px;
    }
    @media (max-width: ${BREAKPOINTS.md}px) {
        padding: 12px;
        gap: 16px;
    }
`


export const Section1BottomTxtWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    @media (max-width: ${BREAKPOINTS.md}px) {
        gap: 3px;
    }
`

export const Section1BottomInfoWrapper = styled.div`
    background: rgba(255, 249, 225, 0.70);
    backdrop-filter: blur(4px);
    padding: 24px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 32px;
    border-radius: 10px;
    @media (max-width: ${BREAKPOINTS.md}px) {
        padding: 12px;
        gap: 8px;
        flex-direction: column;
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;

        .total-distribute-statistics-wrapper {
            display: flex;
            gap: 16px;
            align-items: center;

            @media (max-width: ${BREAKPOINTS.md}px) {
                flex-wrap: wrap;
                gap: 3px;
            }

            .total-distribute-statistics {
                display: flex;
                gap: 6px;
                align-items: center;

                .icon-statistics {
                    width: 21px;
                    height: 21px;
                    @media (max-width: ${BREAKPOINTS.md}px) {
                        width: 14px;
                        height: 14px;
                    }
                }
            }

            .shop-arrow-icon {
                width: 33px;
                height: 20px;
                @media (max-width: ${BREAKPOINTS.md}px) {
                    width: 24px;
                    height: 15px;
                }
            }
        }
    }

    .section1-bottom-line {
        width: 1.5px;
        height: 46px;
        background: rgba(229, 182, 112, 0.50);
        backdrop-filter: blur(4.800000190734863px);
        @media (max-width: ${BREAKPOINTS.md}px) {
            width: 100%;
            height: 1.5px;
        }
    }
`

export const Section2 = styled.div`
    width: 100%;
    border-radius: 10px;
    border: 2px solid #FF7A00;
    background: #FFD98E;
    padding: 40px 36px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    @media (max-width: ${BREAKPOINTS.lg}px) {
        padding: 30px 16px;
    }
    @media (max-width: ${BREAKPOINTS.md}px) {
        padding: 30px 12px;
    }
`

export const Section2ChestWrapper = styled.div`
    background-image: url("https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/section2/img.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    gap: 24px;
    @media (max-width: ${BREAKPOINTS.md}px) {
        flex-direction: column;
        background-image: none;
    }
`

export const Section2ChestCommonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`
export const ChestBoxWrapper = styled.div`
    border-radius: 10px;
    background: rgba(255, 249, 225, 0.70);
    backdrop-filter: blur(4px);
    padding: 18px 24px 32px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
export const ChestBoxRateWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    .rate {
        height: 24px;
    }

    .box-info-icon {
        width: 32px;
        height: 32px;
        cursor: pointer;
    }
`

export const ChestBoxContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    .wrapper-box {
        max-width: 302px;
        max-height: 302px;

        .box-style {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
        }

        @media (max-width: ${BREAKPOINTS.lg}px) {
            max-width: 80%;
            max-height: 80%;
        }
    }


    .content-wrapper {
        width: 100%;
        border-top: 1.5px solid #E5B670;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        @media (max-width: ${BREAKPOINTS.lg}px) {
            gap: 30px;
        }

        .desc-wrapper {
            display: flex;
            gap: 6px;
            justify-content: center;
            align-items: center;

            .info-box-wrapper {
                position: relative;
                cursor: pointer;

                .icon-info {
                    width: 16px;
                    height: 16px;
                } 
            }
        }

        #fun-box + #tooltip-fun-box,
                #sip-box + #tooltip-sip-box,
                #joy-box + #tooltip-joy-box,
                #treasure-box + #tooltip-treasure-box,
                #sweat-box + #tooltip-sweat-box {
                    display: none;
                }

                #fun-box:hover + #tooltip-fun-box,
                #sip-box:hover + #tooltip-sip-box,
                #joy-box:hover + #tooltip-joy-box,
                #treasure-box:hover + #tooltip-treasure-box,
                #sweat-box:hover + #tooltip-sweat-box {
                    display: inline-flex;
                    position: absolute;
                    top: -340px;
                    left: -115px;
                    border-radius: 6px;
                    border: 2px solid #E5B670;
                    background: #FFF9E1;
                    padding: 12px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    z-index: 100;
                    width: 240px;

                    .info-wrapper {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        width: 100%;
                    }
                }
    }
`

export const TooltipItem = styled.div`
    border-radius: 10px;
    background: #FFF6D0;
    box-shadow: 0 7.395px 30px 0 rgba(145, 103, 20, 0.30), -6px -6px 4px 0px rgba(215, 144, 60, 0.60) inset, 6px 6px 4px 0px rgba(255, 255, 255, 0.80) inset;
    padding: 12px;
    display: flex;
    gap: 12px;

    .desc-img-wrapper {
        max-width: 36px;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
        }
    }

    .desc-content-wrapper {
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;
    }
`


export const Section2ChestSpecialBoxWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;

    .icon-special-box {
        width: 180px;
        height: 82px;
        @media (max-width: ${BREAKPOINTS.lg}px) {
            width: 140px;
            height: 60px;
            display: block;
        }
        @media (max-width: ${BREAKPOINTS.md}px) {
            display: none;
        }
    }
`

export const Section3 = styled.div`
    margin: 80px auto;
    display: flex;
    flex-direction: row;
    gap: 118px;
    @media (max-width: ${BREAKPOINTS.lg}px) {
        flex-direction: column;
        gap: 36px;
    }
    @media (max-width: ${BREAKPOINTS.md}px) {
        margin: 30px auto;
    }

    .section3-left-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .wrapper-title {
            display: flex;
            flex-direction: column;
            gap: 28px;

            .txt-wrapper {
                max-width: 163px;
            }
        }

        .cat-sleep-img {
            width: 280px;
            display: block;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                overflow: hidden;

            }

            @media (max-width: ${BREAKPOINTS.lg}px) {
                display: none;
            }
        }
    }

    .table-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;

        .table-header {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(12, minmax(0, 1fr));
            padding: 0 36px;
            @media (max-width: ${BREAKPOINTS.md}px) {
                padding: 0 12px;
            }

            .table-header-user {
                grid-column: span 3 / span 3;
            }

            .table-header-current-treat {
                grid-column: span 3 / span 3;
                @media (max-width: ${BREAKPOINTS.md}px) {
                    margin-left: 10px
                }
            }

            .table-header-rewards {
                grid-column: span 6 / span 6;
            }
        }
    }
`

export const Section4 = styled.div`
    width: 100%;
    border-radius: 10px;
    border: 2px solid #00E440;
    background: #CFFFC3;
    padding: 36px;
    display: flex;
    justify-content: center;
    gap: 56px;
    @media (max-width: ${BREAKPOINTS.lg}px) {
        padding: 16px;
        gap: 36px;
    }
    @media (max-width: ${BREAKPOINTS.lg}px) {
        padding: 12px;
        gap: 36px;
    }

    .left-img-wrapper {
        width: 100%;
        max-width: 537px;

        .left-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
        }

        @media (max-width: ${BREAKPOINTS.lg}px) {
            width: 80%;
        }
        @media (max-width: ${BREAKPOINTS.md}px) {
            display: none;
        }
    }

    .right-content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 24px;

        .info-wrapper {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }

`

export const DescItem = styled.div`
    border-radius: 10px;
    background: #FFF6D0;
    box-shadow: 0px 7.395px 30px 0px rgba(145, 103, 20, 0.30), -6px -6px 4px 0px rgba(215, 144, 60, 0.60) inset, 6px 6px 4px 0px rgba(255, 255, 255, 0.80) inset;
    padding: 32px;
    display: flex;
    gap: 24px;
    @media (max-width: ${BREAKPOINTS.lg}px) {
        padding: 16px;
    }
    @media (max-width: ${BREAKPOINTS.md}px) {
        padding: 12px;
    }

    .desc-img-wrapper {
        max-width: 80px;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
        }
    }

    .desc-content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
`
