import { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import Input from 'components/NumericalInput'
import { BodyWrapper } from 'pages/AppBody'
import styled from 'styled-components/macro'

export const PageWrapper = styled(BodyWrapper)`
    width: 100%;
    border: none;
`

export const Wrapper = styled.div`
    position: relative;
    padding: 26px 16px;
    min-width: 480px;

    ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    min-width: 400px;
  `};

    ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
  min-width: 340px;
`};
`

export const ScrollablePage = styled.div`
    padding: 68px 8px 0px;
    position: relative;
    display: flex;
    width: 80%;
    @media (max-width: 1028px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    @media (max-width: 768px) {
        width: 98%;
    }
`

export const DynamicSection = styled(AutoColumn) <{ disabled?: boolean }>`
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
`

export const CurrencyDropdown = styled(CurrencyInputPanel)`
    width: 40%;
    @media (max-width: 767px) {
        width: 100%;
    }
`

export const StyledInput = styled(Input)`
    font-family: Outfit;
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #E5B670;
    background: #FFF6D0;
    padding: 6px 12px;
    
`

/* two-column layout where DepositAmount is moved at the very end on mobile. */
export const ResponsiveTwoColumns = styled.div`
    display: grid;
    grid-column-gap: 50px;
    grid-row-gap: 15px;
    padding-top: 20px;
    grid-template-columns: repeat(1, 1fr);
`

export const RightContainer = styled(AutoColumn)`
    grid-row: 1 / 3;
    grid-column: 2;
    height: fit-content;

    ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
  grid-row: 2 / 3;
  grid-column: 1;
  `};
`

export const StackedContainer = styled.div`
    display: grid;
`

export const StackedItem = styled.div<{ zIndex?: number }>`
    grid-column: 1;
    grid-row: 1;
    height: 100%;
    z-index: ${({ zIndex }) => zIndex};
`

export const MediumOnly = styled.div`
    ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    display: none;
  `};
`

export const HideMedium = styled.div`
    display: none;

    ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    display: block;
  `};
`
