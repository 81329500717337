import {Trace} from '@uniswap/analytics';
import {PageName} from '@uniswap/analytics-events';
import Section0 from "./sectionsMew/Section0";
import Section1 from './sectionsMew/Section1';
import Section2 from './sectionsMew/Section2';
import Section3 from './sectionsMew/Section3';
import Section4 from './sectionsMew/Section4';
import Section5 from './sectionsMew/Section5';
import styled from 'styled-components';
import {useEffect, useRef, useState} from "react";

/*
 * desktop 1280, 1344, 1440, 1536,
 * tablet 768 1024
 * mobile 360, 390
 */

const LandingWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const SectionWrapper = styled.div`
    width: 100%;
    padding: 20px 0;
`;

export default function Landing() {
    const [startAnimation, setStartAnimation] = useState(false);
    const [startAnimation2, setStartAnimation2] = useState(false);
    const [startAnimation3, setStartAnimation3] = useState(false);
    const [startAnimation4, setStartAnimation4] = useState(false);
    const [startAnimation5, setStartAnimation5] = useState(false);

    const section1Ref = useRef<HTMLDivElement>(null);
    const section2Ref = useRef<HTMLDivElement>(null);
    const section3Ref = useRef<HTMLDivElement>(null);
    const section4Ref = useRef<HTMLDivElement>(null);
    const section5Ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleIntersection = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (entry.target === section1Ref.current) {
                        setStartAnimation(true);
                    } else if (entry.target === section2Ref.current) {
                        setStartAnimation2(true);
                    } else if (entry.target === section3Ref.current) {
                        setStartAnimation3(true);
                    } else if (entry.target === section4Ref.current) {
                        setStartAnimation4(true);
                    } else if (entry.target === section5Ref.current) {
                        setStartAnimation5(true);
                    }
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.5,
        });

        if (section1Ref.current) observer.observe(section1Ref.current);
        if (section2Ref.current) observer.observe(section2Ref.current);
        if (section3Ref.current) observer.observe(section3Ref.current);
        if (section4Ref.current) observer.observe(section4Ref.current);
        if (section5Ref.current) observer.observe(section5Ref.current);

        return () => {
            if (section1Ref.current) observer.unobserve(section1Ref.current);
            if (section2Ref.current) observer.unobserve(section2Ref.current);
            if (section3Ref.current) observer.unobserve(section3Ref.current);
            if (section4Ref.current) observer.unobserve(section4Ref.current);
            if (section5Ref.current) observer.unobserve(section5Ref.current);
        };
    }, []);

    return (
        <Trace page={PageName.LANDING_PAGE} shouldLogImpression>
            <LandingWrapper style={{background: '#fff9e1'}}>
                <Section0/>
                <SectionWrapper ref={section1Ref}>
                    {startAnimation && <Section1/>}
                </SectionWrapper>
                <SectionWrapper ref={section2Ref}>
                    {startAnimation2 && <Section2/>}
                </SectionWrapper>
                <SectionWrapper ref={section3Ref}>
                    {startAnimation3 && <Section3/>}
                </SectionWrapper>
                <SectionWrapper ref={section4Ref}>
                    {startAnimation4 && <Section4/>}
                </SectionWrapper>
                <SectionWrapper ref={section5Ref}>
                    {startAnimation5 && <Section5/>}
                </SectionWrapper>
            </LandingWrapper>
        </Trace>
    );
}
