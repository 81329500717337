import styled from "styled-components/macro";
import { TextStyle } from "./Component";
import "../assets/style.css"
import { formatNumber } from "utils/formatNumbers";

const PopupBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    @media (max-width: 768px) {
        align-items: start;
    }
`

const PopupContent = styled.div`
    padding: 32px;
    border-radius: 10px;
    border: 1.5px solid #E5B670;
    background: #FFF6D0;
    box-shadow: 0 7.395px 30px 0 rgba(145, 103, 20, 0.30);
    position: relative;
    width: 573px;
    @media (max-width: 768px) {
        margin-top: 100px;
        width: 336px;
        padding: 20px;
    }
`

const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 22px;
`

const Line = styled.div`
    height: 1px;
    width: 100%;
    background: rgba(229, 182, 112, 0.50);
    backdrop-filter: blur(4.800000190734863px);
    margin: 24px 0;
`

const Table = styled.div`
    border-radius: 10px;
    background: #FFF9E1;
    box-shadow: 0px 4px 20px 0px rgba(145, 103, 20, 0.12);
    padding: 8px 20px;
    @media (max-width: 768px) {
        padding: 8 10px;
    }
`

const TableTH = styled.div`
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    margin-bottom: 18px;
    padding: 0 20px;
    @media (max-width: 768px) {
        padding: 0 10px;
        margin-bottom: 8px;
    }
`

const TableItem = styled.div<{
    borderBottom?: string
}>`
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    border-bottom: ${props => props.borderBottom};
    padding: 12px 0;
`

// PopupEarnTreat Component
export const PopupEarnTreat = ({ show, onClose }: { show: any, onClose: any }) => {
    if (!show) return null;

    return (
        <PopupBackdrop>
            <PopupContent>
                <img onClick={onClose} className="close-icon" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_14.png" alt="img_14" />
                <TextStyle fontSize="24px"
                    fontFamily="DynaPuff"
                >
                    Provide liquidity to earn TREAT
                </TextStyle>
                <DescriptionWrapper>
                    <div className="desc-item">
                        <img className="img-star-yellow" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_15.png" alt="img_15" />
                        <TextStyle>
                            Create an in-range LP position of any eligible pair.
                        </TextStyle>
                    </div>
                    <div className="desc-item">
                        <img className="img-star-yellow" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_15.png" alt="img_15" />
                        <TextStyle>
                            Stake your LP position in the Farming pools.
                        </TextStyle>
                    </div>
                    <div className="desc-item">
                        <img className="img-star-yellow" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_15.png" alt="img_15" />
                        <TextStyle>
                            Gain TREAT every 2 hours based on the value of your staked LP.
                        </TextStyle>
                    </div>
                    <div className="desc-item">
                        <img className="img-star-yellow" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_15.png" alt="img_15" />
                        <div className="desc-list-item">
                            <TextStyle>
                                Eligible pairs:
                            </TextStyle>
                            <div className="ul">
                                <div className="dot">
                                    <TextStyle fontSize="21px" lineHeight="5px">.</TextStyle>
                                </div>
                                <TextStyle>1x: ETH/MAID</TextStyle>
                            </div>
                        </div>
                    </div>
                </DescriptionWrapper>
                <Line></Line>
                <TableTH>
                    <div className="item">
                        <TextStyle fontSize="12px" fontSizeMb="10px" textTransform="uppercase">LP value</TextStyle>
                    </div>
                    <div className="item">
                        <TextStyle fontSize="12px" fontSizeMb="10px" textTransform="uppercase">Pair
                            Multiplier</TextStyle>
                    </div>
                    <div className="item">
                        <TextStyle fontSize="12px" fontSizeMb="10px" textTransform="uppercase">treat/Year</TextStyle>
                    </div>
                    <div className="item">
                        <TextStyle fontSize="12px" fontSizeMb="10px" textTransform="uppercase"
                            textAlign="end">treat/Epoch</TextStyle>
                    </div>
                </TableTH>
                <Table>
                    {[1, 1, 1].map((_, index) => {
                        return (
                            <TableItem borderBottom={index === 2 ? "none" : "1px solid rgba(229, 182, 112, 0.50)"}>
                                <div className="item">
                                    <TextStyle fontSizeMb="12px">$10,000</TextStyle>
                                </div>
                                <div className="item">
                                    <TextStyle fontSizeMb="12px">{index + 1}x</TextStyle>
                                </div>
                                <div className="item">
                                    <TextStyle fontSizeMb="12px">{formatNumber(3750000 * (index + 1))}</TextStyle>
                                </div>
                                <div className="item">
                                    <TextStyle fontSizeMb="12px" textAlign="end">{formatNumber(71917 * (index + 1))}</TextStyle>
                                </div>
                            </TableItem>
                        )
                    })
                    }

                </Table>
            </PopupContent>
        </PopupBackdrop>
    );
};