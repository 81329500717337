import searchIcon from 'assets/svg/search.svg'
import { LoadingRows as BaseLoadingRows } from 'components/Loader/styled'
import styled from 'styled-components/macro'

import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import { BREAKPOINTS } from 'theme'

export const PaddedColumn = styled(AutoColumn)`
  padding: 24px 32px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 12px 16px;
  }
`

export const MenuItem = styled(RowBetween)<{ dim?: boolean }>`
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) auto minmax(0, 72px);
  grid-gap: 16px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  :hover {
    background: #fff9e1da;
  }

  padding: 12px;
  border-radius: 10px;
  background: ${({ disabled, selected, dim }) => (dim || disabled || selected ? '#fff9e1da' : '#FFF9E1')};
  margin-bottom: 8px;
`

export const SearchInput = styled.input`
  position: relative;
  display: flex;
  padding: 16px;
  height: 40px;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  outline: none;
  -webkit-appearance: none;

  border-radius: 10px;
  border: 2px solid #E5B670;
  background: #FFF6D0;
  box-shadow: 2.5px 2.5px 4px 0px rgba(215, 144, 60, 0.80) inset, -4px -4px 4px 0px #FFF inset;

  font-size: 16px;

  ::placeholder {
    color: #717177;
    font-size: 16px;
  }
  transition: border 100ms;
  :focus {
    border: 1px solid ${({ theme }) => theme.accentActiveSoft};
    background-color: ${({ theme }) => theme.backgroundSurface};
    outline: none;
  }
`
export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => 'rgba(239, 239, 228, 0.2)'};
`

export const LoadingRows = styled(BaseLoadingRows)`
  grid-column-gap: 0.5em;
  grid-template-columns: repeat(12, 1fr);
  max-width: 960px;
  padding: 12px 20px;

  & > div:nth-child(4n + 1) {
    grid-column: 1 / 8;
    height: 1em;
    margin-bottom: 0.25em;
  }
  & > div:nth-child(4n + 2) {
    grid-column: 12;
    height: 1em;
    margin-top: 0.25em;
  }
  & > div:nth-child(4n + 3) {
    grid-column: 1 / 4;
    height: 0.75em;
  }
`
