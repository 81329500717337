import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount, Token, TradeType } from '@uniswap/sdk-core'
import { Trade as V2Trade } from 'custom-uniswap-v2-sdk'
import { Trade as V3Trade } from '@uniswap/v3-sdk'
import { LoadingOpacityContainer } from 'components/Loader/styled'
import { AdvancedSwapDetails } from 'components/swap/AdvancedSwapDetailsV2'
import { AutoRouterLogo } from 'components/swap/RouterLabel'
import SwapRoute from 'components/swap/SwapRouteV2'
import TradePrice from 'components/swap/TradePrice'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { MouseoverTooltip, MouseoverTooltipContent } from 'components/Tooltip'
import JSBI from 'jsbi'
import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown, CheckCircle, HelpCircle, Info } from 'react-feather'
import ReactGA from 'react-ga'
import { useNavigate } from 'react-router-dom'
import { LinkStyledButton, TYPE } from '../../theme'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'

import { Text } from 'rebass'
import { V3TradeState } from 'state/routing/types'
import styled, { ThemeContext } from 'styled-components/macro'
import AddressInputPanel from '../../components/AddressInputPanel'
import {
  ButtonConfirmed,
  ButtonError,
  ButtonLight,
  ButtonPrimary,
  ButtonYellow,
  NotchedButtonFillWrapper,
  NotchedButtonFill,
  NotchedButtonFillPrimary,
} from '../../components/Button'
import { GrayCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import CurrencyLogo from '../../components/CurrencyLogo'
import Loader from '../../components/Loader'
import Row, { AutoRow, RowFixed } from '../../components/Row'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModalV2'
import {
  ArrowWrapper,
  Dots,
  ResponsiveTooltipContainer,
  SwapCallbackError,
  SwapWrapper,
  PageWrapper,
} from '../../components/swap/styleds'
import SwapHeader from '../../components/swap/SwapHeader'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import { useAllTokens, useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallbackV2'
import useENSAddress from '../../hooks/useENSAddress'
import { useERC20PermitFromTradeV2, UseERC20PermitState } from '../../hooks/useERC20Permit'
import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import { useSwapCallbackV2 } from '../../hooks/useSwapCallbackV2'
import useToggledVersion, { Version } from '../../hooks/useToggledVersion'
import { useUSDCValue } from '../../hooks/useUSDCPrice'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from '../../state/swap/hooksV2'
import { useExpertModeManager } from '../../state/user/hooks'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { getTradeVersion } from '../../utils/getTradeVersion'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import AppBody from '../AppBody'

import { warningSeverity } from '../../utils/prices'

// faucet
import TokenSafetyModal from '../../components/TokenSafety/TokenSafetyModal'
import { useToggleWalletModal } from '../../state/application/hooks'
import { useWeb3React } from '@web3-react/core'
import useTheme from '../../hooks/useTheme'
import { TOKEN_SHORTHANDS } from '../../constants/tokens'
import { supportedChainId } from '../../utils/supportedChainId'
import { currencyAmountToPreciseFloat, formatTransactionAmount } from '../../utils/formatNumbers'
import { usePermit2Enabled } from '../../featureFlags/flags/permit2'
import usePermit, { PermitState } from '../../hooks/usePermit2'
import { UNIVERSAL_ROUTER_ADDRESS } from '@uniswap/universal-router-sdk'
import {
  useHasPendingApproval,
  useHasPendingApprovalV2,
  useTransactionAdder,
  useTransactionAdderV2,
} from '../../state/transactions/hooks'
import { PERMIT2_ADDRESS_MAP } from '../../constants/addresses'
import { isSupportedChain, SupportedChainId } from '../../constants/chains'
import { PERMIT2_ADDRESS } from '@uniswap/permit2-sdk'
import { useTokenAllowance } from '../../hooks/useTokenAllowance'
import axios, { Method } from 'axios'

const arrowswap =
  'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/swap/arrowswap.png'

const ArrowContainer = styled.div`
  display: inline-block;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`

const SwapSection = styled.div`
  position: relative;
  background: transparent;
  padding: 16px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  span.token-symbol-container {
    font-size: 20px !important;
  }
`

const StyledInfo = styled(Info)`
  height: 16px;
  width: 16px;
  margin-left: 4px;
  color: ${({ theme }) => '#4B2828'};
  :hover {
    color: ${({ theme }) => 'rgba(239, 239, 228, 0.5)'};
  }
`
const ArrowSection = styled.div`
  width: 30%;
`

const OutputSwapSection = styled(SwapSection) <{ showDetailsDropdown: boolean }>`
  border-bottom-left-radius: ${({ showDetailsDropdown }) => showDetailsDropdown && '0'};
  border-bottom-right-radius: ${({ showDetailsDropdown }) => showDetailsDropdown && '0'};
`

const DetailsSwapSection = styled(SwapSection)`
  padding: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`

interface HandleAddCustomTokenParams {
  symbol: string
  contractAddress: string
  decimals: number
  tokenLogoURL: string
}

interface HandleAddCustomTokenFunc {
  (params: HandleAddCustomTokenParams): Promise<void>
}

export function getIsValidSwapQuote(
  trade: V2Trade<Currency, Currency, TradeType> | V3Trade<Currency, Currency, TradeType> | null,
  tradeState: V3TradeState,
  swapInputError?: ReactNode
): boolean {
  return !!swapInputError && !!trade && (tradeState === V3TradeState.VALID || tradeState === V3TradeState.SYNCING)
}

export default function SwapV2() {
  const navigate = useNavigate()
  const { account, chainId } = useWeb3React()
  const loadedUrlParams = useDefaultsFromURLSearch()
  const [newSwapQuoteNeedsLogging, setNewSwapQuoteNeedsLogging] = useState(true)
  const [fetchingSwapQuoteStartTime, setFetchingSwapQuoteStartTime] = useState<Date | undefined>()

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c?.isToken ?? false) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens()
  const importTokensNotInDefault = useMemo(
    () =>
      urlLoadedTokens &&
      urlLoadedTokens
        .filter((token: Token) => {
          return !Boolean(token.address in defaultTokens)
        })
        .filter((token: Token) => {
          // Any token addresses that are loaded from the shorthands map do not need to show the import URL
          const supported = supportedChainId(chainId)
          if (!supported) return true
          return !Object.keys(TOKEN_SHORTHANDS).some((shorthand) => {
            const shorthandTokenAddress = TOKEN_SHORTHANDS[shorthand][supported]
            return shorthandTokenAddress && shorthandTokenAddress === token.address
          })
        }),
    [chainId, defaultTokens, urlLoadedTokens]
  )

  const theme = useTheme()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  // toggle wallet when disconnected
  const toggleWalletModal = useToggleWalletModal()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()

  const toggledVersion = Version.v2

  const {
    v3Trade: { state: v3TradeState },
    bestTrade: trade,
    allowedSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo(toggledVersion)

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
          [Field.INPUT]: parsedAmount,
          [Field.OUTPUT]: parsedAmount,
        }
        : {
          [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
          [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
        },
    [independentField, parsedAmount, showWrap, trade]
  )

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [
      trade instanceof V3Trade ? !trade?.swaps : !trade?.route,
      V3TradeState.LOADING === v3TradeState,
      V3TradeState.SYNCING === v3TradeState,
    ],
    [trade, v3TradeState]
  )

  const fiatValueInput = useUSDCValue(parsedAmounts[Field.INPUT])
  const fiatValueOutput = useUSDCValue(parsedAmounts[Field.OUTPUT])
  const priceImpact = useMemo(
    () => (routeIsSyncing ? undefined : computeFiatValuePriceImpact(fiatValueInput, fiatValueOutput)),
    [fiatValueInput, fiatValueOutput, routeIsSyncing]
  )

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = chainId === 1 ? false : !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
    navigate('/swap/')
  }, [navigate])

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: V2Trade<Currency, Currency, TradeType> | V3Trade<Currency, Currency, TradeType> | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : formatTransactionAmount(currencyAmountToPreciseFloat(parsedAmounts[dependentField])),
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue]
  )

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )

  const permit2Enabled = usePermit2Enabled()
  const maximumAmountIn = useMemo(() => {
    const maximumAmountIn = trade?.maximumAmountIn(allowedSlippage)
    return maximumAmountIn?.currency.isToken ? (maximumAmountIn as CurrencyAmount<Token>) : undefined
  }, [allowedSlippage, trade])
  const permit = usePermit(
    permit2Enabled ? maximumAmountIn : undefined,
    permit2Enabled && chainId ? UNIVERSAL_ROUTER_ADDRESS(chainId) : undefined
  )

  // check whether the user has approved the router on the input token
  const [approvalState, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)
  const {
    state: signatureState,
    signatureData,
    gatherPermitSignature,
  } = useERC20PermitFromTradeV2(trade, allowedSlippage)

  const [approvalPending, setApprovalPending] = useState<boolean>(false)

  const handleApprove = useCallback(async () => {
    setApprovalPending(true)
    try {
      if (signatureState === UseERC20PermitState.NOT_SIGNED && gatherPermitSignature) {
        try {
          await gatherPermitSignature()
        } catch (error) {
          // try to approve if gatherPermitSignature failed for any reason other than the user rejecting it
          if (error?.code !== 4001) {
            await approveCallback()
          }
        }
      } else {
        await approveCallback()
      }
    } finally {
      setApprovalPending(false)
    }
  }, [approveCallback, gatherPermitSignature, signatureState, toggledVersion, trade?.inputAmount.currency.symbol])

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approvalState === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approvalState, approvalSubmitted])

  const maxInputAmount: CurrencyAmount<Currency> | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallbackV2(
    trade,
    allowedSlippage,
    recipient,
    signatureData
  )

  const handleSwap = useCallback(() => {
    if (!swapCallback) {
      return
    }
    if (priceImpact && !confirmPriceImpactWithoutFee(priceImpact)) {
      return
    }
    setSwapState({
      attemptingTxn: true,
      tradeToConfirm,
      showConfirm: true,
      swapErrorMessage: undefined,
      txHash: undefined,
    })
    swapCallback()
      .then((hash) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm: true,
          swapErrorMessage: undefined,
          txHash: hash,
        })
        ReactGA.event({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
                ? 'Swap w/o Send + recipient'
                : 'Swap w/ Send',
          label: [
            trade?.inputAmount?.currency?.symbol,
            trade?.outputAmount?.currency?.symbol,
            getTradeVersion(trade),
            'MH',
          ].join('/'),
        })
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm: true,
          swapErrorMessage: error.message,
          txHash: undefined,
        })
      })
  }, [swapCallback, priceImpact, tradeToConfirm, showConfirm, recipient, recipientAddress, account, trade])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const [swapQuoteReceivedDate, setSwapQuoteReceivedDate] = useState<Date | undefined>()

  // warnings on the greater of fiat value price impact and execution price impact
  const priceImpactSeverity = useMemo(() => {
    const executionPriceImpact = trade?.priceImpact
    return warningSeverity(
      executionPriceImpact && priceImpact
        ? executionPriceImpact.greaterThan(priceImpact)
          ? executionPriceImpact
          : priceImpact
        : executionPriceImpact ?? priceImpact
    )
  }, [priceImpact, trade])

  const isArgentWallet = useIsArgentWallet()

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !isArgentWallet &&
    !swapInputError &&
    (approvalState === ApprovalState.NOT_APPROVED ||
      approvalState === ApprovalState.PENDING ||
      (approvalSubmitted && approvalState === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
  }, [maxInputAmount, onUserInput])

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => onCurrencySelection(Field.OUTPUT, outputCurrency),
    [onCurrencySelection]
  )

  const swapIsUnsupported = useIsSwapUnsupported(currencies[Field.INPUT], currencies[Field.OUTPUT])

  const priceImpactTooHigh = priceImpactSeverity > 3 && !isExpertMode

  // Handle time based logging events and event properties.
  useEffect(() => {
    const now = new Date()
    // If a trade exists, and we need to log the receipt of this new swap quote:
    if (newSwapQuoteNeedsLogging && !!trade) {
      // Set the current datetime as the time of receipt of latest swap quote.
      setSwapQuoteReceivedDate(now)
      setNewSwapQuoteNeedsLogging(false)
      // New quote is not being fetched, so set start time of quote fetch to undefined.
      setFetchingSwapQuoteStartTime(undefined)
    }
    // If another swap quote is being loaded based on changed user inputs:
    if (routeIsLoading) {
      setNewSwapQuoteNeedsLogging(true)
      if (!fetchingSwapQuoteStartTime) setFetchingSwapQuoteStartTime(now)
    }
  }, [
    newSwapQuoteNeedsLogging,
    routeIsSyncing,
    routeIsLoading,
    fetchingSwapQuoteStartTime,
    trade,
    setSwapQuoteReceivedDate,
  ])

  const approveTokenButtonDisabled =
    approvalState !== ApprovalState.NOT_APPROVED || approvalSubmitted || signatureState === UseERC20PermitState.SIGNED

  const showDetailsDropdown = Boolean(
    !showWrap && userHasSpecifiedInputOutput && (trade || routeIsLoading || routeIsSyncing)
  )
  // feth price
  const [priceInputFiate, setPriceInputFiate] = useState<string | undefined>()
  const [priceOnputFiate, setPriceOnputFiate] = useState<string | undefined>()
  // show price
  const headers = {
    Authorization: `Bearer auth=gmeow`,
    'Content-Type': 'application/json',
  }
  let addressTokenInput = currencies[Field?.INPUT] as any
  let addressTokenOutput = currencies[Field?.OUTPUT] as any
  useEffect(() => {
    const fetchTokenPrice = async () => {
      if (addressTokenOutput !== null) {
        try {
          let addressValue = addressTokenOutput.isNative
            ? '0x4300000000000000000000000000000000000004'
            : addressTokenOutput.address
          const res = await axios({
            method: 'get',
            url: `https://api.geckoterminal.com/api/v2/simple/networks/blast/token_price/${addressValue}`,
            headers,
          })
          if (res) {
            const price = res ? (Object.values(res.data.data.attributes.token_prices)[0] as string) : '0'
            console.log('Token Price:', price)
            setPriceOnputFiate(price)
          }
        } catch (error) {
          console.log('Error:', error)
        }
      }
    }

    fetchTokenPrice()
  }, [account, chainId, addressTokenOutput])
  useEffect(() => {
    const fetchTokenPrice = async () => {
      if (addressTokenInput !== null) {
        try {
          let addressValue = addressTokenInput.isNative
            ? '0x4300000000000000000000000000000000000004'
            : addressTokenInput.address
          const res = await axios({
            method: 'get',
            url: `https://api.geckoterminal.com/api/v2/simple/networks/blast/token_price/${addressValue}`,
            headers,
          })
          if (res) {
            const price = res ? (Object.values(res.data.data.attributes.token_prices)[0] as string) : '0'
            console.log('Token Price:', price)
            setPriceInputFiate(price)
          }
        } catch (error) {
          console.log('Error:', error)
        }
      }
    }

    fetchTokenPrice()
  }, [account, chainId, addressTokenInput])

  return (
    <>
      <TokenSafetyModal
        isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
        tokenAddress={importTokensNotInDefault[0]?.address}
        secondTokenAddress={importTokensNotInDefault[1]?.address}
        onContinue={handleConfirmTokenWarning}
        onCancel={handleDismissTokenWarning}
        showCancel={true}
      />
      <PageWrapper>
        {/* <SwapHeader allowedSlippage={allowedSlippage} /> */}
        <SwapWrapper id="swap-page">
          <ConfirmSwapModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            onConfirm={handleSwap}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
          />

          <div style={{ display: 'relative', minHeight: '96px' }}>
            <SwapSection>
              <CurrencyInputPanel
                label={
                  independentField === Field.OUTPUT && !showWrap ? <Trans>From (at most)</Trans> : <Trans>From</Trans>
                }
                value={formattedAmounts[Field.INPUT]}
                showMaxButton={showMaxButton}
                currency={currencies[Field.INPUT]}
                onUserInput={handleTypeInput}
                onMax={handleMaxInput}
                fiatValue={fiatValueInput ?? undefined}
                onCurrencySelect={handleInputSelect}
                otherCurrency={currencies[Field.OUTPUT]}
                showCommonBases={true}
                id="swap-currency-input"
                loading={independentField === Field.OUTPUT && routeIsSyncing}
                customeFiatValue={priceInputFiate}
              />
            </SwapSection>

          </div>
          <div>

            <ArrowSection>
              <ArrowWrapper clickable={isSupportedChain(chainId)}>
                <ArrowContainer
                  onClick={() => {
                    setApprovalSubmitted(false) // reset 2 step UI for approvals
                    onSwitchTokens()
                  }}
                  color={theme.textPrimary}
                >
                  {/* <ArrowDown
                  size="16"
                  color={
                    currencies[Field.INPUT] && currencies[Field.OUTPUT]
                      ? theme.deprecated_text1
                      : theme.deprecated_text3
                  }
                /> */}
                  <img src={arrowswap} alt="" />
                </ArrowContainer>
              </ArrowWrapper>
            </ArrowSection>
          </div>
          <AutoColumn gap="md">
            <div>
              <OutputSwapSection showDetailsDropdown={showDetailsDropdown}>
                <CurrencyInputPanel
                  value={formattedAmounts[Field.OUTPUT]}
                  onUserInput={handleTypeOutput}
                  label={
                    independentField === Field.INPUT && !showWrap ? <Trans>To (at least)</Trans> : <Trans>To</Trans>
                  }
                  showMaxButton={false}
                  hideBalance={false}
                  fiatValue={fiatValueOutput ?? undefined}
                  priceImpact={priceImpact}
                  currency={currencies[Field.OUTPUT]}
                  onCurrencySelect={handleOutputSelect}
                  otherCurrency={currencies[Field.INPUT]}
                  showCommonBases={true}
                  id="swap-currency-output"
                  loading={independentField === Field.INPUT && routeIsSyncing}
                  customeFiatValue={priceOnputFiate}
                />

                {recipient !== null && !showWrap ? (
                  <>
                    <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                      <ArrowWrapper clickable={false}>
                        <ArrowDown size="16" color={'#4B2828'} />
                      </ArrowWrapper>
                      <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                        <Trans>- Remove send</Trans>
                      </LinkStyledButton>
                    </AutoRow>
                    <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                  </>
                ) : null}
              </OutputSwapSection>

              {!showWrap && trade && (
                <Row justify={!trade ? 'center' : 'space-between'}>
                  <RowFixed style={{ position: 'relative' }}>
                    <MouseoverTooltipContent
                      wrap={false}
                      content={
                        <ResponsiveTooltipContainer width="100%">
                          <SwapRoute trade={trade} syncing={routeIsSyncing} />
                        </ResponsiveTooltipContainer>
                      }
                      placement="right"
                      onOpen={() =>
                        ReactGA.event({
                          category: 'Swap',
                          action: 'Router Tooltip Open',
                        })
                      }
                    >
                      <AutoRow gap="4px" width="auto">
                        <AutoRouterLogo />
                        <LoadingOpacityContainer $loading={routeIsSyncing}>
                          {trade instanceof V3Trade && trade.swaps.length > 1 && (
                            <TYPE.blue fontSize={14}>{trade.swaps.length} routes</TYPE.blue>
                          )}
                        </LoadingOpacityContainer>
                      </AutoRow>
                    </MouseoverTooltipContent>
                  </RowFixed>
                  <RowFixed>
                    <LoadingOpacityContainer $loading={routeIsSyncing}>
                      <TradePrice
                        price={trade.executionPrice}
                        showInverted={showInverted}
                        setShowInverted={setShowInverted}
                      />
                    </LoadingOpacityContainer>
                    <MouseoverTooltipContent
                      wrap={false}
                      content={
                        <ResponsiveTooltipContainer origin="top right" width={'295px'}>
                          <AdvancedSwapDetails
                            trade={trade}
                            allowedSlippage={allowedSlippage}
                            syncing={routeIsSyncing}
                          />
                        </ResponsiveTooltipContainer>
                      }
                      placement="bottom"
                      onOpen={() =>
                        ReactGA.event({
                          category: 'Swap',
                          action: 'Transaction Details Tooltip Open',
                        })
                      }
                    >
                      <StyledInfo />
                    </MouseoverTooltipContent>
                  </RowFixed>
                </Row>
              )}
            </div>
          </AutoColumn>
          <div style={{ height: '20px', width: '100%' }}></div>
        </SwapWrapper>
      </PageWrapper>
      <div className="swap-bot-button-container">
        <div>
          {swapIsUnsupported ? (
            <NotchedButtonFillPrimary disabled={true}>
              <TYPE.main mb="4px">
                <Trans>Unsupported Asset</Trans>
              </TYPE.main>
            </NotchedButtonFillPrimary>
          ) : !account ? (
            <NotchedButtonFill onClick={toggleWalletModal}>
              <Trans>CONNECT WALLET</Trans>
            </NotchedButtonFill>
          ) : showWrap ? (
            <NotchedButtonFillPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
              {wrapInputError ??
                (wrapType === WrapType.WRAP ? (
                  <Trans>WRAP</Trans>
                ) : wrapType === WrapType.UNWRAP ? (
                  <Trans>UNWRAP</Trans>
                ) : null)}
            </NotchedButtonFillPrimary>
          ) : routeIsSyncing || routeIsLoading ? (
            <GrayCard style={{ textAlign: 'center' }}>
              <TYPE.main mb="4px">
                <Dots>
                  <Trans>Loading</Trans>
                </Dots>
              </TYPE.main>
            </GrayCard>
          ) : routeNotFound && userHasSpecifiedInputOutput ? (
            <GrayCard style={{ textAlign: 'center' }}>
              <TYPE.main mb="4px">
                <Trans>Insufficient liquidity for this trade, SWITCH TO V3.</Trans>
              </TYPE.main>
            </GrayCard>
          ) : showApproveFlow ? (
            <AutoRow style={{ flexWrap: 'nowrap', width: '100%' }}>
              <AutoColumn style={{ width: '100%' }} gap="12px">
                <ButtonConfirmed
                  fontWeight={600}
                  onClick={handleApprove}
                  disabled={approveTokenButtonDisabled}
                  width="100%"
                  altDisabledStyle={approvalState === ApprovalState.PENDING} // show solid button while waiting
                  confirmed={approvalState === ApprovalState.APPROVED || signatureState === UseERC20PermitState.SIGNED}
                >
                  <AutoRow justify="space-between" style={{ flexWrap: 'nowrap' }} height="20px">
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <CurrencyLogo
                        currency={currencies[Field.INPUT]}
                        size={'20px'}
                        style={{ marginRight: '8px', flexShrink: 0 }}
                      />
                      {/* we need to shorten this string on mobile */}
                      {approvalState === ApprovalState.APPROVED || signatureState === UseERC20PermitState.SIGNED ? (
                        <span style={{ color: '#FFF9E1' }}>
                          <Trans>You can now trade {currencies[Field.INPUT]?.symbol}</Trans>
                        </span>
                      ) : (
                        <Trans>
                          {/* Allow the Gmeow Protocol to use your {currencies[Field.INPUT]?.symbol} */}
                          Approve your trade
                        </Trans>
                      )}
                    </span>
                    {approvalPending || approvalState === ApprovalState.PENDING ? (
                      <Loader stroke={theme.white} />
                    ) : (approvalSubmitted && approvalState === ApprovalState.APPROVED) ||
                      signatureState === UseERC20PermitState.SIGNED ? (
                      <CheckCircle size="20" color={theme.deprecated_green1} />
                    ) : (
                      <MouseoverTooltip
                        text={
                          <Trans>
                            You must give the Gmeow smart contracts permission to use your{' '}
                            {currencies[Field.INPUT]?.symbol}. You only have to do this once per token.
                          </Trans>
                        }
                      >
                        <HelpCircle size="20" color={theme.deprecated_white} style={{ marginLeft: '8px' }} />
                      </MouseoverTooltip>
                    )}
                  </AutoRow>
                </ButtonConfirmed>
                <ButtonError
                  onClick={() => {
                    if (isExpertMode) {
                      handleSwap()
                    } else {
                      setSwapState({
                        tradeToConfirm: trade,
                        attemptingTxn: false,
                        swapErrorMessage: undefined,
                        showConfirm: true,
                        txHash: undefined,
                      })
                    }
                  }}
                  width="100%"
                  id="swap-button"
                  disabled={
                    !isValid ||
                    (approvalState !== ApprovalState.APPROVED && signatureState !== UseERC20PermitState.SIGNED) ||
                    priceImpactTooHigh
                  }
                  error={isValid && priceImpactSeverity > 2}
                >
                  <Text fontSize={16} fontWeight={500}>
                    {priceImpactTooHigh ? (
                      <Trans>High Price Impact</Trans>
                    ) : priceImpactSeverity > 2 ? (
                      <Trans>Swap Anyway</Trans>
                    ) : (
                      <Trans>Swap</Trans>
                    )}
                  </Text>
                </ButtonError>
              </AutoColumn>
            </AutoRow>
          ) : (
            <ButtonError
              onClick={() => {
                if (isExpertMode) {
                  handleSwap()
                } else {
                  setSwapState({
                    tradeToConfirm: trade,
                    attemptingTxn: false,
                    swapErrorMessage: undefined,
                    showConfirm: true,
                    txHash: undefined,
                  })
                }
              }}
              id="swap-button"
              disabled={
                !isValid ||
                routeIsSyncing ||
                routeIsLoading ||
                priceImpactTooHigh ||
                (permit2Enabled ? permit.state === PermitState.LOADING : Boolean(swapCallbackError))
              }
              error={isValid && priceImpactSeverity > 2 && (permit2Enabled || !swapCallbackError)}
            >
              <Text fontSize={20} fontWeight={500}>
                {swapInputError ? (
                  swapInputError
                ) : priceImpactTooHigh ? (
                  <Trans>Price Impact Too High</Trans>
                ) : priceImpactSeverity > 2 ? (
                  <Trans>Swap Anyway</Trans>
                ) : (
                  <Trans>Swap</Trans>
                )}
              </Text>
            </ButtonError>
          )}
          {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
        </div>
      </div>
      {!swapIsUnsupported ? null : (
        <UnsupportedCurrencyFooter
          show={swapIsUnsupported}
          currencies={[currencies[Field.INPUT], currencies[Field.OUTPUT]]}
        />
      )}
    </>
  )
}
