import styled from "styled-components/macro";
import { NotchedButtonFill } from "../../../components/Button";
import { useEffect, useMemo, useState } from "react";
import { BREAKPOINTS } from "../../../theme";
import { TaskType, useDailyTaskStore } from "state/dailyTask/useDailyTaskStore";
import { formatNumber } from "utils/formatNumbers";
import { useWeb3React } from "@web3-react/core";
import { GMEOW_API_BASE_URL } from "utils/env";
import { notification } from "antd";
import { LoadingOutlined } from '@ant-design/icons'
import axios from 'axios'
import { shortenAddress } from "utils";
import { useLocation, useParams } from "react-router-dom";
import useCountdownNumber from "hooks/useCountdownNumber";
import { truncateAddress } from "utils/stringUtils";

const WrapperFaucetSection = styled.section`
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
`

const FaucetWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 720px;

    .content {
        width: 100%;
        max-width: 720px;
        border-radius: 10px;
        background: #FFF6D0;
        box-shadow: 0px 7.395px 30px 0px rgba(145, 103, 20, 0.30), -6px -6px 4px 0px rgba(215, 144, 60, 0.60) inset, 6px 6px 4px 0px rgba(255, 255, 255, 0.80) inset;

        .content-inner {
            padding: 40px;
            display: flex;
            flex-direction: column;
            @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                padding: 20px;
            }

            .title {
                p {
                    color: #4B2828;
                    font-family: DynaPuff;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    margin: 0;
                    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                        font-size: 24px;
                    }
                }
            }

            .block {
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-top: 32px;
                @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                    margin-top: 15px;
                }

                .block-content-wrapper {
                    border-radius: 10px;
                    background: #FFF9E1;
                    box-shadow: 0px 4px 20px 0px rgba(145, 103, 20, 0.12);

                    .content-inner-block {
                        padding: 20px 24px;
                        display: flex;
                        justify-content: space-between;
                        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                            padding: 10px 14px;
                        }

                        .content-left {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 8px;

                            img {
                                width: 32px;
                                height: 32px;
                                @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                                    width: 22px;
                                    height: 22px;
                                }
                            }

                            p {
                                margin: 0;
                                color: #4B2828;
                                font-family: Outfit;
                                font-size: 21px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 100%;
                                @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                                    font-size: 14px;
                                }
                            }
                        }

                        .content-right {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 8px;
                        }
                    }
                }
            }

            .note {
                margin-top: 24px;
                @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                    margin-top: 10px;
                }

                p {
                    margin: 0;
                    color: #E5B670;
                    font-family: Outfit;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%; /* 16px */
                    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                        font-size: 12px;
                    }
                }
            }
        }
    }
`

const DailyTasksWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 720px;

    .content {
        width: 100%;
        max-width: 720px;
        border-radius: 10px;
        background: #FFF6D0;
        box-shadow: 0px 7.395px 30px 0px rgba(145, 103, 20, 0.30), -6px -6px 4px 0px rgba(215, 144, 60, 0.60) inset, 6px 6px 4px 0px rgba(255, 255, 255, 0.80) inset;

        .before-enter-invitation-code-wrapper {
            margin-top: 12px;
            display: flex;
            flex-direction: column;
            gap: 32px;

            @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                gap: 15px;
            }

            p {
                margin: 0;
            }


            .desc-txt {
                color: #4B2828;
                font-family: Outfit;
                font-size: 21px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 21px */
                @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                    font-size: 16px;
                }
            }

            .before-enter-invitation-code-content {
                display: grid;
                grid-template-columns: 50% 50%;
                gap: 12px;
                @media (max-width: ${BREAKPOINTS.lg}px) {
                    grid-template-columns: 100%;
                }

                .content-left {
                    padding: 24px;
                    border-radius: 10px;
                    background: #FFF9E1;
                    box-shadow: 0px 4px 20px 0px rgba(145, 103, 20, 0.12);
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    width: 100%;

                    .ref-link-txt-title {
                        color: rgba(75, 40, 40, 0.50);
                        font-family: Outfit;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%; /* 14px */
                    }

                    .ref-link-content {
                        display: flex;
                        gap: 8px;
                        justify-content: space-between;

                        .link-txt {
                            color: #4B2828;
                            font-family: Outfit;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%; /* 18px */
                            word-break: break-all;
                            @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                                font-size: 14px;
                            }
                        }

                        button {
                            border-radius: 50px;
                            background: #00E440;
                            border: none;
                            cursor: pointer;

                            p {
                                color: #FFF9E1;
                                text-align: center;
                                font-family: Outfit;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 100%; /* 14px */
                                padding: 4px 6px;
                            }
                        }
                    }
                }

                .content-right {
                    padding: 24px;
                    border-radius: 10px;
                    border: 2px solid #E5B670;
                    background: #FFF6D0;
                    box-shadow: 4px 4px 4px 0px rgba(215, 144, 60, 0.80) inset, -4px -4px 4px 0px #FFF inset;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    .invite-code {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        p {
                            color: rgba(75, 40, 40, 0.50);
                            font-family: Outfit;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%; /* 14px */
                        }

                        input { 
                            color: #4B2828;
                            font-family: Outfit;
                            font-size: 21px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 100%; /* 21px */
                            width: 100%;
                            background: transparent;
                            border: none;
                            outline: none;
                            &::placeholder{
                                color: rgba(75, 40, 40, 0.50);
                            }
                        }
                    }

                    .button-check {
                        display: flex;
                        align-items: end;
                    }
                }
            }
        }

        .content-inner {
            padding: 40px;
            display: flex;
            flex-direction: column;
            @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                padding: 20px;
            }

            .title {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .daily-task {
                    p {
                        color: #4B2828;
                        font-family: DynaPuff;
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        margin: 0;
                        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                            font-size: 24px !important;
                        }
                    }
                }

                .MP-balance {
                    display: flex;
                    gap: 8px;

                    p {
                        margin: 0;
                        color: #4B2828;
                        text-align: right;
                        font-family: Outfit;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 00;
                        line-height: 125%; /* 25px */
                        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                            font-size: 16px !important;
                        }
                    }

                    span {
                        color: #05C63B;
                        font-family: Outfit;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 125%;
                        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                            font-size: 16px !important;
                        }
                    }
                }

            }

            .ref-link-wrapper {
                display: flex;
                flex-direction: column;
                gap: 8px;
                margin-top: 24px;

                @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                    margin-top: 15px;
                }

                p {
                    margin: 0;
                }

                .ref-link-txt-title {
                    color: rgba(75, 40, 40, 0.50);
                    font-family: Outfit;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%; /* 14px */
                }

                .ref-link-content {
                    display: flex;
                    gap: 8px;
                    justify-content: space-between;
                    align-items: center;

                    .link-txt {
                        color: #4B2828;
                        font-family: Outfit;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%; /* 18px */
                        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                            font-size: 14px;
                        }
                    }

                    button {
                        border-radius: 50px;
                        background: #00E440;
                        border: none;
                        cursor: pointer;

                        p {
                            color: #FFF9E1;
                            text-align: center;
                            font-family: Outfit;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 100%; /* 14px */
                            padding: 4px 6px;
                        }
                    }
                }
            }

            .line-daily-tasks {
                margin: 24px 0;
                width: 100%;
                height: 1px;
                background: rgba(229, 182, 112, 0.50);
                backdrop-filter: blur(4.800000190734863px);
                @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                    margin: 15px 0;
                }
            }

            .block {
                display: flex;
                flex-direction: column;
                gap: 12px;
                @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                    margin-top: 15px;
                    gap: 8px;
                }

                .block-content-wrapper {
                    border-radius: 10px;
                    background: #FFF9E1;
                    box-shadow: 0px 4px 20px 0px rgba(145, 103, 20, 0.12);

                    .content-inner-block {
                        padding: 20px 24px;
                        display: flex;
                        justify-content: space-between;
                        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                            padding: 10px 14px;
                        }

                        .content-left {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 8px;

                            img {
                                width: 32px;
                                height: 32px;
                                @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                                    width: 22px;
                                    height: 22px;
                                }
                            }

                            p {
                                margin: 0;
                                color: #4B2828;
                                font-family: Outfit;
                                font-size: 21px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 100%;
                                @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                                    font-size: 14px;
                                }
                            }
                        }

                        .content-right {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 8px;
                        }
                    }
                }
            }
        }
    }
`

const ReferralWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 720px;

    .content {
        width: 100%;
        max-width: 720px;
        border-radius: 10px;
        background: #FFF6D0;
        box-shadow: 0px 7.395px 30px 0px rgba(145, 103, 20, 0.30), -6px -6px 4px 0px rgba(215, 144, 60, 0.60) inset, 6px 6px 4px 0px rgba(255, 255, 255, 0.80) inset;

        .content-inner {
            padding: 40px;
            display: flex;
            flex-direction: column;
            @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                padding: 20px;
            }

            .title {
                display: flex;
                flex-direction: column;
                gap: 12px;
                @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                    gap: 15px;
                }

                .referral {
                    p {
                        color: #4B2828;
                        font-family: DynaPuff;
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        margin: 0;
                        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                            font-size: 24px !important;
                        }
                    }
                }

                .desc {
                    p {
                        margin: 0;
                        color: #4B2828;
                        font-family: Outfit;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%; /* 21px */
                        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                            font-size: 16px !important;
                        }
                    }
                }

            }

            .block {
                display: flex;
                flex-direction: column;
                gap: 12px;
                margin-top: 32px;
                @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                    margin-top: 15px;
                    gap: 8px;
                }

                .block-content-wrapper {
                    border-radius: 10px;
                    background: #FFF9E1;
                    box-shadow: 0px 4px 20px 0px rgba(145, 103, 20, 0.12);

                    .content-inner-block {
                        padding: 16px 24px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                            padding: 8px 14px;
                        }

                        .content-left {
                            p {
                                margin: 0;
                                color: #4B2828;
                                font-family: Outfit;
                                font-size: 21px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 100%; /* 21px */
                                @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                                    font-size: 14px;
                                }
                            }
                        }

                        .content-right {
                            img {
                                width: 32px;
                                height: 32px;
                                @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }
                    }
                }

                .block-list-friends-wrapper {
                    border-radius: 10px;
                    background: #FFF9E1;
                    box-shadow: 0px 4px 20px 0px rgba(145, 103, 20, 0.12);
                    height: 235px;
                    overflow-y: auto;

                    .content-inner-block {
                        padding: 24px;
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
                        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                            padding: 14px;
                            gap: 5px;
                        }

                        .title {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;

                            .title-right {
                                p {
                                    color: #4B2828;
                                    font-family: Outfit;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 100%; /* 18px */
                                    margin: 0;
                                    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                                        font-size: 14px;
                                    }
                                }
                            }

                            .title-left {
                                display: flex;
                                gap: 11px;
                                justify-content: center;
                                align-items: center;

                                img {
                                    width: 24px;
                                    height: 24px;
                                    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                                        width: 18px;
                                        height: 18px;
                                    }
                                }

                                p {
                                    color: #05C63B;
                                    text-align: right;
                                    font-family: Outfit;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 100%; /* 18px */
                                    margin: 0;
                                    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }

                    .list-address {
                        .item {
                            display: flex;
                            justify-content: space-between;
                            padding: 12px 0;
                            @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                                padding: 8px 0;
                            }

                            .item-left {
                                color: rgba(75, 40, 40, 0.70);
                                font-family: Outfit;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 100%; /* 16px */
                                margin: 0;
                                @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                                    font-size: 12px;
                                }
                            }

                            .item-right {
                                color: #05C63B;
                                text-align: right;
                                font-family: Outfit;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 100%; /* 16px */
                                margin: 0;
                                @media screen and (max-width: ${BREAKPOINTS.sm}px) {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

function FaucetWrapperComponent() {
    return <FaucetWrapper style={{ display: 'none' }}>
        <div className={"content"}>
            <div className={"content-inner"}>
                <div className={"title"}>
                    <p>Faucet</p>
                </div>
                <div className="block">
                    <div className="block-content-wrapper">
                        <div className={"content-inner-block"}>
                            <div className={"content-left"}>
                                <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/swap/img_1.png" alt="img_1" />
                                <p>Blast Sepolia ETH</p>
                            </div>
                            <div className={"content-right"}>
                                <NotchedButtonFill padding={'0px 17.5px'} mobilePadding={'0px 5px'}
                                    fontSize={'16px'} mbFontSizeXs={'12px'}
                                    width={'180px'}
                                    height={"32px"} mbHeight={"28px"}
                                    earMaxW={"88px"} mbEarMaxW={"75px"}
                                    isHideSwitchchain={true}>
                                    Bridge to Zircuit
                                </NotchedButtonFill>
                                <NotchedButtonFill padding={'0px 17.5px'} mobilePadding={'0px 15px'}
                                    fontSize={'16px'} mbFontSizeXs={'12px'}
                                    width={'100px'}
                                    height={"32px"} mbHeight={"28px"}
                                    earMaxW={"68.51px"} mbEarMaxW={"50px"}
                                    bg={"#00E440"}
                                    isHideSwitchchain={true}>
                                    <p style={{ color: "#fff", margin: 0 }}>Claim</p>
                                </NotchedButtonFill>
                            </div>
                        </div>
                    </div>
                    <div className="block-content-wrapper">
                        <div className="block-content-wrapper">
                            <div className={"content-inner-block"}>
                                <div className={"content-left"}>
                                    <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/swap/img_2.png" alt="img_2" />
                                    <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/swap/img_3.png" alt="img_3" />
                                    <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/swap/img_4.png" alt="img_4" />
                                    <p>Gmeow tokens</p>
                                </div>
                                <div className={"content-right"}>
                                    <NotchedButtonFill padding={'0px 17.5px'} mobilePadding={'0px 15px'}
                                        fontSize={'16px'} mbFontSizeXs={'12px'}
                                        width={'100px'}
                                        height={"32px"} mbHeight={"28px"}
                                        earMaxW={"68.51px"} mbEarMaxW={"50px"}
                                        bg={"#00E440"}
                                        isHideSwitchchain={true}
                                    >
                                        <p style={{ color: "#fff" }}>Claim</p>
                                    </NotchedButtonFill>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"note"}>
                    <p>Request 0.05 ETH every 24 hours. Next request in 23:57:36</p>
                </div>
            </div>
        </div>
    </FaucetWrapper>
}

export class LocalStorageTaskUtils {
    static getItem(key: string) {
        return JSON.parse(localStorage.getItem(key) ?? '{}')
    }
    static setTrueItem(key: string, account: string) {
        const payload = this.getItem(key)
        payload[account] = 1
        localStorage.setItem(key, JSON.stringify(payload))
    }
    static hasDoneTask(key: string, account: string) {
        const payload = this.getItem(key)
        return payload[account] == 1
    }
}
function TaskItem({
    title,
    taskInfo
}: {
    title: string
    taskInfo?: TaskType
}) {
    const { account } = useWeb3React()
    const [isCheckingTask, setIsCheckingTask] = useState<boolean>(false)
    const [taskStatus, setTaskStatus] = useState<boolean>(false)

    const [countdownNumberValue, setCountdownNumberValue] = useState<number>(0)
    const countdownNumber = useCountdownNumber(countdownNumberValue)

    const checkTask = async () => {
        if (isCheckingTask || !taskInfo || countdownNumber > 0) return

        try {
            setIsCheckingTask(true)
            if ([2, 3, 10].includes(taskInfo.taskId)) {
                const response = await fetch(`${GMEOW_API_BASE_URL}/airdrop/check-task`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        address: account?.toLowerCase(),
                        taskId: taskInfo.taskId
                    })
                })
                const result = await response.json()
                if ([400, 500].includes(result.statusCode)) {
                    notification.error({
                        message: result.message
                    })
                    setCountdownNumberValue(15)
                    return
                }
                setTaskStatus(true)
                notification.success({
                    message: "You can claim now"
                })
            }
            else {
                if (account)
                    LocalStorageTaskUtils.setTrueItem(`task_id${taskInfo.taskId}`, account)
                if (taskInfo.taskId == 4) window.open('https://x.com/intent/follow?screen_name=gmeowfi', '_blank');
                else if (taskInfo.taskId == 9) window.open('https://x.com/intent/follow?screen_name=ZircuitDaily', '_blank');
                else if (taskInfo.taskId == 5) {
                    const twitterRetweetUrl = 'https://x.com/intent/retweet?tweet_id=1810973397175914776';
                    window.open(twitterRetweetUrl, '_blank')
                }
                else if (taskInfo.taskId == 11) {
                    const twitterRetweetUrl = 'https://x.com/intent/retweet?tweet_id=1811320065515737497';
                    window.open(twitterRetweetUrl, '_blank')
                }
                else if (taskInfo.taskId == 12) {
                    const twitterRetweetUrl = 'https://x.com/intent/retweet?tweet_id=1811520599992467637';
                    window.open(twitterRetweetUrl, '_blank')
                }
                else if (taskInfo.taskId == 13) {
                    const twitterRetweetUrl = 'https://x.com/intent/retweet?tweet_id=1812267638762197283';
                    window.open(twitterRetweetUrl, '_blank')
                }
                else if (taskInfo.taskId == 14) {
                    const twitterRetweetUrl = 'https://x.com/intent/follow?screen_name=zircatcoin';
                    window.open(twitterRetweetUrl, '_blank')
                }
                else if (taskInfo.taskId == 15) {
                    const twitterRetweetUrl = 'https://x.com/intent/retweet?tweet_id=1814747189652169005';
                    window.open(twitterRetweetUrl, '_blank')
                }
                setTimeout(() => {
                    setTaskStatus(true)
                    setIsCheckingTask(false)
                    notification.success({
                        message: "You can claim now"
                    })
                }, 5000)
            }

        }
        catch (err) {
        }
        finally {
            if ([2, 3].includes(taskInfo.taskId))
                setIsCheckingTask(false)
        }
    }
    const claimTask = async () => {
        if (isCheckingTask || !taskInfo) return
        setIsCheckingTask(true)
        fetch(`${GMEOW_API_BASE_URL}/airdrop/claim-task`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                address: account?.toLowerCase(),
                taskId: taskInfo.taskId
            })
        })
            .then(res => res.json())
            .then(data => {
                if ([400, 500].includes(data.statusCode)) throw 'failed'

                notification.success({
                    message: "Claim task successful"
                })
                let key = undefined
                if (taskInfo.type == 'DAILY_CHECK_IN')
                    key = 'dailyCheckInTask'
                else if (taskInfo.type == 'FOLLOW' && taskInfo.taskId == 4)
                    key = 'followTask'
                else if (taskInfo.type == 'FOLLOW' && taskInfo.taskId == 9)
                    key = 'followTask1'
                else if (taskInfo.type == 'FOLLOW' && taskInfo.taskId == 14)
                    key = 'followTask2'
                else if (taskInfo.type == 'RE_TWEET' && taskInfo.taskId == 5)
                    key = 'reTweetTask'
                else if (taskInfo.type == 'RE_TWEET' && taskInfo.taskId == 11)
                    key = 'reTweetTask1'
                else if (taskInfo.type == 'RE_TWEET' && taskInfo.taskId == 12)
                    key = 'reTweetTask2'
                else if (taskInfo.type == 'RE_TWEET' && taskInfo.taskId == 13)
                    key = 'reTweetTask3'
                 else if (taskInfo.type == 'RE_TWEET' && taskInfo.taskId == 15)
                    key = 'reTweetTask4'
                else if (taskInfo.type == 'ADD_NFT')
                    key = 'addNftTask'
                else if (taskInfo.type == 'SWAP')
                    key = 'swapTask'
                else if (taskInfo.type == 'ADD_LIQUIDITY')
                    key = 'addLiquidityTask'

                if (key)
                    useDailyTaskStore.setState({
                        [key]: {
                            ...taskInfo,
                            taskStatus: true,
                            hasClaimed: true,
                        },
                        totalTokenClaimed: useDailyTaskStore.getState().totalTokenClaimed + (taskInfo?.reward ?? 0)
                    })
            })
            .catch(err => {
                console.log('claim task failed: ', err);
                notification.error({
                    message: "Claim task failed"
                })
            })
            .finally(() => setIsCheckingTask(false))
    }

    useEffect(() => {
        if (taskInfo) {
            if (![4, 5, 9, 11, 12, 13, 14, 15].includes(taskInfo.taskId))
                setTaskStatus(taskInfo.taskStatus)
            else setTaskStatus(LocalStorageTaskUtils.hasDoneTask(`task_id${taskInfo.taskId}`, account ?? ''))
        }
    }, [taskInfo])

    useEffect(() => {
        if (countdownNumber <= 0) setCountdownNumberValue(0)
    }, [countdownNumber])

    return <div className="block-content-wrapper">
        <div className={"content-inner-block"}>
            <div className={"content-left"}>
                <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/swap/img_6.png" alt="img_6" />
                <p>{title}</p>
            </div>
            {
                !taskInfo?.hasClaimed ? <div className={"content-right"}>
                    {
                        isCheckingTask ? <NotchedButtonFill padding={'0px 17.5px'} mobilePadding={'0px 15px'}
                            fontSize={'16px'} mbFontSizeXs={'12px'}
                            width={'100px'}
                            height={"32px"} mbHeight={"28px"}
                            earMaxW={"68.51px"} mbEarMaxW={"50px"}
                            isHideSwitchchain={true}
                        >
                            <LoadingOutlined rev={undefined} />
                        </NotchedButtonFill> : <>
                            {
                                taskStatus
                                    ?
                                    <NotchedButtonFill padding={'0px 17.5px'} mobilePadding={'0px 15px'}
                                        fontSize={'16px'} mbFontSizeXs={'12px'}
                                        width={'100px'}
                                        height={"32px"} mbHeight={"28px"}
                                        earMaxW={"68.51px"} mbEarMaxW={"50px"}
                                        bg={"#00E440"}
                                        onClick={claimTask}
                                        isHideSwitchchain={true}
                                    >
                                        <p style={{ color: "#fff", margin: 0 }}>Claim</p>
                                    </NotchedButtonFill>
                                    :

                                    <NotchedButtonFill padding={'0px 17.5px'} mobilePadding={'0px 15px'}
                                        fontSize={'16px'} mbFontSizeXs={'12px'}
                                        width={'100px'}
                                        height={"32px"} mbHeight={"28px"}
                                        earMaxW={"68.51px"} mbEarMaxW={"50px"}
                                        onClick={checkTask}
                                        disabled={countdownNumber > 0}
                                        isHideSwitchchain={true}
                                    >
                                        {
                                            countdownNumber <= 0 ? 'Check' : countdownNumber
                                        }
                                    </NotchedButtonFill>
                            }
                        </>
                    }
                </div>
                    :
                    <div className="content-right" style={{ color: "#05C63B", fontWeight: 500 }}>
                        +{taskInfo?.reward} TREAT
                    </div>
            }

        </div>
    </div>
}
 
export default function Faucet() {
    const { account, provider } = useWeb3React()
    const location = useLocation()
    const pathParams = useParams()
    const dailyTaskStore = useDailyTaskStore()
    const [hasCopied, setHasCopied] = useState<boolean>(false)
    const refLink = useMemo(() => `${window.location.host}/#/swap/ref=${dailyTaskStore.refCode}`, [dailyTaskStore.refCode])

    const handleCopyRefLink = () => {
        if (!dailyTaskStore.refCode) return
        navigator.clipboard.writeText(refLink)
        setHasCopied(true)
        setTimeout(() => {
            setHasCopied(false)
        }, 5000)
    }

    const [refInputValue, setRefInputValue] = useState<string>()
    const [isCheckingRefcode, setIsCheckingRefcode] = useState<boolean>(false)
    const [hasFailedCheckRefcode, setHasFailedCheckRefcode] = useState<boolean>(false)
    const checkInputRefCode = async () => {
        if (!refInputValue) {
            notification.warning({
                message: 'Please enter invitation code'
            })
            return
        }
        else if (refInputValue.length < 6) {
            notification.warning({
                message: 'Invalid invitation code'
            })
            setHasFailedCheckRefcode(true)
            return
        }
        else if (refInputValue == dailyTaskStore.refCode.toUpperCase()) {
            notification.warning({
                message: 'Cannot use self refcode'
            })
            setHasFailedCheckRefcode(true)
            return
        }
        if (!account || isCheckingRefcode) return

        try {
            setIsCheckingRefcode(true)

            const signMess = refInputValue.toLowerCase()
            const signature = await provider?.send('personal_sign', [signMess, account]);
            await axios.post(`${GMEOW_API_BASE_URL}/airdrop/referral`, {
                address: account.toLowerCase(),
                referralBy: signMess,
                message: signMess,
                signature,
            });
            useDailyTaskStore.setState({
                hasEnteredRefcode: true,
                enteredRefcode: signMess.toUpperCase()
            })
            setHasFailedCheckRefcode(false)
        }
        catch (err) {
            console.log('errror');

            notification.error({
                message: 'Invalid invitation code'
            })
            setHasFailedCheckRefcode(true)
        }
        finally {
            setIsCheckingRefcode(false)
        }
    }
    useEffect(() => {
        if (!account) setRefInputValue('')
        else if (dailyTaskStore.hasEnteredRefcode)
            setRefInputValue(dailyTaskStore.enteredRefcode?.toUpperCase())
        else if (pathParams?.id)
            setRefInputValue(pathParams.id.toUpperCase().split('=')[1])
        else setRefInputValue('')
    }, [dailyTaskStore.hasEnteredRefcode])

    const pageUrl = '/swap'
    const isShowDailyTask = useMemo(() => dailyTaskStore?.hasConnectedX && dailyTaskStore?.hasEnteredRefcode, [dailyTaskStore.hasConnectedX, dailyTaskStore.hasEnteredRefcode])

    useEffect(() => {
        setTimeout(() => {
            if (window.location.href.includes('dailytask'))
                window.scrollTo({
                    top: 800,
                    left: 100,
                    behavior: 'smooth'
                })
        }, 200)
    }, [])
    return (
        <WrapperFaucetSection>
            <FaucetWrapperComponent />

            <DailyTasksWrapper>
                <div className={"content"}>
                    <div className={"content-inner"}>
                        <div className={"title"}>
                            <div className={"daily-task"}>
                                <p>Daily tasks</p>
                            </div>
                            <div className={"MP-balance"}>
                                <p>TREAT Balance:</p>
                                <span>{formatNumber(dailyTaskStore.totalTokenClaimed)}</span>
                            </div>
                        </div>
                        {!isShowDailyTask ?
                            <div className={"before-enter-invitation-code-wrapper"}>
                                <p className={"desc-txt"}>
                                    Enter invitation code to reveal your daily missions
                                </p>
                                <div className={'before-enter-invitation-code-content'}>
                                    <div className={'content-left'}>
                                        <p className={'ref-link-txt-title'}>Ref link</p>
                                        <div className={"ref-link-content"}>
                                            <p className={"link-txt"}>
                                                {refLink}
                                            </p>
                                            {
                                                hasCopied ? <button >
                                                    <p>Copied</p>
                                                </button>
                                                    : <button onClick={handleCopyRefLink} style={{ background: account ? '#00E440' : 'gray' }}>
                                                        <p>Copy</p>
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                    <div className={'content-right'}>
                                        <div className={'invite-code'}>
                                            <p>Enter invitation code</p>
                                            <input
                                                id="refcode_input" type="text"
                                                style={{ color: hasFailedCheckRefcode ? 'red' : '#4B2828' }}
                                                readOnly={isCheckingRefcode || dailyTaskStore.hasEnteredRefcode}
                                                value={refInputValue}
                                                onChange={(e) => { setRefInputValue(e.target.value.toUpperCase()); setHasFailedCheckRefcode(false) }}
                                                placeholder={'-----'}
                                                maxLength={6} />
                                        </div>
                                        <div className={'button-check'}>
                                            {
                                                dailyTaskStore.hasEnteredRefcode ?
                                                    <NotchedButtonFill padding={'0px 17.5px'} mobilePadding={'0px 15px'}
                                                        fontSize={'16px'} mbFontSizeXs={'12px'}
                                                        width={'100px'}
                                                        height={"32px"} mbHeight={"28px"}
                                                        earMaxW={"68.51px"} mbEarMaxW={"50px"}
                                                        bg={"#00E440"}
                                                        isShowDirectly={true}
                                                        onClick={() => {
                                                            window.location.href = `${GMEOW_API_BASE_URL}/auth/social-login/tw?user_address=${account}&redirect_to=${pageUrl}`
                                                        }}
                                                    >
                                                        <p style={{ color: "#fff", margin: 0 }}>
                                                            JOIN X
                                                        </p>
                                                    </NotchedButtonFill>
                                                    :
                                                    <NotchedButtonFill padding={'0px 17.5px'} mobilePadding={'0px 15px'}
                                                        fontSize={'16px'} mbFontSizeXs={'12px'}
                                                        width={'100px'}
                                                        height={"32px"} mbHeight={"28px"}
                                                        earMaxW={"68.51px"} mbEarMaxW={"50px"}
                                                        bg={"#00E440"}
                                                        disabled={!account}
                                                        onClick={checkInputRefCode}
                                                        isShowDirectly={true}
                                                    >
                                                        <p style={{ color: "#fff", margin: 0 }}>
                                                            {
                                                                isCheckingRefcode ? 'Checking...' : 'Check'
                                                            }
                                                        </p>
                                                    </NotchedButtonFill>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                            :
                            <div>
                                <div className={"ref-link-wrapper"}>
                                    <p className={'ref-link-txt-title'}>Ref link</p>
                                    <div className={"ref-link-content"}>
                                        <p className={"link-txt"}>
                                            {refLink}
                                        </p>
                                        {
                                            hasCopied ? <button >
                                                <p>Copied</p>
                                            </button>
                                                : <button onClick={handleCopyRefLink}>
                                                    <p>Copy</p>
                                                </button>
                                        }
                                    </div>
                                </div>
                                <div className={"line-daily-tasks"}></div>
                                <div className="block">
                                    <TaskItem title="Daily Check-in" taskInfo={dailyTaskStore.dailyCheckInTask} />
                                    <TaskItem title="Follow Gmeow on X" taskInfo={dailyTaskStore.followTask} />
                                    <TaskItem title="Follow Zircuit Daily on X" taskInfo={dailyTaskStore.followTask1} />
                                    <TaskItem title="Share the announcement" taskInfo={dailyTaskStore.reTweetTask} />
                                    <TaskItem title="Retweet post on X(1)" taskInfo={dailyTaskStore.reTweetTask1} />
                                    <TaskItem title="Retweet post on X(2)" taskInfo={dailyTaskStore.reTweetTask2} />
                                    <TaskItem title="Follow our launch partner on X" taskInfo={dailyTaskStore.followTask2} />
                                    <TaskItem title="Retweet our launch partner" taskInfo={dailyTaskStore.reTweetTask3} />
                                    <TaskItem title="Retweet post on X(3)" taskInfo={dailyTaskStore.reTweetTask4} />
                                    <TaskItem title="Make a trade with Gmeow" taskInfo={dailyTaskStore.swapTask} />
                                    <TaskItem title="Create some LP tokens" taskInfo={dailyTaskStore.addLiquidityTask} />
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </DailyTasksWrapper>


            <ReferralWrapper>
                <div className={"content"}>
                    <div className={"content-inner"}>
                        <div className={"title"}>
                            <div className={"referral"}>
                                <p>Referral</p>
                            </div>
                            <div className={"desc"}>
                                <p>Refer friends to earn 100 TREAT for each successful invitation</p>
                            </div>
                        </div>
                        <div className="block">
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px', marginTop: '20px' }}>
                                <span style={{ color: 'rgba(75, 40, 40, 0.70)', fontSize: '14px', fontFamily: 'Outfit', fontWeight: '500', textTransform: 'uppercase', wordWrap: 'break-word' }}>LEADERBOARD</span>
                                <span style={{ color: 'rgba(75, 40, 40, 0.70)', fontSize: '14px', fontFamily: 'Outfit', fontWeight: '500', textTransform: 'uppercase', wordWrap: 'break-word' }}>TREAT BALANCE</span>
                            </div>
                            <div className="block-list-friends-wrapper" style={{ height: '800px' }}>
                                <div className={"content-inner-block"}>

                                    <div className={"list-wrapper"}>
                                        {
                                            dailyTaskStore.leaderboardData.map((item, index) => {
                                                return (
                                                    <div key={index} className={"list-address"}
                                                        style={{ borderBottom: "2px solid #f8e8c5" }}>
                                                        <div className={"item"}>
                                                            <p className={"item-left"} style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                                                                {
                                                                    [1, 2, 3].includes(Number(item.rank)) ?
                                                                        <>
                                                                            <img style={{ height: '26px', width: '42px' }} src={`https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/dailytask/rank/top${item.rank}.png`} alt="gmeowfi" />
                                                                        </>
                                                                        : <span style={{ display: 'inline-block', width: '42px', textAlign: 'center' }}>{item.rank}</span>
                                                                }

                                                                {account && index == 0 ? 'You' : truncateAddress(item.address)}
                                                            </p>
                                                            <p className={"item-right"}>{formatNumber(item.totalClaimedXp)} TREAT</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            dailyTaskStore.isLoadingLeaderboard ? <LoadingOutlined rev={undefined} />
                                                :
                                                <>
                                                    {
                                                        dailyTaskStore.leaderboardData.length == 0 && <p>
                                                            The board is empty!
                                                        </p>
                                                    }
                                                </>
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </ReferralWrapper>
        </WrapperFaucetSection>
    )
}
