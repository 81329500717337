import { useEffect, useState } from 'react'
import './paw.css'
import { useToggleWalletModal } from 'state/application/hooks'
import { useWeb3React } from '@web3-react/core'
import { NotchedButtonFill } from 'components/Button'
import { CustomLightSpinner } from '../../theme'
import { MouseoverTooltip } from 'components/Tooltip'
import { PlusSquare } from 'react-feather'
import axios from 'axios'
import { getExplorerLink, ExplorerDataType } from 'utils/getExplorerLink'
import { ExternalLink } from '../../theme'

// import
import { useCustomeContract } from 'hooks/useContract'
import musd_abi from '../../abis/musd/musd.json'
import usdb_abi from '../../abis/musd/usdb.json'
import { ethers } from 'ethers'
import MintMusdModal from 'components/Musd/MintMusdModal'
import RedeemMusdModal from 'components/Musd/RedeemMusdModal'
import { getContract } from 'utils'
import { SupportedChainId } from 'constants/chains'
import { RPC_URLS } from 'constants/networks'
import { MusdPriceChart } from './MusdPriceChart'
import { BASE_API } from 'pages/Xp'
import { formatXpNumber } from 'pages/Xp/sections/XpTitle'
import CollateralChart from './CollateralChart'
import { Pagination } from 'antd'
import { PrevNextRender } from 'pages/Xp/sections/ClaimedXpHistory'
import checkUnsupportChainId from 'utils/checkUnsupportChainId'
import PawTopSection from './sections/PawTopSection'
import PawBottomSection from './sections/PawBottomSection'
import { GMEOW_API_BASE_URL } from 'utils/env'
import { formatNumber } from 'utils/formatNumbers'

const musdlogo = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/paw/pair_token.png'
const musdIcon = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/tokens/paw.png'
const usdbIcon = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/tokens/usdt.png'
const circular = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/paw/colateral_icon.png'
const outlink = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/paw/link_icon.png'

const Circle = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/blue-loader.svg'


const BLAST_USDB_ADDRESSES = "0x2b533475875b518144c2Cf8558d799BC6FB11716"
const BLAST_MUSD_ADDRESSES = "0x156563d6bA40F03480A40AEa7F91392F7974C9F9"
interface HandleAddCustomTokenParams {
  symbol: string
  contractAddress: string
  decimals: number
  tokenLogoURL: string
}
interface HandleAddCustomTokenFunc {
  (params: HandleAddCustomTokenParams): Promise<void>
}

type MusdHistoryEnumType = 'MINT' | 'REDEEM' | 'ADD_COL' | 'ALL'
export type MusdHistoryType = {
  musdType: MusdHistoryEnumType
  amount: number
  createdAt: number
  hash: string
}
type MusdHistoryPageType = {
  data: MusdHistoryType[]
  totalElement: number
}

export type MusdStatType = {
  price: number
  supply: number
  collateral: number
  createdAt: number
  timestamp: number
}

export type TimePeriodType = 'HOUR' | 'DAY' | 'WEEK' | '1MONTH' | 'MONTH'

const MusdPage = () => {
  const toggleWalletModal = useToggleWalletModal()
  const { account, chainId } = useWeb3React()

  const TOKEN_ARRAY = {
    USDT: {
      symbol: 'USDT',
      contractAddress: BLAST_USDB_ADDRESSES,
      decimals: 6,
      tokenLogoURL:
        'https://raw.githubusercontent.com/gmeow-fi/token-list/main/img/zircuit_testnet/assets/0x2b533475875b518144c2Cf8558d799BC6FB11716/logo.png',
    },
    PAW: {
      symbol: 'PAW',
      contractAddress: BLAST_MUSD_ADDRESSES,
      decimals: 6,
      tokenLogoURL:
        'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/tokens/paw.png',
    },
  }
  const [currentTab, setCurrentTab] = useState(1)

  const [txStatus, setTxStatus] = useState<'pending' | 'failed' | 'success'>('pending')
  const [txLink, setTxLink] = useState<string>('')

  const [loadingMintMusd, setLoadingMintMusd] = useState(false)
  const [loadingRedeemUsdb, setLoadingRedeemUsdb] = useState(false)
  const [loadingBalance, setLoadingBalance] = useState(false)
  const [depositeUsdbAmount, setDepositeUsdbAmount] = useState('')
  const [popupMintRedeemAmount, setPopupMintRedeemAmount] = useState('')
  const [depositeMusdAmount, setDepositeMusdAmount] = useState('')
  const [userMusdBalance, setUserMusdBalance] = useState('')
  const [userMusdMaxBalance, setUserMusdMaxBalance] = useState('')
  const [userUsdbBalance, setUserUsdbBalance] = useState('')
  const [userUsdbMaxBalance, setUserUsdbMaxBalance] = useState('')
  const [usdbValidateMess, setUsdbValidateMess] = useState('')
  const [musdValidateMess, setMusdValidateMess] = useState('')
  const [usdbAllowance, setUsdbAllowance] = useState<number>(0)
  // usdb approve
  const [loadingApproveUsdbContract, setLoadingApproveUsdbContract] = useState(false)
  const [checkApproveUsdbContract, setCheckApproveUsdbContract] = useState(false)

  // modal
  const [isPenModalMint, setIsPenModalMint] = useState(false)
  const [errorMintMusd, setErrorMintMusd] = useState(false)
  const [errorMintMusdMess, setErrorMintMusdMess] = useState('')
  const [isOPenModalRedeem, setIsOPenModalRedeem] = useState(false)
  const [errorRedeemUsdb, setErrorRedeemUsdb] = useState(false)
  const [errorRedeemUsdbMess, setErrorRedeemUsdbMess] = useState('')
  // contract info
  const [circulating, setCiculating] = useState('')
  const [collateral, setColateral] = useState('')
  const [musdPrice, setMusdPrice] = useState('0')
  //
  const [loadingHistory, setLoadingHistory] = useState(false)

  const validateInput = (value: any, useBalance: any) => {
    const num = Number(value)
    if (isNaN(num) || num < 10) {
      return 'minimum'
    }
    if (num > Number(useBalance)) {
      return 'error'
    }
    if (currentTab == 1)
      if (num > usdbAllowance) return 'allowance'
    return 'valid'
  }

  // const musdContract = useCustomeContract(BLAST_MUSD_ADDRESSES, musd_abi, false)
  const musdContractWithSign = useCustomeContract(BLAST_MUSD_ADDRESSES, musd_abi, true)
  const usdbContractWithSign = useCustomeContract(BLAST_USDB_ADDRESSES, usdb_abi, true)
  const getUserBalance = async () => {
    setLoadingBalance(true)
    try {
      const userMusd = await musdContractWithSign?.balanceOf(account)
      const userUsdb = await usdbContractWithSign?.balanceOf(account)

      // musd
      const formmattedMusdUserBalance = String(ethers.utils.formatUnits(userMusd, 6)).replace(',', '')
      const maxMusdToken = formmattedMusdUserBalance.toString()
      setUserMusdBalance(formmattedMusdUserBalance)
      setUserMusdMaxBalance(maxMusdToken)

      // usdb
      const formmattedUsdbUserBalance = String(ethers.utils.formatUnits(userUsdb, 6)).replace(',', '')
      const maxUsdbToken = Number(formmattedUsdbUserBalance).toString()
      setUserUsdbBalance(formmattedUsdbUserBalance)
      setUserUsdbMaxBalance(maxUsdbToken)
      setLoadingBalance(false)
    } catch (error) {
      console.log('error', error)
      setLoadingBalance(false)
    }
  }

  const musdContract = getContract(
    BLAST_MUSD_ADDRESSES,
    musd_abi,
    new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.ZIRCUIT_TESTNET][0])
  )

  const getContractInfo = async () => {
    setLoadingBalance(true)
    try {
      // supply
      const circulatingSupply = await musdContract?.totalSupply()
      const musdPrice = await musdContract?.getPrice()

      if (circulatingSupply && musdPrice) {
        const formattedSupply = Number(ethers.utils.formatUnits(circulatingSupply, 6)).toFixed(2).toString()
        setCiculating(formattedSupply)

        const formattedmusdPrice = Number(ethers.utils.formatUnits(musdPrice, 8)).toString()
        setMusdPrice(formattedmusdPrice)
        const colab = (Number(formattedSupply) * Number(formattedmusdPrice)).toFixed(2).toString()
        setColateral(colab)
        setLoadingBalance(false)
      }
      setLoadingBalance(false)
    } catch (error) {
      setLoadingBalance(false)
    }
  }

  // mint musd
  const depositMusd = async () => {
    setLoadingMintMusd(true)
    setIsPenModalMint(true)
    const formattedDepositAmount = ethers.utils.parseUnits(depositeUsdbAmount, 6).toString()

    try {
      if (account) {
        setTxStatus('pending')
        // Estimate the gas limit
        const gasLimit = await musdContractWithSign?.estimateGas.deposit(formattedDepositAmount)
        const increasedGasLimit = Math.floor(gasLimit ? gasLimit.toNumber() * 1.5 : 250000)

        const tx = await musdContractWithSign?.deposit(formattedDepositAmount, { gasLimit: increasedGasLimit })
        await tx.wait()
        setTxLink(`https://explorer.testnet.zircuit.com/tx/${tx.hash}`);
        setTxStatus('success')

        setPopupMintRedeemAmount(depositeUsdbAmount.toString())
        setDepositeMusdAmount('')
        setLoadingMintMusd(false)
        getUserBalance()
        handleCheckApproveUsdbContract()
      }
    } catch (err) {
      setTxStatus('failed')
      setLoadingMintMusd(false)
      setErrorMintMusd(true)
      if (err?.code == 'UNPREDICTABLE_GAS_LIMIT' || (err?.data?.message && String(err?.data?.message).indexOf('insufficient funds')))
        setErrorMintMusdMess("Insufficient funds for gas")
      else
        setErrorMintMusdMess('Mint failed')
      console.log('error', err)
    }
  }

  const handleValidateDeposit = () => {
    if (depositeUsdbAmount == '') {
      setUsdbValidateMess('')
      return
    }
    const validate = validateInput(depositeUsdbAmount, userUsdbMaxBalance)
    if (validate === 'minimum') {
      setUsdbValidateMess('Minimum amount 10 USDT.')
      return
    }
    if (validate == 'allowance') {
      setUsdbValidateMess(`Click Approve to increase your spending limit.`)
      return
    }
    if (validate !== 'valid') {
      setUsdbValidateMess(`Input amount must less than ${formatXpNumber(userUsdbMaxBalance)} USDT.`)
      return
    }

    setUsdbValidateMess('')
  }

  const handleDeposite = () => {
    checkUnsupportChainId(chainId)
    depositMusd()
    setErrorMintMusd(false)
  }
  // approve spend usdb to musd contract
  const approveUsdbContract = async () => {
    checkUnsupportChainId(chainId)
    setLoadingApproveUsdbContract(true)
    try {
      const setApproveContract = await usdbContractWithSign?.approve(BLAST_MUSD_ADDRESSES, ethers.constants.MaxUint256)
      await setApproveContract.wait()
      setLoadingApproveUsdbContract(false)
      return true
    } catch (error) {
      console.log(error)
      setLoadingApproveUsdbContract(false)
      return false
    }
  }

  const handleCheckApproveUsdbContract = async () => {
    try {
      const allowance = await usdbContractWithSign?.allowance(account, BLAST_MUSD_ADDRESSES)
      const formmatedAllowance = Number(ethers.utils.formatUnits(allowance, 6))

      setUsdbAllowance(formmatedAllowance)
      if (formmatedAllowance > 0 && formmatedAllowance >= 10 && formmatedAllowance >= Number(depositeUsdbAmount ?? '0')) {
        setCheckApproveUsdbContract(true)
        setUsdbValidateMess('')
      } else {
        setCheckApproveUsdbContract(false)
      }
    } catch (error) {
      console.log('error', error)
      setCheckApproveUsdbContract(false)
    }
  }

  const handleApproveUsdbContract = async () => {
    await approveUsdbContract()
    handleCheckApproveUsdbContract()
  }

  // redeem
  const redeemUsdb = async () => {
    setLoadingRedeemUsdb(true)
    setIsOPenModalRedeem(true)
    const formattedWithdrawAmount = ethers.utils.parseUnits(depositeMusdAmount, 6).toString()

    try {
      if (account) {
        setTxStatus('pending')
        const gasLimit = await musdContractWithSign?.estimateGas.withdraw(formattedWithdrawAmount)
        const increasedGasLimit = Math.floor(gasLimit ? gasLimit.toNumber() * 1.5 : 250000)
        const tx = await musdContractWithSign?.withdraw(formattedWithdrawAmount, { gasLimit: increasedGasLimit })
        await tx.wait()
        setTxLink(`https://explorer.testnet.zircuit.com/tx/${tx.hash}`);
        setTxStatus('success')

        setPopupMintRedeemAmount(depositeMusdAmount.toString())
        setDepositeMusdAmount('')
        getUserBalance()
        setLoadingRedeemUsdb(false)
      }
    } catch (err) {
      setTxStatus('failed')
      setErrorRedeemUsdb(true)
      if (err?.code == 'UNPREDICTABLE_GAS_LIMIT' || (err?.data?.message && String(err?.data?.message).indexOf('insufficient funds')))
        setErrorMintMusdMess("Insufficient funds for gas")
      else
        setErrorRedeemUsdbMess('Redeem error')
      console.log('error', err)
      setLoadingRedeemUsdb(false)
    }
  }

  const handleValidateRedeem = () => {
    if (depositeMusdAmount == '') { setMusdValidateMess(''); return; }
    const validate = validateInput(depositeMusdAmount, userMusdMaxBalance)
    if (validate === 'minimum') {
      setMusdValidateMess('Minimum amount 10 PAW.')
      return
    }
    if (validate !== 'valid') {
      setMusdValidateMess(`Input amount must less than ${formatXpNumber(userMusdMaxBalance)} PAW.`)
      return
    }
    setMusdValidateMess('')
  }

  const handleRedeem = () => {
    checkUnsupportChainId(chainId)
    redeemUsdb()
    setErrorRedeemUsdb(false)
  }

  useEffect(() => {
    getContractInfo()
    if (account) {
      getUserBalance()
    }
  }, [account, chainId])

  useEffect(() => {
    if (account !== null && account !== undefined) {
      handleCheckApproveUsdbContract()
    }
  }, [account, chainId])

  useEffect(() => {
    if (Number(depositeUsdbAmount) > usdbAllowance || usdbAllowance < 10) setCheckApproveUsdbContract(false)
    else setCheckApproveUsdbContract(true)
    handleValidateDeposit()
  }, [depositeUsdbAmount, usdbAllowance])

  useEffect(() => {
    setUsdbValidateMess('')
    setMusdValidateMess('')
  }, [currentTab])

  useEffect(() => {
    handleValidateRedeem()
  }, [depositeMusdAmount])

  const LiveClock = () => {
    const [currentTime, setCurrentTime] = useState(new Date())

    useEffect(() => {
      const timer = setInterval(() => {
        setCurrentTime(new Date())
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    }, [])

    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const day = days[currentTime.getDay()]
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0')
    const year = currentTime.getFullYear()
    const hours = currentTime.getHours()
    const minutes = currentTime.getMinutes().toString().padStart(2, '0')
    const seconds = currentTime.getSeconds().toString().padStart(2, '0')
    const ampm = hours >= 12 ? 'PM' : 'AM'
    const formattedHours = hours % 12 || 12

    return <>{`${day}, ${month}/${currentTime.getDate()}/${year}, ${formattedHours}:${minutes}:${seconds} ${ampm}`}</>
  }
  // chart and history
  const [musdStats, setMusdStats] = useState<MusdStatType[]>([])
  const [supplyMusdStats, setSupplyMusdStats] = useState<MusdStatType[]>([])
  const [timePeriod, setTimePeriod] = useState<TimePeriodType>('HOUR')
  const [supplyTimePeriod, setSuppplyTimePeriod] = useState<TimePeriodType>('HOUR')

  let isLoadingMusdStat = false
  const getMusdStats = (period: TimePeriodType) => {
    if (isLoadingMusdStat) return
    isLoadingMusdStat = true
    setTimePeriod(period)

    axios
      .get(`${GMEOW_API_BASE_URL}/paw/get-stats-timeperiod?timeperiod=${period}`)
      .then(({ data }: { data: MusdStatType[] }) => {
        setMusdStats(data)
      })
      .finally(() => (isLoadingMusdStat = false))
  }

  let isLoadingSupplyMusdStat = false
  const getSupplyMusdStats = (period: TimePeriodType) => {
    if (isLoadingSupplyMusdStat) return
    isLoadingSupplyMusdStat = true
    setSuppplyTimePeriod(period)

    axios
      .get(`${GMEOW_API_BASE_URL}/paw/get-stats-timeperiod?timeperiod=${period}`)
      .then(({ data }: { data: MusdStatType[] }) => {
        setSupplyMusdStats(data)
      })
      .finally(() => (isLoadingSupplyMusdStat = false))
  }
  // add custome token
  const handleAddCustomToken: HandleAddCustomTokenFunc = async ({
    contractAddress,
    symbol,
    decimals,
    tokenLogoURL,
  }) => {
    if (window.ethereum) {
      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: contractAddress,
            symbol: symbol,
            decimals: decimals,
            image: tokenLogoURL,
          },
        },
      })
    }
  }
  function formatDate(dateString: any) {
    const date = new Date(dateString)
    const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
    return formattedDate
  }

  const getMusdHistoryIcon = (type: MusdHistoryEnumType) => {
    switch (type) {
      case 'MINT':
        return 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/paw/mint_icon.png'
      case 'REDEEM':
        return 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/paw/burn_icon.png'
      case 'ADD_COL':
        return 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/paw/mint_icon.png'
      default:
        return ''
    }
  }
  const getMusdHistoryTypeName = (type: MusdHistoryEnumType) => {
    switch (type) {
      case 'MINT':
        return 'Minting'
      case 'REDEEM':
        return 'Burning'
      case 'ADD_COL':
        return 'Adding Collateral'
      default:
        return ''
    }
  }
  async function getHistory(page: number, type: MusdHistoryEnumType) {
    if (loadingHistory) return
    setCurrentPage(page)
    setHistoryFilterType(type)
    setLoadingHistory(true)
    axios
      .get(`${GMEOW_API_BASE_URL}/paw/get-history?page=${page - 1}&type=${type}&page_size=5`)
      .then(({ data }: { data: MusdHistoryPageType }) => {
        setPaginatedData(data.data)
        setTotalElement(data.totalElement)
        setTotalPages(Math.ceil(data.totalElement / 5))
      })
      .finally(() => setLoadingHistory(false))
  }
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalElement, setTotalElement] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [historyFilterType, setHistoryFilterType] = useState<MusdHistoryEnumType>('ALL')
  const [paginatedData, setPaginatedData] = useState<MusdHistoryType[]>([])

  const onChangePage = (page: number) => {
    getHistory(page, historyFilterType)
  }

  function truncateString(str: any, length: any) {
    if (str.length > length) {
      return str.slice(0, length - 4) + '...' + str.slice(-4)
    } else {
      return str
    }
  }

  useEffect(() => {
    getHistory(1, 'ALL')
    getMusdStats('HOUR')
    getSupplyMusdStats('HOUR')
  }, [])
  return (
    <>
      <MintMusdModal
        isOpen={isPenModalMint}
        loading={loadingMintMusd}
        onDismiss={() => { setIsPenModalMint(false); setPopupMintRedeemAmount('') }}
        errorMintMusd={errorMintMusd}
        errMess={errorMintMusdMess}
        usdbValue={popupMintRedeemAmount}
        txLink={txLink}
        txStatus={txStatus}
        handleAddCustomToken={async () => { await handleAddCustomToken(TOKEN_ARRAY['PAW']) }}
      />
      <RedeemMusdModal
        isOpen={isOPenModalRedeem}
        loading={loadingRedeemUsdb}
        onDismiss={() => { setIsOPenModalRedeem(false); setPopupMintRedeemAmount('') }}
        errorMintMusd={errorRedeemUsdb}
        errMess={errorRedeemUsdbMess}
        usdbValue={popupMintRedeemAmount}
        txLink={txLink}
        txStatus={txStatus}
        handleAddCustomToken={async () => { await handleAddCustomToken(TOKEN_ARRAY['USDT']) }}
      />
      <div className="musd-container">
        <div className="swap-top-container-musd" style={{ display: 'block' }}>
          <PawTopSection />
        </div>
      </div>


      <div className="musd-chart-container" style={{ justifyContent: 'flex-start' }}>
        <div className="musd-chart-top">
          <div className="musd-chart-top-left">
            <div className="musd-chart-top-img">
              <img src={musdlogo} alt="pair logo" width={'100%'} />
            </div>
            <div className="musd-chart-top-pair">
              <p>
                1 PAW = {loadingBalance ? <CustomLightSpinner src={Circle} alt="loader" size="22px" /> : musdPrice}{' '}
                USDT
              </p>
            </div>
          </div>

          <div className="musd-chart-top-increase">
            <p style={{ margin: '0px', fontSize: '14px' }}>
              {loadingBalance ? (
                <CustomLightSpinner src={Circle} alt="loader" size="16px" />
              ) : (
                <>
                  + ${Number(Number(musdPrice) - 1).toFixed(5)} ({Number(100 * (Number(musdPrice) - 1)).toFixed(2)}%)
                </>
              )}
            </p>
          </div>
        </div>
      </div>

      {/* chart + swap */}
      <div className="chart-musd-bg">
        <div className="musd-chart-container">
          <div className="musd-chart">
            <div className="musd-chart-mid">
              <div className="musd-chart-mid-select">
                <p className={timePeriod == 'HOUR' ? 'actived' : ''} onClick={() => getMusdStats('HOUR')}>
                  1D
                </p>
                <p className={timePeriod == 'WEEK' ? 'actived' : ''} onClick={() => getMusdStats('WEEK')}>
                  1W
                </p>
                <p className={timePeriod == '1MONTH' ? 'actived' : ''} onClick={() => getMusdStats('1MONTH')}>
                  1M
                </p>
                <p className={timePeriod == 'MONTH' ? 'actived' : ''} onClick={() => getMusdStats('MONTH')}>
                  3M
                </p>
              </div>
              <div className="musd-chart-mid-time">
                {/* <p>Thu, 02/14/2024, 10:15:00 PM</p> */}
                <p>
                  <LiveClock />
                </p>
              </div>

            </div>
            <div className="musd-chart-chart" style={{ height: '420px' }}>
              <MusdPriceChart musdStats={musdStats} timePeriod={timePeriod} currentPrice={Number(musdPrice ?? 0)} />
            </div>
          </div>
          <div className="musd-swap">

            <div className="musd-swap-bot-container">
              <div className="musd-swap-input">
                {currentTab === 1 && (
                  <div>
                    <div className="musd-swap-input-title-container">
                      <div className="musd-swap-tab">
                        <div
                          className="musd-swap-tab-p musd-tab-selected"
                          onClick={() => setCurrentTab(1)}
                        >
                          <p>Mint</p>
                        </div>
                        <div
                          className="musd-swap-tab-p"
                          onClick={() => setCurrentTab(2)}
                        >
                          <p>Redeem</p>
                        </div>
                      </div>
                      <div className="musd-swap-input-title-add">
                        <div onClick={() => handleAddCustomToken(TOKEN_ARRAY['PAW'])} style={{ cursor: 'pointer' }}>
                          <MouseoverTooltip text={<span>Add PAW to your wallet </span>} placement="bottom">
                            <PlusSquare size="24" color="#00E440" />
                          </MouseoverTooltip>
                        </div>
                      </div>
                    </div>

                    <div className='musd-swap-input-usdb-wrapper'>
                      <div className="musd-swap-input-usdb">
                        <input
                          type="number"
                          placeholder="0.00"
                          value={depositeUsdbAmount}
                          onChange={(e) => setDepositeUsdbAmount(e.target.value)}
                        />

                        <div className="musd-swap-input-balance">
                          <div className='input-label-box'>
                            <div className="musd-swap-input-balance-left">
                              <div
                                className="musd-max-button"
                                onClick={() => {
                                  setDepositeUsdbAmount(userUsdbMaxBalance)
                                }}
                              >
                                <span>MAX</span>
                              </div>
                              <div className="musd-input-balance">
                                <span style={{ color: '#E5B670' }}>Balance: </span>
                                <span>
                                  {loadingBalance ? (
                                    <CustomLightSpinner src={Circle} alt="loader" size="12px" />
                                  ) : formatXpNumber(userUsdbBalance)}{' '}
                                </span>
                              </div>
                            </div>

                            <div className="musd-swap-input-usdb-text">
                              <img src={usdbIcon} alt="" />
                              <p>USDT</p>
                            </div>
                          </div>


                        </div>

                      </div>
                    </div>

                    <div className='musd-swap-input-usdb-wrapper'>


                      <div className="musd-swap-input-usdb">
                        <input
                          type="text"
                          placeholder="0.00"
                          disabled
                          value={musdPrice ? Number(Number(depositeUsdbAmount) / Number(musdPrice)).toFixed(2) : '0.00'}
                        />
                        <div className="musd-swap-input-balance">
                          <div className='input-label-box'>
                            <div className="musd-swap-input-balance">
                              <div className="musd-input-limit">
                                {/* <p>Mint limit: </p> <span>10.000 MUSD per address</span> */}
                              </div>
                              <div className="musd-input-balance">
                                <span style={{ color: '#E5B670' }}>
                                  Balance:{' '}
                                  <span style={{ color: '#4B2828' }}>
                                    {loadingBalance ? (
                                      <CustomLightSpinner src={Circle} alt="loader" size="12px" />
                                    ) : formatXpNumber(userMusdBalance)}{' '}
                                  </span>
                                </span>
                              </div>
                            </div>

                            <div className="musd-swap-input-usdb-text">
                              <img src={musdIcon} alt="musd" />
                              <p>PAW</p>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    {
                      usdbValidateMess && <div style={{ color: '#ff1e56', marginTop: '5px' }}>{usdbValidateMess}</div>
                    }

                  </div>
                )}
                {currentTab === 2 && (
                  <div>
                    <div className="musd-swap-input-title-container">
                      <div className="musd-swap-tab">
                        <div
                          className="musd-swap-tab-p"
                          onClick={() => setCurrentTab(1)}
                        >
                          <p>Mint</p>
                        </div>
                        <div
                          className="musd-swap-tab-p musd-tab-selected"
                          onClick={() => setCurrentTab(2)}
                        >
                          <p>Redeem</p>
                        </div>
                      </div>
                      <div className="musd-swap-input-title-add">
                        <div onClick={() => handleAddCustomToken(TOKEN_ARRAY['USDT'])} style={{ cursor: 'pointer' }}>
                          <MouseoverTooltip text={<span>Add USDT to your wallet </span>} placement="bottom">
                            <PlusSquare size="24" color="#00E440" />
                          </MouseoverTooltip>
                        </div>
                      </div>
                    </div>
                    <div className='musd-swap-input-usdb-wrapper'>
                      <div className="musd-swap-input-usdb">
                        <input
                          type="text"
                          placeholder="0.00"
                          value={depositeMusdAmount}
                          onChange={(e) => setDepositeMusdAmount(e.target.value)}
                        />

                        <div className="musd-swap-input-balance">
                          <div className='input-label-box'>
                            <div className="musd-swap-input-balance-left">
                              <div
                                className="musd-max-button"
                                onClick={() => {
                                  setDepositeMusdAmount(userMusdMaxBalance)
                                }}
                              >
                                <span>MAX</span>
                              </div>
                              <div className="musd-input-balance">
                                <span style={{ color: '#E5B670' }}>
                                  Balance:{' '}
                                  <span style={{ color: '#4B2828' }}>
                                    {loadingBalance ? (
                                      <CustomLightSpinner src={Circle} alt="loader" size="12px" />
                                    ) : formatXpNumber(userMusdBalance)}{' '}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="musd-swap-input-usdb-text">
                            <img src={musdIcon} alt="musd" />
                            <p>PAW</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='musd-swap-input-usdb-wrapper'>
                      <div className="musd-swap-input-usdb">
                        <input
                          type="text"
                          placeholder="0.00"
                          disabled
                          value={musdPrice ? formatNumber(Number(depositeMusdAmount ?? '0') * Number(musdPrice), 2) : '0.00'}
                        />
                        <div className="musd-swap-input-balance">
                          <div className='input-label-box'>
                            <div className="musd-input-balance">
                              <span style={{ color: '#4B2828' }}>
                                {loadingBalance ? (
                                  <CustomLightSpinner src={Circle} alt="loader" size="12px" />
                                ) : formatXpNumber(userUsdbBalance)}{' '}
                              </span>
                            </div>
                            <div className="musd-swap-input-usdb-text">
                              <img src={usdbIcon} alt="" />
                              <p>USDT</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {
                      musdValidateMess && <div style={{ color: '#ff1e56', marginTop: '5px' }}>{musdValidateMess}</div>
                    }
                  </div>

                )}
              </div>
              {/* <div className="musd-swap-loadbar">
                <MintingCapUserBar value={24} total={56} isFull={false} />
                <MintingCapTotal value={15} total={100} isFull={false} />
              </div> */}
              <div className="musd-connectwallet">
                {!account ? (
                  <NotchedButtonFill onClick={toggleWalletModal} width={'100%'} height="72px" earMaxW="200px" textColor="#FFF9E1" bg="#00E440">
                    CONNECT WALLET
                  </NotchedButtonFill>
                ) : (
                  chainId == SupportedChainId.ZIRCUIT_TESTNET && (
                    <>
                      {currentTab === 1 &&
                        (checkApproveUsdbContract ? (
                          <NotchedButtonFill onClick={handleDeposite} height="72px" width={'100%'} earMaxW="200px" textColor="#FFF9E1" bg="#00E440" disabled={depositeUsdbAmount == '' || usdbValidateMess != '' || Number(depositeUsdbAmount) > usdbAllowance}>
                            {loadingMintMusd ? 'Minting...' : 'Mint'}
                          </NotchedButtonFill>
                        ) : (
                          <NotchedButtonFill onClick={handleApproveUsdbContract} height="72px" width={'100%'} earMaxW="200px" textColor="#FFF9E1" bg="#00E440">
                            {loadingApproveUsdbContract ? 'Approving...' : 'Approve'}
                          </NotchedButtonFill>
                        ))}
                      {currentTab === 2 && (
                        <NotchedButtonFill onClick={handleRedeem} height="72px" width={'100%'} earMaxW="200px" textColor="#FFF9E1" bg="#00E440" disabled={musdValidateMess != ''}>
                          {loadingRedeemUsdb ? 'Loading...' : 'Redeem'}
                        </NotchedButtonFill>
                      )}
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* supply */}
      <div className="musd-supply-container">
        <div className="musd-supply">
          <div className="musd-supply-top">
            <div className="musd-supply-top-card-container">
              <div className="musd-supply-top-card" style={{ borderLeft: '4px solid #FFB704' }}>
                <div className="musd-supply-top-card-small">
                  <img src={circular} alt="img" />
                  CIRCULATING SUPPLY
                </div>
                <p >
                  {loadingBalance ? (
                    <CustomLightSpinner src={Circle} alt="loader" size="22px" />
                  ) : Number(circulating) < 1000 ? (
                    circulating
                  ) : (
                    numberWithCommas(circulating)
                  )}{' '}
                  PAW
                </p>
              </div>

              <div className="musd-supply-top-card" style={{ borderLeft: '4px solid #00E440' }}>
                <div className="musd-supply-top-card-small">
                  COLLATERAL
                </div>
                <p>
                  {loadingBalance ? (
                    <CustomLightSpinner src={Circle} alt="loader" size="22px" />
                  ) : Number(collateral) < 1000 ? (
                    collateral
                  ) : (
                    numberWithCommas(collateral)
                  )}{' '}
                  USDT
                </p>
              </div>
            </div>
            <div className="musd-chart-mid-select">
              <p className={supplyTimePeriod == 'HOUR' ? 'actived' : ''} onClick={() => getSupplyMusdStats('HOUR')}>
                1D
              </p>
              <p className={supplyTimePeriod == 'WEEK' ? 'actived' : ''} onClick={() => getSupplyMusdStats('WEEK')}>
                1W
              </p>
              <p className={supplyTimePeriod == '1MONTH' ? 'actived' : ''} onClick={() => getSupplyMusdStats('1MONTH')}>
                1M
              </p>
              <p className={supplyTimePeriod == 'MONTH' ? 'actived' : ''} onClick={() => getSupplyMusdStats('MONTH')}>
                3M
              </p>
            </div>
          </div>
          <div className="musd-supply-chart">
            <CollateralChart musdStats={supplyMusdStats} timePeriod={supplyTimePeriod} />
          </div>
          {/* <div style={{ display: 'grid', justifyContent: 'end', marginTop: '10px' }}>
            <div className="musd-outlink">
              <p style={{ color: '#efefe4', margin: 0, fontSize: '15px' }}>Visit the Treasury</p>
              <ExternalLink
                href="https://blastscan.io/txs?a=0x837fE561e9C5DFa73F607fDa679295DBC2Be5E40"
                target="_blank"
              >
                <img src={outlink} alt="img" />
              </ExternalLink>
            </div>

            <div className="musd-outlink">
              <p style={{ color: '#efefe4', margin: 0, fontSize: '15px' }}>MUSD Contract</p>
              <ExternalLink
                href="https://blastscan.io/token/0x837fE561e9C5DFa73F607fDa679295DBC2Be5E40"
                target="_blank"
              >
                <img src={outlink} alt="img" />
              </ExternalLink>
            </div>
          </div> */}
        </div>
      </div>
      {/* History */}
      <div className="musd-history-container">
        <div className="musd-history">
          <div className="musd-history-top">
            <p className="musd-history-top-title">PAW History</p>
            <div
              className="musd-history-top-filter"
              style={{ justifyContent: 'flex-end', gap: 24, display: 'inline-flex' }}
            >
              <span
                className={`${historyFilterType == 'ALL' ? 'active' : ''}`}
                style={{ color: historyFilterType == 'ALL' ? '#4B2828' : 'rgba(75, 40, 40, 0.50)' }}
                onClick={() => getHistory(1, 'ALL')}
              >
                ALL
              </span>
              <span
                className={`${historyFilterType == 'MINT' ? 'active' : ''}`}
                style={{ color: historyFilterType == 'MINT' ? '#4B2828' : 'rgba(75, 40, 40, 0.50)' }}
                onClick={() => getHistory(1, 'MINT')}
              >
                Mint
              </span>
              <span
                className={`${historyFilterType == 'REDEEM' ? 'active' : ''}`}
                style={{ color: historyFilterType == 'REDEEM' ? '#4B2828' : 'rgba(75, 40, 40, 0.50)' }}
                onClick={() => getHistory(1, 'REDEEM')}
              >
                Burn
              </span>
              {/* <span
                className={`${historyFilterType == 'ADD_COL' ? 'active' : ''}`}
                style={{ color: historyFilterType == 'ADD_COL' ? '#4B2828' : 'rgba(75, 40, 40, 0.50)' }}
                onClick={() => getHistory(1, 'ADD_COL')}
              >
                ADD COL
              </span> */}
            </div>

            <img className='history_img' src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/paw/history_icon.png' alt="gmeowfi" />
          </div>

          <div className='musd-history-table'>
            <div className="musd-history-item-header"  >
              <p className="musd-history-item-title title_col" style={{ color: 'rgba(75, 40, 40, 0.70)', fontFamily: 'Outfit' }}>
                Activity
              </p>

              <p
                className="history-musd-text-color title_col" style={{ color: 'rgba(75, 40, 40, 0.70)', paddingRight: '40px' }}>
                Amount
              </p>
              <p className="musd-history-item-created title_col" style={{ color: 'rgba(75, 40, 40, 0.70)', fontFamily: 'Outfit' }}>Date</p>
            </div>

            <div>
              <div className="musd-history-detail">
                {loadingHistory ? (
                  <div
                    style={{
                      textAlign: 'center',
                      padding: '30px 0'
                    }}
                  >
                    <CustomLightSpinner src={Circle} alt="loader" size="60px" />
                  </div>
                ) : paginatedData.length > 0 ? (
                  paginatedData?.map((item, index) => (
                    <div className="musd-history-item" key={index + item?.createdAt}>
                      <div className="musd-history-item-title">
                        <img style={{ width: '16px', height: '16px' }} src={getMusdHistoryIcon(item.musdType)} alt="add" />
                        <p>{getMusdHistoryTypeName(item.musdType)}</p>
                      </div>
                      <p
                        className={`history-musd-text-color ${item.musdType === 'REDEEM' ? 'history-musd-text-color-withdraw' : ''
                          }`}
                        style={{ color: item.musdType === 'REDEEM' ? 'red' : '#05C63B' }}
                      >
                        <>
                          <img style={{ width: '16px', height: '16px', display: 'inline-block', marginRight: '5px' }} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/tokens/paw.png" alt="add" />
                          {(item.musdType == 'ADD_COL' || item.musdType == 'MINT') && <>+</>}
                          {item.musdType == 'REDEEM' && <>-</>}
                          {formatXpNumber(Math.abs(item.amount), 3)}
                          {' PAW'}
                        </>
                      </p>
                      <p className="musd-history-item-created">
                        <ExternalLink
                          href={getExplorerLink(
                            chainId ? SupportedChainId.ZIRCUIT_TESTNET : SupportedChainId.ZIRCUIT_TESTNET,
                            item.hash,
                            ExplorerDataType.TRANSACTION
                          )}
                          target="_blank"
                        >
                          <img src={outlink} alt="img" />
                        </ExternalLink>
                        <span>
                          {item.createdAt}
                        </span>
                      </p>

                    </div>
                  ))
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className="musd-history-paginate my-claimed-xp">
              {paginatedData?.length > 0 && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                  <Pagination
                    current={currentPage}
                    total={totalElement}
                    pageSize={5}
                    itemRender={PrevNextRender}
                    showSizeChanger={false}
                    onChange={onChangePage}
                  />
                </div>
              )}
            </div>
          </div>

        </div>
      </div>

      <PawBottomSection />

    </>
  )
}

export default MusdPage

const MintingCapUserBar = ({ value, total, isFull }: any) => { }
export const numberWithCommas = (number: any) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
