import { useEffect, useState } from "react"
import styled from "styled-components"
import { truncateAddress } from "utils/stringUtils"
import { BREAKPOINTS } from "theme"
import { GMEOW_API_BASE_URL } from "utils/env"
import axios from "axios"


const PawTopSectionWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 24px;
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`

const CardBox = styled.div`
    width: 100%;
    border-radius: 10px;
    background: #FAEEBD;
    padding: 20px;
    display: flex;
    gap: 24px;
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        width: fit-content;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        flex-direction: column;
    }
`

const CardBoxImgBox = styled.div`
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        display: flex;
        justify-content: center;
    }
`
const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 4px 0;
    h4{
        display: inline-block;
        width: fit-content;
        color: #4B2828;
        font-family: DynaPuff;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 30px */
        margin: 0;
    }
    h4.border_text{
        color: #FFB704;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #723507;
        font-family: DynaPuff;
        font-size: 24px;
        font-style: normal;
        font-weight: medium;
        line-height: 125%; /* 30px */
        letter-spacing: 3.12px;
        text-transform: uppercase;
    }
    h6{
        display: inline-block;
        color: #93553A;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 20px */
        margin: 0;
        max-width: 326px;
    }
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        text-align: center;
        align-items: center;
        justify-content: center;
    }
`
const HistoryBox = styled.div`
    width: 100%;
    position: relative;
    margin-top: 24px;
    margin-bottom: 82px;
    .marquee{
       display: flex;
       gap: 24px;
    }
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        max-width: 596px;
        margin-left: auto;
        margin-right: auto;
    }
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        max-width: 95%;
    }
`
const HistoryItem = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    span{
        color: #93553A;
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 17.5px */
    }
`
type MintType = {
    address: string
    amount: number
}

const PawIcon = ({ fill, stroke }: {
    fill: string; stroke: string
}) => {

    return <svg xmlns="http://www.w3.org/2000/svg" width="31" height="28" viewBox="0 0 31 28" fill="none">
        <path d="M22.8614 18.0092C22.8614 22.3225 19.3649 23.2264 15.0518 23.2264C10.7387 23.2264 7.24219 22.3225 7.24219 18.0092C7.24219 13.6959 10.7387 10.1992 15.0518 10.1992C19.3649 10.1992 22.8614 13.6959 22.8614 18.0092Z" fill={fill} />
        <ellipse cx="9.49472" cy="21.8153" rx="6.18613" ry="6.18639" fill={fill} />
        <ellipse cx="20.4565" cy="21.8153" rx="6.0776" ry="6.18639" fill={fill} />
        <ellipse cx="2.48161" cy="4.27403" rx="2.48161" ry="4.27403" transform="matrix(0.965923 -0.258829 0.258809 0.965928 7.4375 1.28516)" fill={fill} />
        <ellipse cx="2.48161" cy="4.27403" rx="2.48161" ry="4.27403" transform="matrix(-0.965923 -0.258829 -0.258809 0.965928 23.9922 1.28516)" fill={fill} />
        <ellipse cx="1.95285" cy="3.36331" rx="1.95285" ry="3.36331" transform="matrix(-0.857592 -0.514331 -0.5143 0.857611 30.5469 8.07422)" fill={fill} />
        <ellipse cx="1.95285" cy="3.36331" rx="1.95285" ry="3.36331" transform="matrix(0.857592 -0.514331 0.5143 0.857611 0.273438 8.07422)" fill={fill} />
        <path d="M12.5703 14L12.7882 15.883" stroke={stroke} strokeWidth="1.75007" strokeLinecap="round" />
        <path d="M17.2344 14L17.4523 15.883" stroke={stroke} strokeWidth="1.75007" strokeLinecap="round" />
        <path d="M13.3671 19.742L14.9949 22.1406C15.1223 22.3283 15.3962 22.3359 15.5339 22.1556L17.8552 19.1144" stroke={stroke} strokeWidth="1.75007" strokeLinecap="round" />
    </svg>
}
export default function PawTopSection() {
    const [latestMintedHistory, setLatestMintedHistory] = useState<MintType[]>([
        {
            address: '0x90c654eeafb30201f4ee1aaba0247f181eebe9f8',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
        {
            address: '0x084771af5502fe4455d338b7588437766b8b490b',
            amount: 10,
        },
    ])

    const FILL_COLOR = ['', '#FFE500', '#05C13A', '#93553A', '#FFB704']
    const STROKE_COLOR = ['', '#05C13A', '#FFE500', '#FFF9E1', '#FFF9E1']

    useEffect(() => {
        axios
            .get(`${GMEOW_API_BASE_URL}/paw/get-history?page=0&type=MINT&page_size=50`)
            .then(({ data }: { data: any }) => {
                console.log('data load', data);
                setLatestMintedHistory(data.data.map((i: { userAddress: any; amount: any }) => ({
                    address: i.userAddress?? '0x084771af5502fe4455d338b7588437766b8b490b',
                    amount: i.amount
                })))

                console.log(data.data.map((i: { userAddress: any; amount: any }) => ({
                    address: i.userAddress ?? '0x084771af5502fe4455d338b7588437766b8b490b',
                    amount: i.amount
                })));
                
            })
    }, [])

    return <div>
        <PawTopSectionWrapper>
            <CardBox>
                <CardBoxImgBox>
                    <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/paw/top_img1.png" alt="gmeowfi" />
                </CardBoxImgBox>
                <CardBody>
                    <div>
                        <h4>Earn</h4> <h4 className="border_text">AUTO</h4><br />
                        <h4>Real Yield</h4>
                    </div>
                    <h6>Have you ever seen an auto-yield rebasing token that never goes down? You do NOW!</h6>
                </CardBody>
            </CardBox>
            <CardBox>
                <CardBoxImgBox>
                    <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/paw/top_img2.png" alt="gmeowfi" />
                </CardBoxImgBox>
                <CardBody>
                    <div>
                        <h4>Experience</h4><br />
                        <h4 className="border_text">PAW’s Meowgic</h4>
                    </div>
                    <h6>Every PAW is a purrfect investment with purrmanent growth and pawsome returns.</h6>
                </CardBody>
            </CardBox>
        </PawTopSectionWrapper>

        <HistoryBox className="marquee-container">
            <div className="marquee">
                {
                    latestMintedHistory.map((item, index) => (<HistoryItem key={'m1' + index} style={{ minWidth: '215px' }}>
                        <PawIcon fill={FILL_COLOR[(index + 1) % 4] ?? FILL_COLOR[1]} stroke={STROKE_COLOR[(index + 1) % 4] ?? STROKE_COLOR[1]} />
                        <span style={{ fontWeight: 600 }}>{truncateAddress(item.address)}</span>
                        <span>minted</span>
                        <span style={{ fontWeight: 600, color: "#05C63B " }}>{item.amount} PAW</span>
                    </HistoryItem>))
                }
            </div>
            <div className="marquee marquee2">
                {
                    latestMintedHistory.map((item, index) => (<HistoryItem key={'m2' + index} style={{ minWidth: '215px' }}>
                        <PawIcon fill={FILL_COLOR[index + 1]} stroke={STROKE_COLOR[index + 1]} />
                        <span style={{ fontWeight: 600 }}>{truncateAddress(item.address)}</span>
                        <span>minted</span>
                        <span style={{ fontWeight: 600, color: "#05C63B " }}>{item.amount} PAW</span>
                    </HistoryItem>))
                }
            </div>
        </HistoryBox >

    </div >
}