import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from '@uniswap/v3-sdk'
import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from '@uniswap/v2-sdk'
import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { SupportedChainId } from './chains'
import { PERMIT2_ADDRESS } from '@uniswap/permit2-sdk'

type AddressMap = { [chainId: number]: string }


//ZIRCUIT_TESTNET

export const INIT_CODE_HASH_V3: AddressMap = {
  ...constructSameAddressMap('0xda571d86ad43edb30b030580c077ec40d48a0a4203fc3d13dc8ebda8e7b46c44', []),
  [SupportedChainId.ZIRCUIT_TESTNET]: "0xb45d6570cd8190d1c5e51f615a6dac66970c609c953f0c47ece4ca99cc3bccc3",
  [SupportedChainId.ZIRCUIT]: "0xb45d6570cd8190d1c5e51f615a6dac66970c609c953f0c47ece4ca99cc3bccc3",
}

export const INIT_CODE_HASH_V2: AddressMap = {
  ...constructSameAddressMap('0xc9715eb6db3ca1b055b197bd8b44647d9fcc358d2ca358c4f49c973e48a13484', []),
  [SupportedChainId.ZIRCUIT_TESTNET]: "0x1efb60370db30aa3b82a3c940ba8333e0716e488785262164c2a30170bb98421",
  [SupportedChainId.ZIRCUIT]: "0x1efb60370db30aa3b82a3c940ba8333e0716e488785262164c2a30170bb98421",
}

export const UNI_ADDRESS: AddressMap = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984')

export const UNISWAP_NFT_AIRDROP_CLAIM_ADDRESS = '0x8B799381ac40b838BBA4131ffB26197C432AFe78'

export const PERMIT2_ADDRESS_MAP: AddressMap = {
  ...constructSameAddressMap(PERMIT2_ADDRESS, []),
  [SupportedChainId.ZIRCUIT_TESTNET]: "0x7B5f0ceCcC8f03e2B29f6B6BB3aCcb2b3524e6B8",
  [SupportedChainId.ZIRCUIT]: "0x4057C4617E5036b277958004ed6cCF881F528568",
}

export const V2_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(V2_FACTORY_ADDRESS, []),
  [SupportedChainId.ZIRCUIT_TESTNET]: "0x3874db48bBDD965DA824BE998f63f5cF689D4f79",
  [SupportedChainId.ZIRCUIT]: "0x0E8c80Fa42861E4d13472Ba944B67d24cd22Df9f",
}

export const V2_ROUTER_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D', []),
  [SupportedChainId.ZIRCUIT_TESTNET]: "0x27dBA283D1b99856A4b373D0DC0226CAdC3ed9b9",
  [SupportedChainId.ZIRCUIT]: "0x15d8C79D4A9069A47CC3AD4e4741354505624D0A",
}

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(V3_FACTORY_ADDRESS, []),
  [SupportedChainId.ZIRCUIT_TESTNET]: "0xaE218256bB7aD772F04c78D5fCb64E9AC73A22DA",
  [SupportedChainId.ZIRCUIT]: "0xB1bA044f01BBAFA11C36560bc959C843299d5d58",
}

export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34', []),
  [SupportedChainId.ZIRCUIT_TESTNET]: "0x05491908aFD88E576C00A689296B4127640a2596",
  [SupportedChainId.ZIRCUIT]: "0x82609A20287b64808628fAFD3961E3bfB38D7445",
}

export const MULTICALL_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x1F98415757620B543A52E61c46B32eB19261F984', []),
  [SupportedChainId.ZIRCUIT_TESTNET]: "0x352ec651276FAa0358129ee78DfB8B0C96E52980",
  [SupportedChainId.ZIRCUIT]: "0xbF29F381557E53Ed813a87A569e17f77E6a3cAf7",
}

export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xDc5DC81bE42301d73fd0c27b4853E1282dEA8677', []),
  [SupportedChainId.ZIRCUIT_TESTNET]: "0x18B4C0A733F5a2a295c1DDE626B990c90187ab0a",
  [SupportedChainId.ZIRCUIT]: "0x4661B854456741ab2DB04dE787085C3BE798fB43",
}

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = constructSameAddressMap(
  '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'
)
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6',
}
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x408ED6354d4973f66138C91495F2f2FCbd8724C3',
}

export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC')

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}

export const QUOTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', []),
  [SupportedChainId.ZIRCUIT_TESTNET]: "0xa98522e359761b97B0B0620a75060B54678a661C",
  [SupportedChainId.ZIRCUIT]: "0x23078ba692306D2dd4a2b1aFc2eb16975a2d5207",
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xC36442b4a4522E871399CD717aBDD847Ab11FE88', []),
  [SupportedChainId.ZIRCUIT_TESTNET]: "0x959F24517aEecC15F718dFd9F29EBb61195C59BA",
  [SupportedChainId.ZIRCUIT]: "0xF5b84Fb2Ee243aDe61E1f20cb38143380B1A1593",
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}

export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x65770b5283117639760beA3F867b69b3697a91dd',
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  [SupportedChainId.ZIRCUIT_TESTNET]: "0x87fDB72D19b3942406991409f8719B236c99710A",
  [SupportedChainId.ZIRCUIT]: "0x0685a9BEAE49f7567Dfe0ba283a8aeD21d709720",
}

// defined addresses

export const ZIRCUIT_TESTNET_MEOW_NFT_ADDRESS = {
  [SupportedChainId.MAINNET]: '',
  [SupportedChainId.ZIRCUIT_TESTNET]: '0x9Ac9b5f0a9D5c2DA141a43f778b6aDb66638fd33',
  [SupportedChainId.ZIRCUIT]: '',
}
export const ZIRCUIT_TESTNET_MEOW_NFT_MINTER_ADDRESS = {
  [SupportedChainId.MAINNET]: '',
  [SupportedChainId.ZIRCUIT_TESTNET]: '0xb249A5DCFBA5946c1558a7646CdE833913E5bF6A',
  [SupportedChainId.ZIRCUIT]: '',
}

// box
export const MONO_CHEST_ADDRESSES_MAP = {
  [SupportedChainId.MAINNET]: '',
  [SupportedChainId.ZIRCUIT_TESTNET]: '0x3b915b0b0ecA21a742D26a5773fb10B6b8aDE4Be',
  [SupportedChainId.ZIRCUIT]: '0x9091712FE15D07C36cf350D4A61a864E96549e25',
}
export const MONO_CHEST_NFT_ADDRESSES_MAP = {
  [SupportedChainId.MAINNET]: '',
  [SupportedChainId.ZIRCUIT_TESTNET]: '0x87A4f586aea0C47a1f615a13F9b6acfFCF59452B',
  [SupportedChainId.ZIRCUIT]: '0xD5769BD2B7064c35991dD099c084C7e6195DC5de',
}
export const MUSD_ADDRESSES_MAP = {
  [SupportedChainId.MAINNET]: '',
  [SupportedChainId.ZIRCUIT_TESTNET]: '0x156563d6bA40F03480A40AEa7F91392F7974C9F9',
  [SupportedChainId.ZIRCUIT]: '',
}
export const USDB_ADDRESSES_MAP = {
  [SupportedChainId.MAINNET]: '',
  [SupportedChainId.ZIRCUIT_TESTNET]: '0x2b533475875b518144c2Cf8558d799BC6FB11716',
  [SupportedChainId.ZIRCUIT]: '',
}

// xmono
export const XMONO_ADDRESSES_MAP: AddressMap = {
  ...constructSameAddressMap('0x66fBCf5636BC4060D93aF689B125a172b67D867e', []),
  [SupportedChainId.ZIRCUIT_TESTNET]: "0x28A922aD2d2A02250473b30F3444E20b138354d1",
  [SupportedChainId.ZIRCUIT]: '0x91041fdaf58254aba28dAd32F548D4A466FE0E65',
}

// mono
export const MONO_ADDRESSES_MAP: AddressMap = {
  ...constructSameAddressMap('0x66889602f76E8F7efDC968771F1d2f75bdbB3FDE', []),
  [SupportedChainId.ZIRCUIT_TESTNET]: "0x66889602f76E8F7efDC968771F1d2f75bdbB3FDE",
  [SupportedChainId.ZIRCUIT]: '',
}

export const V3STAKER_ADDRESSES_MAP: AddressMap = {
  ...constructSameAddressMap('0x6f86b84A2E053dFA68c02d87061484a71Cb3E3c5', []),
  [SupportedChainId.ZIRCUIT_TESTNET]: '0x86203b4c84af4525805091B8Ee364Ca39c2814DA',
  [SupportedChainId.ZIRCUIT]: '0x133Df9a06246383b2337636817F80027361936AF',
}

export const MONO_PASS_SALE_ADDRESSES_MAP: AddressMap = {
  ...constructSameAddressMap('0x4452a126E442463bbd4531C4d7F13Ca2F3D5aE67', []),
  [SupportedChainId.ZIRCUIT_TESTNET]: '0x4452a126E442463bbd4531C4d7F13Ca2F3D5aE67',
  [SupportedChainId.ZIRCUIT]: '0x4452a126E442463bbd4531C4d7F13Ca2F3D5aE67',
}
export const MONO_PASS_ADDRESSES_MAP: AddressMap = MONO_CHEST_NFT_ADDRESSES_MAP
export const MONO_PASS_STAKE_ADDRESSES_MAP: AddressMap = {
  ...constructSameAddressMap('0xa525Ae317c6068336a6e9632F09aEf52c59c422a', []),
  [SupportedChainId.ZIRCUIT_TESTNET]: '0xa525Ae317c6068336a6e9632F09aEf52c59c422a',
  [SupportedChainId.ZIRCUIT]: '0xa525Ae317c6068336a6e9632F09aEf52c59c422a',
}

export const MONO_LAUNCHPAD: AddressMap = {
  ...constructSameAddressMap('', []),
  [SupportedChainId.ZIRCUIT_TESTNET]: "",
  [SupportedChainId.ZIRCUIT]: '',
}



export const BLAST_MONOPOT_ADDRESSES = ''
export const BLAST_MONOPOT_TICKET_ADDRESSES = ''


export const BLAST_SEPOLIA_TEST_BTC_ADDRESSES = '0x0C8C73C735C9768e0911B7152F3f88Fffcab5Ea4'
export const LP_POOL_V2_ADDRESSES_MAP = '0x0C8C73C735C9768e0911B7152F3f88Fffcab5Ea4'

export const BLAST_SEPOLIA_TEST_USDC_ADDRESSES = '0xd35f0a4C44A12E6f6D50c0f6a511ADCE35f2CF63'
export const BLAST_SEPOLIA_TEST_USDT_ADDRESSES = '0x2b533475875b518144c2Cf8558d799BC6FB11716'
export const BLAST_SEPOLIA_TEST_MULTIL_FAUCET_ADDRESSES = ''
