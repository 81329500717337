import {Trans} from '@lingui/macro'
import {FeeAmount} from '@uniswap/v3-sdk'
import {ButtonRadioChecked} from 'components/Button'
import {AutoColumn} from 'components/Column'
import {useFeeTierDistribution} from 'hooks/useFeeTierDistribution'
import {PoolState} from 'hooks/usePools'
import React from 'react'
import styled from 'styled-components/macro'
import {ThemedText} from 'theme'

import {FeeTierPercentageBadge} from './FeeTierPercentageBadge'
import {FEE_AMOUNT_DETAIL} from './shared'

const ResponsiveText = styled(ThemedText.DeprecatedLabel)`
    color: #4B2828;
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;

    ${({theme}) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    font-size: 18px;
    line-height: 18px;
    color:#4B2828;
  `};
`

interface FeeOptionProps {
    feeAmount: FeeAmount
    active: boolean
    distributions: ReturnType<typeof useFeeTierDistribution>['distributions']
    poolState: PoolState
    onClick: () => void
}

export function FeeOption({feeAmount, active, poolState, distributions, onClick}: FeeOptionProps) {
    return (
        <ButtonRadioChecked active={active} onClick={onClick}>
            <AutoColumn gap="sm" justify="flex-start">
                <AutoColumn justify="flex-start" gap="6px">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '3px',
                    }}>
                        <ResponsiveText>
                            <Trans>{FEE_AMOUNT_DETAIL[feeAmount].label}%</Trans>
                        </ResponsiveText>
                        {distributions && (
                            <FeeTierPercentageBadge distributions={distributions} feeAmount={feeAmount}
                                                    poolState={poolState}/>
                        )}
                    </div>
                    <span style={{
                        fontWeight: '400',
                        fontSize: '12px',
                        textAlign: 'left',
                        color: `${active ? '#fff' : '#E5B670'} `
                    }}>
                        {FEE_AMOUNT_DETAIL[feeAmount].description}
                    </span>
                </AutoColumn>
            </AutoColumn>
        </ButtonRadioChecked>
    )
}
