import styled from "styled-components"
import { BREAKPOINTS } from "theme"


const BridgePageWrapper = styled.div`
    width: 100%;
    position: relative;
    min-height: 1000px;
    padding-top: 64px;
    img.bg{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        padding-top: 32px;
        min-height: 700px;
    }
`

const BridgePageBody = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
`

const PageTitleBox = styled.div`
    display: grid;
    justify-items: center;
    gap: 20px;
    position: relative;
    z-index: 1;
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        max-width: 90%;
        margin: auto;
    }
`
const PageTitle = styled.h1`
    color: #FFB704;
    text-align: center;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #723507;
    font-family: DynaPuff;
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 70px */
    letter-spacing: 1.12px;
    text-transform: uppercase;
    margin: 0;
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        font-size: 32px;
    }
`
const TextLinear = styled.span`
`
const PageDesc = styled.span`
    color: #4B2828;
    text-align: center;
    font-family: DynaPuff;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        font-size: 20px; 
    }
`

const BridgeList = styled.div`
    max-width: 80%;
    margin: auto;
    margin-top: 109px;
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    justify-content: center;
    @media screen and (max-width: ${BREAKPOINTS.xl}px){
        max-width: 90%;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        margin-top: 50px;
    }
`

const BridgeItemBox = styled.div`
    width: 100%;
    max-width: 464px;
    /* height: 176px; */
     
    padding: 24px 20px;
    border-radius: 10px;
    background: #FFF6D0;
    box-shadow: 0px 7.395px 30px 0px rgba(145, 103, 20, 0.30), -6px -6px 4px 0px rgba(215, 144, 60, 0.38) inset, 6px 6px 4px 0px rgba(255, 255, 255, 0.68) inset;
`

const BridgeItemBoxTop = styled.div`
    display: flex;
    justify-content: space-between;
    img.icon{
        width: 25px;
        height: 25px;
        cursor: pointer;
        transition: scale 0.15s ease-in-out;
        &:hover{
            scale: 1.05;
        }
        @media screen and (max-width: ${BREAKPOINTS.xs}px){
            width: 18px;
            height: 18px;
        }
    }
`

const BridgeItemBoxTopLeft = styled.div`
    display: flex;
    gap: 20px;
    width: 100%;
    
    img{
        max-width: 60px;
        max-height: 60px;
        border-radius: 6px;
        @media screen and (max-width: 375px){
            max-width: 50px;
            max-height: 50px;
        }
    }
    h4{
        color: #4B2828;
        font-family: DynaPuff;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 100% */
        margin: 0;
        @media screen and (max-width: 375px){
            font-size: 18px;
        }
    }
    
    &>div{
        width: 100%;
        display: grid;
        gap: 13px;
        @media screen and (max-width: ${BREAKPOINTS.md}px){
            gap: 10px;
        }
        @media screen and (max-width: 375px){
            gap: 7px;
        }
    }
    @media screen and (max-width: 375px){
        gap: 15px;
    }
`
const TagList = styled.div`
    display: flex;
    gap: 15px;
`

const TagItemBorder = styled.div`
    width: fit-content; 
    padding: 1px;
    border-radius: 5px;
    border: 1px solid #4B2828;
`
const TagItemBorderContent = styled.div`
    height: 100%;
    width: 100%;
    padding: 3px 10px;
    display: flex;
    justify-content: center;
    align-items: center; 
    span{
        color: #4B2828;
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 15px */
        @media screen and (max-width: 375px){
            font-size: 9px;
        }
    }
`

const BridgeItemBoxBottom = styled.div`
    margin-top: 24px;
    span{
        color: #4B2828;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; 
        @media screen and (max-width: 375px){
            font-size: 14px;
        }
    }
`

export default function BridgePage() {
    const BridgeInfoList: {
        name: string
        tags: string[]
        content: string
        link: string
        img: string
    }[] = [
            {
                name: 'Zircuit',
                tags: ["Official", "Secure", "Airdrop"],
                content: "Bridge to earn Staking + Restaking + Eigenlayer Points + LRT points + Zircuit Points",
                link: "https://stake.zircuit.com/?ref=9wn6me",
                img: "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/bridge/partners/zircuit.png"
            },
            {
                name: 'Orbiter',
                tags: ["Partner", "Secure", "Fast"],
                content: "Decentralized cross-rollup bridge offers low cost and almost instant transfers",
                link: "https://dealer.orbiter.finance/?dealerId=0xB4c3f56e271d938B5be2C0a813FC6CABc4C2A4A5",
                img: "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/bridge/partners/orbiter.png"
            },
            {
                name: 'RetroBridge',
                tags: ["Partner", "Secure", "Fast"],
                content: "🔮 RetroBridge is a robust multichain ecosystem and gateway to seamless connectivity across various EVM and non-EVM chains, providing solution for all interactions within a single app",
                link: "https://app.retrobridge.io",
                img: "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/bridge/partners/retro.png"
            },
            {
                name: 'Owlto',
                tags: ["Cheap", "Swap"],
                content: "One sentence intro of Owlto: Owlto Finance is an intent-centric interoperability protocol, “Bridge the World with AI Agent”",
                link: "https://owlto.finance/?to=Zircuit",
                img: "https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/bridge/partners/owito.png"
            },
        ]

    return <BridgePageWrapper>
        <BridgePageBody>
            <PageTitleBox>
                <PageTitle>
                    READY TO EXPLORE ZIRCUIT?
                </PageTitle>
                <PageDesc style={{ maxWidth: '864px' }}>
                    Bridge to Zircuit with our trusted partners. <br />
                    Fast, cheap and efficient experience guaranteed.
                </PageDesc>
            </PageTitleBox>

            <BridgeList>
                {
                    BridgeInfoList.map((item, index) => <BridgeItemBox key={index}>
                        <BridgeItemBoxTop>
                            <BridgeItemBoxTopLeft>
                                <img src={item.img} alt="gmeowfi" />
                                <div>
                                    <h4>{item.name}</h4>
                                    <TagList>
                                        {
                                            item.tags.map((tag, tagIndex) => <TagItemBorder key={tagIndex}>
                                                <TagItemBorderContent>
                                                    <TextLinear>{tag}</TextLinear>
                                                </TagItemBorderContent>
                                            </TagItemBorder>)
                                        }
                                    </TagList>
                                </div>
                            </BridgeItemBoxTopLeft>
                            <img onClick={() => window.open(item.link, '_blank')} className="icon" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/bridge/external-icon.png" alt="gmeowfi" />
                        </BridgeItemBoxTop>

                        <BridgeItemBoxBottom>
                            <span>{item.content}</span>
                        </BridgeItemBoxBottom>


                    </BridgeItemBox>)
                }


            </BridgeList>

        </BridgePageBody>
    </BridgePageWrapper>
}
