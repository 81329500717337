

export default function GmeowIEarcon({
    w = 24,
    height = 24,
    fill = '#00E440'
}) {

    return <svg width={w} height={height} viewBox="0 0 98 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M48.6309 0C66.1292 0 106.436 36.5038 95.3681 65.9428C82.7163 99.5963 14.5461 99.5963 1.89401 65.9428C-9.17359 36.5038 31.1325 0 48.6309 0Z" fill={fill} />
    </svg>

}