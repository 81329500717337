// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { Trace } from '@uniswap/analytics'
import { EventName, ModalName } from '@uniswap/analytics-events'
import { Currency, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { sendEvent } from 'components/analytics'
import useDebounce from 'hooks/useDebounce'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useToggle from 'hooks/useToggle'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { getTokenFilter } from 'lib/hooks/useTokenList/filtering'
import { tokenComparator, useSortTokensByQuery } from 'lib/hooks/useTokenList/sorting'
import { ChangeEvent, KeyboardEvent, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { Text } from 'rebass'
import { useAllTokenBalances } from 'state/connection/hooks'
import styled, { useTheme } from 'styled-components/macro'
import { UserAddedToken } from 'types/tokens'
import './modalSelect.css'
import { useAllTokens, useIsUserAddedToken, useSearchInactiveTokenLists, useToken } from '../../hooks/Tokens'
import { BREAKPOINTS, CloseIcon, ThemedText } from '../../theme'
import { isAddress } from '../../utils'
import Column from '../Column'
import Row, { RowBetween } from '../Row'
import CommonBases from './CommonBases'
import CurrentBase from './CurrentBase'
import { CurrencyRow, formatAnalyticsEventProperties } from './CurrencyList'
import CurrencyList, { CurrencyListMyToken } from './CurrencyList'
import { PaddedColumn, SearchInput, Separator } from './styleds'
import { COMMON_BASES, STABLE_COIN, MEME_TOKEN, DEFI_TOKEN } from 'constants/routing'
import {DEFAULT_CHAINID, SupportedChainId} from 'constants/chains'

const ContentWrapper = styled(Column)`
  width: 100%;
  flex: 1 1;
  position: relative;
  color: #4B2828;
  border-radius: 10px;
  border: 1.5px solid #E5B670;
  background: #FFF6D0;
  box-shadow: 0px 7.395px 30px 0px rgba(145, 103, 20, 0.30);
`

const LabelWrapper = styled.div`
  padding: 0 32px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 0 16px;
  }
`

interface CurrencySearchProps {
  isOpen: boolean
  onDismiss: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency, hasWarning?: boolean) => void
  otherSelectedCurrency?: Currency | null
  showCommonBases?: boolean
  showCurrencyAmount?: boolean
  disableNonToken?: boolean
}

export function CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  showCommonBases,
  showCurrencyAmount,
  disableNonToken,
  onDismiss,
  isOpen,
}: CurrencySearchProps) {
  const chainId = DEFAULT_CHAINID
  const theme = useTheme()

  const [tokenLoaderTimerElapsed, setTokenLoaderTimerElapsed] = useState(false)

  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>()

  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedQuery = useDebounce(searchQuery, 200)
  const isAddressSearch = isAddress(debouncedQuery)
  const searchToken = useToken(debouncedQuery)
  const searchTokenIsAdded = useIsUserAddedToken(searchToken)

  useEffect(() => {
    if (isAddressSearch) {
      sendEvent({
        category: 'Currency Select',
        action: 'Search by address',
        label: isAddressSearch,
      })
    }
  }, [isAddressSearch])

  const defaultTokens = useAllTokens()
  const filteredTokens: Token[] = useMemo(() => {
    return Object.values(defaultTokens).filter(getTokenFilter(debouncedQuery))
  }, [defaultTokens, debouncedQuery])

  const [balances, balancesAreLoading] = useAllTokenBalances()
  const sortedTokens: Token[] = useMemo(
    () =>
      !balancesAreLoading
        ? filteredTokens
          .filter((token) => {
            // If there is no query, filter out unselected user-added tokens with no balance.
            if (!debouncedQuery && token instanceof UserAddedToken) {
              if (selectedCurrency?.equals(token) || otherSelectedCurrency?.equals(token)) return true
              // return balances[token.address]?.greaterThan(0)
              return balances[token.address]
            }
            return true
          })
          .sort(tokenComparator.bind(null, balances))
        : [],
    [balances, balancesAreLoading, debouncedQuery, filteredTokens, otherSelectedCurrency, selectedCurrency]
  )
  const sortedTokensMyTokens: Token[] = useMemo(
    () =>
      !balancesAreLoading
        ? filteredTokens
          .filter((token) => {
            // If there is no query, filter out unselected user-added tokens with no balance.
            if (!debouncedQuery && token instanceof UserAddedToken) {
              if (selectedCurrency?.equals(token) || otherSelectedCurrency?.equals(token)) return true
              return balances[token.address]?.greaterThan(0)
            }
            return true
          })
          .sort(tokenComparator.bind(null, balances))
        : [],
    [balances, balancesAreLoading, debouncedQuery, filteredTokens, otherSelectedCurrency, selectedCurrency]
  )
  const isLoading = Boolean(balancesAreLoading && !tokenLoaderTimerElapsed)

  const filteredSortedTokens = useSortTokensByQuery(debouncedQuery, sortedTokens)
  const filteredSortedTokensMyTokens = useSortTokensByQuery(debouncedQuery, sortedTokensMyTokens)

  const native = useNativeCurrency()
  const wrapped = native.wrapped

  const searchCurrencies: Currency[] = useMemo(() => {
    const s = debouncedQuery.toLowerCase().trim()

    const tokens = filteredSortedTokens.filter((t) => !(t.equals(wrapped) || (disableNonToken && t.isNative)))
    const natives = (disableNonToken || native.equals(wrapped) ? [wrapped] : [native, wrapped]).filter(
      (n) => n.symbol?.toLowerCase()?.indexOf(s) !== -1 || n.name?.toLowerCase()?.indexOf(s) !== -1
    )

    return [...natives, ...tokens]
  }, [debouncedQuery, filteredSortedTokens, wrapped, disableNonToken, native])

  const searchCurrenciesMyTokens: Currency[] = useMemo(() => {
    const s = debouncedQuery.toLowerCase().trim()

    const tokens = filteredSortedTokensMyTokens.filter((t) => !(t.equals(wrapped) || (disableNonToken && t.isNative)))
    const natives = (disableNonToken || native.equals(wrapped) ? [wrapped] : [native, wrapped]).filter(
      (n) => n.symbol?.toLowerCase()?.indexOf(s) !== -1 || n.name?.toLowerCase()?.indexOf(s) !== -1
    )

    return [...natives, ...tokens]
  }, [debouncedQuery, filteredSortedTokensMyTokens, wrapped, disableNonToken, native])

  const handleCurrencySelect = useCallback(
    (currency: Currency, hasWarning?: boolean) => {
      onCurrencySelect(currency, hasWarning)
      if (!hasWarning) onDismiss()
    },
    [onDismiss, onCurrencySelect]
  )
  // custome tab list token
  const ListPopular = typeof chainId !== 'undefined' ? COMMON_BASES[chainId] ?? [] : []
  const ListStableCoin = typeof chainId !== 'undefined' ? STABLE_COIN[chainId] ?? [] : []
  const ListMeme = typeof chainId !== 'undefined' ? MEME_TOKEN[chainId] ?? [] : []
  const ListDeFi = typeof chainId !== 'undefined' ? DEFI_TOKEN[chainId] ?? [] : []

  // clear the input on open
  useEffect(() => {
    if (isOpen) setSearchQuery('')
  }, [isOpen])

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>()
  const handleInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
    fixedList.current?.scrollTo(0)
  }, [])

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const s = debouncedQuery.toLowerCase().trim()
        if (s === native?.symbol?.toLowerCase()) {
          handleCurrencySelect(native)
        } else if (searchCurrencies.length > 0) {
          if (
            searchCurrencies[0].symbol?.toLowerCase() === debouncedQuery.trim().toLowerCase() ||
            searchCurrencies.length === 1
          ) {
            handleCurrencySelect(searchCurrencies[0])
          }
        }
      }
    },
    [debouncedQuery, native, searchCurrencies, handleCurrencySelect]
  )

  // menu ui
  const [open, toggle] = useToggle(false)
  const node = useRef<HTMLDivElement>()
  useOnClickOutside(node, open ? toggle : undefined)

  // if no results on main list, show option to expand into inactive
  const filteredInactiveTokens = useSearchInactiveTokenLists(
    filteredTokens.length === 0 || (debouncedQuery.length > 2 && !isAddressSearch) ? debouncedQuery : undefined
  )

  // Timeout token loader after 3 seconds to avoid hanging in a loading state.
  useEffect(() => {
    const tokenLoaderTimer = setTimeout(() => {
      setTokenLoaderTimerElapsed(true)
    }, 3000)
    return () => clearTimeout(tokenLoaderTimer)
  }, [])
  // add selected tab
  const [tabSelector, setTabSelector] = useState(1)

  return (
    <ContentWrapper>
      <Trace name={EventName.TOKEN_SELECTOR_OPENED} modal={ModalName.TOKEN_SELECTOR} shouldLogImpression>
        <PaddedColumn gap="16px">
          <RowBetween>
            <Text className='search-token-title' fontWeight={500} fontSize={24}>
              <Trans>Select a token</Trans>
            </Text>
            <CloseIcon color='#FFB704' onClick={onDismiss} />
          </RowBetween>
          <Row style={{ position: 'relative' }}>
            <SearchInput
              type="text"
              id="token-search-input"
              placeholder={t`Search token`}
              autoComplete="off"
              value={searchQuery}
              ref={inputRef as RefObject<HTMLInputElement>}
              onChange={handleInput}
              onKeyDown={handleEnter}
            />

            <img style={{
              width: '18px',
              position: 'absolute',
              top: '10px',
              right: '10px'
            }} src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/swap/search-icon.png' alt="gmeow-fi" />
          </Row>

          {/*{showCommonBases && (*/}
          {/*  <>*/}
          {/*     <CommonBases*/}
          {/*      chainId={chainId}*/}
          {/*      onSelect={handleCurrencySelect}*/}
          {/*      selectedCurrency={selectedCurrency}*/}
          {/*      searchQuery={searchQuery}*/}
          {/*      isAddressSearch={isAddressSearch}*/}
          {/*    />*/}
          {/*    <Text fontWeight={500} fontSize={16}>*/}
          {/*      <Trans>Common Assets</Trans>*/}
          {/*    </Text>*/}
          {/*    <CurrentBase*/}
          {/*      chainId={chainId}*/}
          {/*      onSelect={handleCurrencySelect}*/}
          {/*      selectedCurrency={selectedCurrency}*/}
          {/*      searchQuery={searchQuery}*/}
          {/*      isAddressSearch={isAddressSearch}*/}
          {/*    />*/}
          {/*  </>*/}
          {/*)}*/}
        </PaddedColumn>
        <Separator />
        {searchToken && !searchTokenIsAdded ? (
          <Column style={{ padding: '20px 0', height: '100%' }}>
            <CurrencyRow
              currency={searchToken}
              isSelected={Boolean(searchToken && selectedCurrency && selectedCurrency.equals(searchToken))}
              onSelect={(hasWarning: boolean) => searchToken && handleCurrencySelect(searchToken, hasWarning)}
              otherSelected={Boolean(searchToken && otherSelectedCurrency && otherSelectedCurrency.equals(searchToken))}
              showCurrencyAmount={showCurrencyAmount}
              eventProperties={formatAnalyticsEventProperties(
                searchToken,
                0,
                [searchToken],
                searchQuery,
                isAddressSearch
              )}
            />
          </Column>
        ) : searchCurrencies?.length > 0 || filteredInactiveTokens?.length > 0 || isLoading ? (
          <div style={{ flex: '1' }}>
            <div className="switch-version-container-select">
              <div
                className={`switch-version-right-select ${tabSelector === 1 ? 'switch-version-left-select' : ''}`}
                onClick={() => {
                  setTabSelector(1)
                }}
              >
                <Text fontWeight={400}>
                  <Trans>Popular</Trans>
                </Text>
              </div>
              
              <div
                className={`switch-version-right-select ${tabSelector === 2 ? 'switch-version-left-select' : ''}`}
                onClick={() => {
                  setTabSelector(2)
                }}
              >
                <Text fontWeight={400}>
                  <Trans>My Tokens</Trans>
                </Text>
              </div>
              <div
                className={`switch-version-right-select ${tabSelector === 3 ? 'switch-version-left-select' : ''}`}
                onClick={() => {
                  setTabSelector(3)
                }}
              >
                <Text fontWeight={400}>
                  <Trans>Meme</Trans>
                </Text>
              </div>
              {/*<div*/}
              {/*  className={`switch-version-right-select ${tabSelector === 4 ? 'switch-version-left-select' : ''}`}*/}
              {/*  onClick={() => {*/}
              {/*    setTabSelector(4)*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <Text fontWeight={400}>*/}
              {/*    <Trans>DeFi</Trans>*/}
              {/*  </Text>*/}
              {/*</div>*/}
              {/*<div*/}
              {/*  className={`switch-version-right-select ${tabSelector === 5 ? 'switch-version-left-select' : ''}`}*/}
              {/*  onClick={() => {*/}
              {/*    setTabSelector(5)*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <Text fontWeight={400}>*/}
              {/*    <Trans>Stablecoins</Trans>*/}
              {/*  </Text>*/}
              {/*</div>*/}
            </div>

            <LabelWrapper style={{ marginBottom: '12px' }}>
              <Text fontWeight={500} fontSize={16}>
                <Trans>All tokens</Trans>
              </Text>
            </LabelWrapper>
            <LabelWrapper  >
              {tabSelector === 1 && (
                <CurrencyList
                  height={500}
                  chainId={chainId}
                  currencies={searchCurrencies}
                  otherListTokens={filteredInactiveTokens}
                  onCurrencySelect={handleCurrencySelect}
                  otherCurrency={otherSelectedCurrency}
                  selectedCurrency={selectedCurrency}
                  fixedListRef={fixedList}
                  showCurrencyAmount={showCurrencyAmount}
                  isLoading={isLoading}
                  searchQuery={searchQuery}
                  isAddressSearch={isAddressSearch}
                  tabList={ListPopular}
                />
              )}
              {tabSelector === 2 && (
                <CurrencyListMyToken
                  height={370}
                  chainId={chainId}
                  currencies={searchCurrenciesMyTokens}
                  otherListTokens={filteredInactiveTokens}
                  onCurrencySelect={handleCurrencySelect}
                  otherCurrency={otherSelectedCurrency}
                  selectedCurrency={selectedCurrency}
                  fixedListRef={fixedList}
                  showCurrencyAmount={showCurrencyAmount}
                  isLoading={isLoading}
                  searchQuery={searchQuery}
                  isAddressSearch={isAddressSearch}
                />
              )}
              {tabSelector === 3 && (
                <CurrencyList
                  height={370}
                  chainId={chainId}
                  currencies={searchCurrencies}
                  otherListTokens={filteredInactiveTokens}
                  onCurrencySelect={handleCurrencySelect}
                  otherCurrency={otherSelectedCurrency}
                  selectedCurrency={selectedCurrency}
                  fixedListRef={fixedList}
                  showCurrencyAmount={showCurrencyAmount}
                  isLoading={isLoading}
                  searchQuery={searchQuery}
                  isAddressSearch={isAddressSearch}
                  tabList={ListMeme}
                />
              )}
              {/*{tabSelector === 4 && (*/}
              {/*  <CurrencyList*/}
              {/*    height={370}*/}
              {/*    chainId={chainId}*/}
              {/*    currencies={searchCurrencies}*/}
              {/*    otherListTokens={filteredInactiveTokens}*/}
              {/*    onCurrencySelect={handleCurrencySelect}*/}
              {/*    otherCurrency={otherSelectedCurrency}*/}
              {/*    selectedCurrency={selectedCurrency}*/}
              {/*    fixedListRef={fixedList}*/}
              {/*    showCurrencyAmount={showCurrencyAmount}*/}
              {/*    isLoading={isLoading}*/}
              {/*    searchQuery={searchQuery}*/}
              {/*    isAddressSearch={isAddressSearch}*/}
              {/*    tabList={ListDeFi}*/}
              {/*  />*/}
              {/*)}*/}
              {/*{tabSelector === 5 && (*/}
              {/*  <CurrencyList*/}
              {/*    height={370}*/}
              {/*    chainId={chainId}*/}
              {/*    currencies={searchCurrencies}*/}
              {/*    otherListTokens={filteredInactiveTokens}*/}
              {/*    onCurrencySelect={handleCurrencySelect}*/}
              {/*    otherCurrency={otherSelectedCurrency}*/}
              {/*    selectedCurrency={selectedCurrency}*/}
              {/*    fixedListRef={fixedList}*/}
              {/*    showCurrencyAmount={showCurrencyAmount}*/}
              {/*    isLoading={isLoading}*/}
              {/*    searchQuery={searchQuery}*/}
              {/*    isAddressSearch={isAddressSearch}*/}
              {/*    tabList={ListStableCoin}*/}
              {/*  />*/}
              {/*)}*/}
            </LabelWrapper>
          </div>
        ) : (
          <Column style={{ padding: '20px', height: '100%' }}>
            <ThemedText.DeprecatedMain color={theme.deprecated_text3} textAlign="center" mb="20px">
              <Trans>No results found.</Trans>
            </ThemedText.DeprecatedMain>
          </Column>
        )}
      </Trace>
    </ContentWrapper >
  )
}
