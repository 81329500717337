export function isDevelopmentEnv(): boolean {
  return process.env.NODE_ENV === 'development'
}

export function isTestEnv(): boolean {
  return process.env.NODE_ENV === 'test'
}

export function isStagingEnv(): boolean {
  return Boolean(process.env.REACT_APP_STAGING)
}

export function isProductionEnv(): boolean {
  return process.env.NODE_ENV === 'production' && !isStagingEnv()
}

export const GMEOW_API_BASE_URL = 'https://api.gmeow.fi/gmeowfi/api/v1'
// export const GMEOW_API_BASE_URL = 'http://localhost:3000/gmeowfi/api/v1'
// export const GMEOW_API_BASE_URL = 'https://vps1.orisu.site/gmeowfi/api/v1'
