import { Trans } from '@lingui/macro'
import { TraceEvent } from '@uniswap/analytics'
import { BrowserEvent, ElementName, EventName } from '@uniswap/analytics-events'
import { useWeb3React } from '@web3-react/core'
import WalletDropdown from 'components/WalletDropdown'
import { getConnection, getIsMetaMask } from 'connection/utils'
import { Portal } from 'nft/components/common/Portal'
import { useIsNftClaimAvailable } from 'nft/hooks/useIsNftClaimAvailable'
import { getIsValidSwapQuote } from 'pages/Swap'
import { darken } from 'polished'
import { useEffect, useMemo, useRef, useState } from 'react'
import { AlertTriangle, ChevronDown, ChevronUp } from 'react-feather'
import { useAppSelector } from 'state/hooks'
import { useDerivedSwapInfo } from 'state/swap/hooks'
import styled, { useTheme } from 'styled-components/macro'
import { colors } from 'theme/colors'
import { flexRowNoWrap } from 'theme/styles'

import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import {
    useCloseModal,
    useModalIsOpen,
    useToggleWalletDropdown,
    useToggleWalletModal,
} from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/reducer'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/types'
import { shortenAddress } from '../../utils'
import { ButtonSecondary } from '../Button'
import StatusIcon from '../Identicon/StatusIcon'
import Loader from '../Loader'
import { RowBetween } from '../Row'
import WalletModal from '../WalletModal'
import { NotchedButtonFill } from '../../components/Button/index'
import { NotchedButtonBolder } from 'components/Button/NotchedButton'
import { Box } from 'nft/components/Box'
import { BREAKPOINTS } from 'theme'
import { Drawer } from 'antd'
import MenuTreat from 'components/NavBar/MenuTreat'
// https://stackoverflow.com/a/31617326
const FULL_BORDER_RADIUS = 9999

const ChevronWrapper = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 10px 16px 10px 4px;

    :hover {
        color: #68ff9b;
    }

    :hover,
    :active,
    :focus {
        border: none;
    }
`

const Web3StatusGeneric = styled(ButtonSecondary)`
    ${flexRowNoWrap};
    width: 100%;
    align-items: center;
    padding: 0.5rem;
    cursor: pointer;
    user-select: none;
    height: 36px;
    margin-right: 2px;
    margin-left: 2px;

    :focus {
        outline: none;
    }
`
const Web3StatusError = styled(Web3StatusGeneric)`
    background-color: ${({ theme }) => theme.deprecated_red1};
    border: 1px solid ${({ theme }) => theme.deprecated_red1};
    color: ${({ theme }) => theme.deprecated_white};
    font-weight: 500;

    :hover,
    :focus {
        background-color: ${({ theme }) => darken(0.1, theme.deprecated_red1)};
    }
`

const Web3StatusConnectWrapper = styled.div<{ faded?: boolean }>`
    ${flexRowNoWrap};
    align-items: center;
    background-color: transparent;
    border: none;
    padding: 0;
    height: 40px;

    :hover,
    :active,
    :focus {
        border: none;
    }
`

const Web3StatusConnected = styled.div<{
    pending?: boolean
    isClaimAvailable?: boolean
}>`
    color: #0a090f;
    font-size: 18px;
    font-family: Righteous;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 18px;
    word-wrap: break-word;
    border: unset;
    margin-left: 5px;

    :hover,
    :active,
    :focus {
        border: unset;
    }

    @media (max-width: 747px) {
        margin-left: 0;
    }

    @media (max-width: 540px) {
        .account_mb_box p{
            /* scale: 0.8; */
            font-size: 12px !important;
        }
    }
`

const AddressAndChevronContainer = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 540px) {
        img{
            width: 16px;
            height: 16px;
        }
    }
`

const Text = styled.p<{
    fontSize?: string,
    fontSizeMb?: string,
    fontFamily?: string,
}>`
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 0.5rem 0 0.25rem;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '1rem')} !important;
    font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : `'DynaPuff', sans-serif`)} !important;
    width: fit-content;
    font-weight: 500;
    @media (max-width: 747px) {
        font-size: ${({ fontSizeMb }) => (fontSizeMb ? fontSizeMb : '12px')} !important;
    }
`

const NetworkIcon = styled(AlertTriangle)`
    margin-left: 0.25rem;
    margin-right: 0.5rem;
    width: 16px;
    height: 16px;
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
    return b.addedTime - a.addedTime
}

const VerticalDivider = styled.div`
    height: 30px;
    margin: 0px 5px 0px 10px;
    width: 1px;
    background-color: #4B2828;
`

const StyledConnectButton = styled.button`
    width: 177px;
    position: relative;
    color: #FFF9E1;
    text-align: center;
    font-family: DynaPuff;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 21px */
    text-transform: uppercase;

    display: inline-flex;
    height: 72px;
    padding: 0px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    background: transparent;
    border: none;
    margin-bottom: 10px;

    &:hover {
        scale: 1.05;
    }

    img.mobile {
        display: none;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        margin-bottom: 0;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        margin-bottom: 0;
        width: 88px;
        height: 40px;
        img.tablet {
            display: none;
        }

        img.mobile {
            display: block;
            top: -2px;
        }
    }
`

const CHEVRON_PROPS = {
    height: 20,
    width: 20,
}


function Web3StatusInner() {
    const { account, connector, chainId, ENSName } = useWeb3React()
    const connectionType = getConnection(connector).type
    // get version from the url
    const {
        trade: { state: tradeState, trade },
        inputError: swapInputError,
    } = useDerivedSwapInfo()
    const validSwapQuote = getIsValidSwapQuote(trade, tradeState, swapInputError)
    const theme = useTheme()
    const toggleWalletDropdown = useToggleWalletDropdown()
    const toggleWalletModal = useToggleWalletModal()
    const walletIsOpen = useModalIsOpen(ApplicationModal.WALLET_DROPDOWN)
    const isClaimAvailable = useIsNftClaimAvailable((state) => state.isClaimAvailable)

    const error = useAppSelector((state) => state.connection.errorByConnectionType[connectionType])
    const allTransactions = useAllTransactions()

    const sortedRecentTransactions = useMemo(() => {
        const txs = Object.values(allTransactions)
        return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
    }, [allTransactions])

    const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

    const hasPendingTransactions = !!pending.length
    // const toggleWallet = toggleWalletDropdown
    const [isShowTreatSidebar, setIsShowTreatSidebar] = useState<boolean>(false)
    const toggleWallet = () => setIsShowTreatSidebar(!isShowTreatSidebar)
    // if (!chainId) {
    //   return null
    // } else
    if (error) {
        return (
            <Web3StatusError onClick={toggleWallet}>
                <NetworkIcon />
                <Text>
                    <Trans>Error</Trans>
                </Text>
            </Web3StatusError>
        )
    } else if (account) {
        const chevronProps = {
            ...CHEVRON_PROPS,
            color: theme.textSecondary,
        }

        return (
            <Web3StatusConnected
                data-testid="web3-status-connected"
                onClick={() => setIsShowTreatSidebar(true)}
                pending={hasPendingTransactions}
                isClaimAvailable={isClaimAvailable}
            >
                <Box display={{ sm: 'none', xxl: 'block' }}>
                    <NotchedButtonFill padding="15px 30px" mbHeight="40px" width="165px" isTurnOfEar={true}
                        isHideSwitchchain={true}>
                        {/* {!hasPendingTransactions && <StatusIcon size={24} connectionType={connectionType} />} */}
                        {hasPendingTransactions ? (
                            <RowBetween>
                                <Text>
                                    <Trans>{pending?.length} Pending</Trans>
                                </Text>{' '}
                                <Loader stroke="black" />
                            </RowBetween>
                        ) : (
                            <AddressAndChevronContainer>
                                <Text>{ENSName || shortenAddress(account, 2)}</Text>
                                {/* {walletIsOpen ? <ChevronUp {...chevronProps} /> : <ChevronDown {...chevronProps} />} */}
                                <img
                                    src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/common/wallet-arrow-down.png"
                                    alt="gmeowfi" />
                            </AddressAndChevronContainer>
                        )}

                    </NotchedButtonFill>
                </Box>

                <Box display={{ sm: 'block', xxl: 'none' }} className="account_mb_box">
                    <NotchedButtonFill padding="7px 12px" mbHeight="30px" isTurnOfEar={true} isHideSwitchchain={true}>
                        {/* {!hasPendingTransactions && <StatusIcon size={16} connectionType={connectionType} />} */}
                        {hasPendingTransactions ? (
                            <RowBetween>
                                <Text>
                                    <Trans>{pending?.length} Pending</Trans>
                                </Text>{' '}
                                <Loader stroke="black" />
                            </RowBetween>
                        ) : (
                            <AddressAndChevronContainer>
                                <Text>{ENSName || shortenAddress(account, 2)}</Text>
                                <img
                                    src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/common/wallet-arrow-down.png"
                                    alt="gmeowfi" />
                                {/* {walletIsOpen ? <ChevronUp {...chevronProps} /> : <ChevronDown {...chevronProps} />
                                } */}
                            </AddressAndChevronContainer>
                        )}
                    </NotchedButtonFill>
                </Box>

                <Drawer open={isShowTreatSidebar} onClose={(e) => {
                    e.stopPropagation()
                    setIsShowTreatSidebar(false)
                }}>
                    <MenuTreat onClose={(e: { stopPropagation: () => void }) => {
                        e.stopPropagation()
                        setIsShowTreatSidebar(false)
                    }} />
                </Drawer>
            </Web3StatusConnected>
        )
    } else {
        const chevronProps = {
            ...CHEVRON_PROPS,
            color: '#68ff9b',
            'data-testid': 'navbar-wallet-dropdown',
        }
        return (
            <TraceEvent
                events={[BrowserEvent.onClick]}
                name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
                properties={{ received_swap_quote: validSwapQuote }}
                element={ElementName.CONNECT_WALLET_BUTTON}
            >
                <Web3StatusConnectWrapper faded={!account}>
                    <Box display={{ sm: 'none', xxl: 'block' }}>
                        <StyledConnectButton
                            onClick={toggleWalletModal}
                        >
                            <img className='tablet'
                                src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/common/wallet-connect-btn-bg.png"
                                alt="gmeowfi" />
                            <img className='mobile'
                                src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/common/wallet-connect-btn-mobile-bg.png"
                                alt="gmeowfi" />
                            CONNECT
                        </StyledConnectButton>
                    </Box>

                    <Box display={{ sm: 'block', xxl: 'none' }}>
                        <StyledConnectButton onClick={toggleWalletModal}
                        >
                            <img className='tablet'
                                src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/common/wallet-connect-btn-bg.png"
                                alt="gmeowfi" />
                            <img className='mobile'
                                src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/common/wallet-connect-btn-mobile-bg.png"
                                alt="gmeowfi" />
                            CONNECT WALLET
                        </StyledConnectButton>
                    </Box>

                    {/* <VerticalDivider /> */}
                    {/* <ChevronWrapper onClick={toggleWalletDropdown}>
            {walletIsOpen ? <ChevronUp {...chevronProps} /> : <ChevronDown {...chevronProps} />}
          </ChevronWrapper> */}
                </Web3StatusConnectWrapper>
            </TraceEvent>
        )
    }
}

export default function Web3Status() {
    const { ENSName } = useWeb3React()

    const allTransactions = useAllTransactions()
    const ref = useRef<HTMLDivElement>(null)
    const walletRef = useRef<HTMLDivElement>(null)
    const closeModal = useCloseModal(ApplicationModal.WALLET_DROPDOWN)
    const isOpen = useModalIsOpen(ApplicationModal.WALLET_DROPDOWN)

    useOnClickOutside(ref, isOpen ? closeModal : undefined, [walletRef])

    const sortedRecentTransactions = useMemo(() => {
        const txs = Object.values(allTransactions)
        return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
    }, [allTransactions])

    const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)
    const confirmed = sortedRecentTransactions.filter((tx) => tx.receipt).map((tx) => tx.hash)

    return (
        <span ref={ref}>
            <Web3StatusInner />
            <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending}
                confirmedTransactions={confirmed} />
            <Portal>
                <span ref={walletRef}>
                    <WalletDropdown />
                </span>
            </Portal>
        </span>
    )
}
