import { useEffect, useState } from "react";


export default function useCountdownNumber(num: number, delay: number = 1000) {
    const [value, setValue] = useState<number>(num)

    let stopCountdown = false
    useEffect(() => {
        const intervalCountdown = setInterval(() => {
            if (!stopCountdown)
                setValue(prev => prev - 1)
        }, delay)

        return () => {
            clearInterval(intervalCountdown)
        }
    }, [])

    useEffect(() => {
        stopCountdown = true
        setValue(num)
        stopCountdown = false
    }, [num])

    return value
}