import {Box} from 'rebass/styled-components'
import styled from 'styled-components/macro'
import {BREAKPOINTS} from 'theme';

const Card = styled(Box)<{ width?: string; padding?: string; border?: string; $borderRadius?: string }>`
    width: ${({width}) => width ?? '100%'};
    padding: ${({padding}) => padding ?? '0'};
    border: ${({border}) => border};
`
export default Card

export const LightCard = styled(Card)`
    border: 1px solid ${({theme}) => '#FFF9E1'};
    color: #4B2828;
    border-radius: 10px;
    background: transparent;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        img {
            width: 24px;
            height: 24px;
        }
    }
`

export const GrayCard = styled(Card)`
    background-color: ${({theme}) => theme.deprecated_bg3};
`

export const DarkGrayCard = styled(Card)`
    background-color: ${({theme}) => theme.deprecated_bg2};
`

export const DarkCard = styled(Card)`
    background-color: ${({theme}) => theme.deprecated_bg0};
`
export const MonoCard = styled(Card)`
    border-radius: 10px;
    background: #FFF0CA;
`

export const OutlineCard = styled(Card)`
    border: 1px solid ${({theme}) => 'rgba(239, 239, 228, 0.2)'};
`

export const YellowCard = styled(Card)`
    background-color: rgba(243, 132, 30, 0.05);
    color: ${({theme}) => theme.deprecated_yellow3};
    font-weight: 500;
`

export const BlueCard = styled(Card)`
        //background-color: ${({theme}) => '#1c1b1f'};
    background-color: transparent;
    color: ${({theme}) => '#4B2828'};
`
