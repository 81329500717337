import styled from 'styled-components'
import {BREAKPOINTS, ExternalLink} from 'theme'
import {useEffect, useState} from "react";
import TextScramble from "./components/TextScarmble";

const WrapperSection2 = styled.section`
    background: #fff9e1;
    width: 100%;
    height: 100%;
    margin: 0px auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`

const WrapperContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 90px;
    padding-bottom: 140px;
    position: relative;
    z-index: 2;

    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        gap: 40px;
        padding-bottom: 60px;
    }
`

const WrapperTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
`

const WrapperImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 114px;
        height: 80px;
    }
`
const WrapperText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    text-align: center;

    h2 {
        margin: 0px;
        color: #4B2828;
        text-align: center;
        font-family: DynaPuff;
        font-size: 56px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 56px */
    }

    p {
        margin: 0px;
        color: #4B2828;
        text-align: center;
        font-family: Outfit;
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 21px */
    }

    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        gap: 16px;
        h2 {
            font-size: 32px;
            max-width: 335px;
        }

        p {
            font-size: 16px;
        }
    }
`

const WrapperBox = styled.div`
    display: flex;
    gap: 24px;
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        display: grid;
        justify-items: center;
    }
`

const Box = styled.div`
    width: 374px;
    height: 369px;
    position: relative;
    border-radius: 10px;
    background: #FFF6D0;
    box-shadow: 0px 7.395px 30px 0px rgba(145, 103, 20, 0.30), -6px -6px 4px 0px rgba(215, 144, 60, 0.60) inset, 6px 6px 4px 0px rgba(255, 255, 255, 0.80) inset;
    cursor: pointer;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        width: 335px;
        height: 308px;
    }
`

const WrapperSvg = styled.div`
    width: 374px;
    height: 369px;
    display: none;
`
const WrapperBoxContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const BoxContent = styled.div`
    padding: 32px;
    height: 100%;
    display: grid;
    gap: 24px;
`
const WrapperBoxContentImg = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const BoxContentImg = styled.div`
    width: 140px;
    height: 140px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        width: 100px;
        height: 100px;
    }
`

const Line = styled.div`
    width: 100%;
    height: 1px;
    background: #ECC487;
    margin: 0px auto;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`
const ContentInner = styled.div`
    display: grid;
    gap: 12px;

    h2 {
        color: #4B2828;
        font-family: DynaPuff;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 36px */
        margin: 0px;
    }

    p {
        color: #93553A;
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 22.5px */
        margin: 0px;
    }

    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        h2 {
            font-size: 24px;
        }

        p {
            font-size: 16px;
        }
    }
`

const Cloud = styled.div`
    position: absolute;
    left: 0;
    top: 43%;
    z-index: 1;
    height: 320px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        display: none;
    }
`

export default function Section3() {
    const phrases = [
        'The Trio of Meow Fortune'
    ];

    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index == phrases.length - 1) {
            return;
        }

        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        }, 2500);

        return () => clearInterval(interval);
    }, [index, phrases]);
    return (
        <>
            <WrapperSection2>
                <WrapperContent>
                    <WrapperTitle>
                        <WrapperImage>
                            <img
                                src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/cat_icon.png"
                                alt="cat_icon"/>
                        </WrapperImage>
                        <WrapperText>
                            <TextScramble text={phrases[index]}/>
                            <p>Meet the charismatic trio behind the Gmeow <br/> ecosystem, where earning is as
                                delightful as a <br/> catnap in the sun</p>
                        </WrapperText>
                    </WrapperTitle>
                    <WrapperBox>
                        <Box>
                            <WrapperSvg>
                                <img
                                    src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section3/box.svg"
                                    alt="box"/>
                            </WrapperSvg>
                            <ExternalLink href="https://docs.gmeow.fi/tokens/gm-token">
                                <WrapperBoxContent>
                                    <BoxContent>
                                        <WrapperBoxContentImg>
                                            <BoxContentImg>
                                                <img width={'100%'} height={'100%'}
                                                     src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section3/img_1.png'
                                                     alt="img_1"/>
                                            </BoxContentImg>
                                        </WrapperBoxContentImg>
                                        <Line/>
                                        <Content>
                                            <ContentInner>
                                                <h2>GM</h2>
                                                <p>The lifeblood of the Gmeow platform with deflationary traits that
                                                    rewards
                                                    holders.</p>
                                            </ContentInner>
                                        </Content>
                                    </BoxContent>
                                </WrapperBoxContent>
                            </ExternalLink>
                        </Box>
                        <Box>
                            <WrapperSvg>
                                <img
                                    src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section3/box.svg"
                                    alt="box"/>
                            </WrapperSvg>
                            <ExternalLink href="https://docs.gmeow.fi/tokens/xgm-token">
                                <WrapperBoxContent>
                                    <BoxContent>
                                        <WrapperBoxContentImg>
                                            <BoxContentImg>
                                                <img width={'100%'} height={'100%'}
                                                     src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section3/img_2.png'
                                                     alt="img_2"/>
                                            </BoxContentImg>
                                        </WrapperBoxContentImg>
                                        <Line/>
                                        <Content>
                                            <ContentInner>
                                                <h2>xGM</h2>
                                                <p>A token for the most loyal Gmeows, offering special staking benefits
                                                    and
                                                    exclusive rewards.</p>
                                            </ContentInner>
                                        </Content>
                                    </BoxContent>
                                </WrapperBoxContent>
                            </ExternalLink>
                        </Box>
                        <Box>
                            <WrapperSvg>
                                <img
                                    src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section3/box.svg"
                                    alt="box"/>
                            </WrapperSvg>
                            <ExternalLink href="https://docs.gmeow.fi/protocol/paw-token">
                                <WrapperBoxContent>
                                    <BoxContent>
                                        <WrapperBoxContentImg>
                                            <BoxContentImg>
                                                <img width={'100%'} height={'100%'}
                                                     src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section3/img_3.png'
                                                     alt="img_3"/>
                                            </BoxContentImg>
                                        </WrapperBoxContentImg>
                                        <Line/>
                                        <Content>
                                            <ContentInner>
                                                <h2>PAW</h2>
                                                <p>Stability grow meets feline finesse. Fully backed by the trusty USD
                                                    in
                                                    the Gmeow vault.</p>
                                            </ContentInner>
                                        </Content>
                                    </BoxContent>
                                </WrapperBoxContent>
                            </ExternalLink>
                        </Box>
                    </WrapperBox>
                </WrapperContent>
                <Cloud>
                    <img width={'100%'} height={'100%'}
                         src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section3/img.png"
                         alt="cloud"/>
                </Cloud>
            </WrapperSection2>
        </>
    )
}
