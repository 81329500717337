import {BigNumber} from '@ethersproject/bignumber'
import type {TransactionResponse} from '@ethersproject/providers'
import {Trans} from '@lingui/macro'
import {CurrencyAmount, Percent} from '@uniswap/sdk-core'
import {NonfungiblePositionManager} from '@uniswap/v3-sdk'
import {useWeb3React} from '@web3-react/core'
import {sendEvent} from 'components/analytics'
import RangeBadge from 'components/Badge/RangeBadge'
import {ButtonConfirmed, ButtonPrimary, NotchedButtonFill} from 'components/Button'
import {LightCard} from 'components/Card'
import {AutoColumn} from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import {Break} from 'components/earn/styled'
import FormattedCurrencyAmount from 'components/FormattedCurrencyAmount'
import Loader from 'components/Loader'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import {RowBetween, RowFixed} from 'components/Row'
import Slider from 'components/Slider'
import Toggle from 'components/Toggle'
import {useV3NFTPositionManagerContract} from 'hooks/useContract'
import useDebouncedChangeHandler from 'hooks/useDebouncedChangeHandler'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import {useV3PositionFromTokenId} from 'hooks/useV3Positions'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {Navigate, useLocation, useNavigate, useParams} from 'react-router-dom'
import {useBurnV3ActionHandlers, useBurnV3State, useDerivedV3BurnInfo} from 'state/burn/v3/hooks'
import {useTransactionAdder} from 'state/transactions/hooks'
import {useUserSlippageToleranceWithDefault} from 'state/user/hooks'
import styled, {useTheme} from 'styled-components/macro'
import {BREAKPOINTS, ThemedText} from 'theme'

import TransactionConfirmationModal, {ConfirmationModalContent} from '../../components/TransactionConfirmationModal'
import {WRAPPED_NATIVE_CURRENCY} from '../../constants/tokens'
import {TransactionType} from '../../state/transactions/types'
import {calculateGasMargin} from '../../utils/calculateGasMargin'
import {currencyId} from '../../utils/currencyId'
import {ResponsiveHeaderText, SmallMaxButton, Wrapper} from './styled'
import './remove.css'
import SettingsTab from '../../components/Settings'
import {PositionPageUnsupportedContent} from "../Pool/PositionPage";
import {isSupportedChain} from "../../constants/chains";


const PopupBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.70);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    @media (max-width: ${BREAKPOINTS.md}px) {
        align-items: start;
    }
`

const PopupContent = styled.div`
    padding: 32px;
    border-radius: 10px;
    border: 1.5px solid #E5B670;
    background: #FFF6D0;
    box-shadow: 0 7.395px 30px 0 rgba(145, 103, 20, 0.30);
    position: relative;
    width: 573px;
    @media (max-width: 768px) {
        margin-top: 100px;
        width: 336px;
        padding: 10px;
    }

    .close-icon {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 5px;
        right: 32px;
        cursor: pointer;
    }

    @media (max-width: 768px) {
        .close-icon {
            width: 20px;
            height: 20px;
            top: 5px;
            right: 5px;
        }
    }
`

const ButtonSelectPercentWrapper = styled.div<{ active?: number }>`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .img-wrapper {
        width: 22.2%;

        .percent-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
            cursor: pointer;

            &.percent-25 {
                opacity: ${({active}) => (active === 25 ? '100%' : '50%')} !important;
            }

            &.percent-50 {
                opacity: ${({active}) => (active === 50 ? '100%' : '50%')} !important;
            }

            &.percent-75 {
                opacity: ${({active}) => (active === 75 ? '100%' : '50%')} !important;
            }

            &.percent-100 {
                opacity: ${({active}) => (active === 100 ? '100%' : '50%')} !important;
            }
        }

        img:hover {
            opacity: 100%;
        }
    }
`;


const ResponsiveText = styled.div`
    font-size: 20px;
    color: #4B2828;
    font-weight: 500;

    @media (max-width: ${BREAKPOINTS.lg}px) {
        font-size: 16px;
    }

    @media (max-width: ${BREAKPOINTS.md}px) {
        font-size: 14px;
    }
`;

const Text = styled.p<{
    fontSize?: string,
    fontSizeMb?: string,
    fontFamily?: string,
}>`
    flex: 1 1 auto;
    margin: 0;
    font-size: ${({fontSize}) => (fontSize ? fontSize : '1rem')} !important;
    font-family: ${({fontFamily}) => (fontFamily ? fontFamily : `'DynaPuff', sans-serif`)} !important;
    width: fit-content;
    font-weight: 500;
    @media (max-width: 747px) {
        font-size: ${({fontSizeMb}) => (fontSizeMb ? fontSizeMb : '12px')} !important;
    }
    text-align: start;
`

const swaptop =
    'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/pool/liquidheader.png'
const swapTokens = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/pool/swap.png'
const DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE = new Percent(5, 100)
const DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)
const DEFAULT_REMOVE_V3_LIQUIDITY_SLIPPAGE_TOLERANCE = new Percent(5, 100)

// redirect invalid tokenIds
export default function RemoveLiquidityV3({onClose}: { onClose?: any }) {
    const {chainId} = useWeb3React();
    const {tokenId} = useParams<{ tokenId: string }>();
    const location = useLocation();
    const navigate = useNavigate();

    const parsedTokenId = useMemo(() => {
        try {
            return BigNumber.from(tokenId);
        } catch {
            return null;
        }
    }, [tokenId]);

    if (parsedTokenId === null || parsedTokenId.eq(0)) {
        return <Navigate to={{...location, pathname: '/pool'}} replace/>;
    }

    return (
        <PopupBackdrop>
            <PopupContent>
                <img onClick={onClose} className="close-icon" src="/treat/img_14.png" alt="img_14"/>
                {isSupportedChain(chainId) ? (
                    <Remove tokenId={parsedTokenId}/>
                ) : (
                    <PositionPageUnsupportedContent/>
                )}
            </PopupContent>
        </PopupBackdrop>
    );
}

function Remove({tokenId}: { tokenId: BigNumber }) {
    const {position} = useV3PositionFromTokenId(tokenId)
    const theme = useTheme()
    const {account, chainId, provider} = useWeb3React()
    // flag for receiving WETH
    const [receiveWETH, setReceiveWETH] = useState(false)
    const nativeCurrency = useNativeCurrency()
    const nativeWrappedSymbol = nativeCurrency.wrapped.symbol
    const navigate = useNavigate()
    const [tabSelector, setTabSelector] = useState(1)
    // burn state
    const {percent} = useBurnV3State()
    const {
        position: positionSDK,
        liquidityPercentage,
        liquidityValue0,
        liquidityValue1,
        feeValue0,
        feeValue1,
        outOfRange,
        error,
    } = useDerivedV3BurnInfo(position, receiveWETH)
    const {onPercentSelect} = useBurnV3ActionHandlers()

    const removed = position?.liquidity?.eq(0)

    // boilerplate for the slider
    const [percentForSlider, onPercentSelectForSlider] = useDebouncedChangeHandler(percent, onPercentSelect)

    const deadline = useTransactionDeadline() // custom from users settings
    const allowedSlippage = useUserSlippageToleranceWithDefault(DEFAULT_REMOVE_V3_LIQUIDITY_SLIPPAGE_TOLERANCE) // custom from users

    const [showConfirm, setShowConfirm] = useState(false)
    const [attemptingTxn, setAttemptingTxn] = useState(false)
    const [txnHash, setTxnHash] = useState<string | undefined>()
    const addTransaction = useTransactionAdder()
    const positionManager = useV3NFTPositionManagerContract()
    const burn = useCallback(async () => {
        setAttemptingTxn(true)
        if (
            !positionManager ||
            !liquidityValue0 ||
            !liquidityValue1 ||
            !deadline ||
            !account ||
            !chainId ||
            !positionSDK ||
            !liquidityPercentage ||
            !provider
        ) {
            return
        }

        // we fall back to expecting 0 fees in case the fetch fails, which is safe in the
        // vast majority of cases
        const {calldata, value} = NonfungiblePositionManager.removeCallParameters(positionSDK, {
            tokenId: tokenId.toString(),
            liquidityPercentage,
            slippageTolerance: allowedSlippage,
            deadline: deadline.toString(),
            collectOptions: {
                expectedCurrencyOwed0: feeValue0 ?? CurrencyAmount.fromRawAmount(liquidityValue0.currency, 0),
                expectedCurrencyOwed1: feeValue1 ?? CurrencyAmount.fromRawAmount(liquidityValue1.currency, 0),
                recipient: account,
            },
        })
        const txn = {
            to: positionManager.address,
            data: calldata,
            value,
        }

        provider
            .getSigner()
            .estimateGas(txn)
            .then((estimate) => {
                const newTxn = {
                    ...txn,
                    gasLimit: calculateGasMargin(estimate),
                }

                return provider
                    .getSigner()
                    .sendTransaction(newTxn)
                    .then((response: TransactionResponse) => {
                        sendEvent({
                            category: 'Liquidity',
                            action: 'RemoveV3',
                            label: [liquidityValue0.currency.symbol, liquidityValue1.currency.symbol].join('/'),
                        })
                        setTxnHash(response.hash)
                        setAttemptingTxn(false)
                        addTransaction(response, {
                            type: TransactionType.REMOVE_LIQUIDITY_V3,
                            baseCurrencyId: currencyId(liquidityValue0.currency),
                            quoteCurrencyId: currencyId(liquidityValue1.currency),
                            expectedAmountBaseRaw: liquidityValue0.quotient.toString(),
                            expectedAmountQuoteRaw: liquidityValue1.quotient.toString(),
                        })
                    })
            })
            .catch((error) => {
                setAttemptingTxn(false)
                console.error(error)
            })
    }, [
        positionManager,
        liquidityValue0,
        liquidityValue1,
        deadline,
        account,
        chainId,
        feeValue0,
        feeValue1,
        positionSDK,
        liquidityPercentage,
        provider,
        tokenId,
        allowedSlippage,
        addTransaction,
    ])

    const handleDismissConfirmation = useCallback(() => {
        setShowConfirm(false)
        // if there was a tx hash, we want to clear the input
        if (txnHash) {
            onPercentSelectForSlider(0)
        }
        setAttemptingTxn(false)
        setTxnHash('')
    }, [onPercentSelectForSlider, txnHash])

    const pendingText = (
        <Trans>
            Removing {liquidityValue0?.toSignificant(6)} {liquidityValue0?.currency?.symbol} and{' '}
            {liquidityValue1?.toSignificant(6)} {liquidityValue1?.currency?.symbol}
        </Trans>
    )

    function modalHeader() {
        return (
            <AutoColumn gap="sm" style={{padding: '16px'}}>
                <RowBetween align="flex-end">
                    <Text fontSize="16px">
                        <Trans>Pooled {liquidityValue0?.currency?.symbol}:</Trans>
                    </Text>
                    <RowFixed>
                        <Text fontSize="16px">
                            {liquidityValue0 && <FormattedCurrencyAmount currencyAmount={liquidityValue0}/>}
                        </Text>
                        <CurrencyLogo size="20px" style={{marginLeft: '8px'}} currency={liquidityValue0?.currency}/>
                    </RowFixed>
                </RowBetween>
                <RowBetween align="flex-end">
                    <Text fontSize="16px">
                        <Trans>Pooled {liquidityValue1?.currency?.symbol}:</Trans>
                    </Text>
                    <RowFixed>
                        <Text fontSize="16px">
                            {liquidityValue1 && <FormattedCurrencyAmount currencyAmount={liquidityValue1}/>}
                        </Text>
                        <CurrencyLogo size="20px" style={{marginLeft: '8px'}} currency={liquidityValue1?.currency}/>
                    </RowFixed>
                </RowBetween>
                {feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0) ? (
                    <>
                        <ThemedText.DeprecatedItalic
                            fontSize={12}
                            color={theme.deprecated_text2}
                            textAlign="left"
                            padding="8px 0 0 0"
                        >
                            <Trans>You will also collect fees earned from this position.</Trans>
                        </ThemedText.DeprecatedItalic>
                        <RowBetween>
                            <Text fontSize="16px">
                                <Trans>{feeValue0?.currency?.symbol} Fees Earned:</Trans>
                            </Text>
                            <RowFixed>
                                <Text fontSize="16px">
                                    {feeValue0 && <FormattedCurrencyAmount currencyAmount={feeValue0}/>}
                                </Text>
                                <CurrencyLogo size="20px" style={{marginLeft: '8px'}} currency={feeValue0?.currency}/>
                            </RowFixed>
                        </RowBetween>
                        <RowBetween>
                            <Text fontSize="16px">
                                <Trans>{feeValue1?.currency?.symbol} Fees Earned:</Trans>
                            </Text>
                            <RowFixed>
                                <Text fontSize="16px">
                                    {feeValue1 && <FormattedCurrencyAmount currencyAmount={feeValue1}/>}
                                </Text>
                                <CurrencyLogo size="20px" style={{marginLeft: '8px'}} currency={feeValue1?.currency}/>
                            </RowFixed>
                        </RowBetween>
                    </>
                ) : null}
                <ButtonPrimary mt="16px" onClick={burn}>
                    <Trans>Remove</Trans>
                </ButtonPrimary>
            </AutoColumn>
        )
    }

    const showCollectAsWeth = Boolean(
        liquidityValue0?.currency &&
        liquidityValue1?.currency &&
        (liquidityValue0.currency.isNative ||
            liquidityValue1.currency.isNative ||
            WRAPPED_NATIVE_CURRENCY[liquidityValue0.currency.chainId]?.equals(liquidityValue0.currency.wrapped) ||
            WRAPPED_NATIVE_CURRENCY[liquidityValue1.currency.chainId]?.equals(liquidityValue1.currency.wrapped))
    )

    const [activePercent, setActivePercent] = useState(0)

    useEffect(() => {
        setActivePercent(percentForSlider)
    }, [percentForSlider])

    return (
        <div className="remove-container">
            {/*<div className="tab-pool-selector">*/}
            {/*    <NotchedButtonBolderNew cursor={`pointer`}>*/}
            {/*        <div*/}
            {/*            className={`wrapper-display-style tab-pool-selector-text`}*/}
            {/*            onClick={() => {*/}
            {/*                navigate('/add')*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            <div className={`wrapper-tab-pool-selector-text`}>*/}
            {/*                <div style={{width: "24px", height: "24px"}}>*/}
            {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"*/}
            {/*                         viewBox="0 0 24 24"*/}
            {/*                         fill="none">*/}
            {/*                        <g clipPath="url(#clip0_160_1026)">*/}
            {/*                            <path*/}
            {/*                                d="M6.80005 11C6.27416 11.9122 5.99756 12.9467 5.99805 13.9996C5.99853 15.0525 6.27608 16.0867 6.80282 16.9984C7.32955 17.9101 8.08692 18.6671 8.99885 19.1934C9.91077 19.7197 10.9451 19.9968 11.998 19.9968C13.051 19.9968 14.0853 19.7197 14.9972 19.1934C15.9092 18.6671 16.6665 17.9101 17.1933 16.9984C17.72 16.0867 17.9976 15.0525 17.998 13.9996C17.9985 12.9467 17.7219 11.9122 17.196 11L11.999 3L6.79905 11H6.80005Z"*/}
            {/*                                stroke="#A59185" strokeWidth="2" strokeLinecap="round"*/}
            {/*                                strokeLinejoin="round"/>*/}
            {/*                        </g>*/}
            {/*                        <defs>*/}
            {/*                            <clipPath id="clip0_160_1026">*/}
            {/*                                <rect width="24" height="24" fill="white"/>*/}
            {/*                            </clipPath>*/}
            {/*                        </defs>*/}
            {/*                    </svg>*/}
            {/*                </div>*/}
            {/*                <p>Add Liquidity</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </NotchedButtonBolderNew>*/}
            {/*    <NotchedButtonBolderNew cursor={`pointer`}>*/}
            {/*        <div*/}
            {/*            className={`wrapper-display-style tab-pool-selector-text ${tabSelector === 1 ? 'tab-pool-active wrapper-display-style-active' : ''}`}*/}
            {/*            onClick={() => {*/}
            {/*                navigate('/pool')*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            <div className={`wrapper-tab-pool-selector-text`}>*/}
            {/*                <div style={{width: "24px", height: "24px"}}>*/}
            {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"*/}
            {/*                         viewBox="0 0 24 24"*/}
            {/*                         fill="none">*/}
            {/*                        <g clipPath="url(#clip0_160_1029)">*/}
            {/*                            <path*/}
            {/*                                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"*/}
            {/*                                stroke="#4B2828" strokeWidth="2" strokeLinecap="round"*/}
            {/*                                strokeLinejoin="round"/>*/}
            {/*                            <path*/}
            {/*                                d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"*/}
            {/*                                stroke="#4B2828" strokeWidth="2" strokeLinecap="round"*/}
            {/*                                strokeLinejoin="round"/>*/}
            {/*                            <path d="M12 2V4" stroke="#4B2828" strokeWidth="2"*/}
            {/*                                  strokeLinecap="round"*/}
            {/*                                  strokeLinejoin="round"/>*/}
            {/*                            <path d="M12 20V22" stroke="#4B2828" strokeWidth="2"*/}
            {/*                                  strokeLinecap="round"*/}
            {/*                                  strokeLinejoin="round"/>*/}
            {/*                            <path d="M20 12H22" stroke="#4B2828" strokeWidth="2"*/}
            {/*                                  strokeLinecap="round"*/}
            {/*                                  strokeLinejoin="round"/>*/}
            {/*                            <path d="M2 12H4" stroke="#4B2828" strokeWidth="2"*/}
            {/*                                  strokeLinecap="round"*/}
            {/*                                  strokeLinejoin="round"/>*/}
            {/*                        </g>*/}
            {/*                        <defs>*/}
            {/*                            <clipPath id="clip0_160_1029">*/}
            {/*                                <rect width="24" height="24" fill="white"/>*/}
            {/*                            </clipPath>*/}
            {/*                        </defs>*/}
            {/*                    </svg>*/}
            {/*                </div>*/}
            {/*                <p>Positions</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </NotchedButtonBolderNew>*/}
            {/*</div>*/}
            <div className="swap-top-right">
                <SettingsTab placeholderSlippage={DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE}/>
            </div>
            <div className="remove-card">
                <TransactionConfirmationModal
                    isOpen={showConfirm}
                    onDismiss={handleDismissConfirmation}
                    attemptingTxn={attemptingTxn}
                    hash={txnHash ?? ''}
                    content={() => (
                        <ConfirmationModalContent
                            title={<Trans>Remove Liquidity</Trans>}
                            onDismiss={handleDismissConfirmation}
                            topContent={modalHeader}
                        />
                    )}
                    pendingText={pendingText}
                />

                <div>
                    {/*<AddRemoveTabs*/}
                    {/*    creating={false}*/}
                    {/*    adding={false}*/}
                    {/*    positionID={tokenId.toString()}*/}
                    {/*    defaultSlippage={DEFAULT_REMOVE_V3_LIQUIDITY_SLIPPAGE_TOLERANCE}*/}
                    {/*/>*/}
                    <div className="remove-card-info">
                        {position ? (
                            <AutoColumn gap="lg">
                                <RowBetween>
                                    <RowFixed>
                                        <DoubleCurrencyLogo
                                            currency0={feeValue0?.currency}
                                            currency1={feeValue1?.currency}
                                            size={20}
                                        />
                                        <div
                                            style={{
                                                marginLeft: '10px',
                                                fontSize: '20px',
                                                color: '#4B2828',
                                            }}
                                        >{`${feeValue0?.currency?.symbol}/${feeValue1?.currency?.symbol}`}</div>
                                    </RowFixed>
                                    <RangeBadge removed={removed} inRange={!outOfRange}/>
                                </RowBetween>
                                <LightCard>
                                    <AutoColumn gap="md">
                                        <div
                                            style={{
                                                fontSize: '20px',
                                                color: '#4B2828',
                                            }}
                                        >
                                            <Trans>Amount</Trans>
                                        </div>
                                        <RowBetween>
                                            <ResponsiveHeaderText>
                                                <Trans>{percentForSlider}%</Trans>
                                            </ResponsiveHeaderText>
                                            {/*<AutoRow gap="4px" justify="flex-end">*/}
                                            {/*    <SmallMaxButton onClick={() => onPercentSelect(25)} width="20%">*/}
                                            {/*        <Trans>*/}
                                            {/*            <span>25%</span>*/}
                                            {/*        </Trans>*/}
                                            {/*    </SmallMaxButton>*/}
                                            {/*    <SmallMaxButton onClick={() => onPercentSelect(50)} width="20%">*/}
                                            {/*        <Trans>*/}
                                            {/*            {' '}*/}
                                            {/*            <span>50%</span>*/}
                                            {/*        </Trans>*/}
                                            {/*    </SmallMaxButton>*/}
                                            {/*    <SmallMaxButton onClick={() => onPercentSelect(75)} width="20%">*/}
                                            {/*        <Trans>*/}
                                            {/*            {' '}*/}
                                            {/*            <span>75%</span>*/}
                                            {/*        </Trans>*/}
                                            {/*    </SmallMaxButton>*/}
                                            {/*    <SmallMaxButton onClick={() => onPercentSelect(100)} width="20%">*/}
                                            {/*        <Trans>*/}
                                            {/*            <span>MAX</span>*/}
                                            {/*        </Trans>*/}
                                            {/*    </SmallMaxButton>*/}
                                            {/*</AutoRow>*/}
                                        </RowBetween>
                                        <Slider value={percentForSlider} onChange={onPercentSelectForSlider}/>
                                        <ButtonSelectPercentWrapper active={activePercent}>
                                            <div className='img-wrapper' onClick={() => onPercentSelect(25)}>
                                                <img className="percent-img percent-25"
                                                     src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/remove-liquidity-v3/img.png" alt="img"/>
                                            </div>
                                            <div className='img-wrapper' onClick={() => onPercentSelect(50)}>
                                                <img className="percent-img percent-50"
                                                     src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/remove-liquidity-v3/img_1.png" alt="img_1"/>
                                            </div>
                                            <div className='img-wrapper' onClick={() => onPercentSelect(75)}>
                                                <img className="percent-img percent-75"
                                                     src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/remove-liquidity-v3/img_2.png" alt="img_2"/>
                                            </div>
                                            <div className='img-wrapper' onClick={() => onPercentSelect(100)}>
                                                <img className="percent-img percent-100"
                                                     src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/remove-liquidity-v3/img_3.png" alt="img_3"/>
                                            </div>
                                        </ButtonSelectPercentWrapper>
                                    </AutoColumn>
                                </LightCard>
                                <LightCard>
                                    <AutoColumn gap="md">
                                        <RowBetween>
                                            <Text fontSize="16px">
                                                <Trans>Pooled {liquidityValue0?.currency?.symbol}:</Trans>
                                            </Text>
                                            <RowFixed>
                                                <Text fontSize="16px">
                                                    {liquidityValue0 &&
                                                        <FormattedCurrencyAmount currencyAmount={liquidityValue0}/>}
                                                </Text>
                                                <CurrencyLogo size="20px" style={{marginLeft: '8px'}}
                                                              currency={liquidityValue0?.currency}/>
                                            </RowFixed>
                                        </RowBetween>
                                        <RowBetween>
                                            <Text fontSize="16px">
                                                <Trans>Pooled {liquidityValue1?.currency?.symbol}:</Trans>
                                            </Text>
                                            <RowFixed>
                                                <Text fontSize="16px">
                                                    {liquidityValue1 &&
                                                        <FormattedCurrencyAmount currencyAmount={liquidityValue1}/>}
                                                </Text>
                                                <CurrencyLogo size="20px" style={{marginLeft: '8px'}}
                                                              currency={liquidityValue1?.currency}/>
                                            </RowFixed>
                                        </RowBetween>
                                        {feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0) ? (
                                            <>
                                                <Break/>
                                                <RowBetween>
                                                    <Text fontSize="16px">
                                                        <Trans>{feeValue0?.currency?.symbol} Fees Earned:</Trans>
                                                    </Text>
                                                    <RowFixed>
                                                        <Text fontSize="16px">
                                                            {feeValue0 &&
                                                                <FormattedCurrencyAmount currencyAmount={feeValue0}/>}
                                                        </Text>
                                                        <CurrencyLogo size="20px" style={{marginLeft: '8px'}}
                                                                      currency={feeValue0?.currency}/>
                                                    </RowFixed>
                                                </RowBetween>
                                                <RowBetween>
                                                    <Text fontSize="16px">
                                                        <Trans>{feeValue1?.currency?.symbol} Fees Earned:</Trans>
                                                    </Text>
                                                    <RowFixed>
                                                        <Text fontSize="16px">
                                                            {feeValue1 &&
                                                                <FormattedCurrencyAmount currencyAmount={feeValue1}/>}
                                                        </Text>
                                                        <CurrencyLogo size="20px" style={{marginLeft: '8px'}}
                                                                      currency={feeValue1?.currency}/>
                                                    </RowFixed>
                                                </RowBetween>
                                            </>
                                        ) : null}
                                    </AutoColumn>
                                </LightCard>

                                {showCollectAsWeth && (
                                    <RowBetween>
                                        <ResponsiveText>
                                            <Trans>Collect as {nativeWrappedSymbol}</Trans>
                                        </ResponsiveText>

                                        <Toggle
                                            id="receive-as-weth"
                                            isActive={receiveWETH}
                                            toggle={() => setReceiveWETH((receiveWETH) => !receiveWETH)}
                                        />
                                    </RowBetween>
                                )}

                                <div style={{display: 'flex'}}>
                                    <AutoColumn gap="md" style={{flex: '1'}}>
                                        <ButtonConfirmed
                                            confirmed={false}
                                            disabled={removed || percent === 0 || !liquidityValue0}
                                            onClick={() => setShowConfirm(true)}
                                        >
                                            {removed ? <Trans>Closed</Trans> : error ?? <Trans>Remove</Trans>}
                                        </ButtonConfirmed>
                                    </AutoColumn>
                                </div>
                            </AutoColumn>
                        ) : (
                            <Loader/>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
