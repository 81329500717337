import { GMEOW_API_BASE_URL } from 'utils/env'
import create from 'zustand'
import { devtools } from 'zustand/middleware'


export type TaskType = {
    taskId: number
    hasClaimed: boolean
    taskStatus: boolean // for check btn
    type: "DAILY_CHECK_IN" | "FOLLOW" | "RE_TWEET" | "SWAP" | "ADD_LIQUIDITY" | "ADD_NFT"
    reward: number
}

type LeaderboardType = {
    rank: number | string,
    address: string,
    referralXp: number,
    totalClaimedXp: number
}
type DailyTaskStoreState = {
    totalTokenClaimed: number
    totalRefToken: number
    invitedFriends: {
        address: string
        earn: number
    }[]
    refCode: string
    hasConnectedX: boolean
    hasEnteredRefcode: boolean
    enteredRefcode?: string

    dailyCheckInTask?: TaskType
    followTask?: TaskType
    followTask1?: TaskType
    followTask2?: TaskType
    reTweetTask?: TaskType
    reTweetTask1?: TaskType
    reTweetTask2?: TaskType
    reTweetTask3?: TaskType
    reTweetTask4?: TaskType
    addNftTask?: TaskType
    swapTask?: TaskType
    addLiquidityTask?: TaskType
    addFarmTask?: TaskType
    refTask?: TaskType
    leaderboardData: LeaderboardType[]
    commission?: {
        1: number
        2: number
        3: number
    },
    invitedCnt: number

    initState: (address: string) => void
    loadLeaderboard: (address?: string) => void
    resetState: () => void
    isLoadingInitState: boolean
    isLoadingLeaderboard: boolean
}

const DEFDAULT_INIT_STATE = {
    totalTokenClaimed: 0,
    totalRefToken: 0,
    invitedFriends: [],
    refCode: '',
    hasConnectedX: false,
    hasEnteredRefcode: false,
    isLoadingInitState: false,
    isLoadingLeaderboard: false,
    leaderboardData: [],

    dailyCheckInTask: undefined,
    followTask: undefined,
    followTask1: undefined,
    followTask2: undefined,
    reTweetTask: undefined,
    reTweetTask1: undefined,
    reTweetTask2: undefined,
    reTweetTask3: undefined,
    reTweetTask4: undefined,
    addNftTask: undefined,
    addLiquidityTask: undefined,
    commission: undefined,
    invitedCnt: 0
}
export const useDailyTaskStore = create<DailyTaskStoreState>()(
    devtools((set, get) => ({
        ...DEFDAULT_INIT_STATE,
        initState: (address: string) => {
            set({ isLoadingInitState: true })
            fetch(`${GMEOW_API_BASE_URL}/airdrop?address=${address}`)
                .then(res => res.json())
                .then(data => {
                    set({
                        totalTokenClaimed: data.totalTokenClaimed,
                        totalRefToken: data.totalRefToken,
                        invitedFriends: data.invitedFriends,
                        refCode: data.refCode,
                        hasConnectedX: data.hasConnectedX,
                        hasEnteredRefcode: data.hasEnteredRefcode,
                        enteredRefcode: data.enteredRefcode,
                        dailyCheckInTask: data.dailyCheckInTask,
                        followTask: data.followTask,
                        reTweetTask: data.reTweetTask,
                        commission: data.commission,
                        invitedCnt: data.invitedCnt,
                    })

                })
                .finally(() => set({ isLoadingInitState: false }))
        },
        loadLeaderboard: (address?: string) => {
            set({ isLoadingLeaderboard: true })
            fetch(`${GMEOW_API_BASE_URL}/airdrop/leaderboard${address ? '?address=' + address.toLowerCase() : ''}`)
                .then(res => res.json())
                .then(data => {
                    set({
                        leaderboardData: data
                    })
                })
                .finally(() => set({ isLoadingLeaderboard: false }))
        },
        resetState: () => {
            set({
                ...DEFDAULT_INIT_STATE
            })
        }
    })))