import styled from 'styled-components/macro'
import { BREAKPOINTS } from 'theme'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from "react";
import TextScramble from "./components/TextScarmble";
import { NotchedButtonFill } from 'components/Button';

const WrapperSection1 = styled.section`
    background: #fff9e1;
    width: 100%;
    height: 100%;
    margin: 0px auto;
    position: relative;
`

const WrapperSvg = styled.div`
    width: 100%;
    height: 966px;
    clip-path: polygon(0 9%, 100% 9%, 100% 100%, 0% 100%);
    margin-top: -5%;

    img{
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    img.desktop_bg {
        display: block;
    }

    img.desktop_xl_bg {
        display: none;
    }

    img.tablet_bg {
        display: none;
    }

    @media screen and (max-width: 1500px) {
        height: 800px;
        img.desktop_bg {
            display: none;
        }
        img.desktop_xl_bg {
            display: block;
        }
    }
    @media screen and (max-width: 1350px) {
        img.desktop_bg {
            scale: 1.1;
        }
    }

    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        height: 900px;
        img.desktop_bg, img.desktop_xl_bg {
            display: none;
        }

        img.tablet_bg {
            display: block;
        }
    }

    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        display: none;
    }

`
const WrapperSvgMobile = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    position: relative;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        display: block;
        min-height: 750px;
        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .gmeo-icon-ear{
            max-width: 85%;
        }
    }
    .btn-wrapper{    
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        bottom: 21.7%;
        &>div{
            min-width: 80%;
        }
        @media screen and (max-width: 390px) {
            bottom: 21.5%;
            &>div{
                min-width: 335px;
            }
        }
         
    }
    @media screen and (max-width: 390px){
        min-height: 691px; 
    }
`

const WrapperContent = styled.div`
    position: absolute;
    top: 0px;
    width: 100%;
`

const Content = styled.div`
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
        color: #FFF;
        text-align: center;
        font-family: DynaPuff;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 50px */
        text-transform: uppercase;

        margin: 0;
        text-align: center;
        width: 411px;

        span {
            color: #FFB704;
            text-align: center;
            -webkit-text-stroke-width: 3px;
            -webkit-text-stroke-color: #723507;
            font-family: DynaPuff;
            font-weight: 900;
        } 
        @media screen and (max-width: ${BREAKPOINTS.lg}px) {
            font-size: 24px;
        }

        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
            font-size: 32px;
        }
    }

    p {
        color: #FFF;
        text-align: center;
        font-family: Outfit;
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 21px */
    }

    @media screen and (max-width: 1500px) {
        font-size: 32px;
        padding-top: 120px;
        h2{
            font-size: 34px;
        }
        p {
            font-size: 18px !important;
            padding-top: 10px !important;
        }
    }

    @media screen and (max-width: 1300px) {
        font-size: 28px;
        padding-top: 120px;
        h2{
            font-size: 34px;
        }
        p {
            font-size: 16px !important;
            padding-top: 10px !important;
        }
    }
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        padding-top: 100px;
        h2{
            font-size: 32px;
        }
        p {
            font-size: 16px !important;
            padding-top: 10px !important;
        }
    }

    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        padding-top: 120px;
    }
   
    @media screen and (max-width: 500px) {
        padding-top: 60px;
        p {
            font-size: 16px !important;
            padding-top: 12px !important;
        }
    }
`
const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 9.1%;
    width: 100%;
    padding-left: 8px;
    .btn-wrapper{
        min-width: 772px;
        max-width: 80%;
        &>div{
            height: 120px;
            .filled-btn{
                height: 120px;
                span{
                    font-size: 36px !important;
                }
            }
            .gmeo-icon-ear-wrapper{
                top: -30px;
            }
            svg{
                width: 97.262px;
                height: 91.183px;
            }
        }
    }
    .gmeo-icon-ear{
        max-width: 82%;
    }
    
    @media screen and (max-width: 1500px){
        bottom: 6%;
    }
    @media screen and (max-width: 1350px){
        bottom: 2.8%;
    }
    @media screen and (max-width: ${BREAKPOINTS.lg}px){
        bottom: 280px;
        padding-left: 16.5px;
        .btn-wrapper{
            min-width: 500px;
            max-width: 500px; 
            &>div{
                height: 45px;
                .filled-btn{
                    height: 100%;
                    span{
                        font-size: 21px !important;
                    }
                }
                .gmeo-icon-ear-wrapper{
                    top: -10px;
                }
                svg{
                    width: 24px;
                    height: 24px;
                }
            }
        }
        .gmeo-icon-ear{
            max-width: 80%;
        }
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        display: none;
    }
`
//animation
// @ts-ignore


export default function Section0() {
    const navigate = useNavigate()
    const phrases = [
        'YOUR GATEWAY TO A <br/> <span> MEOW-NIFICENT </span> <br/> CRYPTO EXPERIENCE!'
    ];

    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index == phrases.length - 1) {
            return;
        }

        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        }, 1500);

        return () => clearInterval(interval);
    }, [index, phrases]);
    return (
        <>
            <WrapperSection1>
                <WrapperSvg>
                    <img className='desktop_bg' width={'100%'} height={'100%'} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section0/bg_img.png"
                        alt="bg_img" />
                    <img className='desktop_xl_bg' width={'100%'} height={'100%'} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section0/bg_img_xl.png"
                        alt="bg_img" />
                    <img className='tablet_bg' width={'100%'} height={'100%'} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section0/tablet-bg.png"
                        alt="bg_img" />
                </WrapperSvg>
                <WrapperSvgMobile>
                    <img width={'100%'} height={'100%'} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section0/bg_img_xs.png" alt="bg_img" />
                    <div className='btn-wrapper'>
                        <NotchedButtonFill onClick={() => navigate('/swap')} isShowDirectly={true}>
                            <span>MEOW NOW</span>
                        </NotchedButtonFill>
                    </div>
                </WrapperSvgMobile>
                <WrapperContent>
                    <Content>
                        <TextScramble text={phrases[index]} />
                        <p style={{ margin: 0, paddingTop: '24px', textAlign: 'center' }}>Trade, earn, and grow with
                            Gmeow, the <br /> ultimate
                            DeFi platform for feline
                            aficionados <br /> and savvy
                            investors alike!</p>
                    </Content>
                </WrapperContent>

                <ButtonWrapper>
                    <div className='btn-wrapper'>
                        <NotchedButtonFill onClick={() => navigate('/swap')} isShowDirectly={true}>
                            <span>MEOW NOW</span>
                        </NotchedButtonFill>
                    </div>
                </ButtonWrapper>

            </WrapperSection1>
        </>
    )
}
