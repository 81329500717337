import { createSlice } from "@reduxjs/toolkit"


export interface AlphapoolState {
  tvlMap: Map<string, number>, 
}
const initialState: AlphapoolState = {
  tvlMap: new Map(),
}

const alphapoolSlice = createSlice({
  name: 'alphapool',
  initialState,
  reducers: {
    updateTvlMap(state, action) {
      state.tvlMap = action.payload
    },
  },
})

export const { updateTvlMap } = alphapoolSlice.actions
export default alphapoolSlice.reducer