import { Box } from 'rebass/styled-components'
import styled from 'styled-components/macro'
import { BREAKPOINTS } from 'theme'

const Row = styled(Box) <{
    width?: string
    align?: string
    justify?: string
    padding?: string
    border?: string
    borderRadius?: string
}>`
    width: ${({ width }) => width ?? '100%'};
    display: flex;
    padding: 0;
    align-items: ${({ align }) => align ?? 'center'};
    justify-content: ${({ justify }) => justify ?? 'flex-start'};
    padding: ${({ padding }) => padding};
    border: ${({ border }) => border};
`

export const RowBetween = styled(Row)`
    justify-content: space-between;
    gap: 16px;
    .fiat_value{
        color: #E5B670;
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 18px */
        @media only screen and (max-width: 640px) {
            font-size: 15px;
        }
    }
`

export const RowFlat = styled.div`
    display: flex;
    align-items: flex-end;
`

export const AutoRow = styled(Row) <{ gap?: string; justify?: string }>`
    flex-wrap: wrap;
    margin: ${({ gap }) => gap && `-${gap}`};
    justify-content: ${({ justify }) => justify && justify};

    & > * {
        margin: ${({ gap }) => gap} !important;
    }
`

export const RowFixed = styled(Row) <{ gap?: string; justify?: string; align?: string }>`
    width: fit-content;
    gap: ${({ gap }) => gap};
`

export default Row
