/**
 * List of all the networks supported by the Uniswap Interface
 */
export enum SupportedChainId {
  MAINNET = 1,
  ZIRCUIT_TESTNET = 48899,
  ZIRCUIT = 48900,
}

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.MAINNET]: 'mainnet',
  [SupportedChainId.ZIRCUIT_TESTNET]: 'zircuit-testnet',
  [SupportedChainId.ZIRCUIT]: 'zircuit',
}

const NOT_YET_UX_SUPPORTED_CHAIN_IDS: number[] = [
  SupportedChainId.MAINNET,
]

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
  (id) => typeof id === 'number' && id !== 1
) as SupportedChainId[]

export function isSupportedChain(chainId: number | null | undefined): chainId is SupportedChainId {
  return !!chainId && !!SupportedChainId[chainId] && NOT_YET_UX_SUPPORTED_CHAIN_IDS.indexOf(chainId) === -1
}

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [
  SupportedChainId.MAINNET,
  SupportedChainId.ZIRCUIT_TESTNET,
  SupportedChainId.ZIRCUIT,
]

/**
 * Unsupported networks for V2 pool behavior.
 */
export const UNSUPPORTED_V2POOL_CHAIN_IDS = [
  696969, // fake
]

export const TESTNET_CHAIN_IDS = [
  SupportedChainId.ZIRCUIT_TESTNET
] as const

export type SupportedTestnetChainId = typeof TESTNET_CHAIN_IDS[number]

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [
  SupportedChainId.MAINNET,
] as const

export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number]

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS = [
  SupportedChainId.ZIRCUIT_TESTNET,
  SupportedChainId.ZIRCUIT
] as const

export type SupportedL2ChainId = typeof L2_CHAIN_IDS[number]

export const DEFAULT_CHAINID = SupportedChainId.ZIRCUIT