import { Trans } from '@lingui/macro'
import { FeeAmount } from '@uniswap/v3-sdk'
import { ButtonGray } from 'components/Button'
import { OutlineCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { Minus, Plus } from 'react-feather'
import styled, { keyframes } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { Input as NumericalInput } from '../NumericalInput'

const pulse = (color: string) => keyframes`
    0% {
        box-shadow: 0 0 0 0 ${color};
    }

    70% {
        box-shadow: 0 0 0 2px ${color};
    }

    100% {
        box-shadow: 0 0 0 0 ${color};
    }
`

const InputRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 10px;
    border: 2px solid #E5B670;
    background: #FFF6D0;
    box-shadow: 4px 4px 4px 0px rgba(215, 144, 60, 0.80) inset, -4px -4px 4px 0px #FFF inset;
    padding: 14px 24px;
`

const InputRowInner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SmallButton = styled(ButtonGray)`
    padding: 0;
    width: fit-content;
`

const FocusedOutlineCard = styled(OutlineCard) <{ active?: boolean; pulsing?: boolean }>`
    border-color: rgba(239, 239, 228, 0.2);
    animation: ${({ pulsing, theme }) => pulsing && pulse(theme.deprecated_blue1)} 0.8s linear;
    padding: 0;
`

const StyledInput = styled(NumericalInput) <{ usePercent?: boolean }>`
    // background-color: transparent;
    text-align: left;
    font-weight: 500;
    padding: 0;
    color: #4B2828;
    font-size: 24px;
    font-family: Outfit;
    @media (max-width: 767px) {
        font-size: 16px;
    }
`

const InputTitle = styled(ThemedText.DeprecatedSmall)`
    color: #E5B670;
    text-align: right;
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 18px */

    span {
        color: #4B2828;
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
    }

    @media (max-width: 767px) {
        font-size: 14px;
        span{
            font-size: 14px;
        }
    }
`

const ButtonLabel = styled(ThemedText.DeprecatedWhite) <{ disabled: boolean }>`
    color: ${({ theme, disabled }) => (disabled ? '#4B2828' : 'rgba(239, 239, 228, 0.5)')} !important;
`

interface StepCounterProps {
    value: string
    onUserInput: (value: string) => void
    decrement: () => string
    increment: () => string
    decrementDisabled?: boolean
    incrementDisabled?: boolean
    feeAmount?: FeeAmount
    label?: string
    width?: string
    locked?: boolean // disable input
    title: ReactNode
    tokenA: string | undefined
    tokenB: string | undefined
}

const StepCounter = ({
    value,
    decrement,
    increment,
    decrementDisabled = false,
    incrementDisabled = false,
    width,
    locked,
    onUserInput,
    title,
    tokenA,
    tokenB,
}: StepCounterProps) => {
    //  for focus state, styled components doesnt let you select input parent container
    const [active, setActive] = useState(false)

    // let user type value and only update parent value on blur
    const [localValue, setLocalValue] = useState('')
    const [useLocalValue, setUseLocalValue] = useState(false)

    // animation if parent value updates local value
    const [pulsing, setPulsing] = useState<boolean>(false)

    const handleOnFocus = () => {
        setUseLocalValue(true)
        setActive(true)
    }

    const handleOnBlur = useCallback(() => {
        setUseLocalValue(false)
        setActive(false)
        onUserInput(localValue) // trigger update on parent value
    }, [localValue, onUserInput])

    // for button clicks
    const handleDecrement = useCallback(() => {
        setUseLocalValue(false)
        onUserInput(decrement())
    }, [decrement, onUserInput])

    const handleIncrement = useCallback(() => {
        setUseLocalValue(false)
        onUserInput(increment())
    }, [increment, onUserInput])

    useEffect(() => {
        if (localValue !== value && !useLocalValue) {
            setTimeout(() => {
                setLocalValue(value) // reset local value to match parent
                setPulsing(true) // trigger animation
                setTimeout(function () {
                    setPulsing(false)
                }, 1800)
            }, 0)
        }
    }, [localValue, useLocalValue, value])

    return (
        <FocusedOutlineCard pulsing={pulsing} active={active} onFocus={handleOnFocus} onBlur={handleOnBlur}
            width={width}>
            <AutoColumn gap="6px">
                <InputTitle fontSize={21} textAlign="start" fontFamily={"Outfit"} fontWeight={"500"}>
                    <span>{title}</span>
                </InputTitle>

                <InputRow>

                    <InputRowInner>
                        <InputTitle fontSize={"18px"} textAlign={"start"}>
                            <Trans>
                                {tokenB} per {tokenA}
                            </Trans>
                        </InputTitle>
                        {!locked && (
                            <SmallButton onClick={handleIncrement} disabled={incrementDisabled}>
                                <ButtonLabel disabled={incrementDisabled} fontSize="12px">
                                    {/*<Plus size={18}/>*/}
                                    <img width={"28px"} height={"28px"} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/add-liquidity/img_7.png" alt="img_7" />
                                </ButtonLabel>
                            </SmallButton>
                        )}
                    </InputRowInner>
                    <InputRowInner>
                        <StyledInput
                            className="rate-input-0"
                            value={localValue}
                            fontSize="20px"
                            disabled={locked}
                            color="#4B2828"
                            onUserInput={(val) => {
                                setLocalValue(val)
                            }}
                        />

                        {!locked && (
                            <SmallButton onClick={handleDecrement} disabled={decrementDisabled}>
                                <ButtonLabel disabled={decrementDisabled} fontSize="12px">
                                    <img width={"28px"} height={"28px"} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/add-liquidity/img_8.png" alt="img_8" />
                                </ButtonLabel>
                            </SmallButton>
                        )}
                    </InputRowInner>
                </InputRow>


            </AutoColumn>
        </FocusedOutlineCard>
    )
}

export default StepCounter
