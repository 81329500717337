import { Trace, user } from '@uniswap/analytics'
import { CustomUserProperties, getBrowser, PageName } from '@uniswap/analytics-events'
import Loader from 'components/Loader'
import TopLevelModals from 'components/TopLevelModals'
import { useFeatureFlagsIsLoaded } from 'featureFlags'
import { useLandingPageFlag } from 'featureFlags/flags/landingPage'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import { useBag } from 'nft/hooks'
import { Suspense, useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { useIsDarkMode } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { SpinnerSVG } from 'theme/components'
import { flexRowNoWrap } from 'theme/styles'
// import { isProductionEnv } from 'utils/env'
// import { getCLS, getFCP, getFID, getLCP, Metric } from 'web-vitals'

import { useAnalyticsReporter } from '../components/analytics'
import ErrorBoundary from '../components/ErrorBoundary'
import NavBar from '../components/NavBar'
import Polling from '../components/Polling'
import Popups from '../components/Popups'
import { useIsExpertMode } from '../state/user/hooks'
import DarkModeQueryParamReader from '../theme/components/DarkModeQueryParamReader'
// import About from './About'
// import AddLiquidity from './AddLiquidity'
// import { RedirectDuplicateTokenIds } from './AddLiquidity/redirects'
// import { RedirectDuplicateTokenIdsV2 } from './AddLiquidityV2/redirects'
// import Earn from './Earn'
// import Manage from './Earn/Manage'
import Landing from './Landing'
// import MigrateV2 from './MigrateV2'
// import MigrateV2Pair from './MigrateV2/MigrateV2Pair'
// import Pool from './Pool'
// import { PositionPage } from './Pool/PositionPage'
import PoolV2 from './Pool/v2'
// import PoolFinder from './PoolFinder'
// import RemoveLiquidity from './RemoveLiquidity'
// import RemoveLiquidityV3 from './RemoveLiquidity/V3'
import Swap from './Swap'
import { RedirectPathToHome, RedirectPathToSwapOnly } from './Swap/redirects'
// import Tokens from './Tokens'
import Footer from 'components/Footer'
import PoolsInfo from './PoolsInfo'
import PoolFinder from './PoolFinder'
import { PositionPage } from './Pool/PositionPage'
import Pool from './Pool'
import { RedirectDuplicateTokenIdsV2 } from './AddLiquidityV2/redirects'
import { RedirectDuplicateTokenIds } from './AddLiquidity/redirects'
import AddLiquidity from './AddLiquidity'
import RemoveLiquidity from './RemoveLiquidity'
import RemoveLiquidityV3 from './RemoveLiquidity/V3'
import MigrateV2 from './MigrateV2'
import MigrateV2Pair from './MigrateV2/MigrateV2Pair'
import MeowMint from "./MonoPass/testnest";
import { useWeb3React } from '@web3-react/core'
import BridgePage from './Bridge/BridgePage'
import { useDailyTaskStore } from 'state/dailyTask/useDailyTaskStore'
import TreatPage from './Treat'
import FarmingV3Page from './Alpha'
import DetailFarmingPage from './Alpha/AlphaDetail'
import MusdPage from './Musd'
import MonoChestPage from './MonoChest/ChestPage'
import { useAlphaTvlMap } from 'state/alphapool/hooks'
import { GMEOW_API_BASE_URL } from 'utils/env'
import { updateTvlMap } from 'state/alphapool/reducer'
import { useAppDispatch } from 'state/hooks'
import MonoPass from './MonoPass'
import { useAlphaStore } from 'hooks/useAlphaStore'
// import { BREAKPOINTS } from 'theme'
// import MusdPage from './Musd/index'
// import PoolsInfo from './PoolsInfo'

// const TokenDetails = lazy(() => import('./TokenDetails'))
// const Vote = lazy(() => import('./Vote'))
// const NftExplore = lazy(() => import('nft/pages/explore'))
// const Collection = lazy(() => import('nft/pages/collection'))
// const Profile = lazy(() => import('nft/pages/profile/profile'))
// const Asset = lazy(() => import('nft/pages/asset/Asset'))

// Placeholder API key. Actual API key used in the proxy server
// const ANALYTICS_DUMMY_KEY = '00000000000000000000000000000000'
// const ANALYTICS_PROXY_URL = process.env.REACT_APP_AMPLITUDE_PROXY_URL
// const COMMIT_HASH = process.env.REACT_APP_GIT_COMMIT_HASH
// initializeAnalytics(ANALYTICS_DUMMY_KEY, OriginApplication.INTERFACE, {
//   // proxyUrl: ANALYTICS_PROXY_URL,
//   defaultEventName: EventName.PAGE_VIEWED,
//   commitHash: COMMIT_HASH,
//   isProductionEnv: isProductionEnv(),
// })

const AppWrapper = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    height: 100%;
    background: #FFF9E1;
    min-height: 100vh;
`

const BodyWrapper = styled.div`
    display: flex;
    background: #FFF9E1;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 72px 0px 0px 0px;
    align-items: center;
    flex: 1;
    ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    padding: 52px 0px 16px 0px;
  `};
`

const HeaderWrapper = styled.div<{ transparent?: boolean }>`
    ${flexRowNoWrap};
    background: #FFF9E1;
    width: 100%;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 9;
`

const FooterWrapper = styled.div`
    background: #FFF9E1;
    width: 100%;
    margin: auto auto 0px auto;
    height: 100%;
`

const Marginer = styled.div`
    margin-top: 5rem;
`

function getCurrentPageFromLocation(locationPathname: string): PageName | undefined {
    switch (true) {
        case locationPathname.startsWith('/swap'):
            return PageName.SWAP_PAGE
        // case locationPathname.startsWith('/vote'):
        //   return PageName.VOTE_PAGE
        case locationPathname.startsWith('/pool'):
            return PageName.POOL_PAGE
        case locationPathname.startsWith('/tokens'):
            return PageName.TOKENS_PAGE
        // case locationPathname.startsWith('/nfts/profile'):
        //   return PageName.NFT_PROFILE_PAGE
        // case locationPathname.startsWith('/nfts/asset'):
        //   return PageName.NFT_DETAILS_PAGE
        // case locationPathname.startsWith('/nfts/collection'):
        //   return PageName.NFT_COLLECTION_PAGE
        // case locationPathname.startsWith('/nfts'):
        //   return PageName.NFT_EXPLORE_PAGE
        default:
            return undefined
    }
}

// this is the same svg defined in assets/images/blue-loader.svg
// it is defined here because the remote asset may not have had time to load when this file is executing
const LazyLoadSpinner = () => (
    <SpinnerSVG width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M92 47C92 22.1472 71.8528 2 47 2C22.1472 2 2 22.1472 2 47C2 71.8528 22.1472 92 47 92"
            stroke="#2172E5"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </SpinnerSVG>
)

export default function App() {
    const { account } = useWeb3React()
    const isLoaded = useFeatureFlagsIsLoaded()
    const dispatch = useAppDispatch()

    const alphaTvlMap = useAlphaTvlMap()
    const { pathname } = useLocation()
    const currentPage = getCurrentPageFromLocation(pathname)
    const isDarkMode = useIsDarkMode()
    const isExpertMode = useIsExpertMode()
    const [scrolledState, setScrolledState] = useState(false)

    useAnalyticsReporter()

    useEffect(() => {
        window.scrollTo(0, 0)
        setScrolledState(false)

        if (pathname.includes('farming'))
            document.querySelectorAll('.default_bg')
                .forEach(item => item.classList.add('farming_bg'))
        else document.querySelectorAll('.default_bg')
            .forEach(item => item.classList.remove('farming_bg'))
    }, [pathname])

    useEffect(() => {
        // sendAnalyticsEvent(EventName.APP_LOADED)
        user.set(CustomUserProperties.USER_AGENT, navigator.userAgent)
        user.set(CustomUserProperties.BROWSER, getBrowser())
        user.set(CustomUserProperties.SCREEN_RESOLUTION_HEIGHT, window.screen.height)
        user.set(CustomUserProperties.SCREEN_RESOLUTION_WIDTH, window.screen.width)
        // getCLS(({ delta }: Metric) => sendAnalyticsEvent(EventName.WEB_VITALS, { cumulative_layout_shift: delta }))
        // getFCP(({ delta }: Metric) => sendAnalyticsEvent(EventName.WEB_VITALS, { first_contentful_paint_ms: delta }))
        // getFID(({ delta }: Metric) => sendAnalyticsEvent(EventName.WEB_VITALS, { first_input_delay_ms: delta }))
        // getLCP(({ delta }: Metric) => sendAnalyticsEvent(EventName.WEB_VITALS, { largest_contentful_paint_ms: delta }))
    }, [])

    useEffect(() => {
        user.set(CustomUserProperties.DARK_MODE, isDarkMode)
    }, [isDarkMode])

    useEffect(() => {
        user.set(CustomUserProperties.EXPERT_MODE, isExpertMode)
    }, [isExpertMode])

    useEffect(() => {
        const scrollListener = () => {
            setScrolledState(window.scrollY > 0)
        }
        window.addEventListener('scroll', scrollListener)

        return () => window.removeEventListener('scroll', scrollListener)
    }, [])

    const isBagExpanded = useBag((state) => state.bagExpanded)

    const isHeaderTransparent = !scrolledState && !isBagExpanded

    const landingPageFlag = useLandingPageFlag()

    const dailyTaskStore = useDailyTaskStore()
    useEffect(() => {
        if (account) dailyTaskStore.initState(account)
        else dailyTaskStore.resetState()

        setTimeout(() => dailyTaskStore.loadLeaderboard(account), 1000)
    }, [account])


    const alphaStore = useAlphaStore()
    useEffect(() => {
        if (alphaTvlMap.size == 0) {
            fetch(`${GMEOW_API_BASE_URL}/pool-xp-multiplier/get-alphapool-tvl-list`)
                .then(res => res.json())
                .then((data: { [x: string]: number }) => {
                    const tvlMapRes = new Map<string, number>()
                    Object.keys(data).forEach(key => {
                        tvlMapRes.set(String(key), data[key])
                    })
                    dispatch(updateTvlMap(tvlMapRes))
                })
        }

        fetch(`${GMEOW_API_BASE_URL}/pool-xp-multiplier/get-token-prices`)
            .then(res => res.json())
            .then(data => {
                useAlphaStore.setState({
                    tokenPrices: data
                })
            })

        alphaStore.initV3Pools()
    }, [])

    useEffect(() => {
        if (alphaTvlMap.size > 0 && alphaStore.totalV3Pools.length > 0) {
            const poolInfos: any = {}
            alphaStore.totalV3Pools.forEach(farmingPool => {
                const poolTvl = alphaTvlMap.get(String(farmingPool.incentiveId).toLowerCase()) ?? 0
                const aprDay = poolTvl * 180
                const aprRes = (farmingPool.totalRewardUnclaimed * 0.001 * 365 * 100) / aprDay
                poolInfos[String(farmingPool.pool).toLowerCase()] = {
                    apr: aprRes,
                    tvl: poolTvl
                }
            })
            useAlphaStore.setState({
                poolInfos: poolInfos
            })
        }
    }, [alphaStore.totalV3Pools, alphaTvlMap])
    return (
        <ErrorBoundary>
            <DarkModeQueryParamReader />
            <ApeModeQueryParamReader />
            <AppWrapper className='default_bg'>
                <Trace page={currentPage}>
                    <HeaderWrapper transparent={isHeaderTransparent}>
                        <NavBar />
                    </HeaderWrapper>
                    <BodyWrapper className="site-body default_bg">
                        <Popups />
                        <Polling />
                        <TopLevelModals />
                        <Suspense fallback={<Loader />}>
                            {isLoaded ? (
                                <Routes>
                                    {/* {landingPageFlag === LandingPageVariant.Enabled && <Route path="/" element={<Landing />} />} */}
                                    <Route path="/" element={<Landing />} />
                                    <Route path="/home" element={<Landing />} />
                                    {/*<Route path="tokens" element={<Tokens />}>*/}
                                    {/*  <Route path=":chainName" />*/}
                                    {/*</Route>*/}
                                    {/*<Route path="tokens/:chainName/:tokenAddress" element={<TokenDetails />} />*/}
                                    {/* <Route
                    path="vote/*"
                    element={
                      <Suspense fallback={<LazyLoadSpinner />}>
                        <Vote />
                      </Suspense>
                    }
                  /> */}
                                    {/* <Route path="create-proposal" element={<Navigate to="/vote/create-proposal" replace />} /> */}
                                    {/*<Route path="claim" element={<OpenClaimAddressModalAndRedirectToSwap />} />*/}
                                    {/*<Route path="uni" element={<Earn />} />*/}
                                    {/*<Route path="uni/:currencyIdA/:currencyIdB" element={<Manage />} />*/}

                                    <Route path="send" element={<RedirectPathToSwapOnly />} />
                                    <Route path="swap" element={<Swap />} />
                                    <Route path="/" element={<Swap />} />
                                    <Route path="swap/:id" element={<Swap />} />
                                    {/* <Route path="leaderboard" element={<LeaderBoard />} /> */}

                                    <Route path="paw" element={<MusdPage />} />

                                    {/* <Route path="maestro" element={<Maestro />} /> */}
                                    {/* <Route path="maestro/create" element={<CreateMaestro />}>
                    <Route path=":currencyIdA" />
                    <Route path=":currencyIdA/:currencyIdB" />
                    <Route path=":currencyIdA/:currencyIdB/:feeAmount" />
                  </Route>
                  <Route path="maestro/detail" element={<MastroDetail />}>
                    <Route path=":currencyIdA" />
                  </Route> */}

                                    <Route path="bridge" element={<BridgePage />} />
                                    <Route path="treat" element={<TreatPage />} />
                                    <Route path="treat/:id" element={<TreatPage />} />
                                    <Route path="farming" element={<FarmingV3Page />} />
                                    <Route path="farming/detail/:poolAddress" element={<DetailFarmingPage />} />
                                    {/*<Route path="farmingV2" element={<AlphaV2 />} />*/}
                                    {/*<Route path="farmingV2/detailv2" element={<AlphaDetailV2 />}>*/}
                                    {/*  <Route path=":poolAddress" element={<AlphaDetailV2 />} />*/}
                                    {/*</Route>*/}

                                    <Route path="pools-list" element={<PoolsInfo />} />

                                    <Route path="pool/v2/find" element={<PoolFinder />} />
                                    {/* <Route path="pool/v2" element={<PoolV2 />} /> */}
                                    <Route path="pool" element={<Pool />} />
                                    <Route path="pool/:tokenId" element={<PositionPage />} />

                                    <Route path="add/v2" element={<RedirectDuplicateTokenIdsV2 />}>
                                        <Route path=":currencyIdA" />
                                        <Route path=":currencyIdA/:currencyIdB" />
                                    </Route>

                                    {/* this is workaround since react-router-dom v6 doesn't support optional parameters any more */}
                                    <Route path="add" element={<RedirectDuplicateTokenIds />}>
                                        <Route path=":currencyIdA" />
                                        <Route path=":currencyIdA/:currencyIdB" />
                                        <Route path=":currencyIdA/:currencyIdB/:feeAmount" />
                                    </Route>

                                    <Route path="increase" element={<AddLiquidity />}>
                                        <Route path=":currencyIdA" />
                                        <Route path=":currencyIdA/:currencyIdB" />
                                        <Route path=":currencyIdA/:currencyIdB/:feeAmount" />
                                        <Route path=":currencyIdA/:currencyIdB/:feeAmount/:tokenId" />
                                    </Route>

                                    <Route path="remove/v2/:currencyIdA/:currencyIdB" element={<RemoveLiquidity />} />
                                    {/*<Route path="remove/:tokenId" element={<RemoveLiquidityV3 />} />*/}

                                    <Route path="migrate/v2" element={<MigrateV2 />} />
                                    <Route path="migrate/v2/:address" element={<MigrateV2Pair />} />
                                    {/*<Route path="meow-mint/:id" element={<MeowMint />} />*/}
                                    {/* <Route path="meow-mint" element={<MeowMint />} />
                                    <Route path="meow" element={<MonoPass/>}/> */}
                                    {/*<Route path="xp" element={<MainQuestXp />} />*/}
                                    {/*<Route path="xp/:id" element={<MainQuestXp />} />*/}

                                    {/*<Route path="launchpad" element={<Launchpad />} />*/}
                                    {/*<Route path="launchpad/:id" element={<Launchpad />} />*/}

                                    {/*<Route path="lottery" element={<MonoPotPage />} />*/}
                                    {/*<Route path="trading-contest" element={<TradingContestPage />} />*/}
                                    {/* <Route path="/" element={<Landing />} />
                                     <Route path="/swap" element={<Swap />} /> */}

                                    {/* <Route path="about" element={<About />} /> */}
                                    {/*<Route path="bitget" element={<BitgetTest />} />*/}
                                    <Route path="box" element={<MonoChestPage />} />
                                    {/*<Route path="*" element={<RedirectPathToSwapOnly />} />*/}
                                    <Route path="*" element={<RedirectPathToHome />} />
                                </Routes>
                            ) : (
                                <Loader />
                            )}
                        </Suspense>
                        <Marginer />
                    </BodyWrapper>
                    <FooterWrapper className='default_bg'>
                        <Footer />
                    </FooterWrapper>
                </Trace>
            </AppWrapper>
        </ErrorBoundary>
    )
}
