import styled from "styled-components/macro";
import {BREAKPOINTS} from "../../../theme";

export const TextStyle = styled.p<
    {
        color?: string,
        fontFamily?: string,
        fontSize?: string,
        fontSizeMb?: string,
        fontSizeLg?: string,
        fontWeight?: string,
        lineHeight?: string,
        margin?: string,
        marginMb?: string,
        padding?: string,
        textAlign?: string,
        textTransform?: string,
        textAlignMb?: string,
    }
>`
    color: ${({color}) => color ? color : '#4B2828'} !important;
    font-family: ${({fontFamily}) => fontFamily ?? 'Outfit'};
    font-size: ${({fontSize}) => fontSize ?? '16px'} !important;
    font-style: normal;
    font-weight: ${({fontWeight}) => fontWeight ?? '500'};
    line-height: ${({lineHeight}) => lineHeight ?? '100%'};
    margin: ${({margin}) => margin ?? 0};
    padding: ${({padding}) => padding};
    text-align: ${({textAlign}) => textAlign ?? 'start'};
    text-transform: ${({textTransform}) => textTransform ?? 'none'};
    @media (max-width: ${BREAKPOINTS.lg}px) {
        font-size: ${({fontSizeLg}) => fontSizeLg ?? '16px'} !important;
    }
    @media (max-width: ${BREAKPOINTS.md}px) {
        font-size: ${({fontSizeMb}) => fontSizeMb ?? '14px'} !important;
        margin: ${({marginMb}) => marginMb};
        text-align: ${({textAlignMb}) => textAlignMb ?? 'start'};
    }
`

export const SpanOutlined = styled.span`
    color: #ADFE00;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #4B2828;
    @media (max-width: ${BREAKPOINTS.md}px) {
        -webkit-text-stroke-width: 1px;
    }
`

export const BannerImageWrapper = styled.div`
        border-radius: 10px;
        width: 100%;
        max-width: 1720px;
        background-image: url("https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/swap/img_11.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 24px 40px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        @media (max-width: ${BREAKPOINTS.md}px) {
                padding: 10px;
                gap: 5px;
        }
        p{
                margin: 0;
        }
        span.border_text{
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: #5F2A01;
                font-weight: 800;
        }
        span.bold_text{
                font-weight: 800;
                font-family: Outfit !important;
        }
        span.sub_text{
                font-family: Outfit !important;
                font-size: 21px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: 0.22px;
                @media (max-width: ${BREAKPOINTS.lg}px) {
                        font-size: 18px;
                }
                @media (max-width: ${BREAKPOINTS.sm}px) {
                        max-width: 360px;
                        font-size: 13px;
                }
        }
`

export const InformationWrapper = styled.div`
    max-width: 1170px;
    margin: 0 auto;
    width: 100%;
    position: relative;
    z-index: 1;
    @media (max-width: ${BREAKPOINTS.xl}px) {
        max-width: 95%;
    }
`

export const InformationContainer = styled.div`
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 100px;
        @media (max-width: ${BREAKPOINTS.md}px) {
                flex-direction: column;
                gap: 32px;
        }

        .left-content {
                display: flex;
                flex-direction: column;
                gap: 24px;
                max-width: 497px;

                .desc-wrapper {
                        display: flex;
                        flex-direction: column;
                        gap: 16px;

                        .link-wrapper {
                                display: flex;
                                gap: 20px;

                                .link {
                                        display: flex;

                                        img {
                                                width: 24px;
                                                height: 24px;
                                        }
                                }
                        }
                }
        }

        .right-content {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 64px;

                .feature {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 32px;

                        .feature-item {
                                width: 100%;
                                display: grid;
                                grid-template-columns: repeat(3, 1fr);
                                row-gap: 24px;
                                @media (max-width: ${BREAKPOINTS.md}px) {
                                        grid-template-columns: repeat(2, 1fr);
                                        row-gap: 14px;
                                }

                                .item {
                                        grid-column: span 1 / span 1;
                                        display: flex;
                                        gap: 8px;
                                        align-items: center;

                                        .icon-coin {
                                                display: flex;

                                                img {
                                                        width: 32px;
                                                        height: 32px;
                                                        @media (max-width: ${BREAKPOINTS.md}px) {
                                                                width: 24px;
                                                                height: 24px;
                                                        }
                                                }

                                                .right-img {
                                                        margin-left: -10px;
                                                }
                                        }
                                }

                                .text-feature-wrapper {
                                        display: flex;
                                        flex-direction: column;
                                }
                        }
                }
        }
`

