import { NotchedButtonFill } from "../../components/Button";
import { useEffect, useMemo, useState } from "react";
import {
    ActionTreatWrapper, ActionTreatLeftAction, ActionTreatRightInfo,
    Container,
    ContentTopWrapper, Dropdown,
    InfoTreatBottom,
    InfoTreatTop,
    InfoTreatWrapper, InviteFriendWrapper, LeaderboardWrapper,
    Line, LineInviteFriend, Paging, Table, TableItem, TableItemTreatHistory, TabSelectItem,
    TextStyle, TitleActionFarmingPool, TreatHistoryWrapper,
    Wrapper
} from "./component/Component";
import { PopupEarnTreat } from "./component/PopupEarnTreat";
import { PopupInviteFriend } from "./component/PopupInviteFriend";
import { PopupPaw } from "./component/PopupPaw";
import { PopupSwap } from "./component/PopupSwap";
import { useNavigate, useParams } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import axios from "axios"
import { GMEOW_API_BASE_URL } from "utils/env";
import { formatNumber } from "utils/formatNumbers";
import { notification, Pagination, Tooltip } from "antd";
import useCountdownTimer from "hooks/useCountdownTimer";
import { truncateAddress } from "utils/stringUtils";
import ContentLoading from "components/ContentLoading";
import { PrevNextRender } from "pages/Xp/sections/ClaimedXpHistory";
import '../Xp/sections/leaderboard.css'
import TreatRefcodePopup from "./component/TreatRefcodePopup";
import { useDailyTaskStore } from "state/dailyTask/useDailyTaskStore";

type ReceivedRewardType = {
    totalPaw: number
    totalGm: number
    totalXgm: number
    totalPoint: number
    totalG: number
}
type TaskRewardType = {
    swap: ReceivedRewardType
    farm: ReceivedRewardType
    paw: ReceivedRewardType
    ref: ReceivedRewardType
}
type LeaderBoardType = 'SWAP' | 'FARM' | 'PAW' | 'XGM' | 'REF' | 'BONUS' | 'RW_PAW' | 'RW_XGM' | 'RW_GM' | 'RW_POINT' | 'RW_G';

type RewardType = {
    paw: number;
    gm: number;
    xgm: number;
    point: number;
    g: number;
}
type XpLeaderboardType = {
    currentEpoch: {
        seq: number,
        endDate: string
    };
    totalDistributed: RewardType,
    nextTotalDistributed: RewardType,
    myPendingReward?: RewardType,
    list: [{
        rank: number;
        address: string;
        xp: number;
        reward?: RewardType
    }],
    myTop: {
        rank: number, xp: number, myRefXp: number, reward?: RewardType
    },
}

export default function TreatPage() {
    const { account, provider } = useWeb3React()
    const dailyTaskStore = useDailyTaskStore()
    const navigate = useNavigate()
    const pathParams = useParams()

    const [tab, setTab] = useState(1)
    const [active, setActive] = useState(false)

    const [showPopupEarnTreat, setShowPopupEarnTreat] = useState(false);
    const [showPopupInviteFriend, setShowPopupInviteFriend] = useState(false);
    const [showPopupPaw, setShowPopupPaw] = useState(false);
    const [showPopupSwap, setShowPopupSwap] = useState(false);

    const togglePopupEarnTreat = () => setShowPopupEarnTreat(!showPopupEarnTreat);
    const togglePopupInviteFriend = () => setShowPopupInviteFriend(!showPopupInviteFriend);
    const togglePopupPaw = () => setShowPopupPaw(!showPopupPaw);
    const togglePopupSwap = () => setShowPopupSwap(!showPopupSwap);

    const handleTab = (tab: number) => () => {
        setTab(tab)
    }

    const handleDropDown = () => {
        setActive(!active)
    }

    const [myXp, setMyXp] = useState<number>(0)
    const [invitedCnt, setInvitedCnt] = useState<number>(0)
    const [myReceivedRewards, setMyReceivedRewards] = useState<ReceivedRewardType>()
    const [taskRewards, setTaskRewards] = useState<TaskRewardType>()

    const [isLoadingUserInfo, setisLoadingUserInfo] = useState<boolean>(false)
    const loadUserInfo = () => {
        if (isLoadingUserInfo) return
        setisLoadingUserInfo(true)
        axios
            .get(`${GMEOW_API_BASE_URL}/leaderboard/my-xp-info?address=${account ?? ''}`)
            .then(
                ({
                    data,
                }: {
                    data: {
                        xp: number
                        invitedCnt: number
                        refcode: string,
                        receivedRewards: ReceivedRewardType
                        taskRewards: TaskRewardType
                    }
                }) => {
                    setUserRefLink(`${window.location.host}/#/treat/ref=${data.refcode}`);
                    setMyXp(data.xp)
                    setInvitedCnt(data.invitedCnt)
                    setMyReceivedRewards(data.receivedRewards)
                    setTaskRewards(data.taskRewards)
                }
            )
            .catch((err) => { })
            .finally(() => setisLoadingUserInfo(false))
    }
    useEffect(() => {
        setTimeout(() => loadUserInfo(), 1000)
    }, [account])

    // FOR REF
    const [userRefLink, setUserRefLink] = useState<string>('');
    const [isOpenSignModal, setIsOpenSignModal] = useState<boolean>(false);
    const [refCode, setRefCode] = useState<string>('');
    let isSigningMess = false;

    const handleCopyRefLink = () => {
        navigator.clipboard.writeText(userRefLink)
        notification.success({
            message: 'Your invite link has been copied to your clipboard'
        })
    }


    // leaderboard
    const [tableType, setTableType] = useState<LeaderBoardType>('FARM');
    const [xpLeaderboard, setXpLeaderboard] = useState<XpLeaderboardType>();
    const [isLoadingLeaderboard, setIsLoadingLeaderboard] = useState<boolean>(false);

    const [currentLeaderboardPage, setCurrentLeaderboardPage] = useState<number>(0)
    const [totalLeaderboardPage, setTotalLeaderboardPage] = useState<number>(0)

    const nextEpochTime = useMemo(() => Number(xpLeaderboard?.currentEpoch?.endDate ?? 0), [xpLeaderboard])
    const { days, hours, minutes, seconds } = useCountdownTimer('DAY', nextEpochTime)

    const loadLeaderboardData = (type: LeaderBoardType) => {
        if (isLoadingLeaderboard) return;
        setIsLoadingLeaderboard(true)
        setTableType(type);
        axios.get(`${GMEOW_API_BASE_URL}/leaderboard/leaderboard?type=${type}&address=${account ?? ''}`)
            .then(({ data }: { data: XpLeaderboardType }) => {
                setXpLeaderboard(data);
            })
            .catch(err => { })
            .finally(() => setIsLoadingLeaderboard(false));
    }

    useEffect(() => {
        loadLeaderboardData('FARM')
    }, [account])

    // for history 
    const [historyFilterType, setHistoryFilterType] = useState<LeaderBoardType | 'all' | 'RW'>('all');
    const [isLoadingHistoryData, setIsLoadingHistoryData] = useState<boolean>(false)
    const [historyList, setHistoryList] = useState<any[]>([]);
    const [totalHistoryElement, setHistoryTotalElement] = useState<number>(0);
    const [currentHistoryPage, setCurrentHistoryPage] = useState<number>(1);

    const onCallHistoryApi = (page: number, type: LeaderBoardType | 'all' | 'RW') => {
        if (isLoadingHistoryData) return;
        setIsLoadingHistoryData(true);
        setHistoryFilterType(type)
        setActive(false)
        axios.get(`${GMEOW_API_BASE_URL}/leaderboard/my-claimed-xp-history?address=${account ?? ''}&page=${page - 1}&type=${type}`)
            .then(({ data }: { data: any }) => {
                setHistoryList(data.data);
                setHistoryTotalElement(data.totalElement);
            })
            .catch(err => { })
            .finally(() => setIsLoadingHistoryData(false))
    }

    const getHistoryTypeName = (type: string) => {
        switch (type) {
            case 'SWAP': return 'Swap';
            case 'FARM': return 'Farm';
            case 'PAW': return 'PAW';
            case 'XGM': return 'xGM';
            case 'REF': return 'Referral';
            case 'BONUS': return 'Bonus';
            default:
                if (type.startsWith('RW'))
                    return 'Reward';
                else return '';
        }
    }

    const getHistoryIcon = (type: string) => {
        if (!type || type.startsWith('RW')) return 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/reward/reward_icon.png'
        else return `https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/reward/${type.toLowerCase()}_icon.png`
    }

    const getHistoryBalanceName = (type: string) => {
        switch (type) {
            case 'SWAP': return 'TREAT';
            case 'FARM': return 'TREAT';
            case 'PAW': return 'TREAT';
            case 'XGM': return 'TREAT';
            case 'REF': return 'TREAT';
            case 'BONUS': return 'TREAT';
            case 'RW_PAW': return 'PAW';
            case 'RW_XGM': return 'xGM';
            case 'RW_GM': return 'GM';
            case 'RW_POINT': return 'Point';
            case 'RW_G': return '';
            default: return '';
        }
    }
    const onHistoryChangePage = (page: number) => { setCurrentHistoryPage(page); onCallHistoryApi(page, historyFilterType) };

    useEffect(() => {
        if (account) onCallHistoryApi(1, 'all')
    }, [account])

    const [isMobile, setIsMobile] = useState<boolean>(false)
    useEffect(() => {
        setIsMobile(window.screen.width <= 600)
    }, [])
    return (
        <Container>
            <Wrapper>
                <PopupEarnTreat show={showPopupEarnTreat} onClose={togglePopupEarnTreat}></PopupEarnTreat>
                <PopupInviteFriend show={showPopupInviteFriend} onClose={togglePopupInviteFriend}></PopupInviteFriend>
                <PopupPaw show={showPopupPaw} onClose={togglePopupPaw}></PopupPaw>
                <PopupSwap show={showPopupSwap} onClose={togglePopupSwap}></PopupSwap>
                <TreatRefcodePopup />
                <ContentTopWrapper>
                    <InfoTreatWrapper>
                        <InfoTreatTop>
                            <div className="title">
                                <TextStyle textTransform={"uppercase"} textAlign={"center"} fontFamily={"DynaPuff"}
                                    fontSize={"40px"} fontSizeMb={"28px"} fontWeight={"600"} lineHeight={"125%"}
                                    textAlignMb="center">treat
                                    your
                                    meow <br /> to some</TextStyle>
                                <TextStyle textTransform={"uppercase"} textAlign={"center"} fontFamily={"DynaPuff"}
                                    fontSize={"40px"} fontSizeMb={"28px"} fontWeight={"600"} lineHeight={"125%"}
                                    color={"#FFB704"} textAlignMb="center"
                                >
                                    <span>great treats</span>
                                </TextStyle>
                            </div>
                            <div className="info-treat-wrapper">
                                <div className="info-treat-content">
                                    <TextStyle color={"#D7903C"} fontSize="14px" fontSizeMb="12px"
                                        fontWeight={"400"}>Total TREAT</TextStyle>
                                    <div className="info-total-treat" style={{ justifyContent: 'center' }}>
                                        <img className="icon-treat" style={{ width: '24px', height: '24px' }} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img.png" alt="img" />
                                        <TextStyle fontWeight={"600"} fontSize="24px" fontSizeMb="18px">
                                            {formatNumber(dailyTaskStore.totalTokenClaimed)}
                                        </TextStyle>
                                    </div>
                                </div>
                                <Line></Line>
                                <div className="info-treat-content">
                                    <TextStyle color={"#D7903C"} fontSize="14px" fontSizeMb="12px"
                                        fontWeight={"400"}>
                                        Reward Received</TextStyle>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
                                        {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
                                            <Tooltip title="PAW">
                                                <img style={{ width: '18px', cursor: 'pointer' }} className="icon-treat" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_4.png" alt="img_4" />
                                            </Tooltip>
                                            <TextStyle fontWeight={"600"} fontSize="24px" fontSizeMb="18px"
                                                textAlign={"center"}>
                                                {formatNumber(myReceivedRewards?.totalPaw)}
                                            </TextStyle>
                                        </div> */}
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
                                            <Tooltip title="xGM">
                                                <img style={{ width: '24px', height: '24px', cursor: 'pointer' }} className="icon-treat" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_5.png" alt="img_5" />
                                            </Tooltip>
                                            <TextStyle fontWeight={"600"} fontSize="24px" fontSizeMb="18px"
                                                textAlign={"center"}>
                                                {formatNumber(myReceivedRewards?.totalXgm)}
                                            </TextStyle>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </InfoTreatTop>
                        <InfoTreatBottom>
                            <img className="info-treat-img" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_1.png" alt="img_1" />
                        </InfoTreatBottom>
                    </InfoTreatWrapper>
                    <ActionTreatWrapper>
                        <div className="title-wrapper">
                            <TextStyle textTransform={"uppercase"}
                                fontSize="24px" fontSizeMb="18px"

                                fontWeight={"600"}
                                lineHeight={"140%"}
                                fontFamily={"DynaPuff"}>
                                get treat now!
                            </TextStyle>
                            <img className="img-triple-treat" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_2.png" alt="img_2" />
                        </div>

                        <div className="action-content-wrapper">
                            <div className="action-content-title">
                                <TextStyle fontWeight={"400"}
                                    fontSize="14px" fontSizeMb="12px"
                                    fontFamily={"DynaPuff"}
                                    textTransform={"uppercase"}>referral</TextStyle>
                            </div>
                            <div className="action-content-desc">
                                <TextStyle fontSize="18px" fontSizeMb="12px"
                                    fontFamily={"DynaPuff"}
                                    lineHeight={"125%"}>
                                    Invite friends to trade and earn with Gmeow
                                </TextStyle>
                                <img onClick={togglePopupInviteFriend} style={{ cursor: 'pointer' }} className="icon-info" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_3.png" alt="img_3" />
                            </div>
                            <div className="action-content-info-wrapper">
                                <ActionTreatRightInfo>
                                    <TextStyle color={"#D7903C"}
                                        fontSize="14px" fontSizeMb="12px"
                                        fontWeight={"400"}>
                                        Current Rewards
                                    </TextStyle>
                                    <div className="statistics-wrapper">
                                        {/* <div className="statistics-content">
                                            <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_4.png" alt="img_4" />
                                            <TextStyle fontWeight={"600"}>
                                                {formatNumber(taskRewards?.ref?.totalPaw)}
                                                <span style={{
                                                    color: "rgba(75, 40, 40, 0.50)",
                                                    fontFamily: "Outfit",
                                                    marginLeft: "4px"
                                                }}>PAW</span>
                                            </TextStyle>
                                        </div> */}
                                        <div className="statistics-content">
                                            <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_5.png" alt="img_4" />
                                            <TextStyle fontWeight={"600"}>
                                                {formatNumber(taskRewards?.ref?.totalXgm)}
                                                <span style={{
                                                    color: "rgba(75, 40, 40, 0.50)",
                                                    fontFamily: "Outfit",
                                                    marginLeft: "4px"
                                                }}>xGM</span>
                                            </TextStyle>
                                        </div>
                                    </div>
                                </ActionTreatRightInfo>
                                <ActionTreatLeftAction>
                                    <div className="statistic-wrapper">
                                        <TextStyle color={"#D7903C"}
                                            fontSize="14px" fontSizeMb="12px"
                                            fontWeight={"400"}>
                                            Total Bonus
                                        </TextStyle>
                                        <div className="statistics-content">
                                            <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img.png" alt="img_4" />
                                            <TextStyle fontWeight={"600"}>
                                                21%
                                            </TextStyle>
                                        </div>
                                    </div>

                                    <NotchedButtonFill isShowDirectly={true}
                                        mbFontSizeXs="12px"
                                        padding="15px 12px" mobilePadding="13px 10px" earMaxW="70px"
                                        mbEarMaxW="70px"
                                        height="40px" mbHeight="30px"
                                        onClick={handleCopyRefLink}
                                    >
                                        <TextStyle color={"#4B2828"}
                                            fontSize="14px"
                                            fontFamily={"DynaPuff"}
                                            textTransform={"uppercase"}>
                                            invite now
                                        </TextStyle>
                                    </NotchedButtonFill>

                                </ActionTreatLeftAction>
                            </div>
                        </div>

                        <div className="action-content-wrapper action-content-wrapper-farming-pool">
                            <TitleActionFarmingPool>
                                <div className="title-action-farming-pool-left-wrapper">
                                    <div className="title-action-farming-pool-left-content">
                                        <div className="action-content-title">
                                            <TextStyle fontWeight={"400"}
                                                fontSize="14px" fontSizeMb="12px"
                                                fontFamily={"DynaPuff"}
                                                textTransform={"uppercase"}>farming pool</TextStyle>
                                        </div>
                                        <img className="icon-water" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_6.png" alt="img_6" />
                                    </div>
                                    <div className="action-content-title">
                                        <TextStyle color={"#05C63B"}
                                            fontWeight={"400"}
                                            fontSize="14px" fontSizeMb="12px"
                                            fontFamily={"DynaPuff"}
                                            textTransform={"uppercase"}>best to earn</TextStyle>
                                    </div>
                                </div>
                                <img className="icon-star" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/e10.png" alt="img_7" />
                            </TitleActionFarmingPool>
                            <div className="action-content-desc">
                                <TextStyle fontSize="18px" fontSizeMb="12px"
                                    fontFamily={"DynaPuff"}
                                    lineHeight={"125%"}>
                                    Stake at least $10 worth LP tokens
                                </TextStyle>
                                <img onClick={togglePopupEarnTreat} style={{ cursor: 'pointer' }} className="icon-info" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_3.png" alt="img_3" />
                            </div>
                            <div className="action-content-info-wrapper">
                                <ActionTreatRightInfo style={{ boxShadow: "0px 0px 3px 3px rgba(0, 228, 64, 0.40)" }}>
                                    <TextStyle color={"#D7903C"}
                                        fontSize="14px" fontSizeMb="12px"
                                        fontWeight={"400"}>
                                        Current Rewards
                                    </TextStyle>
                                    <div className="statistics-wrapper">
                                        {/* <div className="statistics-content">
                                            <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_4.png" alt="img_4" />
                                            <TextStyle fontWeight={"600"}>
                                                {formatNumber(taskRewards?.farm?.totalPaw)}
                                                <span style={{
                                                    color: "rgba(75, 40, 40, 0.50)",
                                                    fontFamily: "Outfit",
                                                    marginLeft: "4px"
                                                }}>PAW</span>
                                            </TextStyle>
                                        </div> */}
                                        <div className="statistics-content">
                                            <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_5.png" alt="img_4" />
                                            <TextStyle fontWeight={"600"}>
                                                {formatNumber(taskRewards?.farm?.totalXgm)}
                                                <span style={{
                                                    color: "rgba(75, 40, 40, 0.50)",
                                                    fontFamily: "Outfit",
                                                    marginLeft: "4px"
                                                }}>xGM</span>
                                            </TextStyle>
                                        </div>
                                    </div>
                                </ActionTreatRightInfo>
                                <ActionTreatLeftAction style={{ boxShadow: "0px 0px 3px 3px rgba(0, 228, 64, 0.40)" }}>
                                    <div className="statistic-wrapper">
                                        <TextStyle color={"#D7903C"}
                                            fontSize="14px" fontSizeMb="12px"
                                            fontWeight={"400"}>
                                            TREAT Earn
                                        </TextStyle>
                                        <div className="statistics-content">
                                            <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img.png" alt="img_4" />
                                            <TextStyle fontWeight={"600"}>
                                                Every 2 hours
                                            </TextStyle>
                                        </div>
                                    </div>

                                    <NotchedButtonFill onClick={() => navigate('/farming')} isShowDirectly={true}
                                        mbFontSizeXs="12px"
                                        padding="15px 12px" mobilePadding="13px 10px" earMaxW="70px"
                                        mbEarMaxW="70px"
                                        height="40px" mbHeight="30px">
                                        <TextStyle color={"#4B2828"}
                                            fontSize="14px"
                                            fontFamily={"DynaPuff"}
                                            textTransform={"uppercase"}>
                                            STAKE now
                                        </TextStyle>
                                    </NotchedButtonFill>

                                </ActionTreatLeftAction>
                            </div>
                        </div>

                        {/* <div className="action-content-wrapper">
                            <div className="action-content-title">
                                <TextStyle fontWeight={"400"}
                                    fontSize="14px" fontSizeMb="12px"
                                    fontFamily={"DynaPuff"}
                                    textTransform={"uppercase"}>paw</TextStyle>
                            </div>
                            <div className="action-content-desc">
                                <TextStyle fontSize="18px" fontSizeMb="12px"
                                    fontFamily={"DynaPuff"}
                                    lineHeight={"125%"}>
                                    Mint PAW worth more than $10
                                </TextStyle>
                                <img onClick={togglePopupPaw} style={{ cursor: 'pointer' }} className="icon-info" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_3.png" alt="img_3" />
                            </div>
                            <div className="action-content-info-wrapper">
                                <ActionTreatRightInfo>
                                    <TextStyle color={"#D7903C"}
                                        fontSize="14px" fontSizeMb="12px"
                                        fontWeight={"400"}>
                                        Current Rewards
                                    </TextStyle>
                                    <div className="statistics-wrapper">
                                        <div className="statistics-content">
                                            <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_4.png" alt="img_4" />
                                            <TextStyle fontWeight={"600"}>
                                                {formatNumber(taskRewards?.paw?.totalPaw)}
                                                <span style={{
                                                    color: "rgba(75, 40, 40, 0.50)",
                                                    fontFamily: "Outfit",
                                                    marginLeft: "4px"
                                                }}>PAW</span>
                                            </TextStyle>
                                        </div>
                                        <div className="statistics-content">
                                            <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_5.png" alt="img_4" />
                                            <TextStyle fontWeight={"600"}>
                                                {formatNumber(taskRewards?.paw?.totalXgm)}
                                                <span style={{
                                                    color: "rgba(75, 40, 40, 0.50)",
                                                    fontFamily: "Outfit",
                                                    marginLeft: "4px"
                                                }}>PAW</span>
                                            </TextStyle>
                                        </div>
                                    </div>
                                </ActionTreatRightInfo>
                                <ActionTreatLeftAction>
                                    <div className="statistic-wrapper">
                                        <TextStyle color={"#D7903C"}
                                            fontSize="14px" fontSizeMb="12px"
                                            fontWeight={"400"}>
                                            Minimum TREAT
                                        </TextStyle>
                                        <div className="statistics-content">
                                            <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img.png" alt="img_4" />
                                            <TextStyle fontWeight={"600"}>
                                                11
                                            </TextStyle>
                                        </div>
                                    </div>

                                    <NotchedButtonFill onClick={() => navigate('/paw')} isShowDirectly={true}
                                        mbFontSizeXs="12px"
                                        padding="15px 12px" mobilePadding="13px 10px" earMaxW="70px"
                                        mbEarMaxW="65px"
                                        height="40px" mbHeight="30px">
                                        <TextStyle color={"#4B2828"}
                                            fontSize="14px"
                                            fontFamily={"DynaPuff"}
                                            textTransform={"uppercase"}>
                                            mint now
                                        </TextStyle>
                                    </NotchedButtonFill>

                                </ActionTreatLeftAction>
                            </div>
                        </div> */}

                        <div className="action-content-wrapper">
                            <div className="action-content-title">
                                <TextStyle fontWeight={"400"}
                                    fontSize="14px" fontSizeMb="12px"
                                    fontFamily={"DynaPuff"}
                                    textTransform={"uppercase"}>swap</TextStyle>
                            </div>
                            <div className="action-content-desc">
                                <TextStyle fontSize="18px" fontSizeMb="12px"
                                    fontFamily={"DynaPuff"}
                                    lineHeight={"125%"}>
                                    Make a trade worth no less than $10
                                </TextStyle>
                                <img onClick={togglePopupSwap} style={{ cursor: 'pointer' }} className="icon-info" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_3.png" alt="img_3" />
                            </div>
                            <div className="action-content-info-wrapper">
                                <ActionTreatRightInfo>
                                    <TextStyle color={"#D7903C"}
                                        fontSize="14px" fontSizeMb="12px"
                                        fontWeight={"400"}>
                                        Current Rewards
                                    </TextStyle>
                                    <div className="statistics-wrapper">
                                        {/* <div className="statistics-content">
                                            <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_4.png" alt="img_4" />
                                            <TextStyle fontWeight={"600"}>
                                                {formatNumber(taskRewards?.swap?.totalPaw)}
                                                <span style={{
                                                    color: "rgba(75, 40, 40, 0.50)",
                                                    fontFamily: "Outfit",
                                                    marginLeft: "4px"
                                                }}>PAW</span>
                                            </TextStyle>
                                        </div> */}
                                        <div className="statistics-content">
                                            <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_5.png" alt="img_4" />
                                            <TextStyle fontWeight={"600"}>
                                                {formatNumber(taskRewards?.swap?.totalXgm)}
                                                <span style={{
                                                    color: "rgba(75, 40, 40, 0.50)",
                                                    fontFamily: "Outfit",
                                                    marginLeft: "4px"
                                                }}>xGM</span>
                                            </TextStyle>
                                        </div>
                                    </div>
                                </ActionTreatRightInfo>
                                <ActionTreatLeftAction>
                                    <div className="statistic-wrapper">
                                        <TextStyle color={"#D7903C"}
                                            fontSize="14px" fontSizeMb="12px"
                                            fontWeight={"400"}>
                                            Minimum TREAT
                                        </TextStyle>
                                        <div className="statistics-content">
                                            <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img.png" alt="img_4" />
                                            <TextStyle fontWeight={"600"}>
                                                10
                                            </TextStyle>
                                        </div>
                                    </div>

                                    <NotchedButtonFill onClick={() => navigate('/swap')} isShowDirectly={true}
                                        mbFontSizeXs="12px"
                                        padding="15px 12px" mobilePadding="13px 10px" earMaxW="65px"
                                        mbEarMaxW="70px"
                                        height="40px" mbHeight="30px">
                                        <TextStyle color={"#4B2828"}
                                            fontSize="14px"
                                            fontFamily={"DynaPuff"}
                                            textTransform={"uppercase"}>
                                            swap now
                                        </TextStyle>
                                    </NotchedButtonFill>

                                </ActionTreatLeftAction>
                            </div>
                        </div>

                    </ActionTreatWrapper>
                </ContentTopWrapper>

                {
                    account && <InviteFriendWrapper>
                        <TextStyle color={"#FFF9E1"}
                            fontFamily={"DynaPuff"}
                            fontSize="24px" fontSizeMb="16px"
                            lineHeight={"125%"}>
                            <span className="invite-friend-title">Earn extra PAW & TREAT from your friends <br
                                className="type-br" /> and their friends!</span>
                        </TextStyle>
                        <div className="invite-friend-content-wrapper">
                            <div className="invite-friend-content">
                                <TextStyle color={"rgba(75, 40, 40, 0.50)"}
                                    fontSize="14px" fontSizeMb="12px">
                                    Invite Link
                                </TextStyle>
                                <div className="link-to-invite-wrapper">
                                    <TextStyle fontSize={"21px"}>
                                        {userRefLink}
                                    </TextStyle>
                                    <div className="copy-wrapper" style={{ border: '1px solid #fff9e16e' }}>
                                        <TextStyle
                                            style={{ cursor: 'pointer' }}
                                            color={"#FFF9E1"}
                                            fontWeight={"600"} onClick={handleCopyRefLink}>
                                            Copy
                                        </TextStyle>
                                    </div>
                                </div>
                            </div>
                            <LineInviteFriend></LineInviteFriend>
                            <div className="invite-friend-content">
                                <TextStyle color={"rgba(75, 40, 40, 0.50)"}
                                    fontSize="14px" fontSizeMb="12px">
                                    Invited
                                </TextStyle>
                                <TextStyle fontWeight={"600"}
                                    fontSize={"28px"}>
                                    {invitedCnt}
                                </TextStyle>
                            </div>
                            <LineInviteFriend></LineInviteFriend>
                            <div className="invite-friend-content">
                                <TextStyle color={"rgba(75, 40, 40, 0.50)"}
                                    fontSize="14px" fontSizeMb="12px">
                                    Referral TREAT
                                </TextStyle>
                                <TextStyle fontWeight={"600"}
                                    fontSize={"28px"}>
                                    {
                                        formatNumber(dailyTaskStore.totalRefToken)
                                    }
                                </TextStyle>
                            </div>
                        </div>
                    </InviteFriendWrapper>
                }

                <LeaderboardWrapper>
                    <TextStyle fontFamily="DynaPuff"
                        fontSize="36px" fontSizeMb="24px"
                        lineHeight="125%"
                        textTransform="uppercase">
                        leaderboard
                    </TextStyle>
                    <div className="leaderboard-info-wrapper">
                        <div className="epoch-info-box">
                            <div className="leaderboard-info">
                                <TextStyle color="#D7903C"
                                    fontSize="14px" fontSizeMb="12px"
                                    fontWeight="400">
                                    Current Epoch
                                </TextStyle>
                                <TextStyle fontSize={"21px"}>
                                    #{(xpLeaderboard?.currentEpoch.seq ?? '-')}
                                </TextStyle>
                            </div>
                            <div className="line"></div>
                            <div className="leaderboard-info">
                                <TextStyle color={"#D7903C"}
                                    fontSize="14px" fontSizeMb="12px"
                                    fontWeight={"400"}>
                                    Next Epoch
                                </TextStyle>
                                <TextStyle fontSize={"21px"}
                                    color={"#05C63B"}>
                                    {days < 0 ? '-' : days}d : {hours < 0 ? '-' : hours}h : {minutes < 0 ? '-' : minutes}m : {seconds < 0 ? '-' : seconds}s
                                </TextStyle>
                            </div>
                        </div>

                        <div className="reward-info-box">
                            <div className="line"></div>
                            <div className="leaderboard-info">
                                <TextStyle color={"#D7903C"}
                                    fontSize="14px" fontSizeMb="12px"
                                    fontWeight={"400"}>
                                    Total Distributed
                                </TextStyle>
                                <div className="total-distribute-statistics-wrapper">
                                    {/* <div className="total-distribute-statistics">
                                        <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_4.png" alt="img_4" />
                                        <TextStyle fontWeight={"600"} fontSize="21px" fontSizeMb="14px">
                                            {formatNumber(xpLeaderboard?.totalDistributed?.paw)}
                                            <span style={{
                                                color: "rgba(75, 40, 40, 0.50)",
                                                fontFamily: "Outfit",
                                                marginLeft: "4px"
                                            }}></span>
                                        </TextStyle>
                                    </div> */}
                                    <div className="total-distribute-statistics">
                                        <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_5.png" alt="img_4" />
                                        <TextStyle fontWeight={"600"} fontSize="21px" fontSizeMb="14px">
                                            {formatNumber(xpLeaderboard?.totalDistributed?.xgm)}
                                            <span style={{
                                                color: "rgba(75, 40, 40, 0.50)",
                                                fontFamily: "Outfit",
                                                marginLeft: "4px"
                                            }}></span>
                                        </TextStyle>
                                    </div>
                                    {/* <div className="total-distribute-statistics">
                                    <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img.png" alt="img_4" />
                                    <TextStyle fontWeight={"600"} fontSize="21px" fontSizeMb="14px">
                                        103K
                                        <span style={{
                                            color: "rgba(75, 40, 40, 0.50)",
                                            fontFamily: "Outfit",
                                            marginLeft: "4px"
                                        }}>TREAT</span>
                                    </TextStyle>
                                </div> */}
                                </div>
                            </div>

                            <div className="line"></div>
                            <div className="leaderboard-info">
                                <TextStyle color={"#D7903C"}
                                    fontSize="14px" fontSizeMb="12px"
                                    fontWeight={"400"}>
                                    Next Distribution
                                </TextStyle>
                                <div className="total-distribute-statistics-wrapper">
                                    {/* <div className="total-distribute-statistics">
                                        <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_4.png" alt="img_4" />
                                        <TextStyle fontWeight={"600"} fontSize="21px" fontSizeMb="14px">
                                            {formatNumber(xpLeaderboard?.nextTotalDistributed?.paw)}
                                            <span style={{
                                                color: "rgba(75, 40, 40, 0.50)",
                                                fontFamily: "Outfit",
                                                marginLeft: "4px"
                                            }}></span>
                                        </TextStyle>
                                    </div> */}
                                    <div className="total-distribute-statistics">
                                        <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_5.png" alt="img_4" />
                                        <TextStyle fontWeight={"600"} fontSize="21px" fontSizeMb="14px">
                                            {formatNumber(xpLeaderboard?.nextTotalDistributed?.xgm)}
                                            <span style={{
                                                color: "rgba(75, 40, 40, 0.50)",
                                                fontFamily: "Outfit",
                                                marginLeft: "4px"
                                            }}></span>
                                        </TextStyle>
                                    </div>
                                </div>
                            </div>

                            <div className="line"></div>
                            <div className="leaderboard-info">
                                <TextStyle color={"#D7903C"}
                                    fontSize="14px" fontSizeMb="12px"
                                    fontWeight={"400"}>
                                    Your Pending Rewards
                                </TextStyle>
                                <div className="total-distribute-statistics-wrapper">
                                    {/* <div className="total-distribute-statistics">
                                        <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_4.png" alt="img_4" />
                                        <TextStyle fontWeight={"600"} fontSize="21px" fontSizeMb="14px">
                                            {formatNumber(xpLeaderboard?.myPendingReward?.paw)}
                                            <span style={{
                                                color: "rgba(75, 40, 40, 0.50)",
                                                fontFamily: "Outfit",
                                                marginLeft: "4px"
                                            }}></span>
                                        </TextStyle>
                                    </div> */}
                                    <div className="total-distribute-statistics">
                                        <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_5.png" alt="img_4" />
                                        <TextStyle fontWeight={"600"} fontSize="21px" fontSizeMb="14px">
                                            {formatNumber(xpLeaderboard?.myPendingReward?.xgm)}
                                            <span style={{
                                                color: "rgba(75, 40, 40, 0.50)",
                                                fontFamily: "Outfit",
                                                marginLeft: "4px"
                                            }}></span>
                                        </TextStyle>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="tab-select-wrapper">
                        <TabSelectItem onClick={() => { setTab(1); loadLeaderboardData('FARM') }} selected={tab === 1}>
                            <div className="item-content">
                                <TextStyle fontSize="18px" fontSizeMb="12px">
                                    Farm
                                </TextStyle>
                                <img className="icon-water" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_9.png" alt="img_9" />
                            </div>
                        </TabSelectItem>
                        <TabSelectItem onClick={() => { setTab(2); loadLeaderboardData('SWAP') }} selected={tab === 2}>
                            <div className="item-content">
                                <TextStyle fontSize="18px" fontSizeMb="12px">
                                    Swap
                                </TextStyle>
                            </div>
                        </TabSelectItem>
                        {/* <TabSelectItem onClick={() => { setTab(3); loadLeaderboardData('PAW') }} selected={tab === 3}>
                            <div className="item-content">
                                <TextStyle fontSize="18px" fontSizeMb="12px">
                                    PAW
                                </TextStyle>
                            </div>
                        </TabSelectItem> */}
                        <TabSelectItem onClick={() => { setTab(4); loadLeaderboardData('REF') }} selected={tab === 4}>
                            <div className="item-content">
                                <TextStyle fontSize="18px" fontSizeMb="12px">
                                    Referral
                                </TextStyle>
                            </div>
                        </TabSelectItem>
                    </div>
                    <div className="table-wrapper">
                        <div className="table-header">
                            <div className="table-header-rank">
                                <TextStyle fontSize="14px" fontSizeMb="12px"
                                    color={"rgba(75, 40, 40, 0.70)"}>#</TextStyle>
                            </div>
                            <div className="table-header-user">
                                <TextStyle fontSize="14px" fontSizeMb="12px"
                                    color={"rgba(75, 40, 40, 0.70)"}>#USER</TextStyle>
                            </div>
                            <div className="table-header-current-treat">
                                <TextStyle fontSize="14px" fontSizeMb="12px" color={"rgba(75, 40, 40, 0.70)"}
                                    textTransform={"uppercase"}>current treat</TextStyle>
                            </div>
                            <div className="table-header-rewards">
                                <TextStyle fontSize="14px" fontSizeMb="12px" color={"rgba(75, 40, 40, 0.70)"}
                                    textTransform={"uppercase"} textAlignMb="end">estimated rewards</TextStyle>
                            </div>
                        </div>

                        <ContentLoading isLoading={isLoadingLeaderboard}>
                            <Table style={{
                                height: '100%',
                                maxHeight: (xpLeaderboard?.list ?? []).length > 10 ? '500px' : `${(xpLeaderboard?.list ?? []).length * 60}px`
                            }}>
                                {
                                    (xpLeaderboard?.list ?? []).map((item, index) => {
                                        return (
                                            <TableItem key={index}
                                                borderBottom={index === 9 ? "none" : "1px solid rgba(229, 182, 112, 0.50)"}>
                                                <div className="table-body-rank">
                                                    <TextStyle>{item.rank == 0 ? '-' : item.rank}</TextStyle>
                                                </div>
                                                <div className="table-body-user">
                                                    <TextStyle>
                                                        {truncateAddress(item.address, isMobile ? 2 : 4)}{item.address == String(account ?? '').toLowerCase() ? '(You)' : ''}
                                                    </TextStyle>
                                                </div>
                                                <div className="table-body-current-treat">
                                                    <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img.png" alt="img" />
                                                    <TextStyle fontWeight={"600"}>
                                                        {formatNumber(item.xp, 5)}
                                                    </TextStyle>
                                                </div>
                                                <div className="table-body-rewards">
                                                    {/* <div className="rewards-content">
                                                        <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_4.png"
                                                            alt="img_4" />
                                                        <TextStyle fontWeight={"600"}>
                                                            {formatNumber(item.reward?.paw)}
                                                            <span style={{
                                                                color: "rgba(75, 40, 40, 0.50)",
                                                                fontFamily: "Outfit",
                                                                marginLeft: "4px"
                                                            }}>PAW</span>
                                                        </TextStyle>
                                                    </div> */}
                                                    <div className="rewards-content">
                                                        <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_5.png"
                                                            alt="img_4" />
                                                        <TextStyle fontWeight={"600"}>
                                                            {formatNumber(item.xp <= 0 ? 0 : item.reward?.xgm)}
                                                            <span style={{
                                                                color: "rgba(75, 40, 40, 0.50)",
                                                                fontFamily: "Outfit",
                                                                marginLeft: "4px"
                                                            }}>xGM</span>
                                                        </TextStyle>
                                                    </div>
                                                </div>
                                            </TableItem>
                                        )
                                    })
                                }
                            </Table>
                        </ContentLoading>

                        {/* <Paging>
                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_10.png" alt="previous" />
                            <div className="number">
                                <TextStyle fontSize="14px" fontSizeMb="12px" fontWeight={"600"}>1</TextStyle>
                                <TextStyle fontSize="14px" fontSizeMb="12px" fontWeight={"600"}>2</TextStyle>
                                <TextStyle fontSize="14px" fontSizeMb="12px" fontWeight={"600"}>...</TextStyle>
                                <TextStyle fontSize="14px" fontSizeMb="12px" fontWeight={"600"}>8</TextStyle>
                                <TextStyle fontSize="14px" fontSizeMb="12px" fontWeight={"600"}>9</TextStyle>
                            </div>
                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_11.png" alt="next" />
                        </Paging> */}
                        {/* {
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                <Pagination
                                    current={currentLeaderboardPage}
                                    total={totalLeaderboardPage}
                                    pageSize={100}
                                    itemRender={PrevNextRender}
                                    showSizeChanger={false}
                                    onChange={onChangePage}
                                />
                            </div>
                        } */}
                    </div>
                </LeaderboardWrapper>

                {
                    account && <TreatHistoryWrapper>
                        <TextStyle fontFamily={"DynaPuff"}
                            fontSize="36px" fontSizeMb="24px"
                            lineHeight={"125%"}
                            textTransform={"uppercase"}>
                            treat history
                        </TextStyle>

                        <div className="table-wrapper my-claimed-xp">
                            <div className="table-header">
                                <div className="table-header-user">
                                    <Dropdown display={active}>
                                        <div className="action" onClick={handleDropDown}>
                                            <TextStyle textTransform={"uppercase"} fontSize="14px" fontSizeMb="12px"
                                                color={"rgba(75, 40, 40, 0.70)"}>Activity</TextStyle>
                                            <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img_13.png" alt="down" />
                                        </div>
                                        <div className="display">
                                            <TextStyle className={historyFilterType == 'all' ? 'active' : ''} fontSize="14px" fontSizeMb="12px"
                                                lineHeight={"125%"} onClick={() => onCallHistoryApi(1, 'all')}>All</TextStyle>
                                            <TextStyle className={historyFilterType == 'FARM' ? 'active' : ''} fontSize="14px" fontSizeMb="12px"
                                                lineHeight={"125%"} onClick={() => onCallHistoryApi(1, 'FARM')}>Farm</TextStyle>

                                            <TextStyle className={historyFilterType == 'SWAP' ? 'active' : ''} fontSize="14px" fontSizeMb="12px"
                                                lineHeight={"125%"} onClick={() => onCallHistoryApi(1, 'SWAP')}>Swap</TextStyle>

                                            <TextStyle className={historyFilterType == 'REF' ? 'active' : ''} fontSize="14px" fontSizeMb="12px"
                                                lineHeight={"125%"} onClick={() => onCallHistoryApi(1, 'REF')}>Referral</TextStyle>

                                            <TextStyle className={historyFilterType == 'RW' ? 'active' : ''} fontSize="14px" fontSizeMb="12px"
                                                lineHeight={"125%"} onClick={() => onCallHistoryApi(1, 'RW')}>Reward</TextStyle>

                                            {/* <TextStyle className={historyFilterType == 'PAW' ? 'active' : ''} fontSize="14px" fontSizeMb="12px"
                                                lineHeight={"125%"} onClick={() => onCallHistoryApi(1, 'PAW')}>Paw</TextStyle>

                                            <TextStyle className={historyFilterType == 'BONUS' ? 'active' : ''} fontSize="14px" fontSizeMb="12px"
                                                lineHeight={"125%"} onClick={() => onCallHistoryApi(1, 'BONUS')}>Bonus</TextStyle>

                                              */}
                                        </div>
                                    </Dropdown>
                                </div>
                                <div className="table-header-current-treat">
                                    <TextStyle fontSize="14px" fontSizeMb="12px" color={"rgba(75, 40, 40, 0.70)"}
                                        textTransform={"uppercase"} textAlignMb="center">amount</TextStyle>
                                </div>
                                <div className="table-header-rewards">
                                    <TextStyle fontSize="14px" fontSizeMb="12px" color={"rgba(75, 40, 40, 0.70)"}
                                        textTransform={"uppercase"} textAlign={"end"}
                                        textAlignMb="end">date</TextStyle>
                                </div>
                            </div>
                            <ContentLoading isLoading={isLoadingHistoryData}>
                                <Table style={{
                                    height: '100%',
                                    maxHeight: historyList.length > 10 ? '500px' : `${historyList.length * 60}px`
                                }}>
                                    {
                                        historyList.map((item, index) => {
                                            return (
                                                <TableItemTreatHistory key={index}
                                                    borderBottom={index === 9 ? "none" : "1px solid rgba(229, 182, 112, 0.50)"}>
                                                    <div className="table-body-user" style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                                                        <img src={getHistoryIcon(item?.type)} alt="gmeowfi" />
                                                        <TextStyle>
                                                            {getHistoryTypeName(item?.type)}
                                                        </TextStyle>
                                                    </div>
                                                    <div className="table-body-current-treat">
                                                        {/* <img className="icon-statistics" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/treat/img.png" alt="img" /> */}
                                                        <TextStyle fontWeight={"600"}>
                                                            {formatNumber(item.xp)}
                                                            {' ' + getHistoryBalanceName(item?.type)}
                                                        </TextStyle>
                                                    </div>
                                                    <div className="table-body-rewards">
                                                        <TextStyle fontWeight={"600"}>
                                                            {item.date}
                                                        </TextStyle>
                                                    </div>
                                                </TableItemTreatHistory>
                                            )
                                        })
                                    }
                                    {
                                        historyList.length == 0 && <p style={{ textAlign: 'center' }}>Data is empty</p>
                                    }
                                </Table>
                            </ContentLoading>
                            {
                                historyList.length > 0 &&
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                    <Pagination
                                        current={currentHistoryPage}
                                        total={totalHistoryElement}
                                        pageSize={50}
                                        itemRender={PrevNextRender}
                                        showSizeChanger={false}
                                        onChange={onHistoryChangePage}
                                    />
                                </div>
                            }
                        </div>
                    </TreatHistoryWrapper>
                }
            </Wrapper>
        </Container>
    )
}