import { useEffect } from 'react'
import ChestPageSection1 from './sections/ChestPageSection1'
import styled from 'styled-components'
import {BREAKPOINTS} from "../../theme";


const MonoChestPageWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 1170px;
    @media (max-width: ${BREAKPOINTS.xl}px) {
        max-width: 95%;
    }
`

const PreviewImageBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -99;
  img{
    height: 1px !important;
    width: 1px !important;
  }
`

export default function MonoChestPage() {
  const arr = [0, 1, 2, 3, 4]
 
  return (
    <MonoChestPageWrapper>
      <ChestPageSection1 />
      <PreviewImageBox>
        {
          arr.map((i, index) => <img key={index} src={` https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chest/popupOpenBox/Box-${i+1}-Open.gif`} />)
        }
      </PreviewImageBox>
    </MonoChestPageWrapper>
  )
}
