import { Trans } from '@lingui/macro'
import { sendEvent } from 'components/analytics'
import { ButtonOutlined, NotchedButtonFill } from 'components/Button'
import { AutoRow } from 'components/Row'
import React from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const Button = styled(NotchedButtonFill).attrs(() => ({}))`
  color: ${({ theme }) => theme.deprecated_text1};
  flex: 1;
`

export default function PresetsButtons({ setFullRange }: { setFullRange: () => void }) {
  return (
    <AutoRow gap="14px" width="auto">
      <Button
        onClick={() => {
          setFullRange()
          sendEvent({
            category: 'Liquidity',
            action: 'Full Range Clicked',
          })
        }}
        isHideSwitchchain={true}
      >
        <div style={{ color: '#000', fontSize: '18px', fontWeight: '400' }}>
          <Trans>Full Range</Trans>
        </div>
      </Button>
    </AutoRow>
  )
}
