import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { NotchedButtonBolderNew } from 'components/Button/NotchedButton'
import { NotchedButtonFill } from 'components/Button'
import { DoubleLogo, SingleCurrencyLogo } from 'components/DoubleLogo'
import {
    useDefaultsFromURLSearch,
    useDerivedSwapInfo,
    useSwapActionHandlers,
    useSwapState,
} from '../../state/swap/hooks'
import './poolsInfo.css'
import SettingsTab from '../../components/Settings'
import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core'
import { MouseoverTooltip } from 'components/Tooltip'
import { AlertTriangle, ArrowDown, CheckCircle, HelpCircle, Info, PlusSquare, Activity, Gift } from 'react-feather'
import { PoolList } from '../../constants/poolListData'
import axios, { Method } from 'axios'
import styled from 'styled-components/macro'
import { currencyId } from "../../utils/currencyId";
import { formatNumber } from 'utils/formatNumbers'
import { useAlphaStore } from 'hooks/useAlphaStore'
import ContentLoading from 'components/ContentLoading'
import { useAllTokens } from 'hooks/Tokens'

// img
const search = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/maestro/search.svg'
const tag = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/poolslist/tag2.svg'
const listcard = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/maestro/pair2.svg'
const ethIcon = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/maestro/musd1.svg'
const requireIcon =
    'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/maestro/requireIcon.svg'
const pools = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/poolslist/pools.svg'

const musdIcon = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/musd/musd.svg'
const usdbIcon = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/musd/usdb.svg'

const TextStyle = styled.span<
    {
        color?: string,
        fontFamily?: string,
        fontSize?: string,
        fontSizeMb?: string,
        fontWeight?: string,
        lineHeight?: string,
        margin?: string,
        marginMb?: string,
        padding?: string,
        textAlign?: string
    }
>`
    color: ${({ color }) => color ? color : '#4B2828'} !important;
    font-family: ${({ fontFamily }) => fontFamily ?? 'Outfit'};
    font-size: ${({ fontSize }) => fontSize ?? '16px'} !important;
    font-style: normal;
    font-weight: ${({ fontWeight }) => fontWeight ?? '500'};
    line-height: ${({ lineHeight }) => lineHeight ?? '100%'};
    margin: ${({ margin }) => margin};
    padding: ${({ padding }) => padding};
    text-align: ${({ textAlign }) => textAlign ?? 'start'};
    @media (max-width: 747px) {
        font-size: ${({ fontSizeMb }) => fontSizeMb ?? '21px'} !important;
        margin: ${({ marginMb }) => marginMb};
    }
`

const TopTrendingCoinWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    @media (max-width: 1028px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 60px;
    }
`

const BlockWrapper = styled.div`
    position: relative;
    width: 100%;
`

const Block = styled.div`
    position: relative;
    width: 100%;
    padding: 24px 20px;
    background: #FFF6D0;
    box-shadow: 0 7.395px 30px 0 rgba(145, 103, 20, 0.30), -6px -6px 4px 0 rgba(215, 144, 60, 0.60) inset, 6px 6px 4px 0 rgba(255, 255, 255, 0.80) inset;
    border-radius: 8px 8px 30px 30px;
    z-index: 2;
`

const EarWrapper = styled.div`
    position: absolute;
    top: -25%;
    left: 0;
    width: 100%;
    z-index: 1;
`

const EarStyle = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
`

const BlockTop = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .block-top-inner {
        display: flex;

        img {
            width: 40px;
            height: 40px;
        }

        .img-right {
            margin-left: -8px;
        }
    }
`

const BlockBottom = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    .block-bottom-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;

            img {
                width: 24px;
                height: 24px;
            }
        }
    }
`

const Line = styled.div`
    margin: 24px 0;
    width: 100%;
    height: 1px;
    background: rgba(229, 182, 112, 0.50);
    backdrop-filter: blur(4.800000190734863px);
`

const Cloud = styled.div`
    margin: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
        width: 257.999px;
        height: 132px;
    }

    @media (max-width: 747px) {
        margin: 40px 0;
    }
`

const PoolInfo = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 1536px) {
        flex-wrap: wrap;
        gap: 24px;
    }
`

const Search = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-radius: 10px;
    border: 2px solid #E5B670;
    background: #FFF6D0;
    box-shadow: 2.5px 2.5px 4px 0px rgba(215, 144, 60, 0.80) inset, -4px -4px 4px 0px #FFF inset;

    input {
        color: rgba(75, 40, 40, 0.50);
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 20px */
        outline: none;
        border: none;
        background: transparent;
    }

    img {
        width: 24px;
        height: 24px;
    }

    @media (max-width: 1536px) {
        width: 100%;
    }
`

const PoolTvlInfo = styled.div`
    padding: 8px 31px;
    border-radius: 10px;
    background: #FFE7AB;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media (max-width: 1536px) {
        width: 100%;
    }
    @media (max-width: 1280px) {
        padding: 8px;
    }
    @media (max-width: 1024px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const PoolTvlInfoItem = styled.div<
    {
        borderRight?: string,
        borderBottom?: string
    }
>`
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    border-right: ${({ borderRight }) => borderRight ?? ''};

    .left-content {
        display: flex;
        align-items: center;
        gap: 6px;

        img {
            width: 24px;
            height: 24px;
        }
    }

    @media (max-width: 1280px) {
        padding: 8px;
    }
    @media (max-width: 1024px) {
        border-right: none;
        border-bottom: ${({ borderBottom }) => borderBottom ?? ''};
    }
`

const PoolListItemWrapper = styled.div`
    margin-top: 40px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    @media (max-width: 1280px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const PoolListItem = styled.div`
    padding: 29.6px 20px 20px 20px;
    background: #FFF6D0;
    box-shadow: 0 7.395px 30px 0 rgba(145, 103, 20, 0.30), -6px -6px 4px 0 rgba(215, 144, 60, 0.60) inset, 6px 6px 4px 0 rgba(255, 255, 255, 0.80) inset;
    border-radius: 10px;
`

const PoolListItemTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    .top-content-left {
        display: flex;
        align-items: center;
        gap: 8px;

        .img-wrapper {
            display: flex;

            img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }

            .img-right {
                margin-left: -8px;
            }
        }
        .pool_info_wrapper{
            display: flex;
            @media (max-width: 500px) {
                display: grid;
            }
        }
        .pool_fee_box{
            height: 24px;
            border-radius: 50px;
            background: #FFE7AB;
            padding: 2px 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 500px) {
                span{
                    font-size: 12px !important;
                }
            }
        }
        .farming_box{
            cursor: pointer;
            transition: scale 0.15s ease-in-out;
            &:hover{
                scale: 1.05;
            }
        }
    }
`

const PoolListItemLine = styled.div`
    background: rgba(229, 182, 112, 0.50);
    backdrop-filter: blur(4.800000190734863px);
    width: 100%;
    height: 1px;
    margin: 24px 0;
`

const PoolListItemBottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bottom-content {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    @media (max-width: 600px) {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        gap: 10px;
        .bottom-content{
            gap: 6px;
        }
    }
`

interface Row {
    attributes: {
        fdv_usd: string
        base_token_price_usd: string
        transactions: {
            h24: {
                buys: string
                sells: string
            }
        }
        price_change_percentage: {
            m5: number
            h1: number
            h6: number
            h24: number
        }
        volume_usd: {
            h24: string
        }
        reserve_in_usd: string
        name: string
    }
    relationships: {
        base_token: {
            data: {
                id: string
            }
        }
        quote_token: {
            data: {
                id: string
            }
        }
        dex: {
            data: {
                id: string
            }
        }
    }
}


const PoolsInfo = () => {
    const navigate = useNavigate()
    const {
        trade: { state: tradeState, trade },
        allowedSlippage,
        currencyBalances,
        parsedAmount,
        currencies,
        inputError: swapInputError,
    } = useDerivedSwapInfo()
    const allTokens = useAllTokens()
    const alphaStore = useAlphaStore()
    // v3 pool info
    // get lp of each pool

    const [isLoadingPool, setIsLoadingPool] = useState<boolean>(false)
    const [v3DataList, setV3DataList] = useState<Row[]>([])
    const [v2DataList, setV2DataList] = useState<Row[]>([])
    const [totalReserveInUsdV3, setTotalReserveInUsdV3] = useState<any>()
    const [totalVolumeUsdH24V3, setTotalVolumeUsdH24V3] = useState<any>()
    const [totalTransactionsV3, seTtotalTransactionsV3] = useState<any>()
    const [totalReserveInUsdV2, setTotalReserveInUsdV2] = useState<any>()
    const [totalVolumeUsdH24V2, setTotalVolumeUsdH24V2] = useState<any>()
    const [totalTransactionsV2, seTtotalTransactionsV2] = useState<any>()
    const headers = {
        'Content-Type': 'application/json',
    }

    function filterPoolsWithH24Transactions(pools: any) {
        return pools
    }

    async function getV3PoolList() {
        setIsLoadingPool(true)
        let url = `https://api.geckoterminal.com/api/v2/networks/zircuit/dexes/gmeow/pools?page=1`
        let method: Method = 'get'
        const config = { method, url, headers }
        try {
            const res = await axios(config)
            const tokenAddresses = Object.keys(allTokens).map(i => i.toLowerCase())
            if (res) {
                // return res?.data?.data[0].attributes?.reserve_in_usd
                const listRes = res?.data.data
                const filteredPools = listRes.filter((item: Row) => {
                    if (!tokenAddresses.find(i => i == trimId(item.relationships.base_token.data.id))
                        || !tokenAddresses.find(i => i == trimId(item.relationships.quote_token.data.id))) return false
                    else return true
                })

                setV3DataList(filteredPools)
                let totalLiquid = 0
                let totalVolumh24 = 0
                let totalTransh24 = 0
                filteredPools.forEach((pool: any) => {
                    totalLiquid += parseFloat(pool.attributes.reserve_in_usd)
                    totalVolumh24 += parseFloat(pool.attributes.volume_usd.h24)
                    totalTransh24 += pool.attributes.transactions.h24.buys + pool.attributes.transactions.h24.sells
                })
                setTotalReserveInUsdV3(totalLiquid)
                setTotalVolumeUsdH24V3(totalVolumh24)
                seTtotalTransactionsV3(totalTransh24)
            } else {
                setTotalReserveInUsdV3(0)
                setTotalVolumeUsdH24V3(0)
                seTtotalTransactionsV3(0)
            }
        } catch (error) {
            console.log('error', error)
            setTotalReserveInUsdV3(0)
            setTotalVolumeUsdH24V3(0)
            seTtotalTransactionsV3(0)
        }
        finally {
            setIsLoadingPool(false)
        }
    }

    async function getV2PoolList() {
        return
        let url = `https://api.geckoterminal.com/api/v2/networks/blast/dexes/monoswap-V2-blast/pools?include=monoswap-V2-blast&page=1`
        let method: Method = 'get'
        const config = { method, url, headers }
        try {
            const res = await axios(config)
            if (res) {
                const filteredPools = filterPoolsWithH24Transactions(res?.data.data)
                setV2DataList(filteredPools)
                let totalLiquid = 0
                let totalVolumh24 = 0
                let totalTransh24 = 0
                filteredPools.forEach((pool: any) => {
                    totalLiquid += parseFloat(pool.attributes.reserve_in_usd)
                    totalVolumh24 += parseFloat(pool.attributes.volume_usd.h24)
                    totalTransh24 += pool.attributes.transactions.h24.buys + pool.attributes.transactions.h24.sells
                })
                setTotalReserveInUsdV2(totalLiquid)
                setTotalVolumeUsdH24V2(totalVolumh24)
                seTtotalTransactionsV2(totalTransh24)
            } else {
                setTotalReserveInUsdV2(0)
                setTotalVolumeUsdH24V2(0)
                seTtotalTransactionsV2(0)
            }
        } catch (error) {
            console.log('error', error)
            setTotalReserveInUsdV2(0)
            setTotalVolumeUsdH24V2(0)
            seTtotalTransactionsV2(0)
        }
    }

    useEffect(() => {
        getV3PoolList()
        // getV2PoolList()
    }, [])

    // trim token address
    function trimId(id: any) {
        return id?.slice(8).toLowerCase()
    }
    const [poolSearchVal, setPoolSearchVal] = useState<string>('')
    const v3DataListFilter = useMemo(() => v3DataList.filter(i => i.attributes.name.includes(poolSearchVal?.toUpperCase())), [v3DataList, poolSearchVal])

    function extractPoolFee(inputString: string) {
        const regex = /(\d+(\.\d+)?%)/;
        let match = inputString.match(regex);
        if (match) {
            return Number(match[0].replace('%', '')); // Return the first match found
        } else {
            return 0; // Return null if no match found
        }
    }

    const getFarmingPoolApr = useCallback((poolAddress: string) => {
        // @ts-ignore
        const farmingPoolInfo = alphaStore.poolInfos[poolAddress.toLowerCase()]
        if (farmingPoolInfo) return farmingPoolInfo.apr
        else return 0
    }, [alphaStore.poolInfos])
    return (
        <>
            <div className="pool-list-container">
                {/*<div className="tab-pool-selector">*/}
                {/*  <NotchedButtonBolderNew w="100%" h="100%" borderColor={'rgba(239, 239, 228, 0.5)'}>*/}
                {/*    <div*/}
                {/*      className={`tab-pool-selector-text ${tabSelector === 1 ? 'tab-pool-active' : ''}`}*/}
                {/*      onClick={() => {*/}
                {/*        setTabSelector(1)*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      V3*/}
                {/*    </div>*/}
                {/*  </NotchedButtonBolderNew>*/}
                {/*  <NotchedButtonBolderNew w="100%" h="100%" borderColor={'rgba(239, 239, 228, 0.5)'}>*/}
                {/*    <div*/}
                {/*      className={`tab-pool-selector-text ${tabSelector === 2 ? 'tab-pool-active' : ''}`}*/}
                {/*      onClick={() => {*/}
                {/*        setTabSelector(2)*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      V2*/}
                {/*    </div>*/}
                {/*  </NotchedButtonBolderNew>*/}
                {/*</div>*/}
                <div className="pool-top-container">
                    <div className="pool-top-left">
                        <div className="pool-top-left-img">
                            <img src="/pools-list/img.png" alt="img" />
                        </div>
                        <div className="pool-top-div">
                            <TextStyle fontFamily={'DynaPuff'} fontSize={'48px'} fontSizeMb={'32px'}>Liquidity Purrse</TextStyle>
                            <TextStyle fontSize={'21px'} fontSizeMb={'16px'} textAlign={'center'}>Seamless swaps with
                                optimized
                                slippage</TextStyle>
                        </div>
                    </div>
                    {/* <div className="swap-top-right">
                            <SettingsTab placeholderSlippage={DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE} />
                        </div> */}

                    {/* <TopTrendingCoinWrapper>
                        <BlockWrapper>
                            <EarWrapper>
                                <EarStyle>
                                    <Ear></Ear>
                                    <Ear></Ear>
                                </EarStyle>
                            </EarWrapper>
                            <Block>
                                <BlockTop>
                                    <div className={'block-top-inner'}>
                                        <img src="/pools-list/img_1.png" alt="img_1"/>
                                        <img className="img-right" src="/pools-list/img_2.png" alt="img_2"/>
                                    </div>
                                    <TextStyle fontSize={'18px'} fontSizeMb={"14px"}>
                                        ETH/USDC
                                    </TextStyle>
                                </BlockTop>
                                <Line></Line>
                                <BlockBottom>
                                    <div className={'block-bottom-inner'}>
                                        <div className={'content'}>
                                            <img src="/pools-list/img_3.png" alt="img_3"/>
                                            <TextStyle color={'rgba(75, 40, 40, 0.50)'} fontSize={'16px'}
                                                       fontSizeMb={'14px'}
                                                       lineHeight={'125%'}>
                                                TVL
                                            </TextStyle>
                                        </div>
                                        <TextStyle fontSize={'16px'} fontSizeMb={"12px"}
                                                   fontWeight={'600'} lineHeight={'125%'}>
                                            22,235,689.36
                                        </TextStyle>
                                    </div>
                                    <div className={'block-bottom-inner'}>
                                        <div className={'content'}>
                                            <img src="/pools-list/img_3.png" alt="img_3"/>
                                            <TextStyle color={'rgba(75, 40, 40, 0.50)'} fontSize={'16px'}
                                                       fontSizeMb={"14px"}
                                                       lineHeight={'125%'}>
                                                TVL
                                            </TextStyle>
                                        </div>
                                        <TextStyle fontSize={'16px'} fontSizeMb={"12px"} fontWeight={'600'}
                                                   lineHeight={'125%'}>
                                            22,235,689.36
                                        </TextStyle>
                                    </div>
                                </BlockBottom>
                            </Block>
                        </BlockWrapper>
                        <BlockWrapper>
                            <EarWrapper>
                                <EarStyle>
                                    <Ear></Ear>
                                    <Ear></Ear>
                                </EarStyle>
                            </EarWrapper>
                            <Block>
                                <BlockTop>
                                    <div className={'block-top-inner'}>
                                        <img src="/pools-list/img_1.png" alt="img_1"/>
                                        <img className="img-right" src="/pools-list/img_2.png" alt="img_2"/>
                                    </div>
                                    <TextStyle fontSize={'18px'} fontSizeMb={"14px"}>
                                        ETH/USDC
                                    </TextStyle>
                                </BlockTop>
                                <Line></Line>
                                <BlockBottom>
                                    <div className={'block-bottom-inner'}>
                                        <div className={'content'}>
                                            <img src="/pools-list/img_3.png" alt="img_3"/>
                                            <TextStyle color={'rgba(75, 40, 40, 0.50)'} fontSize={'16px'}
                                                       fontSizeMb={"14px"}
                                                       lineHeight={'125%'}>
                                                TVL
                                            </TextStyle>
                                        </div>
                                        <TextStyle fontSize={'16px'} fontSizeMb={"12px"} fontWeight={'600'}
                                                   lineHeight={'125%'}>
                                            22,235,689.36
                                        </TextStyle>
                                    </div>
                                    <div className={'block-bottom-inner'}>
                                        <div className={'content'}>
                                            <img src="/pools-list/img_3.png" alt="img_3"/>
                                            <TextStyle color={'rgba(75, 40, 40, 0.50)'} fontSize={'16px'}
                                                       fontSizeMb={"14px"}
                                                       lineHeight={'125%'}>
                                                TVL
                                            </TextStyle>
                                        </div>
                                        <TextStyle fontSize={'16px'} fontSizeMb={"12px"} fontWeight={'600'}
                                                   lineHeight={'125%'}>
                                            22,235,689.36
                                        </TextStyle>
                                    </div>
                                </BlockBottom>
                            </Block>
                        </BlockWrapper>
                        <BlockWrapper>
                            <EarWrapper>
                                <EarStyle>
                                    <Ear></Ear>
                                    <Ear></Ear>
                                </EarStyle>
                            </EarWrapper>
                            <Block>
                                <BlockTop>
                                    <div className={'block-top-inner'}>
                                        <img src="/pools-list/img_1.png" alt="img_1"/>
                                        <img className="img-right" src="/pools-list/img_2.png" alt="img_2"/>
                                    </div>
                                    <TextStyle fontSize={'18px'} fontSizeMb={"14px"}>
                                        ETH/USDC
                                    </TextStyle>
                                </BlockTop>
                                <Line></Line>
                                <BlockBottom>
                                    <div className={'block-bottom-inner'}>
                                        <div className={'content'}>
                                            <img src="/pools-list/img_3.png" alt="img_3"/>
                                            <TextStyle color={'rgba(75, 40, 40, 0.50)'}
                                                       fontSize={'16px'}
                                                       fontSizeMb={"14px"}
                                                       lineHeight={'125%'}>
                                                TVL
                                            </TextStyle>
                                        </div>
                                        <TextStyle fontSize={'16px'}
                                                   fontSizeMb={"12px"}
                                                   fontWeight={'600'}
                                                   lineHeight={'125%'}>
                                            22,235,689.36
                                        </TextStyle>
                                    </div>
                                    <div className={'block-bottom-inner'}>
                                        <div className={'content'}>
                                            <img src="/pools-list/img_3.png" alt="img_3"/>
                                            <TextStyle color={'rgba(75, 40, 40, 0.50)'}
                                                       fontSize={'16px'}
                                                       fontSizeMb={"14px"}
                                                       lineHeight={'125%'}>
                                                TVL
                                            </TextStyle>
                                        </div>
                                        <TextStyle fontSize={'16px'} fontSizeMb={"12px"} fontWeight={'600'}
                                                   lineHeight={'125%'}>
                                            22,235,689.36
                                        </TextStyle>
                                    </div>
                                </BlockBottom>
                            </Block>
                        </BlockWrapper>
                    </TopTrendingCoinWrapper> */}
                </div>

                <Cloud>
                    <img src="/pools-list/img_6.png" alt="img_6" />
                </Cloud>

                <PoolInfo>
                    <Search>
                        <input type="text" value={poolSearchVal} onChange={e => setPoolSearchVal(e.target.value)} placeholder={'Search token'} />
                        <img src="/pools-list/img_5.png" alt="img_5" />
                    </Search>
                    <PoolTvlInfo>
                        <PoolTvlInfoItem borderRight={'1px solid rgba(75, 40, 40, 0.50)'}
                            borderBottom={"1px solid rgba(75, 40, 40, 0.50)"}>
                            <div className={'left-content'}>
                                <img src="/pools-list/img_7.png" alt="img_7" />
                                <TextStyle color={'rgba(75, 40, 40, 0.50)'} lineHeight={'125%'} fontSizeMb={"14px"}>
                                    TVL
                                </TextStyle>
                            </div>
                            <TextStyle fontSize={'16px'} fontSizeMb={"12px"} fontWeight={'600'} lineHeight={'125%'}>
                                $ {formatNumber(totalReserveInUsdV3)}
                            </TextStyle>
                        </PoolTvlInfoItem>
                        <PoolTvlInfoItem borderRight={'1px solid rgba(75, 40, 40, 0.50)'}
                            borderBottom={"1px solid rgba(75, 40, 40, 0.50)"}>
                            <div className={'left-content'}>
                                <img src="/pools-list/img_8.png" alt="img_8" />
                                <TextStyle color={'rgba(75, 40, 40, 0.50)'} lineHeight={'125%'} fontSizeMb={"14px"}>
                                    24h Volume
                                </TextStyle>
                            </div>
                            <TextStyle fontSize={'16px'} fontSizeMb={"12px"} fontWeight={'600'} lineHeight={'125%'}>
                                ${formatNumber(totalVolumeUsdH24V3)}
                            </TextStyle>
                        </PoolTvlInfoItem>
                        <PoolTvlInfoItem>
                            <div className={'left-content'}>
                                <img src="/pools-list/img_9.png" alt="img_9" />
                                <TextStyle color={'rgba(75, 40, 40, 0.50)'} lineHeight={'125%'} fontSizeMb={"14px"}>
                                    24h Transaction
                                </TextStyle>
                            </div>
                            <TextStyle fontSize={'16px'} fontSizeMb={"12px"} fontWeight={'600'} lineHeight={'125%'}>
                                {formatNumber(totalTransactionsV3)}
                            </TextStyle>
                        </PoolTvlInfoItem>

                    </PoolTvlInfo>
                </PoolInfo>

                <PoolListItemWrapper>
                    <ContentLoading isLoading={isLoadingPool}>
                        {v3DataListFilter.length > 0 && v3DataListFilter.map((item: any, index: number) => (
                            <PoolListItem key={item?.attributes?.fdv_usd + index}>
                                <PoolListItemTop>
                                    <div className={'top-content-left'}>
                                        <div className={'img-wrapper'}>
                                            <img src={`https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/tokens/address/pools/${item?.relationships?.base_token?.data?.id?.replace('zircuit_', '')}.png`} alt="img_1" />
                                            <img className={'img-right'} src={`https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/tokens/address/pools/${item?.relationships?.quote_token?.data?.id.replace('zircuit_', '')}.png`} alt="img_1" />
                                        </div>
                                        <div className='pool_info_wrapper' style={{ alignItems: 'center', gap: '6px' }}>
                                            <TextStyle fontSize={'18px'} fontSizeMb={"14px"}>
                                                {item?.attributes.name.replace(extractPoolFee(item?.attributes.name) + '%', '')}
                                            </TextStyle>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                                <span className='pool_fee_box'>
                                                    <TextStyle fontFamily='DynaPuff' fontSize={'14px'} fontSizeMb={"14px"}>
                                                        {extractPoolFee(item?.attributes.name)}%
                                                    </TextStyle>
                                                </span>
                                                <span className='pool_fee_box farming_box' onClick={() => navigate('/farming')}>
                                                    <TextStyle color='#05C63B' fontFamily='DynaPuff' fontSize={'14px'} fontSizeMb={"14px"}>
                                                        FARM {formatNumber(getFarmingPoolApr(item.attributes.address))}%
                                                    </TextStyle>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <NotchedButtonFill
                                        onClick={() => {
                                            let token0 = trimId(item?.relationships?.quote_token?.data?.id)
                                            if (token0 == '0x4200000000000000000000000000000000000006') token0 = 'ETH'
                                            let token1 = trimId(item?.relationships?.base_token?.data?.id)
                                            if (token1 == '0x4200000000000000000000000000000000000006') token1 = 'ETH'
                                            const poolFee = Number(extractPoolFee(item?.attributes.name)) * 10000
                                            navigate(`/add/${token0}/${token1}/${poolFee}`)
                                        }}
                                        isShowDirectly={true}
                                        width="100%"
                                        padding="6px 8px"
                                        bg={'#00E440'}
                                        earMaxW={'100px'}
                                        mbEarMaxW={'80px'}
                                        mbHeight='40px'
                                        isTurnOfEar={window.screen.width <= 450}
                                    >
                                        <p className={'button-add-liquidity-style'}>
                                            ADD {window.screen.width <= 450 ? 'LP' : 'LIQUIDITY'}
                                        </p>
                                    </NotchedButtonFill>
                                </PoolListItemTop>
                                <PoolListItemLine></PoolListItemLine>
                                <PoolListItemBottom>
                                    <div className={'bottom-content'}>
                                        <TextStyle color={'rgba(75, 40, 40, 0.50)'} fontSize={'16px'} fontSizeMb={"12px"}
                                            lineHeight={'125%'}>Fees APR</TextStyle>
                                        <TextStyle color={'#05C63B'} fontSize={'18px'} fontSizeMb={"14px"}>
                                            {formatNumber(((Number(item?.attributes.volume_usd.h24) * Number(extractPoolFee(item?.attributes.name) / 100)) * 365) / Number(item?.attributes?.reserve_in_usd) * 100)}%
                                        </TextStyle>
                                    </div>
                                    <div className={'bottom-content'}>
                                        <TextStyle color={'rgba(75, 40, 40, 0.50)'} fontSize={'16px'} fontSizeMb={"12px"}
                                            lineHeight={'125%'}>Price (USD)</TextStyle>
                                        <TextStyle fontSize={'18px'} fontSizeMb={"14px"}>
                                            ${Number(item?.attributes?.base_token_price_usd) < 0.0000001 ? Number(item?.attributes?.base_token_price_usd).toFixed(8) : formatNumber(item?.attributes?.base_token_price_usd, 4)}
                                        </TextStyle>
                                    </div>
                                    <div className={'bottom-content'}>
                                        <TextStyle color={'rgba(75, 40, 40, 0.50)'} fontSize={'16px'} fontSizeMb={"12px"}
                                            lineHeight={'125%'}>TVL</TextStyle>
                                        <TextStyle fontSize={'18px'} fontSizeMb={"14px"}>
                                            $
                                            {item?.attributes?.reserve_in_usd > 1000
                                                ? numberWithCommas(Number(item?.attributes?.reserve_in_usd).toFixed(2))
                                                : Number(item?.attributes?.reserve_in_usd).toFixed(2)}
                                        </TextStyle>
                                    </div>
                                    <div className={'bottom-content'}>
                                        <TextStyle color={'rgba(75, 40, 40, 0.50)'} fontSize={'16px'} fontSizeMb={"12px"}
                                            lineHeight={'125%'}>24h Volume</TextStyle>
                                        <TextStyle fontSize={'18px'} fontSizeMb={"14px"}>
                                            $
                                            {item?.attributes?.volume_usd?.h24 > 1000
                                                ? numberWithCommas(Number(item?.attributes?.volume_usd?.h24).toFixed(2))
                                                : Number(item?.attributes?.volume_usd?.h24).toFixed(2)}
                                        </TextStyle>
                                    </div>
                                </PoolListItemBottom>
                            </PoolListItem>
                        ))}
                    </ContentLoading>

                </PoolListItemWrapper>
                {
                    v3DataListFilter.length == 0 && !isLoadingPool && <TextStyle color={'rgba(75, 40, 40, 0.50)'} lineHeight={'125%'} fontSizeMb={"14px"}>
                        There are no pools
                    </TextStyle>
                }

                {/*<div className="musd-history-paginate" style={{marginTop: "80px"}}>*/}
                {/*    <NotchedButtonFill disabled={true}>*/}
                {/*        <span style={{fontWeight: '500'}}>Previous</span>*/}
                {/*    </NotchedButtonFill>*/}
                {/*    <TextStyle>Page 1 of 1</TextStyle>*/}
                {/*    <NotchedButtonFill disabled={false}>*/}
                {/*        <span style={{fontWeight: '500'}}>Next</span>*/}
                {/*    </NotchedButtonFill>*/}
                {/*</div>*/}

                {/*<div className="pool-list-cover">*/}
                {/*    {tabSelector === 1 && (*/}
                {/*        <div className="pools-list-card-container">*/}
                {/*            <table>*/}
                {/*                <thead>*/}
                {/*                <tr>*/}
                {/*                    <th>*/}
                {/*                        <p className="pools-list-card-title">*/}
                {/*                            Pool*/}
                {/*                            <MouseoverTooltip*/}
                {/*                                text={*/}
                {/*                                    <div style={{width: '300px', height: 'fit-content'}}>*/}
                {/*                                        A token pair can have multiple pools, each with a different swap*/}
                {/*                                        earnings will be*/}
                {/*                                        automatically reinvested in your pool.{' '}*/}
                {/*                                    </div>*/}
                {/*                                }*/}
                {/*                                placement="top"*/}
                {/*                            >*/}
                {/*                                <Info size="12" color="#4B2828" style={{marginLeft: '4px'}}/>*/}
                {/*                            </MouseoverTooltip>*/}
                {/*                        </p>*/}
                {/*                    </th>*/}
                {/*                    <th>*/}
                {/*                        <p className="pools-list-card-title">Price (in USD)</p>*/}
                {/*                    </th>*/}
                {/*                    <th className="pools-list-hide-mobile">*/}
                {/*                        <p className="pools-list-card-title" style={{minWidth: '50px'}}>*/}
                {/*                            24h TX*/}
                {/*                        </p>*/}
                {/*                    </th>*/}
                {/*                    <th className="pools-list-hide-mobile">*/}
                {/*                        <p className="pools-list-card-title" style={{minWidth: '50px'}}>*/}
                {/*                            5M*/}
                {/*                        </p>*/}
                {/*                    </th>*/}
                {/*                    <th className="pools-list-hide-mobile">*/}
                {/*                        <p className="pools-list-card-title" style={{minWidth: '50px'}}>*/}
                {/*                            1H*/}
                {/*                        </p>*/}
                {/*                    </th>*/}
                {/*                    <th className="pools-list-hide-mobile">*/}
                {/*                        <p className="pools-list-card-title" style={{minWidth: '50px'}}>*/}
                {/*                            6H*/}
                {/*                        </p>*/}
                {/*                    </th>*/}
                {/*                    <th className="pools-list-hide-mobile">*/}
                {/*                        <p className="pools-list-card-title" style={{minWidth: '50px'}}>*/}
                {/*                            24H*/}
                {/*                        </p>*/}
                {/*                    </th>*/}
                {/*                    <th className="pools-list-hide-mobile">*/}
                {/*                        <p className="pools-list-card-title">24H VOLUME</p>*/}
                {/*                    </th>*/}
                {/*                    <th>*/}
                {/*                        <p className="pools-list-card-title">LIQUIDITY</p>*/}
                {/*                    </th>*/}
                {/*                    <th className="pools-list-hide-mobile">*/}
                {/*                        <p className="pools-list-card-title">FDV</p>*/}
                {/*                    </th>*/}
                {/*                    <th className="pools-list-hide-mobile">*/}
                {/*                        <p className="pools-list-card-title">Action</p>*/}
                {/*                    </th>*/}
                {/*                </tr>*/}
                {/*                </thead>*/}
                {/*                <tbody>*/}
                {/*                {v3DataList &&*/}
                {/*                    v3DataList?.map((row: any, index: number) => (*/}
                {/*                        <tr*/}
                {/*                            key={row?.attributes?.fdv_usd + index}*/}
                {/*                            className={index % 2 === 0 ? 'pool-list-colored' : 'leaderboard-normal'}*/}
                {/*                        >*/}
                {/*                            <td>*/}
                {/*                                <div className="pools-list-card-td-1">*/}
                {/*                                    /!* <DoubleLogo*/}
                {/*            img1={trimId(row?.relationships?.base_token?.data?.id)}*/}
                {/*            img2={trimId(row?.relationships?.quote_token?.data?.id)}*/}
                {/*            size={22}*/}
                {/*          />{' '} *!/*/}
                {/*                                    <p>{row?.attributes.name}</p>*/}
                {/*                                </div>*/}
                {/*                            </td>*/}

                {/*                            <td className="">*/}
                {/*                                <div>*/}
                {/*                                    <p>*/}
                {/*                                        $*/}
                {/*                                        {Number(row?.attributes?.base_token_price_usd) > 0.0001*/}
                {/*                                            ? Number(row?.attributes?.base_token_price_usd).toFixed(4)*/}
                {/*                                            : Number(row?.attributes?.base_token_price_usd).toExponential(2)}*/}
                {/*                                    </p>*/}
                {/*                                </div>*/}
                {/*                            </td>*/}
                {/*                            <td className="pools-list-hide-mobile">*/}
                {/*                                <div>*/}
                {/*                                    <p>*/}
                {/*                                        {Number(row?.attributes?.transactions?.h24?.buys) +*/}
                {/*                                            Number(row?.attributes?.transactions?.h24?.sells)}*/}
                {/*                                    </p>*/}
                {/*                                </div>*/}
                {/*                            </td>*/}
                {/*                            <td className="pools-list-hide-mobile">*/}
                {/*                                <div>*/}
                {/*                                    <p*/}
                {/*                                        style={{*/}
                {/*                                            color:*/}
                {/*                                                row?.attributes?.price_change_percentage?.m5 < 0*/}
                {/*                                                    ? '#ef4444'*/}
                {/*                                                    : row?.attributes?.price_change_percentage?.m5 > 0*/}
                {/*                                                        ? '#22c55e'*/}
                {/*                                                        : 'rgba(239, 239, 228, 0.5)',*/}
                {/*                                        }}*/}
                {/*                                    >*/}
                {/*                                        {row?.attributes?.price_change_percentage?.m5}%*/}
                {/*                                    </p>*/}
                {/*                                </div>*/}
                {/*                            </td>*/}
                {/*                            <td className="pools-list-hide-mobile">*/}
                {/*                                <div>*/}
                {/*                                    <p*/}
                {/*                                        style={{*/}
                {/*                                            color:*/}
                {/*                                                row?.attributes?.price_change_percentage?.h1 < 0*/}
                {/*                                                    ? '#ef4444'*/}
                {/*                                                    : row?.attributes?.price_change_percentage?.h1 > 0*/}
                {/*                                                        ? '#22c55e'*/}
                {/*                                                        : 'rgba(239, 239, 228, 0.5)',*/}
                {/*                                        }}*/}
                {/*                                    >*/}
                {/*                                        {row?.attributes?.price_change_percentage?.h1}%*/}
                {/*                                    </p>*/}
                {/*                                </div>*/}
                {/*                            </td>*/}
                {/*                            <td className="pools-list-hide-mobile">*/}
                {/*                                <div>*/}
                {/*                                    <p*/}
                {/*                                        style={{*/}
                {/*                                            color:*/}
                {/*                                                row?.attributes?.price_change_percentage?.h6 < 0*/}
                {/*                                                    ? '#ef4444'*/}
                {/*                                                    : row?.attributes?.price_change_percentage?.h6 > 0*/}
                {/*                                                        ? '#22c55e'*/}
                {/*                                                        : 'rgba(239, 239, 228, 0.5)',*/}
                {/*                                        }}*/}
                {/*                                    >*/}
                {/*                                        {row?.attributes?.price_change_percentage?.h6}%*/}
                {/*                                    </p>*/}
                {/*                                </div>*/}
                {/*                            </td>*/}
                {/*                            <td className="pools-list-hide-mobile">*/}
                {/*                                <div>*/}
                {/*                                    <p*/}
                {/*                                        style={{*/}
                {/*                                            color:*/}
                {/*                                                row?.attributes?.price_change_percentage?.h24 < 0*/}
                {/*                                                    ? '#ef4444'*/}
                {/*                                                    : row?.attributes?.price_change_percentage?.h24 > 0*/}
                {/*                                                        ? '#22c55e'*/}
                {/*                                                        : 'rgba(239, 239, 228, 0.5)',*/}
                {/*                                        }}*/}
                {/*                                    >*/}
                {/*                                        {row?.attributes?.price_change_percentage?.h24}%*/}
                {/*                                    </p>*/}
                {/*                                </div>*/}
                {/*                            </td>*/}

                {/*                            <td className="pools-list-hide-mobile">*/}
                {/*                                <div>*/}
                {/*                                    $*/}
                {/*                                    {row?.attributes?.volume_usd?.h24 > 1000*/}
                {/*                                        ? numberWithCommas(Number(row?.attributes?.volume_usd?.h24).toFixed(2))*/}
                {/*                                        : Number(row?.attributes?.volume_usd?.h24).toFixed(2)}*/}
                {/*                                </div>*/}
                {/*                            </td>*/}
                {/*                            <td className="">*/}
                {/*                                <div>*/}
                {/*                                    $*/}
                {/*                                    {row?.attributes?.reserve_in_usd > 1000*/}
                {/*                                        ? numberWithCommas(Number(row?.attributes?.reserve_in_usd).toFixed(2))*/}
                {/*                                        : Number(row?.attributes?.reserve_in_usd).toFixed(2)}*/}
                {/*                                </div>*/}
                {/*                            </td>*/}
                {/*                            <td className="pools-list-hide-mobile">*/}
                {/*                                <div>*/}
                {/*                                    <p>*/}
                {/*                                        $*/}
                {/*                                        {row?.attributes?.fdv_usd > 1000*/}
                {/*                                            ? numberWithCommas(Number(row?.attributes?.fdv_usd).toFixed(2))*/}
                {/*                                            : Number(row?.attributes?.fdv_usd).toFixed(2)}*/}
                {/*                                    </p>*/}
                {/*                                </div>*/}
                {/*                            </td>*/}
                {/*                            <td className="pools-list-hide-mobile">*/}
                {/*                                <div className="pools-list-card-td-action">*/}
                {/*                                    <MouseoverTooltip*/}
                {/*                                        text={<div style={{width: '150px', height: 'fit-content'}}>Add*/}
                {/*                                            liquidity</div>}*/}
                {/*                                        placement="top"*/}
                {/*                                    >*/}
                {/*                                        <div*/}
                {/*                                            onClick={() => {*/}
                {/*                                                navigate(*/}
                {/*                                                    `/add/${trimId(row?.relationships?.base_token?.data?.id)}/${trimId(*/}
                {/*                                                        row?.relationships?.quote_token?.data?.id*/}
                {/*                                                    )}`*/}
                {/*                                                )*/}
                {/*                                            }}*/}
                {/*                                            style={{cursor: 'pointer'}}*/}
                {/*                                        >*/}
                {/*                                            <PlusSquare/>*/}
                {/*                                        </div>*/}
                {/*                                    </MouseoverTooltip>*/}
                {/*                                </div>*/}
                {/*                            </td>*/}
                {/*                        </tr>*/}
                {/*                    ))}*/}
                {/*                </tbody>*/}
                {/*            </table>*/}
                {/*            <div className="musd-history-paginate">*/}
                {/*                <NotchedButtonFill disabled={true}>*/}
                {/*                    <span style={{fontWeight: '500'}}>Previous</span>*/}
                {/*                </NotchedButtonFill>*/}
                {/*                <span>Page 1 of 1</span>*/}
                {/*                <NotchedButtonFill disabled={false}>*/}
                {/*                    <span style={{fontWeight: '500'}}>Next</span>*/}
                {/*                </NotchedButtonFill>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*    {tabSelector === 2 && (*/}
                {/*        <div className="pools-list-card-container">*/}
                {/*            <table>*/}
                {/*                <thead>*/}
                {/*                <tr>*/}
                {/*                    <th>*/}
                {/*                        <p className="pools-list-card-title">*/}
                {/*                            Pool*/}
                {/*                            <MouseoverTooltip*/}
                {/*                                text={*/}
                {/*                                    <div style={{width: '300px', height: 'fit-content'}}>*/}
                {/*                                        A token pair can have multiple pools, each with a different swap*/}
                {/*                                        earnings will be*/}
                {/*                                        automatically reinvested in your pool.{' '}*/}
                {/*                                    </div>*/}
                {/*                                }*/}
                {/*                                placement="top"*/}
                {/*                            >*/}
                {/*                                <Info size="12" color="#4B2828" style={{marginLeft: '4px'}}/>*/}
                {/*                            </MouseoverTooltip>*/}
                {/*                        </p>*/}
                {/*                    </th>*/}
                {/*                    <th>*/}
                {/*                        <p className="pools-list-card-title">Price (in USD)</p>*/}
                {/*                    </th>*/}
                {/*                    <th className="pools-list-hide-mobile">*/}
                {/*                        <p className="pools-list-card-title" style={{minWidth: '50px'}}>*/}
                {/*                            24h TX*/}
                {/*                        </p>*/}
                {/*                    </th>*/}
                {/*                    <th className="pools-list-hide-mobile">*/}
                {/*                        <p className="pools-list-card-title" style={{minWidth: '50px'}}>*/}
                {/*                            5M*/}
                {/*                        </p>*/}
                {/*                    </th>*/}
                {/*                    <th className="pools-list-hide-mobile">*/}
                {/*                        <p className="pools-list-card-title" style={{minWidth: '50px'}}>*/}
                {/*                            1H*/}
                {/*                        </p>*/}
                {/*                    </th>*/}
                {/*                    <th className="pools-list-hide-mobile">*/}
                {/*                        <p className="pools-list-card-title" style={{minWidth: '50px'}}>*/}
                {/*                            6H*/}
                {/*                        </p>*/}
                {/*                    </th>*/}
                {/*                    <th className="pools-list-hide-mobile">*/}
                {/*                        <p className="pools-list-card-title" style={{minWidth: '50px'}}>*/}
                {/*                            24H*/}
                {/*                        </p>*/}
                {/*                    </th>*/}
                {/*                    <th className="pools-list-hide-mobile">*/}
                {/*                        <p className="pools-list-card-title">24H VOLUME</p>*/}
                {/*                    </th>*/}
                {/*                    <th>*/}
                {/*                        <p className="pools-list-card-title">LIQUIDITY</p>*/}
                {/*                    </th>*/}
                {/*                    <th className="pools-list-hide-mobile">*/}
                {/*                        <p className="pools-list-card-title">FDV</p>*/}
                {/*                    </th>*/}
                {/*                    <th className="pools-list-hide-mobile">*/}
                {/*                        <p className="pools-list-card-title">Action</p>*/}
                {/*                    </th>*/}
                {/*                </tr>*/}
                {/*                </thead>*/}
                {/*                <tbody>*/}
                {/*                {v2DataList &&*/}
                {/*                    v2DataList?.map((row: any, index: number) => (*/}
                {/*                        <tr*/}
                {/*                            key={row?.attributes?.fdv_usd + index}*/}
                {/*                            className={index % 2 === 0 ? 'pool-list-colored' : 'leaderboard-normal'}*/}
                {/*                        >*/}
                {/*                            <td>*/}
                {/*                                <div className="pools-list-card-td-1">*/}
                {/*                                    <p>{row?.attributes.name}</p>*/}
                {/*                                </div>*/}
                {/*                            </td>*/}

                {/*                            <td className="">*/}
                {/*                                <div>*/}
                {/*                                    <p>*/}
                {/*                                        $*/}
                {/*                                        {Number(row?.attributes?.base_token_price_usd) > 0.0001*/}
                {/*                                            ? Number(row?.attributes?.base_token_price_usd).toFixed(4)*/}
                {/*                                            : Number(row?.attributes?.base_token_price_usd).toExponential(2)}*/}
                {/*                                    </p>*/}
                {/*                                </div>*/}
                {/*                            </td>*/}
                {/*                            <td className="pools-list-hide-mobile">*/}
                {/*                                <div>*/}
                {/*                                    <p>*/}
                {/*                                        {Number(row?.attributes?.transactions?.h24?.buys) +*/}
                {/*                                            Number(row?.attributes?.transactions?.h24?.sells)}*/}
                {/*                                    </p>*/}
                {/*                                </div>*/}
                {/*                            </td>*/}
                {/*                            <td className="pools-list-hide-mobile">*/}
                {/*                                <div>*/}
                {/*                                    <p*/}
                {/*                                        style={{*/}
                {/*                                            color:*/}
                {/*                                                row?.attributes?.price_change_percentage?.m5 < 0*/}
                {/*                                                    ? '#ef4444'*/}
                {/*                                                    : row?.attributes?.price_change_percentage?.m5 > 0*/}
                {/*                                                        ? '#22c55e'*/}
                {/*                                                        : 'rgba(239, 239, 228, 0.5)',*/}
                {/*                                        }}*/}
                {/*                                    >*/}
                {/*                                        {row?.attributes?.price_change_percentage?.m5}%*/}
                {/*                                    </p>*/}
                {/*                                </div>*/}
                {/*                            </td>*/}
                {/*                            <td className="pools-list-hide-mobile">*/}
                {/*                                <div>*/}
                {/*                                    <p*/}
                {/*                                        style={{*/}
                {/*                                            color:*/}
                {/*                                                row?.attributes?.price_change_percentage?.h1 < 0*/}
                {/*                                                    ? '#ef4444'*/}
                {/*                                                    : row?.attributes?.price_change_percentage?.h1 > 0*/}
                {/*                                                        ? '#22c55e'*/}
                {/*                                                        : 'rgba(239, 239, 228, 0.5)',*/}
                {/*                                        }}*/}
                {/*                                    >*/}
                {/*                                        {row?.attributes?.price_change_percentage?.h1}%*/}
                {/*                                    </p>*/}
                {/*                                </div>*/}
                {/*                            </td>*/}
                {/*                            <td className="pools-list-hide-mobile">*/}
                {/*                                <div>*/}
                {/*                                    <p*/}
                {/*                                        style={{*/}
                {/*                                            color:*/}
                {/*                                                row?.attributes?.price_change_percentage?.h6 < 0*/}
                {/*                                                    ? '#ef4444'*/}
                {/*                                                    : row?.attributes?.price_change_percentage?.h6 > 0*/}
                {/*                                                        ? '#22c55e'*/}
                {/*                                                        : 'rgba(239, 239, 228, 0.5)',*/}
                {/*                                        }}*/}
                {/*                                    >*/}
                {/*                                        {row?.attributes?.price_change_percentage?.h6}%*/}
                {/*                                    </p>*/}
                {/*                                </div>*/}
                {/*                            </td>*/}
                {/*                            <td className="pools-list-hide-mobile">*/}
                {/*                                <div>*/}
                {/*                                    <p*/}
                {/*                                        style={{*/}
                {/*                                            color:*/}
                {/*                                                row?.attributes?.price_change_percentage?.h24 < 0*/}
                {/*                                                    ? '#ef4444'*/}
                {/*                                                    : row?.attributes?.price_change_percentage?.h24 > 0*/}
                {/*                                                        ? '#22c55e'*/}
                {/*                                                        : 'rgba(239, 239, 228, 0.5)',*/}
                {/*                                        }}*/}
                {/*                                    >*/}
                {/*                                        {row?.attributes?.price_change_percentage?.h24}%*/}
                {/*                                    </p>*/}
                {/*                                </div>*/}
                {/*                            </td>*/}

                {/*                            <td className="pools-list-hide-mobile">*/}
                {/*                                <div>*/}
                {/*                                    $*/}
                {/*                                    {row?.attributes?.volume_usd?.h24 > 1000*/}
                {/*                                        ? numberWithCommas(Number(row?.attributes?.volume_usd?.h24).toFixed(2))*/}
                {/*                                        : Number(row?.attributes?.volume_usd?.h24).toFixed(2)}*/}
                {/*                                </div>*/}
                {/*                            </td>*/}
                {/*                            <td className="">*/}
                {/*                                <div>*/}
                {/*                                    $*/}
                {/*                                    {row?.attributes?.reserve_in_usd > 1000*/}
                {/*                                        ? numberWithCommas(Number(row?.attributes?.reserve_in_usd).toFixed(2))*/}
                {/*                                        : Number(row?.attributes?.reserve_in_usd).toFixed(2)}*/}
                {/*                                </div>*/}
                {/*                            </td>*/}
                {/*                            <td className="pools-list-hide-mobile">*/}
                {/*                                <div>*/}
                {/*                                    <p>*/}
                {/*                                        $*/}
                {/*                                        {row?.attributes?.fdv_usd > 1000*/}
                {/*                                            ? numberWithCommas(Number(row?.attributes?.fdv_usd).toFixed(2))*/}
                {/*                                            : Number(row?.attributes?.fdv_usd).toFixed(2)}*/}
                {/*                                    </p>*/}
                {/*                                </div>*/}
                {/*                            </td>*/}
                {/*                            <td className="pools-list-hide-mobile">*/}
                {/*                                <div className="pools-list-card-td-action">*/}
                {/*                                    <MouseoverTooltip*/}
                {/*                                        text={<div style={{width: '150px', height: 'fit-content'}}>Add*/}
                {/*                                            liquidity</div>}*/}
                {/*                                        placement="top"*/}
                {/*                                    >*/}
                {/*                                        <div*/}
                {/*                                            onClick={() => {*/}
                {/*                                                navigate(*/}
                {/*                                                    `/add/${trimId(row?.relationships?.base_token?.data?.id)}/${trimId(*/}
                {/*                                                        row?.relationships?.quote_token?.data?.id*/}
                {/*                                                    )}`*/}
                {/*                                                )*/}
                {/*                                            }}*/}
                {/*                                            style={{cursor: 'pointer'}}*/}
                {/*                                        >*/}
                {/*                                            <PlusSquare/>*/}
                {/*                                        </div>*/}
                {/*                                    </MouseoverTooltip>*/}
                {/*                                </div>*/}
                {/*                            </td>*/}
                {/*                        </tr>*/}
                {/*                    ))}*/}
                {/*                </tbody>*/}
                {/*            </table>*/}
                {/*            <div className="musd-history-paginate">*/}
                {/*                <NotchedButtonFill disabled={true}>*/}
                {/*                    <span style={{fontWeight: '500'}}>Previous</span>*/}
                {/*                </NotchedButtonFill>*/}
                {/*                <span>Page 1 of 1</span>*/}
                {/*                <NotchedButtonFill disabled={false}>*/}
                {/*                    <span style={{fontWeight: '500'}}>Next</span>*/}
                {/*                </NotchedButtonFill>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</div>*/}
            </div>
        </>
    )
}

export default PoolsInfo
export const numberWithCommas = (number: any) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
