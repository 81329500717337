import Tooltip from 'components/Tooltip'
import { useState } from 'react'
import styled from 'styled-components/macro'

const TextWrapper = styled.span<{
  margin: boolean
  link?: boolean
  fontSize?: string
  adjustSize?: boolean
  textColor?: string
}>`
  font-family: "Outfit";
  margin-left: ${({margin}) => margin && '4px'};
  font-size: ${({fontSize}) => fontSize ?? 'inherit'};
  font-style: normal;
  font-weight: 500;
  background: #4B2828;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  @media screen and (max-width: 600px) {
    font-size: ${({adjustSize}) => adjustSize && '12px'};
  }
`

const HoverInlineText = ({
  text,
  maxCharacters = 20,
  margin = false,
  adjustSize = false,
  fontSize,
  textColor,
  link,
  ...rest
}: {
  text?: string
  maxCharacters?: number
  margin?: boolean
  adjustSize?: boolean
  fontSize?: string
  textColor?: string
  link?: boolean
}) => {
  const [showHover, setShowHover] = useState(false)

  if (!text) {
    return <span />
  }

  if (text.length > maxCharacters) {
    return (
      <Tooltip text={text} show={showHover}>
        <TextWrapper
          onMouseEnter={() => setShowHover(true)}
          onMouseLeave={() => setShowHover(false)}
          margin={margin}
          adjustSize={adjustSize}
          textColor={'#4B2828'}
          link={link}
          fontSize={fontSize}
          {...rest}
        >
          {' ' + text.slice(0, maxCharacters - 1) + '...'}
        </TextWrapper>
      </Tooltip>
    )
  }

  return (
    <TextWrapper
      margin={margin}
      adjustSize={adjustSize}
      link={link}
      fontSize={fontSize}
      textColor={'#4B2828'}
      {...rest}
    >
      {text}
    </TextWrapper>
  )
}

export default HoverInlineText
