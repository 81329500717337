import styled from "styled-components"
import { formatXpNumber, TextLinear, TreeIconPopup } from "./XpTitle";
import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import PaginatedTable from "pages/Xp/sections/table";
import { BREAKPOINTS } from "theme";
import { BASE_API, LeaderBoardType } from "..";
import axios from 'axios';
import { CountdownTimer } from "pages/Alpha/AlphaDetail";
import { Tooltip } from "antd";


const XpLeaderboardWrapper = styled.section`
    max-width: 1116px;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
        padding: 20px;
    }
`;

const Title = styled.h3`
    display: flex;
    text-align: center;
    font-family: Righteous;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px; /* 60px */
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        font-size: 40px;
        line-height: 50px;
        width: unset;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        margin-bottom: 0;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        background-size: cover;
        font-size: 32px;
        line-height: 40px;
    } 
`;

const XpLeaderboardInfo = styled.div`
`;

const RefInfoBox = styled.div`
    padding: 24px 32px;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(3.2727274894714355px);
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        padding: 12px 16px;
    }
`;
const RefInfoBoxTitle = styled.div`
    
    display: flex;
    align-items: center;
    gap: 5px;
    span {
        font-family: Urbanist;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 25.2px; /* 25.2px */
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        font-size: 16px;
        line-height: 18.5px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        display: grid;
    }
`;
const RefInfoStatBox = styled.div`
    margin-top: 24px;
    display: flex;
    gap: 40px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        flex-direction: column;
        gap: 12px;
    }
`;

const RefInfoVerticalSeperator = styled.div`
    width: 2px;
    background: rgba(239, 239, 228, 0.12);
    backdrop-filter: blur(4.800000190734863px);
`;

const RefInfoStatBoxLeft = styled.div`
    width: 50%;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        width: 100%;
    }
`;
const RefInfoStatBoxLeftInviteBox = styled.div`
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* align-content: center; */
    padding: 0 20px;
    padding-top: 7px;
    padding-bottom: 11px;
    height: 40px;
    background: rgba(239, 239, 228, 0.05);
`;

const RefInfoStatBoxRight = styled.div`
    width: 50%;
    display: flex;
    gap: 32px;
    padding-top: 14px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        width: 100%;
        padding-top: 0;
    }
`;

const RefInfoStatBoxRightItemBox = styled.div`
    display: grid;
    gap: 8px;
`


const RefInfoStatLabel = styled.span`
    color: rgba(239, 239, 228, 0.50);
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 17.5px */
`;
const RefInfoStatContent = styled.span`
    color: #4B2828;
    /* Righteous/Button */
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 18px */
    text-transform: uppercase;
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        font-size: 16px;
        line-height: 18.5px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        font-size: 14px !important;
        line-height: 125% !important;
    }
`;

const RefEpochBox = styled.div`
    display: flex;
    padding: 24px 32px;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(3.2727274894714355px);
    margin-top: 12px;
    column-gap: 50px;
    
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        column-gap: 32px;
        flex-wrap: wrap;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        padding: 12px 16px;
        row-gap: 12px;
    }
`;
const RefEpochBoxItem = styled.div`
    display: grid;
    gap: 24px;
    align-content: space-between;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        gap: 12px;
    }
`;
const RefEpochBoxItemTop = styled.div<{
    isHideOnMobile?: boolean
}>`
    display: grid;
    gap: 2px;
    min-width: 230px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        display: ${({ isHideOnMobile }) => isHideOnMobile ? 'none' : 'grid'};
    }
`;
const RefEpochBoxItemBottom = styled.div`
    display: grid;
    gap: 2px;
`;

const RewardInfoWrapper = styled.div<{
    gap?: string
}>`
    display: flex;
    gap: ${({ gap }) => gap ?? '8px'};
    position: relative;
    z-index: 10;
`;

export const RewardInfo = ({ musd, xmono, point, goldPoint, isTurnOffSepartor, gap }: { musd?: number; xmono?: number, point?: number; goldPoint?: number; isTurnOffSepartor?: boolean, gap?: string }) => {
    return <RewardInfoWrapper gap={gap}>
        <RefInfoStatContent style={{ fontSize: '16px', fontWeight: 600, display: 'flex', alignItems: 'center', gap: '5px' }}>
            {formatXpNumber(musd)}
            <Tooltip title="MUSD">
                <img style={{ cursor: 'pointer', width: '20px', height: '20px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)' }} src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/musd/musd.svg' />
            </Tooltip>
        </RefInfoStatContent>
        {
            !isTurnOffSepartor &&
            <RefInfoStatLabel style={{ fontSize: '16px', fontWeight: 600 }}>|</RefInfoStatLabel>
        }

        <RefInfoStatContent style={{ fontSize: '16px', fontWeight: 600, display: 'flex', alignItems: 'center', gap: '5px' }}>
            {formatXpNumber(xmono)}
            <Tooltip title="xMONO">
                <img style={{ cursor: 'pointer', width: '20px', height: '20px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)' }} src='https://raw.githubusercontent.com/Monoswap-Labs/monoswap-token-list/main/img/blast_sepolia/assets/0xa07aC8cDe2a98B189477b8e41F0c2Ea6CdDbC055/logo.png' />
            </Tooltip>
        </RefInfoStatContent>
        {
            !isTurnOffSepartor &&
            <RefInfoStatLabel style={{ fontSize: '16px', fontWeight: 600 }}>|</RefInfoStatLabel>
        }
        <RefInfoStatContent style={{ fontSize: '16px', fontWeight: 600, display: 'flex', alignItems: 'center', gap: '5px' }}>
            {formatXpNumber(point ?? 0)}
            <Tooltip title="Blast Point">
                <img style={{ cursor: 'pointer', width: '20px', height: '20px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)' }} src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/chain-logo/blast.png' />
            </Tooltip>
        </RefInfoStatContent>
        {
            !isTurnOffSepartor &&
            <RefInfoStatLabel style={{ fontSize: '16px', fontWeight: 600 }}>|</RefInfoStatLabel>
        }
        <RefInfoStatContent style={{ fontSize: '16px', fontWeight: 600, display: 'flex', alignItems: 'center', gap: '5px' }}>{formatXpNumber(goldPoint ?? 0)}
            <Tooltip title="Blast Gold">
                <img style={{ cursor: 'pointer', width: '20px', height: '20px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)' }} src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/xp/blast-gold-icon.png' />
            </Tooltip>
        </RefInfoStatContent>
    </RewardInfoWrapper>
}

const LeaderboardTableBox = styled.div`
    margin-top: 40px;
`;
const LeaderboardTableFilterBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    margin-bottom: 24px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        gap: 12px;
    }
`;
const LeaderboardTableFilterItem = styled.div<{
    isActived?: boolean
}>`
    color: ${({ isActived }) => isActived ? '#4B2828' : 'rgba(239, 239, 228, 0.50)'};
    font-family: Righteous;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 35px */
    cursor: ${({ isActived }) => isActived ? 'unset' : 'pointer'};
    display: flex;
    align-items: center;

    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        font-size: 20px;
        line-height: 125%; /* 35px */
    }

    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        font-size: 18px;
        line-height: 125%; /* 35px */
    }

    &:hover{
        color: #4B2828;
    }
`;

export type RewardType = {
    musd: number;
    mono: number;
    xmono: number;
    point: number;
    goldPoint: number;
}
type XpLeaderboardType = {
    currentEpoch: {
        seq: number,
        endDate: string
    };
    totalDistributed: RewardType,
    nextTotalDistributed: RewardType,
    myPendingReward?: RewardType,
    list: [{
        rank: number;
        address: string;
        xp: number;
        reward?: RewardType
    }],
    myTop: {
        rank: number, xp: number, myRefXp: number, invitedCnt: number, reward?: RewardType
    },
    refCode: string
}

export default function XpLeaderboard({ onOpenTaskInfo }: { onOpenTaskInfo: (type: LeaderBoardType) => void }) {
    const { account, chainId } = useWeb3React();

    const [hasCopied, setHasCopied] = useState<boolean>(false)
    const [refLink, setRefLink] = useState<string>('');

    const handleCopyRefLink = () => {
        if (!chainId) return;
        navigator.clipboard.writeText(refLink)
        setHasCopied(true)
        setTimeout(() => {
            setHasCopied(false)
        }, 5000)
    }

    const [tableType, setTableType] = useState<LeaderBoardType>('FARM');
    const [xpLeaderboard, setXpLeaderboard] = useState<XpLeaderboardType>();
    let isLoadingLeaderboard = false;
    const loadLeaderboardData = (type: LeaderBoardType) => {
        if (isLoadingLeaderboard) return;
        isLoadingLeaderboard = true;
        setTableType(type);
        axios.get(`${BASE_API}/monoswap/api/v1/leaderboard/leaderboard?type=${type}&address=${account ?? ''}`)
            .then(({ data }: { data: XpLeaderboardType }) => {
                setRefLink(`${window.location.protocol}//${window.location.host}/#/xp/ref=${data.refCode}`);
                setXpLeaderboard(data);
            })
            .catch(err => { })
            .finally(() => isLoadingLeaderboard = false);
    }

    useEffect(() => {
        loadLeaderboardData('FARM');
    }, [account])

    return <XpLeaderboardWrapper id="xp-leaderboard">
        <XpLeaderboardInfo>
            <Title>
                <TextLinear>
                    Leader
                </TextLinear>
                <span>
                    board
                </span>
            </Title>

            {
                account &&
                <RefInfoBox>
                    <RefInfoBoxTitle>
                        <TextLinear style={{ fontWeight: 600 }}>Earn extra MUSD &amp; Blast Points</TextLinear>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <span>from your friends and their referral</span>
                            <img onClick={() => onOpenTaskInfo('REF')} style={{ width: '16px', height: '16p', cursor: 'pointer' }} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/xp/more-info-icon.png" />
                        </div>
                    </RefInfoBoxTitle>

                    <RefInfoStatBox>
                        <RefInfoStatBoxLeft>
                            <RefInfoStatLabel>
                                Invitation Link
                            </RefInfoStatLabel>
                            <RefInfoStatBoxLeftInviteBox>
                                <RefInfoStatContent style={{ textTransform: 'lowercase' }}>
                                    {chainId && refLink}
                                </RefInfoStatContent>
                                {
                                    refLink && <>
                                        {hasCopied ? (
                                            <span
                                                style={{
                                                    fontFamily: 'Urbanist',
                                                    background: 'linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)',
                                                    backgroundClip: 'text',
                                                    WebkitTextFillColor: 'transparent',
                                                    WebkitBackgroundClip: 'text',
                                                }}
                                            >
                                                Copied
                                            </span>
                                        ) : (
                                            <svg
                                                onClick={handleCopyRefLink}
                                                style={{ cursor: 'pointer' }}
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M4 16C2.9 16 2 15.1 2 14V4C2 2.9 2.9 2 4 2H14C15.1 2 16 2.9 16 4M10 8H20C21.1046 8 22 8.89543 22 10V20C22 21.1046 21.1046 22 20 22H10C8.89543 22 8 21.1046 8 20V10C8 8.89543 8.89543 8 10 8Z"
                                                    stroke="url(#paint0_linear_25_140807)"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <defs>
                                                    <linearGradient
                                                        id="paint0_linear_25_140807"
                                                        x1="2"
                                                        y1="11.083"
                                                        x2="22"
                                                        y2="11.083"
                                                        gradientUnits="userSpaceOnUse"
                                                    >
                                                        <stop stopColor="#68FFFF" />
                                                        <stop offset="1" stopColor="#68FF9B" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        )}
                                    </>
                                }

                            </RefInfoStatBoxLeftInviteBox>
                        </RefInfoStatBoxLeft>
                        <RefInfoVerticalSeperator />

                        <RefInfoStatBoxRight>
                            <RefInfoStatBoxRightItemBox>
                                <RefInfoStatLabel>
                                    Invited
                                </RefInfoStatLabel>
                                <RefInfoStatContent>
                                    {
                                        Number(xpLeaderboard?.myTop?.invitedCnt ?? 0)
                                    }
                                </RefInfoStatContent>
                            </RefInfoStatBoxRightItemBox>
                            <RefInfoStatBoxRightItemBox>
                                <RefInfoStatLabel>
                                    Referral XP
                                </RefInfoStatLabel>
                                <RefInfoStatContent>
                                    {
                                        Number(xpLeaderboard?.myTop?.myRefXp ?? 0)
                                    }
                                </RefInfoStatContent>
                            </RefInfoStatBoxRightItemBox>

                        </RefInfoStatBoxRight>

                    </RefInfoStatBox>

                </RefInfoBox>
            }

            <RefEpochBox>
                <RefEpochBoxItem>
                    <RefEpochBoxItemTop>
                        <RefInfoStatLabel>Current Epoch</RefInfoStatLabel>
                        <RefInfoStatContent>#{xpLeaderboard?.currentEpoch?.seq ?? '~'}</RefInfoStatContent>
                    </RefEpochBoxItemTop>

                    <RefEpochBoxItemBottom>
                        <RefInfoStatLabel>Total Distributed</RefInfoStatLabel>
                        <RewardInfo musd={xpLeaderboard?.totalDistributed?.musd}
                            xmono={xpLeaderboard?.totalDistributed?.xmono}
                            point={xpLeaderboard?.totalDistributed?.point}
                            goldPoint={xpLeaderboard?.totalDistributed?.goldPoint} />
                    </RefEpochBoxItemBottom>
                </RefEpochBoxItem>

                <RefEpochBoxItem>
                    <RefEpochBoxItemTop>
                        <RefInfoStatLabel>Next Epoch</RefInfoStatLabel>
                        <RefInfoStatContent style={{ fontSize: '16px' }}>
                            <TextLinear style={{ display: 'flex' }}>
                                <CountdownTimer target={xpLeaderboard?.currentEpoch.endDate ?? 0} />
                            </TextLinear>
                        </RefInfoStatContent>
                    </RefEpochBoxItemTop>

                    <RefEpochBoxItemBottom>
                        <RefInfoStatLabel>Next Rewards</RefInfoStatLabel>
                        <RewardInfo musd={xpLeaderboard?.nextTotalDistributed?.musd}
                            xmono={xpLeaderboard?.nextTotalDistributed?.xmono}
                            point={xpLeaderboard?.nextTotalDistributed?.point}
                            goldPoint={xpLeaderboard?.nextTotalDistributed?.goldPoint} />
                    </RefEpochBoxItemBottom>
                </RefEpochBoxItem>

                <RefEpochBoxItem>
                    <RefEpochBoxItemTop isHideOnMobile={true} />
                    <RefEpochBoxItemBottom>
                        <RefInfoStatLabel >Your Pending Rewards</RefInfoStatLabel>
                        <RewardInfo musd={xpLeaderboard?.myPendingReward?.musd}
                            xmono={xpLeaderboard?.myPendingReward?.xmono}
                            point={xpLeaderboard?.myPendingReward?.point}
                            goldPoint={xpLeaderboard?.myPendingReward?.goldPoint} />
                    </RefEpochBoxItemBottom>
                </RefEpochBoxItem>

            </RefEpochBox>

            <LeaderboardTableBox className="my-claimed-xp">
                <LeaderboardTableFilterBox>
                    <LeaderboardTableFilterItem style={{ gap: '5px' }} isActived={tableType == 'FARM'} onClick={() => loadLeaderboardData('FARM')}>
                        <span>
                            Farm
                        </span>
                        <TreeIconPopup width="22px" placement="topRight" />
                    </LeaderboardTableFilterItem>
                    <LeaderboardTableFilterItem isActived={tableType == 'SWAP'} onClick={() => loadLeaderboardData('SWAP')}>
                        <span>
                            Swap
                        </span>
                    </LeaderboardTableFilterItem>
                    <LeaderboardTableFilterItem isActived={tableType == 'MUSD'} onClick={() => loadLeaderboardData('MUSD')}>
                        <span>
                            MUSD
                        </span>
                    </LeaderboardTableFilterItem>
                    {/* <LeaderboardTableFilterItem isActived={tableType == 'XMONO'} onClick={() => loadLeaderboardData('XMONO')}>
                        <span>
                            xMONO
                        </span>
                    </LeaderboardTableFilterItem> */}
                    <LeaderboardTableFilterItem isActived={tableType == 'REF'} onClick={() => loadLeaderboardData('REF')}>
                        <span>
                            Referral
                        </span>
                    </LeaderboardTableFilterItem>
                </LeaderboardTableFilterBox>
                <PaginatedTable data={xpLeaderboard?.list ?? []} myXp={xpLeaderboard?.myTop} maxSize={['REF', 'FARM'].includes(tableType) ? 500 : 100} />

            </LeaderboardTableBox>
        </XpLeaderboardInfo>
    </XpLeaderboardWrapper>
}