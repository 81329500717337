import { BigNumber } from '@ethersproject/bignumber'
import type { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { TraceEvent } from '@uniswap/analytics'
import { BrowserEvent, ElementName, EventName } from '@uniswap/analytics-events'
import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { sendEvent } from 'components/analytics'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
import { useCallback, useEffect, useState } from 'react'
import { Plus } from 'react-feather'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Text } from 'rebass'
import { useTheme } from 'styled-components/macro'

import { ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import { BlueCard, LightCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row, { RowBetween, RowFlat } from '../../components/Row'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import { ZERO_PERCENT } from '../../constants/misc'
import { WRAPPED_NATIVE_CURRENCY } from '../../constants/tokens'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useV2RouterContract } from '../../hooks/useContract'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { PairState } from '../../hooks/useV2Pairs'
import { useToggleWalletModal } from '../../state/application/hooks'
import { Field } from '../../state/mint/actions'
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from '../../state/mint/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TransactionType } from '../../state/transactions/types'
import { useIsExpertMode, useUserSlippageToleranceWithDefault } from '../../state/user/hooks'
import { ThemedText } from '../../theme'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { calculateSlippageAmount } from '../../utils/calculateSlippageAmount'
import { currencyId } from '../../utils/currencyId'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import AppBody from '../AppBody'
import { Dots, Wrapper } from '../Pool/styleds'
import { ConfirmAddModalBottom } from './ConfirmAddModalBottom'
import { PoolPriceBar } from './PoolPriceBar'
import './addV2.css'

import SettingsTab from '../../components/Settings'
import { NotchedButtonBolderNew } from 'components/Button/NotchedButton'
import { NotchedButtonFill } from 'components/Button'
import axios, { Method } from 'axios'
import { isSupportedChain } from "../../constants/chains";
import { PositionPageUnsupportedContent } from "../Pool/PositionPage";

const swaptop =
    'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/pool/liquidheader.png'
const reload = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/swap/reload.png'
const DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)

export default function AddLiquidityWrapper() {
    const { chainId } = useWeb3React()
    if (isSupportedChain(chainId)) {
        return <AddLiquidity />
    } else {
        return <PositionPageUnsupportedContent />
    }
}

function AddLiquidity() {
    const { currencyIdA, currencyIdB } = useParams<{ currencyIdA?: string; currencyIdB?: string }>()
    const navigate = useNavigate()
    const { account, chainId, provider } = useWeb3React()

    const theme = useTheme()

    const currencyA = useCurrency(currencyIdA)
    const currencyB = useCurrency(currencyIdB)

    const wrappedNativeCurrency = chainId ? WRAPPED_NATIVE_CURRENCY[chainId] : undefined

    const oneCurrencyIsWETH = Boolean(
        chainId &&
        wrappedNativeCurrency &&
        ((currencyA && currencyA.equals(wrappedNativeCurrency)) || (currencyB && currencyB.equals(wrappedNativeCurrency)))
    )

    const toggleWalletModal = useToggleWalletModal() // toggle wallet when disconnected

    const expertMode = useIsExpertMode()

    // mint state
    const { independentField, typedValue, otherTypedValue } = useMintState()
    const {
        dependentField,
        currencies,
        pair,
        pairState,
        currencyBalances,
        parsedAmounts,
        price,
        noLiquidity,
        liquidityMinted,
        poolTokenPercentage,
        error,
    } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)

    const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

    const isValid = !error

    // modal and loading
    const [showConfirm, setShowConfirm] = useState<boolean>(false)
    const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

    // txn values
    const deadline = useTransactionDeadline() // custom from users settings
    const allowedSlippage = useUserSlippageToleranceWithDefault(DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE) // custom from users
    const [txHash, setTxHash] = useState<string>('')

    // get formatted amounts
    const formattedAmounts = {
        [independentField]: typedValue,
        [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
    }

    // get the max amounts user can add
    const maxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
        (accumulator, field) => {
            return {
                ...accumulator,
                [field]: maxAmountSpend(currencyBalances[field]),
            }
        },
        {}
    )

    const atMaxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
        (accumulator, field) => {
            return {
                ...accumulator,
                [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
            }
        },
        {}
    )

    const router = useV2RouterContract()

    // check whether the user has approved the router on the tokens
    const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], router?.address)
    const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], router?.address)

    const addTransaction = useTransactionAdder()

    async function onAdd() {
        if (!chainId || !provider || !account || !router) return

        const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
        if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB || !deadline) {
            return
        }
        setShowConfirm(true)
        const amountsMin = {
            [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? ZERO_PERCENT : allowedSlippage)[0],
            [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? ZERO_PERCENT : allowedSlippage)[0],
        }

        let estimate,
            method: (...args: any) => Promise<TransactionResponse>,
            args: Array<string | string[] | number>,
            value: BigNumber | null
        if (currencyA.isNative || currencyB.isNative) {
            const tokenBIsETH = currencyB.isNative
            estimate = router.estimateGas.addLiquidityETH
            method = router.addLiquidityETH
            args = [
                (tokenBIsETH ? currencyA : currencyB)?.wrapped?.address ?? '', // token
                (tokenBIsETH ? parsedAmountA : parsedAmountB).quotient.toString(), // token desired
                amountsMin[tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
                amountsMin[tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // eth min
                account,
                deadline.toHexString(),
            ]
            value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).quotient.toString())
        } else {
            estimate = router.estimateGas.addLiquidity
            method = router.addLiquidity
            args = [
                currencyA?.wrapped?.address ?? '',
                currencyB?.wrapped?.address ?? '',
                parsedAmountA.quotient.toString(),
                parsedAmountB.quotient.toString(),
                amountsMin[Field.CURRENCY_A].toString(),
                amountsMin[Field.CURRENCY_B].toString(),
                account,
                deadline.toHexString(),
            ]
            value = null
        }

        setAttemptingTxn(true)
        await estimate(...args, value ? { value } : {})
            .then((estimatedGasLimit) =>
                method(...args, {
                    ...(value ? { value } : {}),
                    gasLimit: calculateGasMargin(estimatedGasLimit),
                }).then((response) => {
                    setAttemptingTxn(false)

                    addTransaction(response, {
                        type: TransactionType.ADD_LIQUIDITY_V2_POOL,
                        baseCurrencyId: currencyId(currencyA),
                        expectedAmountBaseRaw: parsedAmounts[Field.CURRENCY_A]?.quotient.toString() ?? '0',
                        quoteCurrencyId: currencyId(currencyB),
                        expectedAmountQuoteRaw: parsedAmounts[Field.CURRENCY_B]?.quotient.toString() ?? '0',
                    })

                    setTxHash(response.hash)

                    sendEvent({
                        category: 'Liquidity',
                        action: 'Add',
                        label: [currencies[Field.CURRENCY_A]?.symbol, currencies[Field.CURRENCY_B]?.symbol].join('/'),
                    })
                })
            )
            .catch((error) => {
                setAttemptingTxn(false)
                // we only care if the error is something _other_ than the user rejected the tx
                if (error?.code !== 4001) {
                    console.error(error)
                }
            })
    }

    const modalHeader = () => {
        return noLiquidity ? (
            <AutoColumn gap="20px">
                <LightCard mt="20px">
                    <RowFlat>
                        <div style={{ marginLeft: '20px' }}>
                            <DoubleCurrencyLogo
                                currency0={currencies[Field.CURRENCY_A]}
                                currency1={currencies[Field.CURRENCY_B]}
                                size={30}
                            />
                        </div>

                        <Text fontSize="32px" fontWeight={500} lineHeight="36px">
                            {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol}
                        </Text>
                    </RowFlat>
                </LightCard>
            </AutoColumn>
        ) : (
            <AutoColumn gap="20px">
                <RowFlat style={{ marginTop: '20px' }}>
                    <div style={{ marginLeft: '20px' }}>
                        <DoubleCurrencyLogo
                            currency0={currencies[Field.CURRENCY_A]}
                            currency1={currencies[Field.CURRENCY_B]}
                            size={30}
                        />
                    </div>

                    <Text fontSize="32px" fontWeight={500} lineHeight="36px">
                        {liquidityMinted?.toSignificant(6)}
                    </Text>
                </RowFlat>
                <Row>
                    <Text fontSize="24px">
                        {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol + ' Pool Tokens'}
                    </Text>
                </Row>
                <ThemedText.DeprecatedItalic fontSize={12} textAlign="left" padding="8px 0 0 0 ">
                    <Trans>
                        Output is estimated. If the price changes by more than {allowedSlippage.toSignificant(4)}% your
                        transaction
                        will revert.
                    </Trans>
                </ThemedText.DeprecatedItalic>
            </AutoColumn>
        )
    }

    const modalBottom = () => {
        return (
            <ConfirmAddModalBottom
                price={price}
                currencies={currencies}
                parsedAmounts={parsedAmounts}
                noLiquidity={noLiquidity}
                onAdd={onAdd}
                poolTokenPercentage={poolTokenPercentage}
            />
        )
    }

    const pendingText = (
        <Trans>
            Supplying {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} {currencies[Field.CURRENCY_A]?.symbol} and{' '}
            {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} {currencies[Field.CURRENCY_B]?.symbol}
        </Trans>
    )

    const handleCurrencyASelect = useCallback(
        (currencyA: Currency) => {
            const newCurrencyIdA = currencyId(currencyA)
            if (newCurrencyIdA === currencyIdB) {
                navigate(`/add/v2/${currencyIdB}/${currencyIdA}`)
            } else {
                navigate(`/add/v2/${newCurrencyIdA}/${currencyIdB}`)
            }
        },
        [currencyIdB, navigate, currencyIdA]
    )
    const handleCurrencyBSelect = useCallback(
        (currencyB: Currency) => {
            const newCurrencyIdB = currencyId(currencyB)
            if (currencyIdA === newCurrencyIdB) {
                if (currencyIdB) {
                    navigate(`/add/v2/${currencyIdB}/${newCurrencyIdB}`)
                } else {
                    navigate(`/add/v2/${newCurrencyIdB}`)
                }
            } else {
                navigate(`/add/v2/${currencyIdA ? currencyIdA : 'ETH'}/${newCurrencyIdB}`)
            }
        },
        [currencyIdA, navigate, currencyIdB]
    )

    const handleDismissConfirmation = useCallback(() => {
        setShowConfirm(false)
        // if there was a tx hash, we want to clear the input
        if (txHash) {
            onFieldAInput('')
        }
        setTxHash('')
    }, [onFieldAInput, txHash])

    const { pathname } = useLocation()
    const isCreate = pathname.includes('/create')

    const addIsUnsupported = useIsSwapUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)
    const [tabSelector, setTabSelector] = useState(2)
    const headers = {
        Authorization: `Bearer auth=gmeow`,
        'Content-Type': 'application/json',
    }

    const [priceInputFiate, setPriceInputFiate] = useState<string | undefined>()
    const [priceOnputFiate, setPriceOnputFiate] = useState<string | undefined>()
    // show price

    // let addressTokenInput = currencyIdA as any
    // let addressTokenOutput = currencyIdB as any
    useEffect(() => {
        const fetchTokenPrice = async () => {
            if (currencyIdA !== null) {
                try {
                    let addressValue = currencyIdA === 'ETH' ? '0x4300000000000000000000000000000000000004' : currencyIdA
                    const res = await axios({
                        method: 'get',
                        url: `https://api.geckoterminal.com/api/v2/simple/networks/blast/token_price/${addressValue}`,
                        headers,
                    })
                    if (res) {
                        const price = res ? (Object.values(res.data.data.attributes.token_prices)[0] as string) : '0'
                        setPriceInputFiate(price)
                    }
                } catch (error) {
                    console.log('Error:', error)
                }
            }
        }

        fetchTokenPrice()
    }, [account, chainId, currencyIdA])
    useEffect(() => {
        const fetchTokenPrice = async () => {
            if (currencyIdB !== null) {
                try {
                    let addressValue = currencyIdB === 'ETH' ? '0x4300000000000000000000000000000000000004' : currencyIdB
                    const res = await axios({
                        method: 'get',
                        url: `https://api.geckoterminal.com/api/v2/simple/networks/blast/token_price/${addressValue}`,
                        headers,
                    })
                    if (res) {
                        const price = res ? (Object.values(res.data.data.attributes.token_prices)[0] as string) : '0'
                        setPriceOnputFiate(price)
                    }
                } catch (error) {
                    console.log('Error:', error)
                }
            }
        }

        fetchTokenPrice()
    }, [account, chainId, currencyIdB])

    return (
        <>
            <div className="pool-top-container-container">
                <div className="tab-pool-selector">
                    <NotchedButtonBolderNew cursor={`pointer`}>
                        <div
                            className={`wrapper-display-style tab-pool-selector-text ${tabSelector === 1 ? 'tab-pool-active wrapper-display-style-active' : ''}`}
                            onClick={() => {
                                navigate('/add')
                            }}
                        >
                            <div className={`wrapper-tab-pool-selector-text`}>
                                <div style={{ width: "24px", height: "24px" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none">
                                        <g clipPath="url(#clip0_160_1026)">
                                            <path
                                                d="M6.80005 11C6.27416 11.9122 5.99756 12.9467 5.99805 13.9996C5.99853 15.0525 6.27608 16.0867 6.80282 16.9984C7.32955 17.9101 8.08692 18.6671 8.99885 19.1934C9.91077 19.7197 10.9451 19.9968 11.998 19.9968C13.051 19.9968 14.0853 19.7197 14.9972 19.1934C15.9092 18.6671 16.6665 17.9101 17.1933 16.9984C17.72 16.0867 17.9976 15.0525 17.998 13.9996C17.9985 12.9467 17.7219 11.9122 17.196 11L11.999 3L6.79905 11H6.80005Z"
                                                stroke="#A59185" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_160_1026">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                Add Liquidity
                            </div>
                        </div>
                    </NotchedButtonBolderNew>
                    <NotchedButtonBolderNew cursor={`pointer`}>
                        <div
                            className={`wrapper-display-style tab-pool-selector-text ${tabSelector === 2 ? 'tab-pool-active wrapper-display-style-active' : ''}`}
                            onClick={() => {
                                setTabSelector(2)
                            }}
                        >
                            <div className={`wrapper-tab-pool-selector-text`}>
                                <div style={{ width: "24px", height: "24px" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none">
                                        <g clipPath="url(#clip0_160_1029)">
                                            <path
                                                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                                                stroke="#4B2828" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round" />
                                            <path
                                                d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                                                stroke="#4B2828" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round" />
                                            <path d="M12 2V4" stroke="#4B2828" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round" />
                                            <path d="M12 20V22" stroke="#4B2828" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round" />
                                            <path d="M20 12H22" stroke="#4B2828" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round" />
                                            <path d="M2 12H4" stroke="#4B2828" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_160_1029">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <p>Positions</p>
                            </div>
                        </div>
                    </NotchedButtonBolderNew>
                    {/* <NotchedButtonBolderNew w="100%" h="100%" borderColor={'rgba(239, 239, 228, 0.5)'}>
            <div className={`tab-pool-selector-text ${tabSelector === 3 ? 'tab-pool-active' : ''}`}>Add LP Vault</div>
          </NotchedButtonBolderNew> */}
                </div>
                <div className={`wrapper-pool-top-and-body`}>
                    <div className="pool-top-container">
                        <div className="swap-top-container">
                            <div className="swap-top-left">
                                <img src={swaptop} alt="swap image" />
                                <div className="swap-top-div">
                                    <p>V2 Liquidity</p>
                                    <p>Add liquidity to the AMM and receive protocol incentives in MUSD</p>
                                </div>
                            </div>
                            <div className="swap-top-right">
                                <SettingsTab placeholderSlippage={DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE} />
                            </div>
                        </div>
                    </div>
                    <div className="pool-body-container-page">
                        {/* <AddRemoveTabs creating={isCreate} adding={true} defaultSlippage={DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE} /> */}
                        <div className="pool-body-mid-container">
                            <div className="pool-body-mid-left">
                                <Wrapper>
                                    <TransactionConfirmationModal
                                        isOpen={showConfirm}
                                        onDismiss={handleDismissConfirmation}
                                        attemptingTxn={attemptingTxn}
                                        hash={txHash}
                                        content={() => (
                                            <ConfirmationModalContent
                                                title={noLiquidity ? <Trans>You are creating a pool</Trans> :
                                                    <Trans>You will receive</Trans>}
                                                onDismiss={handleDismissConfirmation}
                                                topContent={modalHeader}
                                                bottomContent={modalBottom}
                                            />
                                        )}
                                        pendingText={pendingText}
                                        currencyToAdd={pair?.liquidityToken}
                                    />
                                    <AutoColumn gap="20px">
                                        {noLiquidity ||
                                            (isCreate ? (
                                                <ColumnCenter>
                                                    <BlueCard>
                                                        <AutoColumn gap="10px">
                                                            <ThemedText.DeprecatedLink fontWeight={600}
                                                                color="deprecated_primaryText1">
                                                                <Trans>You are the first liquidity provider.</Trans>
                                                            </ThemedText.DeprecatedLink>
                                                            <ThemedText.DeprecatedLink fontWeight={400}
                                                                color="deprecated_primaryText1">
                                                                <Trans>The ratio of tokens you add will set the price of
                                                                    this pool.</Trans>
                                                            </ThemedText.DeprecatedLink>
                                                            <ThemedText.DeprecatedLink fontWeight={400}
                                                                color="deprecated_primaryText1">
                                                                <Trans>Once you are happy with the rate click supply to
                                                                    review.</Trans>
                                                            </ThemedText.DeprecatedLink>
                                                        </AutoColumn>
                                                    </BlueCard>
                                                </ColumnCenter>
                                            ) : (
                                                <ColumnCenter>
                                                    <BlueCard>
                                                        <AutoColumn gap="10px">
                                                            <ThemedText.DeprecatedLink fontWeight={400}
                                                                color="deprecated_primaryText1">
                                                                <Trans>
                                                                    <b>
                                                                        <Trans>Tip:</Trans>
                                                                    </b>{' '}
                                                                    When you add liquidity, you will receive pool tokens
                                                                    representing your position. These
                                                                    tokens automatically earn fees proportional to your
                                                                    share of the pool, and can be
                                                                    redeemed at any time.
                                                                </Trans>
                                                            </ThemedText.DeprecatedLink>
                                                        </AutoColumn>
                                                    </BlueCard>
                                                </ColumnCenter>
                                            ))}
                                        <CurrencyInputPanel
                                            value={formattedAmounts[Field.CURRENCY_A]}
                                            onUserInput={onFieldAInput}
                                            onMax={() => {
                                                onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
                                            }}
                                            onCurrencySelect={handleCurrencyASelect}
                                            showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                                            currency={currencies[Field.CURRENCY_A] ?? null}
                                            id="add-liquidity-input-tokena"
                                            showCommonBases
                                            customeFiatValue={priceInputFiate}
                                        />
                                        <ColumnCenter>
                                            <Plus size="16" color={theme.deprecated_text2} />
                                        </ColumnCenter>
                                        <CurrencyInputPanel
                                            value={formattedAmounts[Field.CURRENCY_B]}
                                            onUserInput={onFieldBInput}
                                            onCurrencySelect={handleCurrencyBSelect}
                                            onMax={() => {
                                                onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
                                            }}
                                            showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                                            currency={currencies[Field.CURRENCY_B] ?? null}
                                            id="add-liquidity-input-tokenb"
                                            showCommonBases
                                            customeFiatValue={priceOnputFiate}
                                        />
                                        {currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
                                            <>
                                                <LightCard padding="0px">
                                                    <RowBetween padding="1rem">
                                                        <ThemedText.DeprecatedSubHeader fontWeight={500} fontSize={14}>
                                                            {noLiquidity ? (
                                                                <Trans>Initial prices and pool share</Trans>
                                                            ) : (
                                                                <Trans>Prices and pool share</Trans>
                                                            )}
                                                        </ThemedText.DeprecatedSubHeader>
                                                    </RowBetween>{' '}
                                                    <LightCard padding="1rem">
                                                        <PoolPriceBar
                                                            currencies={currencies}
                                                            poolTokenPercentage={poolTokenPercentage}
                                                            noLiquidity={noLiquidity}
                                                            price={price}
                                                        />
                                                    </LightCard>
                                                </LightCard>
                                            </>
                                        )}

                                        {addIsUnsupported ? (
                                            <NotchedButtonFill disabled={true}>
                                                <ThemedText.DeprecatedMain mb="4px">
                                                    <Trans>Unsupported Asset</Trans>
                                                </ThemedText.DeprecatedMain>
                                            </NotchedButtonFill>
                                        ) : !account ? (
                                            <TraceEvent
                                                events={[BrowserEvent.onClick]}
                                                name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
                                                properties={{ received_swap_quote: false }}
                                                element={ElementName.CONNECT_WALLET_BUTTON}
                                            >
                                                <NotchedButtonFill onClick={toggleWalletModal}>
                                                    <Trans>Connect Wallet</Trans>
                                                </NotchedButtonFill>
                                            </TraceEvent>
                                        ) : (
                                            <AutoColumn gap="md">
                                                {(approvalA === ApprovalState.NOT_APPROVED ||
                                                    approvalA === ApprovalState.PENDING ||
                                                    approvalB === ApprovalState.NOT_APPROVED ||
                                                    approvalB === ApprovalState.PENDING) &&
                                                    isValid && (
                                                        <RowBetween>
                                                            {approvalA !== ApprovalState.APPROVED && (
                                                                <NotchedButtonFill
                                                                    onClick={approveACallback}
                                                                    disabled={approvalA === ApprovalState.PENDING}
                                                                    width={approvalB !== ApprovalState.APPROVED ? '48%' : '100%'}
                                                                >
                                                                    {approvalA === ApprovalState.PENDING ? (
                                                                        <Dots>
                                                                            <Trans>Approving {currencies[Field.CURRENCY_A]?.symbol}</Trans>
                                                                        </Dots>
                                                                    ) : (
                                                                        <Trans>Approve {currencies[Field.CURRENCY_A]?.symbol}</Trans>
                                                                    )}
                                                                </NotchedButtonFill>
                                                            )}
                                                            {approvalB !== ApprovalState.APPROVED && (
                                                                <NotchedButtonFill
                                                                    onClick={approveBCallback}
                                                                    disabled={approvalB === ApprovalState.PENDING}
                                                                    width={approvalA !== ApprovalState.APPROVED ? '48%' : '100%'}
                                                                >
                                                                    {approvalB === ApprovalState.PENDING ? (
                                                                        <Dots>
                                                                            <Trans>Approving {currencies[Field.CURRENCY_B]?.symbol}</Trans>
                                                                        </Dots>
                                                                    ) : (
                                                                        <Trans>Approve {currencies[Field.CURRENCY_B]?.symbol}</Trans>
                                                                    )}
                                                                </NotchedButtonFill>
                                                            )}
                                                        </RowBetween>
                                                    )}
                                                <ButtonError
                                                    onClick={() => {
                                                        expertMode ? onAdd() : setShowConfirm(true)
                                                    }}
                                                    disabled={
                                                        !isValid || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED
                                                    }
                                                    error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
                                                >
                                                    <Text fontSize={20} fontWeight={500}>
                                                        {error ?? <Trans>Supply</Trans>}
                                                    </Text>
                                                </ButtonError>
                                            </AutoColumn>
                                        )}
                                    </AutoColumn>
                                </Wrapper>
                            </div>
                            <div className="pool-body-mid-right">
                                {!addIsUnsupported ? (
                                    pair && !noLiquidity && pairState !== PairState.INVALID ? (
                                        <AutoColumn style={{ minWidth: '20rem', width: '100%', marginTop: '1rem' }}>
                                            <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
                                        </AutoColumn>
                                    ) : null
                                ) : (
                                    <UnsupportedCurrencyFooter
                                        show={addIsUnsupported}
                                        currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <SwitchLocaleLink /> */}
        </>
    )
}
