import MeowButton from 'components/Button/MeowButton'
import styled from 'styled-components'
import {BREAKPOINTS, ExternalLink} from 'theme'
import {useEffect, useState} from "react";
import TextScramble from "./components/TextScarmble";

const WrapperSection2 = styled.section`
    width: 100%;
    margin: 0px auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        margin-bottom: 20px;
    }
`

const ContentTablet = styled.div`
    width: 1170px;
    height: 256px;
    background-image: url("https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section5/img.png");
    background-size: cover;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        display: none;
    }
`
const ContentInner = styled.div`
    padding: 57px 0px 56px 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2 {
        color: #FFF9E1;
        -webkit-text-stroke-width: 3px;
        -webkit-text-stroke-color: #723507;
        font-family: DynaPuff;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 40px */
        margin: 0;
    }
`

const WrapperImage = styled.div`
    width: 212px;
    height: 72px;
`

const ContentMobile = styled.div`
    display: none;
    position: relative;
    width: 335px;
    height: 526px;
    margin: auto;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        display: block;
    }
`
const ButtonWrapper = styled.div`
    position: absolute;
    top: 50px;
    z-index: 2;
`
export default function Section5() {
    const phrases = [
        'Join the Gmeow Guild',
    ];

    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index == phrases.length - 1) {
            return;
        }

        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        }, 4000);

        return () => clearInterval(interval);
    }, [index, phrases]);
    return (
        <>
            <WrapperSection2>
                <ContentTablet>
                    <ContentInner>
                        <TextScramble text={phrases[index]}/>
                        <ExternalLink href="https://discord.com/invite/QcTmqgaUK6" target="_blank">
                            <WrapperImage>
                                <img width={'100%'} height={'100%'}
                                     src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section5/img_1.png"
                                     alt="img_1"/>
                            </WrapperImage>
                        </ExternalLink>
                    </ContentInner>
                </ContentTablet>

                <ContentMobile>
                    <img
                        src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/landing/section5/mobile-bg.png"
                        alt="gmeowfi"/>
                </ContentMobile>
            </WrapperSection2>
        </>
    )
}
