import { ethers } from "ethers"

function getGasPriceSetting(gasLimit: number | ethers.BigNumberish) {
    try {
        gasLimit = ethers.BigNumber.from(gasLimit)
        const gweiAmount = Number(localStorage.getItem('gas_mining_setting') ?? '0');
        if (gweiAmount === 0) return undefined;
        return ethers.utils.parseUnits(String(gweiAmount), "gwei").div(gasLimit)
    }
    catch (err) {
        console.log(err);
    }
    return undefined
}

export {
    getGasPriceSetting
}