import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import { MONO_ADDRESSES_MAP } from '../../constants/addresses'
import { useWeb3React } from '@web3-react/core'
import { useCurrency, useToken, useTokenLogo } from '../../hooks/Tokens'
import { BREAKPOINTS, ThemedText } from '../../theme'
import { DEFAULT_CHAINID } from '../../constants/chains'
import styled, { css, useTheme } from 'styled-components/macro'
import { AutoColumn } from '../../components/Column'
import { AlertTriangle } from 'react-feather'
import { useAlphaTvlMap } from 'state/alphapool/hooks'
import { PoolV3Info, useAlphaStore } from '../../hooks/useAlphaStore'
import { useToggleWalletModal } from 'state/application/hooks'
import useTokenPrice from 'hooks/useTokenPrice'
import FarmingListItem from './FarmingListItem'
import {
  LoadingOutlined
} from "@ant-design/icons"
import "./farming.css"
import { GMEOW_API_BASE_URL } from 'utils/env'

const reload = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/swap/reload.png'

export function useMultipleCurrencies(currencyIds: any) {
  const currencies = currencyIds?.map(({ currencyIdA, currencyIdB, incentiveToken }: any) => {
    const currencyA = useCurrency(currencyIdA)
    const currencyB = useCurrency(currencyIdB)
    const incentive = useCurrency(incentiveToken)
    return { currencyA, currencyB, incentive }
  })

  return currencies
}


const PageWrapper = styled.div`
  padding: 28px 8px 0px;
  width: 80%;

  @media (max-width: 768px) {
    width: 90%;
  }
`

const MainContentWrapper = styled.main`
  background: rgba(239, 239, 228, 0.03);
  border: 1px solid rgba(239, 239, 228, 0.2);
  padding: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
`

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 25vh;
  color: #efefe4;
`

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`

const NetworkIcon = styled(AlertTriangle)`
  ${IconStyle}
`

export function WrongNetworkCard() {
  const theme = useTheme()

  return (
    <>
      <PageWrapper>
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <div className="pool-top-container">
              <div className="swap-top-container">
                <div className="swap-top-left">
                  {/*<img src={swaptop} alt="swap image" />*/}
                  <div className="swap-top-div">
                    <p>Farming Pools</p>
                    <p>Deposit liquidity into Farming pools to earn 3 months of additional xMONO emissions.</p>
                  </div>
                </div>
                <div className="swap-top-right">
                  <img src={reload} alt="swap image" />
                </div>
              </div>
            </div>

            <MainContentWrapper>
              <ErrorContainer>
                <ThemedText.DeprecatedBody color={theme.deprecated_text3} textAlign="center">
                  <NetworkIcon strokeWidth={1.2} />
                  <div data-testid="pools-unsupported-err">
                    <Trans>Your connected network is unsupported.</Trans>
                  </div>
                </ThemedText.DeprecatedBody>
              </ErrorContainer>
            </MainContentWrapper>
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
      {/* <SwitchLocaleLink /> */}
    </>
  )
}

export const numberWithCommas = (number: any) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}




const FarmingPageWrapper = styled.div`  
    max-width: 1200px;
    width: 100%;
    margin: auto;
    margin-top: 80px;

    .main_img{
        max-width: 327.404px;
        max-height: 279.139px;
        width: 100%;
        height: 100%;
        display: block;
        margin: auto;
    }
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        width: 90%;
    }
`

const TitleBoxWrapper = styled.div`
    max-width: 726px;
    margin: auto;
    display: grid;
    gap: 10px;
    justify-items: center;
    h1{
        color: #FFB704;
        text-align: center;
        -webkit-text-stroke-width: 3px;
        -webkit-text-stroke-color: #723507;
        font-family: DynaPuff;
        font-size: 56px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 70px */
        letter-spacing: 1.12px;
        text-transform: uppercase;
        margin: 0;
        position: relative;
        width: fit-content;
        img{
            position: absolute;
            top: -15px;
            right: -50px;
        } 
    }
    h5{
        color: #4B2828;
        text-align: center;
        font-family: Outfit;
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 21px */
        margin: 0;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        h1{
            font-size: 32px;
            img{
                width: 35px;
                right: -20px;
            }
        }
        h5{
            font-size: 18px;
        }
    }
`

const FilterBoxWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const StatusFilterBox = styled.div`
    display: inline-flex;
    padding: 4px;
    align-items: center;
    gap: 5px;
    border-radius: 50px;
    border: 2px solid #FEF5CF;
`
const StatusFilterItem = styled.button<{
  isActive?: boolean
}>`
    display: flex;
    height: 24px;
    padding: 0px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    background: ${({ isActive }) => isActive ? '#FFF6D0' : 'unset'};
    cursor: ${({ isActive }) => isActive ? 'not-allowed' : 'pointer'};
    outline: none;
    border: none;
    span{
        color: ${({ isActive }) => isActive ? '#4B2828' : 'rgba(75, 40, 40, 0.50)'};
        text-align: center;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 16px */
    }
    &:hover, &:active{
        transition-timing-function: ease-in-out;
        transition-duration: 150ms;
        transform: ${({ isActive }) => isActive ? 'unset' : 'scale(1.05)'};
    }
`
const FarmingList = styled.div`
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, calc(33% - 10px));
    gap: 24px;
    justify-items: center;

    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        grid-template-columns: repeat(2, calc(50% - 12px));
    }

    @media screen and (max-width: 700px) {
        grid-template-columns: repeat(1, 100%);
    }

`

export default function FarmingV3Page() {
  const alphaStore = useAlphaStore()

  const [loadingContractInfo, setLoadingContractInfo] = useState(false)
  const [totalPools, setTotalPools] = useState<PoolV3Info[]>([])
  const [endedPools, setEndedPools] = useState<PoolV3Info[]>([])

  const [filterStatus, setFilterStatus] = useState<'LIVE' | 'ENDED'>('LIVE')

  useEffect(() => {
    if (alphaStore.totalV3Pools.length > 0) {
      setTotalPools(alphaStore.totalV3Pools.filter(item => !item.hasEnded))
      setEndedPools(alphaStore.totalV3Pools.filter(item => item.hasEnded))
    }
  }, [alphaStore.totalV3Pools])

  const filteredPools = useMemo(() => {
    if (filterStatus == 'LIVE')
      return totalPools
    else return endedPools
  }, [totalPools, endedPools, filterStatus])

  const getContractInfo = async (force = false) => {
    if (alphaStore.totalV3Pools.length > 0 && !force) return
    if (loadingContractInfo) return
    try {
      setLoadingContractInfo(true)
      await alphaStore.initV3Pools()
    } catch (error) {
      console.log('getContractInfo failed', error)
    }
    finally {
      setLoadingContractInfo(false)
    }
  }

  useEffect(() => {
    console.log('farm');
    getContractInfo()
  }, [])

  return <FarmingPageWrapper>

    <TitleBoxWrapper>
      <h1>
        Meow Swimming Pool
        <img src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/farming/title_icon.png" alt="gmeowfi" />
      </h1>
      <h5>Stake LP tokens and have it all! </h5>
    </TitleBoxWrapper>
    <img className="main_img" src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/farming/main_img.png" alt="gmeowfi" />

    <FilterBoxWrapper>
      <StatusFilterBox>
        <StatusFilterItem isActive={filterStatus == 'LIVE'} onClick={() => { if (filterStatus != 'LIVE') setFilterStatus('LIVE') }}>
          <span>LIVE</span>
        </StatusFilterItem>

        <StatusFilterItem isActive={filterStatus == 'ENDED'} onClick={() => { if (filterStatus != 'ENDED') setFilterStatus('ENDED') }}>
          <span>FINISHED</span>
        </StatusFilterItem>
      </StatusFilterBox>

      <StatusFilterItem isActive={true}>
        <span>HOT</span>
        <img style={{
          width: '18px',
          height: '18px'
        }} src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/swap/inactive-dropdown.png" alt="gmeowfi" />
      </StatusFilterItem>
    </FilterBoxWrapper>

    {
      loadingContractInfo ? <div style={{ width: '100%', height: '20px', textAlign: 'center', marginTop: '40px', display: 'flex', justifyContent: 'center' }}>
        <LoadingOutlined rev={undefined} />
      </div>
        : <FarmingList>
          {filteredPools?.length > 0 &&
            filteredPools?.map((item: any, index: any) => (<AlphaCardItem alphaInfo={item} key={index} />
            ))}

        </FarmingList>
    }
    {
      !loadingContractInfo && filteredPools.length == 0 && <p style={{ textAlign: 'center' }}>
        No pool available
      </p>
    }
  </FarmingPageWrapper>
}

const AlphaCardItem = ({
  alphaInfo
}: { alphaInfo: PoolV3Info }) => {
  const { account } = useWeb3React()
  const toggleWalletModal = useToggleWalletModal()
  const navigate = useNavigate()
  const tvlMap = useAlphaTvlMap()

  const [userTvl, setUserTvl] = useState<number>(0)
  const {
    price: xMonoPriceRes
  } = useTokenPrice(MONO_ADDRESSES_MAP[DEFAULT_CHAINID])

  const xMonoPrice = useMemo(() => 0.001, [])

  const poolTvl = useMemo(() => {
    return tvlMap.get(String(alphaInfo.incentiveId).toLowerCase()) ?? 0
  }, [tvlMap, alphaInfo])

  const apr = useMemo(() => {
    if (poolTvl > 0) {
      const aprDay = poolTvl * 180
      const aprRes = (alphaInfo.totalRewardUnclaimed * xMonoPrice * 365 * 100) / aprDay
      alphaInfo.apr = aprRes.toString()
      return aprRes
    }
    return 0
  }, [poolTvl, xMonoPrice])


  const tokenA = useToken(alphaInfo.token0)
  const tokenB = useToken(alphaInfo.token1)

  const token0Url = useTokenLogo(alphaInfo.token0)
  const token1Url = useTokenLogo(alphaInfo.token1)

  const loadUserTvl = async () => {
    fetch(`${GMEOW_API_BASE_URL}/pool-xp-multiplier/get-alphapool-tvl?incentive_id=${alphaInfo.incentiveId}&user_address=${account}`)
      .then(res => res.json())
      .then(data => {
        alphaInfo.userTvl = data.userTvl
        setUserTvl(data.userTvl)
      })
  }

  useEffect(() => {
    if (account) loadUserTvl()

  }, [account, alphaInfo])

  useEffect(() => {
    console.log('tvlMap', tvlMap);

  }, [tvlMap])

  return <FarmingListItem
    tvl={poolTvl}
    apr={apr}
    totalUnclaimReward={alphaInfo.totalRewardUnclaimed}
    token0={tokenA}
    token1={tokenB}
    token0Url={token0Url}
    token1Url={token1Url}
    poolFee={Number(alphaInfo.poolFee) / 10000}
    userTvl={userTvl}
    goDetail={() => navigate(`/farming/detail/${alphaInfo?.pool}`, { state: alphaInfo })}
    hasEnded={alphaInfo.hasEnded}
  />

}