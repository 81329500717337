import { useWeb3React } from '@web3-react/core'
import { NotchedButtonBolderNew } from 'components/Button/NotchedButton'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

const LabelText = styled.span`
  color: rgba(255, 239, 176, 0.80);
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.5px; /* 17.5px */
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 13px;
    line-height: 15.5px;
  }
`

const ContentText = styled.span`
  color: #4B2828;
  font-family: Outfit;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 24px */
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 18px;
    line-height: 22.5px;
  }
`
const ItemBox = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;

  span:nth-child(2){
    text-align: center;
  }
`
const ContentBoxWrapper = styled.div`
`

const ContentBox = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  gap: 12px;
`

const ContentBoxTop = styled.div`
  background: #D7903C;
  border: 2px solid #D7903C;
  padding: 17px 24px;
  border-radius: 10px;
`

const ContentBoxTopBody = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 16px;
  &>div:first-child{
    width: 30%;
  }

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    width: 100%;
    display: grid;
    &>div:first-child{
      width: 100%;
    }
  }
`

const ContentBoxTopBodySeparator = styled.div`
  min-height: 47px;
  width: 2px;
  background: rgba(255, 239, 176, 0.50);
  backdrop-filter: blur(4.800000190734863px);
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    display: none;
  }
`
const CopyBtn = styled.div`
  display: inline-flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #00E440;
  width: 51px;
  height: 22px;
  cursor: pointer;

  color: #FFF9E1;
  text-align: center;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 14px */
`

const ContentBoxBottom = styled.div`
  background: #FFEFB0;
  border: 2px solid #D7903C; 
  padding: 24px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  &>div:first-child{
    display: flex;
    gap: 24px; 
    align-items: center;
  }

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    display: grid;
    gap: 20px;
  }
`

const ContentBoxBottomSeparator = styled.div`
  min-height: 43px;
  width: 2px;
  background: rgba(229, 182, 112, 0.50);
  backdrop-filter: blur(4.800000190734863px);
`

export default function MyNftStats({
  refCode,
  totalInvited,
  totalMinted,
  mintingSlotLeft,
  totalYourShare,
  isLoadingUserInfo
}: {
  refCode: string
  totalInvited: number
  totalMinted: number
  mintingSlotLeft: number
  totalYourShare: number
  isLoadingUserInfo: boolean
}) {
  const { account, chainId } = useWeb3React();
  const [hasCopied1, setHasCopied1] = useState<boolean>(false)
  const [hasCopied, setHasCopied] = useState<boolean>(false)
  const [refLink, setRefLink] = useState<string>('')

  const handleCopyRefcode = () => {
    if (!refCode) return
    navigator.clipboard.writeText(refCode)
    setHasCopied1(true)
    setTimeout(() => {
      setHasCopied1(false)
    }, 5000)
  }

  const handleCopyRefLink = () => {
    if (!refCode) return
    navigator.clipboard.writeText('https://' + refLink)
    setHasCopied(true)
    setTimeout(() => {
      setHasCopied(false)
    }, 5000)
  }
  useEffect(() => {
    setRefLink(`gmeow.fi/#/meow-mint/ref=${refCode}`)
  }, [account, chainId, refCode])

  const isMobile = window.screen.width <= 600
  return (
    <>
      <ContentBoxWrapper
      >
        <ContentBox>
          <ContentBoxTop>
            <ContentText style={{ fontSize: isMobile ? '15px' : '18px', fontWeight: 600, fontFamily: 'Outfit', color: '#FFF9E1' }}>
              Invite friends to mint & get TREAT together
            </ContentText>

            <ContentBoxTopBody>
              <ItemBox>
                <LabelText>Invite Code</LabelText>
                <div style={{
                  background: 'unset',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                }}>
                  <ContentText style={{ color: '#FFF9E1', fontSize: isMobile ? '14px' : '18px', }}>{refCode}</ContentText>
                  {hasCopied1 ? (
                    <CopyBtn >
                      Copied
                    </CopyBtn>
                  ) : (
                    <CopyBtn onClick={handleCopyRefcode}>
                      Copy
                    </CopyBtn>
                  )}
                </div>
              </ItemBox>

              <ContentBoxTopBodySeparator />

              <ItemBox>
                <LabelText>Invite Link</LabelText>
                <div
                  style={{
                    background: 'unset',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    gap: '8px'
                  }}
                >
                  <ContentText
                    style={{
                      fontSize: isMobile ? '14px' : '18px',
                      lineHeight: '125%',
                      color: '#FFF9E1'
                    }}
                  >
                    {refLink}
                  </ContentText>

                  {hasCopied ? (
                    <CopyBtn >
                      Copied
                    </CopyBtn>
                  ) : (
                    <CopyBtn onClick={handleCopyRefLink}>
                      Copy
                    </CopyBtn>
                  )}
                </div>
              </ItemBox>
            </ContentBoxTopBody>

          </ContentBoxTop>

          <ContentBoxBottom>
            <div>
              <ItemBox>
                <LabelText style={{ color: '#D7903C' }}>Invited</LabelText>
                <ContentText>{isLoadingUserInfo ? <LoadingOutlined rev={undefined} /> : totalInvited.toLocaleString('en-US')}</ContentText>
              </ItemBox>

              <ContentBoxBottomSeparator />

              <ItemBox>
                <LabelText style={{ color: '#D7903C' }}>Minted</LabelText>
                <ContentText>{isLoadingUserInfo ? <LoadingOutlined rev={undefined} /> : totalMinted.toLocaleString('en-US')}</ContentText>
              </ItemBox>

              <ContentBoxBottomSeparator />

              <ItemBox>
                <LabelText style={{ color: '#D7903C' }}>
                  Minting slots left
                  <Tooltip title='You get 1 free mint for each successful invite'>
                    <InfoCircleOutlined style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer', }} rev={undefined} />
                  </Tooltip>
                </LabelText>

                <ContentText>{isLoadingUserInfo ? <LoadingOutlined rev={undefined} /> : Math.max(0, mintingSlotLeft).toLocaleString('en-US')}</ContentText>
              </ItemBox>
            </div>

            <ItemBox>
              <LabelText style={{ color: '#D7903C' }}>Zircuit Incentive Share</LabelText>
              <ContentText style={{ color: '#05C63B' }}>{isLoadingUserInfo ? <LoadingOutlined rev={undefined} /> : totalYourShare.toFixed(2)}%</ContentText>
            </ItemBox>
          </ContentBoxBottom>
        </ContentBox>
      </ContentBoxWrapper >
    </>
  )
}
