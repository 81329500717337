import { BigNumber } from '@ethersproject/bignumber'
import type { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { Trace } from '@uniswap/analytics'
import { PageName } from '@uniswap/analytics-events'
import { Currency, CurrencyAmount, Fraction, Percent, Price, Token } from '@uniswap/sdk-core'
import { NonfungiblePositionManager, Pool, Position } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { sendEvent } from 'components/analytics'
import './pool.css'

import {
    ButtonConfirmed,
    ButtonPrimary,
    NotchedButtonFill,
} from 'components/Button'
import { DarkCard, LightCard, MonoCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import Loader from 'components/Loader'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { RowBetween, RowFixed } from 'components/Row'
import { Dots } from 'components/swap/styleds'
import Toggle from 'components/Toggle'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { useToken } from 'hooks/Tokens'
import { useV3NFTPositionManagerContract } from 'hooks/useContract'
import useIsTickAtLimit from 'hooks/useIsTickAtLimit'
import { PoolState, usePool } from 'hooks/usePools'
import useStablecoinPrice from 'hooks/useStablecoinPrice'
import { useV3PositionFees } from 'hooks/useV3PositionFees'
import { useV3PositionFromTokenId } from 'hooks/useV3Positions'
import { useSingleCallResult } from 'lib/hooks/multicall'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Bound } from 'state/mint/v3/actions'
import { useIsTransactionPending, useTransactionAdder } from 'state/transactions/hooks'
import styled, { useTheme } from 'styled-components/macro'
import { ExternalLink, HideExtraSmall, ThemedText } from 'theme'
import { currencyId } from 'utils/currencyId'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import { formatTickPrice } from 'utils/formatTickPrice'
import { unwrappedToken } from 'utils/unwrappedToken'
import SettingsTab from '../../components/Settings'

import RangeBadge from '../../components/Badge/RangeBadge'
import { getPriceOrderingFromPositionForUI } from '../../components/PositionListItem'
import RateToggle from '../../components/RateToggle'
import { usePositionTokenURI } from '../../hooks/usePositionTokenURI'
import { TransactionType } from '../../state/transactions/types'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import { LoadingRows } from './styleds'
import { NotchedButtonBolderNew } from "../../components/Button/NotchedButton";
import RemoveLiquidityV3 from "../RemoveLiquidity/V3";
import AddLiquidityV3Popup from 'components/AddLiquidityV3Popup'

const PageWrapper = styled.div`
    padding: 68px 8px 0px;
    width: 80%;
    display: flex;
    @media (max-width: 1028px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    @media (max-width: 768px) {
        width: 90%;
    }
`

const PositionPageButtonPrimary = styled(ButtonPrimary)`
    width: 228px;
    height: 40px;
    font-size: 16px;
    line-height: 20px;
    border-radius: 12px;
`

const WrapperBadgeText = styled.div`
    display: flex;
    justify-content: end;
    align-items: end;
    height: 36px;
    @media (max-width: 747px) {
        height: 21px;
    }
`

// responsive text
// disable the warning because we don't use the end prop, we just want to filter it out
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Label = styled(({ end, ...props }) => <div {...props} />) <{ end?: boolean }>`
    display: flex;
    justify-content: ${({ end }) => (end ? 'flex-end' : 'flex-start')};
    align-items: center;
    color: rgba(75, 40, 40, 0.50);
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 20px */
`


const ExtentsText = styled.span<
    {
        color?: string,
        fontFamily?: string,
        fontSize?: string,
        fontSizeMb?: string,
        fontWeight?: string,
        lineHeight?: string,
        margin?: string,
        marginMb?: string,
        padding?: string
    }
>`
    color: ${({ color }) => color ? color : '#4B2828'} !important;
    font-family: ${({ fontFamily }) => fontFamily ?? 'Outfit'};
    font-size: ${({ fontSize }) => fontSize ?? '16px'} !important;
    font-style: normal;
    font-weight: ${({ fontWeight }) => fontWeight ?? '500'};
    line-height: ${({ lineHeight }) => lineHeight ?? '100%'};
    margin: ${({ margin }) => margin};
    padding: ${({ padding }) => padding};
    @media (max-width: 747px) {
        font-size: ${({ fontSizeMb }) => fontSizeMb ?? '21px'} !important;
        margin: ${({ marginMb }) => marginMb};
    }
`

const HoverText = styled(ThemedText.DeprecatedMain)`
    text-decoration: none;
    color: #4B2828;

    :hover {
        color: rgba(239, 239, 228, 0.5);
        text-decoration: none;
    }
`

const DoubleArrow = styled.span`
    color: ${({ theme }) => theme.deprecated_text3};
    margin: 0 1rem;
`
const ResponsiveRow = styled(RowBetween)`
    flex-direction: column;
    ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    width: 100%:
  `};
`

const Section1 = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 52px;
`

const GmeowCart = styled.div`
    border-radius: 10px;
    background: #FFF6D0;
    box-shadow: 0px 7.395px 30px 0px rgba(145, 103, 20, 0.30), -6px -6px 4px 0px rgba(215, 144, 60, 0.60) inset, 6px 6px 4px 0px rgba(255, 255, 255, 0.80) inset;
    padding: 24px 32px 24px 32px;
    @media (max-width: 747px) {
        padding: 16px;
    }
`

const Line = styled.div`
    width: 100%;
    height: 1px;
    background: rgba(229, 182, 112, 0.50);
    backdrop-filter: blur(4.800000190734863px);
    margin: 24px 0;
    @media (max-width: 747px) {
        margin: 12px 0;
    }
`

const CurrencySymbolTxt = styled.div`
    color: #4B2828;
    font-family: Outfit;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 24px */
    @media (max-width: 747px) {
        font-size: 16px;
    }
`

const InvertedTxt = styled.div`
    color: rgba(75, 40, 40, 0.50);
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
    @media (max-width: 747px) {
        font-size: 12px;
    }
`

const ClaimFeeButton = styled.p`
    margin: 0;
    color: #FFF9E1;
    text-align: center;
    font-family: DynaPuff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
    text-transform: uppercase;
    @media (max-width: 747px) {
        font-size: 12px;
    }
`

const CollectingButton = styled.p`
    margin: 0;
    text-align: center;
    font-family: DynaPuff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
    text-transform: uppercase;
    @media (max-width: 747px) {
        font-size: 12px;
    }
`


const ResponsiveButtonPrimary = styled(NotchedButtonFill)`
    padding: 6px 8px;
    width: fit-content;
    font-size: 16px;
    ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex: 1 1 auto;
    width: 49%;
  `};
`

const NFTGrid = styled.div`
    display: grid;
    grid-template: 'overlap';
    min-height: 400px;

    div {
        grid-area: overlap;
    }

    div svg rect#nft_bg {
        stroke: #ffeb3b;
    }

    div.nft_bg_1 svg rect#nft_bg {
        fill: url(#nft_bg_1)
    }

    div.nft_bg_2 svg rect#nft_bg {
        fill: url(#nft_bg_2)
    }

    div.nft_bg_3 svg rect#nft_bg {
        fill: url(#nft_bg_3)
    }

    div.nft_bg_4 svg rect#nft_bg {
        fill: url(#nft_bg_4)
    }

    svg {
        grid-area: overlap;
    }
`

const NFTCanvas = styled.canvas`
    grid-area: overlap;
`

const NFTImage = styled.img`
    grid-area: overlap;
    height: 400px;
    /* Ensures SVG appears on top of canvas. */
    z-index: 1;
`

const CurrentPriceCardWrapper = styled.div<
    {
        justifyContent?: string,
        alignItems?: string,
        gap?: string
    }
>`
    display: flex;
    flex-direction: column;
    justify-content: ${({ justifyContent }) => justifyContent};
    align-items: ${({ alignItems }) => alignItems};
    gap: ${({ gap }) => gap ? gap : '12px'};
`

const CurrentPriceCardInner = styled.div`
    display: flex;
    align-items: end;
    gap: 8px;
`

const Section4 = styled.div`
    display: flex;
    justify-content: space-between;
`

const DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)

function CurrentPriceCard({
    inverted,
    pool,
    currencyQuote,
    currencyBase,
}: {
    inverted?: boolean
    pool?: Pool | null
    currencyQuote?: Currency
    currencyBase?: Currency
}) {
    if (!pool || !currencyQuote || !currencyBase) {
        return null
    }

    return (
        <CurrentPriceCardWrapper>
            <ExtentsText color={'rgba(75, 40, 40, 0.50)'}>
                <Trans>Current price</Trans>
            </ExtentsText>
            <CurrentPriceCardInner>
                <ExtentsText fontSize={'40px'} fontSizeMb={'16px'} lineHeight={'90%'}>
                    {(inverted ? pool.token1Price : pool.token0Price).toSignificant(6)}{' '}
                </ExtentsText>
                <ExtentsText color={'#E5B670'} fontSizeMb={"10px"}>
                    <Trans>
                        {currencyQuote?.symbol} per {currencyBase?.symbol}
                    </Trans>
                </ExtentsText>
            </CurrentPriceCardInner>
        </CurrentPriceCardWrapper>
    )
}

function LinkedCurrency({ chainId, currency, size }: { chainId?: number; currency?: Currency; size?: string }) {
    const address = (currency as Token)?.address

    if (typeof chainId === 'number' && address) {
        return (
            <ExternalLink href={getExplorerLink(chainId, address, ExplorerDataType.TOKEN)}>
                <RowFixed>
                    <CurrencyLogo currency={currency} size={size ? size : "20px"} style={{ marginRight: '0.5rem' }} />
                    <CurrencySymbolTxt>
                        {currency?.symbol}
                    </CurrencySymbolTxt>
                </RowFixed>
            </ExternalLink>
        )
    }

    return (
        <RowFixed>
            <CurrencyLogo currency={currency} size={size ? size : "20px"} style={{ marginRight: '0.5rem' }} />
            <CurrencySymbolTxt>{currency?.symbol}</CurrencySymbolTxt>
        </RowFixed>
    )
}

function getRatio(
    lower: Price<Currency, Currency>,
    current: Price<Currency, Currency>,
    upper: Price<Currency, Currency>
) {
    try {
        if (!current.greaterThan(lower)) {
            return 100
        } else if (!current.lessThan(upper)) {
            return 0
        }

        const a = Number.parseFloat(lower.toSignificant(15))
        const b = Number.parseFloat(upper.toSignificant(15))
        const c = Number.parseFloat(current.toSignificant(15))

        const ratio = Math.floor((1 / ((Math.sqrt(a * b) - Math.sqrt(b * c)) / (c - Math.sqrt(b * c)) + 1)) * 100)

        if (ratio < 0 || ratio > 100) {
            throw Error('Out of range')
        }

        return ratio
    } catch {
        return undefined
    }
}

// snapshots a src img into a canvas
function getSnapshot(src: HTMLImageElement, canvas: HTMLCanvasElement, targetHeight: number) {
    const context = canvas.getContext('2d')

    if (context) {
        let { width, height } = src

        // src may be hidden and not have the target dimensions
        const ratio = width / height
        height = targetHeight
        width = Math.round(ratio * targetHeight)

        // Ensure crispness at high DPIs
        canvas.width = width * devicePixelRatio
        canvas.height = height * devicePixelRatio
        canvas.style.width = width + 'px'
        canvas.style.height = height + 'px'
        context.scale(devicePixelRatio, devicePixelRatio)

        context.clearRect(0, 0, width, height)
        context.drawImage(src, 0, 0, width, height)
    }
}

function NFT({ image, height: targetHeight }: { image: string; height: number }) {
    const [animate, setAnimate] = useState(false)

    const canvasRef = useRef<HTMLCanvasElement>(null)
    const imageRef = useRef<HTMLImageElement>(null)

    const [svgSource, setSvgSource] = useState<string>()

    const getSvgBg = () => {
        return `` + (Math.floor(Math.random() * 4) + 1)
    }

    useEffect(() => {
        if (image) {
            const rawSvt = atob(image.replace('data:image/svg+xml;base64,', ''))
            setSvgSource(rawSvt.replaceAll('â¢', '•').replace('#nft_bg_1', `#nft_bg_2`))
        }
    }, [image])

    return (
        <NFTGrid
            onMouseEnter={() => {
                setAnimate(true)
            }}
            onMouseLeave={() => {
                // snapshot the current frame so the transition to the canvas is smooth
                if (imageRef.current && canvasRef.current) {
                    getSnapshot(imageRef.current, canvasRef.current, targetHeight)
                }
                setAnimate(false)
            }}
        >
            <NFTCanvas ref={canvasRef} />
            {/* <NFTImage
                ref={imageRef}
                src={image}
                hidden={!animate}
                onLoad={() => {
                    // snapshot for the canvas
                    if (imageRef.current && canvasRef.current) {
                        getSnapshot(imageRef.current, canvasRef.current, targetHeight)
                    }
                }}
            /> */}

            {
                svgSource && <div className={getSvgBg()} dangerouslySetInnerHTML={{ __html: svgSource }} />
            }
        </NFTGrid>
    )
}

const useInverter = ({
    priceLower,
    priceUpper,
    quote,
    base,
    invert,
}: {
    priceLower?: Price<Token, Token>
    priceUpper?: Price<Token, Token>
    quote?: Token
    base?: Token
    invert?: boolean
}): {
    priceLower?: Price<Token, Token>
    priceUpper?: Price<Token, Token>
    quote?: Token
    base?: Token
} => {
    return {
        priceUpper: invert ? priceLower?.invert() : priceUpper,
        priceLower: invert ? priceUpper?.invert() : priceLower,
        quote: invert ? base : quote,
        base: invert ? quote : base,
    }
}

export function PositionPageUnsupportedContent() {
    return (
        <PageWrapper>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: "100%" }}>
                <ThemedText.HeadlineLarge style={{ marginBottom: '8px', color: "#4B2828" }}>
                    <Trans>Position unavailable</Trans>
                </ThemedText.HeadlineLarge>
                <ThemedText.BodyPrimary style={{ marginBottom: '32px', color: "#4B2828" }}>
                    <Trans>To view a position, you must be connected to the network it belongs to.</Trans>
                </ThemedText.BodyPrimary>

                <NotchedButtonFill>
                    Connect
                </NotchedButtonFill>
            </div>
        </PageWrapper>
    )
}

export function PositionPage() {
    const { tokenId: tokenIdFromUrl } = useParams<{ tokenId?: string }>()
    const { chainId, account, provider } = useWeb3React()
    const theme = useTheme()
    const navigate = useNavigate()
    const [tabSelector, setTabSelector] = useState(1)

    const parsedTokenId = tokenIdFromUrl ? BigNumber.from(tokenIdFromUrl) : undefined
    const { loading, position: positionDetails } = useV3PositionFromTokenId(parsedTokenId)
    const {
        token0: token0Address,
        token1: token1Address,
        fee: feeAmount,
        liquidity,
        tickLower,
        tickUpper,
        tokenId,
    } = positionDetails || {}

    const removed = liquidity?.eq(0)

    const metadata = usePositionTokenURI(parsedTokenId)
    const token0 = useToken(token0Address)
    const token1 = useToken(token1Address)

    const currency0 = token0 ? unwrappedToken(token0) : undefined
    const currency1 = token1 ? unwrappedToken(token1) : undefined

    // flag for receiving WETH
    const [receiveWETH, setReceiveWETH] = useState(false)
    const nativeCurrency = useNativeCurrency()
    const nativeWrappedSymbol = nativeCurrency.wrapped.symbol

    // construct Position from details returned
    const [poolState, pool] = usePool(token0 ?? undefined, token1 ?? undefined, feeAmount)
    const position = useMemo(() => {
        if (pool && liquidity && typeof tickLower === 'number' && typeof tickUpper === 'number') {
            return new Position({ pool, liquidity: liquidity.toString(), tickLower, tickUpper })
        }
        return undefined
    }, [liquidity, pool, tickLower, tickUpper])

    const tickAtLimit = useIsTickAtLimit(feeAmount, tickLower, tickUpper)

    const pricesFromPosition = getPriceOrderingFromPositionForUI(position)
    const [manuallyInverted, setManuallyInverted] = useState(false)

    // handle manual inversion
    const { priceLower, priceUpper, base } = useInverter({
        priceLower: pricesFromPosition.priceLower,
        priceUpper: pricesFromPosition.priceUpper,
        quote: pricesFromPosition.quote,
        base: pricesFromPosition.base,
        invert: manuallyInverted,
    })

    const inverted = token1 ? base?.equals(token1) : undefined
    const currencyQuote = inverted ? currency0 : currency1
    const currencyBase = inverted ? currency1 : currency0

    const ratio = useMemo(() => {
        return priceLower && pool && priceUpper
            ? getRatio(
                inverted ? priceUpper.invert() : priceLower,
                pool.token0Price,
                inverted ? priceLower.invert() : priceUpper
            )
            : undefined
    }, [inverted, pool, priceLower, priceUpper])

    // fees
    const [feeValue0, feeValue1] = useV3PositionFees(pool ?? undefined, positionDetails?.tokenId, receiveWETH)

    // these currencies will match the feeValue{0,1} currencies for the purposes of fee collection
    const currency0ForFeeCollectionPurposes = pool ? (receiveWETH ? pool.token0 : unwrappedToken(pool.token0)) : undefined
    const currency1ForFeeCollectionPurposes = pool ? (receiveWETH ? pool.token1 : unwrappedToken(pool.token1)) : undefined

    const [collecting, setCollecting] = useState<boolean>(false)
    const [collectMigrationHash, setCollectMigrationHash] = useState<string | null>(null)
    const isCollectPending = useIsTransactionPending(collectMigrationHash ?? undefined)
    const [showConfirm, setShowConfirm] = useState(false)

    // usdc prices always in terms of tokens
    const price0 = useStablecoinPrice(token0 ?? undefined)
    const price1 = useStablecoinPrice(token1 ?? undefined)

    const fiatValueOfFees: CurrencyAmount<Currency> | null = useMemo(() => {
        if (!price0 || !price1 || !feeValue0 || !feeValue1) return null

        // we wrap because it doesn't matter, the quote returns a USDC amount
        const feeValue0Wrapped = feeValue0?.wrapped
        const feeValue1Wrapped = feeValue1?.wrapped

        if (!feeValue0Wrapped || !feeValue1Wrapped) return null

        const amount0 = price0.quote(feeValue0Wrapped)
        const amount1 = price1.quote(feeValue1Wrapped)
        return amount0.add(amount1)
    }, [price0, price1, feeValue0, feeValue1])

    const fiatValueOfLiquidity: CurrencyAmount<Token> | null = useMemo(() => {
        if (!price0 || !price1 || !position) return null
        const amount0 = price0.quote(position.amount0)
        const amount1 = price1.quote(position.amount1)
        return amount0.add(amount1)
    }, [price0, price1, position])
    const addTransaction = useTransactionAdder()
    const positionManager = useV3NFTPositionManagerContract()
    const collect = useCallback(() => {
        if (
            !currency0ForFeeCollectionPurposes ||
            !currency1ForFeeCollectionPurposes ||
            !chainId ||
            !positionManager ||
            !account ||
            !tokenId ||
            !provider
        )
            return

        setCollecting(true)

        // we fall back to expecting 0 fees in case the fetch fails, which is safe in the
        // vast majority of cases
        const { calldata, value } = NonfungiblePositionManager.collectCallParameters({
            tokenId: tokenId.toString(),
            expectedCurrencyOwed0: feeValue0 ?? CurrencyAmount.fromRawAmount(currency0ForFeeCollectionPurposes, 0),
            expectedCurrencyOwed1: feeValue1 ?? CurrencyAmount.fromRawAmount(currency1ForFeeCollectionPurposes, 0),
            recipient: account,
        })

        const txn = {
            to: positionManager.address,
            data: calldata,
            value,
        }

        provider
            .getSigner()
            .estimateGas(txn)
            .then((estimate) => {
                const newTxn = {
                    ...txn,
                    gasLimit: calculateGasMargin(estimate),
                }

                return provider
                    .getSigner()
                    .sendTransaction(newTxn)
                    .then((response: TransactionResponse) => {
                        setCollectMigrationHash(response.hash)
                        setCollecting(false)

                        sendEvent({
                            category: 'Liquidity',
                            action: 'CollectV3',
                            label: [currency0ForFeeCollectionPurposes.symbol, currency1ForFeeCollectionPurposes.symbol].join('/'),
                        })

                        addTransaction(response, {
                            type: TransactionType.COLLECT_FEES,
                            currencyId0: currencyId(currency0ForFeeCollectionPurposes),
                            currencyId1: currencyId(currency1ForFeeCollectionPurposes),
                            expectedCurrencyOwed0: CurrencyAmount.fromRawAmount(currency0ForFeeCollectionPurposes, 0).toExact(),
                            expectedCurrencyOwed1: CurrencyAmount.fromRawAmount(currency1ForFeeCollectionPurposes, 0).toExact(),
                        })
                    })
            })
            .catch((error) => {
                setCollecting(false)
                console.error(error)
            })
    }, [
        chainId,
        feeValue0,
        feeValue1,
        currency0ForFeeCollectionPurposes,
        currency1ForFeeCollectionPurposes,
        positionManager,
        account,
        tokenId,
        addTransaction,
        provider,
    ])

    const owner = useSingleCallResult(!!tokenId ? positionManager : null, 'ownerOf', [tokenId]).result?.[0]
    const ownsNFT = owner === account || positionDetails?.operator === account

    const feeValueUpper = inverted ? feeValue0 : feeValue1
    const feeValueLower = inverted ? feeValue1 : feeValue0

    // check if price is within range
    const below = pool && typeof tickLower === 'number' ? pool.tickCurrent < tickLower : undefined
    const above = pool && typeof tickUpper === 'number' ? pool.tickCurrent >= tickUpper : undefined
    const inRange: boolean = typeof below === 'boolean' && typeof above === 'boolean' ? !below && !above : false

    function modalHeader() {
        return (
            <AutoColumn gap="md" style={{ marginTop: '20px' }}>
                <LightCard padding="12px 16px">
                    <AutoColumn gap="md">
                        <RowBetween>
                            <RowFixed>
                                <CurrencyLogo currency={feeValueUpper?.currency} size="20px"
                                    style={{ marginRight: '0.5rem' }} />
                                <ThemedText.DeprecatedMain color={'#4B2828'}>
                                    {feeValueUpper ? formatCurrencyAmount(feeValueUpper, 4) : '-'}
                                </ThemedText.DeprecatedMain>
                            </RowFixed>
                            <ThemedText.DeprecatedMain
                                color={'#4B2828'}>{feeValueUpper?.currency?.symbol}</ThemedText.DeprecatedMain>
                        </RowBetween>
                        <RowBetween>
                            <RowFixed>
                                <CurrencyLogo currency={feeValueLower?.currency} size="20px"
                                    style={{ marginRight: '0.5rem' }} />
                                <ThemedText.DeprecatedMain color={'#4B2828'}>
                                    {feeValueLower ? formatCurrencyAmount(feeValueLower, 4) : '-'}
                                </ThemedText.DeprecatedMain>
                            </RowFixed>
                            <ThemedText.DeprecatedMain
                                color={'#4B2828'}>{feeValueLower?.currency?.symbol}</ThemedText.DeprecatedMain>
                        </RowBetween>
                    </AutoColumn>
                </LightCard>
                <div style={{ color: '#4B2828' }}>
                    <Trans>Collecting fees will withdraw currently available fees for you.</Trans>
                </div>
                <ButtonPrimary onClick={collect}>
                    <Trans>Collect</Trans>
                </ButtonPrimary>
            </AutoColumn>
        )
    }

    const showCollectAsWeth = Boolean(
        ownsNFT &&
        (feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0)) &&
        currency0 &&
        currency1 &&
        (currency0.isNative || currency1.isNative) &&
        !collectMigrationHash
    )

    const [showRemoveLiquidity, setShowRemoveLiquidity] = useState(false);

    const handleOpenRemoveLiquidity = useCallback(() => {
        setShowRemoveLiquidity(true);
    }, []);

    const handleCloseRemoveLiquidity = useCallback(() => {
        setShowRemoveLiquidity(false);
    }, []);

    const [isShowAddLiqPopup, setIsShowAddLiqPopup] = useState<boolean>(false)

    return loading || poolState === PoolState.LOADING || !feeAmount ? (
        <LoadingRows>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
        </LoadingRows>
    ) : (
        <Trace page={PageName.POOL_PAGE} shouldLogImpression>
            <>
                <PageWrapper>
                    {
                        position?.pool && isShowAddLiqPopup && <AddLiquidityV3Popup
                            currencyIdA={position?.pool.token0?.address}
                            currencyIdB={position?.pool.token1?.address}
                            feeAmountFromUrl={position?.pool.fee}
                            tokenId={tokenId}
                            onClose={() => setIsShowAddLiqPopup(false)}
                        />
                    }

                    <TransactionConfirmationModal
                        isOpen={showConfirm}
                        onDismiss={() => setShowConfirm(false)}
                        attemptingTxn={collecting}
                        hash={collectMigrationHash ?? ''}
                        content={() => (
                            <ConfirmationModalContent
                                title={<Trans>Claim fees</Trans>}
                                onDismiss={() => setShowConfirm(false)}
                                topContent={modalHeader}
                            />
                        )}
                        pendingText={<Trans>Collecting fees</Trans>}
                    />
                    <div className="tab-pool-selector">
                        <NotchedButtonBolderNew cursor={`pointer`}>
                            <div
                                className={`wrapper-display-style tab-pool-selector-text`}
                                onClick={() => {
                                    navigate('/add')
                                }}
                            >
                                <div className={`wrapper-tab-pool-selector-text`}>
                                    <div style={{ width: "24px", height: "24px" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24"
                                            fill="none">
                                            <g clipPath="url(#clip0_160_1026)">
                                                <path
                                                    d="M6.80005 11C6.27416 11.9122 5.99756 12.9467 5.99805 13.9996C5.99853 15.0525 6.27608 16.0867 6.80282 16.9984C7.32955 17.9101 8.08692 18.6671 8.99885 19.1934C9.91077 19.7197 10.9451 19.9968 11.998 19.9968C13.051 19.9968 14.0853 19.7197 14.9972 19.1934C15.9092 18.6671 16.6665 17.9101 17.1933 16.9984C17.72 16.0867 17.9976 15.0525 17.998 13.9996C17.9985 12.9467 17.7219 11.9122 17.196 11L11.999 3L6.79905 11H6.80005Z"
                                                    stroke="#A59185" strokeWidth="2" strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_160_1026">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <p>Add Liquidity</p>
                                </div>
                            </div>
                        </NotchedButtonBolderNew>
                        <NotchedButtonBolderNew cursor={`pointer`}>
                            <div
                                className={`wrapper-display-style tab-pool-selector-text ${tabSelector === 1 ? 'tab-pool-active wrapper-display-style-active' : ''}`}
                                onClick={() => {
                                    navigate('/pool')
                                }}
                            >
                                <div className={`wrapper-tab-pool-selector-text`}>
                                    <div style={{ width: "24px", height: "24px" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24"
                                            fill="none">
                                            <g clipPath="url(#clip0_160_1029)">
                                                <path
                                                    d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                                                    stroke="#4B2828" strokeWidth="2" strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                                <path
                                                    d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                                                    stroke="#4B2828" strokeWidth="2" strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                                <path d="M12 2V4" stroke="#4B2828" strokeWidth="2" strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                                <path d="M12 20V22" stroke="#4B2828" strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                                <path d="M20 12H22" stroke="#4B2828" strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                                <path d="M2 12H4" stroke="#4B2828" strokeWidth="2" strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_160_1029">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <p>Positions</p>
                                </div>
                            </div>
                        </NotchedButtonBolderNew>
                    </div>
                    <div className="pool-container">
                        <div className="pool-top-container">
                            <div className="swap-top-container">
                                <div className="swap-top-left">
                                    <p style={{ cursor: "pointer" }} onClick={() => {
                                        navigate('/pool')
                                    }}>Positions</p>
                                    <p>{'>'}</p>
                                    <div className={`swap-top-left-inner`}>
                                        <RowFixed style={{ height: "100%" }}>
                                            <DoubleCurrencyLogo currency0={currencyBase} currency1={currencyQuote}
                                                size={36}
                                                margin={true} />
                                            <ExtentsText fontSize={'40px'}
                                                margin={"0 10px 0 0"}
                                                marginMb={"0 5px 0 0"}
                                            >
                                                &nbsp;{currencyQuote?.symbol}&nbsp;/&nbsp;{currencyBase?.symbol}
                                            </ExtentsText>
                                            <WrapperBadgeText>
                                                <ExtentsText fontSize={'24px'} fontSizeMb={'16px'} fontWeight={"600"}
                                                    color={"#05C63B"}>
                                                    <Trans>{new Percent(feeAmount, 1_000_000).toSignificant()}%</Trans>
                                                </ExtentsText>
                                            </WrapperBadgeText>
                                        </RowFixed>
                                    </div>
                                </div>
                                <div className="swap-top-right">
                                    <SettingsTab placeholderSlippage={DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE} />
                                </div>
                            </div>
                        </div>
                        <AutoColumn gap="md">
                            <AutoColumn gap="large">
                                {/*<Link*/}
                                {/*    data-cy="visit-pool"*/}
                                {/*    style={{textDecoration: 'none', width: 'fit-content', margin: '20px 0px'}}*/}
                                {/*    to="/pool"*/}
                                {/*>*/}
                                {/*    <HoverText>*/}
                                {/*        <Trans>← Back to Pools</Trans>*/}
                                {/*    </HoverText>*/}
                                {/*</Link>*/}
                                <RowBetween></RowBetween>
                            </AutoColumn>
                            <Section1>
                                {'result' in metadata ? (
                                    <MonoCard
                                        width="100%"
                                        height="100%"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            justifyContent: 'space-around',
                                        }}
                                    >
                                        <div style={{ marginRight: 12 }}>
                                            <NFT image={metadata.result.image} height={400} />
                                        </div>
                                        {typeof chainId === 'number' && owner && !ownsNFT ? (
                                            <ExternalLink
                                                href={getExplorerLink(chainId, owner, ExplorerDataType.ADDRESS)}>
                                                <Trans>Owner</Trans>
                                            </ExternalLink>
                                        ) : null}
                                    </MonoCard>
                                ) : (
                                    <MonoCard
                                        width="100%"
                                        height="100%"
                                        style={{
                                            minWidth: '340px',
                                        }}
                                    >
                                        <Loader />
                                    </MonoCard>
                                )}
                                {ownsNFT && (
                                    <div className="button-rowfixed">
                                        {tokenId && !removed ? (
                                            <NotchedButtonFill onClick={handleOpenRemoveLiquidity}
                                                bg={'#E5B670'}
                                                width="100%" padding="6px 8px">
                                                <p className={"button-rowfixed-style-txt"}>
                                                    Remove liquidity
                                                </p>
                                            </NotchedButtonFill>
                                        ) : null}
                                        {currency0 && currency1 && feeAmount && tokenId ? (
                                            <NotchedButtonFill
                                                width="100%"
                                                padding="6px 8px"
                                                bg={'#00E440'}
                                                onClick={() => {
                                                    // navigate(`/increase/${currencyId(currency0)}/${currencyId(currency1)}/${feeAmount}/${tokenId}`)
                                                    setIsShowAddLiqPopup(true)
                                                }}
                                            >
                                                <p className={"button-rowfixed-style-txt"}>
                                                    Add liquidity
                                                </p>
                                            </NotchedButtonFill>
                                        ) : null}
                                        {showRemoveLiquidity && <RemoveLiquidityV3 onClose={handleCloseRemoveLiquidity} />}
                                    </div>
                                )}
                            </Section1>
                            <AutoColumn gap="12px" style={{ width: '100%', height: '100%', marginTop: "60px" }}>
                                <GmeowCart>
                                    <AutoColumn style={{ width: '100%' }}>
                                        <AutoColumn gap="md">
                                            <Label>
                                                <Trans>Liquidity</Trans>
                                            </Label>
                                            {fiatValueOfLiquidity?.greaterThan(new Fraction(1, 100)) ? (
                                                <ExtentsText fontSize={'40px'}>
                                                    <Trans>${fiatValueOfLiquidity.toFixed(2, { groupSeparator: ',' })}</Trans>
                                                </ExtentsText>
                                            ) : (
                                                <ExtentsText fontSize={'40px'}>
                                                    <Trans>$-</Trans>
                                                </ExtentsText>
                                            )}
                                        </AutoColumn>
                                        <Line></Line>
                                        <AutoColumn gap="md">
                                            <RowBetween>
                                                <LinkedCurrency size={"32px"} chainId={chainId}
                                                    currency={currencyQuote} />
                                                <RowFixed gap={'8px'} align={"end"}>
                                                    <CurrencySymbolTxt>
                                                        {inverted ? position?.amount0.toSignificant(4) : position?.amount1.toSignificant(4)}
                                                    </CurrencySymbolTxt>
                                                    {typeof ratio === 'number' && !removed ? (
                                                        <InvertedTxt>
                                                            <Trans>{inverted ? ratio : 100 - ratio}%</Trans>
                                                        </InvertedTxt>
                                                    ) : null}
                                                </RowFixed>
                                            </RowBetween>
                                            <RowBetween>
                                                <LinkedCurrency size={"32px"} chainId={chainId}
                                                    currency={currencyBase} />
                                                <RowFixed gap={'8px'} align={"end"}>
                                                    <CurrencySymbolTxt>
                                                        {inverted ? position?.amount1.toSignificant(4) : position?.amount0.toSignificant(4)}
                                                    </CurrencySymbolTxt>
                                                    {typeof ratio === 'number' && !removed ? (
                                                        <InvertedTxt>
                                                            <Trans>{inverted ? 100 - ratio : ratio}%</Trans>
                                                        </InvertedTxt>
                                                    ) : null}
                                                </RowFixed>
                                            </RowBetween>
                                        </AutoColumn>
                                    </AutoColumn>
                                </GmeowCart>
                                <GmeowCart>
                                    <AutoColumn style={{ width: '100%' }}>
                                        <AutoColumn gap="md">
                                            <RowBetween style={{ alignItems: 'flex-start' }}>
                                                <AutoColumn gap="md">
                                                    <Label>
                                                        <Trans>Unclaimed fees</Trans>
                                                    </Label>
                                                    {fiatValueOfFees?.greaterThan(new Fraction(1, 100)) ? (
                                                        <ExtentsText fontSize={'40px'}>
                                                            <Trans>${fiatValueOfFees.toFixed(2, { groupSeparator: ',' })}</Trans>
                                                        </ExtentsText>
                                                    ) : (
                                                        <ExtentsText fontSize={'40px'}>
                                                            <Trans>$-</Trans>
                                                        </ExtentsText>
                                                    )}
                                                </AutoColumn>
                                                {ownsNFT &&
                                                    (feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0) || !!collectMigrationHash) ? (
                                                    <div>
                                                        <ButtonConfirmed
                                                            disabled={collecting || !!collectMigrationHash}
                                                            confirmed={!!collectMigrationHash && !isCollectPending}
                                                            width="148px"
                                                            height="32px"
                                                            bg="#00E440"
                                                            earMaxW="100px"
                                                            mbEarMaxW="60px"
                                                            padding="4px 8px"
                                                            onClick={() => setShowConfirm(true)}
                                                        >
                                                            {!!collectMigrationHash && !isCollectPending ? (
                                                                <ThemedText.DeprecatedMain
                                                                    color={theme.deprecated_text1}>
                                                                    <Trans> Collected</Trans>
                                                                </ThemedText.DeprecatedMain>
                                                            ) : isCollectPending || collecting ? (
                                                                <ThemedText.DeprecatedMain
                                                                    color={theme.deprecated_text1}>
                                                                    {' '}
                                                                    <Dots display={'flex'} fontSize={"16px"}>
                                                                        <Trans>
                                                                            <CollectingButton>
                                                                                Collecting
                                                                            </CollectingButton>
                                                                        </Trans>
                                                                    </Dots>
                                                                </ThemedText.DeprecatedMain>
                                                            ) : (
                                                                <>
                                                                    <ThemedText.BodySecondary
                                                                        color={theme.deprecated_white}>
                                                                        <Trans>
                                                                            <ClaimFeeButton>
                                                                                CLAIM FEE
                                                                            </ClaimFeeButton>
                                                                        </Trans>
                                                                    </ThemedText.BodySecondary>
                                                                </>
                                                            )}
                                                        </ButtonConfirmed>
                                                    </div>
                                                ) : null}
                                            </RowBetween>
                                        </AutoColumn>
                                        <Line></Line>
                                        <AutoColumn gap="md">
                                            <RowBetween>
                                                <RowFixed>
                                                    <CurrencyLogo
                                                        currency={feeValueUpper?.currency}
                                                        size="32px"
                                                        style={{ marginRight: '0.5rem' }}
                                                    />
                                                    <CurrencySymbolTxt>
                                                        {feeValueUpper?.currency?.symbol}
                                                    </CurrencySymbolTxt>
                                                </RowFixed>
                                                <RowFixed>
                                                    <CurrencySymbolTxt>
                                                        {feeValueUpper ? formatCurrencyAmount(feeValueUpper, 4) : '-'}
                                                    </CurrencySymbolTxt>
                                                </RowFixed>
                                            </RowBetween>
                                            <RowBetween>
                                                <RowFixed>
                                                    <CurrencyLogo
                                                        currency={feeValueLower?.currency}
                                                        size="32px"
                                                        style={{ marginRight: '0.5rem' }}
                                                    />
                                                    <CurrencySymbolTxt>
                                                        {feeValueLower?.currency?.symbol}
                                                    </CurrencySymbolTxt>
                                                </RowFixed>
                                                <RowFixed>
                                                    <CurrencySymbolTxt>
                                                        {feeValueLower ? formatCurrencyAmount(feeValueLower, 4) : '-'}
                                                    </CurrencySymbolTxt>
                                                </RowFixed>
                                            </RowBetween>
                                        </AutoColumn>
                                        {showCollectAsWeth && (
                                            <AutoColumn gap="md" style={{ marginTop: "14px" }}>
                                                <RowBetween>
                                                    <div style={{ color: '#4B2828' }}>
                                                        <Trans>Collect as {nativeWrappedSymbol}</Trans>
                                                    </div>
                                                    <Toggle
                                                        id="receive-as-weth"
                                                        isActive={receiveWETH}
                                                        toggle={() => setReceiveWETH((receiveWETH) => !receiveWETH)}
                                                    />
                                                </RowBetween>
                                            </AutoColumn>
                                        )}
                                    </AutoColumn>
                                </GmeowCart>
                                <GmeowCart>
                                    <AutoColumn>
                                        <Section4>
                                            <CurrentPriceCard
                                                inverted={inverted}
                                                pool={pool}
                                                currencyQuote={currencyQuote}
                                                currencyBase={currencyBase}
                                            />

                                            <CurrentPriceCardWrapper justifyContent={"space-between"}
                                                alignItems={'end'}>
                                                <RowFixed>
                                                    <HideExtraSmall>
                                                        <>
                                                            <RangeBadge removed={removed} inRange={inRange} />
                                                            <span style={{ width: '8px' }} />
                                                        </>
                                                    </HideExtraSmall>
                                                </RowFixed>
                                                <RowFixed>
                                                    {currencyBase && currencyQuote && (
                                                        <RateToggle
                                                            currencyA={currencyBase}
                                                            currencyB={currencyQuote}
                                                            handleRateToggle={() => setManuallyInverted(!manuallyInverted)}
                                                        />
                                                    )}
                                                </RowFixed>
                                            </CurrentPriceCardWrapper>
                                        </Section4>
                                        <Line></Line>
                                        <RowBetween>
                                            <LightCard padding="12px" width="100%">
                                                <AutoColumn gap="16px" justify="start">
                                                    <ExtentsText color={'rgba(75, 40, 40, 0.50)'} fontSizeMb={'16px'}>
                                                        <Trans>Min price</Trans>
                                                    </ExtentsText>
                                                    <CurrentPriceCardInner>
                                                        <ExtentsText fontSize={'32px'} fontSizeMb={'21px'}>
                                                            {formatTickPrice({
                                                                price: priceLower,
                                                                atLimit: tickAtLimit,
                                                                direction: Bound.LOWER,
                                                            })}
                                                        </ExtentsText>
                                                        <ExtentsText color={'#E5B670'} fontSize={'24px'}
                                                            fontSizeMb={'10px'}>
                                                            {' '}
                                                            <Trans>
                                                                {currencyQuote?.symbol} per {currencyBase?.symbol}
                                                            </Trans>
                                                        </ExtentsText>
                                                    </CurrentPriceCardInner>
                                                    {inRange && (
                                                        <ExtentsText color={'#E5B670'} lineHeight={'125%'}
                                                            fontSizeMb={'12px'}>
                                                            <Trans>Your position will be 100% {currencyBase?.symbol} at
                                                                this
                                                                price.</Trans>
                                                        </ExtentsText>
                                                    )}
                                                </AutoColumn>
                                            </LightCard>

                                            <LightCard padding="12px" width="100%">
                                                <AutoColumn gap="16px" justify="start">
                                                    <ExtentsText color={'rgba(75, 40, 40, 0.50)'} fontSizeMb={'16px'}>
                                                        <Trans>Max price</Trans>
                                                    </ExtentsText>
                                                    <CurrentPriceCardInner>
                                                        <ExtentsText fontSize={'32px'} fontSizeMb={'21px'}>
                                                            {formatTickPrice({
                                                                price: priceLower,
                                                                atLimit: tickAtLimit,
                                                                direction: Bound.UPPER,
                                                            })}
                                                        </ExtentsText>
                                                        <ExtentsText color={'#E5B670'} fontSize={'24px'}
                                                            fontSizeMb={'10px'}>
                                                            {' '}
                                                            <Trans>
                                                                {currencyQuote?.symbol} per{currencyBase?.symbol}
                                                            </Trans>
                                                        </ExtentsText>
                                                    </CurrentPriceCardInner>
                                                    {inRange && (
                                                        <ExtentsText color={'#E5B670'} lineHeight={'125%'}
                                                            fontSizeMb={'12px'}>
                                                            <Trans>Your position will be 100% {currencyQuote?.symbol} at
                                                                this price.</Trans>
                                                        </ExtentsText>
                                                    )}
                                                </AutoColumn>
                                            </LightCard>
                                        </RowBetween>

                                    </AutoColumn>
                                </GmeowCart>
                            </AutoColumn>
                        </AutoColumn>
                    </div>
                </PageWrapper>
                {/* <SwitchLocaleLink /> */}
            </>
        </Trace>
    )
}
