import { notification } from "antd";
import { DEFAULT_CHAINID } from "constants/chains";


export default function checkUnsupportChainId(chainId: number = 0) {
    if (chainId != DEFAULT_CHAINID) {
        notification.warning({
            message: 'Please switch chain to Zircuit Mainnet to use this feature'
        })
        throw `${chainId} is not support`
    }
}