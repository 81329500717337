import React, { useState } from 'react'
import styled from 'styled-components'
import { CloseIcon, CustomLightSpinner, ThemedText } from '../../theme'
type CoinGeckoChartProps = {
  url: string
}

const Circle = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/blue-loader.svg'

// Create a styled iframe component with responsive styles
const StyledIframe = styled.iframe`
  width: 100%;
  height: 450px; // Default height
  border: none;

  @media (max-width: 1297px) {
    height: 450px;
  }

  @media (max-width: 767px) {
    height: 450px;
  }
`
const CoinGeckoChart: React.FC<CoinGeckoChartProps> = ({ url }) => {
  const [loading, setLoading] = useState(true)

  const handleIframeLoad = () => {
    setLoading(false)
  }
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {/* {loading ? <CustomLightSpinner src={Circle} alt="loader" size="22px" /> : null} */}
      <StyledIframe src={url} title="CoinGecko Chart" onLoad={handleIframeLoad} />
    </div>
  )
}

export default CoinGeckoChart
