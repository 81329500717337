import create from "zustand";
import { devtools } from "zustand/middleware";

type BoxStoreType = {
  totalOpennedAmount: number;
  totalParticipant: number;
  totalRewardEarned: {
    totalXMono: number;
    totalUSD: number;
    totalMonoPass: number;
  };
  totalNftChest1: number
  totalNftChest2: number
  totalNftChest3: number
  totalNftChest4: number
  totalNftChest5: number
};

const initBox = {
  totalOpennedAmount: 0,
  totalParticipant: 0,
  totalRewardEarned: {
    totalXMono: 0,
    totalUSD: 0,
    totalMonoPass: 0,
  },
  totalNftChest1: 0,
  totalNftChest2: 0,
  totalNftChest3: 0,
  totalNftChest4: 0,
  totalNftChest5: 0,
};
export const useBoxStore = create<BoxStoreType>()(
  devtools((set, get) => ({
    ...initBox,
  }))
);
