
import { Token } from '@uniswap/sdk-core'
import blsteIcon from '../assets/images/BLSTE.jpg'
import {
  USDT_ZIRCUIT_TESTNET,
  USDC_ZIRCUIT_TESTNET, WETH_ZIRCUIT, MAID_ZIRCUIT, xGM_ZIRCUIT, BTC_ZIRCUIT_TESTNET
} from './tokens'

const musdIcon = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/musd/musd.svg'
const usdbIcon = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/musd/usdb.svg'
const tmonoIcon = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/maestro/musd1.svg'
const ethIcon = 'https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/assets/images/swap/eth.png'
const btcIcon = 'https://assets.coingecko.com/coins/images/1/standard/bitcoin.png'
const usdcIcon = 'https://assets.coingecko.com/coins/images/6319/standard/usdc.png'
const BTC = {
  logo: btcIcon,
  name: 'BTC',
  address: '0xcF749620571A3920a27CD8Eef17Daa1db7C93c4C',
}
const ETH = {
  logo: ethIcon,
  name: 'ETH',
  address: 'ETH',
}
const MONO = {
  logo: tmonoIcon,
  name: 'tMONO',
  address: '0xa07aC8cDe2a98B189477b8e41F0c2Ea6CdDbC055',
}
const USDB = {
  logo: usdbIcon,
  name: 'USDB',
  address: '0x4300000000000000000000000000000000000003',
}
const MUSD = {
  logo: musdIcon,
  name: 'MUSD',
  address: '0x837fE561e9C5DFa73F607fDa679295DBC2Be5E40',
}
const xMONO = {
  logo: tmonoIcon,
  name: 'xMONO',
  address: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
}
const BLSTE = {
  logo: blsteIcon,
  name: 'BLSTE',
  address: '0x5f49349fB82454d4cA935f3e2e736bD5BE556578',
}
// pool alpha infomation
interface AlphaPoolAddress {
  [chainId: number]: {
    currencyIdA: string
    currencyIdB: string
    pool: string
    incentiveToken: string
    poolName: string
    id: number
  }[]
}
interface AlphaPoolAddressV2 {
  [chainId: number]: {
    poolName: string
    _stakedToken: string
    _rewardToken: string
    _poolAddress: string
    _pairToken: any
    _poolEndTime: any
    currencyIdA: string
    currencyIdB: string
    incentiveToken: string
  }[]
}

export const alphaPoolAddress: AlphaPoolAddress = {
  1: [],
  48899: [
    {
      currencyIdA: BTC_ZIRCUIT_TESTNET.address,
      currencyIdB: USDC_ZIRCUIT_TESTNET.address,
      pool: '0xaf08b70CbA81d17c4A15a1d31e7286b0328a4CA8',
      incentiveToken: '0x77f753D9bD8346F5c8ae0015d7cC84b266422694',
      poolName: 'BTC/USDC',
      id: 0,
    },
  ],
  48900: [
    {
      currencyIdA: MAID_ZIRCUIT.address,
      currencyIdB: WETH_ZIRCUIT.address,
      pool: '0x8135c146d9666f25e939e2857A009d222ef0d75E',
      incentiveToken: xGM_ZIRCUIT.address,
      poolName: 'MAID/WETH',
      id: 0,
    },
  ]
}
export const alphaPoolAddressV2: AlphaPoolAddressV2 = {
  1: [],
  // 168587773: [
  //   {
  //     poolName: 'WETH/TEST',
  //     _stakedToken: '0x55A24858C097a25D123adf21fE45C1ec8F591964',
  //     _rewardToken: '0x1b71B460e914FEF538C2AE177a954e0Ce5fCe314',
  //     _poolAddress: '0x6DB5eAA1FddAfFF239F251Bf5269570EF8060335',
  //     _pairToken: ETH,
  //     _poolEndTime: '1721149200',
  //     currencyIdA: WETH_BLAST,
  //     currencyIdB: 'TEST_TOKEN',
  //     incentiveToken: ''
  //   },
  // ],
  //Mainnet
  // 81457: [
  //   {
  //     poolName: 'WETH/ZKDX',
  //     _stakedToken: ZKDX_BLAST,
  //     _rewardToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
  //     _poolAddress: '0xC5159fA55C8f06a879D00D521B8654a76EE51C8f',
  //     _pairToken: ETH,
  //     _poolEndTime: '1721229803',
  //     currencyIdA: WETH_BLAST,
  //     currencyIdB: ZKDX_BLAST,
  //     incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
  //   },
  //   {
  //     poolName: 'WETH/USDB',
  //     _stakedToken: USDB_BLAST,
  //     _rewardToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
  //     _poolAddress: '0xdc0FFF238B563BA9b2f5832311a72F124E389aAF',
  //     _pairToken: ETH,
  //     _poolEndTime: '1724166000',
  //     currencyIdA: WETH_BLAST,
  //     currencyIdB: USDB_BLAST,
  //     incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
  //   },
  // ],

}

// poolist and trending pair infomation

export const PoolList = {
  v3: [
    {
      token1: ETH,
      token2: USDB,
      fee: '0.3',
      apr: '216.38',
      liquidityUsd: '513,608',
      volume24h: '605,704',
      token1liquidity: '75.8',
      token2liquidity: '256,801',
      famPool: {
        status: false,
        address: '0x0d44f3f22f917cc57368a6d30ba18d5801789cd9',
      },
      pairAddress: {
        trending: true,
        address: '0x0d44f3f22f917cc57368a6d30ba18d5801789cd9',
      },
    },

    {
      token1: MUSD,
      token2: USDB,
      fee: '0.3',
      apr: '126.5',
      liquidityUsd: '563,854',
      token1liquidity: '55,780',
      token2liquidity: '56,385',
      volume24h: '789,397',
      famPool: {
        status: false,
        address: '',
      },
      pairAddress: {
        trending: true,
        address: '0x3002961f2166277060d03cf76f7e7082713cf3b6',
      },
    },
    {
      token1: MUSD,
      token2: ETH,
      fee: '0.3',
      apr: '58.33',
      liquidityUsd: '462,869',
      token1liquidity: '42,079 ',
      token2liquidity: '12.39 ',
      volume24h: '601,729.7',
      famPool: {
        status: false,
        address: '',
      },
      pairAddress: {
        trending: true,
        address: '0x6d57bb116b3b64b27dc55e9267e304e7eb77c76d',
      },
    },
  ],
  v2: [
    // {
    //   token1: WIF,
    //   token2: ETH,
    //
    //   apr: '483.62',
    //   liquidityUsd: '253,874',
    //   volume24h: '1,001,000',
    //   token1liquidity: '295,250 ',
    //   token2liquidity: '37.74',
    // },
    // {
    //   token1: ETH,
    //   token2: MONO,
    //   apr: '15.23',
    //   liquidityUsd: '241,531',
    //   token1liquidity: '14.1',
    //   token2liquidity: '51,312',
    //   volume24h: '32,521',
    // },
  ],
}

export const trendingPair = {
  v3: [
    {
      token1: ETH,
      token2: USDB,

      famPool: {
        status: false,
        address: '0x0d44f3f22f917cc57368a6d30ba18d5801789cd9',
      },
      pairAddress: {
        trending: true,
        address: '0x0d44f3f22f917cc57368a6d30ba18d5801789cd9',
      },
      pairSelect1: 'ETHUSDB',
      pairSelect2: 'USDBETH',
    },

    {
      token1: MUSD,
      token2: USDB,

      famPool: {
        status: false,
        address: '',
      },
      pairAddress: {
        trending: true,
        address: '0x52b3b26541e5347ba22c17334a12ca759f221d8f',
      },
      pairSelect1: 'MUSDUSDB',
      pairSelect2: 'USDBMUSD',
    },
    {
      token1: MUSD,
      token2: ETH,

      famPool: {
        status: false,
        address: '0xfb45661dffa7e768c1d03b5aa9cd9e7f55032c1c',
      },
      pairAddress: {
        trending: true,
        address: '0xfb45661dffa7e768c1d03b5aa9cd9e7f55032c1c',
      },
      pairSelect1: 'MUSDETH',
      pairSelect2: 'ETHMUSD',
    },
    // {
    //   token1: WIF,
    //   token2: ETH,
    //
    //   famPool: {
    //     status: false,
    //     address: '',
    //   },
    //   pairAddress: {
    //     trending: true,
    //     address: '0x3110ac04d332779b1deaec652361d745da0cc041',
    //   },
    //   pairSelect1: 'WIFETH',
    //   pairSelect2: 'ETHWIF',
    // },
    // {
    //   token1: BLSTE,
    //   token2: ETH,

    //   famPool: {
    //     status: false,
    //     address: '',
    //   },
    //   pairAddress: {
    //     trending: true,
    //     address: '0x75993a51fc9e8b7161c3f90ee5acdb6e418cabcf',
    //   },
    //   pairSelect1: 'BLSTEETH',
    //   pairSelect2: 'ETHBLSTE',
    // },
  ],
}
