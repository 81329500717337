import { ChangeEvent, useCallback } from 'react';
import styled from 'styled-components/macro';
import { Slider as AntdSlider } from "antd";
import { BREAKPOINTS } from 'theme';

const StyledRangeInput = styled.input<{ width: number; widthMb: number; height: number; heightMb: number; value: number; maxValue: number }>`
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &::-moz-focus-outer {
        border: 0;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: ${({ height }) => height}px;
        width: ${({ width }) => width}px;
        background-image: url("https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/remove-liquidity-v3/img_4.png");
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
        transform: ${({ value, maxValue }) => value == maxValue ? "translate(13%, -70%)" : "translate(-10%, -70%)"};
        transition: transform 0.3s ease, scale 0.3s ease, width 0.3s ease, height 0.3s ease;
        @media (max-width: 747px) {
            height: ${({ heightMb }) => heightMb}px;
            width: ${({ widthMb }) => widthMb}px;
        }

        &:hover,
        &:focus {
            scale: 1.02;
        }
    }

    &::-moz-range-thumb {
        height: ${({ height }) => height}px;
        width: ${({ width }) => width}px;
        background-image: url("https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/remove-liquidity-v3/img_4.png");
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
        transform: ${({ value }) => value == 100 ? "translate(13%, -70%)" : "translate(-10%, -70%)"};
        transition: transform 0.3s ease, scale 0.3s ease, width 0.3s ease, height 0.3s ease;
        @media (max-width: 747px) {
            height: ${({ heightMb }) => heightMb}px;
            width: ${({ widthMb }) => widthMb}px;
        }

        &:hover,
        &:focus {
            scale: 1.02;
        }
    }

    &::-ms-thumb {
        height: ${({ height }) => height}px;
        width: ${({ width }) => width}px;
        background-image: url("https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/remove-liquidity-v3/img_4.png");
        background-repeat: no-repeat;
        background-size: cover;
        transform: ${({ value }) => value == 100 ? "translate(13%, -70%)" : "translate(-10%, -70%)"};
        transition: transform 0.3s ease, scale 0.3s ease, width 0.3s ease, height 0.3s ease;
        @media (max-width: 747px) {
            height: ${({ heightMb }) => heightMb}px;
            width: ${({ widthMb }) => widthMb}px;
        }

        &:hover,
        &:focus {
            scale: 1.02;
        }
    }

    &::-webkit-slider-runnable-track {
        border-radius: 50px;
        height: 8px;
        border: 1.5px solid #D7903C;
        background: linear-gradient(
        to right,
        #D7903C ${({ value }) => value}%,
        #FFF6D0 ${({ value }) => value}%
        );
    }

    &::-moz-range-track {
        border-radius: 50px;
        height: 8px;
        border: 1.5px solid #D7903C;
        background: linear-gradient(
        to right,
        #D7903C ${({ value }) => value}%,
        #FFF6D0 ${({ value }) => value}%
        );
    }

    &::-ms-track {
        border-radius: 50px;
        height: 8px;
        border: 1.5px solid #D7903C;
        background: linear-gradient(
        to right,
        #D7903C ${({ value }) => value}%,
        #FFF6D0 ${({ value }) => value}%
        );
    }

    &::-ms-fill-lower {
        border-radius: 50px;
        height: 8px;
        border: 1.5px solid #D7903C;
        background: #D7903C;
    }

    &::-ms-fill-upper {
        border-radius: 50px;
        height: 8px;
        border: 1.5px solid #FFF6D0;
        background: #FFF6D0;
    }
`;

const SliderWrapper = styled.div`   
    .slider_mobile{
        display: none;
    }

    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        .slider_desktop{
            display: none;
        }
        .slider_mobile{
            display: block;
        }
    }
`
interface InputSliderProps {
    value: number;
    onChange: (value: number) => void;
    step?: number;
    min?: number;
    max?: number;
    width?: number;
    height?: number;
    widthMb?: number;
    heightMb?: number;
}

export default function Slider({
    value,
    onChange,
    min = 0,
    step = 1,
    max = 100,
    width = 43,
    height = 40,
    widthMb = 33,
    heightMb = 30,
    ...rest
}: InputSliderProps) {
    const changeCallback = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            onChange(parseInt(e.target.value));
        },
        [onChange]
    );

    return (
        <SliderWrapper>
            <StyledRangeInput
                className='slider_desktop'
                width={width}
                height={height}
                widthMb={widthMb}
                heightMb={heightMb}
                value={value}
                maxValue={max}
                {...rest}
                type="range"
                style={{ padding: '15px 0' }}
                onChange={changeCallback}
                aria-labelledby="input slider"
                step={step}
                min={min}
                max={max}
            />

            <AntdSlider className='slider_mobile' value={value} onChange={(value) => onChange(value)} tooltip={{ open: false }} />
        </SliderWrapper>
    );
}
