import { useWeb3React } from "@web3-react/core"
import AlphaPositionV3List from "./AlphaPositionV3List"
import { useEffect, useState } from "react"
import { DEFAULT_CHAINID } from "constants/chains"
import { useMultiplePositions } from "hooks/useMultiplePositions"
import { useCustomeContract } from "hooks/useContract"
import { NONFUNGIBLE_POSITION_MANAGER_ADDRESSES, V3STAKER_ADDRESSES_MAP } from "constants/addresses"
import v3staker_abi from '../../abis/alpha/V3Staker.json'
import ERC721 from '../../abis/erc721.json'
import { Currency, CurrencyAmount } from "@uniswap/sdk-core"
import { ethers } from "ethers"
import { NotchedButtonFill } from "components/Button"
import styled from "styled-components"
import { notification } from "antd"
import { PoolV3Info } from "hooks/useAlphaStore"
import { getGasPriceSetting } from "utils/gasSetting"

const ButtonBox = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
`

const mapData = (
  data: any,
  positions: {
    liquidityValue0?: string
    liquidityValue1?: string
    currency0?: CurrencyAmount<Currency>
    currency1?: CurrencyAmount<Currency>
    outOfRange?: boolean
  }[]
) => {
  return data.map((item: any, index: number) => {
    const position = positions[index]
    return {
      tokenId: item.tokenId,
      formattedTokenId: item.tokenId.toString(),
      fee: item.fee,
      liquidity: Number(ethers.utils.formatUnits(item.liquidity, 6)).toFixed(5).toString(),
      tickLower: item.tickLower.toString(),
      tickUpper: item.tickUpper.toString(),
      currency0: position?.currency0 ?? null,
      currency1: position?.currency1 ?? null,
      token0: item?.token0 ?? null,
      token1: item?.token1 ?? null,
      tokensOwed0: Number(ethers.utils.formatUnits(item.tokensOwed0, 18)).toFixed(2).toString(),
      tokensOwed1: Number(ethers.utils.formatUnits(item.tokensOwed1, 18)).toFixed(2).toString(),
      liquidityValue0: position?.liquidityValue0 ?? null,
      liquidityValue1: position?.liquidityValue1 ?? null,
      outOfRange: position?.outOfRange ?? false,
    }
  })
}



type AlphaDepositModalProps = {
  isOpen: boolean
  onDismiss: () => void,
  userPosition: any
  tokenInfo: any
  poolInfo: PoolV3Info
  handleDataFromChild: any
  setTrigger: any
}
export default function AlphaDepositModal({
  isOpen,
  onDismiss,
  userPosition,
  tokenInfo,
  poolInfo,
  handleDataFromChild,
  setTrigger,
}: AlphaDepositModalProps) {
  const { account, chainId } = useWeb3React()
  // const { positions, loading } = useV3Positions(account)
  const [ethPrice, setEthPrice] = useState(3000)
  const [customeChainId, setCustomeChainId] = useState(DEFAULT_CHAINID)
  useEffect(() => {
    if (chainId) {
      setCustomeChainId(chainId)
    } else {
      setCustomeChainId(DEFAULT_CHAINID)
    }
  }, [account, chainId])
  const positions = useMultiplePositions(userPosition)
  const mappedData = mapData(userPosition, positions)


  const [checkedIds, setCheckedIds] = useState<number[]>([])
  const onCheckedNftId = (formattedTokenId: number, isChecked: boolean) => {
    if (isChecked)
      setCheckedIds([formattedTokenId])
    else
      setCheckedIds(checkedIds.filter(nftId => nftId != formattedTokenId))
  }

  useEffect(() => {
    // fetchPrice().then((price) => {
    //   setEthPrice(price || 0)
    // })
  }, [])

  function mapToDesiredStructure(checkedIds: any, poolInfo: PoolV3Info) {
    return checkedIds.map((id: any, index: any) => {
      return {
        ['Data']: [
          {
            rewardToken: poolInfo.rewardToken,
            pool: poolInfo.pool,
            startTime: ethers.BigNumber.from(poolInfo.startTime),
            endTime: ethers.BigNumber.from(poolInfo.endTime),
            refundee: poolInfo.refundee,
            lockDuration: ethers.BigNumber.from(poolInfo.lockDuration),
          },
          ethers.BigNumber.from(id),
        ],
      }
    })
  }

  const NFT_ADDRESS = NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[customeChainId]
  const STAKING_ADDRESS = V3STAKER_ADDRESSES_MAP[customeChainId]

  const v3stakerWithSign = useCustomeContract(STAKING_ADDRESS, v3staker_abi, true)

  const [loadingDeposit, setLoadingDeposit] = useState(false)

  const NftContractWithSign = useCustomeContract(NFT_ADDRESS, ERC721, true)
  const [loadingApproveNft, setLoadingApproveNft] = useState(false)
  const [isApproveNft, setIsApproveNft] = useState(false)

  const ApproveNFTContract = async () => {
    setLoadingApproveNft(true)
    try {
      const setApproveContract = await NftContractWithSign?.setApprovalForAll(STAKING_ADDRESS, true)
      await setApproveContract.wait()
      setLoadingApproveNft(false)
      return true
    } catch (error) {
      console.log(error)
      setLoadingApproveNft(false)
      return false
    }
  }
  const handleCheckApproveNFTContract = async () => {
    try {
      const isApproved = await NftContractWithSign?.isApprovedForAll(account, STAKING_ADDRESS)

      if (isApproved) {
        setIsApproveNft(true)
      } else {
        setIsApproveNft(false)
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  const handleApproveNFTContract = async () => {
    await ApproveNFTContract()
    handleCheckApproveNFTContract()
  }

  useEffect(() => {
    if (account !== null && account !== undefined) {
      handleCheckApproveNFTContract()
    }
  }, [account, chainId])

  // deposit function
  const depositNft = async () => {
    if (checkedIds.length == 0) {
      notification.warning({
        message: 'Please choose nft that you want stake'
      })
      return
    }
    setLoadingDeposit(true)
    try {
      const depositParams = mapToDesiredStructure(checkedIds, poolInfo)

      const stakeTokenCalldata = v3stakerWithSign?.interface.encodeFunctionData('depositAndStake', [
        depositParams[0]?.Data[0],
        depositParams[0]?.Data[1],
      ])

      const gasEstimate = await v3stakerWithSign?.estimateGas.multicall([stakeTokenCalldata]);
      const gasLimit = Math.floor(gasEstimate ? gasEstimate.toNumber() * 2 : 200000)
      const gasPrice = undefined

      const tx = await v3stakerWithSign?.multicall([stakeTokenCalldata], {
        gasPrice,
        gasLimit
      })

      await tx.wait()
      if (tx) {
        console.log('tx', tx)
        setLoadingDeposit(false)
        getUserStakeInfo()
        // getContractInfo()
        setTrigger(true)
        setCheckedIds([])
      }
      setLoadingDeposit(false)
    } catch (error) {
      console.log(error)
      setLoadingDeposit(false)
    }
  }
  const getUserStakeInfo = async () => {
    try {
      const tokenIdByUser = await v3stakerWithSign?.getTokensByOwner(account, '0', '100')
      const items = await Promise.all(
        tokenIdByUser?.map(async (item: any) => {
          return {
            tokenId: item.tokenId.toString(),
            poolAddress: item.incentiveKey.pool,
          }
        })
      )
      let filterByPool = items?.filter((item: any) => {
        return item?.poolAddress?.toLowerCase() === poolInfo?.pool?.toLowerCase()
      })
      handleDataFromChild(filterByPool?.length)
    } catch (error) {
      console.log(error)
    }
  }


  const ModalBottom = () => {
    return <>
      {
        mappedData.length == 0 ? <div style={{ marginTop: '20px' }}>
          <NotchedButtonFill
            // isShowDirectly={true} bg="#E5B670" textColor="#FFF9E1" 
            mbEarMaxW="110px"
            onClick={onDismiss}>
            CANCEL
          </NotchedButtonFill>
        </div>
          :
          <ButtonBox>
            <NotchedButtonFill
              // isShowDirectly={true} bg="#E5B670" textColor="#FFF9E1"
              mbEarMaxW="110px"
              onClick={onDismiss}>
              CANCEL
            </NotchedButtonFill>
            {
              isApproveNft ? <NotchedButtonFill
                mbEarMaxW="110px"
                onClick={depositNft}
              // isShowDirectly={true} bg="#00E440"  textColor="#FFF9E1"
              >
                {loadingDeposit ? 'STAKING...' : 'STAKE'}
              </NotchedButtonFill>
                : <NotchedButtonFill
                  // isShowDirectly={true} bg="#00E440"  textColor="#FFF9E1"
                  onClick={handleApproveNFTContract}
                >
                  {
                    loadingApproveNft ? 'APPROVING...' : 'APPROVE'
                  }
                </NotchedButtonFill>
            }
          </ButtonBox>
      }
    </>
  }

  return <AlphaPositionV3List
    title="Stake to"
    desc="Deposit your LP to earn extra incentives"
    isOpen={isOpen}
    onDismiss={onDismiss}
    loading={loadingDeposit}
    nftInfoList={mappedData}
    checkedNftIds={checkedIds}
    onCheckedNftId={onCheckedNftId}
    showPositionType='DEPOSIT'
    modalBottom={<ModalBottom />}
    tokenCurrencyA={tokenInfo[0].currencyA}
    tokenCurrencyB={tokenInfo[0].currencyB}
  />
}