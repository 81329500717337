// a list of tokens by chain
import { Currency, Token } from '@uniswap/sdk-core'

import { SupportedChainId } from './chains'
import {
  DAI,
  nativeOnChain,
  USDC_MAINNET,
  USDT,
  WRAPPED_NATIVE_CURRENCY,
  USDC_ZIRCUIT_TESTNET,
  USDT_ZIRCUIT_TESTNET,
  WETH_ZIRCUIT_TESTNET,
  BTC_ZIRCUIT_TESTNET,
  PAW_ZIRCUIT_TESTNET,
  MAID_ZIRCUIT,
  ZIRCUIT_TOKEN,
} from './tokens'

type ChainTokenList = {
  readonly [chainId: number]: Token[]
}

type ChainCurrencyList = {
  readonly [chainId: number]: Currency[]
}

const WRAPPED_NATIVE_CURRENCIES_ONLY: ChainTokenList = Object.fromEntries(
  Object.entries(WRAPPED_NATIVE_CURRENCY)
    .map(([key, value]) => [key, [value]])
    .filter(Boolean)
)

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WRAPPED_NATIVE_CURRENCIES_ONLY,
  [SupportedChainId.MAINNET]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.MAINNET], DAI, USDC_MAINNET, USDT],
  [SupportedChainId.ZIRCUIT_TESTNET]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.ZIRCUIT_TESTNET], USDC_ZIRCUIT_TESTNET, USDT_ZIRCUIT_TESTNET],
  [SupportedChainId.ZIRCUIT]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.ZIRCUIT]],
}
export const ADDITIONAL_BASES: { [chainId: number]: { [tokenAddress: string]: Token[] } } = {
  [SupportedChainId.MAINNET]: {
    // '0xF16E4d813f4DcfDe4c5b44f305c908742De84eF0': [ETH2X_FLI],
    // [rETH2.address]: [sETH2],
    // [SWISE.address]: [sETH2],
    // [FEI.address]: [TRIBE],
    // [TRIBE.address]: [FEI],
    // [FRAX.address]: [FXS],
    // [FXS.address]: [FRAX],
    // [WBTC.address]: [renBTC],
    // [renBTC.address]: [WBTC],
  },
}
/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId: number]: { [tokenAddress: string]: Token[] } } = {
  [SupportedChainId.MAINNET]: {
    // [AMPL.address]: [DAI, WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token],
  },
}

/**
 * Shows up in the currency select for swap and add liquidity
 */
export const COMMON_BASES: ChainCurrencyList = {
  [SupportedChainId.MAINNET]: [
    nativeOnChain(SupportedChainId.MAINNET),
    WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token,
  ],
  [SupportedChainId.ZIRCUIT_TESTNET]: [
    nativeOnChain(SupportedChainId.ZIRCUIT_TESTNET),
    WRAPPED_NATIVE_CURRENCY[SupportedChainId.ZIRCUIT_TESTNET] as Token,
    USDC_ZIRCUIT_TESTNET,
    USDT_ZIRCUIT_TESTNET,
    BTC_ZIRCUIT_TESTNET,
    PAW_ZIRCUIT_TESTNET
  ],
  [SupportedChainId.ZIRCUIT]: [
    nativeOnChain(SupportedChainId.ZIRCUIT),
    WRAPPED_NATIVE_CURRENCY[SupportedChainId.ZIRCUIT] as Token,
    MAID_ZIRCUIT,
    ZIRCUIT_TOKEN
  ],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WRAPPED_NATIVE_CURRENCIES_ONLY,
  [SupportedChainId.MAINNET]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.MAINNET], DAI, USDC_MAINNET, USDT],
  [SupportedChainId.ZIRCUIT_TESTNET]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.ZIRCUIT_TESTNET], USDC_ZIRCUIT_TESTNET, USDT_ZIRCUIT_TESTNET],
  [SupportedChainId.ZIRCUIT]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.ZIRCUIT]],
}

// Pinned V2 pair lp
export const PINNED_PAIRS: { readonly [chainId: number]: [Token, Token][] } = {
  [SupportedChainId.MAINNET]: [],
  [SupportedChainId.ZIRCUIT_TESTNET]: [
    [WETH_ZIRCUIT_TESTNET, USDC_ZIRCUIT_TESTNET],
    [WETH_ZIRCUIT_TESTNET, USDT_ZIRCUIT_TESTNET],
  ],
  [SupportedChainId.ZIRCUIT]: [],
}

// custome list token selected
/**
 * Shows up in the stable coin currency select for swap and add liquidity
 */
export const STABLE_COIN: ChainCurrencyList = {
  [SupportedChainId.MAINNET]: [
    nativeOnChain(SupportedChainId.MAINNET),
    WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token,
  ],
  [SupportedChainId.ZIRCUIT_TESTNET]: [USDC_ZIRCUIT_TESTNET, USDT_ZIRCUIT_TESTNET],
  [SupportedChainId.ZIRCUIT]: [],
}

// meme
export const MEME_TOKEN: ChainCurrencyList = {
  [SupportedChainId.MAINNET]: [
    nativeOnChain(SupportedChainId.MAINNET),
    WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token,
  ],
  [SupportedChainId.ZIRCUIT_TESTNET]: [],
  [SupportedChainId.ZIRCUIT]: [MAID_ZIRCUIT],
}

// defi

export const DEFI_TOKEN: ChainCurrencyList = {
  [SupportedChainId.MAINNET]: [
    nativeOnChain(SupportedChainId.MAINNET),
    WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token,
  ],
  [SupportedChainId.ZIRCUIT_TESTNET]: [PAW_ZIRCUIT_TESTNET],
  [SupportedChainId.ZIRCUIT]: [ZIRCUIT_TOKEN],
}
