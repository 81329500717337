import { useWeb3React } from "@web3-react/core"
import AlphaPositionV3List from "./AlphaPositionV3List"
import { useEffect, useMemo, useState } from "react"
import { DEFAULT_CHAINID } from "constants/chains"
import { ethers } from "ethers"
import { V3STAKER_ADDRESSES_MAP } from "constants/addresses"
import { useCustomeContract } from "hooks/useContract"
import v3staker_abi from '../../abis/alpha/V3Staker.json'
import { NotchedButtonFill } from "components/Button"
import styled from "styled-components"
import { notification } from "antd"
import { getGasPriceSetting } from "utils/gasSetting"


const ButtonBox = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
`

function calculateTotalRewardValue(data: any) {
  return data?.reduce((total: any, item: any) => {
    const rewardValue = Number(item?.rewardValue)
    return total + rewardValue
  }, 0)
}

type AlphaHarvestModalProps = {
  isOpen: boolean
  onDismiss: () => void
  poolInfo: any
  tokenInfo: any
  userPositionsLocked: any
  handleDataFromChildPendingReward: any
}
export default function AlphaHarvestModal({
  isOpen,
  onDismiss,
  poolInfo,
  tokenInfo,
  userPositionsLocked,
  handleDataFromChildPendingReward,
}: AlphaHarvestModalProps) {
  const { account, chainId } = useWeb3React()

  const [customeChainId, setCustomeChainId] = useState(DEFAULT_CHAINID)
  useEffect(() => {
    if (chainId) {
      setCustomeChainId(chainId)
    } else {
      setCustomeChainId(DEFAULT_CHAINID)
    }
  }, [account, chainId])
  // state
  const [loadingHarvest, setLoadingHarvest] = useState(false)
  const [loadingUserStakeInfo, setLoadingUserStakeInfo] = useState(false)
  const [stakeNFTList, setStakeUserList] = useState<any>([])

  // select to harvest
  const [checkedIds, setCheckedIds] = useState<number[]>([])
  const onCheckedNftId = (formattedTokenId: number, isChecked: boolean) => {
    if (isChecked)
      setCheckedIds([formattedTokenId])
    else
      setCheckedIds(checkedIds.filter(nftId => nftId != formattedTokenId))
  }

  const totalHarvestReward = useMemo(() => {
    if (checkedIds.length == 0) return 0
    let totalRw = 0
    for (let index = 0; index < stakeNFTList.length; index++) {
      const element = stakeNFTList[index];
      if (checkedIds.includes(Number(element.formattedTokenId)))
        totalRw += element.rewardValue
    }
    return totalRw
  }, [checkedIds])

  // mapped params
  function mapClaimRewardCalldata(poolInfo: any) {
    const rewardToken = poolInfo.rewardToken
    // const refundee = account
    const result = [rewardToken, account, ethers.constants.MaxUint256]
    return result
  }

  function mapStakeAndUnstakeCallData(checkedIds: any, poolInfo: any) {
    return checkedIds.map((id: any, index: any) => {
      return {
        ['Data']: [
          {
            rewardToken: poolInfo.rewardToken,
            pool: poolInfo.pool,
            startTime: ethers.BigNumber.from(poolInfo.startTime),
            endTime: ethers.BigNumber.from(poolInfo.endTime),
            lockDuration: ethers.BigNumber.from(poolInfo.lockDuration),
            refundee: poolInfo.refundee,
          },
          ethers.BigNumber.from(id),
        ],
      }
    })
  }
  function mapGetData(poolInfo: any) {
    const rewardToken = poolInfo.rewardToken
    const refundee = poolInfo.refundee
    const pool = poolInfo.pool
    const startTime = ethers.BigNumber.from(poolInfo.startTime)
    const endTime = ethers.BigNumber.from(poolInfo.endTime)
    const lockDuration = ethers.BigNumber.from(poolInfo.lockDuration)

    const result = [rewardToken, pool, startTime, endTime, lockDuration, refundee]
    return result
  }
  // using custom image

  // contract
  const STAKING_ADDRESS = V3STAKER_ADDRESSES_MAP[chainId!]

  const v3stakerWithSign = useCustomeContract(STAKING_ADDRESS, v3staker_abi, true)

  // get user stake info
  const handleGetUserInfo = async () => {
    if (loadingUserStakeInfo) return
    setLoadingUserStakeInfo(true)
    try {
      const tokenIdByUser = await v3stakerWithSign?.getTokensByOwner(account, '0', '1000')
      const items = await Promise.all(
        tokenIdByUser?.map(async (item: any, index: number) => {
          const userPosition = userPositionsLocked[index]!
          const paramsGetRewardInfo = mapGetData(poolInfo)
          const userRewardByTokenId = await v3stakerWithSign?.getRewardInfos([item.tokenId])
          let timeNowStampInSeconds = Math.floor(Date.now() / 1000)
          let lockedUntil = item.deposit.lockUntil.toString()
          let isUnstake = Number(timeNowStampInSeconds) < Number(lockedUntil) ? false : true
          let countdownTime =
            Number(lockedUntil) - Number(timeNowStampInSeconds) > 0
              ? Number(lockedUntil) - Number(timeNowStampInSeconds)
              : 0
          return {
            tokenId: item.tokenId.toString(),
            formattedTokenId: item.tokenId.toString(),
            userReward: paramsGetRewardInfo,
            rewardValue: Number(ethers?.utils?.formatUnits(userRewardByTokenId[0]?.reward, 18)),
            lockedTime: paramsGetRewardInfo[4].toString(),
            lockedUntil: lockedUntil,
            isUnstake: isUnstake,
            countdownTime: countdownTime,
            poolAddress: item.incentiveKey.pool,
            outOfRange: userPosition?.outOfRange ?? false,
            token0: poolInfo?.token0,
            token1: poolInfo?.token1
          }
        })
      )
      let filterByPool = items?.filter((item: any) => {
        return item?.poolAddress?.toLowerCase() === poolInfo?.pool?.toLowerCase()
      })

      setStakeUserList(filterByPool)
      const totalRewardValue = calculateTotalRewardValue(filterByPool)
      handleDataFromChildPendingReward(totalRewardValue)
      setLoadingUserStakeInfo(false)
    } catch (error) {
      console.log(error)
      setLoadingUserStakeInfo(false)
    }
  }

  const handleHarvest = async () => {
    if (checkedIds.length == 0) {
      notification.warning({
        message: 'Please choose nft that you want stake'
      })
      return
    }
    setLoadingHarvest(true)
    try {
      const paramClaimRewardCalldata = mapClaimRewardCalldata(poolInfo)
      const paramStakeAndUnstakeCalldata = mapStakeAndUnstakeCallData(checkedIds, poolInfo)
      const unstakeCalldata = v3stakerWithSign?.interface.encodeFunctionData(
        'unstakeToken',
        paramStakeAndUnstakeCalldata[0].Data
      )
      const claimRewardCalldata = v3stakerWithSign?.interface.encodeFunctionData('claimReward', [
        paramClaimRewardCalldata[0],
        paramClaimRewardCalldata[1],
        paramClaimRewardCalldata[2],
      ])
      const stakeTokenCalldata = v3stakerWithSign?.interface.encodeFunctionData(
        'stakeToken',
        paramStakeAndUnstakeCalldata[0].Data
      )

      const gasEstimate = await v3stakerWithSign?.estimateGas.multicall([unstakeCalldata, claimRewardCalldata, stakeTokenCalldata]);
      const gasLimit = Math.floor(gasEstimate ? gasEstimate.toNumber() * 2 : 200000)
      const gasPrice = undefined

      const tx = await v3stakerWithSign?.multicall([unstakeCalldata, claimRewardCalldata, stakeTokenCalldata], {
        gasLimit,
        gasPrice
      })
      await tx.wait()
      if (tx) {
        console.log('tx', tx)
        setLoadingHarvest(false)
        handleGetUserInfo()
        setCheckedIds([])
      }
    } catch (error) {
      console.log(error)
      setLoadingHarvest(false)
    }
  }
  // useEffect(() => {
  //   if (userPositionsLocked) {
  //     handleGetUserInfo()
  //   }
  // }, [userPositionsLocked])
  // useEffect(() => {
  //   if (account) {
  //     handleGetUserInfo()
  //   }
  // }, [account, chainId])
  useEffect(() => {
    if (isOpen) { handleGetUserInfo(); setCheckedIds([]) }
  }, [isOpen])

  useEffect(() => {
    handleGetUserInfo()
  }, [userPositionsLocked, poolInfo, tokenInfo])

  const ModalBottom = () => {
    return <>
      {
        stakeNFTList.length == 0 ? <div style={{ marginTop: '20px' }}>
          <NotchedButtonFill
            // isShowDirectly={true} bg="#E5B670" textColor="#FFF9E1" 
            mbEarMaxW="110px"
            onClick={onDismiss}>
            CANCEL
          </NotchedButtonFill>
        </div>
          :
          <ButtonBox>
            <NotchedButtonFill
              // isShowDirectly={true} bg="#E5B670" textColor="#FFF9E1"
              mbEarMaxW="110px"
              onClick={onDismiss}>
              CANCEL
            </NotchedButtonFill>
            <NotchedButtonFill
              mbEarMaxW="110px"
              onClick={handleHarvest}
            // isShowDirectly={true} bg="#00E440"  textColor="#FFF9E1"
            >
              {loadingHarvest ? 'HARVESTING...' : 'HARVEST'}
            </NotchedButtonFill>
          </ButtonBox>
      }
    </>
  }

  return <AlphaPositionV3List
    title="Harvest from"
    desc=""
    isOpen={isOpen}
    onDismiss={onDismiss}
    loading={loadingUserStakeInfo}
    nftInfoList={stakeNFTList}
    showPositionType='HARVEST'
    checkedNftIds={checkedIds}
    onCheckedNftId={onCheckedNftId}
    totalHarvestReward={totalHarvestReward}
    modalBottom={<ModalBottom />}
    tokenCurrencyA={tokenInfo[0].currencyA}
    tokenCurrencyB={tokenInfo[0].currencyB}
  />
}