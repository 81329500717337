import { ReactNode } from "react";
import styled from "styled-components";
import { LoadingOutlined } from '@ant-design/icons'


const ContentLoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
`

export default function ContentLoading({
    isLoading = false,
    children
}: {
    isLoading?: boolean;
    children: ReactNode
}) {


    return <>
        {
            isLoading ? <ContentLoadingWrapper>
                <LoadingOutlined rev={undefined} />
            </ContentLoadingWrapper>
                : <>{children} </>
        }
    </>
}