import { Percent } from '@uniswap/sdk-core'

import { warningSeverity } from '../../utils/prices'
import { ErrorText } from './styleds'
import styled from 'styled-components'

export const formatPriceImpact = (priceImpact: Percent) => {
  const percent = Number(priceImpact.multiply(-1).toSignificant())
  return ` ${Math.abs(percent) < 0.001 ? percent.toFixed(6) : percent.toFixed(2)}%`
}

const Wrapper = styled.span`
  color: #4B2828;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
`
/**
 * Formatted version of price impact text with warning colors
 */
export default function FormattedPriceImpact({ priceImpact, }: { priceImpact?: Percent }) {
  return (
    <ErrorText severity={warningSeverity(priceImpact)} >
      <Wrapper style={{
        color: Number(priceImpact?.multiply(-1).toFixed(2)) < 0 ? 'red' : '#4B2828'
      }}>
        {priceImpact ? formatPriceImpact(priceImpact) : '-'}
      </Wrapper>
    </ErrorText >
  )
}
