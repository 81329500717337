import Modal from '../Modal'
import { Trans } from '@lingui/macro'
import { Text } from 'rebass'


import styled, { useTheme } from 'styled-components/macro'
import { BREAKPOINTS, CloseIcon, CustomLightSpinner } from '../../theme'
import { AutoColumn, ColumnCenter } from '../Column'
import { ButtonLight, ButtonPrimary, NotchedButtonFillPrimary, NotchedButtonFill } from '../Button'
import { CheckCircle, AlertCircle, AlertTriangle } from 'react-feather'
import AnimatedConfirmation from '../TransactionConfirmationModal/AnimatedConfirmation'
import { useState } from 'react'
import { EnterCodePopupNote, EnterCodePopupTitle } from './MintMusdModal'
import { formatNumber } from 'utils/formatNumbers'
import { LoadingOutlined } from '@ant-design/icons'
interface RedeemMusdModalInterface {
  isOpen: boolean
  onDismiss: () => void
  loading: boolean
  errorMintMusd: boolean
  errMess: string
  usdbValue: string
  txLink: string
  txStatus: string
  handleAddCustomToken: Function
}
const Wrapper = styled.div`
  outline: none;
  width: 100%;
  border-radius: 10px;
  border: 1.5px solid #E5B670;
  background: #FFF6D0;
  box-shadow: 0px 7.395px 30px 0px rgba(145, 103, 20, 0.30);

  padding: 24px 32px;
  overflow: auto;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    padding: 24px 12px;
  }
`
const WrapperTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: #4B2828;
  }
`
const WrapperMid = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default function RedeemMusdModal({
  isOpen,
  onDismiss,
  loading,
  errorMintMusd,
  errMess,
  usdbValue,
  txLink,
  txStatus,
  handleAddCustomToken
}: RedeemMusdModalInterface) {

  const [isAddingToken, setIsAddingToken] = useState<boolean>(false)

  const handleAddToken = async () => {
    if (isAddingToken) return
    try {
      setIsAddingToken(true)
      await handleAddCustomToken()
    }
    catch (err) { }
    setIsAddingToken(false)
  }
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <WrapperTop>
          <p> </p>
          <CloseIcon onClick={onDismiss} />
        </WrapperTop>

        {
          txStatus == 'pending' && <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CustomLightSpinner src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/swap/swap-spinner.svg" alt="loader" size={'120px'} />
            </div>
            <EnterCodePopupTitle style={{
              maxWidth: '473px',
              margin: 'auto',
              marginBottom: '0',
              marginTop: '32px'
            }}>
              Minting
            </EnterCodePopupTitle>

            <EnterCodePopupNote style={{
              fontWeight: 500,
              display: 'block',
              textAlign: 'center',
              marginTop: '0px',
              marginBottom: 0,
              color: '#4B2828'
            }}>
              Confirm tx from your wallet
            </EnterCodePopupNote>
          </>
        }
        {
          txStatus == 'failed' &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
              <img style={{ width: '100px', height: '100px' }}
                src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/transaction-status/failed.png" />
            </div>
            <EnterCodePopupTitle
              style={{ maxWidth: '473px', margin: 'auto', color: '#FF1E56' }}>
              Failed transaction
            </EnterCodePopupTitle>
            <EnterCodePopupNote style={{
              fontWeight: 500,
              display: 'block',
              textAlign: 'center',
              marginTop: '6px',
              marginBottom: 0,
              color: '#4B2828'
            }}>
              Please try again
            </EnterCodePopupNote>
            {
              txLink && <div style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center',
                marginBottom: '5px', marginTop: '6px', cursor: 'pointer'
              }}
                onClick={() => txLink && window.open(txLink, '_blank')}
              >
                <span
                  style={{ borderBottom: '1px solid #EEAA01', fontSize: '16px', color: '#EEAA01' }}>View
                  Tx</span>
              </div>
            }
          </>
        }

        {
          txStatus == 'success' &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
              <img style={{ width: '100px', height: '100px' }}
                src="https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/transaction-status/success.png" />
            </div>
            <EnterCodePopupTitle
              style={{ width: '351px', margin: 'auto', color: '#00E440' }}>
              Successfully redeemed
            </EnterCodePopupTitle>
            <EnterCodePopupNote style={{
              fontWeight: 500,
              display: 'block',
              textAlign: 'center',
              marginTop: '6px',
              marginBottom: 0,
              color: '#4B2828'
            }}>
              You’ve successfully redeemed {formatNumber(usdbValue)} PAW
            </EnterCodePopupNote>

            {
              txLink && <div style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center',
                marginBottom: '5px', marginTop: '6px', cursor: 'pointer'
              }}
                onClick={() => txLink && window.open(txLink, '_blank')}
              >
                <span
                  style={{ borderBottom: '1px solid #EEAA01', fontSize: '16px', color: '#EEAA01' }}>View
                  Tx</span>
              </div>
            }
            <p style={{ color: '#00E440', fontSize: '16px', textAlign: 'center', cursor: 'pointer' }} onClick={handleAddToken}>
              Add to Metamask {isAddingToken && <LoadingOutlined rev={undefined} />}
            </p>
          </>
        }
      </Wrapper>
    </Modal>
  )
}
