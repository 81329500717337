import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
import { NotchedButtonFill } from 'components/Button'
import Modal from 'components/Modal'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'

import ClaimedXpHistory from './sections/ClaimedXpHistory'
import XpLeaderboard from './sections/XpLeaderboard'
import XpTitle from './sections/XpTitle'

const MainQuestXpWapper = styled.div`
  width: 100%;
  margin: 0px auto;
  background: url('https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/xp/bg.png');
  background-size: cover;
`;


const TaskModalInfoWrapper = styled.div`
    padding: 23px 32px;
    /* max-width: 700px; */
    width: 100%;
    margin: 0 auto;
`;

const TaskModalInfoTitleBox = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
`;

const TaskModalInfoTitle = styled.div`
    color: #4B2828;
    font-family: Righteous;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 30px */
    width: calc(100% - 24px);
`;

const TaskModalListItemContainer = styled.div`
    display: grid;
    gap: 16px;
`;
const TaskModalListItemBox = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
`;
const TaskModalText = styled.p`
    color: #FFF;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 125%; /* 20px */
    margin: 0;
    span {
      font-family: Urbanist;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      line-height: 125%; /* 20px */
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      width: 100%;
    }
`;
const TaskModalListIcon = ({ w, h }: { w?: string; h?: string }) => <svg width={w ?? "12"} height={h ?? "12"} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="5.65625" y="0.299805" width="8" height="8" transform="rotate(45 5.65625 0.299805)" fill="url(#paint0_linear_132_11725)" />
  <defs>
    <linearGradient id="paint0_linear_132_11725" x1="5.65625" y1="3.93299" x2="13.6562" y2="3.93299" gradientUnits="userSpaceOnUse">
      <stop stopColor="#68FFFF" />
      <stop offset="1" stopColor="#68FF9B" />
    </linearGradient>
  </defs>
</svg>

const TaskModalLinerText1 = styled.span<{
  bg?: string
}>`
    background: ${({ bg }) => bg ?? 'linear-gradient(90deg, #68FFFF 6.39%, #68FF9B 19.45%)'};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FFF;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 20px */
`;

const TaskModalLinerText2 = styled.span<{
  bg?: string
}>`
    background: ${({ bg }) => bg ?? 'linear-gradient(90deg, #68FFFF 24.75%, #68FF9B 35.07%)'};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FFF;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 20px */
`;

const EnterCodePopupInputList = styled.div`
  display: flex;
  gap: 8px;
  padding: 0 32px;
  padding-bottom: 40px;
  justify-content: center;
`;
const EnterCodePopupInputBox = styled.div`
  width: 61px;
  height: 61px;
  display: flex;
  justify-content: center;
  align-content: center;
  background: rgba(239, 239, 228, 0.05);
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    width: 60px;
    height: 60px;
  }
`;
const EnterCodePopupInput = styled.input<{
  isValidInputCode: boolean
}>`
  color: #4B2828;
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: Righteous;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 30px */
  outline: none;
  border: unset;
  text-transform: lowercase;
  background: ${({ isValidInputCode }) => isValidInputCode ? 'linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)' : 'unset'};
  background-clip: ${({ isValidInputCode }) => isValidInputCode ? 'text' : 'unset'};
  -webkit-background-clip: ${({ isValidInputCode }) => isValidInputCode ? 'text' : 'unset'};
  -webkit-text-fill-color: ${({ isValidInputCode }) => isValidInputCode ? 'transparent' : 'unset'};
`;
export type LeaderBoardType = 'SWAP' | 'FARM' | 'MUSD' | 'XMONO' | 'REF' | 'BONUS' | 'RW_MUSD' | 'RW_XMONO' | 'RW_MONO' | 'RW_POINT' | 'RW_GOLD';

export const BASE_API = 'https://api.monoswap.io';
// export const BASE_API = 'http://47.129.26.39:2989';
// export const BASE_API = 'http://vps1.orisu.lol:2999';
// export const BASE_API = 'http://vps1.orisu.lol:2999';
// export const BASE_API = 'https://vps1.orisu.lol';

export default function MainQuestXp() {
  const { account, provider, chainId } = useWeb3React();
  const pathParams = useParams();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<LeaderBoardType>('SWAP');
  function onOpenTaskInfo(type: LeaderBoardType) {
    setModalType(type);
    setIsOpenModal(true);
  }

  // FOR REF
  const [isOpenSignModal, setIsOpenSignModal] = useState<boolean>(false);
  const [refCode, setRefCode] = useState<string>('');
  let isSigningMess = false;

  const onClickSignMess = async () => {
    if (isSigningMess) return;
    isSigningMess = true;
    try {
      if (pathParams.id && provider && provider.provider && account) {
        const refCode = pathParams?.id?.split('=')[1];
        if (refCode.length < 6) return;

        const signMess = refCode.toLowerCase();
        const signature = await provider.send('personal_sign', [signMess, account]);
        axios.post(`${BASE_API}/monoswap/api/v1/leaderboard/referral`, {
          "address": account,
          "referralBy": refCode,
          signature
        });
        setIsOpenSignModal(false);
      }
    } catch (err) {
      console.error('Failed to sign mess', err);
    }
    finally {
      isSigningMess = false;
    }
  }

  const hasEnteredRef = async () => {
    const refCheck = await axios.get(`${BASE_API}/monoswap/api/v1/leaderboard/has-entered-ref?address=${account}`);
    if (!refCheck.data)
      setIsOpenSignModal(true);
  }

  useEffect(() => {
    if (pathParams.id && account) {
      const refCode = pathParams?.id?.split('=')[1];
      if (refCode.length < 6) return;
      setRefCode(refCode);
      hasEnteredRef()
    }
  }, [account, chainId])

  return (
    <MainQuestXpWapper>
      <Modal maxWidth={592} isOpen={isOpenSignModal} onDismiss={() => setIsOpenSignModal(false)}>
        <TaskModalInfoWrapper>
          <TaskModalInfoTitleBox>
            <TaskModalInfoTitle>
              You received an invite to Monoswap
            </TaskModalInfoTitle>
            <svg style={{ cursor: 'pointer', width: '24px', height: '24px' }} onClick={() => setIsOpenSignModal(false)} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.6">
                <path d="M7.05025 7.35038L16.9497 17.2499M16.9497 7.35038L7.05025 17.2499" stroke="#4B2828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </g>
            </svg>
          </TaskModalInfoTitleBox>

          <EnterCodePopupInputList>
            {refCode &&
              refCode.split('').map((_, index) => <EnterCodePopupInputBox key={index}>
                <EnterCodePopupInput className="inputCode" value={_} style={{ color: '#4B2828' }} isValidInputCode={true} maxLength={1} readOnly />
              </EnterCodePopupInputBox>
              )
            }
          </EnterCodePopupInputList>

          <div>
            <NotchedButtonFill onClick={onClickSignMess}>
              Confirm
            </NotchedButtonFill>
          </div>
        </TaskModalInfoWrapper>
      </Modal>

      <Modal maxWidth={592} isOpen={isOpenModal} onDismiss={() => setIsOpenModal(false)}>
        {
          modalType == 'FARM' &&
          <TaskModalInfoWrapper>
            <TaskModalInfoTitleBox>
              <TaskModalInfoTitle>
                Provide liquidity to earn Point & Gold
              </TaskModalInfoTitle>
              <svg style={{ cursor: 'pointer', width: '24px', height: '24px' }} onClick={() => setIsOpenModal(false)} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.6">
                  <path d="M7.05025 7.35038L16.9497 17.2499M16.9497 7.35038L7.05025 17.2499" stroke="#4B2828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </g>
              </svg>
            </TaskModalInfoTitleBox>

            <TaskModalListItemContainer>
              <TaskModalListItemBox>
                <TaskModalListIcon />
                <TaskModalText>
                  Create an <span style={{ color: '#68FF9B' }}>in-range</span> position of any yield-earning pair.
                </TaskModalText>
              </TaskModalListItemBox>

              <TaskModalListItemBox>
                <TaskModalListIcon />
                <TaskModalText>
                  Stake your <span style={{ color: '#68FFFF' }}> LP position </span> in any farming pools.
                </TaskModalText>
              </TaskModalListItemBox>

              <TaskModalListItemBox>
                <TaskModalListIcon />
                <TaskModalText>
                  Gain
                  <TaskModalLinerText1 bg="linear-gradient(to right, #68FFFF, #68FF9B)"> MonoXP every 2 hours </TaskModalLinerText1>
                  based on the value of your
                  <span style={{ color: '#68FF9B' }}> staked LP.</span>
                </TaskModalText>
              </TaskModalListItemBox>

              <TaskModalListItemBox style={{ alignItems: 'flex-start' }}>
                <div style={{ marginTop: '1px' }}>
                  <TaskModalListIcon />
                </div>

                <div>
                  <TaskModalText>
                    Eligible pairs:
                  </TaskModalText>
                  <div style={{ display: 'grid', paddingLeft: '20px', marginTop: '5px', gap: '5px' }}>
                    <TaskModalListItemBox>
                      <TaskModalListIcon w='8' h='8' />
                      <TaskModalLinerText2 bg="linear-gradient(to right, #68FFFF 5.25%, #68FF9B 66.86%)">2.5x: OLE/ETH, PAC/ETH, BAJA/ETH, ANDY/ETH, JUICE/ETH, ORBIT/ETH, YIELD/ETH, ezETH/ETH, ZKDX/ETH, KID/ETH</TaskModalLinerText2>
                    </TaskModalListItemBox>

                    <TaskModalListItemBox>
                      <TaskModalListIcon w='8' h='8' />
                      <TaskModalLinerText2 style={{fontSize: '15px'}} bg="linear-gradient(to right, #68FFFF 5.25%, #68FF9B 66.86%)">2x: MUSD/USDB, MUSD/ETH</TaskModalLinerText2>
                    </TaskModalListItemBox>

                    <TaskModalListItemBox>
                      <TaskModalListIcon w='8' h='8' />
                      <TaskModalLinerText2 bg="linear-gradient(to right, #68FFFF 5.25%, #68FF9B 66.86%)">1.5x: ETH/USDB </TaskModalLinerText2>
                    </TaskModalListItemBox>
                  </div>
                </div>

              </TaskModalListItemBox>

            </TaskModalListItemContainer>
            <img style={{ marginTop: '20px', width: '100%' }} src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/xp/farm-popup-img.png' />


          </TaskModalInfoWrapper>
        }

        {
          modalType == 'REF' &&
          <TaskModalInfoWrapper>
            <TaskModalInfoTitleBox>
              <TaskModalInfoTitle>
                Invite friends for extra points
              </TaskModalInfoTitle>
              <svg style={{ cursor: 'pointer' }} onClick={() => setIsOpenModal(false)} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.6">
                  <path d="M7.05025 7.35038L16.9497 17.2499M16.9497 7.35038L7.05025 17.2499" stroke="#4B2828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </g>
              </svg>
            </TaskModalInfoTitleBox>

            <TaskModalListItemContainer>

              <TaskModalListItemBox>
                <TaskModalListIcon />
                <TaskModalText>
                  Get bonus
                  <span style={{ color: '#68FFFF' }}>  Blast Points & Yield </span>
                  when you invite friends to join.
                </TaskModalText>
              </TaskModalListItemBox>

              <TaskModalListItemBox>
                <TaskModalListIcon />
                <TaskModalText>
                  Earn even
                  <span style={{ color: '#68FF9B' }}> more MonoXP </span>
                  when your friends invite their friends.
                </TaskModalText>
              </TaskModalListItemBox>

              <TaskModalListItemBox>
                <TaskModalListIcon />
                <TaskModalText style={{ width: '90%' }}>
                  Unlock a tiered <span style={{ color: '#68FFFF' }}> 26% </span> total bonus - <span style={{ color: '#68FF9B' }}> 15% </span> from your invitees, <span style={{ color: '#68FFFF' }}> 8% </span> from their friends, and 3% from the extended circle!
                </TaskModalText>
              </TaskModalListItemBox>

              <TaskModalListItemBox>
                <TaskModalListIcon />
                <TaskModalText>
                  <span style={{ color: '#68FFFF' }}> Have at least 50 MonoXP </span>
                  to be eligible for reward distribution.
                </TaskModalText>
              </TaskModalListItemBox>

            </TaskModalListItemContainer>
            <img style={{ marginTop: '40px', width: '100%' }} src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/xp/ref-popup-img.png' />

          </TaskModalInfoWrapper>
        }

        {
          modalType == 'MUSD' &&
          <TaskModalInfoWrapper>
            <TaskModalInfoTitleBox>
              <TaskModalInfoTitle>
                The more you mint, the more you earn
              </TaskModalInfoTitle>
              <svg style={{ cursor: 'pointer' }} onClick={() => setIsOpenModal(false)} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.6">
                  <path d="M7.05025 7.35038L16.9497 17.2499M16.9497 7.35038L7.05025 17.2499" stroke="#4B2828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </g>
              </svg>
            </TaskModalInfoTitleBox>

            <TaskModalListItemContainer>

              <TaskModalListItemBox>
                <TaskModalListIcon />
                <TaskModalText>
                  Mint MUSD to get your hands on <span style={{ color: '#68FF9B' }} >25% real yield & Blast Points.</span>
                </TaskModalText>
              </TaskModalListItemBox>

              <TaskModalListItemBox>
                <TaskModalListIcon />
                <TaskModalText>
                  Each power of <span style={{ color: '#68FFFF' }}> $10 MUSD </span> minted will get you to a higher threshold of rewards.
                </TaskModalText>
              </TaskModalListItemBox>

              <TaskModalListItemBox>
                <TaskModalListIcon />
                <TaskModalText>
                  The Leaderboard will be reset and the rewards will be distributed on a <span style={{ color: '#68FFFF' }}> weekly basis. </span>
                </TaskModalText>
              </TaskModalListItemBox>

            </TaskModalListItemContainer>
            <img style={{ marginTop: '40px', width: '100%' }} src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/xp/musd-popup-img.png' />

          </TaskModalInfoWrapper>
        }

        {
          modalType == 'SWAP' &&
          <TaskModalInfoWrapper>
            <TaskModalInfoTitleBox>
              <TaskModalInfoTitle>
                Trade and Earn like never before
              </TaskModalInfoTitle>
              <svg style={{ cursor: 'pointer' }} onClick={() => setIsOpenModal(false)} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.6">
                  <path d="M7.05025 7.35038L16.9497 17.2499M16.9497 7.35038L7.05025 17.2499" stroke="#4B2828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </g>
              </svg>
            </TaskModalInfoTitleBox>

            <TaskModalListItemContainer>

              <TaskModalListItemBox>
                <TaskModalListIcon />
                <TaskModalText>
                  Earn <span style={{ color: '#68FF9B' }}> Blast Points </span> for simply executing trades. </TaskModalText>
              </TaskModalListItemBox>

              <TaskModalListItemBox>
                <TaskModalListIcon />
                <TaskModalText>
                  <TaskModalLinerText2 bg="linear-gradient(90deg, #FFF 0%, #68FF9B 28.59%)">Perform trades on eligible pairs to receive rewards. </TaskModalLinerText2>                        </TaskModalText>
              </TaskModalListItemBox>

              <TaskModalListItemBox>
                <TaskModalListIcon />
                <TaskModalText>
                  The higher the volume is, the more MonoXP you reap.
                </TaskModalText>
              </TaskModalListItemBox>

              <TaskModalListItemBox style={{ alignItems: 'flex-start' }}>
                <div style={{ marginTop: '1px' }}>
                  <TaskModalListIcon />
                </div>

                <div>
                  <TaskModalText>
                    Eligible pairs:
                  </TaskModalText>
                  <div style={{ display: 'grid', paddingLeft: '8px', marginTop: '5px', gap: '5px' }}>
                    <TaskModalListItemBox>
                      <TaskModalListIcon w='8' h='8' />
                      <TaskModalLinerText2 style={{fontSize: '15px'}} bg="linear-gradient(to right, #68FFFF 5.25%, #68FF9B 66.86%)">2.5x: OLE/ETH, PAC/ETH, BAJA/ETH, ANDY/ETH, JUICE/ETH, ORBIT/ETH, YIELD/ETH, ZKDX/ETH, KID/ETH</TaskModalLinerText2>
                    </TaskModalListItemBox>

                    <TaskModalListItemBox>
                      <TaskModalListIcon w='8' h='8' />
                      <TaskModalLinerText2 style={{fontSize: '15px'}} bg="linear-gradient(to right, #68FFFF 5.25%, #68FF9B 66.86%)">2x: MUSD/USDB, MUSD/ETH, ezETH/ETH</TaskModalLinerText2>
                    </TaskModalListItemBox>

                    <TaskModalListItemBox>
                      <TaskModalListIcon w='8' h='8' />
                      <TaskModalLinerText2 style={{fontSize: '15px'}} bg="linear-gradient(to right, #68FFFF 5.25%, #68FF9B 66.86%)">1.5x: ETH/USDB</TaskModalLinerText2>
                    </TaskModalListItemBox>
                  </div>
                </div>

              </TaskModalListItemBox>

            </TaskModalListItemContainer>
            <img style={{ marginTop: '40px', width: '100%' }} src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/xp/swap-popup-img.png' />

          </TaskModalInfoWrapper>
        }

      </Modal>
      <XpTitle onOpenTaskInfo={onOpenTaskInfo} />
      <div style={{ padding: '0 20px' }}>
        <img style={{ margin: 'auto', display: 'block', width: '100%', maxWidth: '267px' }} src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/xp/hr-icon.png' />
      </div>
      <XpLeaderboard onOpenTaskInfo={onOpenTaskInfo} />

      {account && <>
        <div style={{ padding: '0 20px' }}>
          <img style={{ margin: 'auto', display: 'block', width: '100%', maxWidth: '267px' }} src='https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/xp/hr-icon.png' />
        </div> <ClaimedXpHistory />
      </>}

    </MainQuestXpWapper>
  )
}
