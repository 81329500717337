import { Trans } from '@lingui/macro'
import { Percent, Price, Token } from '@uniswap/sdk-core'
import { Position } from '@uniswap/v3-sdk'
import Badge from 'components/Badge'
import RangeBadge from 'components/Badge/RangeBadge'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import HoverInlineText from 'components/HoverInlineText'
import Loader from 'components/Loader'
import { RowBetween } from 'components/Row'
import { useToken } from 'hooks/Tokens'
import useIsTickAtLimit from 'hooks/useIsTickAtLimit'
import { usePool } from 'hooks/usePools'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Bound } from 'state/mint/v3/actions'
import styled from 'styled-components/macro'
import { BREAKPOINTS, HideSmall, MEDIA_WIDTHS, SmallOnly } from 'theme'
import { PositionDetails } from 'types/position'
import { formatTickPrice } from 'utils/formatTickPrice'
import { unwrappedToken } from 'utils/unwrappedToken'

import { DAI, USDC_MAINNET, USDT, WRAPPED_NATIVE_CURRENCY } from '../../constants/tokens'
import { hasURL } from "../../utils/urlChecks";
import { BigNumber } from "@ethersproject/bignumber";
import { useAlphaStore } from 'hooks/useAlphaStore'
import { formatNumber } from 'utils/formatNumbers'

const LinkRow = styled(Link)`
    position: relative;
    align-items: center;
    display: flex;
    cursor: pointer;
    user-select: none;
    flex-direction: column;
    justify-content: space-between;
    color: ${({ theme }) => theme.deprecated_text1};
    padding: 25px 71px 25px 32px;
    text-decoration: none;
    font-weight: 500;
    fill: #FFF6D0;
    box-shadow: -6px -6px 4px 0px rgba(215, 144, 60, 0.60) inset, 6px 6px 4px 0px rgba(255, 255, 255, 0.80) inset;
    filter: drop-shadow(0px 7.395px 30px rgba(145, 103, 20, 0.30));
    border-radius: 10px;

    & > div:not(:first-child) {
        text-align: center;
    }

    :hover {
        background-color: ${({ theme }) => theme.hoverDefault};
    }

    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        padding: 12px 35px 12px 16px;
    }

    ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-direction: column;
    row-gap: 8px;
  `};
`

const WrapperArrow = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 39px;
    height: 100%;
    border-radius: 0px 10px 10px 0px;
    background: #FFE7AB;
    fill: #FFE7AB;
    box-shadow: -6px -6px 4px 0px rgba(215, 144, 60, 0.60) inset, -5px 6px 4px 0px rgba(255, 255, 255, 0.80) inset;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        width: 20px;
        svg{
            width: 12px;
            height: 12px;
        }
    }
`

const WrapperDoubleCurrencyLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
`

const WrapperRangeBadge = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 7.5px;
`


const Line = styled.div`
    width: 100%;
    height: 1px;
    background: rgba(229, 182, 112, 0.50);
    backdrop-filter: blur(4.800000190734863px);
    margin: 25px 0;
    @media (max-width: 747px) {
        margin: 10px 0;
    }
`

const BadgeText = styled.div`
    color: #05C63B;
    text-align: right;
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 18px */
    text-transform: uppercase;
    ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    font-size: 12px;
  `};
`

const APRText = styled.div`
    color: rgba(75, 40, 40, 0.50);
    text-align: right;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 20px */
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        font-size: 14px;
    }
`

const DataLineItem = styled.div`
    font-size: 14px;
`

const RangeLineItem = styled(DataLineItem)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
    width: 100%;
`

const DoubleArrow = styled.span`
    margin: 0 2px;
    color: ${({ theme }) => theme.deprecated_text3};
`

const RangeText = styled.span`
    padding: 0.25rem 0.25rem;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        span{
            font-size: 12px !important;
        }
    }
`

const ExtentsText = styled.span`
    color: rgba(75, 40, 40, 0.50);
    font-family: Outfit;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 20px */
    margin-right: 4px;
    ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
        display: none;
    `};
     
`

const PrimaryPositionIdData = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > * {
        margin-right: 8px;
    }
`

const DataText = styled.div`
    color: #4B2828;
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 18px */
    text-transform: uppercase;
    ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    font-size: 14px;
  `};
`

interface PositionListItemProps {
    token0: string
    token1: string
    tokenId: BigNumber
    fee: number
    liquidity: BigNumber
    tickLower: number
    tickUpper: number
}

export function getPriceOrderingFromPositionForUI(position?: Position): {
    priceLower?: Price<Token, Token>
    priceUpper?: Price<Token, Token>
    quote?: Token
    base?: Token
} {
    if (!position) {
        return {}
    }

    const token0 = position.amount0.currency
    const token1 = position.amount1.currency

    // if token0 is a dollar-stable asset, set it as the quote token
    const stables = [DAI, USDC_MAINNET, USDT]
    if (stables.some((stable) => stable.equals(token0))) {
        return {
            priceLower: position.token0PriceUpper.invert(),
            priceUpper: position.token0PriceLower.invert(),
            quote: token0,
            base: token1,
        }
    }

    // if token1 is an ETH-/BTC-stable asset, set it as the base token
    const bases = [...Object.values(WRAPPED_NATIVE_CURRENCY)]
    if (bases.some((base) => base && base.equals(token1))) {
        return {
            priceLower: position.token0PriceUpper.invert(),
            priceUpper: position.token0PriceLower.invert(),
            quote: token0,
            base: token1,
        }
    }

    // if both prices are below 1, invert
    if (position.token0PriceUpper.lessThan(1)) {
        return {
            priceLower: position.token0PriceUpper.invert(),
            priceUpper: position.token0PriceLower.invert(),
            quote: token0,
            base: token1,
        }
    }

    // otherwise, just return the default
    return {
        priceLower: position.token0PriceLower,
        priceUpper: position.token0PriceUpper,
        quote: token1,
        base: token0,
    }
}

export default function PositionListItem({
    token0: token0Address,
    token1: token1Address,
    tokenId,
    fee: feeAmount,
    liquidity,
    tickLower,
    tickUpper,
}: PositionListItemProps) {

    const token0 = useToken(token0Address)
    const token1 = useToken(token1Address)

    const currency0 = token0 ? unwrappedToken(token0) : undefined
    const currency1 = token1 ? unwrappedToken(token1) : undefined

    // construct Position from details returned
    const [, pool] = usePool(currency0 ?? undefined, currency1 ?? undefined, feeAmount)

    const position = useMemo(() => {
        if (pool) {
            return new Position({ pool, liquidity: liquidity.toString(), tickLower, tickUpper })
        }
        return undefined
    }, [liquidity, pool, tickLower, tickUpper])

    const tickAtLimit = useIsTickAtLimit(feeAmount, tickLower, tickUpper)
    // prices
    const { priceLower, priceUpper, quote, base } = getPriceOrderingFromPositionForUI(position)

    const currencyQuote = quote && unwrappedToken(quote)
    const currencyBase = base && unwrappedToken(base)

    // check if price is within range
    const outOfRange: boolean = pool ? pool.tickCurrent < tickLower || pool.tickCurrent >= tickUpper : false

    const positionSummaryLink = '/pool/' + tokenId

    const removed = liquidity?.eq(0)

    const containsURL = useMemo(
        () =>
            [token0?.name, token0?.symbol, token1?.name, token1?.symbol].reduce(
                (acc, testString) => acc || Boolean(testString && hasURL(testString)),
                false
            ),
        [token0?.name, token0?.symbol, token1?.name, token1?.symbol]
    )

    if (containsURL) {
        return null
    }

    // const { liquidityValue0, liquidityValue1, currency0, currency1, outOfRange } = useDerivedV3PositionInfo(positions[i], pool, false, token0, token1)

    const alphaStore = useAlphaStore()
    const totalLiquidity = useMemo(() => {
        if (!position) return 0
        else {
            const usd0 = alphaStore.calcUsd(String(position.pool.token0.address).toLowerCase(), Number(position.amount0.toSignificant()))
            const usd1 = alphaStore.calcUsd(String(position.pool.token1.address).toLowerCase(), Number(position.amount1.toSignificant()))
            return usd0 + usd1
        }
    }, [alphaStore.tokenPrices, position])



    return (
        <LinkRow to={positionSummaryLink}>
            <RowBetween>
                <PrimaryPositionIdData>
                    <WrapperDoubleCurrencyLogo>
                        <DoubleCurrencyLogo margin currency0={currencyBase} currency1={currencyQuote} size={40} />
                        <WrapperRangeBadge>
                            <RangeBadge removed={removed} inRange={!outOfRange} />
                            <DataText>
                                &nbsp;{currencyQuote?.symbol}&nbsp;/&nbsp;{currencyBase?.symbol}&nbsp;
                                <BadgeText style={{ display: 'inline' }}>
                                    <Trans>{new Percent(feeAmount, 1_000_000).toSignificant()}%</Trans>
                                    {
                                        position?.pool.token0.symbol
                                    }
                                </BadgeText>
                            </DataText>

                        </WrapperRangeBadge>
                    </WrapperDoubleCurrencyLogo>

                    <WrapperRangeBadge style={{ alignItems: 'center' }}>
                        <APRText>Liquidity</APRText>
                        <BadgeText style={{ color: '#4B2828' }}>
                            <Trans>
                                {formatNumber(totalLiquidity, 6)}
                                $
                            </Trans>
                        </BadgeText>
                    </WrapperRangeBadge>
                </PrimaryPositionIdData>

            </RowBetween>
            <Line></Line>
            {priceLower && priceUpper ? (
                <RangeLineItem>
                    <img style={{ width: "24px", height: "24px" }} src="/pool/img.png" alt="img" />
                    <RangeText>
                        <ExtentsText>
                            <Trans>Min: </Trans>
                        </ExtentsText>
                        <Trans>
                            <span style={{ color: '#4B2828', fontSize: "16px" }}>
                                {formatTickPrice({
                                    price: priceLower,
                                    atLimit: tickAtLimit,
                                    direction: Bound.LOWER,
                                })}
                            </span>
                            {/*{' '}*/}
                            {/*<HoverInlineText text={currencyQuote?.symbol}/>{' '}*/}
                            {/*<span style={{color: 'rgba(248, 248, 135, 0.5)'}}>per</span>{' '}*/}
                            {/*<HoverInlineText text={currencyBase?.symbol ?? ''}/>*/}
                        </Trans>
                    </RangeText>

                    <img style={{ width: "93px", height: "2px", margin: "0 16px" }} src="/pool/img_2.png" alt="img_2" />
                    <img style={{ width: "24px", height: "24px" }} src="/pool/img_1.png" alt="img" />

                    <RangeText>
                        <ExtentsText>
                            <Trans>Max:</Trans>
                        </ExtentsText>
                        <Trans>
                            <span style={{ color: '#4B2828', fontSize: "16px" }}>
                                {formatTickPrice({
                                    price: priceUpper,
                                    atLimit: tickAtLimit,
                                    direction: Bound.UPPER,
                                })}
                            </span>
                            {/*<HoverInlineText text={currencyQuote?.symbol} textColor="#4B2828"/>{' '}*/}
                            {/*<span style={{color: 'rgba(239, 239, 228, 0.5)'}}>per</span>{' '}*/}
                            {/*<HoverInlineText maxCharacters={10} text={currencyBase?.symbol}/>*/}
                        </Trans>
                    </RangeText>
                </RangeLineItem>
            ) : (
                <Loader />
            )}

            <WrapperArrow>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_160_2276)">
                        <path d="M7 7L12 12L7 17" stroke="#4B2828" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round" />
                        <path d="M13 7L18 12L13 17" stroke="#4B2828" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_160_2276">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </WrapperArrow>

        </LinkRow>
    )
}
