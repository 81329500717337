import { useWeb3React } from '@web3-react/core'
import Modal from '../Modal'
import styled from 'styled-components/macro'
import { CloseIcon } from 'theme'
import AlphaPostionV3Item, { AlphaPositionV3Info, ShowPositionType } from './AlphaPostionV3Item'
import { NotchedButtonFill } from 'components/Button'
// import ContentLoading from 'components/ContentLoading'
import { BigNumber } from 'ethers'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { formatXpNumber } from 'pages/Xp/sections/XpTitle'
import { useToken } from 'hooks/Tokens'
import { TokenInfo } from '@uniswap/token-lists'
import { Currency } from '@uniswap/sdk-core'


const Wrapper = styled.div` 
  width: 100%;
  max-width: 573px;
  min-height: 700px;
  padding: 32px;
  border-radius: 10px;
  border: 1.5px solid #E5B670;
  background: #FFF6D0;
  box-shadow: 0px 7.395px 30px 0px rgba(145, 103, 20, 0.30);
  overflow: auto;

  @media screen and (max-width: 768px) {
    padding: 20px 15px;
  }
`

const TitleBox = styled.div`
  display: grid;
  gap: 20px;
  h3{
    color: #4B2828;
    font-family: DynaPuff;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 24px */
    margin: 0;
  }
  h5{
    color: #4B2828;
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 18px */
    margin: 0;
  }
  &>div{
    display: flex;
    justify-content: space-between;
  }
`

const PositionList = styled.div`
  margin-top: 32px;
  display: grid;
  gap: 12px;
`



const NoteBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  p{
    margin: 0;
    color: #EEAA01;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 20px */
  }
`
const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  span{
    color: #4B2828;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
  }
`

// style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
const PoolInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 420px) {
    display: grid;
  }
`
const PoolInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
 
    h5{
        color: #4B2828;
        font-family: DynaPuff;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 24px */
        margin: 0;
    }

    .token_img_box{
        display: flex;
        gap: 4px;
        img{
            width: 24px;
            height: 24px;
            border-radius: 24px;
            box-shadow: 2px 2px 0px 0px #D7903C;
        }
    }
    div.tag{
        border-radius: 50px;
        background: #FFE7AB;
        display: inline-flex;
        height: 24px;
        padding: 2px 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        span{
            color: #4B2828;
            text-align: center;
            font-family: DynaPuff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
            text-transform: uppercase;
        }
    }
`
interface AlphaPositionModalInterface {
  title: string
  desc: string
  isOpen: boolean
  onDismiss: () => void
  loading: boolean
  showPositionType: ShowPositionType
  nftInfoList: AlphaPositionV3Info[]
  checkedNftIds: number[]
  onCheckedNftId: (id: number, isChecked: boolean) => void
  totalHarvestReward?: number
  modalBottom?: ReactNode
  tokenCurrencyA?: any;
  tokenCurrencyB?: any;
}

export default function AlphaPositionV3List({
  title,
  desc,
  isOpen,
  onDismiss,
  loading,
  showPositionType,
  nftInfoList,
  checkedNftIds,
  onCheckedNftId,
  totalHarvestReward,
  modalBottom,
  tokenCurrencyA,
  tokenCurrencyB
}: AlphaPositionModalInterface) {

  const { img0URL, img1URL } = useMemo(() => ({
    img0URL: `https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/tokens/address/${tokenCurrencyA?.address}.png`,
    img1URL: `https://raw.githubusercontent.com/gmeow-fi/image-repo/master/gmeow/tokens/address/${tokenCurrencyB?.address}.png`,
  }), [tokenCurrencyA, tokenCurrencyB])

  useEffect(() => {
    if (isOpen) window.scrollTo(0, 0)
  }, [isOpen])
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth={520}>
      <Wrapper>
        <TitleBox>
          <div className='title'>
            <PoolInfoWrapper>
              <h3>{title}</h3>
              <PoolInfo>
                <div className="token_img_box">
                  <img src={img0URL} alt="gmeowfi" />
                  <img src={img1URL} alt="gmeowfi" />
                </div>
                <h5>
                  {tokenCurrencyA?.symbol}/{tokenCurrencyB?.symbol}
                </h5>
                <div className="tag">
                  <span>v3</span>
                </div>
              </PoolInfo>
            </PoolInfoWrapper>
            <CloseIcon onClick={onDismiss} />
          </div>
          <h5>{desc}</h5>
        </TitleBox>

        <PositionList>
          {/* <ContentLoading isLoading={loading}>
            {
              nftIds.map((nftId, index) => (<AlphaPostionV3Item key={index} nftId={nftId} showPositionType={showPositionType} />))
            }
          </ContentLoading> */}
          {
            nftInfoList.map((nftInfo, index) => (<AlphaPostionV3Item
              key={index}
              nftInfo={nftInfo}
              showPositionType={showPositionType}
              isChecked={checkedNftIds.includes(Number(nftInfo.formattedTokenId))}
              onCheckedNftId={onCheckedNftId}
            />))
          }
          {
            !loading && nftInfoList.length == 0 && <EmptyBox>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="8" stroke="#A5938F" strokeWidth="2" />
                <line x1="17.543" y1="7.13093" x2="6.34454" y2="18.3294" stroke="#A5938F" strokeWidth="2" />
              </svg>

              <span>You don’t have any compatible position</span>
            </EmptyBox>
          }

        </PositionList>

        {
          modalBottom
        }


      </Wrapper>
    </Modal >
  )
}
